import { useQuery } from 'react-query';

import { resources } from '..';
import queryKeys from '../queryKeys';

import { CACHE_TIME } from './constants';

interface Props {
  url?: string;
}
const useGetManifest = ({ url }: Props) => {
  const { data } = useQuery(
    queryKeys.manifest(),
    () => resources.getManifest(url ?? ''),
    {
      enabled: Boolean(url),
      refetchOnWindowFocus: false,
      staleTime: CACHE_TIME,
    }
  );

  return { data };
};

export default useGetManifest;
