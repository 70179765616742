import { useState } from 'react';

import CategoryPreview from './CategoryPreview';
import SummaryCategory from './SummaryCategory';
import { Category } from './types';

interface SummaryCategoriesProps {
  categories: Category[];
}

const SummaryCategories = ({ categories }: SummaryCategoriesProps) => {
  const [selectedCategory, setSelectedCategory] = useState<
    Category | undefined
  >();

  return (
    <div className="flex gap-3 flex-col">
      {selectedCategory && (
        <CategoryPreview
          category={selectedCategory}
          onClose={() => setSelectedCategory(undefined)}
        />
      )}

      {categories.map((category: Category) => (
        <SummaryCategory
          category={category}
          onCategorySelected={() => setSelectedCategory(category)}
          key={category.name}
          className="bg-white"
          containerClassName="rounded-lg border border-focus-background"
        />
      ))}
    </div>
  );
};

export default SummaryCategories;
