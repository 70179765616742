import styled from '@emotion/styled';
import config from 'app/config';
import { ToastContainer as ToastifyContainer } from 'react-toastify';

const ToastContainer = ({ toastId }: { toastId: string }) => {
  return <StyledToastContainer containerId={toastId} limit={1} />;
};

const StyledToastContainer = styled(ToastifyContainer)`
  &&&.Toastify__toast-container {
    width: initial;
  }

  .Toastify__toast {
    display: inline-block;
    height: 36px;
    min-height: auto;
    margin-bottom: 15px;
    padding: 0;
    background-color: ${config.colors['grayscale-primary']};
    border-radius: 9px;
  }

  .Toastify__toast-body {
    height: 100%;
    margin: 0;
    padding: 1px;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.4)
    );

    > div {
      height: 100%;
      background-color: ${config.colors['grayscale-primary']};
      border-radius: 8px;
    }
  }
`;

export default ToastContainer;
