import { useQuery } from 'react-query';

import { resources } from '..';
import queryKeys from '../queryKeys';
interface GetSummarizeQueryParams {
  articleId: number;
  actionId: number;
  language: string;
  enabled?: boolean;
}

const useGetSummarizeQuery = ({
  articleId,
  actionId,
  language,
  enabled,
}: GetSummarizeQueryParams) => {
  const { data, error, isLoading } = useQuery(
    queryKeys.getSummary(articleId, actionId, language),
    () => resources.getSummary(articleId, actionId, language),
    {
      enabled,
    }
  );

  return {
    data: data?.data,
    error,
    isLoading,
  };
};

export default useGetSummarizeQuery;
