import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import Input from 'app/components/Input';
import config from 'app/config';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import useChannelsTranslation from 'app/internationalization/hooks/useChannelsTranslation';
import {
  FormDefaultValue,
  ISOLanguage,
  TitleLanguage,
} from 'app/pages/Channels/types';
import { LocationTick, Trash } from 'iconsax-react';
import { useRef, useState } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { Popover } from 'react-tiny-popover';
import MoreLineIcon from 'remixicon-react/MoreLineIcon';

type OnNameChange = ({
  index,
  value,
  isMain,
}: {
  index: number;
  value: string;
  isMain?: boolean;
}) => void;
interface Props {
  value: string;
  field: TitleLanguage;
  index: number;
  readOnly?: boolean;
  selectedLanguages: ISOLanguage[];
  contentLength: number;
  onNameChange: OnNameChange;
  removeLanguage: (code: string) => void;
  getLanguageName: (code: string) => void;
  register?: UseFormRegister<FormDefaultValue>;
}

const TranslatableInput = ({
  value,
  field,
  index,
  readOnly,
  selectedLanguages,
  contentLength,
  removeLanguage,
  onNameChange,
  getLanguageName,
  register,
}: Props) => {
  const { t } = useChannelsTranslation();
  const { t: at } = useArticlesTranslation();
  const [open, setOpen] = useState(false);
  const parent = useRef<HTMLDivElement>(null);

  return (
    <div ref={parent}>
      <Popover
        key={index}
        isOpen={open}
        parentElement={parent.current ?? undefined}
        positions={['bottom', 'right']}
        align="end"
        onClickOutside={() => setOpen(false)}
        containerClassName="-mt-4 z-[50] shadow-atobi rounded-lg bg-white min-w-[220px]"
        content={
          <div className="w-full">
            {!field.isMain && (
              <button
                type="button"
                className="flex gap-2 p-4 py-3 items-center w-full bg-white rounded-t-lg hover:bg-focus-background"
                onClick={() => onNameChange({ index, value, isMain: true })}
                disabled={field.isMain}
              >
                <LocationTick />
                <span>{at('Set as Main Language')}</span>
              </button>
            )}
            <button
              type="button"
              className={cx(
                'flex gap-2 p-4 py-3 items-center w-full rounded-b-lg',
                {
                  'bg-white': field.isMain || selectedLanguages.length === 1,
                  'hover:bg-focus-background':
                    selectedLanguages.length > 1 && !field.isMain,
                  'opacity-30': selectedLanguages.length === 1 || field.isMain,
                  'rounded-t-lg': field.isMain,
                }
              )}
              onClick={() => {
                removeLanguage(field.code);
                setOpen(false);
              }}
              disabled={selectedLanguages.length === 1 || field.isMain}
            >
              <Trash />
              <span>{t('Remove')}</span>
            </button>
          </div>
        }
      >
        <div className="relative">
          <span className="font-semibold flex text-sm">
            <>
              {getLanguageName(field.code)}
              {field.isMain && (
                <span className="pl-2 font-normal flex text-grayscale-secondary items-center justify-center">
                  <LocationTick size={16} />
                  <span className="pl-1">{at('Main')}</span>
                </span>
              )}
            </>
          </span>
          <div className="flex flex-row gap-3 pb-5 pt-2.5">
            <div className="border border-gray-dark rounded grow">
              <Input
                {...(!register && {
                  onChange: (e) =>
                    onNameChange({ index, value: e.target.value }),
                })}
                register={register}
                value={value}
                inputClassName="py-[9px] focus"
                readOnly={readOnly}
                name={`names.${index}.value`}
                key={field.code}
                placeholder={t('e.g. Product Training or News')}
                containerClassName="focus-within:!border-focus !rounded !px-3"
                maxLength={contentLength}
                contentEnd={
                  <span className="text-xs text-grayscale-secondary">{`${value.length}/${contentLength}`}</span>
                }
              />
            </div>

            {!readOnly && (
              <MoreButton
                className="h-10 w-10 bg-white flex justify-center items-center rounded-lg z-[2]"
                onClick={() => {
                  setOpen((prev) => !prev);
                }}
                type="button"
              >
                <MoreLineIcon />
              </MoreButton>
            )}
          </div>
        </div>
      </Popover>
    </div>
  );
};

const MoreButton = styled.button`
  border: 1px solid ${config.colors['grayscale-bg-dark']};
  box-shadow: 0px 0px 12px rgba(112, 112, 112, 0.1);
  transform: rotate(90deg);
`;

export default TranslatableInput;
