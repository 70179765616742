import { request } from 'app/api/gcsRequest';
import config from 'app/config';
import { CommentApi } from 'submodules/common-ui/generated/api/gcs';

const deleteComment = (commentId: number) =>
  new CommentApi(undefined, config.env.gcsApiUrl, request()).deleteComment(
    commentId
  );

export default deleteComment;
