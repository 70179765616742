import { useEditor, Node } from '@craftjs/core';
import { useCallback, useState } from 'react';

import BlockTypes from '../helpers/constants';

import useCloneTree from './useCloneTree';

const useEditorShortcuts = () => {
  const [copiedNode, setCopiedNode] = useState<Node>();

  const { cloneTree } = useCloneTree();

  const {
    canRedo,
    canUndo,
    nodeId,
    nodes,

    actions: {
      addNodeTree,
      selectNode,
      history: { redo, undo },
    },
  } = useEditor((state, editor) => ({
    canRedo: editor.history.canRedo(),
    canUndo: editor.history.canUndo(),
    nodeId: [...state.events.selected][0],
    nodes: editor.getNodes(),
  }));

  const copy = useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault();
      e.stopPropagation();

      const nodeToCopy = nodes[nodeId];
      const availableBlocks = Object.entries(BlockTypes)
        .map(([__, value]) => value)
        .filter(
          (blockType) =>
            blockType !== BlockTypes.CoverImageBlock &&
            blockType !== BlockTypes.TitleBlock &&
            blockType !== BlockTypes.CategoriesBlock
        );

      if (!nodeToCopy) return;
      if (!availableBlocks.includes(nodeToCopy.data.name)) return;

      setCopiedNode(nodeToCopy);
    },
    [nodeId, nodes]
  );

  const paste = useCallback(
    (e: KeyboardEvent, node: Node) => {
      e.preventDefault();
      e.stopPropagation();

      if (!node.data.parent) return;

      const tree = cloneTree(node.id);
      addNodeTree(tree, node.data.parent);
      selectNode(tree.rootNodeId);
    },
    [addNodeTree, cloneTree, selectNode]
  );

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (canRedo && e.code === 'KeyY' && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        e.stopPropagation();

        redo();
        return;
      }

      if (canUndo && e.code === 'KeyZ' && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        e.stopPropagation();

        undo();
        return;
      }

      const canCopyPaste =
        document.activeElement?.tagName.toLowerCase() === 'body' ||
        document.activeElement?.classList.contains('block-container');

      if (
        canCopyPaste &&
        e.code === 'KeyC' &&
        (e.ctrlKey || e.metaKey) &&
        nodeId
      ) {
        copy(e);
        return;
      }

      if (
        canCopyPaste &&
        e.code === 'KeyV' &&
        (e.ctrlKey || e.metaKey) &&
        copiedNode
      ) {
        paste(e, copiedNode);
      }
    },
    [canRedo, canUndo, copiedNode, nodeId, redo, undo, copy, paste]
  );

  return {
    onKeyDown,
  };
};

export default useEditorShortcuts;
