import { cx } from '@emotion/css';
import { VerticalChevron } from 'app/components';
import { Icon as IconType } from 'iconsax-react';
import { DetailedHTMLProps, ButtonHTMLAttributes } from 'react';

interface DropdownInputProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  text: string;
  Icon?: IconType;
  totalSelected?: number;
  isSelected?: boolean;
  open: boolean;
  required?: boolean;
  onClick: VoidFunction;
}

const DropdownInput = ({
  text,
  Icon,
  totalSelected,
  open,
  required,
  onClick,
  className,
  isSelected = false,
  ...rest
}: DropdownInputProps) => (
  <button
    {...rest}
    type="button"
    className={cx(
      'flex border border-gray-light rounded h-10 justify-start items-center w-full',
      { 'bg-gray-light': rest.disabled },
      className
    )}
    onClick={onClick}
  >
    {Icon && <Icon className="text-gray-dark ml-4" />}
    <span
      className={cx('text-sm text-left text-grayscale-primary ml-2', {
        'opacity-50': isSelected,
      })}
    >
      {text}
      {required && <span className="text-error">*</span>}
    </span>
    {!!totalSelected && (
      <div className="flex justify-center items-center w-[25px] h-[25px] ml-2 bg-focus rounded-[6px] text-white text-sm">
        {totalSelected}
      </div>
    )}
    <div className="ml-auto mr-3">
      <VerticalChevron
        size={16}
        open={open}
        className={cx({ 'cursor-default': rest.disabled })}
      />
    </div>
  </button>
);

export default DropdownInput;
