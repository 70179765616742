import { Node, useNode } from '@craftjs/core';
import { ReactNode } from 'react';

const PollOptionsContainer = ({ children }: { children?: ReactNode }) => {
  const {
    connectors: { connect },
  } = useNode();

  return <div ref={(ref) => ref && connect(ref)}>{children}</div>;
};

PollOptionsContainer.craft = {
  displayName: 'PollOptionsContainer',
  rules: {
    canMoveIn: (incomingNodes: Node[], _self: Node) => {
      const node = incomingNodes[0];

      return node.data.displayName === 'PollOption';
    },

    canMoveOut: () => false,
  },
};

export default PollOptionsContainer;
