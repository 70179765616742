import { SocialFeedKey } from '../types';

import useBaseTranslation from './useBaseTranslation';

const useSocialFeedTranslation = () =>
  useBaseTranslation<SocialFeedKey>(undefined, {
    keyPrefix: 'socialFeed',
  });

export default useSocialFeedTranslation;
