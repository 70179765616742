import { SearchZoomIn, Import, Add, SearchZoomOut1 } from 'iconsax-react';
import { HTMLAttributes, ReactNode, useState } from 'react';

interface ActionButtonsProps {
  closeModal: () => void;
  zoomImage: () => void;
  downloadImage: () => void;
}

const ActionButtons = ({
  closeModal,
  zoomImage,
  downloadImage,
}: ActionButtonsProps) => {
  const [isZoomed, setIsZoomed] = useState(false);

  const ActionButton = ({
    children,
    ...restProps
  }: { children: ReactNode } & HTMLAttributes<HTMLButtonElement>) => {
    return (
      <button
        {...restProps}
        className="flex items-center justify-center p-3 rounded-xl text-white bg-grayscale-secondary opacity-8"
      >
        {children}
      </button>
    );
  };

  const onZoomClick = () => {
    setIsZoomed((prev) => !prev);
    zoomImage();
  };

  return (
    <div className="flex gap-2 w-1/3 justify-end">
      <ActionButton onClick={onZoomClick}>
        {isZoomed ? <SearchZoomOut1 size={24} /> : <SearchZoomIn size={24} />}
      </ActionButton>
      <ActionButton onClick={downloadImage}>
        <Import size={24} />
      </ActionButton>
      <ActionButton onClick={closeModal}>
        <Add size={24} className="rotate-45" />
      </ActionButton>
    </div>
  );
};

export default ActionButtons;
