import { useNode } from '@craftjs/core';
import { cx } from '@emotion/css';
import { useAuthenticatedUser } from 'app/api/auth/hooks';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import useIsViewer from 'app/pages/ArticleEditor/hooks/useIsViewer';
import useRecurringDateAsSchedule from 'app/pages/Editor/hooks/useRecurringDateAsSchedule';
import {
  ArrowRight2,
  Eye,
  LockCircle,
  MessageQuestion,
  Profile2User,
  Shop,
} from 'iconsax-react';
import noop from 'lodash/noop';
import { DbMultiChoiceAnswer } from 'submodules/common-ui/generated/api/gcs';

import { useCurrentSelectedInstance } from '../components/ActionSettings/context';
import Deadline from '../components/Deadline';
import Title from '../components/Title';

import { QuizBlockProps } from '.';

// eslint-disable-next-line sonarjs/cognitive-complexity
const EndUserMode = () => {
  const { t } = useArticlesTranslation();

  const { data: user } = useAuthenticatedUser();
  const {
    isActionCompleted,
    currentInstanceAnswer,
    colleagueAnswer,
    answeredByColleague,
  } = useCurrentSelectedInstance();

  const { getRecurringDate } = useRecurringDateAsSchedule();

  const { selected, quizProps } = useNode((node) => ({
    selected: node.events.selected,
    nodeId: node.id,
    linkedNodes: node.data.linkedNodes,
    quizProps: node.data.props as QuizBlockProps,
  }));

  const {
    title,
    description,
    deadline,
    required,
    audiences,
    isLocked,
    schedule,
  } = quizProps;

  const { isViewer } = useIsViewer(audiences);

  const isRecurring = schedule && schedule?.frequency !== 'once';

  let recurringText;
  if (schedule && isRecurring) {
    const recurringDate = getRecurringDate(schedule);
    recurringText = `${recurringDate?.recurring.label} - (${recurringDate?.start.time} - ${recurringDate?.end.time})`;
  }

  const displayAnswer = answeredByColleague
    ? colleagueAnswer
    : currentInstanceAnswer;
  const isCorrect = (displayAnswer?.answer as DbMultiChoiceAnswer)?.some(
    (uac) => uac.correct
  );

  const renderIcon = () => {
    if (!isViewer && required === -1) {
      return (
        <img
          className="w-7 h-7 rounded md:w-8 md:h-8 md:rounded-sm"
          src={user?.avatars?.small}
          alt="avatar"
        />
      );
    }

    if (required === -1) {
      return (
        <div className="bg-focus-background rounded p-1.5">
          <Profile2User size={16} className="text-ceil" />
        </div>
      );
    }

    return (
      <div className="bg-focus-background rounded p-1.5">
        <Shop size={16} className="text-ceil" />
      </div>
    );
  };

  return (
    <div
      className={cx(
        'flex justify-between items-center min-h-[54px] md:min-h-0 mx-2 md:my-2 p-3 rounded-lg md:rounded border border-gray-light cursor-pointer',
        {
          'bg-focus-background':
            selected &&
            (!isActionCompleted || (isActionCompleted && isViewer)) &&
            !isLocked,
          'bg-white':
            !selected &&
            (!isActionCompleted || (isActionCompleted && isViewer)),
          'bg-success-background': !isViewer && isActionCompleted && isCorrect,
          'bg-error-light': !isViewer && isActionCompleted && !isCorrect,
          'opacity-50': isActionCompleted || answeredByColleague,
          'shadow-action': !isLocked,
        }
      )}
    >
      <div className="relative">
        {isLocked ? (
          <LockCircle size={20} className="text-ceil mr-3" />
        ) : (
          <MessageQuestion size={20} className="text-ceil mr-3" />
        )}
        {isViewer && (
          <div
            className={cx('absolute top-[10px] right-[6px]', {
              'bg-focus-background': selected,
              'bg-white': !selected,
              'opacity-50': isActionCompleted || answeredByColleague,
            })}
          >
            <Eye size={14} className="text-focus" />
          </div>
        )}
      </div>

      <div className="flex flex-col w-full">
        <Title
          className={cx('font-bold md:font-normal', {
            'line-through': isActionCompleted || answeredByColleague,
          })}
          placeholder={`${t('Description')}*`}
          enabled={false}
          description={title ? title : description}
          onChange={noop}
          isLocked={isLocked}
        />

        <div className="flex text-gray-dark [&:not(:empty)]:mt-2.5">
          {deadline || answeredByColleague ? (
            <Deadline
              disabled={true}
              selected={deadline}
              onChange={noop}
              isRecurring={isRecurring}
            />
          ) : (
            isRecurring && (
              <Deadline
                disabled={true}
                selected={deadline}
                onChange={noop}
                isRecurring={isRecurring}
                recurringText={recurringText}
              />
            )
          )}
        </div>
      </div>
      {!isActionCompleted && (
        <>
          {renderIcon()}
          <ArrowRight2
            className="md:hidden min-w-4 ml-2 text-grayscale-secondary"
            size={16}
          />
        </>
      )}
    </div>
  );
};

export default EndUserMode;
