import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import { COMMAND_PRIORITY_LOW, BLUR_COMMAND } from 'lexical';
import { useLayoutEffect, useState } from 'react';

const HasFocus = () => {
  const [editor] = useLexicalComposerContext();

  const [clicks, setClicks] = useState(0);

  useLayoutEffect(() => {
    const onClick = () => {
      setClicks((prev) => prev + 1);
    };

    if (clicks > 1) {
      editor.setEditable(true);
      editor.focus();
    }

    return mergeRegister(
      editor.registerRootListener(
        (
          rootElement: null | HTMLElement,
          prevRootElement: null | HTMLElement
        ) => {
          if (prevRootElement !== null) {
            prevRootElement.removeEventListener('click', onClick);
          }
          if (rootElement !== null) {
            rootElement.addEventListener('click', onClick);
          }
        }
      ),

      editor.registerCommand(
        BLUR_COMMAND,
        () => {
          setClicks(0);
          return false;
        },
        COMMAND_PRIORITY_LOW
      )
    );
  }, [editor, clicks]);

  return <></>;
};

export default HasFocus;
