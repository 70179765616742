export default `<?xml version="1.0" encoding="UTF-8"?>
<manifest identifier="com.example.scorm" version="1.2"
xmlns="http://www.imsproject.org/xsd/imscp_rootv1p1p2"
xmlns:adlcp="http://www.adlnet.org/xsd/adlcp_rootv1p2"
xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
xsi:schemaLocation="http://www.imsproject.org/xsd/imscp_rootv1p1p2 
http://www.imsproject.org/xsd/imscp_rootv1p1p2.xsd
http://www.adlnet.org/xsd/adlcp_rootv1p2 
http://www.adlnet.org/xsd/adlcp_rootv1p2.xsd">
<metadata>
<schema>ADL SCORM</schema>
<schemaversion>1.2</schemaversion>
</metadata>
<organizations default="ORG-1">
<organization identifier="ORG-1">
<title>Sample SCORM Package</title>
<item identifier="ITEM-1" identifierref="RESOURCE-1">
<title>Article</title>
</item>
</organization>
</organizations>
<resources>
<resource identifier="RESOURCE-1" type="webcontent" adlcp:scormtype="sco" href="index.html">
<file href="index.html"/>
<!-- Include other necessary files -->
</resource>
</resources>
</manifest>`;
