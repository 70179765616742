import useGetWebAccessToken from 'app/api/sisense/hooks/useGetWebAccessToken';
import { Alert, PageLoader, SisenseDashboard } from 'app/components';
import FilterButtons from 'app/components/FilterButtons';
import {
  useArticlesTranslation,
  useSisenseDashboardTranslation,
} from 'app/internationalization/hooks';
import { useState } from 'react';
import { Article } from 'submodules/common-ui/generated/api/gcs';

import Answers from '../Answers';
import { AnswersContextProvider } from '../Answers/context';

interface DashboardProps {
  article?: Article;
}

const Dashboard = ({ article }: DashboardProps) => {
  const { t } = useSisenseDashboardTranslation();
  const { t: ta } = useArticlesTranslation();

  const { token, isLoading } = useGetWebAccessToken();

  const defaultTab = 'results';
  const [tab, setTab] = useState(defaultTab);

  const tabs: { [key: string]: string } = {
    answers: ta('Answers'),
    results: ta('Results'),
    ...(article?.isShared && { shared: ta('Forwarded results') }),
  };

  if (isLoading) return <PageLoader />;

  const dashboard =
    tab === defaultTab
      ? '64afea1abcdca60032003098'
      : '64ef59c66530f50033add959';

  if (!token)
    return (
      <div className="flex w-full h-full items-center justify-center m-2">
        <Alert variant="error">
          {t('Failed to get Web access Token. Please try to refresh the page.')}
        </Alert>
      </div>
    );

  const containerClassName =
    tab === 'answers'
      ? 'w-full'
      : 'flex flex-col items-center md:py-4 px-0 md:px-6 h-full w-full';

  return (
    <div className={containerClassName}>
      <div className="flex m-auto justify-center mb-7">
        <FilterButtons
          className="w-fit whitespace-nowrap"
          onClick={setTab}
          selectedItem={tab}
          items={Object.keys(tabs).map((key) => ({
            label: tabs[key],
            value: key,
          }))}
        />
      </div>
      {tab === 'answers' ? (
        <AnswersContextProvider>
          <div className="flex max-w-full justify-center">
            <div className="lg:min-h-[550px] lg:mb-[50px] transition duration-300">
              <div className="inline-block h-full backdrop-blur-[2px] bg-white rounded-[22px] p-6 z-[2] border border-focus-background">
                <Answers />
              </div>
            </div>
          </div>
        </AnswersContextProvider>
      ) : (
        <SisenseDashboard
          dashboard={dashboard}
          filters={[
            {
              disabled: false,
              isCascading: false,
              jaql: {
                column: 'article_id',
                datatype: 'numeric',
                dim: '[dim_articles.article_id]',
                table: 'dim_articles',
                title: 'article_id',
                filter: {
                  members: [article?.id.toString() ?? '1'],
                },
              },
            },
          ]}
          settings={{ showRightPane: false, showToolbar: false }}
          token={token}
        />
      )}
    </div>
  );
};

export default Dashboard;
