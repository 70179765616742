import { cx } from '@emotion/css';
import { useDispatch, useSelector } from 'app/hooks';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import useCanChangeArticleStructure from 'app/pages/Editor/hooks/useCanChangeArticleStructure';
import { actions, selectors } from 'app/store/editor';
import {
  Additem,
  ArrowDown,
  ArrowUp,
  Cd,
  FlashCircle,
  Icon as IconType,
  More,
  TickCircle,
  Trash,
} from 'iconsax-react';
import { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import ReactTooltip from 'react-tooltip';
import { TranslationStatusEnum } from 'submodules/common-ui/generated/api/gcs';

import TranslationStatusDropdownContainer from '../TranslationStatusDropdownContainer';

interface ContextualMenuProps {
  additionalOptions?: OptionProps[];
  readOptions?: OptionProps[];
  nodeId: string;
  showActions?: boolean;
  showTranslationStatus?: boolean;
  onDelete?: VoidFunction;
  onDuplicate?: VoidFunction;
  onMoveDown?: VoidFunction;
  onMoveUp?: VoidFunction;
}

const getTranslationStatusIcon = (status: TranslationStatusEnum) => {
  switch (status) {
    case 'approved':
      return <TickCircle className="text-success" size={20} variant="Bold" />;
    case 'draft':
      return <Cd className="text-warning" size={20} variant="Bold" />;
    case 'auto':
      return <FlashCircle className="text-ceil" size={20} variant="Bold" />;
  }
};

const ContextualMenu = ({
  additionalOptions,
  readOptions,
  nodeId,
  showActions = true,
  showTranslationStatus = true,
  onDelete,
  onDuplicate,
  onMoveDown,
  onMoveUp,
}: ContextualMenuProps) => {
  const dispatch = useDispatch();
  const { t } = useArticlesTranslation();

  const [showTranslationStatusDropdown, setShowTranslationStatusDropdown] =
    useState(false);
  const [showActionsDropdown, setShowActionsDropdown] = useState(false);

  const { canChangeArticleStructure } = useCanChangeArticleStructure();

  const selectedLanguages = useSelector(selectors.getSelectedLanguages);
  const selectedLanguage = selectedLanguages.find((sl) => sl.active);

  const translationStatuses = useSelector(selectors.getTranslationStatus);
  const translationStatus =
    selectedLanguage &&
    translationStatuses[nodeId] &&
    translationStatuses[nodeId][selectedLanguage.code]
      ? translationStatuses[nodeId][selectedLanguage.code]
      : 'draft';

  const commonOptions = [
    {
      button: 'Backspace',
      Icon: Trash,
      text: t('Delete'),
      onClick: () => onDelete?.(),
    },
    {
      Icon: Additem,
      text: t('Duplicate'),
      onClick: () => onDuplicate?.(),
    },
  ];

  const moveOptions = [
    {
      button: ArrowUp,
      Icon: ArrowUp,
      text: t('Move up'),
      onClick: () => onMoveUp?.(),
    },
    {
      button: ArrowDown,
      Icon: ArrowDown,
      text: t('Move down'),
      onClick: () => onMoveDown?.(),
    },
  ];

  return (
    <div className="py-1.5 px-2 flex justify-center items-center absolute -top-5 right-2 bg-white border border-gray-light rounded shadow-atobi z-10">
      {showTranslationStatus && (
        <button
          onClick={() => setShowTranslationStatusDropdown(true)}
          data-tip={t('Status')}
          data-for="status"
        >
          {getTranslationStatusIcon(translationStatus)}
          <ReactTooltip
            place="top"
            effect="solid"
            class="react-tooltip"
            id="status"
          />
        </button>
      )}
      {showTranslationStatus && showActions && (
        <div className="h-4 w-[1px] mx-2 bg-gray-light" />
      )}
      {(showActions || (readOptions && readOptions.length > 0)) && (
        <button onClick={() => setShowActionsDropdown(true)}>
          <More className="text-grayscale-secondary" size={20} />
        </button>
      )}
      {showTranslationStatusDropdown && selectedLanguage && (
        <OutsideClickHandler
          onOutsideClick={() => setShowTranslationStatusDropdown(false)}
        >
          <TranslationStatusDropdownContainer
            className="top-full right-[36px] xl:left-0 border border-gray-light"
            status={translationStatus}
            onSelect={(status) => {
              dispatch(
                actions.updateTranslationStatus({
                  [nodeId]: {
                    [selectedLanguage.code]: status,
                  },
                })
              );

              setShowTranslationStatusDropdown(false);
            }}
          />
        </OutsideClickHandler>
      )}
      {showActionsDropdown && (
        <OutsideClickHandler
          onOutsideClick={() => setShowActionsDropdown(false)}
        >
          <div
            className={cx(
              'absolute top-full right-0 w-[193px] text-grayscale-primary bg-white border border-gray-light rounded shadow-block overflow-hidden z-30',
              {
                'xl:left-0': !showTranslationStatus,
                'xl:left-[36px]': showTranslationStatus,
              }
            )}
          >
            {readOptions && readOptions.length > 0 && (
              <>
                {renderOptions(readOptions, true)}
                <div className="h-[1px] bg-gray-light" />
              </>
            )}
            {showActions && (
              <>
                {renderOptions(commonOptions)}
                <div className="h-[1px] bg-gray-light" />
              </>
            )}
            {showActions &&
              additionalOptions &&
              additionalOptions.length > 0 && (
                <>
                  {renderOptions(additionalOptions, true)}
                  <div className="h-[1px] bg-gray-light" />
                </>
              )}

            {showActions && renderOptions(moveOptions)}
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );

  function renderOptions(options: OptionProps[], isAdditional = false) {
    return options.map(({ onClick, disabled, ...restProps }, index) => (
      <Option
        key={index}
        onClick={() => {
          onClick?.();
          setShowActionsDropdown(false);
        }}
        disabled={
          isAdditional ? disabled : disabled || !canChangeArticleStructure
        }
        {...restProps}
      />
    ));
  }
};

export interface OptionProps {
  button?: IconType | string;
  disabled?: boolean;
  download?: boolean;
  Icon: IconType;
  text: string;
  url?: string | null;
  onClick?(): void;
}

export const Option = ({
  button,
  disabled,
  download,
  Icon,
  text,
  url,
  onClick,
}: OptionProps) => {
  const className = cx('flex items-center gap-3 w-full py-2 px-3 text-sm', {
    'hover:bg-hover-blue': !disabled,
    'cursor-default opacity-50': disabled,
  });
  const content = (
    <>
      <Icon className="text-grayscale-secondary" size={16} />
      {text}
      {button && (
        <span className="flex items-center justify-center h-6 min-w-6 ml-auto py-0.5 px-1 rounded-sm bg-gray-light">
          {typeof button === 'string' ? (
            button
          ) : (
            <Icon className="text-grayscale-secondary" size={16} />
          )}
        </span>
      )}
    </>
  );

  if (url) {
    return (
      <a
        className={className}
        href={url}
        rel="noreferrer"
        target="_blank"
        download={download}
      >
        <Icon className="text-grayscale-secondary" size={16} />
        {text}
      </a>
    );
  }

  return (
    <button className={className} disabled={disabled} onClick={onClick}>
      {content}
    </button>
  );
};

export default ContextualMenu;
