import { useArticlesTranslation } from 'app/internationalization/hooks';
import { FIRST_STEP } from 'app/utils/steps';
import CrossIcon from 'assets/icons/cross.svg?react';
import { ArrowLeft, Link2 } from 'iconsax-react';

interface ModalHeaderProps {
  step: number;
  previousStep: VoidFunction;
  onClose: VoidFunction;
}

const ModalHeader = ({ step, previousStep, onClose }: ModalHeaderProps) => {
  const { t } = useArticlesTranslation();

  return (
    <div className="flex flex-col h-full p-4">
      <div className="relative flex items-center text-grayscale-secondary">
        {step > FIRST_STEP && (
          <button onClick={previousStep} className="mr-2">
            <ArrowLeft size={16} />
          </button>
        )}
        <div className="flex gap-2 items-center text-black">
          {step === FIRST_STEP && <Link2 size={16} />}
          <span className="text-sm font-bold">
            {step === FIRST_STEP ? t('Share Article') : t('Forward externally')}
          </span>
        </div>
        <button className="absolute right-0 top-0" onClick={onClose}>
          <CrossIcon />
        </button>
      </div>
    </div>
  );
};

export default ModalHeader;
