import { cx } from '@emotion/css';
import { ComplaintTypes } from 'app/api/complaints/constants';
import { useScreenBreakpoint } from 'app/hooks';
import { isMobile } from 'app/utils';
import ReactIcon from 'assets/icons/react.svg?react';
import ReplyIcon from 'assets/icons/undo.svg?react';
import {
  ChartSquare,
  Trash as DeleteIcon,
  Edit2 as EditIcon,
} from 'iconsax-react';
import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import MoreIcon from 'remixicon-react/More2FillIcon';
import { EmojiEnum } from 'submodules/common-ui/generated/api/gcs';
import { Drawer } from 'vaul';

import ComplaintsPopup from '../ComplaintsPopup';
import ControlButton from '../ControlButton';
import { Emojis } from '../Reactions';
import Tooltip from '../Tooltip';

import DeleteModal from './DeleteModal';

interface CommentControlsProps {
  userName: string;
  commentId: number;
  userCommentId: number;
  createdByMe: boolean;
  isTranslated?: boolean;
  reactLabel?: string | null;
  replyLabel?: string | null;
  editLabel: string;
  deleteLabel: string;
  isLoading?: boolean;
  deleteModalTitle: string;
  deleteModalDescription?: string;
  hideLabel: string;
  hideDescription: string;
  complaintType: ComplaintTypes;
  showContextMenu?: boolean;
  contextMenuClassName?: string;
  engagementLabel?: string;
  onDelete(): void;
  onDisplayEditor(): void;
  onReact?(emoji: EmojiEnum): void;
  onReply?(): void;
  onTranslate?(): void;
  onViewEngagement?(): void;
}

const CommentControls: React.FC<CommentControlsProps> = ({
  userName,
  commentId,
  userCommentId,
  createdByMe,
  isTranslated,
  editLabel,
  deleteLabel,
  isLoading,
  deleteModalDescription,
  deleteModalTitle,
  complaintType,
  reactLabel,
  replyLabel,
  hideLabel,
  hideDescription,
  showContextMenu = true,
  contextMenuClassName,
  engagementLabel,
  onDelete,
  onDisplayEditor,
  onReact,
  onReply,
  onTranslate,
  onViewEngagement,
}) => {
  const [openReactions, setOpenReactions] = useState(false);
  const [shouldDisplayMore, setShouldDisplayMore] = useState(false);
  const [shouldDisplayDeleteModal, setShouldDisplayDeleteModal] =
    useState(false);

  const breakpoint = useScreenBreakpoint();
  const mobile = isMobile(breakpoint);

  const iconClassName = showContextMenu
    ? 'w-6 h-6 mr-4'
    : 'w-[18px] h-[18px] text-grayscale-secondary';

  const renderAddReactionButton = () => (
    <Tooltip content={reactLabel} show={!showContextMenu}>
      <ControlButton
        className="rounded-sm"
        onClick={() => {
          setOpenReactions((prev) => !prev);
        }}
      >
        <ReactIcon className={iconClassName} />
        {showContextMenu && (
          <span className="whitespace-nowrap">{reactLabel}</span>
        )}
      </ControlButton>
    </Tooltip>
  );

  if (createdByMe) {
    return (
      <OutsideClickHandler onOutsideClick={() => setShouldDisplayMore(false)}>
        {shouldDisplayDeleteModal && (
          <DeleteModal
            title={deleteModalTitle}
            description={deleteModalDescription}
            onDelete={onDelete}
            onClose={() => setShouldDisplayDeleteModal(false)}
          />
        )}
        {showContextMenu && (
          <button
            onClick={() => setShouldDisplayMore((prev) => !prev)}
            type="button"
            className={contextMenuClassName}
          >
            <MoreIcon />
          </button>
        )}
        <div className="relative">
          {(shouldDisplayMore || !showContextMenu) && (
            <div
              className={cx(
                'absolute z-10 text-sm bg-light right-0 shadow-atobi rounded-[8px] translate-x-3 py-1 px-2 border border-focus-background gap-1.5',
                {
                  flex: !showContextMenu,
                }
              )}
            >
              {onReact && (
                <>
                  {mobile ? (
                    <Drawer.Root
                      open={openReactions}
                      onOpenChange={setOpenReactions}
                    >
                      <Drawer.Trigger asChild>
                        {renderAddReactionButton()}
                      </Drawer.Trigger>
                      <Drawer.Portal>
                        <Drawer.Overlay
                          className="fixed inset-0 bg-grayscale-primary/20 z-50"
                          onClick={() => setOpenReactions(false)}
                        />
                        <Drawer.Content className="fixed bottom-0 left-0 right-0 flex flex-col outline-none z-50">
                          <Emojis
                            onClick={(emoji) => {
                              onReact(emoji);
                              setOpenReactions(false);
                            }}
                          />
                        </Drawer.Content>
                      </Drawer.Portal>
                    </Drawer.Root>
                  ) : (
                    <OutsideClickHandler
                      onOutsideClick={() => setOpenReactions(false)}
                    >
                      {openReactions && (
                        <div className="absolute -top-2 -right-2">
                          <Emojis
                            onClick={(emoji) => {
                              onReact(emoji);
                              setOpenReactions(false);
                            }}
                          />
                        </div>
                      )}
                      {renderAddReactionButton()}
                    </OutsideClickHandler>
                  )}
                </>
              )}
              {onReply && (
                <Tooltip content={replyLabel} show={!showContextMenu}>
                  <ControlButton
                    disabled={isLoading}
                    className="rounded-sm"
                    onClick={onReply}
                  >
                    <ReplyIcon className={iconClassName} />
                    {showContextMenu && (
                      <span className="whitespace-nowrap">{replyLabel}</span>
                    )}
                  </ControlButton>
                </Tooltip>
              )}
              <Tooltip content={editLabel} show={!showContextMenu}>
                <ControlButton
                  disabled={isLoading}
                  className="rounded-sm"
                  onClick={onDisplayEditor}
                >
                  <EditIcon className={iconClassName} />
                  {showContextMenu && (
                    <span className="whitespace-nowrap">{editLabel}</span>
                  )}
                </ControlButton>
              </Tooltip>
              {onViewEngagement && (
                <Tooltip content={engagementLabel} show={!showContextMenu}>
                  <ControlButton
                    disabled={isLoading}
                    className="rounded-sm"
                    onClick={onViewEngagement}
                  >
                    <ChartSquare className={iconClassName} />
                    {showContextMenu && (
                      <span className="whitespace-nowrap">
                        {engagementLabel}
                      </span>
                    )}
                  </ControlButton>
                </Tooltip>
              )}
              <Tooltip content={deleteLabel} show={!showContextMenu}>
                <ControlButton
                  disabled={isLoading}
                  onClick={() => {
                    setShouldDisplayDeleteModal(true);
                  }}
                  className="rounded-sm"
                >
                  <DeleteIcon className={iconClassName} />
                  {showContextMenu && (
                    <span className="whitespace-nowrap">{deleteLabel}</span>
                  )}
                </ControlButton>
              </Tooltip>
            </div>
          )}
        </div>
      </OutsideClickHandler>
    );
  }

  return (
    <ComplaintsPopup
      hideDescription={hideDescription}
      hideLabel={hideLabel}
      creatorName={userName}
      creatorUserId={userCommentId}
      complaintObjectId={commentId}
      complaintType={complaintType}
      isLoading={isLoading}
      isTranslated={isTranslated}
      reactLabel={reactLabel}
      replyLabel={replyLabel}
      showContextMenu={showContextMenu}
      contextMenuClassName={contextMenuClassName}
      onReact={onReact}
      onReply={onReply}
      onTranslate={onTranslate}
    />
  );
};

export default CommentControls;
