import { useNode } from '@craftjs/core';
import { useScreenBreakpoint } from 'app/hooks';
import useIsEditorEnabled from 'app/hooks/useIsEditorEnabled';
import BlockTypes from 'app/pages/Editor/helpers/constants';
import { selectors } from 'app/store/editor';
import { isMobile } from 'app/utils';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  ActionSchedule,
  GetCategory,
  GetChoiceSchema,
  GetUserAnswers,
  MultiChoiceBlockRenderTypeEnum,
  MultiChoiceBlockTypeEnum,
} from 'submodules/common-ui/generated/api/gcs';
import { Drawer } from 'vaul';

import BaseBlockContainer from '../../../BaseBlockContainer';
import ActionsSettings from '../components/ActionSettings';
import { CurrentInstanceProvider } from '../components/ActionSettings/context';

import EditorUserMode from './EditorUserMode';
import EndUserMode from './EndUserMode';

export interface QuizBlockProps {
  articleId?: number;
  deadline?: string;
  description?: string;
  title?: string;
  explanation?: string;
  minCorrectAnswers?: number;
  choices?: GetChoiceSchema[];
  categories?: GetCategory[];
  mandatory?: boolean;
  required: number;
  public: boolean;
  audiences: number[];
  type: MultiChoiceBlockTypeEnum;
  translationStatus: string;
  currentUserAnswers?: GetUserAnswers;
  completed: boolean;
  renderType: typeof MultiChoiceBlockRenderTypeEnum.Quiz;
  isSkeleton?: boolean;
  isLocked?: boolean;
  schedule?: ActionSchedule | null;
}

const QuizBlock = (quiz: QuizBlockProps) => {
  const { mode } = useParams<{ mode: string }>();
  const { enabled } = useIsEditorEnabled();
  const [hasFocus, setHasFocus] = useState(false);
  const [backspace, setBackspace] = useState<boolean>(false);

  const choiceHasFocus = useSelector(selectors.getChoiceHasFocus);

  const {
    actions: { setProp },
    connectors: { connect },
    id,
    selected,
  } = useNode((node) => ({
    selected: node.events.selected,
  }));

  const breakpoint = useScreenBreakpoint();
  const mobile = isMobile(breakpoint);

  const renderEndUserMode = () => {
    if (mobile && !quiz.isLocked)
      return (
        <Drawer.Trigger asChild>
          <div>
            <EndUserMode />
          </div>
        </Drawer.Trigger>
      );

    return <EndUserMode />;
  };

  return (
    <div ref={(ref) => ref && connect(ref)}>
      <BaseBlockContainer
        deleteOnBackspace={
          enabled && !hasFocus && !choiceHasFocus && !backspace
        }
        nodeId={id}
        selected={selected}
        type="MultiTask"
      >
        {mode === 'actions' ? (
          <CurrentInstanceProvider actionId={id}>
            {renderEndUserMode()}
          </CurrentInstanceProvider>
        ) : (
          <EditorUserMode
            setBackspace={setBackspace}
            setHasFocus={setHasFocus}
            setProp={setProp}
          />
        )}
      </BaseBlockContainer>
    </div>
  );
};

QuizBlock.craft = {
  displayName: BlockTypes.QuizBlock,
  related: {
    settings: ActionsSettings,
  },
};

export default QuizBlock;
