import { Button, Modal } from 'app/components';
import { useScreenBreakpoint } from 'app/hooks';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { isMobile } from 'app/utils';
import CrossIcon from 'assets/icons/cross.svg?react';

interface OverwriteModalProps {
  onClose: () => void;
  overwrite?: () => void;
}

const OverwriteModal = ({ onClose }: OverwriteModalProps) => {
  const { t } = useArticlesTranslation();
  const breakpoint = useScreenBreakpoint();
  const _isMobile = isMobile(breakpoint);

  return (
    <Modal onClose={onClose} width={_isMobile ? 'sm' : 'md'} className="p-6">
      <div className="flex items-center mb-4 justify-between">
        <p className="text-grayscale-primary text-lg font-bold">
          {t('Article conflict')}
        </p>
        <button
          className="text-grayscale-secondary flex items-center justify-center w-10 h-10"
          onClick={onClose}
        >
          <CrossIcon />
        </button>
      </div>

      <p className="text-grayscale-primary text-base">
        {t(
          'Another member has updated this article and your copy is no longer the latest. Updates have not been saved and we recommend copying your changes before you refresh the page to update the article.'
        )}
      </p>

      <div className="flex mt-4 justify-center items-center">
        <Button variant="secondary" className="w-3/6 h-12" onClick={onClose}>
          {t('Cancel')}
        </Button>
      </div>
    </Modal>
  );
};

export default OverwriteModal;
