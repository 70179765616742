import config from 'app/config';
import { store } from 'app/store';
import { resetState } from 'app/store/utils';
import { AxiosError } from 'axios';

import { createInstance } from './request';

export const BASE_CONFIG = {
  apiUrl: config.env.gcsApiUrl,
};

interface SetRequestHeadersData {
  token?: string;
}

let requestInstance = createInstance(BASE_CONFIG);

const setRequestHeaders = ({ token }: SetRequestHeadersData) => {
  requestInstance = createInstance({
    ...BASE_CONFIG,
    token,
  });

  requestInstance.defaults.withCredentials = true;

  requestInstance.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      const isTokenRefreshing = store.getState().config.isTokenRefreshing;
      if (error?.response?.status === 401 && !isTokenRefreshing) {
        resetState();
      }

      return Promise.reject(error);
    }
  );
};

const request = () => requestInstance;

const reset = () => {
  requestInstance = createInstance(BASE_CONFIG);
};

export { setRequestHeaders, request, reset };
