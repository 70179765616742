import { ReactNode } from 'react';

const SimpleToast = ({
  text,
  Icon,
}: {
  text: ReactNode;
  Icon?: JSX.Element;
}) => {
  return (
    <div className="flex items-center h-full rounded-lg border border-transparent px-4">
      {Icon}
      <span className="text-sm text-white font-custom">{text}</span>
    </div>
  );
};

export default SimpleToast;
