import { RootState } from '../types';

import { NAME } from './constants';

export const getToken = (state: RootState) => state[NAME].token;
export const getExpiry = (state: RootState) => state[NAME].expiresAt;
export const getSelectedTenant = (state: RootState) =>
  state[NAME].selectedTenant;
export const getTranslationLanguage = (state: RootState) =>
  state[NAME].translationLanguage;
export const getTenants = (state: RootState) => state[NAME].tenants;
export const getModuleAccessPermissions = (state: RootState) =>
  state[NAME].moduleAccessPermissions;
export const getRefreshToken = (state: RootState) => state[NAME].refreshToken;
export const isTokenRefreshing = (state: RootState) =>
  state[NAME].isTokenRefreshing;
