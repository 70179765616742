import useGetArticleBlock from 'app/api/articles/hooks/useGetArticleBlock';
import useAuthenticatedUser from 'app/api/auth/hooks/useAuthenticatedUser';
import { selectors } from 'app/store/editor';
import React, { createContext, useState, useContext, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import {
  ActionAnswerResponse,
  ActionInstance,
  Article,
  GetUserAnswer,
} from 'submodules/common-ui/generated/api/gcs';

interface CurrentInstanceContextValues {
  currentSelectedInstanceId: number | undefined;
  setCurrentSelectedInstanceId: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  block: any;
  refetch: () => void;
  currentInstanceId: number | undefined;
  currentInstanceAnswer: ActionAnswerResponse | undefined;
  colleagueAnswer?: ActionAnswerResponse;
  currentSelectedInstance: ActionInstance | undefined;
  isActionCompleted: boolean;
  answeredByColleague: boolean;
  colleagueId?: number;
  article?: Article;
}

const uninitializedFunction = () => {
  throw new Error('Uninitialized Function');
};

const CurrentInstanceContext = createContext<CurrentInstanceContextValues>({
  currentSelectedInstanceId: undefined,
  setCurrentSelectedInstanceId: uninitializedFunction,
  block: null,
  refetch: uninitializedFunction,
  currentInstanceId: undefined,
  currentInstanceAnswer: undefined,
  currentSelectedInstance: undefined,
  isActionCompleted: false,
  answeredByColleague: false,
  colleagueId: undefined,
  article: undefined,
});

interface CurrentInstanceProviderProps {
  children: ReactNode;
  actionId: string;
}

export const CurrentInstanceProvider: React.FC<
  CurrentInstanceProviderProps
> = ({ children, actionId }) => {
  const [currentSelectedInstanceId, setCurrentSelectedInstanceId] = useState<
    number | undefined
  >(undefined);
  const article = useSelector(selectors.getArticle);
  const { data: user } = useAuthenticatedUser();

  const { block, refetch } = useGetArticleBlock({
    articleId: article?.id as number,
    blockId: parseInt(actionId),
    enabled: true,
    timezone: user?.timezone,
    live: '1',
  });

  const currentInstanceId =
    currentSelectedInstanceId ?? block?.currentInstanceId;

  const currentUserAnswersList = block?.currentUserAnswers || [];
  const currentInstanceAnswer = currentUserAnswersList.find(
    (currentUserAnswer: ActionAnswerResponse) =>
      currentUserAnswer.actionInstanceId === currentInstanceId
  );

  const isActionCompleted =
    !!currentInstanceAnswer && currentInstanceAnswer.revertedAt === null;

  const currentSelectedInstance = block?.instances?.find(
    (instance: ActionInstance) => instance.id === currentInstanceId
  );

  const colleagueAnswer = block?.answers?.find(
    (answer: GetUserAnswer) =>
      answer.actionInstanceId === currentInstanceId &&
      answer.completedBy !== user?.id &&
      answer.revertedAt === null
  );

  const answeredByColleague = !isActionCompleted && !!colleagueAnswer;
  const colleagueId = colleagueAnswer?.completedBy;

  const contextValue: CurrentInstanceContextValues = {
    currentSelectedInstanceId,
    setCurrentSelectedInstanceId,
    block,
    refetch,
    currentInstanceId,
    currentInstanceAnswer,
    colleagueAnswer,
    currentSelectedInstance,
    isActionCompleted,
    answeredByColleague,
    colleagueId,
    article,
  };

  return (
    <CurrentInstanceContext.Provider value={contextValue}>
      {children}
    </CurrentInstanceContext.Provider>
  );
};

export const useCurrentSelectedInstance = (): CurrentInstanceContextValues => {
  const context = useContext(CurrentInstanceContext);
  if (context === undefined) {
    throw new Error(
      'useCurrentSelectedInstance must be used within a CurrentInstanceProvider'
    );
  }
  return context;
};
