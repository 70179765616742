import { useEditor, useNode } from '@craftjs/core';
import { cx } from '@emotion/css';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import useOpenVertically from 'app/pages/ArticleEditor/hooks/useOpenVertically';
import BlockTypes from 'app/pages/Editor/helpers/constants';
import YesNoIcon from 'assets/icons/yes-no.svg?react';
import {
  Chart2,
  Gallery,
  MessageQuestion,
  MessageText1,
  Teacher,
  TickSquare,
} from 'iconsax-react';
import { useRef, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import CheckLineIcon from 'remixicon-react/CheckLineIcon';
import {
  GetYesNoTaskBlockV1TypeEnum,
  ActionTypeEnum,
  MultiChoiceBlockRenderTypeEnum,
} from 'submodules/common-ui/generated/api/gcs';

import PollBlock from '../../Poll';
import QuizBlock from '../../Quiz';
import ScormBlock from '../../ScormBlock';
import SimpleTask from '../../SimpleTask';
import YesNoNaBlock from '../../YesNoNa';

interface TaskTypeProps {
  disabled?: boolean;
  type: ActionTypeEnum;
  renderType?: MultiChoiceBlockRenderTypeEnum;
  onClick?: (type: ActionTypeEnum) => void;
}

const TaskType = ({ disabled, type, renderType, onClick }: TaskTypeProps) => {
  const { t } = useArticlesTranslation();

  const [open, setOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useOpenVertically(dropdownRef, open);

  const {
    actions: { addNodeTree, delete: deleteNode },
    query: { parseReactElement },
  } = useEditor();

  const { nodeId, displayName } = useNode((node) => ({
    nodeId: node.id,
    displayName: node.data.displayName,
  }));

  const optionClassName = 'rounded-b-lg';

  const options = [
    {
      taskType: ActionTypeEnum.SimpleTask,
      name: 'Task',
      text: t('Confirm and track if something is done'),
      Icon: TickSquare,
      className: '',
      click: () => toSimpleTask(ActionTypeEnum.SimpleTask),
    },
    {
      taskType: ActionTypeEnum.MediaTask,
      name: 'Media Task',
      text: t('Get visual confirmation of how it looks'),
      Icon: Gallery,
      className: optionClassName,
      click: () => toSimpleTask(ActionTypeEnum.MediaTask),
    },
    {
      taskType: ActionTypeEnum.MultiChoice,
      multiRenderType: MultiChoiceBlockRenderTypeEnum.Quiz,
      name: 'Quiz',
      text: t(
        'Test people’s knowledge with single or multiple-choice quizzes.'
      ),
      Icon: MessageQuestion,
      className: optionClassName,
      click: () => toQuiz(),
    },
    {
      taskType: ActionTypeEnum.OpenQuestionTask,
      name: 'Open Question',
      text: t('Get open answer for the question'),
      Icon: MessageText1,
      className: optionClassName,
      click: () => toSimpleTask(ActionTypeEnum.OpenQuestionTask),
    },
    {
      taskType: ActionTypeEnum.MultiChoice,
      multiRenderType: MultiChoiceBlockRenderTypeEnum.YesNoNa,
      name: 'Yes/No Question',
      text: t('Ask question with Yes/No answer'),
      Icon: YesNoIcon,
      className: optionClassName,
      click: () => toYesNoNa(),
    },
    {
      taskType: ActionTypeEnum.MultiChoice,
      multiRenderType: MultiChoiceBlockRenderTypeEnum.Poll,
      name: 'Poll',
      text: t('Gather opinions and insights by creating a survey.'),
      Icon: Chart2,
      className: optionClassName,
      click: () => toPoll(),
    },
    {
      taskType: ActionTypeEnum.ScormTask,
      name: 'Scorm Package',
      text: t('Add a Scorm Package and track member’s completion'),
      Icon: Teacher,
      className: optionClassName,
      click: () => toScorm(),
    },
  ];

  const toggleDropdown = () => {
    if (disabled) return;
    setOpen((prev) => !prev);
  };

  const renderIcon = () => {
    const className = 'text-ceil mr-3';
    const size = 20;

    switch (type) {
      case ActionTypeEnum.SimpleTask:
        return (
          <TickSquare
            size={size}
            className={className}
            onClick={toggleDropdown}
          />
        );
      case ActionTypeEnum.MediaTask:
        return (
          <Gallery size={size} className={className} onClick={toggleDropdown} />
        );
      case ActionTypeEnum.OpenQuestionTask:
        return (
          <MessageText1
            size={size}
            className={className}
            onClick={toggleDropdown}
          />
        );
      case GetYesNoTaskBlockV1TypeEnum.YesNoTask:
        return (
          <YesNoIcon
            height={size}
            width={size}
            className={className}
            onClick={toggleDropdown}
          />
        );
      case ActionTypeEnum.ScormTask:
        return (
          <Teacher size={size} className={className} onClick={toggleDropdown} />
        );
      case ActionTypeEnum.MultiChoice: {
        if (renderType === MultiChoiceBlockRenderTypeEnum.Poll) {
          return (
            <Chart2
              size={size}
              className={className}
              onClick={toggleDropdown}
            />
          );
        }

        if (renderType === MultiChoiceBlockRenderTypeEnum.YesNoNa) {
          return (
            <YesNoIcon
              height={size}
              width={size}
              className={className}
              onClick={toggleDropdown}
            />
          );
        }

        return (
          <MessageQuestion
            size={size}
            className={className}
            onClick={toggleDropdown}
          />
        );
      }
    }
  };

  const toQuiz = () => {
    if (displayName === BlockTypes.QuizBlock) return;

    addNodeTree(
      parseReactElement(
        <QuizBlock
          type={ActionTypeEnum.MultiChoice}
          required={-1}
          public={true}
          completed={false}
          audiences={[]}
          translationStatus="draft"
          renderType={MultiChoiceBlockRenderTypeEnum.Quiz}
        />
      ).toNodeTree(),
      'dropableRegion'
    );

    onClick?.(ActionTypeEnum.MultiChoice);
    deleteNode(nodeId);
  };

  const toYesNoNa = () => {
    if (displayName === BlockTypes.YesNoNaBlock) return;

    addNodeTree(
      parseReactElement(
        <YesNoNaBlock
          type={ActionTypeEnum.MultiChoice}
          required={-1}
          public={true}
          completed={false}
          audiences={[]}
          translationStatus="draft"
          renderType={MultiChoiceBlockRenderTypeEnum.YesNoNa}
        />
      ).toNodeTree(),
      'dropableRegion'
    );

    onClick?.(ActionTypeEnum.MultiChoice);
    deleteNode(nodeId);
  };

  const toPoll = () => {
    if (displayName === BlockTypes.PollBlock) return;

    addNodeTree(
      parseReactElement(
        <PollBlock
          type={ActionTypeEnum.MultiChoice}
          required={-1}
          public={true}
          completed={false}
          audiences={[]}
          translationStatus="draft"
          renderType={MultiChoiceBlockRenderTypeEnum.Poll}
        />
      ).toNodeTree(),
      'dropableRegion'
    );

    onClick?.(ActionTypeEnum.MultiChoice);
    deleteNode(nodeId);
  };

  const toSimpleTask = (taskType: ActionTypeEnum) => {
    if (
      displayName === BlockTypes.QuizBlock ||
      displayName === BlockTypes.PollBlock
    ) {
      addNodeTree(
        parseReactElement(
          <SimpleTask
            type={taskType}
            required={-1}
            public={true}
            completed={false}
            audiences={[]}
            translationStatus="draft"
          />
        ).toNodeTree(),
        'dropableRegion'
      );

      deleteNode(nodeId);

      return;
    }

    if (taskType === type) return;

    onClick?.(taskType);
  };

  const toScorm = () => {
    if (displayName === BlockTypes.ScormBlock) return;

    addNodeTree(
      parseReactElement(
        <ScormBlock
          type={ActionTypeEnum.ScormTask}
          required={-1}
          public={true}
          completed={false}
          audiences={[]}
          translationStatus="draft"
        />
      ).toNodeTree(),
      'dropableRegion'
    );

    onClick?.(ActionTypeEnum.ScormTask);
    deleteNode(nodeId);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
      <div className="relative">
        {renderIcon()}
        {open && (
          <div
            ref={dropdownRef}
            className="absolute bg-white rounded-lg z-50 shadow-lg m-0 bg-clip-padding left-2 top-6 transform -translate-y-2 shadow-atobi p3"
          >
            <div className="flex items-center h-10 pl-4">
              <span className="text-xs text-black font-bold">
                {t('Actions')}
              </span>
            </div>
            {options.map((opt) => {
              const { Icon, click } = opt;
              return (
                <button
                  key={opt.text}
                  className={cx(
                    'text-sm w-full px-3 py-2 font-normal flex items-center whitespace-nowrap bg-transparent hover:bg-hover-blue text-black',
                    opt.className
                  )}
                  onClick={() => click()}
                >
                  <Icon size={20} className="text-ceil mr-3" />

                  <div className="flex flex-col mr-2 items-start">
                    <span className="text-black">{opt.name}</span>
                    <span className="text-grayscale-secondary">{opt.text}</span>
                  </div>

                  <CheckLineIcon
                    size={20}
                    className={cx('text-focus ml-auto', {
                      invisible:
                        opt.taskType === 'multi_choice'
                          ? renderType !== opt.multiRenderType
                          : type !== opt.taskType,
                    })}
                  />
                </button>
              );
            })}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default TaskType;
