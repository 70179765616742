import { CommentKey } from '../types';

import useBaseTranslation from './useBaseTranslation';

const useCommentTranslation = () =>
  useBaseTranslation<CommentKey>(undefined, {
    keyPrefix: 'comment',
  });

export default useCommentTranslation;
