import useGetArticle from 'app/api/articles/hooks/useGetArticle';
import { useAuthenticatedUser } from 'app/api/auth/hooks';
import { editorTypes } from 'app/router/constants';
import { actions } from 'app/store/editor';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const useArticle = () => {
  const { id: articleId, mode } = useParams<{ id?: string; mode: string }>();
  const { data: user } = useAuthenticatedUser();
  const dispatch = useDispatch();

  const allowFilter = mode === editorTypes.actions;

  const { article, isLoading, apiError, isFetching, refetch } = useGetArticle({
    id: Number(articleId),
    enabled: articleId !== undefined,
    staleTime: mode !== editorTypes.edit,
    refetchOnWindowFocus: mode !== editorTypes.edit,
    filter: allowFilter
      ? {
          timezone: user?.timezone,
          language: user?.contentLanguage.uiLanguage,
        }
      : {
          ...(mode === editorTypes.edit && { include: 'audiences' }),
        },
  });

  useEffect(() => {
    if (article) dispatch(actions.setArticle(article));
  }, [article, dispatch]);

  return {
    article,
    isLoading,
    isFetching,
    apiError,
    refetch,
  };
};

export default useArticle;
