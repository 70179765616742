import { cx } from '@emotion/css';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import DatePickerTimeInput from 'app/pages/ArticleEditor/components/blocks/tasks/components/Deadline/DatePickerTimeInput';
import removeTimezone from 'app/utils/removeTimezone';
import dayjs from 'dayjs';
import { ArrowLeft, ArrowRight, Trash } from 'iconsax-react';
import { FC } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import DatePickerInput from './DatePickerInput';

interface Props {
  selected?: string | null;
  onChange: (date: string | null) => void;
  disabled?: boolean;
  publishDate?: string | null;
  customInputText?: string;
  inputClassName?: string;
}

const Datepicker: FC<Props> = ({
  selected,
  onChange,
  disabled,
  publishDate,
  customInputText,
  inputClassName,
}) => {
  const { t } = useArticlesTranslation();

  const selectedDate = dayjs(removeTimezone(selected));
  const currentDate = dayjs().toDate();

  const value = selected ? selectedDate.toDate() : currentDate;

  const customPickerDate = selected
    ? selectedDate.format('YYYY-MM-DD HH:00')
    : undefined;

  const minDate = publishDate
    ? dayjs(removeTimezone(publishDate)).toDate()
    : undefined;

  return (
    <ReactDatePicker
      selected={value}
      minDate={minDate ?? currentDate}
      disabled={disabled}
      showPopperArrow={false}
      shouldCloseOnSelect={false}
      onChange={(date) => {
        const apiDate = removeTimezone(date);
        const now = removeTimezone(currentDate);

        const isSame =
          dayjs(apiDate).isSame(now, 'day') &&
          dayjs(apiDate).isSame(now, 'month');

        if (minDate && isSame)
          return onChange(dayjs(now).add(1, 'hour').toISOString() ?? null);

        onChange(apiDate ?? null);
      }}
      showTimeInput
      monthsShown={2}
      timeInputLabel=""
      customTimeInput={
        <DatePickerTimeInput date={selected} minDate={minDate} />
      }
      customInput={
        <DatePickerInput
          date={customPickerDate}
          text={customInputText || `${t('Add dates')}`}
          clearDate={() => onChange(null)}
          disabled={disabled}
          className={inputClassName}
        />
      }
      renderCustomHeader={({
        monthDate,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
        customHeaderCount,
      }) => (
        <div className="flex justify-between bg-white px-5">
          <button
            onClick={(e) => {
              e.preventDefault();
              decreaseMonth();
            }}
            disabled={prevMonthButtonDisabled}
            className={cx({
              hidden: customHeaderCount === 1,
            })}
          >
            <ArrowLeft size={14} />
          </button>
          <div className="flex">
            <span>{dayjs(monthDate).format('MMMM')}</span>
            &nbsp;
            <span>{dayjs(monthDate).format('YYYY')}</span>
          </div>

          <button
            onClick={(e) => {
              e.preventDefault();
              increaseMonth();
            }}
            disabled={nextMonthButtonDisabled}
            className={cx({
              hidden: customHeaderCount === 0,
            })}
          >
            <ArrowRight size={14} />
          </button>
        </div>
      )}
    >
      {selected && (
        <button
          className="flex items-center h-10 w-full justify-center hover:bg-hover-blue text-error text-sm font-lato rounded-sm"
          onClick={() => onChange(null)}
          type="button"
        >
          <Trash size={20} className="mx-2" />
          <span>{t('Remove Schedule')}</span>
        </button>
      )}
    </ReactDatePicker>
  );
};

export default Datepicker;
