import { Node } from '@craftjs/core/lib/interfaces/nodes';
import { selectors } from 'app/store/editor';
import {
  Article,
  ArticleVariant,
  CoverImageFile,
  MultiChoiceBlock,
  UpdatedArticle,
  UpdatedBlock,
} from 'common-ui/generated/api/gcs';
import { useSelector } from 'react-redux';

import BlockTypes from '../helpers/constants';
import {
  mapArticleLinkBlock,
  mapFileBlock,
  mapGiphyBlock,
  mapImageBlock,
  mapPdfBlock,
  mapPollBlock,
  mapQuizBlock,
  mapScormBlock,
  mapTaskBlock,
  mapTextBlock,
  mapUrlBlock,
  mapVideoBlock,
  mapYesNoNaBlock,
} from '../helpers/craftToApi';
import mapDraggableBlock from '../helpers/craftToApi/draggableBlock';
import { Props } from '../helpers/craftToApi/types';

import useRecurringDateAsSchedule from './useRecurringDateAsSchedule';

const useMapUpdateArticle = (nodes: Record<string, Node>) => {
  const selectedLanguages = useSelector(selectors.getSelectedLanguages);
  const selectedAudiences = useSelector(selectors.getSelectedAudiences);
  const selectedCollaborators = useSelector(selectors.getSelectedCollaborators);
  const selectedChannel = useSelector(selectors.getSelectedChannel);
  const selectedCategories = useSelector(selectors.getSelectedCategories);
  const selectedLanguage = useSelector(selectors.getActiveLanguage);
  const isReactingAllowed = useSelector(selectors.getAllowComments);
  const mainLanguage =
    selectedLanguages.find((sl) => sl.isDefault)?.code ?? 'en';
  const translationStatuses = useSelector(selectors.getTranslationStatus);
  const recurringDate = useSelector(selectors.getRecurringDate);
  const shownAs = useSelector(selectors.getShownAs);
  const answerSharing = useSelector(selectors.getAnswerSharing);
  const { getScheduleDate } = useRecurringDateAsSchedule();

  const scheduleDate = getScheduleDate(recurringDate);

  const getScheduleValue = (block: Node) =>
    scheduleDate ? scheduleDate : block.data.props.schedule ?? null;

  const getDeadlineValue = (block: Node) =>
    scheduleDate
      ? null
      : block.data.props.schedule
        ? null
        : block.data.props.deadline ?? null;

  const nodesToUpdateArticle = (article: Article): UpdatedArticle => {
    const blocks: UpdatedBlock[] = getBlocks(article);

    const coverImage = nodes['coverImage'].data.props.imageSchema;
    const title = nodes['title'].data.props.text;

    const variants = {
      ...article.variants,
      ...buildOuterVariantObject(title, coverImage),
    };

    Object.keys(variants).forEach((language) => {
      variants[language] = {
        ...variants[language],
        ...{
          translationStatus:
            translationStatuses['title'][language] ??
            variants[language].translationStatus,
        },
      };
    });

    const audiences =
      selectedAudiences.length > 0
        ? selectedAudiences.map((sa) => sa.id)
        : article.audiences;

    const updatedArticle: UpdatedArticle = {
      id: article.id,
      channelId: selectedChannel?.id ?? null,
      variants,
      blocks,
      status: article.status,
      publishAt: article.publishAt,
      archiveAt: article.archiveAt,
      updatedAt: article.updatedAt,
      audiences,
      languages: selectedLanguages.map((sl) => {
        return { language: sl.code, isDefault: sl.isDefault };
      }),
      users: selectedCollaborators.map((sc) => {
        return {
          id: sc.id,
          languages: sc.languages,
        };
      }),
      categories:
        selectedCategories?.map((sc) => {
          return sc.id;
        }) ?? [],
      isReactingAllowed,
      publishedInstantly: article.publishedInstantly,
      isTemplate: article.isTemplate,
      objectId: article.objectId,
      objectType: article.objectType,
      shownAs: shownAs.value,
      answerSharing: answerSharing ?? article.answerSharing,
    };

    return syncVariants(updatedArticle);
  };

  const getBlocks = (article: Article): UpdatedBlock[] => {
    const blockIds = nodes['dropableRegion'].data.nodes;

    const blocks: Array<UpdatedBlock | null> = [];

    blockIds.forEach((id, index) => {
      const blockId = article.blocks.find((b) => b.id.toString() === id)?.id;
      const block = nodes[id];
      const params: Props = {
        block,
        index,
        article,
        selectedLanguage,
        blockId,
        translationStatuses,
        deadline: getDeadlineValue(block),
        schedule: getScheduleValue(block),
        nodes,
      };
      switch (block.data.displayName) {
        case BlockTypes.ArticleLinkBlock:
          blocks.push(mapArticleLinkBlock(params));
          break;
        case BlockTypes.TextBlock: {
          blocks.push(mapTextBlock(params));
          const dragId = params.block.data.linkedNodes['draggableContainer'];
          if (dragId) blocks.push(buildDraggableContainer(params, dragId));
          break;
        }
        case BlockTypes.UrlBlock:
          blocks.push(mapUrlBlock(params));
          break;
        case BlockTypes.ImageBlock:
          blocks.push(mapImageBlock(params));
          break;
        case BlockTypes.VideoBlock:
          blocks.push(mapVideoBlock(params));
          break;
        case BlockTypes.GiphyBlock:
          blocks.push(mapGiphyBlock(params));
          break;
        case BlockTypes.PDFBlock:
          blocks.push(mapPdfBlock(params));
          break;
        case BlockTypes.FileBlock:
          blocks.push(mapFileBlock(params));
          break;
        case BlockTypes.SimpleTaskBlock:
          blocks.push(mapTaskBlock(params));
          break;
        case BlockTypes.QuizBlock:
          blocks.push(mapQuizBlock(params));
          break;
        case BlockTypes.YesNoNaBlock:
          blocks.push(mapYesNoNaBlock(params));
          break;
        case BlockTypes.PollBlock:
          blocks.push(mapPollBlock(params));
          break;
        case BlockTypes.ScormBlock:
          blocks.push(mapScormBlock(params));
          break;
        default:
          break;
      }
    });

    return blocks.flatMap((block) => (block ? [block] : []));
  };

  const buildDraggableContainer = (params: Props, dragId: string) =>
    mapDraggableBlock({
      ...params,
      block: nodes[dragId],
      blockId: params.article.blocks.find(
        (block) => block.id.toString() === dragId
      )?.id,
    });

  const buildOuterVariantObject = (
    title: string,
    coverImage: CoverImageFile
  ): { [key: string]: ArticleVariant } => {
    return {
      [selectedLanguage]: {
        title,
        coverImage,
        translationStatus: 'draft',
      },
    };
  };

  const syncVariants = (updatedArticle: UpdatedArticle): UpdatedArticle => {
    if (!mainLanguage) return updatedArticle;

    const selectedLanguageCodes = selectedLanguages
      .map((sl) => sl.code)
      .filter((code) => code !== mainLanguage);

    let withSyncedBlocks = updatedArticle;

    const variants = Array.from(Object.keys(updatedArticle.variants));

    const variantsToRemove = variants.filter((element) => {
      return ![...selectedLanguageCodes, mainLanguage].includes(element);
    });

    updatedArticle = removeVariants(variantsToRemove, updatedArticle);

    withSyncedBlocks = addArticleVariants(
      selectedLanguageCodes,
      updatedArticle,
      withSyncedBlocks
    );

    return withSyncedBlocks;
  };

  const removeVariants = (
    variants: string[],
    updatedArticle: UpdatedArticle
  ) => {
    variants.forEach((toRemove) => {
      delete updatedArticle.variants[toRemove];

      updatedArticle.blocks.forEach((b) => {
        if (b.type === 'multi_choice') {
          b.choices.forEach((c) => {
            delete c.variants[toRemove];
          });
        }
        delete b.variants[toRemove];
      });
    });

    return updatedArticle;
  };

  const addArticleVariants = (
    languageCodes: string[],
    article: UpdatedArticle,
    withSyncedBlocks: UpdatedArticle
  ) => {
    languageCodes.forEach((code) => {
      if (!article.variants[code]) {
        withSyncedBlocks.variants[code] = {
          ...article.variants[mainLanguage],
          ...{ translationStatus: 'draft' },
        };
      }

      withSyncedBlocks = addArticleBlockVariants(
        article,
        withSyncedBlocks,
        code
      );
    });

    return withSyncedBlocks;
  };

  const addArticleBlockVariants = (
    article: UpdatedArticle,
    articleToSync: UpdatedArticle,
    lang: string
  ) => {
    article.blocks.forEach((block, i) => {
      if (block.variants[lang]) return;

      articleToSync.blocks[i].variants[lang] = {
        ...block.variants[mainLanguage],
        ...{ translationStatus: 'draft' },
      };

      if (block.type !== 'multi_choice') return;

      articleToSync = syncChoices(block, articleToSync, lang, i);
    });
    return articleToSync;
  };

  const syncChoices = (
    block: MultiChoiceBlock,
    articleToSync: UpdatedArticle,
    lang: string,
    parentIndex: number
  ) => {
    block.choices.forEach((ch, idx) => {
      if (ch.variants[lang]) return;

      (articleToSync.blocks[parentIndex] as MultiChoiceBlock).choices[
        idx
      ].variants[lang] = {
        ...block.choices[idx].variants[mainLanguage],
        ...{ translationStatus: 'draft' },
      };
    });

    return articleToSync;
  };

  return {
    nodesToUpdateArticle,
  };
};

export default useMapUpdateArticle;
