import { useArticlesTranslation } from 'app/internationalization/hooks';
import { Danger } from 'iconsax-react';

import { useAnswersContext } from '../../context';
import LoadingSkeleton from '../Skeletons/LoadingSkeleton';

interface Props {
  contentHeightList?: string;
  contentHeightGrid?: string;
  skeletonContentCount?: number;
}

const EmptyFilterSkeleton: React.FC<Props> = ({
  contentHeightList,
  contentHeightGrid,
  skeletonContentCount,
}) => {
  const { t } = useArticlesTranslation();
  const { layout, onAudienceChange, selectedAudiences } = useAnswersContext();
  const numberOfSkeletons = layout === 'grid' ? 10 : 6;

  return (
    <>
      {[...Array(numberOfSkeletons)].map((_, i) => (
        <LoadingSkeleton
          key={i}
          contentHeightList={contentHeightList}
          contentHeightGrid={contentHeightGrid}
          skeletonContentCount={skeletonContentCount}
        />
      ))}

      <div className="absolute flex w-[744px] h-[784px] py-3 px-2 justify-center items-center gap-2.5">
        <div className="flex p-4 flex-col items-center gap-3 absolute left-[218px] top-[141px] rounded-lg bg-[#FEFFFE]">
          <div className="flex flex-col items-center gap-2 text-error">
            <Danger className="w-6 h-6" />
            <span className="flex flex-col items-center gap-1 text-base font-bold">
              {t('No answers match the current filtering')}
            </span>
          </div>
          {selectedAudiences.length === 0 ? (
            <div></div>
          ) : (
            <button
              onClick={() => {
                onAudienceChange();
              }}
              className="flex py-3 px-[64px] justify-center items-center gap-2.5 self-stretch rounded-lg bg-hover-blue"
            >
              <span className="flex justify-center items-center gap-1.5 text-focus">
                {t('Clear the Filtering')}
              </span>
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default EmptyFilterSkeleton;
