import { cx } from '@emotion/css';
import { useAuthenticatedUser } from 'app/api/auth/hooks';
import { PageLoader } from 'app/components';
import { useISOLanguages } from 'app/hooks';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import useTranslateArticleMutation from 'app/pages/Articles/hooks/useTranslateArticleMutation';
import { selectors, actions } from 'app/store/editor';
import { Translate } from 'iconsax-react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface TranslateButtonProps {
  className?: string;
  size?: number;
}

const TranslateButton = ({ className, size }: TranslateButtonProps) => {
  const { t } = useArticlesTranslation();
  const dispatch = useDispatch();
  const [translated, setTranslated] = useState(false);

  const { data: user } = useAuthenticatedUser();
  const { mutate: translate, isLoading: isTranslating } =
    useTranslateArticleMutation();

  const article = useSelector(selectors.getArticle);
  const activeLanguage = useSelector(selectors.getActiveLanguage);

  const languages = useISOLanguages({ search: '' });
  const language = languages.find(
    (l) => l.code === user?.contentLanguage.uiLanguage
  );

  const translateArticle = () => {
    if (!article || !user) return;

    translate(
      { articleId: article.id, toLanguage: user.contentLanguage.uiLanguage },
      {
        onSuccess: (updatedArticle) => {
          dispatch(actions.setArticle(updatedArticle));
          dispatch(
            actions.addLanguage({
              name: language?.name ?? '',
              code: user.contentLanguage.uiLanguage,
              isDefault: false,
              active: true,
            })
          );
          dispatch(actions.setActiveLanguage(user.contentLanguage.uiLanguage));
          setTranslated(true);
        },
      }
    );
  };

  if (activeLanguage === user?.contentLanguage.uiLanguage) return <></>;

  return (
    <button
      className={cx(
        'flex justify-center items-center w-[34px] h-[34px] rounded-[10px] text-grayscale-primary hover:bg-hover-blue hover:text-focus transition-all',
        className,
        {
          'text-grayscale-primary hover:bg-hover-blue hover:text-focus':
            !translated,
          'text-success bg-success-background': translated,
          'bg-hover-blue': isTranslating,
        }
      )}
      disabled={isTranslating}
      data-tip={!isTranslating ? t('Translate article') : undefined}
      data-for={!isTranslating ? 'navigation-actions' : undefined}
      onClick={() => !isTranslating && translateArticle()}
    >
      {isTranslating ? (
        <PageLoader className="scale-[65%]" />
      ) : (
        <Translate size={size} />
      )}
    </button>
  );
};

export default TranslateButton;
