import { useEditor } from '@craftjs/core';
import { cx } from '@emotion/css';
import TextBlock from 'app/pages/ArticleEditor/components/blocks/TextBlock';
import { InitialType } from 'app/pages/ArticleEditor/helpers/lexical/generateInitialState';
import a11yKeydown from 'app/utils/a11y/keydown';
import { useState } from 'react';

interface OptionButtonProps {
  className: string;
  text: string;
  type: InitialType;
}

const TextBlockOption = ({ className, text, type }: OptionButtonProps) => {
  const [pressing, setPressing] = useState(false);
  const {
    connectors: { create },
    actions: { addNodeTree },
    query: { parseReactElement },
  } = useEditor();

  const addBlock = () => {
    const block = parseReactElement(
      <TextBlock initialType={type} translationStatus="draft" />
    ).toNodeTree();

    addNodeTree(block, 'dropableRegion');
  };

  return (
    <div
      role="button"
      ref={(ref) =>
        ref &&
        create(ref, <TextBlock initialType={type} translationStatus="draft" />)
      }
      tabIndex={0}
      onClick={addBlock}
      onKeyDown={(e) => a11yKeydown(e, addBlock)}
      onMouseDown={() => setPressing(true)}
      onMouseUp={() => setPressing(false)}
      onDragStart={() => setPressing(true)}
      onDragEnd={() => setPressing(false)}
      className={cx(
        'flex w-full text-sm justify-center items-center rounded-lg mt-2 h-[40px] cursor-grab min-w-[148px]',
        {
          'cursor-grabbing transform translate-0 bg-focus-background border border-focus':
            pressing,
          'bg-gray-white-shadow hover:bg-hover-blue border border-transparent':
            !pressing,
        }
      )}
    >
      <span
        className={cx(className, {
          'text-focus': pressing,
          'text-black': !pressing,
        })}
      >
        {text}
      </span>
      <div
        className={cx(
          'h-4.5 w-4.5 flex justify-center items-center absolute -top-1.5 -left-1.5 bg-focus rounded-lg text-white text-xs',
          { hidden: !pressing }
        )}
      >
        +
      </div>
    </div>
  );
};

export default TextBlockOption;
