import { useNode } from '@craftjs/core';
import { css, cx } from '@emotion/css';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { ComponentProps } from 'react';
import {
  MediaTaskBlockTypeEnum,
  SimpleTaskBlockTypeEnum,
} from 'submodules/common-ui/generated/api/gcs';

import SimpleTask from '../../../../SimpleTask';
import ActionContainer from '../ActionContainer';
import EditorNavigation from '../EditorNavigation';

interface SimpleTaskViewerActionProps {
  type: MediaTaskBlockTypeEnum | SimpleTaskBlockTypeEnum;
}

const SimpleTaskViewerAction = ({ type }: SimpleTaskViewerActionProps) => {
  const { t } = useArticlesTranslation();

  const { selectedProps } = useNode((node) => ({
    selectedProps: node.data.props as ComponentProps<typeof SimpleTask>,
  }));

  const { title, description } = selectedProps;

  return (
    <ActionContainer
      isMandatory={selectedProps.mandatory}
      canAnswer={false}
      type={type}
    >
      <div className="flex flex-col">
        <span
          className={cx('text-lg font-bold', css('word-break: break-word;'))}
        >
          {title}
        </span>
        <span className={cx('text-black', css('word-break: break-word;'))}>
          {description}
        </span>
      </div>

      {type === 'media_task' && (
        <div className="flex items-center justify-center w-full rounded-xl border border-dashed border-ceil text-ceil py-8 mt-4">
          <span>+ {t('Upload photo')}</span>
        </div>
      )}
      <div className="flex justify-between items-center w-full mt-6">
        <EditorNavigation />
      </div>
    </ActionContainer>
  );
};

export default SimpleTaskViewerAction;
