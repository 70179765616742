import { InfiniteData, useInfiniteQuery } from 'react-query';
import {
  EmojiEnum,
  ReactionObjectTypeEnum,
  ReactionPage,
} from 'submodules/common-ui/generated/api/gcs/api';
import { NonNegativeNumber } from 'submodules/common-ui/utils/nonNegativeNumber';

import { queryKeys, resources } from '../index';

const useReactionsQuery = ({
  objectType,
  objectId,
  page,
  perPage = 20,
  emoji,
  createdAt = 'asc',
  enabled,
  onSuccess,
}: {
  objectType: ReactionObjectTypeEnum;
  objectId: number;
  page: number;
  perPage?: number;
  createdAt?: 'asc' | 'desc';
  emoji?: EmojiEnum;
  enabled?: boolean;
  onSuccess?: (data: InfiniteData<ReactionPage> | undefined) => void;
}) => {
  const { error, data, isFetching, isLoading, refetch } = useInfiniteQuery(
    queryKeys.reactions(NonNegativeNumber.create(page).value, objectId, emoji),
    ({ pageParam }) =>
      resources.getReactions({
        objectId,
        objectType,
        page: NonNegativeNumber.create(pageParam || 0).value,
        createdAt,
        emoji,
        perPage,
      }),
    {
      enabled,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      onSuccess,
    }
  );

  return {
    data: data?.pages.map((item) => item.data).flat(),
    error,
    isFetching,
    isLoading,
    refetch,
  };
};

export default useReactionsQuery;
