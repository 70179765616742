import { useEditor } from '@craftjs/core';
import useGetAudiencesQuery from 'app/api/audiences/hooks/useAudiencesQuery';
import { IconButton, Modal } from 'app/components';
import BasicModal from 'app/components/BasicModal';
import { useDispatch, useSelector } from 'app/hooks';
import useAppSelector from 'app/hooks/useSelector';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import PollBlock from 'app/pages/ArticleEditor/components/blocks/tasks/Poll';
import QuizBlock from 'app/pages/ArticleEditor/components/blocks/tasks/Quiz';
import SimpleTask from 'app/pages/ArticleEditor/components/blocks/tasks/SimpleTask';
import RecurringArticleModal from 'app/pages/Articles/Components/RecuringArticleModal';
import { RecurringArticleDate } from 'app/pages/Articles/Components/RecuringArticleModal/types';
import useArticleActionBlocks from 'app/pages/Articles/hooks/useArticleActionBlocks';
import { selectors, actions } from 'app/store/editor';
import dayjs from 'dayjs';
import {
  ComponentProps,
  FC,
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import CloseFillIcon from 'remixicon-react/CloseFillIcon';
import {
  Article,
  ArticleStatusEnum,
} from 'submodules/common-ui/generated/api/gcs';

import BlockTypes from '../../helpers/constants';
import useRecurringDateAsSchedule from '../../hooks/useRecurringDateAsSchedule';

import FirstStepForm from './components/forms/FirstStepForm';
import SecondStepForm from './components/forms/SecondStepForm';
import ThirdStepForm from './components/forms/ThirdStepForm';
import useBlockSchedulingInfo from './components/forms/useBlockSchedulingInfo';

interface Props {
  onClose: () => void;
  publish: VoidFunction;
  schedule: VoidFunction;
  update: VoidFunction;
  unArchive: VoidFunction;
  archive: VoidFunction;
  cancelSchedule: VoidFunction;
  article?: Article;
}

const PublishModalStepper: FC<Props> = ({
  onClose,
  publish,
  schedule,
  cancelSchedule,
  archive,
  update,
  unArchive,
  article,
}) => {
  const dispatch = useDispatch();
  const { t } = useArticlesTranslation();
  const [activeStep, setActiveStep] = useState<number>();
  const articleChannel = useAppSelector(selectors.getSelectedChannel);
  const selected = useSelector(selectors.getSelectedAudiences);
  const storePublishDate = useSelector(selectors.getPublishDate);
  const recurringDate = useSelector(selectors.getRecurringDate);
  const { getRecurringDate, getScheduleDate } = useRecurringDateAsSchedule();

  const [isSubmitClicked, setSubmitClicked] = useState(false);
  const [toggleRecurring, setToggleRecurring] = useState(false);
  const [toggleSchedule, setToggleSchedule] = useState(false);

  const {
    nodes,
    actions: { setProp },
  } = useEditor((state, _) => ({
    nodes: state.nodes,
  }));

  const { isLoading } = useGetAudiencesQuery({
    page: 1,
    query: '',
    enabled: false,
  });

  useEffect(() => {
    if (article) {
      dispatch(actions.setPublishDate(article?.publishAt || undefined));
      dispatch(actions.setArchiveDate(article?.archiveAt || undefined));
    }
  }, [article, dispatch]);

  const actionBlocks = useArticleActionBlocks(nodes);

  const { totalBlocks, recurringBlocks, consistentSchedule } =
    useBlockSchedulingInfo(actionBlocks, getRecurringDate);

  useEffect(() => {
    if (!articleChannel) return setActiveStep(0);

    if (
      isLoading &&
      selected.length === 0 &&
      (article?.audiences.length ?? 0) > 0
    ) {
      setActiveStep(2);
      return;
    }

    if (selected.length === 0) {
      setActiveStep(1);
      return;
    }

    setActiveStep(2);
  }, [articleChannel, selected.length, isLoading, article?.audiences.length]);

  const handleGoToStep = useCallback(
    (step: number) => {
      setActiveStep(step);
    },
    [setActiveStep]
  );

  useEffect(() => {
    if (isSubmitClicked) setSubmitClicked(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [article]);

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      setSubmitClicked(true);
      e.preventDefault();

      if (!article?.status) return storePublishDate ? schedule() : publish();

      switch (article.status) {
        case ArticleStatusEnum.Draft:
          return storePublishDate ? schedule() : publish();
        case ArticleStatusEnum.Published:
          return dayjs() < dayjs(article.publishAt)
            ? cancelSchedule()
            : archive();
        case ArticleStatusEnum.Archived:
          return unArchive();
      }
    },
    [
      archive,
      article?.publishAt,
      article?.status,
      cancelSchedule,
      publish,
      schedule,
      storePublishDate,
      unArchive,
    ]
  );

  const articleStatus: ArticleStatusEnum = useMemo(() => {
    if (!article) {
      return ArticleStatusEnum.Draft;
    }

    return article.status;
  }, [article]);

  const handleUpdate = useCallback(() => {
    update();
    onClose();
  }, [onClose, update]);

  const getTasks = useCallback(() => {
    const tasks = [
      BlockTypes.SimpleTaskBlock,
      BlockTypes.QuizBlock,
      BlockTypes.PollBlock,
      BlockTypes.YesNoNaBlock,
      BlockTypes.ScormBlock,
    ];

    return Object.keys(nodes)
      .map((key) => nodes[key])
      .filter((node) => tasks.includes(node.data.displayName));
  }, [nodes]);

  const updateBlockAudiences = useCallback(
    (audienceIds: number[]) => {
      const taskIds = actionBlocks.map(({ id }) => id);

      if (taskIds.length === 0) return;

      for (const id of taskIds) {
        setProp(
          id,
          (props: ComponentProps<typeof SimpleTask | typeof QuizBlock>) => {
            props.audiences = audienceIds;
          }
        );
      }
    },
    [setProp, actionBlocks]
  );

  const onSubmitReplace = useCallback(() => {
    dispatch(actions.setRecurringDate(undefined));
    handleGoToStep(3);
  }, [dispatch, handleGoToStep]);

  const onSubmitCancel = useCallback(() => {
    const taskIds = getTasks().map(({ id }) => id);

    for (const id of taskIds)
      setProp(
        id,
        (
          props: ComponentProps<
            typeof SimpleTask | typeof QuizBlock | typeof PollBlock
          >
        ) => {
          props.schedule = undefined;
          props.deadline = undefined;
        }
      );

    dispatch(actions.setRecurringDate(undefined));
    setToggleRecurring(false);
    handleGoToStep(2);
  }, [dispatch, getTasks, handleGoToStep, setProp]);

  const onSubmitRecurring = useCallback(
    (date: RecurringArticleDate) => {
      dispatch(actions.setRecurringDate(date));

      const taskIds = actionBlocks.map(({ id }) => id);

      for (const id of taskIds)
        setProp(
          id,
          (
            props: ComponentProps<
              typeof SimpleTask | typeof QuizBlock | typeof PollBlock
            >
          ) => {
            props.schedule = getScheduleDate(date);
          }
        );

      setToggleRecurring(true);
      handleGoToStep(2);
    },
    [dispatch, actionBlocks, handleGoToStep, getScheduleDate, setProp]
  );

  if (activeStep === 3) {
    return (
      <RecurringArticleModal
        existingRecurringDate={recurringDate ?? consistentSchedule}
        onClose={() => handleGoToStep(2)}
        onCancel={() => {
          if (!article) return onSubmitCancel();
          handleGoToStep(4);
        }}
        onSubmit={onSubmitRecurring}
      />
    );
  }

  if (activeStep === 4 || activeStep === 5) {
    return (
      <BasicModal
        onClose={() => handleGoToStep(2)}
        onSubmit={activeStep === 4 ? onSubmitCancel : onSubmitReplace}
        submitLabel={t(activeStep === 4 ? 'Yes, delete' : 'Yes, overwrite')}
        title={t(
          activeStep === 4
            ? 'Delete recurring actions?'
            : 'Overwrite recurring schedules?'
        )}
        cancelLabel={t('No, cancel')}
        description={t(
          activeStep === 4
            ? 'Delete recurring actions means that all future occurrences will be canceled. You will still have access to the previous occurrences. Would you like to continue?'
            : '{{recurringBlocks}} of the {{totalBlocks}} action(s) are set to recur. If you continue, you will overwrite them and set the same recurring schedule for all actions. Would you like to continue?',
          {
            recurringBlocks: recurringBlocks,
            totalBlocks: totalBlocks,
          }
        )}
      />
    );
  }

  return (
    <Modal
      onClose={onClose}
      className="w-[536px]"
      overflow={activeStep === 0 || activeStep === 1}
    >
      <div className="flex justify-between items-center">
        <span className="text-xs text-grayscale-secondary">
          {articleStatus === ArticleStatusEnum.Draft
            ? t('Step {{activeStep}} of {{totalSteps}}', {
                activeStep: (activeStep ?? 0) + 1,
                totalSteps: 3,
              })
            : t('Summary')}
        </span>
        <IconButton
          Icon={CloseFillIcon}
          onClick={onClose}
          className="shadow-none"
        />
      </div>

      <form className="flex gap-4 flex-col" onSubmit={handleSubmit}>
        {activeStep === 0 && <FirstStepForm goToStep={handleGoToStep} />}
        {activeStep === 1 && (
          <SecondStepForm
            goToStep={handleGoToStep}
            updateBlockAudiences={updateBlockAudiences}
          />
        )}
        {activeStep === 2 && (
          <ThirdStepForm
            setToggleSchedule={setToggleSchedule}
            toggleSchedule={toggleSchedule}
            recurringDate={recurringDate}
            toggleRecuring={toggleRecurring}
            goToStep={handleGoToStep}
            cancelSchedule={cancelSchedule}
            article={article}
            isSubmitDisabled={isSubmitClicked}
            update={handleUpdate}
            updateBlockAudiences={updateBlockAudiences}
            setToggleRecurring={setToggleRecurring}
            getTasks={() => getTasks().map(({ data }) => data)}
          />
        )}
      </form>
    </Modal>
  );
};

export default PublishModalStepper;
