import { cx } from '@emotion/css';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { actions as modalActions } from 'app/store/modal';
import { ModalTypes } from 'app/store/modal/types';
import CrossIcon from 'assets/icons/cross.svg?react';
import { BackSquare, Buliding, Link, MessageText, Slash } from 'iconsax-react';
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { ArticleSharingStatus } from 'submodules/common-ui/generated/api/gcs';

import ActionButton from './ActionButton';
import StatusTag from './StatusTag';

interface SharingStatusRowProps {
  sharingStatus: ArticleSharingStatus;
  articleOwner: number;
  articleId: number;
  isOpen: boolean;
  setOpenComment: Dispatch<SetStateAction<number | undefined>>;
  sender?: string;
}

const SharingStatusRow = ({
  sharingStatus,
  articleOwner,
  articleId,
  isOpen,
  sender,
  setOpenComment,
}: SharingStatusRowProps) => {
  const { t } = useArticlesTranslation();

  const dispatch = useDispatch();

  const commentRef = useRef<HTMLDivElement | null>(null);

  const toggleComment = (tenantId: number) =>
    setOpenComment((prev) => (prev === tenantId ? undefined : tenantId));

  const openDeleteSharingConnectionModal = () =>
    dispatch(
      modalActions.showModal({
        modalType: ModalTypes.DELETE_SHARED_ARTICLE_MODAL,
        modalProps: {
          articleOwner,
          articleId,
          receiverName: sharingStatus.tenantName,
          tenantId: sharingStatus.tenantId,
        },
      })
    );

  const scrollCommentIntoView = () =>
    commentRef.current?.scrollIntoView({ block: 'nearest', inline: 'start' });

  useEffect(() => {
    if (isOpen) scrollCommentIntoView();
  }, [isOpen]);

  const AnswerSharingStatus = () => {
    return (
      <div
        className={cx('flex gap-1', {
          'text-success': sharingStatus.answerSharing,
          'text-error': !sharingStatus.answerSharing,
        })}
      >
        {sharingStatus.answerSharing ? <Link size={16} /> : <Slash size={16} />}
        <span className="text-xs font-bold">
          {sharingStatus.answerSharing
            ? t('Insights sharing on')
            : t('Insights sharing off')}
        </span>
      </div>
    );
  };

  return (
    <>
      <div className="flex w-full items-center mt-2 py-2 justify-between">
        <div className="flex">
          <div className="w-8 h-8 flex items-center justify-center  text-grayscale-secondary rounded border border-gray-light bg-white">
            {sharingStatus?.tenantLogo ? (
              <img src={sharingStatus.tenantLogo} alt="Logo" />
            ) : (
              <Buliding size={20} />
            )}
          </div>
          <div className="flex flex-col ml-3">
            <span className="text-sm">
              {sharingStatus?.tenantTitle ?? sharingStatus.tenantName}
            </span>
            <div className="flex items-center gap-2.5">
              <StatusTag sharingStatus={sharingStatus} className="!px-0" />
              <AnswerSharingStatus />
            </div>
          </div>
        </div>
        <div className="text-grayscale-secondary">
          {sharingStatus.sharingComment && (
            <ActionButton
              id="comment"
              tooltipText={t('View comment')}
              onClick={() => toggleComment(sharingStatus.tenantId)}
            >
              <MessageText size={20} />
            </ActionButton>
          )}
          <ActionButton
            id="withdraw"
            tooltipText={t('Withdraw article')}
            onClick={openDeleteSharingConnectionModal}
          >
            <BackSquare size={20} />
          </ActionButton>
        </div>
      </div>
      {isOpen && (
        <div
          ref={commentRef}
          className="relative h-auto bg-gray rounded-xl rounded-tl-xs py-2 px-4 text-sm flex flex-col"
        >
          <span className="font-bold">{sender}</span>
          {sharingStatus.sharingComment}
          <button
            className="absolute right-1 top-1"
            onClick={() => setOpenComment(undefined)}
          >
            <CrossIcon />
          </button>
        </div>
      )}
    </>
  );
};

export default SharingStatusRow;
