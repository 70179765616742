import { cx } from '@emotion/css';
import { Language } from 'app/api/auth/types';
import DropdownInput from 'app/components/DropdownInput';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import TickMarkIcon from 'assets/icons/tickmark.svg?react';
import { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

interface LanguageDropdownProps {
  languages: Language[];
  onSelectedLanguage: (language: Language | undefined) => void;
}

const LanguageDropdown = ({
  languages,
  onSelectedLanguage,
}: LanguageDropdownProps) => {
  const [open, setOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<
    Language | undefined
  >();

  useEffect(() => {
    if (languages?.length === 1) {
      setSelectedLanguage(languages[0]);
      onSelectedLanguage(languages[0]);
    }
  }, [languages, onSelectedLanguage]);

  const isDisabledLanguageSelection = languages?.length === 1;

  const { t } = useArticlesTranslation();

  return (
    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
      <div className="relative">
        <DropdownInput
          text={selectedLanguage ? selectedLanguage.name : t('Choose language')}
          open={open}
          disabled={isDisabledLanguageSelection}
          onClick={() => setOpen((prev) => !prev)}
          className="bg-grayscale-bg-dark h-[52px]"
        />
        {open && !isDisabledLanguageSelection && (
          <div className="absolute z-10 top-[59px] left-0 right-0 bg-white border-gray-light border rounded">
            <div className="h-full overflow-auto max-h-[256px]">
              {languages?.map((language) => {
                return (
                  <button
                    className="flex items-center justify-between py-2 px-5 hover:bg-focus-background w-full group"
                    key={language.code}
                    onClick={() => {
                      setSelectedLanguage(language);
                      onSelectedLanguage(language);
                      setOpen(false);
                    }}
                  >
                    {language.name}
                    <span
                      className={cx('group-hover:visible  text-focus', {
                        invisible: selectedLanguage?.id !== language.id,
                      })}
                    >
                      <TickMarkIcon />
                    </span>
                  </button>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default LanguageDropdown;
