import { useSelector, useDispatch } from 'app/hooks';
import { actions, selectors } from 'app/store/sessionConfig';
import { ReactNode, useEffect } from 'react';

interface Props {
  children: ReactNode;
}

const SessionConfigHandler = ({ children }: Props) => {
  const landedPage = useSelector(selectors.getLandedPage);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!landedPage) {
      dispatch(actions.landedPage(window.location.href));
    }
  }, [dispatch, landedPage]);

  return <>{children}</>;
};

export default SessionConfigHandler;
