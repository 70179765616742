import { request } from 'app/api/gcsRequest';
import config from 'app/config';
import { ArticleFilters } from 'app/pages/Articles/context';
import { ArticleApi } from 'submodules/common-ui/generated/api/gcs';

const perPage = 20;

const getArticles = (
  page: number,
  filters: ArticleFilters,
  language?: string
) =>
  new ArticleApi(undefined, config.env.gcsApiUrl, request())
    .getArticlePage(
      page,
      perPage,
      filters.title,
      filters.status,
      filters.realStatus,
      filters.createdBy,
      filters.channelId,
      filters.live,
      filters.unseen,
      filters.inprogress,
      undefined,
      filters.excludeFeedPosts,
      filters.timezone,
      filters.categoryIds && filters.categoryIds.length > 0
        ? filters.categoryIds?.join(', ')
        : undefined,
      undefined,
      filters.deadline,
      filters.archivedAt,
      filters.createdAt,
      undefined,
      filters.publishedAt,
      filters.updatedAt,
      filters.progress,
      filters.include,
      language
    )
    .then((response) => response.data);

export default getArticles;
