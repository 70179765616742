import { useQuery } from 'react-query';

import { resources, queryKeys } from '../index';

const useGetAudienceQuery = ({
  id,
  enabled = true,
}: {
  id: number;
  enabled?: boolean;
}) => {
  const { data, isLoading, error, isFetching, refetch } = useQuery(
    queryKeys.audience(id),
    () => resources.getAudience(id),
    { enabled, refetchOnWindowFocus: false }
  );

  return {
    data: data?.data?.data,
    isFetching,
    isLoading,
    error,
    refetch,
  };
};

export default useGetAudienceQuery;
