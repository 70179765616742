import { queryKeys } from 'app/api/articles';
import { VerticalChevron } from 'app/components';
import SimpleToast from 'app/components/Toast/SimpleToast';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import ForwarArticleLanguages from 'app/pages/Editor/components/ForwardModal/ForwarArticleLanguages';
import useUpdateArticleMutation from 'app/pages/Editor/hooks/mutations/useUpdateArticleMutation';
import queryClient from 'app/query';
import { Danger } from 'iconsax-react';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  ActionChangeLogSchedule,
  Article,
  UpdatedArticle,
  UpdatedBlock,
} from 'submodules/common-ui/generated/api/gcs';

import useUIArticleLanguage from '../../hooks/useUIArticleLanguage';

import LanguageStatus from './components/LanguageStatus';

interface ArticleDetailsLanguagesProps {
  article: Article;
  hasHeader?: boolean;
  hasChevron?: boolean;
  isForward?: boolean;
}

interface Variants {
  [key: string]: { translationStatus: string };
}

const ArticleDetailsLanguages = ({
  article,
  hasHeader = true,
  hasChevron = false,
  isForward = false,
}: ArticleDetailsLanguagesProps) => {
  const { id: articleId } = useParams<{ id?: string }>();

  const { t } = useArticlesTranslation();
  const { getMainLanguage } = useUIArticleLanguage();

  const mainLanguage = getMainLanguage(article?.languages);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isExpanded = !hasHeader || !hasChevron || isOpen;
  const onExpand = () => setIsOpen((prev) => !prev);

  const [approvedLanguages, setApprovedLanguages] = useState<string[]>([
    mainLanguage,
  ]);
  const [draftLanguages, setDraftLanguages] = useState<string[]>([]);

  const { mutate: updateArticle, serverErrorMessage } =
    useUpdateArticleMutation();

  const nonProofreadStatuses = useMemo(() => ['draft', 'auto'], []);

  useEffect(() => {
    const languagesStatus: { [key: string]: string[] } = {};

    const setLanguagesStatus = (variants: Variants) => {
      Object.entries(variants).forEach(([code, variant]) => {
        if (!languagesStatus[code]) languagesStatus[code] = [];
        if (!variant.translationStatus) return;
        if (languagesStatus[code].indexOf(variant.translationStatus) === -1)
          languagesStatus[code].push(variant.translationStatus);
      });
    };

    article.blocks.forEach((block) =>
      setLanguagesStatus(block.variants as Variants)
    );

    Object.entries(languagesStatus).forEach(([language, status]) => {
      if (language === mainLanguage) return;
      if (nonProofreadStatuses.some((x) => status.includes(x)))
        return setDraftLanguages((prev) => [...prev, language]);

      setApprovedLanguages((prev) => [...prev, language]);
    });
  }, [article, mainLanguage, nonProofreadStatuses]);

  const showToast = (content: JSX.Element) => {
    toast.dismiss();
    toast.clearWaitingQueue();

    toast(content, {
      position: 'bottom-center',
      autoClose: 8000,
      closeButton: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      containerId: articleId ? 'Simple' : 'articleActions',
    });
  };

  const onUpdateStatus = () => {
    const updateVariant = (variants: Variants) =>
      Object.fromEntries(
        Object.entries(variants).map(([key, variant]: [string, any]) => [
          key,
          nonProofreadStatuses.some((x) =>
            variant.translationStatus.includes(x)
          ) && key !== mainLanguage
            ? { ...variant, translationStatus: 'approved' }
            : variant,
        ])
      );

    const updatedBlocks: UpdatedBlock[] = article.blocks.map((block) => ({
      ...block,
      categories:
        'categories' in block
          ? block.categories?.map(({ id }) => id) || []
          : [],
      variants: updateVariant(block.variants as Variants),
      schedule:
        'schedule' in block
          ? (block.schedule as ActionChangeLogSchedule)
          : undefined,
    }));

    const updatedArticle: UpdatedArticle = {
      ...article,
      blocks: updatedBlocks,
      publishAt: article.publishAt,
      archiveAt: article.archiveAt ?? null,
      channelId: article.channel?.id ?? null,
      categories: article.categories.map(({ id }) => id),
      variants: updateVariant(article.variants),
    };

    updateArticle(
      { article: updatedArticle },
      {
        onSuccess: () => setDraftLanguages([]),
        onError: (error) => {
          queryClient.invalidateQueries(queryKeys.article(article.id));
          showToast(<SimpleToast text={serverErrorMessage(error)} />);
        },
      }
    );
  };

  const ErrorLabel = () => {
    if (draftLanguages.length > 0) {
      return (
        <div className="flex items-center gap-1 text-error">
          <Danger size={16} />
          <span className="text-xs">
            {t('The article contains blocks that were not proofread')}
          </span>
        </div>
      );
    }

    return null;
  };

  const Content = () => {
    return (
      <div className="flex flex-col gap-2 text-xs">
        {hasHeader && (
          <div className="flex justify-between">
            <span className="font-bold">{t('Language summary')}</span>
            {hasChevron && (
              <button onClick={onExpand}>
                <VerticalChevron size={16} open={isOpen} />
              </button>
            )}
          </div>
        )}
        {draftLanguages.length > 0 && !isForward && <ErrorLabel />}
        {isExpanded && (
          <>
            <LanguageStatus
              status="approved"
              languages={approvedLanguages}
              mainLanguage={mainLanguage}
              onUpdateStatus={onUpdateStatus}
            />
            <LanguageStatus
              status="draft"
              languages={draftLanguages}
              mainLanguage={mainLanguage}
              onUpdateStatus={onUpdateStatus}
            />
          </>
        )}
      </div>
    );
  };

  return isForward ? (
    <ForwarArticleLanguages error={<ErrorLabel />}>
      <Content />
    </ForwarArticleLanguages>
  ) : (
    <Content />
  );
};

export default ArticleDetailsLanguages;
