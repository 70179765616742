import { useTranslation } from 'react-i18next';
import { LanguageList } from 'submodules/common-ui/generated/api/gcs';

const useUIArticleLanguage = () => {
  const {
    i18n: { language: userLanguage },
  } = useTranslation();

  const getMainLanguage = (languages: LanguageList = []) => {
    const defaultLanguage =
      languages.find((l) => l.isDefault)?.language || 'en';
    return (
      languages.find((l) => l.language === userLanguage)?.language ??
      defaultLanguage
    );
  };

  return {
    getMainLanguage,
  };
};

export default useUIArticleLanguage;
