import { ModulesKeys } from '../types';

import useBaseTranslation from './useBaseTranslation';

const useModulesTranslation = () =>
  useBaseTranslation<ModulesKeys>(undefined, {
    keyPrefix: 'modules',
  });

export default useModulesTranslation;
