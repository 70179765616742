import useAuthenticatedUser from 'app/api/auth/hooks/useAuthenticatedUser';
import useUpcomingActions from 'app/api/channels/hooks/useUpcomingActions';
import { UpcomingActionsFilter } from 'app/api/channels/types';
import dayjs from 'dayjs';
import { useMemo } from 'react';

import { UpcomingActionsRequiredFilter, requireFilterMap } from '../types';

const useOthersUpcomingActions = (required: UpcomingActionsRequiredFilter) => {
  const { data: user } = useAuthenticatedUser();

  const filter = useMemo<UpcomingActionsFilter>(
    () => ({
      deadlineFrom: dayjs().endOf('week').add(2, 'day').format('YYYY-MM-DD'),
      timezone: user?.timezone,
      required: requireFilterMap[required],
    }),
    [required, user?.timezone]
  );

  return useUpcomingActions(filter);
};

export default useOthersUpcomingActions;
