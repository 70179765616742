import { HomeKey } from '../types';

import useBaseTranslation from './useBaseTranslation';

const useHomeTranslation = () =>
  useBaseTranslation<HomeKey>(undefined, {
    keyPrefix: 'home',
  });

export default useHomeTranslation;
