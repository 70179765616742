import { cx } from '@emotion/css';
import { Tooltip, VerticalChevron } from 'app/components';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { selectors } from 'app/store/editor';
import {
  ArrowForwardSquare,
  Send,
  ClipboardText,
  Teacher,
} from 'iconsax-react';
import { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useSelector } from 'react-redux';

interface PublishDropdownProps {
  disabled: boolean;
  hasPermission: boolean;
  onPublishClick: VoidFunction;
  onShareClick?: VoidFunction;
  onTemplateClick: VoidFunction;
  onGenerateScormPackageClick?: VoidFunction;
}

const PublishDropdown = ({
  disabled,
  hasPermission,
  onPublishClick,
  onShareClick,
  onTemplateClick,
  onGenerateScormPackageClick,
}: PublishDropdownProps) => {
  const { t } = useArticlesTranslation();
  const article = useSelector(selectors.getArticle);

  const [open, setOpen] = useState(false);

  const isTemplateDisabled =
    disabled || (article?.status !== 'archived' && article?.status !== 'draft');

  const isGenerateScormPackageDisabled =
    article?.status !== 'published' || article.channel?.easyAccess !== true;

  const forwardDisabled =
    disabled ||
    !article ||
    onShareClick === undefined ||
    (article.originalTenant !== null && !article.isResharingAllowed);

  const isConnectDisabled = !hasPermission || disabled;

  const buttonClass = 'flex items-center justify-between px-4 py-2 rounded-lg';

  return (
    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
      <div className="flex relative">
        <button
          className={cx(
            'flex items-center justify-center ml-2 w-auto px-3 h-[36px] rounded-l-xl border-transparent',
            {
              'bg-focus text-white': !isConnectDisabled,
              'bg-gray-light text-grayscale-secondary': isConnectDisabled,
            }
          )}
          disabled={isConnectDisabled}
          onClick={onPublishClick}
        >
          <Send size={16} className="mr-1.5" />
          <span className="text-sm">
            {article?.status === 'published' ? t('Republish') : t('Publish')}
          </span>
        </button>
        <div
          className={cx(
            'flex items-center justify-center ml-0.5 w-[36px] rounded-r-lg text border-transparent h-[36px] ',
            {
              'bg-focus text-white': !disabled,
              'bg-gray-light text-grayscale-secondary': disabled,
            }
          )}
        >
          <VerticalChevron
            onClick={() => setOpen((prev) => !prev)}
            open={open}
            size={16}
            className={cx('w-4', {
              'text-white': !disabled,
              'text-grayscale-secondary': disabled,
            })}
          />
        </div>

        {open && (
          <div className="flex flex-col absolute w-[290px] p-1 bg-white z-50 rounded-lg shadow-lg top-10 right-0 bg-clip-padding shadow-atobi border border-focus-background">
            {hasPermission && (
              <Tooltip
                content={t(
                  'Resharing of this forwarded article has been disabled by an external partner.'
                )}
                centerText
                fixedWidth
                show={
                  article?.originalTenant !== null &&
                  !article?.isResharingAllowed
                }
              >
                <button
                  className={cx(buttonClass, {
                    'hover:bg-focus-background': !forwardDisabled,
                  })}
                  onClick={onShareClick}
                  disabled={forwardDisabled}
                >
                  <div>
                    <ArrowForwardSquare
                      size={24}
                      className={cx({
                        'text-grayscale-secondary': forwardDisabled,
                        'text-black': !forwardDisabled,
                      })}
                    />
                  </div>
                  <div className="flex flex-col items-start justify-center text-start ml-2.5">
                    <span
                      className={cx('text-sm', {
                        'text-grayscale-secondary': forwardDisabled,
                        'text-black': !forwardDisabled,
                      })}
                    >
                      {t('Share')}
                    </span>
                    <span className="text-xs text-gray-silver">
                      {t(
                        'Forward copy to external partners outside your company'
                      )}
                    </span>
                  </div>
                </button>
              </Tooltip>
            )}
            <button
              className={cx(buttonClass, {
                'hover:bg-focus-background': !isTemplateDisabled,
              })}
              onClick={onTemplateClick}
              disabled={isTemplateDisabled}
            >
              <div>
                <ClipboardText
                  size={24}
                  className={cx({
                    'text-grayscale-secondary': isTemplateDisabled,
                    'text-black': !isTemplateDisabled,
                  })}
                />
              </div>
              <div className="flex flex-col items-start justify-center text-start ml-2.5">
                <span
                  className={cx('text-sm', {
                    'text-grayscale-secondary': isTemplateDisabled,
                    'text-black': !isTemplateDisabled,
                  })}
                >
                  {t('Convert to Template')}
                </span>
                <span className="text-xs text-gray-silver">
                  {t('Transform your go-to articles into templates.')}
                </span>
              </div>
            </button>
            <button
              className={cx(buttonClass, {
                'hover:bg-focus-background': !isGenerateScormPackageDisabled,
              })}
              onClick={onGenerateScormPackageClick}
              disabled={isGenerateScormPackageDisabled}
            >
              <div>
                <Teacher
                  size={24}
                  className={cx({
                    'text-grayscale-secondary': isGenerateScormPackageDisabled,
                    'text-black': !isGenerateScormPackageDisabled,
                  })}
                />
              </div>
              <div className="flex flex-col items-start justify-center text-start ml-2.5">
                <span
                  className={cx('text-sm', {
                    'text-grayscale-secondary': isGenerateScormPackageDisabled,
                    'text-black': !isGenerateScormPackageDisabled,
                  })}
                >
                  {t('Generate SCORM package')}
                </span>
                <span className="text-xs text-gray-silver">
                  {t(
                    'Generate the article as SCORM package and embed it in your own LMS'
                  )}
                </span>
              </div>
            </button>
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default PublishDropdown;
