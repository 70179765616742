import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import useGetCategoriesQuery from 'app/api/categories/hooks/useCategories';
import { FixedSizeInfiniteScroll, PageLoader } from 'app/components';
import Input from 'app/components/Input2';
import config from 'app/config';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { RefObject, useState } from 'react';
import CheckLineIcon from 'remixicon-react/CheckLineIcon';
import {
  GetCategory,
  CategoryStatusEnum,
} from 'submodules/common-ui/generated/api/gcs';

interface Props {
  popupRef: RefObject<HTMLDivElement>;
  bottomPosition: number | null;
  selectedCategory?: GetCategory;
  onChange: (category?: GetCategory) => void;
  onOpen: (open: boolean) => void;
  getCategoryName: (category: GetCategory) => string;
}

const CategoryPopup = ({
  popupRef,
  onChange,
  onOpen,
  getCategoryName,
  selectedCategory,
  bottomPosition,
}: Props) => {
  const { t } = useArticlesTranslation();

  const [search, setSearch] = useState('');

  const {
    isLoading,
    data: categories,
    meta,
    fetchNextPage,
  } = useGetCategoriesQuery({
    page: 0,
    per_page: 20,
    name: search,
    status: CategoryStatusEnum.Active,
  });

  const currentPage = meta?.currentPage ?? 0;
  const lastPage = meta?.lastPage ?? 0;

  const isCategoriesNotEmpty = categories.length > 0;

  const handleSearchClear = () => setSearch('');
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearch(event.target.value);

  return (
    <Popup
      bottomPosition={bottomPosition}
      ref={popupRef}
      role="presentation"
      className="rounded w-[200px] md:right-auto absolute bg-white z-50 transform  shadow-atobi cursor-auto"
    >
      <div className="flex flex-col">
        <div className="px-3 pt-3 pb-1">
          <Input
            className="h-10 rounded-[8px] outline-[1px] text-grayscale-primary"
            placeholder={t('Search')}
            onChange={handleSearch}
            onClear={handleSearchClear}
            isSearch={true}
            searchIconSize={16}
          />
        </div>
        <div className="overflow-y-auto z-10">
          {isLoading && (
            <div className="h-[220px]">
              <PageLoader />
            </div>
          )}
          {isCategoriesNotEmpty && (
            <FixedSizeInfiniteScroll
              height="220px"
              dataLength={categories.length}
              hasMore={currentPage < lastPage}
              loadMore={fetchNextPage}
            >
              {categories.map((category, index) => (
                <button
                  key={`${category.id}-${index}`}
                  className={cx(
                    'flex justify-between items-center w-full hover:bg-gray-light py-2 px-3',
                    {
                      'bg-focus-background':
                        category.id === selectedCategory?.id,
                    }
                  )}
                  onClick={() => {
                    onChange(
                      category.id === selectedCategory?.id
                        ? undefined
                        : category
                    );
                    onOpen(false);
                  }}
                >
                  <span className="text-base text-grayscale-primary">
                    {getCategoryName(category)}
                  </span>
                  {category.id === selectedCategory?.id && (
                    <CheckLineIcon size={20} color={config.colors.focus} />
                  )}
                </button>
              ))}
            </FixedSizeInfiniteScroll>
          )}
          {!isCategoriesNotEmpty && !isLoading && (
            <div className="flex justify-center items-center h-[220px]">
              {t('No results')}
            </div>
          )}
        </div>
      </div>
    </Popup>
  );
};

const Popup = styled.div<{ bottomPosition: number | null }>`
  bottom: ${(props) =>
    props.bottomPosition ? `${props.bottomPosition}px` : ''};
`;

export default CategoryPopup;
