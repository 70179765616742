import { useNode } from '@craftjs/core';
import { css, cx } from '@emotion/css';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { ComponentProps } from 'react';

import SimpleTask from '../../../../SimpleTask';
import ActionContainer from '../ActionContainer';
import EditorNavigation from '../EditorNavigation';

const OpenQuestionViewerAction = () => {
  const { t } = useArticlesTranslation();

  const { selectedProps } = useNode((node) => ({
    selectedProps: node.data.props as ComponentProps<typeof SimpleTask>,
  }));

  const { title, description } = selectedProps;

  return (
    <ActionContainer
      isMandatory={selectedProps.mandatory}
      canAnswer={false}
      type={'open_question_task'}
    >
      <div className="flex flex-col">
        <span
          className={cx('text-lg font-bold', css('word-break: break-word;'))}
        >
          {title}
        </span>
        <span className={cx('text-black', css('word-break: break-word;'))}>
          {description}
        </span>
      </div>
      <div className="bg-gray-light border-gray-dark border-[1px] rounded-lg w-full h-[110px] px-4 py-3 mt-4">
        <span className="text-grayscale-secondary text-sm">
          {t('Answering is disabled for viewers')}.
        </span>
      </div>
      <div className="flex justify-between items-center w-full mt-6">
        <EditorNavigation />
      </div>
    </ActionContainer>
  );
};

export default OpenQuestionViewerAction;
