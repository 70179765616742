import { css, cx } from '@emotion/css';
import useGetAudienceUsersQuery from 'app/api/audiences/hooks/useGetAudienceUsersQuery';
import { Audience } from 'app/api/audiences/types';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { Placeholder } from 'app/pages/Articles/Components/ArticleTable/ArticleTableSkeleton';

interface AudienceSummaryCellProps extends Pick<Audience, 'name' | 'id'> {
  name: string;
  userCount?: number;
  timezoneCount?: number;
}

const AudienceDetails = ({ id, name, userCount }: AudienceSummaryCellProps) => {
  const { t } = useArticlesTranslation();

  const { data: users, isLoading } = useGetAudienceUsersQuery({
    id: id,
    page: 1,
    per_page: 3,
  });

  return (
    <div className="flex flex-col justify-center truncate gap-1">
      <span className="text-ellipsis whitespace-nowrap overflow-hidden font-bold text-left">
        {name}
      </span>
      <div className="flex items-center gap-2">
        <div className="flex -space-x-2 h-6">
          {isLoading && <Placeholder className="w-[80px] mt-1" />}
          {users &&
            users.map((user) => (
              <img
                key={user.id}
                src={user.avatars.small}
                alt={user.fullName}
                className={cx(
                  'w-6 h-6 border border-white object-cover',
                  css({
                    borderRadius: '8px',
                  })
                )}
              />
            ))}
        </div>
        <span className="text-sm text-grayscale-secondary">
          {t('{{count}} members', {
            count: userCount ?? 0,
          })}
        </span>
      </div>
    </div>
  );
};

export default AudienceDetails;
