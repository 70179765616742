import { cx } from '@emotion/css';
import useGetActionAnswer from 'app/api/articles/hooks/useGetActionAnswer';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { selectors } from 'app/store/editor';
import { Danger } from 'iconsax-react';
import { useSelector } from 'react-redux';
import { GetYesNoTaskBlockV1 } from 'submodules/common-ui/generated/api/gcs';

import AnswerHeader from './AnswerHeader';
import AnswerTitle from './AnswerTitle';
import { useAnswersContext } from './context';
import YesNoAnswer from './YesNoAnswer';

interface Props {
  block: GetYesNoTaskBlockV1;
}

const YesNoTaskAnswers = ({ block }: Props) => {
  const { t } = useArticlesTranslation();
  const languange = useSelector(selectors.getActiveLanguage);
  const { setSelectedTaskId } = useAnswersContext();

  const { actionAnswers, error, isLoading } = useGetActionAnswer({
    id: block.id,
  });

  const showMoreBtnStyling = cx('w-[342px] h-[48px] rounded-xl bg-hover-blue');

  return (
    <div className="flex flex-col gap-3 w-[390px] rounded-[18px] border border-focus-background bg-grayscale-bg-dark p-6">
      <AnswerHeader
        deadline={block.deadline}
        blockType={block.type}
        isPublic={block.public}
        mandatory={block.mandatory ?? false}
        required={block.required}
      />

      <AnswerTitle
        description={block.variants[languange].description}
        title={block.variants[languange].title ?? ''}
      />

      {actionAnswers.map((answer) => (
        <YesNoAnswer answer={answer} key={answer.id} />
      ))}
      {error && (
        <div className="h-7 w-fit bg-light rounded-[6px] py-1 px-2 text-error flex gap-1 justify-center items-center">
          <Danger className="w-4 h-4" />
          <span className="text-sm">
            {t('Unable to receive answers, please try again later.')}
          </span>
        </div>
      )}

      {actionAnswers?.length === 0 && (
        <div className="h-7 w-fit bg-light rounded-[6px] py-1 px-2 text-sm text-grayscale-secondary">
          {t('There are no answers to this action yet.')}
        </div>
      )}

      {!isLoading && (actionAnswers?.length ?? 0) > 0 && !error && (
        <button
          onClick={() => setSelectedTaskId(block.id)}
          className={cx(
            showMoreBtnStyling,
            'text-sm p-3 text-focus hover:bg-focus-background'
          )}
        >
          {t('Show More')}
        </button>
      )}

      {(isLoading || actionAnswers?.length === 0) && (
        <div className={showMoreBtnStyling}></div>
      )}
    </div>
  );
};

export default YesNoTaskAnswers;
