import { cx } from '@emotion/css';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { editorTypes } from 'app/router/constants';
import { LineBreakNode } from 'lexical';
import { useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';

import { SerializedLexicalState, SerializedLexicalText } from '../types';

interface TextEditorProps {
  enabled: boolean;
  type?: 'plain' | 'rich';
  placeholder?: string;
  onRef: (_floatingAnchorElem: HTMLDivElement) => void;
}

const TextEditor = ({ enabled, type, placeholder, onRef }: TextEditorProps) => {
  const { mode } = useParams<{ mode: string }>();

  const [editor] = useLexicalComposerContext();

  editor.registerNodeTransform(LineBreakNode, (node) => {
    node.remove();
  });

  useLayoutEffect(() => {
    editor.setEditable(enabled);
  });

  const Editor = type === 'rich' ? RichTextPlugin : PlainTextPlugin;

  const isEmpty = (node?: SerializedLexicalText) => {
    if (!node) return true;
    if (node.text) return false;

    if (node.children && node.children.length > 0)
      for (const child of node.children) if (!isEmpty(child)) return false;

    return true;
  };

  const isEmptyEditor = () => {
    const state = editor.toJSON();
    const data = state.editorState as SerializedLexicalState;

    return isEmpty(data.root);
  };

  const cursorControl =
    mode !== editorTypes.actions
      ? enabled
        ? 'cursor-text'
        : 'cursor-pointer'
      : 'cursor-default';

  return (
    <Editor
      contentEditable={
        <div ref={onRef}>
          <ContentEditable
            className={cx(
              'relative px-2 py-3 outline-none focus-visible:bg-white rounded-lg',
              cursorControl
            )}
          />
          {isEmptyEditor() && placeholder && (
            <span
              className={cx(
                'absolute pointer-events-none left-0 right-0 ml-2 text-gray-dark text-2xl select-none top-1/2 -translate-y-1/2',
                cursorControl
              )}
            >
              {placeholder}
            </span>
          )}
        </div>
      }
      ErrorBoundary={LexicalErrorBoundary}
      placeholder={null}
    />
  );
};

export default TextEditor;
