import { useAuthenticatedUser } from 'app/api/auth/hooks';
import useGetChannelQuery from 'app/api/channels/hooks/useChannel';
import { useScreenBreakpoint } from 'app/hooks';
import { selectors } from 'app/store/request';
import { getChannelTitle, isMobile } from 'app/utils';
import { Notepad2, Clipboard } from 'iconsax-react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import DefaultBreadcrumb from './DefaultBreadcrumb';

const ChannelBreadcrumb = () => {
  const { id } = useParams<{ id: string }>();
  const instanceReady = useSelector(selectors.getRequestInstanceReady);
  const breakpoint = useScreenBreakpoint();
  const _isMobile = isMobile(breakpoint);

  const { data: user } = useAuthenticatedUser();
  const { data: channel } = useGetChannelQuery(
    Number(id),
    instanceReady && !!id
  );

  const interfaceLanguage = user?.contentLanguage.uiLanguage ?? 'en';

  const channelIcon = useMemo(
    () =>
      _isMobile
        ? undefined
        : channel?.shownAs === 'feed'
          ? Clipboard
          : Notepad2,
    [_isMobile, channel?.shownAs]
  );

  return (
    <DefaultBreadcrumb
      to={window.location.href}
      title={channel ? getChannelTitle(channel, interfaceLanguage) : undefined}
      Icon={channelIcon}
    />
  );
};

export default ChannelBreadcrumb;
