import { useArticlesTranslation } from 'app/internationalization/hooks';
import a11yKeydown from 'app/utils/a11y/keydown';

interface ActionToastProps {
  action?: string;
  text: string;
  onClick: () => void;
}

const ActionToast = ({ action, text, onClick }: ActionToastProps) => {
  const { t } = useArticlesTranslation();

  return (
    <div className="h-full flex justify-between items-center text-sm text-white font-custom">
      <span className="pl-4 pr-3">{text}</span>
      <span className="h-full w-[1px] bg-white opacity-30" />
      <a
        className="pl-3 pr-4 font-bold uppercase"
        role="button"
        tabIndex={0}
        onClick={onClick}
        onKeyDown={(e) => a11yKeydown(e, onClick)}
      >
        {action ? action : t('Got it')}
      </a>
    </div>
  );
};

export default ActionToast;
