/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import { FC } from 'react';
import ReactPlayer from 'react-player';

interface VideoProps {
  url: string;
}

const Video: FC<VideoProps> = ({ url }) => (
  <div
    className="w-full"
    onClick={(e) => {
      e.stopPropagation();
    }}
  >
    <Player
      className={cx('!w-full !h-full relative', {
        'pt-[56.25%]': window.matchMedia('(orientation: portrait)').matches,
      })}
      playing
      url={url}
      controls
    />
  </div>
);

const Player = styled(ReactPlayer)`
  & > video {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export default Video;
