import useGetActionAnswer from 'app/api/articles/hooks/useGetActionAnswer';
import useGetArticle from 'app/api/articles/hooks/useGetArticle';
import useGetArticleSharingStatusOverview from 'app/api/articleSharing/hooks/useGetArticleSharingStatusOverview';
import useGetAudienceQuery from 'app/api/audiences/hooks/useGetAudienceQuery';
import useGetAudienceUsersIdsQuery from 'app/api/audiences/hooks/useGetAudienceUsersIdsQuery';
import { Location } from 'app/api/auth/types';
import { Profession } from 'app/api/professions/types';
import { selectors } from 'app/store/editor';
import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import {
  ActionAnswerPageMeta,
  ActionAnswerResponse,
  Article,
  ArticleSharingStatus,
  Audience,
  SharingStatusOverviewResponse,
  SortOrderEnum,
} from 'submodules/common-ui/generated/api/gcs';

const uninitialisedFunction = () => new Error('Uninitialsed Function');

export const AnswersContext = createContext<AnswersContextValues>({
  assigneeAudiences: [],
  selectedAudiences: [],
  selectedLocations: [],
  selectedProfessions: [],
  actionAnswers: [],
  actionAnswersMeta: undefined,
  isLoadingActionAnswers: false,
  professions: undefined,
  locations: undefined,
  users: undefined,
  search: '',
  answerSearch: '',
  sortScore: undefined,
  sortCreatedAt: 'desc',
  layout: 'list',
  isLoadingAudience: false,
  actionAnswersError: undefined,
  isLoadingUserIds: false,
  isFetchingNextPage: false,
  tenants: undefined,
  isLoadingTenants: false,
  selectedTenant: undefined,
  article: undefined,
  isLoadingSharedArticle: false,
  fetchNextPage: uninitialisedFunction,
  setLayout: uninitialisedFunction,
  setAnswerSearch: uninitialisedFunction,
  setSearch: uninitialisedFunction,
  setSortCreatedAt: uninitialisedFunction,
  setSortScore: uninitialisedFunction,
  onAudienceChange: uninitialisedFunction,
  setSelectedLocations: uninitialisedFunction,
  setSelectedTaskId: uninitialisedFunction,
  setSelectedInstanceId: uninitialisedFunction,
  onProfessionChange: uninitialisedFunction,
  clearProfessions: uninitialisedFunction,
  onTenantChange: uninitialisedFunction,
});

interface AnswersContextValues {
  assigneeAudiences: Audience[];
  selectedAudiences: number[];
  selectedLocations: number[];
  selectedProfessions: number[];
  locations?: Location[];
  professions?: Profession[];
  users?: number[];
  actionAnswers: ActionAnswerResponse[];
  actionAnswersMeta?: ActionAnswerPageMeta;
  isLoadingActionAnswers: boolean;
  selectedTaskId?: number;
  selectedInstanceId?: number;
  isLoadingAudience: boolean;
  isLoadingUserIds: boolean;
  search: string;
  answerSearch: string;
  sortScore: SortOrderEnum | undefined;
  sortCreatedAt: SortOrderEnum | undefined;
  layout: 'list' | 'grid';
  actionAnswersError?: string | null;
  isFetchingNextPage: boolean;
  tenants?: SharingStatusOverviewResponse;
  isLoadingTenants: boolean;
  selectedTenant?: ArticleSharingStatus;
  article?: Article;
  isLoadingSharedArticle: boolean;
  setLayout: React.Dispatch<React.SetStateAction<'list' | 'grid'>>;
  setSortCreatedAt: React.Dispatch<
    React.SetStateAction<SortOrderEnum | undefined>
  >;
  setSortScore: React.Dispatch<React.SetStateAction<SortOrderEnum | undefined>>;
  setAnswerSearch: React.Dispatch<React.SetStateAction<string>>;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  setSelectedLocations: React.Dispatch<React.SetStateAction<number[]>>;
  setSelectedTaskId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setSelectedInstanceId: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  onProfessionChange: (id: number) => void;
  onAudienceChange: (id?: number) => void;
  onTenantChange: (tenant?: ArticleSharingStatus) => void;
  fetchNextPage: () => void;
  clearProfessions: () => void;
}

type ContextProps = {
  children: ReactNode;
};

const AnswersContextProvider = ({ children }: ContextProps) => {
  const [selectedAudiences, setSelectedAudiences] = useState<number[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<number[]>([]);
  const [selectedProfessions, setSelectedProfessions] = useState<number[]>([]);
  const [selectedTenant, setSelectedTenant] = useState<ArticleSharingStatus>();
  const [search, setSearch] = useState('');
  const [answerSearch, setAnswerSearch] = useState('');
  const [layout, setLayout] = useState<'list' | 'grid'>('list');
  const [selectedTaskId, setSelectedTaskId] = useState<number | undefined>();
  const [selectedInstanceId, setSelectedInstanceId] = useState<
    number | undefined
  >();

  const [sortCreatedAt, setSortCreatedAt] = useState<SortOrderEnum | undefined>(
    'desc'
  );
  const [sortScore, setSortScore] = useState<SortOrderEnum | undefined>('desc');

  const { article: sharedArticle, isLoading: isLoadingSharedArticle } =
    useGetArticle({
      id: Number(selectedTenant?.articleId),
      enabled: Boolean(
        selectedTenant?.articleId && selectedTenant?.originalArticleId
      ),
      filter: {
        originalArticleId: Number(selectedTenant?.originalArticleId),
        include: 'audiences',
      },
    });

  const originalArticle = useSelector(selectors.getArticle);
  const article = selectedTenant ? sharedArticle : originalArticle;

  const { data, isLoading } = useGetAudienceQuery({
    id: selectedAudiences[0],
    enabled: selectedAudiences[0] !== undefined,
  });

  const { data: users, isLoading: isLoadingUserIds } =
    useGetAudienceUsersIdsQuery({
      id: selectedAudiences[0],
      locations: selectedLocations,
      professions: selectedProfessions,
    });

  const {
    actionAnswers,
    actionAnswersMeta,
    isLoading: isLoadingActionAnswers,
    error,
    isFetchingNextPage,
    fetchNextPage,
  } = useGetActionAnswer({
    id: selectedTaskId || 0,
    originalArticleId: selectedTenant?.originalArticleId || undefined,
    perPage: 10,
    sortCreatedAt,
    sortScore,
    actionInstanceId: selectedInstanceId,
    userIds: users,
    enabled: selectedTaskId !== undefined && !isLoadingUserIds,
  });

  const { data: tenants, isLoading: isLoadingTenants } =
    useGetArticleSharingStatusOverview({
      articleId: originalArticle?.id ?? -1,
      enabled: originalArticle && originalArticle.isShared,
    });

  const professions = useMemo(() => data?.professions, [data]);
  const locations = useMemo(() => data?.locations, [data]);

  const assigneeAudiences = useMemo(() => {
    const assigneeAudienceIds: number[] = [];

    article?.blocks.forEach((block) => {
      if ('audiences' in block) {
        assigneeAudienceIds.push(...block.audiences);
      }
    });

    return (
      article?.audiencesResources?.filter((ar) => {
        return (
          Array.from(new Set(assigneeAudienceIds)).includes(ar.id) &&
          ar.name.toLowerCase().includes(search.toLowerCase())
        );
      }) || []
    );
  }, [article, search]);

  const onProfessionChange = (id: number) => {
    if (selectedProfessions.includes(id)) {
      setSelectedProfessions(selectedProfessions.filter((pid) => pid !== id));
    } else {
      setSelectedProfessions([...selectedProfessions, id]);
    }
  };

  const clearProfessions = () => setSelectedProfessions([]);

  const onAudienceChange = (id?: number) => {
    const newAudience = id ? (selectedAudiences.includes(id) ? [] : [id]) : [];
    setSelectedAudiences(newAudience);
    setSelectedLocations([]);
    setSelectedProfessions([]);
  };

  const onTenantChange = (sharedArticleId?: ArticleSharingStatus) => {
    setSelectedTenant(sharedArticleId);
  };

  const contextValue: AnswersContextValues = {
    assigneeAudiences,
    selectedAudiences,
    selectedLocations,
    selectedProfessions,
    professions,
    locations,
    users,
    actionAnswers,
    actionAnswersMeta,
    selectedTaskId,
    selectedInstanceId,
    search,
    answerSearch,
    sortCreatedAt,
    sortScore,
    layout,
    isLoadingAudience: isLoading,
    isLoadingActionAnswers,
    actionAnswersError: error,
    isLoadingUserIds,
    isFetchingNextPage,
    tenants,
    isLoadingTenants,
    selectedTenant,
    article,
    isLoadingSharedArticle,
    fetchNextPage,
    setLayout,
    setSortScore,
    setSortCreatedAt,
    setAnswerSearch,
    onAudienceChange,
    onProfessionChange,
    setSearch,
    setSelectedTaskId,
    setSelectedInstanceId,
    setSelectedLocations,
    clearProfessions,
    onTenantChange,
  };

  return (
    <AnswersContext.Provider value={contextValue}>
      {children}
    </AnswersContext.Provider>
  );
};

const useAnswersContext = () => {
  const context = useContext(AnswersContext);

  if (context === undefined) {
    throw new Error(
      'useAnswersContext must be used within a AnswersContextProvider'
    );
  }

  return context;
};

export { AnswersContextProvider, useAnswersContext };
export type { AnswersContextValues };
