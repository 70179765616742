import { useEditor, useNode } from '@craftjs/core';
import { cx } from '@emotion/css';
import useIsEditorEnabled from 'app/hooks/useIsEditorEnabled';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import apiToLexical from 'app/pages/ArticleEditor/helpers/lexical/apiToLexical';
import lexicalInitialState from 'app/pages/ArticleEditor/helpers/lexical/initialState';
import { lexicalToApi } from 'app/pages/ArticleEditor/helpers/lexical/lexicalToApi';
import { CloseCircle, MinusCirlce, TickCircle } from 'iconsax-react';
import { useCallback, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { Paragraph } from 'submodules/common-ui/generated/api/gcs';

import LexicalInputWrapper from './Lexical/LexicalInputWrapper';

export interface YesNoNaOptionProps {
  correct?: boolean | null;
  text?: Paragraph;
}

const YesNoNaOption = ({ correct = null, text }: YesNoNaOptionProps) => {
  const { t } = useArticlesTranslation();
  const [emptyOptionsError, setEmptyOptionsError] = useState(false);

  const getInitialInputstate = () => {
    if (!text) {
      return lexicalInitialState();
    }

    const parsedToEditor = apiToLexical(text);

    return JSON.stringify(parsedToEditor);
  };

  const {
    connectors: { select },
    query: { node: getNode },
    nodes,
  } = useEditor((state) => ({ nodes: state.nodes }));

  const {
    connectors: { connect },
    actions: { setProp },
    parent,
    index,
  } = useNode((node) => {
    let parents: Array<string> = [];
    let children: Array<string> = [];

    if (node.data?.parent) {
      const { ancestors, childNodes } = getNode(node.data.parent);
      parents = ancestors();
      children = childNodes();
    }

    return {
      container: node.data.parent,
      parent: parents[0] ?? null,
      nodeId: node.id,
      index: Array.from(children).indexOf(node.id) + 1,
    };
  });

  const blockSelected = useCallback(
    () => nodes[parent].events.selected,
    [nodes, parent]
  );

  const { enabled } = useIsEditorEnabled();
  type StatusEnum = 'correct' | 'incorrect' | 'neutral';
  const btnClass = 'p-1 rounded-md';

  const renderTooltip = (status: StatusEnum) => {
    switch (status) {
      case 'correct':
        return (
          <div>
            <div className="font-bold font-sm text-center">
              {t('Mark as Correct')}
            </div>
            <div className="font-xs text-center font-normal">
              {t(
                "Select this when the answer is intended to be the right choice. It's used to indicate a preferred or accurate response."
              )}
            </div>
          </div>
        );
      case 'incorrect':
        return (
          <div>
            <div className="font-bold font-sm text-center">
              {t('Mark as Incorrect')}
            </div>
            <div className="font-xs text-center font-normal">
              {t(
                'Choose this for answers that are not the intended or correct choice. It helps identify responses that are less accurate or not preferred.'
              )}
            </div>
          </div>
        );
      case 'neutral':
        return (
          <div>
            <div className="font-bold font-sm text-center">
              {t('Mark as Neutral')}
            </div>
            <div className="font-xs text-center font-normal">
              {t(
                'Use this for answers where correctness is not applicable, such as in surveys. This option shows that the response neither aligns with nor opposes the preferred answer.'
              )}
            </div>
          </div>
        );
    }
  };

  return (
    <div ref={(ref) => ref && connect(ref)}>
      <div
        ref={(ref) => ref && select(ref, parent)}
        className="flex items-center mt-2 relative"
      >
        <div className="font-sm font-normal text-gray-dark pl-0.5">
          {index.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}
        </div>
        <LexicalInputWrapper
          initialState={getInitialInputstate()}
          disabled={!enabled || !blockSelected()}
          error={enabled ? emptyOptionsError : false}
          onChange={(state) => {
            const lexicalText = lexicalToApi(state.root.children);

            setEmptyOptionsError(false);
            if (
              lexicalText.children.length === 0 ||
              (lexicalText.children[0]?.type === 'text' &&
                !lexicalText.children[0].value)
            ) {
              setEmptyOptionsError(true);
            }
            setProp((props: YesNoNaOptionProps) => {
              props.text = lexicalText;
            });
          }}
        />

        <div className="flex items-center">
          <div className="flex items-center">
            <button
              className={cx(btnClass, {
                'bg-focus-background': correct === true,
              })}
              data-tip="correct"
              data-for="status"
              disabled={!enabled}
            >
              <TickCircle
                className="w-4.5 h-4.5 text-success"
                onClick={() =>
                  setProp((props: YesNoNaOptionProps) => {
                    props.correct = true;
                  })
                }
              />
            </button>
            <button
              className={cx(btnClass, {
                'bg-error-light': correct === false,
              })}
              disabled={!enabled}
              data-tip="incorrect"
              data-for="status"
            >
              <CloseCircle
                className="w-4.5 h-4.5 text-error"
                onClick={() =>
                  setProp((props: YesNoNaOptionProps) => {
                    props.correct = false;
                  })
                }
              />
            </button>
            <button
              className={cx(btnClass, {
                'bg-warning-light': correct === null,
              })}
              disabled={!enabled}
              data-tip="neutral"
              data-for="status"
            >
              <MinusCirlce
                className="w-4.5 h-4.5 text-warning"
                onClick={() =>
                  setProp((props: YesNoNaOptionProps) => {
                    props.correct = null;
                  })
                }
              />
            </button>
          </div>
        </div>
      </div>

      {enabled && (
        <>
          <ReactTooltip
            effect="solid"
            class="react-tooltip max-w-xs"
            id="status"
            getContent={(dataTip) => renderTooltip(dataTip as StatusEnum)}
          />
          <ReactTooltip
            place="top"
            effect="solid"
            class="react-tooltip"
            id="delete"
          />
        </>
      )}
    </div>
  );
};

YesNoNaOption.craft = {
  displayName: 'YesNoNaOption',
};

export default YesNoNaOption;
