import { Response } from 'app/api/types';

import { request } from '../../request';

const per_page = 20;

export interface GetAudienceUsersProps {
  id: number;
  locations?: number[];
  professions?: number[];
}

interface GetAudienceUsersIdsResponse {
  ids: number[];
}

const getAudienceUsersIds = (params: GetAudienceUsersProps) => {
  const { id, locations, professions } = params;

  return request().get<Response<GetAudienceUsersIdsResponse>>(
    `/api/v1/audiences/${id}/users`,
    {
      params: {
        id,
        page: 1,
        per_page: per_page,
        locations: locations?.toString(),
        professions: professions?.toString(),
        only_user_id: true,
      },
    }
  );
};

export { getAudienceUsersIds };
export default getAudienceUsersIds;
