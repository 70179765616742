import { useComponentsTranslation } from 'app/internationalization/hooks';
import dayjs from 'dayjs';

interface Props {
  type: 'relativeTime' | 'formattedDate' | 'fullDate' | 'dayMonthYearTime';
  timestamp: string;
}

const FORMAT = 'DD.MM.YYYY';
const FULL_DATE_FORMAT = 'DD MMM YYYY';
const RELATIVE_FORMAT = 'DD.MM.YYYY';
const DAY_MONTH_YEAR_TIME_FORMAT = 'DD/MM/YYYY [at] HH:mm';

const useDateTimeFormat = ({ timestamp, type }: Props) => {
  const { t } = useComponentsTranslation();

  const time = dayjs(timestamp);
  const now = dayjs();

  const diffInHours = now.diff(time, 'hour');
  const diffInDays = now.diff(time, 'day');

  const hoursAgo = `${diffInHours === 1 ? t('hour') : t('hours')} ${t('ago')}`;
  const daysAgo = `${diffInDays === 1 ? t('day') : t('days')} ${t('ago')}`;

  const getRelativeTime = (format: string) => {
    if (diffInHours === 0) return t('now');
    if (diffInHours < 24) return `${diffInHours} ${hoursAgo}`;
    if (diffInDays >= 1 && diffInDays < 3) return `${diffInDays} ${daysAgo}`;

    return time.format(format);
  };

  const getFormattedDate = () => {
    switch (diffInDays) {
      case 0:
        return t('Today');
      case 1:
        return t('Yesterday');
      case 2:
      case 3:
        return `${diffInDays} ${daysAgo}`;
      default:
        break;
    }

    return time.format(FORMAT);
  };

  const getDayMonthYearTime = () => {
    return time.format(DAY_MONTH_YEAR_TIME_FORMAT);
  };

  switch (type) {
    case 'fullDate':
      return getRelativeTime(FULL_DATE_FORMAT);
    case 'relativeTime':
      return getRelativeTime(RELATIVE_FORMAT);
    case 'formattedDate':
      return getFormattedDate();
    case 'dayMonthYearTime':
      return getDayMonthYearTime();
    default:
      break;
  }
};

export default useDateTimeFormat;
