import { useEffect, useRef, useState } from 'react';
import { ActionAnswerPageMeta } from 'submodules/common-ui/generated/api/gcs';

const useFetchComments = (
  fetchFn: () => void,
  isLoading: boolean,
  isFetching: boolean,
  meta?: ActionAnswerPageMeta
) => {
  const [hasMorePages, setHasMorePages] = useState<boolean>(false);
  const threshold = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!meta) return;
    setHasMorePages(meta.currentPage < meta.lastPage);
  }, [meta]);

  useEffect(() => {
    const isMoreComments =
      hasMorePages && !isFetching && threshold.current && !isLoading;

    if (isMoreComments) {
      const options: IntersectionObserverInit = {
        root: null,
        rootMargin: '0px',
        threshold: 1,
      };
      const observer = new IntersectionObserver((entities) => {
        const target = entities[0];

        if (target.isIntersecting) fetchFn();
      }, options);

      observer.observe(threshold.current);
    }
  }, [hasMorePages, isFetching, fetchFn, isLoading]);

  return { hasMorePages, threshold };
};

export default useFetchComments;
