import { FIRST_STEP, SECOND_STEP } from 'app/utils/steps';
import { useMemo } from 'react';

const useForwardArticleSteps = ({
  step,
  isShared,
}: {
  step: number;
  isShared: boolean;
}) =>
  useMemo(() => {
    const isFirstStep = step === FIRST_STEP;
    const isSecondStep = step === SECOND_STEP;

    const isFirstOrSecondStep = isFirstStep || isSecondStep;

    const isFirstOrSecondStepCurrent =
      (isShared && isFirstStep) || (!isShared && isSecondStep);

    const isTenantSelectVisible =
      (isShared && isFirstOrSecondStep) || (!isShared && isSecondStep);

    const isCommentVisible = isSecondStep;

    const isSummaryVisible = !isShared && isFirstStep;

    const isSubmitAllowed = isSecondStep;

    return {
      isFirstOrSecondStepCurrent,
      isTenantSelectVisible,
      isCommentVisible,
      isSubmitAllowed,
      isSummaryVisible,
    };
  }, [step, isShared]);

export default useForwardArticleSteps;
