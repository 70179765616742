import { Element } from '@craftjs/core';
import { LockCircle } from 'iconsax-react';
import { FC } from 'react';

import HeadingSectionContainer from './HeadingSectionsContainer';

interface EndUserModeProps {
  isSectionBlocked: boolean;
  title: string;
}

const EndUserMode: FC<EndUserModeProps> = ({ isSectionBlocked, title }) => {
  if (isSectionBlocked) {
    return (
      <div className="w-full rounded-lg bg-fade-blue border border-hover-blue flex flex-col items-center">
        <div className="pt-6 px-6 flex flex-col items-center gap-2">
          <div className="flex gap-2">
            <LockCircle size={28} variant="Linear" className="text-ceil" />
            <span className="font-bold text-grayscale-primary text-lg">
              Locked
            </span>
          </div>
          <span className="text-base text-grayscale-secondary">
            Unlock by completing actions
          </span>
        </div>
        <div className="px-6 pb-6 w-full text-center">
          <hr className="my-3 w-full border-t border-gray-silver-blue" />
          <span className="font-bold text-grayscale-primary text-lg opacity-20">
            {title}
          </span>
        </div>
      </div>
    );
  }

  return (
    <Element id="headingSectionContainer" is={HeadingSectionContainer} canvas />
  );
};

export default EndUserMode;
