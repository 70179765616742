import { useEditor, useNode } from '@craftjs/core';
import useIsEditorEnabled from 'app/hooks/useIsEditorEnabled';
import { editorTypes } from 'app/router/constants';
import { selectors } from 'app/store/editor';
import isVisible from 'app/utils/isVisible';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const useIsSelected = (nodeId: string) => {
  const {
    actions: { selectNode },
  } = useEditor();
  const { ref } = useNode((node) => ({
    ref: node.dom,
  }));

  const { enabled } = useIsEditorEnabled();
  const { mode } = useParams<{ mode: string }>();
  const article = useSelector(selectors.getArticle);

  const notNew =
    article?.blocks?.some((block) => block.id.toString() === nodeId) ?? false;

  useEffect(() => {
    if (
      !enabled ||
      !nodeId ||
      !isNaN(Number(nodeId)) ||
      (mode && mode !== editorTypes.edit) ||
      notNew
    )
      return;

    selectNode(nodeId);
  }, [enabled, nodeId, selectNode, mode, notNew]);

  useEffect(() => {
    if (!ref || isVisible(ref) || (mode && mode !== editorTypes.edit) || notNew)
      return;

    ref.scrollIntoView();
  }, [ref, mode, notNew]);
};

export default useIsSelected;
