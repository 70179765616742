import { useQuery } from 'react-query';

import { resources, queryKeys } from '../index';

import { CACHE_TIME } from './constants';

interface Props {
  enabled?: boolean;
  subdomain: string;
}

const useGetLinksQuery = ({ enabled = true, subdomain }: Props) => {
  const { data, isLoading, error, isFetching, refetch } = useQuery(
    queryKeys.links(),
    () => resources.getLinks(subdomain),
    { enabled, staleTime: CACHE_TIME }
  );

  return {
    data: data?.data,
    isFetching,
    isLoading,
    error,
    refetch,
  };
};

export default useGetLinksQuery;
