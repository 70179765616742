import { queryKeys, resources } from 'app/api/articles';
import queryClient from 'app/query';
import { useMutation } from 'react-query';

const useUnarchiveArticleMutation = () => {
  const { mutate, isLoading, isError, data } = useMutation(
    resources.unArchiveArticle,
    {
      onSettled: (res, _) => {
        const resultId = res?.[0]?.id;
        if (!res || !resultId) return;

        queryClient.invalidateQueries(queryKeys.article(resultId));
        queryClient.invalidateQueries(['articles']);
      },
    }
  );

  return { mutate, isLoading, isError, data };
};

export default useUnarchiveArticleMutation;
