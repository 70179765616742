import useCommentTranslation from 'app/internationalization/hooks/useCommentTranslation';
import { actions } from 'app/store/comments';
import { clearUrlHash } from 'app/utils';
import MessageIcon from 'assets/icons/message.svg?react';
import { useDispatch } from 'react-redux';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';

import { useCommentsContext } from '../context';
import useCommentUrlHash from '../hooks/useCommentUrlHash';

const CommentHeader = () => {
  const { t } = useCommentTranslation();
  const dispatch = useDispatch();
  const { comments, allowComments } = useCommentsContext();
  const { commentId } = useCommentUrlHash();

  return (
    <div className="flex justify-between w-full p-6 pb-3 bg-white">
      <div className="flex flex-col">
        <div className="flex items-center gap-2 text-focus [&_svg]:h-5 [&_svg]:w-5">
          <MessageIcon className="[&_path]:stroke-current" />
          <span className="font-bold text-black lowercase">
            {allowComments
              ? `${comments.length} ${
                  comments.length === 1 ? t('Comment') : t('Comments')
                }`
              : ''}
          </span>
        </div>
        {commentId && (
          <button
            className="text-focus text-sm font-bold mt-4"
            onClick={clearUrlHash}
          >
            {t('Show all comments')}
          </button>
        )}
      </div>

      <CloseLineIcon
        className="cursor-pointer"
        size={23}
        onClick={() => dispatch(actions.hideCommentModal())}
      />
    </div>
  );
};

export default CommentHeader;
