import { ArticleFilter as ArticleFilters } from 'app/pages/Articles/context';
import { SortOrderEnum } from 'submodules/common-ui/generated/api/gcs';

import { ArticleFilter, BacklinkFilter } from './types';

export default {
  actionAnswer: (
    id: number,
    userIds?: number[],
    sortScore?: SortOrderEnum,
    sortCreatedAt?: SortOrderEnum,
    actionInstanceId?: number,
    answerIds?: number[]
  ) => [
    'actionAnswer',
    id,
    userIds,
    sortScore,
    sortCreatedAt,
    actionInstanceId,
    answerIds,
  ],
  actionInstance: (
    id: number,
    start?: SortOrderEnum,
    startFrom?: string,
    startTo?: string
  ) => ['actionInstance', id, start, startFrom, startTo],
  scormAction: (
    blockId: number,
    actionInstanceId?: number,
    timezone?: string
  ) => ['scormAction', blockId, actionInstanceId, timezone],
  articles: (
    page: number,
    filters: ArticleFilters,
    nodeId?: string,
    language?: string
  ) => ['articles', page, filters, nodeId, language],
  article: (id: number, filter?: ArticleFilter) => [
    'article',
    id,
    filter ?? [],
  ],
  backlinks: (articleId: number, filter?: BacklinkFilter) => [
    'backlinks',
    articleId,
    filter,
  ],
  file: (id: string, name: string) => ['file', id, name],
  articleBlock: (articleId: number, blockId: number) => [
    'articleBlock',
    articleId,
    blockId,
  ],
  actionAnswerSummary: (actionId: number, actionInstanceId?: number) => [
    'actionAnswerSummary',
    actionId,
    actionInstanceId,
  ],
};
