import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import { ResponsiveImage } from 'app/components';
import config from 'app/config';
import { FC } from 'react';
import {
  InternalFileGet,
  Paragraph,
} from 'submodules/common-ui/generated/api/gcs';

import editorTextToHtml from '../../../../utils/editorTextToHtml';

interface Props {
  text?: Paragraph;
  imageUri?: InternalFileGet;
  isSelected: boolean;
  canAnswer: boolean;
  choicePercentage?: number;
  onClick: () => void;
  completedByColleague?: boolean;
  isAddingOwnAnswer?: boolean;
}

const PollAnswerOption: FC<Props> = (props) => {
  const {
    text,
    imageUri,
    isSelected,
    canAnswer,
    choicePercentage,
    completedByColleague,
    onClick,
    isAddingOwnAnswer,
  } = props;

  const hasTextContent = !!text?.children.length;

  const renderSmallContainer = () => {
    return (
      <div className="flex items-center h-11 relative">
        {imageUri && (
          <div className="w-16 h-full relative group">
            <ResponsiveImage
              alt={imageUri.url}
              className="object-cover w-full h-full rounded-l-lg"
              directUrls={imageUri?.directUrls}
            />
            <div
              className={cx(
                'absolute top-0 left-0 transition-transform transform scale-0 group-hover:scale-100 group-hover:w-[331px] group-hover:h-[210px] z-20 flex justify-center items-center rounded-lg overflow-hidden'
              )}
            >
              <ResponsiveImage
                alt={imageUri.url}
                className="object-cover w-full h-full rounded-lg"
                directUrls={imageUri?.directUrls}
              />
            </div>
          </div>
        )}

        {hasTextContent ? (
          <p className="truncate flex-1 px-2">{editorTextToHtml(text)}</p>
        ) : (
          <div className="flex-1" />
        )}
        {(!canAnswer && choicePercentage !== undefined) ||
        (completedByColleague &&
          !isAddingOwnAnswer &&
          choicePercentage !== undefined) ? (
          <p className="px-2">{choicePercentage}%</p>
        ) : null}
      </div>
    );
  };

  const renderLargeContainer = () => {
    return (
      <>
        <div
          className={cx('flex z-10', {
            'px-3 py-2': hasTextContent || !canAnswer,
          })}
        >
          {hasTextContent ? (
            <p className="grow mr-4">{editorTextToHtml(text)}</p>
          ) : (
            <div className="flex-1" />
          )}
          {(!canAnswer && choicePercentage !== undefined) ||
          (completedByColleague &&
            !isAddingOwnAnswer &&
            choicePercentage !== undefined) ? (
            <p className="px-2">{choicePercentage}%</p>
          ) : null}
        </div>
        {imageUri && (
          <div className="w-full p-2">
            <ResponsiveImage
              alt={imageUri.url}
              directUrls={imageUri.directUrls}
              className="h-[210px] self-stretch w-full z-10 rounded-lg"
            />
          </div>
        )}
      </>
    );
  };

  const renderOption = () => {
    if (canAnswer) return renderLargeContainer();
    return renderSmallContainer();
  };

  const PercentageBar = styled.span`
    ${!canAnswer &&
    choicePercentage !== undefined &&
    `background: linear-gradient(
      to right,
      ${config.colors['focus-background']} ${choicePercentage}%,
      transparent ${choicePercentage - 100}%
    );`}
  `;

  return (
    <PercentageBar
      className={cx(
        'rounded-lg border relative cursor-pointer flex flex-col align-start',
        {
          'border border-focus': isSelected,
          'border border-gray-dark': !isSelected,
          'bg-focus-background': isSelected && canAnswer,
          'bg-grayscale-bg-dark': !isSelected && canAnswer,
        }
      )}
      role="presentation"
      onClick={canAnswer ? onClick : undefined}
    >
      {renderOption()}
    </PercentageBar>
  );
};

export default PollAnswerOption;
