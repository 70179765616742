const LoadingOpenQuestionTasks = () => {
  return (
    <div className="flex gap-1">
      <div className="w-7 h-7 rounded bg-focus-background flex items-center justify-center" />

      <div className="w-full rounded-ss-xs rounded-se-xl rounded-ee-xl rounded-es-xl bg-hover-blue py-[10px] px-3 flex flex-col gap-2.5">
        <div className="w-full flex flex-col gap-1">
          <div className="flex justify-between text-sm">
            <div className="h-5 w-21 bg-focus-background rounded" />

            <div className="h-5 w-14 bg-focus-background rounded" />
          </div>

          <div className="flex gap-1 items-center text-grayscale-secondary text-sm">
            <div className="h-5 w-18 bg-focus-background rounded" />

            <div className="h-5 w-10 bg-focus-background rounded" />
          </div>
        </div>

        <div className="h-5  bg-focus-background rounded" />
      </div>
    </div>
  );
};

export default LoadingOpenQuestionTasks;
