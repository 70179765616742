import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import useCommentsQuery from 'app/api/comments/hooks/useCommentsQuery';
import { ComplaintTypes } from 'app/api/complaints/constants';
import useCommentReaction from 'app/api/reactions/hooks/useCommentReaction';
import useCreateReactionMutation from 'app/api/reactions/hooks/useCreateReaction';
import useDeleteReactionMutation from 'app/api/reactions/hooks/useDeleteReaction';
import CommentPopup from 'app/components/Comments/CommentPopup';
import { Reaction } from 'app/components/Reactions';
import { useScreenBreakpoint, useTranslations } from 'app/hooks';
import useDateTimeFormat from 'app/hooks/useDateTimeFormat';
import useCommentTranslation from 'app/internationalization/hooks/useCommentTranslation';
import ReactionsList from 'app/pages/Editor/Reactions/ReactionsList';
import useUserQuery from 'app/pages/profiles/PublicProfile/useUserQuery';
import { routes } from 'app/router';
import { isMobile } from 'app/utils';
import { Translate as TranslateIcon } from 'iconsax-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import ArrowBack from 'remixicon-react/ArrowGoBackLineIcon';
import {
  DeleteReactionByEmojiParams,
  NewReaction,
  EmojiEnum,
  Text,
  ReactionCounts,
} from 'submodules/common-ui/generated/api/gcs';

import { NestedComment, useCommentsContext } from '../context';
import useCommentUrlHash from '../hooks/useCommentUrlHash';

import CommentReplies from './CommentReplies';

interface Props {
  isReply?: boolean;
  comment: NestedComment;
}

const CommentItem = ({ comment, isReply }: Props) => {
  const { t } = useCommentTranslation();
  const [activeEmoji, setActiveEmoji] = useState<EmojiEnum>();
  const [reactions, setReactions] = useState<ReactionCounts>(
    comment.reactionCounts || {}
  );
  const [userReactions, setUserReactions] = useState<EmojiEnum[]>(
    comment.currentUserReactions || []
  );
  const timeAgo = useDateTimeFormat({
    timestamp: comment.createdAt,
    type: 'relativeTime',
  });

  const {
    commentPopupId,
    currentUser,
    errorCommentObject,
    metaComments,
    articleId,
    isRefetchingReplies,
    setIsRefetchingReplies,
    onCreateComment,
    setCommentAction,
    setCommentPopupId,
    setText,
    setUserLoading,
    onDeleteComment,
    isHiddenOrBlocked,
  } = useCommentsContext();

  const {
    data: repliesData,
    isLoading: isRepliesLoading,
    isFetching: isRepliesFetching,
    meta: metaReplies,
    refetch: refetchReplies,
    fetchNextPage: fetchReplies,
  } = useCommentsQuery({
    objectId: comment.id,
    objectType: 'comment',
    page: 0,
    createdAt: 'asc',
    enabled: !comment.parentCommentId && comment.id > 0,
  });

  const { commentId } = useCommentUrlHash();

  const { data: user, isLoading } = useUserQuery({
    id: comment.createdBy,
  });

  const parentId = useMemo(
    () => (isReply ? comment.parentCommentId : articleId),
    [articleId, comment.parentCommentId, isReply]
  );

  const { setCommentReaction } = useCommentReaction();

  const { mutate: createReaction } = useCreateReactionMutation();
  const { mutate: deleteReaction } = useDeleteReactionMutation();

  const breakpoint = useScreenBreakpoint();
  const mobile = isMobile(breakpoint);

  const hiddenReplies: NestedComment[] = useMemo(
    () =>
      repliesData
        .filter((reply) => reply.parentCommentId === comment.id)
        .map((reply) => ({
          ...reply,
          isHidden: isHiddenOrBlocked(
            reply.id,
            reply.createdBy,
            ComplaintTypes.articleCommentReply
          ),
        })),
    [comment.id, isHiddenOrBlocked, repliesData]
  );

  const replies = useMemo(
    () => hiddenReplies?.filter(({ isHidden }) => !isHidden) || [],
    [hiddenReplies]
  );

  useEffect(() => {
    if (!isRefetchingReplies) return;
    refetchReplies().then(() => setIsRefetchingReplies(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefetchingReplies]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [reactions]);

  useEffect(() => {
    setUserLoading(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const interfaceLanguage = currentUser?.contentLanguage.uiLanguage;
  const commentVariant =
    (interfaceLanguage && comment.variants[interfaceLanguage]) ||
    comment.variants[comment.defaultLanguage];
  const commentText = commentVariant?.[0]?.children[0] as Text;

  const {
    translations: [translatedText],
    isTranslated,
    isTranslating,
    toggleTranslation,
  } = useTranslations([commentText.value]);

  const containerRef = useRef<HTMLDivElement>(null);

  if (!user) return null;

  const isMainComment = !comment.parentCommentId;
  const fullName = `${user.firstName} ${user.lastName}`;

  const onReact = (emoji: EmojiEnum) => {
    const payload: DeleteReactionByEmojiParams | NewReaction = {
      objectId: comment.id,
      objectType: 'comment',
      emoji,
    };

    if (userReactions?.includes(emoji))
      return deleteReaction(payload, {
        onSuccess: () => {
          setCommentReaction({
            type: 'remove',
            parentId,
            emoji,
            objectId: comment.id,
            currentPage: metaComments?.currentPage,
          });
          setUserReactions((prevUserReactions) => {
            const updatedReactions = [...prevUserReactions];
            updatedReactions.splice(updatedReactions.indexOf(emoji), 1);

            return updatedReactions;
          });
          setReactions((prevReactions) => {
            const updatedReactions = {
              ...prevReactions,
              [emoji]: (prevReactions[emoji] || 0) - 1,
            };
            if (updatedReactions[emoji] === 0) delete updatedReactions[emoji];

            return updatedReactions;
          });
        },
      });

    return createReaction(payload, {
      onSuccess: () => {
        setCommentReaction({
          type: 'add',
          parentId,
          emoji,
          objectId: comment.id,
          currentPage: metaComments?.currentPage,
        });
        setUserReactions((prevUserReactions) => [...prevUserReactions, emoji]);
        setReactions((prevReactions) => {
          const prevReaction = prevReactions[emoji];

          if (!prevReaction) return { ...prevReactions, [emoji]: 1 };

          return {
            ...prevReactions,
            [emoji]: prevReaction + 1,
          };
        });
      },
    });
  };

  const onReply = () => {
    setCommentAction({ commentId: comment.id, type: 'reply' });
    setText('');
  };

  const hasUserEditedComment =
    currentUser?.id === comment.createdBy && !!comment.updatedAt;
  const isNotEditedReply = !hasUserEditedComment && !isMainComment;
  const isAddCommentError = comment.id === errorCommentObject?.id;

  if (parseInt(commentId ?? '') === comment.id) {
    containerRef.current?.scrollIntoView({});
  }

  return (
    <div
      ref={containerRef}
      className={cx({
        'pb-5 px-6': isMainComment,
        'pb-4': !isMainComment,
      })}
    >
      <div
        className="flex w-full"
        onMouseEnter={() => setCommentPopupId(comment.id)}
        onMouseLeave={() => setCommentPopupId(undefined)}
      >
        <Link
          className={cx('mr-2', {
            'h-8': isMainComment,
            'h-6': !isMainComment,
          })}
          to={routes.user.create(comment.createdBy)}
        >
          <img
            className={cx('rounded', {
              'min-w-8 w-8 h-8': isMainComment,
              'min-w-6 w-6 h-6': !isMainComment,
            })}
            src={user.avatars?.small}
            alt={fullName}
          />
        </Link>
        <div
          className={cx(
            'w-full rounded-tl-sm rounded-xl py-2.5 px-3 bg-hover-blue text-sm',
            { 'border border-focus': parseInt(commentId ?? '') === comment.id }
          )}
        >
          <div className="flex justify-between mb-1">
            <span className="font-bold text-grayscale-primary">{fullName}</span>
            <span className="text-grayscale-secondary">{timeAgo}</span>
          </div>
          <div className="flex items-center text-grayscale-secondary mb-1.5">
            {user.profession.name}
            <span className="w-0.5 h-0.5 mx-1 bg-gray-dark rounded-full"></span>
            {user.location?.name}
          </div>
          <div
            className={cx('flex', {
              'justify-between': isNotEditedReply,
              'flex-col': !isNotEditedReply,
            })}
          >
            <StyledTranslatedText
              className={cx('text-base text-grayscale-primary', {
                'mb-3': !isNotEditedReply,
              })}
            >
              {translatedText}
            </StyledTranslatedText>
            <div className="flex justify-between">
              <div>
                {hasUserEditedComment && (
                  <span className="mr-2 text-sm text-grayscale-secondary">
                    {t('edited')}
                  </span>
                )}
                {isAddCommentError && (
                  <button
                    className="font-bold text-sm text-error"
                    onClick={onCreateComment}
                  >
                    {t('Couldn’t post. Tap to retry')}
                  </button>
                )}
                {isMainComment && !isAddCommentError && (
                  <button
                    className="font-bold text-sm text-grayscale-secondary"
                    onClick={onReply}
                  >
                    {t('Reply')}
                  </button>
                )}
              </div>
              <button onClick={toggleTranslation} disabled={isTranslating}>
                {isTranslated ? (
                  <ArrowBack className="text-grayscale-secondary w-5 h-5" />
                ) : (
                  <TranslateIcon className="text-grayscale-secondary w-5 h-5" />
                )}
              </button>
            </div>
          </div>
          <div className="flex flex-wrap gap-1.5 mt-1.5">
            {Object.entries(reactions)
              .sort((a, b) => (a[0] < b[0] ? -1 : a[0] > b[0] ? 1 : 0))
              .map(([key, count]) => {
                const emoji = key as EmojiEnum;

                return (
                  <Reaction
                    key={key}
                    animated={mobile}
                    className={cx(
                      'flex items-center justify-center gap-1 h-8 px-2 rounded-[20px] [&_svg]:h-6 [&_svg]:w-6 text-xs text-hover-primary border border-transparent shadow-atobi transition-all duration-300',
                      {
                        'bg-focus-background !border-focus':
                          userReactions?.includes(emoji),
                      }
                    )}
                    data-tip
                    data-for={`emoji-${comment.id}`}
                    emoji={emoji}
                    text={<span className="min-w-5">{count}</span>}
                    onClick={() => onReact(emoji)}
                    onMouseEnter={() => setActiveEmoji(emoji)}
                    onMouseLeave={() => setActiveEmoji(undefined)}
                  />
                );
              })}
          </div>
        </div>
        {commentPopupId === comment.id && !isAddCommentError && (
          <CommentPopup
            commentId={comment.id}
            createdBy={comment.createdBy}
            fullName={fullName}
            isParentComment={isMainComment}
            onReact={onReact}
            onReply={onReply}
            onDeleteComment={onDeleteComment}
            onEditComment={(id) =>
              setCommentAction({ commentId: id, type: 'edit' })
            }
          />
        )}
      </div>
      {comment.commentCount > 0 && replies.length > 0 && (
        <CommentReplies
          mainComment={comment}
          replies={replies}
          fetch={fetchReplies}
          isFetching={isRepliesFetching}
          isLoading={isRepliesLoading}
          meta={metaReplies}
        />
      )}
      <ReactTooltip
        class="react-tooltip"
        effect="solid"
        id={`emoji-${comment.id}`}
        place="top"
        getContent={[
          () => {
            const reactionCount = activeEmoji
              ? reactions[activeEmoji]
              : undefined;
            return activeEmoji && reactionCount && reactionCount > 0 ? (
              <ReactionsList
                count={reactionCount}
                emoji={activeEmoji}
                objectId={comment.id}
                objectType="comment"
                selected={userReactions?.includes(activeEmoji)}
              />
            ) : (
              false
            );
          },
          0,
        ]}
      />
    </div>
  );
};

const StyledTranslatedText = styled.p`
  word-break: break-word;
`;

export default CommentItem;
