import { request } from 'app/api/gcsRequest';
import config from 'app/config';
import { TranslateApi } from 'common-ui/generated/api/gcs';

const translateArticle = ({
  articleId,
  toLanguage,
}: {
  articleId: number;
  toLanguage: string;
}) =>
  new TranslateApi(undefined, config.env.gcsApiUrl, request())
    .postTranslate({ articleId, toLanguage })
    .then((response) => response.data);

export { translateArticle };
export default translateArticle;
