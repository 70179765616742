import { useEditor, useNode } from '@craftjs/core';
import { ResizeableTextarea } from 'app/components';
import useIsEditorEnabled from 'app/hooks/useIsEditorEnabled';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import BlockTypes from 'app/pages/Editor/helpers/constants';
import { useEffect } from 'react';

import BaseBlockContainer from '../BaseBlockContainer';

interface TitleBlockProps {
  text?: string;
}

const TitleBlock = ({ text = '' }: TitleBlockProps) => {
  const { enabled } = useIsEditorEnabled();
  const { t } = useArticlesTranslation();

  const {
    actions: { setProp },
    connectors: { connect },
    id,
    selected,
  } = useNode((state) => ({
    selected: state.events.selected,
  }));

  const {
    actions: { selectNode },
  } = useEditor();

  useEffect(() => {
    selectNode(id);
  }, [selectNode, id]);

  return (
    <div ref={(ref) => ref && connect(ref)} className="px-2">
      <BaseBlockContainer
        deleteOnBackspace={false}
        draggable={false}
        nodeId={id}
        selected={selected}
        showActions={false}
        type="Title"
      >
        <ResizeableTextarea
          className="w-full relative px-2 py-3 outline-none cursor-text bg-transparent focus-visible:bg-white rounded-lg text-3xl placeholder-gray-dark font-custom disabled:cursor-default overflow-hidden resize-none"
          defaultHeight={0}
          disabled={!enabled}
          placeholder={`${t('Add title')}*`}
          rows={1}
          value={text ?? null}
          onChange={(e) => {
            setProp((props: TitleBlockProps) => (props.text = e.target.value));
          }}
        />
      </BaseBlockContainer>
    </div>
  );
};

TitleBlock.craft = {
  displayName: BlockTypes.TitleBlock,
  rules: {
    canDrag: () => false,
  },
};

export { TitleBlock };
export default TitleBlock;
