import { cx } from '@emotion/css';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { outsideRoutes } from 'app/router';
import {
  Command,
  Gift,
  Icon as IconType,
  InfoCircle,
  Keyboard,
  LampCharge,
  MessageQuestion,
} from 'iconsax-react';
import { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

interface InfoPopUpProps {
  className?: string;
}

const InfoPopUp = ({ className }: InfoPopUpProps) => {
  const { t } = useArticlesTranslation();
  const [open, setOpen] = useState(false);
  const [showShortcut, setShowShortcut] = useState(false);

  const options = [
    {
      Icon: InfoCircle,
      text: t('Help center'),
      url: outsideRoutes.atobiHelp.create(),
    },
    {
      Icon: Keyboard,
      text: t('Keyboard shortcuts'),
      onClick: () => setShowShortcut(true),
    },
    {
      Icon: LampCharge,
      text: t('Learn the basics'),
      url: outsideRoutes.articlesIntro.create(),
    },
    {
      Icon: Gift,
      text: t('What’s new'),
      url: outsideRoutes.atobiUpdates.create(),
    },
  ];

  const shortcuts = [
    {
      button: 'Backspace',
      text: t('Delete'),
    },
    {
      button: 'C',
      control: true,
      text: t('Copy'),
    },
    {
      button: 'V',
      control: true,
      text: t('Paste'),
    },
    {
      button: '↓',
      text: t('Down'),
    },
    {
      button: '↑',
      text: t('Up'),
    },
    {
      button: 'Z',
      control: true,
      text: t('Undo'),
    },
    {
      button: 'Y',
      control: true,
      text: t('Redo'),
    },
  ];

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setOpen(false);
        setShowShortcut(false);
      }}
    >
      <div className={cx('flex flex-col items-end', className)}>
        {open && (
          <div className="absolute top-14 w-[193px] pb-3 text-grayscale-primary bg-white border border-gray-light rounded shadow-block">
            <div className="px-3 pt-4 pb-2 text-xs font-bold">
              {showShortcut ? 'Shortcuts' : t('Information')}
            </div>
            {!showShortcut &&
              options.map((option, index) => (
                <InfoOption key={index} {...option} />
              ))}
            {showShortcut &&
              shortcuts.map((shortcut, index) => (
                <Shortcut key={index} {...shortcut} />
              ))}
          </div>
        )}
        <button
          className="text-grayscale-primary hover:text-focus p-2 rounded-lg hover:bg-hover-blue"
          onClick={() =>
            setOpen((prev) => {
              if (prev) setShowShortcut(false);
              return !prev;
            })
          }
        >
          <MessageQuestion size={20} />
        </button>
      </div>
    </OutsideClickHandler>
  );
};

const InfoOption = ({
  Icon,
  text,
  url,
  onClick,
}: {
  Icon: IconType;
  text: string;
  url?: string;
  onClick?(): void;
}) => {
  const className =
    'flex items-center gap-3.5 w-full py-2 px-3 text-sm hover:bg-hover-blue';

  if (url)
    return (
      <a className={className} href={url} rel="noreferrer" target="_blank">
        <Icon className="text-grayscale-secondary" size={16} />
        {text}
      </a>
    );

  return (
    <button className={className} onClick={onClick}>
      <Icon className="text-grayscale-secondary" size={16} />
      {text}
    </button>
  );
};

const Shortcut = ({
  button,
  control,
  text,
}: {
  button: string;
  control?: boolean;
  text: string;
}) => (
  <div className="flex items-center justify-between py-1.5 px-3 text-sm">
    <span className="text-grayscale-primary">{text}</span>
    <span className="flex items-center text-black">
      {control && (
        <span className="flex items-center justify-center h-6 min-w-6 ml-1 p-1 rounded-sm bg-gray-light">
          {window.navigator.userAgent.indexOf('Mac') !== -1 ? (
            <Command size={13} />
          ) : (
            'Ctrl'
          )}
        </span>
      )}
      <span className="flex items-center justify-center h-6 min-w-6 ml-1 p-1 rounded-sm bg-gray-light">
        {button}
      </span>
    </span>
  </div>
);

export default InfoPopUp;
