import { request } from 'app/api/gcsRequest';
import config from 'app/config';
import { RevertAnswer, RevertAnswerApi } from 'common-ui/generated/api/gcs';

const revertAnswer = (answer: RevertAnswer) =>
  new RevertAnswerApi(undefined, config.env.gcsApiUrl, request())
    .revertAnswer(answer)
    .then((response) => response.data);

export { revertAnswer };
export default revertAnswer;
