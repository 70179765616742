import { NonNegativeNumber } from 'common-ui/utils/nonNegativeNumber';
import last from 'lodash/last';
import { useInfiniteQuery } from 'react-query';

import { resources, queryKeys } from '../index';

const useGetAudiencesQuery = ({
  page = 1,
  query,
  enabled = true,
  refetchOnWindowFocus = true,
}: {
  page?: number;
  query: string;
  enabled?: boolean;
  refetchOnWindowFocus?: boolean;
}) => {
  const {
    data,
    isLoading,
    error,
    isFetching,
    isPreviousData,
    refetch,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    queryKeys.audiences(NonNegativeNumber.create(page).value, query),
    ({ pageParam }) =>
      resources.getAudiences(
        NonNegativeNumber.create(pageParam || page).value,
        query
      ),
    {
      getNextPageParam: (lastPage) => lastPage.meta.currentPage + 1,
      enabled,
      refetchOnWindowFocus,
    }
  );

  return {
    data: data?.pages.flatMap((pg) => pg.data),
    meta: last(data?.pages)?.meta,
    isFetching,
    isFetchingNextPage,
    isLoading,
    isPreviousData,
    error,
    refetch,
    fetchNextPage,
  };
};
export default useGetAudiencesQuery;
