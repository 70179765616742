import { Input2, ScrollbarContainer, VerticalChevron } from 'app/components';
import {
  useArticlesTranslation,
  useAudiencesTranslation,
} from 'app/internationalization/hooks';
import a11yKeydown from 'app/utils/a11y/keydown';
import { Profile2User } from 'iconsax-react';
import { useRef, useState } from 'react';
import { Popover } from 'react-tiny-popover';
import CheckLineIcon from 'remixicon-react/CheckLineIcon';

import { useAnswersContext } from '../context';

const AudiencesDropdown = () => {
  const { t: at } = useAudiencesTranslation();
  const { t: art } = useArticlesTranslation();

  const { assigneeAudiences, selectedAudiences, setSearch, onAudienceChange } =
    useAnswersContext();

  const [audidencesOpen, setAudiencesOpen] = useState(false);

  const parentRef = useRef(null);

  const selectedAudienceName =
    assigneeAudiences.filter((audience) =>
      selectedAudiences.includes(audience.id)
    )[0]?.name ?? at('Audiences');

  return (
    <div ref={parentRef}>
      <Popover
        isOpen={audidencesOpen}
        onClickOutside={() => setAudiencesOpen(false)}
        positions={['bottom']}
        containerClassName="z-[9999]"
        parentElement={parentRef.current ?? undefined}
        content={({ childRect }) => (
          <div
            className="mb-4 bg-light border border-gray-light rounded shadow-atobi"
            style={{ width: childRect.width + 32 }}
          >
            <div className="flex flex-col gap-2 pt-4">
              <div className="px-4">
                <Input2
                  searchIconSize={16}
                  className=" py-3 pr-3 rounded-lg outline-[1px] outline-gray-white"
                  placeholder={at('Audience Name')}
                  onChange={(e) => setSearch(e.target.value)}
                  onClear={() => setSearch('')}
                  isSearch={true}
                />
              </div>

              <ScrollbarContainer className="max-h-[225px] overflow-auto">
                {assigneeAudiences?.map((audience) => (
                  <button
                    key={audience.id}
                    className="flex items-center w-full p-2 hover:bg-focus-background rounded-sm"
                    onClick={() => {
                      onAudienceChange(audience.id);
                      setAudiencesOpen(false);
                    }}
                  >
                    <div className="flex flex-col justify-center min-w-0 ml-3">
                      <div className="flex mb-0.5">
                        <span className="text-sm text-ellipsis whitespace-nowrap overflow-hidden ">
                          {audience.name}
                        </span>
                      </div>
                    </div>
                    {selectedAudiences.includes(audience.id) && (
                      <CheckLineIcon
                        size={20}
                        className="ml-auto mr-1 text-focus"
                      />
                    )}
                  </button>
                ))}
              </ScrollbarContainer>
            </div>
            <button
              className="flex items-center justify-center w-full text-grayscale-secondary font-bold py-3 bg-grayscale-bg-dark rounded-br rounded-bl"
              onClick={() => onAudienceChange()}
            >
              {art('Clear selection')}
            </button>
          </div>
        )}
      >
        <div
          role="button"
          tabIndex={0}
          onKeyDown={(e) =>
            a11yKeydown(e, () => setAudiencesOpen((prev) => !prev))
          }
          className="flex w-full items-center rounded-lg text-sm text-focus p-3 bg-focus-background"
          onClick={() => setAudiencesOpen((prev) => !prev)}
        >
          <Profile2User size={16} />
          <span className="ml-[6px]">{selectedAudienceName}</span>
          <div className="ml-auto">
            <VerticalChevron open={audidencesOpen} size={16} />
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default AudiencesDropdown;
