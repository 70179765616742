import CloseFillIcon from 'remixicon-react/CloseFillIcon';

import IconButton from '../IconButton';
import Modal from '../Modal';
import SubmitAndCancelButton from '../SubmitAndCancelButton';

interface Props {
  title: string;
  description: string;
  cancelLabel: string;
  submitLabel: string;
  onClose: () => void;
  onSubmit: () => void;
}

const BasicModal = ({
  onClose,
  onSubmit,
  cancelLabel,
  description,
  submitLabel,
  title,
}: Props) => (
  <Modal className="w-[424px]" onClose={onClose}>
    <div className="flex justify-between items-center mb-4">
      <span className="text-lg text-grayscale-primary font-bold">{title}</span>
      <IconButton
        Icon={CloseFillIcon}
        onClick={onClose}
        className="shadow-none"
      />
    </div>
    <p className="mb-7 text-base">{description}</p>
    <SubmitAndCancelButton
      cancelLabel={cancelLabel}
      submitLabel={submitLabel}
      onCancel={onClose}
      onSubmit={onSubmit}
    />
  </Modal>
);

export default BasicModal;
