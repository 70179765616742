import { cx } from '@emotion/css';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { ArrowRight2 } from 'iconsax-react';
import { ReactNode, useState } from 'react';

const ForwarArticleLanguages = ({
  children,
  error,
}: {
  children: ReactNode;
  error: ReactNode;
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const { t } = useArticlesTranslation();
  return (
    <div className="flex flex-col gap-2 pt-3 bg-white border border-focus-background rounded-xl">
      <div className="flex gap-2 items-center justify-start px-2">
        <button onClick={() => setExpanded((prev) => !prev)}>
          <ArrowRight2
            className={cx({
              'transform rotate-90': expanded,
            })}
            size={16}
          />
        </button>
        <span className="text-xs font-bold">{t('Language summary')}</span>
      </div>

      <div className="px-2">{error}</div>
      <div
        className={cx(
          'max-h-0 transition-all duration-300 ease-in-out overflow-hidden',
          {
            'max-h-[200px]': expanded,
          }
        )}
      >
        <div className={cx('relative flex items-center px-2 pb-3')}>
          <div className="flex flex-col gap-2 text-xs">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ForwarArticleLanguages;
