import { Node } from '@craftjs/core/lib/interfaces/nodes';
import { RecurringArticleDate } from 'app/pages/Articles/Components/RecuringArticleModal/types';
import isEqual from 'lodash/isEqual';
import { useMemo } from 'react';
import { ActionSchedule } from 'submodules/common-ui/generated/api/gcs';

type GetRecurringDateFunction = (
  date: ActionSchedule
) => RecurringArticleDate | undefined;

const useBlockSchedulingInfo = (
  actionBlocks: Node[],
  getRecurringDate: GetRecurringDateFunction
) => {
  const countBlocks = (blocks: Node[]) => {
    const totalBlocks = blocks.length;
    const recurringBlocks = blocks.reduce((count: number, block: Node) => {
      if (
        block.data.props.schedule &&
        block.data.props.schedule?.frequency !== 'once'
      ) {
        return count + 1;
      }
      return count;
    }, 0);
    return { totalBlocks, recurringBlocks };
  };

  const getConsistentRecurringSchedule = (blocks: Node[]) => {
    const recurringBlocks = blocks.filter((block) => block.data.props.schedule);
    if (recurringBlocks.length === 0) return null;

    const firstSchedule = recurringBlocks[0].data.props.schedule;
    const allSameSchedule = recurringBlocks.every((block) =>
      isEqual(block.data.props.schedule, firstSchedule)
    );

    return allSameSchedule ? firstSchedule : null;
  };

  const { totalBlocks, recurringBlocks, consistentSchedule } = useMemo(() => {
    const blocksCount = countBlocks(actionBlocks);
    const schedule = getConsistentRecurringSchedule(actionBlocks);
    const transformedSchedule = schedule ? getRecurringDate(schedule) : null;
    return { ...blocksCount, consistentSchedule: transformedSchedule };
  }, [actionBlocks, getRecurringDate]);

  return { totalBlocks, recurringBlocks, consistentSchedule };
};

export default useBlockSchedulingInfo;
