import { useEditor } from '@craftjs/core';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import BlockTypes from 'app/pages/Editor/helpers/constants';
import { DocumentForward, DocumentText1, Folder, Link1 } from 'iconsax-react';
import React from 'react';

import SideMenuActionContainer from '../../../SideMenuActionContainer';

import { FileBlockOption, FileBlockType } from './FileBlockOption';

interface Props {
  onClose: () => void;
}

const FileBlockDropdown = ({ onClose }: Props) => {
  const { selected: selectedProps } = useEditor((state) => {
    const [currentNodeId] = state.events.selected;
    let selected;

    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        name: state.nodes[currentNodeId].data.name,
        settings:
          state.nodes[currentNodeId].related &&
          state.nodes[currentNodeId].related.settings,
      };
    }

    return {
      selected,
    };
  });

  const { t } = useArticlesTranslation();

  const getBlockTitle = () => {
    switch (selectedProps?.name) {
      case BlockTypes.PDFBlock:
        return t('PDF');
      case BlockTypes.FileBlock:
        return t('File');
      default:
        undefined;
    }
  };

  const isSettingsOpened = selectedProps && selectedProps.settings;
  const title = isSettingsOpened
    ? t('{{block}} Settings', {
        block: getBlockTitle(),
      })
    : t('Attachments');

  return (
    <SideMenuActionContainer title={title} onClose={onClose}>
      {isSettingsOpened ? (
        React.createElement(selectedProps.settings)
      ) : (
        <>
          <FileBlockOption
            Icon={DocumentText1}
            text={t('PDF')}
            type={FileBlockType.Pdf}
          />
          <FileBlockOption
            Icon={Folder}
            text={t('File')}
            type={FileBlockType.File}
          />
          <FileBlockOption
            Icon={Link1}
            text={t('Link')}
            type={FileBlockType.Url}
          />
          <FileBlockOption
            Icon={DocumentForward}
            text={t('Article Linking')}
            type={FileBlockType.Article}
          />
        </>
      )}
    </SideMenuActionContainer>
  );
};

export default FileBlockDropdown;
