import { ChannelCoverImage } from 'app/api/channels/types';
import config from 'app/config';
import { useEffect, useState } from 'react';
import { DirectUrls } from 'submodules/common-ui/generated/api/gcs';

const useImageSource = (image: ChannelCoverImage) => {
  const [directUrls, setDirectUrls] = useState<DirectUrls>();
  const [external, setExternal] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [imageSource, setImageSource] = useState('');

  useEffect(() => {
    if (!image) return;
    if (typeof image === 'string') return setImageSource(image);

    if ('directUrls' in image) setDirectUrls(image.directUrls);

    setExternal(image?.type === 'external');

    if (!image?.file && image?.url) {
      return setImageSource(`${config.env.gcsApiUrl}/${image.url}`);
    }

    if (image?.file) {
      const src = URL.createObjectURL(image.file);
      setImageSource(src);

      return () => URL.revokeObjectURL(src);
    }

    setImageSource('');
  }, [image]);

  return {
    directUrls,
    external,
    hasError,
    hasLoaded,
    imageSource,
    setHasLoaded,
    setHasError,
  };
};

export default useImageSource;
