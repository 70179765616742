import { NonNegativeNumber } from 'common-ui/utils/nonNegativeNumber';
import last from 'lodash/last';
import { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';
import {
  BasicChannelInfo,
  GetChannelPageShownAsEnum,
  SortOrderEnum,
} from 'submodules/common-ui/generated/api/gcs';

import { queryKeys, resources } from '../index';

export interface GetChannelsQuery {
  ranking: SortOrderEnum;
  enabled?: boolean;
  refetchOnWindowFocus?: boolean;
  refetchOnMount?: boolean;
  staleTime?: number;
  shownAs?: GetChannelPageShownAsEnum;
  onSuccess?: (data: BasicChannelInfo[]) => void;
}

const useGetChannelsQuery = ({
  ranking,
  enabled,
  refetchOnWindowFocus = true,
  refetchOnMount = false,
  staleTime,
  shownAs,
  onSuccess,
}: GetChannelsQuery) => {
  const {
    data,
    isLoading,
    error,
    isFetching,
    isPreviousData,
    isFetchingNextPage,
    refetch,
    fetchNextPage,
    remove,
  } = useInfiniteQuery(
    queryKeys.channels({
      ranking,
      refetchOnWindowFocus,
      refetchOnMount,
    }),
    ({ pageParam }) =>
      resources.getChannels(
        NonNegativeNumber.create(pageParam || 0).value,
        ranking,
        shownAs
      ),
    {
      enabled,
      refetchOnMount,
      refetchOnWindowFocus,
      refetchOnReconnect: false,
      keepPreviousData: true,
      staleTime,
      getNextPageParam: (lastPage) => lastPage.meta.currentPage + 1,
      onSuccess(res) {
        if (!res.pages) return;
        const lastIndex = res.pages.length - 1;
        const newData = res.pages[lastIndex].data;
        onSuccess && onSuccess(newData);
      },
    }
  );

  const joinedChannels = useMemo(
    () => data?.pages.flatMap((pg) => pg.data) || [],
    [data?.pages]
  );

  return {
    data: joinedChannels,
    meta: last(data?.pages)?.meta,
    isFetching,
    isFetchingNextPage,
    isLoading,
    isPreviousData,
    error,
    refetch,
    fetchNextPage,
    remove,
  };
};

export default useGetChannelsQuery;
