import { cx } from '@emotion/css';
import a11yKeydown from 'app/utils/a11y/keydown';
import { ArrowDown2 } from 'iconsax-react';
import noop from 'lodash/noop';
import { MouseEvent, KeyboardEvent, useState, EventHandler } from 'react';

interface VerticalChevronProps {
  open?: boolean;
  disabled?: boolean;
  className?: string;
  size?: number;
  onClick?: EventHandler<MouseEvent | KeyboardEvent>;
}

const VerticalChevron = ({
  open,
  disabled = false,
  className,
  size,
  onClick,
}: VerticalChevronProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const click = (
    e:
      | MouseEvent<HTMLDivElement, globalThis.MouseEvent>
      | KeyboardEvent<HTMLDivElement>
  ) => {
    if (open === undefined) {
      setIsOpen((prev) => !prev);
    }
    onClick?.(e);
  };

  return (
    <div
      role="presentation"
      onKeyDown={disabled ? noop : (e) => a11yKeydown(e, () => click(e))}
      className="flex items-center justify-center cursor-pointer"
      onClick={disabled ? noop : (e) => click(e)}
    >
      <ArrowDown2
        size={size}
        className={cx('transform', { 'rotate-180': open ?? isOpen }, className)}
      />
    </div>
  );
};

export default VerticalChevron;
