import useGetManifest from 'app/api/pwaSettings/hooks/useGetManifest';
import config from 'app/config';
import { resolveSubdomain } from 'app/utils/subdomain';
import { useEffect, useState } from 'react';

const useManifestUrl = () => {
  const [manifestUrl, setManifestUrl] = useState<string | undefined>();

  const subdomain = resolveSubdomain();
  const baseUrl = `${config.env.subdomainApiUrl}/${subdomain}`;

  const { data: manifest } = useGetManifest({ url: `${baseUrl}/manifest` });

  useEffect(() => {
    if (!manifest) return;

    const textBlob = new Blob([JSON.stringify(manifest)], {
      type: 'text/plain',
    });

    const blobUrl = URL.createObjectURL(textBlob);
    setManifestUrl(blobUrl);

    return () => {
      URL.revokeObjectURL(blobUrl);
    };
  }, [manifest]);

  return { manifestUrl };
};

export default useManifestUrl;
