import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import config from 'app/config';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { ArrowDown2, InfoCircle } from 'iconsax-react';
import { useEffect, useState } from 'react';
import * as ReactDOMServer from 'react-dom/server';
import { SummarySchema } from 'submodules/common-ui/generated/api/ai-service';

import MainSummary from './MainSummary';
import SummaryCategories from './SummaryCategories';
import Typewriter from './Typewriter';

interface SummaryProps {
  summary: SummarySchema;
  expanded?: boolean;
  isNewSummary?: boolean;
}

const Summary = ({ summary, expanded, isNewSummary }: SummaryProps) => {
  const summaryObj = JSON.parse(summary.summary);
  const [showExpanded, setShowExpanded] = useState(false);
  const [isTypedMain, setIsTypedMain] = useState(false);
  const [isTypedCategories, setIsTypedCategories] = useState(false);
  const { t } = useArticlesTranslation();

  useEffect(() => {
    if (expanded === undefined) return;

    setShowExpanded(expanded);
  }, [expanded]);

  return (
    <div className=" ">
      {isNewSummary && !isTypedMain ? (
        <Typewriter
          text={ReactDOMServer.renderToString(
            <MainSummary
              summary={summaryObj.summary}
              score={summaryObj.score}
            />
          )}
          delay={2}
          onFinish={() => setIsTypedMain(true)}
        />
      ) : (
        <MainSummary summary={summaryObj.summary} score={summaryObj.score} />
      )}

      <div
        className={cx('flex gap-3 flex-col mx-4', { hidden: !showExpanded })}
      >
        {isNewSummary && !isTypedCategories ? (
          isTypedMain ? (
            <Typewriter
              text={ReactDOMServer.renderToString(
                <SummaryCategories categories={summaryObj.categories} />
              )}
              delay={2}
              onFinish={() => setIsTypedCategories(true)}
            />
          ) : (
            ''
          )
        ) : (
          <SummaryCategories categories={summaryObj.categories} />
        )}
      </div>

      <div className="text-10 text-gray-light-blue flex items-center my-3 mx-5">
        <span className="group relative">
          <InfoCircle size={12} className="mr-1" />
          <Tooltip className="!rounded !opacity-100 top-6 left-2 bg-gray-extra-dark absolute z-999 hidden group-hover:inline-block text-white text-sm px-4 py-2 w-[283px] whitespace-normal text-center -translate-x-1/2">
            {t(
              'This summary is generated by an AI, and some opinions may not be fully captured. We recommend reviewing the actual answers for complete insights.'
            )}
          </Tooltip>
        </span>

        {t(
          '{{totalAnswers}} answers summarized. AI outputs can be misleading or wrong',
          { totalAnswers: summary.answersCount }
        )}
      </div>

      <div className="grid justify-items-center">
        <button type="button" onClick={() => setShowExpanded(!showExpanded)}>
          <ArrowDown2
            size={16}
            className={cx('transform', { 'rotate-180': showExpanded })}
          />
        </button>
      </div>
    </div>
  );
};

const Tooltip = styled.div`
  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 50%;
    border-width: 5px;
    border-style: solid;
    transform: translateX(-50%);
    border-color: transparent transparent ${config.colors['gray-extra-dark']}
      transparent;
  }
`;
export default Summary;
