import { cx } from '@emotion/css';
import useGetChannelsQuery from 'app/api/channels/hooks/useChannelsQuery';
import {
  Button,
  IconButton,
  Modal,
  PageLoader,
  RadioButton,
  ScrollbarContainer,
  Spinner,
} from 'app/components';
import { useDispatch } from 'app/hooks';
import useAppSelector from 'app/hooks/useSelector';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { actions, selectors } from 'app/store/editor';
import { actions as modalActions } from 'app/store/modal';
import { getChannelTitle } from 'app/utils';
import channelsIcon from 'assets/icons/channels-medium.svg';
import { Clock } from 'iconsax-react';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import CloseIcon from 'remixicon-react/CloseLineIcon';
import { BasicChannelInfo } from 'submodules/common-ui/generated/api/gcs';

import ChannelModalImage from './ChannelModalImage';

export interface ChannelModalProps {
  canPublish?: boolean;
  setPublishModalOpen?: Dispatch<SetStateAction<boolean>>;
  setChannelModalOpen?: Dispatch<SetStateAction<boolean>>;
}

const ChannelModal = ({
  canPublish,
  setPublishModalOpen,
  setChannelModalOpen,
}: ChannelModalProps) => {
  const { t } = useArticlesTranslation();
  const dispatch = useDispatch();
  const [selectedChannel, setSelectedChannel] = useState<BasicChannelInfo>();
  const [hasMorePages, setHasMorePages] = useState<boolean>(false);
  const threshold = useRef<HTMLDivElement>(null);

  const { data, isLoading, isFetching, meta, fetchNextPage } =
    useGetChannelsQuery({
      ranking: 'desc',
      refetchOnMount: true,
    });

  const recentlyUsed = data.slice(0, 3);
  const channels = data.slice(3);
  const articleChannel = useAppSelector(selectors.getSelectedChannel);

  useEffect(() => {
    if (!meta) return;

    setHasMorePages(meta.currentPage < meta.lastPage);
  }, [meta]);

  useEffect(() => {
    if (hasMorePages && !isFetching && threshold.current) {
      const options: IntersectionObserverInit = {
        root: null,
        rootMargin: '0px',
        threshold: 1,
      };
      const observer = new IntersectionObserver((entities) => {
        const target = entities[0];

        if (target.isIntersecting) fetchNextPage();
      }, options);

      observer.observe(threshold.current);
    }
  }, [hasMorePages, isFetching, fetchNextPage]);

  useEffect(() => {
    if (articleChannel) setSelectedChannel(articleChannel);
  }, [articleChannel]);

  const onClose = useCallback(() => {
    if (setChannelModalOpen) return setChannelModalOpen(false);
    dispatch(modalActions.hideModal());
  }, [dispatch, setChannelModalOpen]);

  const onConfirm = useCallback(
    (channel: BasicChannelInfo) => {
      dispatch(actions.addChannel(channel));
      onClose();
      if (canPublish && setPublishModalOpen) {
        setPublishModalOpen(true);
      }
    },
    [canPublish, dispatch, onClose, setPublishModalOpen]
  );

  const renderRadioOption = (channel: BasicChannelInfo) => (
    <div
      key={channel.id}
      className="flex justify-between items-center mb-2 gap-2"
    >
      <RadioButton
        checked={selectedChannel?.id === channel.id}
        label={getChannelTitle(channel)}
        onClick={() => setSelectedChannel(channel)}
      />
      <div className="h-[45px] min-w-[90px] max-w-[90px] rounded bg-hover-blue overflow-hidden">
        <ChannelModalImage image={channel.coverImage} />
      </div>
    </div>
  );

  return (
    <Modal onClose={onClose} className="w-[410px] relative">
      {isLoading ? (
        <PageLoader />
      ) : (
        <>
          <div className="flex justify-between items-center">
            <span className="text-xs text-grayscale-secondary">
              {t('Step {{activeStep}} of {{totalSteps}}', {
                activeStep: 1,
                totalSteps: 3,
              })}
            </span>
            <IconButton
              Icon={CloseIcon}
              onClick={onClose}
              className="shadow-none"
            />
          </div>
          <form className="flex gap-4 flex-col">
            <div className="flex flex-col">
              <span className="text-base font-bold flex items-center gap-2">
                <img className="h-6" src={channelsIcon} alt="" />
                <span>{t('Select Channel')}</span>
              </span>
              <span className="text-xs text-grayscale-secondary pt-1">
                {t('Which channel you want to publish the article to?')}
              </span>
            </div>

            <ScrollbarContainer className="max-h-[360px] pr-4">
              {recentlyUsed.length > 0 && (
                <div>
                  <div className="flex gap-1.5 items-center mb-1">
                    <Clock size={16} />
                    <p className="text-xs text-grayscale-secondary">
                      {t('Recently used')}
                    </p>
                  </div>
                  {recentlyUsed.map((channel) => renderRadioOption(channel))}
                </div>
              )}

              {channels.length > 0 && (
                <div>
                  <div className="text-xs pt-2 mb-1 text-grayscale-secondary">
                    {t('Channels')}
                  </div>
                  {channels.map((channel) => renderRadioOption(channel))}
                </div>
              )}
              {hasMorePages && (
                <>
                  {!isFetching ? (
                    <div ref={threshold}></div>
                  ) : (
                    <Spinner className="!my-5" />
                  )}
                </>
              )}
            </ScrollbarContainer>

            <div className="w-full flex items-center justify-center mt-1">
              <Button
                className={cx('border-0 !w-56', {
                  'bg-focus text-white': !!selectedChannel,
                  'bg-gray-dark text-grayscale-secondary': !selectedChannel,
                })}
                onClick={() => selectedChannel && onConfirm(selectedChannel)}
              >
                {t('Select Channel')}
              </Button>
            </div>
          </form>
        </>
      )}
    </Modal>
  );
};

export default ChannelModal;
