import { Frame, useEditor } from '@craftjs/core';
import { css, cx } from '@emotion/css';
import styled from '@emotion/styled';
import { useSelector } from 'app/hooks';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { selectors } from 'app/store/editor';
import { ArchiveTick, ArrowDown, Medal } from 'iconsax-react';
import isNil from 'lodash/isNil';
import meanBy from 'lodash/meanBy';
import { PropsWithChildren, useEffect, useMemo } from 'react';
import { Block } from 'submodules/common-ui/generated/api/gcs';

import { ACTION_BLOCKS } from '../helpers/blocks';
import useArticleBlocks from '../hooks/useArticleBlocks';

const ProgressIndicator = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: ${(props: {
    completePercentage: number;
  }) => `radial-gradient(closest-side, white 60%, transparent 60% 100%),
  conic-gradient(#2E3192 ${props.completePercentage}%, ${
    props.completePercentage < 1
      ? 'rgba(34, 34, 34, 0.24)'
      : 'rgba(151, 152, 200, 0.24)'
  } 0);`};
`;

interface ActionsViewProps {
  onClick?(): void;
}

const ActionsView = ({
  children,
  onClick,
}: ActionsViewProps & PropsWithChildren) => {
  const { t } = useArticlesTranslation();

  const {
    store: {
      actions: { deserialize },
    },
  } = useEditor((_, query) => ({
    nodes: query.getNodes(),
  }));

  const article = useSelector(selectors.getArticle);
  const stats = useSelector(selectors.getActionStats);
  const selectedLanguages = useSelector(selectors.getSelectedLanguages);
  const mainLanguage = selectedLanguages.find((l) => l.isDefault)?.code ?? 'en';
  const { blocks } = useArticleBlocks(mainLanguage, ACTION_BLOCKS);

  const quizBlocks = useMemo<Block[] | undefined>(
    () =>
      article?.blocks
        .filter((block) => block.type === 'multi_choice')
        .map((block) => block),
    [article?.blocks]
  );
  const totalScore = useMemo<number>(() => {
    const scores = quizBlocks
      ?.map((block) => {
        if (block && 'currentUserAnswers' in block)
          return block.currentUserAnswers
            .filter((answer) => !isNil(answer.score))
            .map((answer) => answer.score);

        return 0;
      })
      .flat();

    if (scores?.length === 0) return 0;

    return meanBy(scores);
  }, [quizBlocks]);

  useEffect(() => {
    if (!blocks) return;

    deserialize(blocks);
  }, [blocks, deserialize]);

  const progress = [
    {
      Icon: ArchiveTick,
      score: stats?.progress ?? 0,
      text: t('Completed'),
    },
    ...(quizBlocks && quizBlocks.length > 0
      ? [
          {
            Icon: Medal,
            score: Math.round(totalScore),
            text: t('Total Quiz Score'),
          },
        ]
      : []),
  ];

  return (
    <div className="relative flex flex-col h-full w-full bg-hover-blue">
      <div
        className={cx(
          'absolute top-0 left-0 h-30 w-full',
          css(
            'background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 47.4%, rgba(255, 255, 255, 0) 100%)'
          )
        )}
      ></div>
      <div className="relative flex items-center p-4 z-10">
        <button
          className="flex justify-center items-center h-10 w-10 bg-white text-grayscale-secondary rounded-lg shadow-atobi"
          onClick={onClick}
        >
          <ArrowDown />
        </button>
        <span className="flex-auto pr-10 font-bold text-grayscale-primary text-center">
          {t('All Actions')}
        </span>
      </div>
      <div className="flex gap-4 pt-2 px-4">
        {progress.map(({ Icon, score, text }, index) => (
          <div
            key={index}
            className={cx(
              'flex-1 py-4 px-6 text-grayscale-secondary border border-black/5 rounded-2lg drop-shadow',
              css(
                'background: linear-gradient(180deg, #FFFFFF 0%, #EAEAF4 100%)'
              ),
              {
                'flex gap-6 items-center': progress.length <= 1,
              }
            )}
          >
            <ProgressIndicator
              className={cx('relative drop-shadow', {
                'mb-2': progress.length > 1,
              })}
              completePercentage={score}
            >
              <div
                className={cx(
                  'absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 flex items-center justify-center h-[36px] w-[36px] rounded-full',
                  css(
                    'background: linear-gradient(180deg, #FFFFFF 0%, #EAEAF4 100%)'
                  )
                )}
              >
                <Icon size={20} />
              </div>
              <progress value={score} max="100" className="hidden h-0 w-0" />
            </ProgressIndicator>
            <div>
              <div className="text-grayscale-primary font-bold">{score}%</div>
              <div className="text-sm truncate">{text}</div>
            </div>
          </div>
        ))}
      </div>
      <Frame />
      {children}
    </div>
  );
};

export default ActionsView;
