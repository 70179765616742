import { request } from 'app/api/gcsRequest';
import config from 'app/config';
import { EventApi } from 'submodules/common-ui/generated/api/gcs';

const logEventArticle = (articleId: number) =>
  new EventApi(undefined, config.env.gcsApiUrl, request()).postEvent({
    event: 'seen',
    objectType: 'article',
    objectId: articleId,
  });

export { logEventArticle };
export default logEventArticle;
