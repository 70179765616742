import Masonry from 'react-responsive-masonry';
import { ActionTypeEnum, Block } from 'submodules/common-ui/generated/api/gcs';

import { useAnswersContext } from '../context';

import MediaTaskAnswersDeepDive from './MediaTaskAnswersDeepDive';
import MultiChoiceAnswersDeepDive from './MultiChoiceTaskAnswersDeepDive';
import OpenQuestionAnswersDeepDive from './OpenQuestionAnswersDeepDive';
import SimpleTaskAnswersDeepDive from './SimpleTaskAnswersDeepDive';
import ErrorSkeleton from './Skeletons/ErrorSkeleton';
import YesNoTaskAnswersDeepDive from './YesNoTaskAnswersDeepDive';

const ListDeepDiveAnswers = () => {
  const { selectedTaskId, article } = useAnswersContext();

  const selectedTaskBlock = article?.blocks.find(
    (block) =>
      block.id === selectedTaskId &&
      (Object.values(ActionTypeEnum) as string[]).includes(block.type)
  );

  const renderAnswers = (taskBlock: Block) => {
    switch (taskBlock.type) {
      case ActionTypeEnum.SimpleTask:
        return <SimpleTaskAnswersDeepDive key={taskBlock.id} />;
      case ActionTypeEnum.MediaTask:
        return <MediaTaskAnswersDeepDive key={taskBlock.id} />;
      case ActionTypeEnum.OpenQuestionTask:
        return <OpenQuestionAnswersDeepDive key={taskBlock.id} />;
      case ActionTypeEnum.MultiChoice:
        return <MultiChoiceAnswersDeepDive key={taskBlock.id} />;
      case ActionTypeEnum.YesNoTask:
        return <YesNoTaskAnswersDeepDive key={taskBlock.id} />;
    }
  };

  if (!selectedTaskBlock) return <ErrorSkeleton />;

  return (
    <Masonry columnsCount={1} gutter="10px">
      {renderAnswers(selectedTaskBlock)}
    </Masonry>
  );
};

export default ListDeepDiveAnswers;
