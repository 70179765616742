import { cx } from '@emotion/css';
import config from 'app/config';
import useDateTimeFormat from 'app/hooks/useDateTimeFormat';
import useUserQuery from 'app/pages/profiles/PublicProfile/useUserQuery';
import { selectors as authSelectors } from 'app/store/auth';
import { LocationTick, CalendarCircle } from 'iconsax-react';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { DbMediaTaskAnswer } from 'submodules/common-ui/generated/api/gcs';

interface Props {
  userId: number;
  image: DbMediaTaskAnswer;
  createdAt: string;
}
const MediaTask: FC<Props> = ({ userId, image, createdAt }) => {
  const tenant = useSelector(authSelectors.getSelectedTenant);
  const { data: user, isLoading: isLoadingUser } = useUserQuery({ id: userId });

  const displayImage = Array.isArray(image) ? image[0] : image;

  const createdAtTime = useDateTimeFormat({
    timestamp: createdAt,
    type: 'dayMonthYearTime',
  });

  return (
    <div
      className={cx(
        'rounded-xl gap-2 bg-grayscale-bg-dark shadow-atobi flex flex-col p-2',
        {
          'bg-hover-blue': isLoadingUser,
          'bg-grayscale-bg-dark': !isLoadingUser,
        }
      )}
    >
      <header className="flex flex-col gap-1">
        <div className={cx('flex gap-1 items-center text-grayscale-primary')}>
          {isLoadingUser ? <></> : <LocationTick className="h-4 w-4" />}
          {isLoadingUser ? (
            <div className="h-4 w-[108px] bg-focus-background rounded-[10px]" />
          ) : (
            <span className="text-xs font-semibold truncate">
              {user?.location?.name}
            </span>
          )}
        </div>

        <div className={cx('flex gap-1 items-center text-grayscale-secondary')}>
          {isLoadingUser ? <></> : <CalendarCircle className="h-4 w-4" />}
          {isLoadingUser ? (
            <div className="h-4 w-[125px] bg-focus-background rounded-[10px]" />
          ) : (
            <span className="text-xs">{createdAtTime}</span>
          )}
        </div>
      </header>
      {isLoadingUser ? (
        <div className="w-full rounded-[10px] h-[185px] bg-focus-background" />
      ) : (
        <img
          className="w-full rounded-[10px] h-[185px] object-cover"
          src={`${config.env.gcsApiUrl}/file/${tenant?.alias ?? ''}/${displayImage}`}
          alt="media-task"
        />
      )}
    </div>
  );
};

export default MediaTask;
