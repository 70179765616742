import { cx } from '@emotion/css';
import { selectors } from 'app/store/editor';
import { Language } from 'app/store/editor/types';
import { ProfileAdd } from 'iconsax-react';
import { useSelector } from 'react-redux';
import { Popover } from 'react-tiny-popover';

import { useLanguageSettingsContext } from '../context';

import LanguageCollaboratorsDropdown from './LanguageCollaboratorsDropdown';

const MAX_COLLABARATORS = 2;
const REMAINING_COLLABARATORS = 1;

interface Props {
  language: Language;
  parentRef: HTMLDivElement;
  onSave: () => void;
}

const LanguageCollaborators = ({ language, parentRef, onSave }: Props) => {
  const { setPopovers, popovers, hasArticleOwnerPermissions } =
    useLanguageSettingsContext();

  const selectedCollaborators = useSelector(selectors.getSelectedCollaborators);

  const collaborators = selectedCollaborators.filter(({ languages }) =>
    languages.includes(language.code)
  );

  const displayedCollaborators = collaborators.slice(0, MAX_COLLABARATORS);
  const remainingCollaborators = collaborators.slice(REMAINING_COLLABARATORS);
  const remainingCollaboratorsCount = remainingCollaborators.length;
  const isPopoverVisible = popovers?.openCollaboratorsPopover === language.code;

  const onOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setPopovers((prev) => ({
      openCollaboratorsPopover:
        prev?.openCollaboratorsPopover === language.code
          ? undefined
          : language.code,
    }));
  };

  return (
    <div className="flex flex-row">
      {collaborators.length <= MAX_COLLABARATORS ? (
        displayedCollaborators.map((collaborator, index) => (
          <img
            key={collaborator.id}
            src={collaborator.avatar}
            alt="avatar"
            className={cx('w-6 h-6 rounded', { '-ml-1': index > 0 })}
            data-tip={collaborator.fullName}
          />
        ))
      ) : (
        <img
          src={displayedCollaborators[0]?.avatar}
          alt="avatar"
          className="w-6 h-6 rounded"
          data-tip={displayedCollaborators[0]?.fullName}
        />
      )}
      {collaborators.length > MAX_COLLABARATORS && (
        <div className="border w-6 h-6 rounded flex border-gray-light justify-center items-center -ml-1 bg-grayscale-bg-dark">
          <span className="font-semibold text-10 text-grayscale-secondary">{`+${remainingCollaboratorsCount}`}</span>
        </div>
      )}
      {hasArticleOwnerPermissions && (
        <Popover
          parentElement={parentRef}
          containerClassName="z-50"
          isOpen={isPopoverVisible}
          positions={['bottom']}
          onClickOutside={() => setPopovers(undefined)}
          content={
            <LanguageCollaboratorsDropdown
              language={language}
              languageCollaborators={collaborators}
              onSave={onSave}
            />
          }
        >
          <button
            onClick={onOpen}
            className="hover:bg-focus-background border w-6 h-6 rounded flex border-gray-light justify-center items-center cursor-pointer -ml-1 bg-grayscale-bg-dark"
          >
            <ProfileAdd
              size={16}
              className="text-grayscale-secondary hover:text-focus"
            />
          </button>
        </Popover>
      )}
    </div>
  );
};

export default LanguageCollaborators;
