import { useEditor } from '@craftjs/core';
import HeadingSectionBlock from 'app/pages/ArticleEditor/components/blocks/HeadingSection';
import SimpleTask from 'app/pages/ArticleEditor/components/blocks/tasks/SimpleTask';
import { ACTION_BLOCKS } from 'app/pages/Editor/helpers/blocks';
import useArticleBlocks from 'app/pages/Editor/hooks/useArticleBlocks';
import { selectors } from 'app/store/editor';
import { ExtendedSerializedNode } from 'app/utils/createCraftBlocks';
import sortBy from 'lodash/sortBy';
import { ComponentProps, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useUnblockNextSection = (actionNodeId: string) => {
  const selectedLanguages = useSelector(selectors.getSelectedLanguages);
  const mainLanguage = selectedLanguages.find((l) => l.isDefault)?.code ?? 'en';
  const article = useSelector(selectors.getArticle);
  const { blocks } = useArticleBlocks(mainLanguage);

  const [isUnblocked, setIsUnblocked] = useState(false);

  const {
    nodes,
    actions: { setProp },
  } = useEditor((state, _) => ({
    nodes: state.nodes,
  }));

  const unblockNextSection = useCallback(() => {
    if (!article) return;

    const articleBlocks: Record<string, ExtendedSerializedNode> = blocks;
    const sections = Object.values(blocks)
      .filter(
        (block) =>
          block.id.includes('headingSection') &&
          block.parent === 'dropableRegion'
      )
      .map((block) => block.id);

    const sortedActionBlocks = sortBy(
      Object.values(articleBlocks).filter((block) =>
        ACTION_BLOCKS.includes(block.displayName)
      ),
      'position'
    );

    const currentIndex = sortedActionBlocks.findIndex(
      (block) => block.id === actionNodeId
    );
    const nextActionBlock = sortedActionBlocks[currentIndex + 1];

    if (
      nextActionBlock &&
      nextActionBlock.props.isLocked &&
      !nextActionBlock.props.completed
    )
      setProp(
        nextActionBlock.id,
        (props: ComponentProps<typeof SimpleTask>) => {
          props.isLocked = false;
        }
      );

    for (let i = 0; i < sections.length; i++) {
      const section = nodes[sections[i]];
      const container = section.data.linkedNodes['headingSectionContainer'];
      const containerNodes = nodes[container]?.data.nodes;

      const hasNoActionBlock = containerNodes.some(
        (id) => !('completed' in nodes[id])
      );

      const hasActionBlock = containerNodes.some(
        (id) =>
          'completed' in nodes[id].data.props && !nodes[id].data.props.completed
      );

      if (hasNoActionBlock || hasActionBlock) {
        setProp(
          sections[i],
          (props: ComponentProps<typeof HeadingSectionBlock>) => {
            props.isSectionBlocked = false;
          }
        );
        if (hasActionBlock) return;
      }
    }
  }, [article, blocks, actionNodeId, setProp, nodes]);

  useEffect(() => {
    if (!isUnblocked) return;
    unblockNextSection();
    setIsUnblocked(false);
  }, [isUnblocked, unblockNextSection]);

  return { setIsUnblocked };
};

export default useUnblockNextSection;
