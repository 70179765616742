import config from 'app/config';

interface OutsideRoute {
  create: (...args: any) => string;
}

interface OutsideRoutes {
  [key: string]: OutsideRoute;
}

const routes: OutsideRoutes = {
  privacyPolicy: {
    create: () => `${config.env.privacyPolicyUrl}`,
  },
  adminDashboard: {
    create: (tenantUrl: string) => `${tenantUrl}/admin`,
  },
  termsOfService: {
    create: () => 'https://help.atobi.io/article/57-user-terms-of-service',
  },
  glossary: {
    create: () => 'https://help.atobi.io/article/188-glossary',
  },
  chronologicalActions: {
    create: () => 'https://help.atobi.io/article/189-chronological-actions',
  },
  articlesIntro: {
    create: () => 'https://help.atobi.io/article/135-articles-intro',
  },
  atobiUpdates: {
    create: () => 'https://help.atobi.io/category/156-atobi-updates',
  },
  atobiHelp: {
    create: () => 'https://help.atobi.io/',
  },
  atobiPrivacyPolicy: {
    create: () => 'https://help.atobi.io/category/58-security-privacy-safety',
  },
  atobiHelpMail: {
    create: () => 'mailto:help@atobi.io',
  },
  atobiCreateAnAudience: {
    create: () =>
      'https://help.atobi.io/article/147-audiences-how-to-create-an-audience',
  },
};

export default routes;
