import { resources } from 'app/api/articles';
import { useMutation } from 'react-query';

const useTranslateArticleMutation = () => {
  const response = useMutation(resources.translateArticle);

  return { ...response };
};

export default useTranslateArticleMutation;
