import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from 'react-query';
import {
  ActionAnswerPageMeta,
  UncompletedAction,
} from 'submodules/common-ui/generated/api/gcs';

export interface HandleQueryBase<TData = unknown, TError = unknown> {
  isFetching: boolean;
  isLoading: boolean;
  isPreviousData: boolean;
  error: unknown;
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<TData, TError>>;
  fetchNextPage: (
    options?: FetchNextPageOptions
  ) => Promise<InfiniteQueryObserverResult<TData, TError>>;
}

export interface UpcomingActionsQuery extends HandleQueryBase {
  data: UncompletedAction[];
  meta: ActionAnswerPageMeta | undefined;
}

export type UpcomingActionsRequiredFilter = 'all' | 'you' | 'team';

export const requireFilterMap: Record<
  UpcomingActionsRequiredFilter,
  number | undefined
> = {
  all: undefined,
  team: 1,
  you: -1,
};
