import ArticleDetailsLanguages from 'app/pages/Articles/Components/ArticleDetails/ArticleDetailsLanguages';
import { selectors } from 'app/store/editor';
import { useSelector } from 'react-redux';

const ForwardArticleSummary = () => {
  const article = useSelector(selectors.getArticle);

  if (!article) return null;

  return (
    <ArticleDetailsLanguages
      article={article}
      hasChevron={false}
      hasHeader={false}
      isForward={true}
    />
  );
};
export default ForwardArticleSummary;
