import clockService from 'app/api/clockService';
import config from 'app/config';
import removeHashFromUrlIfImmediate from 'app/utils/removeHashFromUrlIfImmediate';
import { UserManager } from 'oidc-client';

const createOpenIdManager = (
  originUrl?: string,
  isTokenExpired?: boolean,
  prompt?: string
) =>
  new UserManager({
    authority: config.env.identityServerUri,
    client_id: config.env.identityServerClientId,
    response_type: 'code',
    redirect_uri: removeHashFromUrlIfImmediate(
      originUrl ?? `${window.location.origin}${window.location.pathname}`
    ),
    post_logout_redirect_uri: removeHashFromUrlIfImmediate(
      `${window.location.origin}${window.location.pathname}`
    ),
    scope: config.env.identityServerScopes,
    clockService,
    extraQueryParams: {
      ...(isTokenExpired && {
        errorCode: 401,
      }),
      ...(prompt && {
        prompt,
      }),
    },
  });

export default createOpenIdManager;
