import { resources } from 'app/api/reactions';
import { useMutation } from 'react-query';

const useCreateReactionMutation = () => {
  const mutation = useMutation(resources.createReaction);

  return { ...mutation };
};

export default useCreateReactionMutation;
