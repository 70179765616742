import { useQuery } from 'react-query';

import { queryKeys, resources } from '../index';
import { ArticleFilter } from '../types';

const useGetArticle = ({
  id,
  enabled,
  filter,
  staleTime = true,
  refetchOnWindowFocus = true,
}: {
  id: number;
  enabled: boolean;
  filter: ArticleFilter;
  staleTime?: boolean;
  refetchOnWindowFocus?: boolean;
}) => {
  const {
    data,
    isLoading,
    isFetching,
    error: apiError,
    refetch,
  } = useQuery(
    queryKeys.article(id, filter),
    () => resources.getArticle(id, filter),
    {
      enabled,
      //840000 milis = 14 minutes, 15 minutes is the valid duration of the directUrls in this response
      staleTime: staleTime ? 840000 : undefined,
      refetchOnWindowFocus,
    }
  );

  return {
    article: data,
    isLoading,
    isFetching,
    error: apiError,
    apiError,
    refetch,
  };
};

export { useGetArticle };
export default useGetArticle;
