import { ScrollbarContainer, VerticalChevron } from 'app/components';
import config from 'app/config';
import {
  useArticlesTranslation,
  useCommonTranslation,
} from 'app/internationalization/hooks';
import { Buliding } from 'iconsax-react';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import Select, {
  StylesConfig,
  components,
  MenuListProps,
  DropdownIndicatorProps,
  OptionProps,
} from 'react-select';
import {
  SharedTenants,
  SharingStatusOverviewResponse,
} from 'submodules/common-ui/generated/api/gcs';

export type SelectOption = {
  value: number;
  label: string;
  logo?: string | null;
};

interface MultiSelectProps {
  isLoading: boolean;
  statusOverview?: SharingStatusOverviewResponse;
  sharingTenants?: SharedTenants;
  selectedTennants: SelectOption[];
  setSelectedTennants: Dispatch<SetStateAction<SelectOption[]>>;
}

const MultiSelect = ({
  isLoading,
  statusOverview,
  sharingTenants,
  selectedTennants,
  setSelectedTennants,
}: MultiSelectProps) => {
  const { t } = useArticlesTranslation();
  const { t: ct } = useCommonTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const sharedTenants = useMemo(
    () =>
      sharingTenants?.receivers
        ?.map((r) => ({ value: r.id, label: r.name, logo: r.logo }))
        .filter((f) => statusOverview?.every((s) => s.tenantName !== f.label)),
    [sharingTenants, statusOverview]
  );

  const onTenantChange = (option?: SelectOption) => {
    if (!option) return;

    const exists = selectedTennants?.find((v) => v.value === option.value);

    setSelectedTennants((prev) => {
      if (!prev) return [option];
      if (exists) return prev?.filter((p) => p.value !== exists.value);

      return [...prev, option];
    });
  };

  const selectStyles: StylesConfig<SelectOption, true> = {
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: config.colors['focus-background'],
      borderRadius: '32px',
      height: '28px',
      padding: '6px 12px',
      display: 'flex',
      alignItems: 'center',
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: config.colors['focus'],
      padding: '0px',
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: config.colors['grayscale-secondary'],
      ':hover': {
        backgroundColor: 'transparent',
        color: config.colors['grayscale-secondary'],
      },
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
    }),
    control: (styles) => ({
      ...styles,
      minHeight: '52px',
      borderRadius: '12px',
      backgroundColor: config.colors['grayscale-bg-dark'],
      border: `1px solid ${
        isOpen ? config.colors.focus : config.colors['gray-dark']
      }`,
      boxShadow: 'none',
      ':hover': {
        borderColor: config.colors.focus,
      },
    }),
    option: (styles) => ({
      ...styles,
      borderRadius: '4px',
      ':hover': {
        backgroundColor: config.colors['hover-blue'],
      },
      backgroundColor: '#FFFFFF',
      cursor: 'pointer',
    }),
    menu: (styles) => ({
      ...styles,
      width: '100%',
    }),
    menuList: (styles) => ({
      ...styles,
      maxHeight: 'initial',
      padding: '0',
    }),
  };

  const MenuList = (props: MenuListProps<SelectOption, true>) => (
    <components.MenuList {...props}>
      <ScrollbarContainer className="max-h-[224px]">
        {props.children}
      </ScrollbarContainer>
      <button
        type="button"
        className="w-full h-12 bg-hover-blue text-focus text-xs font-bold rounded"
        onClick={() => {
          if (!sharedTenants) return;
          setSelectedTennants(sharedTenants);
        }}
      >
        {t('Add All')}
      </button>
    </components.MenuList>
  );

  const DropdownIndicator = (
    props: DropdownIndicatorProps<SelectOption, true>
  ) => (
    <components.DropdownIndicator {...props}>
      <VerticalChevron
        size={24}
        open={isOpen}
        className="text-grayscale-secondary"
      />
    </components.DropdownIndicator>
  );

  const Option = (props: OptionProps<SelectOption, true>) => (
    <components.Option {...props}>
      <div className="flex items-center">
        <div className="flex flex-row items-center w-8 h-8 justify-center text-grayscale-secondary rounded border border-gray-light bg-white">
          {props.data?.logo ? (
            <img src={props.data?.logo} alt="Logo" />
          ) : (
            <Buliding size={20} />
          )}
        </div>
        <span className="ml-2">{props.data.label}</span>
      </div>
    </components.Option>
  );

  return (
    <Select
      isLoading={isLoading}
      loadingMessage={() => <span>{ct('Loading...')}</span>}
      captureMenuScroll={false}
      options={sharedTenants}
      isMulti
      styles={selectStyles}
      onMenuOpen={() => setIsOpen(true)}
      onMenuClose={() => setIsOpen(false)}
      placeholder={<span className="text-base">{t('Add partner')}</span>}
      components={{
        MenuList,
        DropdownIndicator,
        Option,
        IndicatorSeparator: () => null,
        ClearIndicator: () => null,
      }}
      value={selectedTennants}
      onChange={(_, a) => onTenantChange(a.option ?? a.removedValue)}
    />
  );
};

export default MultiSelect;
