import { SisenseDashboardKey } from '../types';

import useBaseTranslation from './useBaseTranslation';

const useSisenseDashboardTranslation = () =>
  useBaseTranslation<SisenseDashboardKey>(undefined, {
    keyPrefix: 'sisenseDashboard',
  });

export default useSisenseDashboardTranslation;
