import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import { actions } from 'app/store/editor';
import {
  BLUR_COMMAND,
  FOCUS_COMMAND,
  COMMAND_PRIORITY_LOW,
  $setSelection,
} from 'lexical';
import { useCallback, useLayoutEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

const useEditorFocus = () => {
  const dispatch = useDispatch();

  const [editor] = useLexicalComposerContext();

  const floatingMenuPress = useRef<boolean>(false);

  const isFloatingMenuPress = useCallback(
    (element: Element | null, index: number): boolean => {
      const maxParentElements = 4;

      if (index > maxParentElements) return false;

      if (element?.id !== 'floatingMenu') {
        return isFloatingMenuPress(element?.parentElement ?? null, index + 1);
      }

      return true;
    },
    []
  );

  const onMouseDown = useCallback(
    (ev: MouseEvent) => {
      floatingMenuPress.current = isFloatingMenuPress(ev.target as Element, 0);
    },
    [isFloatingMenuPress]
  );

  document.addEventListener('mousedown', onMouseDown);

  useLayoutEffect(() => {
    mergeRegister(
      editor.registerCommand(
        FOCUS_COMMAND,
        () => {
          dispatch(actions.setChoiceHasFocus(true));
          return false;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        BLUR_COMMAND,
        () => {
          if (!floatingMenuPress.current) {
            dispatch(actions.setChoiceHasFocus(false));
            editor.blur();
            $setSelection(null);
          }
          return false;
        },
        COMMAND_PRIORITY_LOW
      )
    );

    return () => document.removeEventListener('mousedown', onMouseDown);
  }, [editor, onMouseDown, dispatch]);
};

export default useEditorFocus;
