import useFileDirectUrls from 'app/hooks/useFileDirectUrls';
import useGetFile from 'app/hooks/useGetFile';
import dayjs from 'dayjs';
import {
  ExternalFile,
  InternalFileGet,
} from 'submodules/common-ui/generated/api/gcs/api';

interface Props {
  fileSchema: InternalFileGet | ExternalFile | undefined;
  tenantAlias?: string;
  fileId: string;
  fileName: string;
  pdfUrl?: string;
}
const useDownloadFile = ({
  fileSchema,
  tenantAlias,
  fileId,
  fileName,
}: Props) => {
  const { directUrls } = useFileDirectUrls(fileSchema);

  const { refetch: getFileUrl } = useGetFile(
    tenantAlias ?? '',
    fileId,
    fileName
  );

  const getResourceUrl = async () => {
    if (directUrls?.original?.url) {
      const isExpired = urlExpired(directUrls.original.url);

      if (isExpired) {
        return await refreshFileUrl().catch();
      }

      return directUrls.original.url;
    }

    if (fileSchema?.url) return fileSchema.url;
  };

  const urlExpired = (url: string) => {
    const _url = new URL(url);

    const expiry = _url.searchParams.get('se');

    return dayjs() > dayjs(expiry);
  };

  const refreshFileUrl = async () => {
    const res = await getFileUrl().catch();

    return res.data?.originalUrl ?? res.data?.url;
  };

  const openFileInBrowser = () => {
    getResourceUrl().then((url) => {
      if (!url) return;

      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.target = '_blank';
      a.rel = 'noopener noreferrer';

      document.body.appendChild(a);

      a.click();

      document.body.removeChild(a);
    });
  };

  const downloadFileToClient = () => {
    getResourceUrl().then((url) => {
      if (!url) return;

      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;

      document.body.appendChild(a);

      a.click();

      document.body.removeChild(a);
    });
  };

  return {
    openFileInBrowser,
    downloadFileToClient,
  };
};

export default useDownloadFile;
