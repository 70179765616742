import { AudiencesKey } from '../types';

import useBaseTranslation from './useBaseTranslation';

const useAudiencesTranslation = () =>
  useBaseTranslation<AudiencesKey>(undefined, {
    keyPrefix: 'audiences',
  });

export default useAudiencesTranslation;
