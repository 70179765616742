import { GlossaryKey } from '../types';

import useBaseTranslation from './useBaseTranslation';

const useGlossaryTranslation = () =>
  useBaseTranslation<GlossaryKey>(undefined, {
    keyPrefix: 'glossary',
  });

export default useGlossaryTranslation;
