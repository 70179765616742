import { ArrowDown } from 'iconsax-react';
import { Drawer } from 'vaul';

import ScormIframe from '../ScormIframe';

interface Props {
  open: boolean;
  actionId: string;
  languageCode: string;
  onClose: () => void;
}

const ScormAnswerDrawer = ({
  actionId,
  languageCode,
  open,
  onClose,
}: Props) => {
  return (
    <Drawer.Root nested open={open}>
      <Drawer.Portal>
        <Drawer.Overlay className="fixed inset-0 bg-grayscale-primary/20 z-50" />
        <Drawer.Content className="fixed bottom-0 left-0 right-0 flex flex-col z-50 bg-white rounded-t-lg outline-none h-[97%] max-h-screen p-2 shadow-block">
          <button
            onClick={onClose}
            className="p-2 border rounded-[10px] border-gray-light w-[38px]"
          >
            <ArrowDown size={20} />
          </button>
          <ScormIframe actionId={actionId} languageCode={languageCode} />
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
};

export default ScormAnswerDrawer;
