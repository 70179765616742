const useCommentUrlHash = () => {
  const hash = window.location.hash;
  const isArticleComment = hash.indexOf('articlecomment') > -1;
  const isPostComment = hash.indexOf('feedpostcomment') > -1;

  const props: { commentId?: string; articleId?: string } = {
    commentId: undefined,
    articleId: undefined,
  };

  if (!isArticleComment && !isPostComment) return props;

  if (isArticleComment) {
    const commentId = hash.split('-')[1];

    props.commentId = Number.isNaN(commentId) ? undefined : commentId;
  }

  if (isPostComment) {
    const joinedIds = hash.split('-')[1];
    const ids = joinedIds.split(':');
    const articleId = ids[0];
    const commentId = ids[1];

    props.articleId = Number.isNaN(articleId) ? undefined : articleId;
    props.commentId = Number.isNaN(commentId) ? undefined : commentId;
  }

  return { ...props };
};

export default useCommentUrlHash;
