import { useArticlesTranslation } from 'app/internationalization/hooks';
import { Danger } from 'iconsax-react';

import { useAnswersContext } from '../../context';
import LoadingSkeleton from '../Skeletons/LoadingSkeleton';

interface Props {
  contentHeightList?: string;
  contentHeightGrid?: string;
  skeletonContentCount?: number;
}

const ErrorSkeleton: React.FC<Props> = ({
  contentHeightList,
  contentHeightGrid,
  skeletonContentCount,
}) => {
  const { t } = useArticlesTranslation();
  const { layout } = useAnswersContext();
  const numberOfSkeletons = layout === 'grid' ? 10 : 6;

  return (
    <>
      {[...Array(numberOfSkeletons)].map((_, i) => (
        <LoadingSkeleton
          key={i}
          contentHeightList={contentHeightList}
          contentHeightGrid={contentHeightGrid}
          skeletonContentCount={skeletonContentCount}
        />
      ))}

      <div className="absolute inset-0 flex justify-center items-center">
        <div className="bg-light rounded-lg flex flex-col items-center w-[390px] p-6 gap-4 absolute top-[120px] left-1/2 transform -translate-x-1/2">
          <div className="flex items-center gap-1 text-error">
            <Danger className="w-6 h-6 text-error" />
            <span className="font-bold text-lg text-error">
              {t("We couldn't load the deep-dive.")}
            </span>
          </div>
          <span className="text-grayscale-secondary text-sm text-center">
            {t('Please try refreshing the page or check back later.')}
          </span>
        </div>
      </div>
    </>
  );
};

export default ErrorSkeleton;
