import { request } from 'app/api/gcsRequest';
import config from 'app/config';
import { UncompletedActionsApi } from 'submodules/common-ui/generated/api/gcs';

import { UpcomingActionsFilter } from '../types';

const getUpcomingActions = (
  page: number,
  perPage: number,
  filter?: UpcomingActionsFilter
) =>
  new UncompletedActionsApi(
    undefined,
    config.env.gcsApiUrl,
    request()
  ).getUncompletedActions(
    filter?.deadlineFrom,
    filter?.deadlineTo,
    filter?.noDeadline ? '1' : undefined,
    filter?.required,
    filter?.timezone,
    page,
    perPage
  );

export { getUpcomingActions };
export default getUpcomingActions;
