import { cx } from '@emotion/css';
import { ScrollbarContainer, VerticalChevron } from 'app/components';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import useOpenVertically from 'app/pages/ArticleEditor/hooks/useOpenVertically';
import { ClipboardTick, InfoCircle } from 'iconsax-react';
import { useRef, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import Button from './Button';

interface PollFeedbackProps {
  optionsNumber?: number;
  correctAnswersNumber?: number;
  setNumberOfChoices: (value: number) => void;
}
const PollFeedback = ({
  setNumberOfChoices,
  optionsNumber = 1,
  correctAnswersNumber = 1,
}: PollFeedbackProps) => {
  const { t } = useArticlesTranslation();

  const [open, setOpen] = useState(false);
  const [answersOpen, setAnswersOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useOpenVertically(dropdownRef, answersOpen);

  return (
    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
      <div className="relative">
        <ClipboardTick
          onClick={() => {
            setOpen((prev) => !prev);
          }}
          size={20}
          className={cx({ 'text-grayscale-secondary': open })}
        />

        {open && (
          <div className="w-[330px] absolute bg-white rounded-lg z-50 shadow-lg m-0 bg-clip-padding left-2 bottom-4 transform -translate-y-2 shadow-atobi text-black border-gray-light border-[1px]">
            <div className="p-3">
              <div className="flex items-center">
                <span className="text-sm font-bold">
                  {t('Limit of Choices')}
                </span>
                <a
                  target="_blank"
                  rel="noroferrer noreferrer"
                  href="https://help.atobi.io/article/148-articles-actions"
                >
                  <InfoCircle size={16} className="ml-2 text-gray-dark" />
                </a>
              </div>
              <div className="flex items-center mt-[14px]">
                <span className="text-sm">
                  {t('Number of options that assignee can choose')}:
                </span>
                <div
                  className="flex items-center justify-center relative bg-hover-blue rounded p-2 ml-4"
                  role="presentation"
                  onClick={() => setAnswersOpen((prev) => !prev)}
                >
                  <span className="mr-2 text-sm text-focus">
                    {correctAnswersNumber}
                  </span>
                  <VerticalChevron
                    size={12}
                    className="text-focus"
                    open={answersOpen}
                  />
                  {answersOpen && (
                    <OutsideClickHandler
                      onOutsideClick={() => setAnswersOpen(false)}
                    >
                      <div ref={dropdownRef} className="absolute left-0 top-7">
                        <ScrollbarContainer className="rounded h-[250px]">
                          <div className="flex flex-col bg-white rounded">
                            {[...Array(optionsNumber)].map((_, index) => {
                              return (
                                <Button
                                  key={index}
                                  disabled={index + 1 > optionsNumber}
                                  index={index + 1}
                                  optionsNumber={optionsNumber}
                                  correctAnswersNumber={correctAnswersNumber}
                                  onClick={() => setNumberOfChoices(index + 1)}
                                />
                              );
                            })}
                          </div>
                        </ScrollbarContainer>
                      </div>
                    </OutsideClickHandler>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default PollFeedback;
