import { cx } from '@emotion/css';
import ReactionEmoji from 'app/components/Emoji/ReactionEmoji';
import { useAnimate } from 'app/hooks';
import { ButtonHTMLAttributes, ReactNode } from 'react';
import { EmojiEnum } from 'submodules/common-ui/generated/api/gcs/api';

interface ReactionsProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  animated?: boolean;
  emoji: EmojiEnum;
  text?: ReactNode | null;
}

const Reaction = ({
  animated,
  className,
  emoji,
  text,
  onClick,
  ...restProps
}: ReactionsProps) => {
  const { animationClassName, animate } = useAnimate('shake');

  return (
    <button
      className={cx(className, animationClassName)}
      onClick={(e) => {
        if (!onClick) return;
        if (animated) {
          animate();
          navigator?.vibrate?.(50);
        }

        onClick(e);
      }}
      {...restProps}
    >
      <ReactionEmoji emoji={emoji} />
      {text}
    </button>
  );
};

export default Reaction;
