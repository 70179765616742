import { useAuthenticatedUser } from 'app/api/auth/hooks';
import useReactionsQuery from 'app/api/reactions/hooks/useReactions';
import ReactionEmoji from 'app/components/Emoji/ReactionEmoji';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import useUserQuery from 'app/pages/profiles/PublicProfile/useUserQuery';
import { useEffect } from 'react';
import {
  EmojiEnum,
  ReactionObjectTypeEnum,
} from 'submodules/common-ui/generated/api/gcs/api';

const MAX_USERS_COUNT = 5;

interface ReactionsListProps {
  count: number;
  emoji: EmojiEnum;
  objectId: number;
  objectType: ReactionObjectTypeEnum;
  selected?: boolean;
}

const ReactionsList = ({
  count,
  emoji,
  objectId,
  objectType,
  selected,
}: ReactionsListProps) => {
  const { t } = useArticlesTranslation();
  const { data: user } = useAuthenticatedUser();
  const { data: reactionsData, refetch } = useReactionsQuery({
    objectId,
    objectType,
    page: 0,
    emoji,
    enabled: !!emoji,
  });

  useEffect(() => {
    refetch();
  }, [count, refetch]);

  const reactions =
    reactionsData
      ?.filter((reaction) => reaction.createdBy !== user?.id)
      .slice(0, MAX_USERS_COUNT) ?? [];
  const othersCount = count - (selected ? 1 : 0) - MAX_USERS_COUNT;

  return (
    <div className="flex flex-col items-center gap-2 w-[149px] py-1 [&_svg]:h-8 [&_svg]:w-8">
      <ReactionEmoji emoji={emoji} />
      {reactions.length > 0 && (
        <div className="w-full text-left text-xs text-white">
          <p className="min-h-4">
            {reactions.map(({ createdBy }, index) => (
              <span key={index}>
                <ReactionUser id={createdBy} />
                {index < reactions.length - 1 ? ', ' : ' '}
              </span>
            ))}
            {othersCount > 0 &&
              t('and others', {
                count: othersCount,
              })}
          </p>
          {renderEmojiText()}
        </div>
      )}
      {selected && (
        <div className="w-full text-left text-xs text-white">
          <p className="mt-2">{t('You (click to remove)')}</p>
          {renderEmojiText()}
        </div>
      )}
    </div>
  );

  function renderEmojiText() {
    return (
      <p className="text-grayscale-secondary">
        {t('reacted with', {
          emoji,
        })}
      </p>
    );
  }
};

const ReactionUser = ({ id }: { id: number }) => {
  const { data } = useUserQuery({
    id,
  });

  if (!data) return null;

  return (
    <span>
      {data?.firstName} {data?.lastName}
    </span>
  );
};

export default ReactionsList;
