export default (
  hostname: string,
  articleId: number,
  languageId?: number,
  locationId?: number
) => `
    var findAPITries = 0;
    var startTimeStamp = null;
    var processedUnload = false;

    var SCORM_TRUE = 'true';
    var SCORM_FALSE = 'false';
    var SCORM_NO_ERROR = '0';

    //Since the Unload handler will be called twice, from both the onunload
    //and onbeforeunload events, ensure that we only call LMSFinish once.
    var finishCalled = false;

    //Track whether or not we successfully initialized.
    var initialized = false;

    var API = null;
    

    function findAPI(win) {
        // Check to see if the window (win) contains the API
        // if the window (win) does not contain the API and
        // the window (win) has a parent window and the parent window
        // is not the same as the window (win)
        while (win.API == null && win.parent != null && win.parent != win) {
            // increment the number of findAPITries
            findAPITries++;

            // Note: 7 is an arbitrary number, but should be more than sufficient
            if (findAPITries > 7) {
            alert('Error finding API -- too deeply nested.');
            return null;
            }

            // set the variable that represents the window being
            // being searched to be the parent of the current window
            // then search for the API again
            win = win.parent;
        }

        return win.API;
    }

    function getAPI() {
        // start by looking for the API in the current window
        var theAPI = findAPI(window);

        // if the API is null (could not be found in the current window)
        // and the current window has an opener window
        if (
            theAPI == null &&
            window.opener != null &&
            typeof window.opener != 'undefined'
        ) {
            // try to find the API in the current window�s opener
            theAPI = findAPI(window.opener);
        }

        // if the API has not been found
        if (theAPI == null) {
            // Alert the user that the API Adapter could not be found
            alert('Unable to find an API adapter');
        }

        return theAPI;
    }

    function ScormProcessInitialize() {
        var navigationEl = document.getElementById("navDiv");
        var result;
        startTimeStamp = new Date();

        API = getAPI();

        if (API == null) {
            alert('ERROR - Could not establish a connection with the LMS. Your results may not be recorded.');
            return;
        }

        result = API.LMSInitialize('');

        initialized = true;
    
        var completionStatus = ScormProcessGetValue("cmi.core.lesson_status");
        if (completionStatus == "not attempted"){
            ScormProcessSetValue("cmi.core.lesson_status", "incomplete");
        } else if(completionStatus == "completed"){
            navigationEl.style.display = 'block';
         }
            
        var url = new URL('${hostname}/article-studio/editor/${articleId}/actions');

        const [lastName, firstName] = ScormProcessGetValue("cmi.core.student_name").split(",");

        url.searchParams.append('firstName', firstName.trim());
        url.searchParams.append('lastName', lastName.trim());
        url.searchParams.append('email', ScormProcessGetValue("cmi.core.student_id").trim());
        url.searchParams.append('language', ${languageId});
        url.searchParams.append('location', ${locationId});

        document.getElementById("atobi-iframe").src = url.href;
        

        if (result == SCORM_FALSE) {
            var errorNumber = API.LMSGetLastError();
            var errorString = API.LMSGetErrorString(errorNumber);
            var diagnostic = API.LMSGetDiagnostic(errorNumber);

            var errorDescription =
            'Number: ' +
            errorNumber +
            'Description: ' +
            errorString +
            'Diagnostic: ' +
            diagnostic;

            alert(
            'Error - Could not initialize communication with the LMS. Your results may not be recorded. ' +
                errorDescription
            );
            return;
        }

        window.addEventListener(
            'message',
            (event) => {
                if (
                    typeof event.data === 'object' &&
                    event.data.key === 'articleStatus'
                ) {
                    ScormProcessSetValue('cmi.core.lesson_status', event.data.status);
                    navigationEl.style.display = 'block';
                }

                if (typeof event.data === 'object' && event.data.key === 'articleScore') {
                    const score = event.data.score;
                    ScormProcessSetValue('cmi.core.score.raw', score);
                    ScormProcessSetValue('cmi.core.score.min', '0');
                    ScormProcessSetValue('cmi.core.score.max', '100');
                }
            },
            false
        );
    
    };

    function ScormProcessGetValue(element){
        
        var result;
        
        if (initialized == false || finishCalled == true){return;}
        
        result = API.LMSGetValue(element);
        
        if (result == ""){
        
            var errorNumber = API.LMSGetLastError();
            
            if (errorNumber != SCORM_NO_ERROR){
                var errorString = API.LMSGetErrorString(errorNumber);
                var diagnostic = API.LMSGetDiagnostic(errorNumber);
                
                var errorDescription = "Number: " + errorNumber + "Description: " + errorString + "Diagnostic: " + diagnostic;
                
                alert("Error - Could not retrieve a value from the LMS. " + errorDescription);
                return "";
            }
        }
        
        return result;
    }

    function ScormProcessSetValue(element, value){
    
        var result;
        
        if (initialized == false || finishCalled == true){return;}
        
        result = API.LMSSetValue(element, value);
        
        if (result == SCORM_FALSE){
            var errorNumber = API.LMSGetLastError();
            var errorString = API.LMSGetErrorString(errorNumber);
            var diagnostic = API.LMSGetDiagnostic(errorNumber);
            
            var errorDescription = "Number: " + errorNumber + "Description: " + errorString + "Diagnostic: " + diagnostic;
            
            alert("Error - Could not store a value in the LMS. Your results may not be recorded. " + errorDescription);
            return;
        }
        
    }

    function doUnload(pressedExit){
        
        //don't call this function twice
        if (processedUnload == true){return;}
        
        processedUnload = true;
        
        //record the session time
        var endTimeStamp = new Date();
        var totalMilliseconds = (endTimeStamp.getTime() - startTimeStamp.getTime());
        var scormTime = ConvertMilliSecondsToSCORMTime(totalMilliseconds, false);
        
        ScormProcessSetValue("cmi.core.session_time", scormTime);
        
        ScormProcessFinish();
    }


    function doExit(){

        ScormProcessSetValue("cmi.core.exit", "");
        
        //process the unload handler to close out the session.
        //the presense of an adl.nav.request will cause the LMS to 
        //take the content away from the user.
        doUnload(true);
        
    }

    //SCORM requires time to be formatted in a specific way
    function ConvertMilliSecondsToSCORMTime(intTotalMilliseconds, blnIncludeFraction){
	
	    var intHours;
	    var intintMinutes;
	    var intSeconds;
	    var intMilliseconds;
	    var intHundredths;
	    var strCMITimeSpan;
    	
	    if (blnIncludeFraction == null || blnIncludeFraction == undefined){
		    blnIncludeFraction = true;
	    }
    	
	    //extract time parts
	    intMilliseconds = intTotalMilliseconds % 1000;

	    intSeconds = ((intTotalMilliseconds - intMilliseconds) / 1000) % 60;

	    intMinutes = ((intTotalMilliseconds - intMilliseconds - (intSeconds * 1000)) / 60000) % 60;

	    intHours = (intTotalMilliseconds - intMilliseconds - (intSeconds * 1000) - (intMinutes * 60000)) / 3600000;

	    /*
	    deal with exceptional case when content used a huge amount of time and interpreted CMITimstamp 
	    to allow a number of intMinutes and seconds greater than 60 i.e. 9999:99:99.99 instead of 9999:60:60:99
	    note - this case is permissable under SCORM, but will be exceptionally rare
	    */

	    if (intHours == 10000) 
	    {	
		    intHours = 9999;

		    intMinutes = (intTotalMilliseconds - (intHours * 3600000)) / 60000;
		    if (intMinutes == 100) 
		    {
			    intMinutes = 99;
		    }
		    intMinutes = Math.floor(intMinutes);
    		
		    intSeconds = (intTotalMilliseconds - (intHours * 3600000) - (intMinutes * 60000)) / 1000;
		    if (intSeconds == 100) 
		    {
			    intSeconds = 99;
		    }
		    intSeconds = Math.floor(intSeconds);
    		
		    intMilliseconds = (intTotalMilliseconds - (intHours * 3600000) - (intMinutes * 60000) - (intSeconds * 1000));
	    }

	    //drop the extra precision from the milliseconds
	    intHundredths = Math.floor(intMilliseconds / 10);

	    //put in padding 0's and concatinate to get the proper format
	    strCMITimeSpan = ZeroPad(intHours, 4) + ":" + ZeroPad(intMinutes, 2) + ":" + ZeroPad(intSeconds, 2);
    	
	    if (blnIncludeFraction){
		    strCMITimeSpan += "." + intHundredths;
	    }

	    //check for case where total milliseconds is greater than max supported by strCMITimeSpan
	    if (intHours > 9999) 
	    {
		    strCMITimeSpan = "9999:99:99";
    		
		    if (blnIncludeFraction){
			    strCMITimeSpan += ".99";
		    }
	    }

	    return strCMITimeSpan;
    	
    }

    function ZeroPad(intNum, intNumDigits){
 
	    var strTemp;
	    var intLen;
	    var i;
    	
	    strTemp = new String(intNum);
	    intLen = strTemp.length;
    	
	    if (intLen > intNumDigits){
		    strTemp = strTemp.substr(0,intNumDigits);
	    }
	    else{
		    for (i=intLen; i<intNumDigits; i++){
			    strTemp = "0" + strTemp;
		    }
	    }
    	
	    return strTemp;
    }


    function ScormProcessFinish(){
    
    var result;
    
    //Don't terminate if we haven't initialized or if we've already terminated
    if (initialized == false || finishCalled == true){return;}
    
    result = API.LMSFinish("");
    
    finishCalled = true;
    
    if (result == SCORM_FALSE){
        var errorNumber = API.LMSGetLastError();
        var errorString = API.LMSGetErrorString(errorNumber);
        var diagnostic = API.LMSGetDiagnostic(errorNumber);
        
        var errorDescription = "Number: " + errorNumber + "Description: " + errorString + " Diagnostic: " + diagnostic;
        
        alert("Error - Could not terminate communication with the LMS. Your results may not be recorded. " + errorDescription);
        return;
    }
}


window.onload = ScormProcessInitialize;
`;
