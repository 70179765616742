import { SuccessModalProps } from 'app/components/SuccessModal';
import { ScormAnswerModalProps } from 'app/pages/ArticleEditor/components/blocks/tasks/components/ActionSettings/components/Modals/ScormAnswerModal';
import { YesNoConfirmModalProps } from 'app/pages/ArticleEditor/components/blocks/tasks/components/ActionSettings/components/Modals/YesNoConfirmModal';
import { ChannelModalProps } from 'app/pages/Editor/components/ChannelModal/ChannelModal';
import { ForwardModalProps } from 'app/pages/Editor/components/ForwardModal';
import { DeleteSharedArticleModalProps } from 'app/pages/Editor/components/ForwardModal/DeleteSharedArticleModal';
import { LanguagesModalProps } from 'app/pages/Editor/components/LanguagesModal/LanguagesModal';

export interface SaveAlert {
  message: string | null;
}

export enum ModalTypes {
  SUCCESS_MODAL = 'success_modal',
  LANGUAGES_MODAL = 'languages_modal',
  SHARE_SUCCESS_MODAL = 'share_success_modal',
  FORWARD_MODAL = 'forward_modal',
  DELETE_SHARED_ARTICLE_MODAL = 'delete_shared_article_modal',
  ADD_CHANNEL_MODAL = 'add_channel_modal',
  SCHEDULE_MODAL = 'schedule_modal',
  YES_NO_NOT_POSSIBLE_MODAL = 'yes_no_not_possible_modal',
  SCORM_ANSWER_MODAL = 'scorm_answer_modal',
}

export type ModalProps =
  | SuccessModalProps
  | DeleteSharedArticleModalProps
  | ForwardModalProps
  | ChannelModalProps
  | LanguagesModalProps
  | YesNoConfirmModalProps
  | ScormAnswerModalProps;

export interface Modals {
  modalType: ModalTypes | null;
  modalProps: ModalProps;
}

export interface State {
  saveAlert: SaveAlert;
  modalsOpened: number;
  modals: Modals;
}
