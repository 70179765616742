import { cx } from '@emotion/css';
import { useAuthenticatedUser } from 'app/api/auth/hooks';
import useCreateReactionMutation from 'app/api/reactions/hooks/useCreateReaction';
import useDeleteReactionMutation from 'app/api/reactions/hooks/useDeleteReaction';
import useReactionsQuery from 'app/api/reactions/hooks/useReactions';
import { Reaction, ReactionsSheet } from 'app/components/Reactions';
import { useScreenBreakpoint } from 'app/hooks';
import { selectors } from 'app/store/editor';
import { isMobile } from 'app/utils';
import ReactionIcon from 'assets/icons/reaction.svg?react';
import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import {
  DeleteReactionByEmojiParams,
  NewReaction,
  EmojiEnum,
  ReactionCounts,
} from 'submodules/common-ui/generated/api/gcs/api';
import { Swiper as SwiperClass } from 'swiper';
import 'swiper/css';
import 'swiper/css/free-mode';
import { FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import ReactionsList from './ReactionsList';

const Reactions = () => {
  const article = useSelector(selectors.getArticle);
  const [swiper, setSwiper] = useState<SwiperClass>();
  const [activeEmoji, setActiveEmoji] = useState<EmojiEnum>();

  const { mutate: createReaction } = useCreateReactionMutation();
  const { mutate: deleteReaction } = useDeleteReactionMutation();

  const refetchTimeout = useRef<number | null>(null);

  const queueRefetch = () => {
    refetchTimeout.current = window.setTimeout(() => {
      refetch();
    }, 500);
  };

  const breakpoint = useScreenBreakpoint();
  const mobile = isMobile(breakpoint);

  const { data: user } = useAuthenticatedUser();
  const { refetch } = useReactionsQuery({
    objectId: article?.id ?? 0,
    objectType: 'article',
    page: 0,
    perPage: 30,
    enabled: article?.id !== undefined,
    onSuccess(data) {
      if (!data) return;

      const response = data.pages.map((item) => item.data).flat();

      const currentUserReactions = response
        ?.filter((reaction) => reaction.createdBy === user?.id)
        .map((reaction) => reaction.emoji);
      const reactionCounts = response?.reduce(
        (counts, reaction) => {
          const emojiKey = reaction.emoji as keyof typeof EmojiEnum;
          return {
            ...counts,
            [emojiKey]: (counts[emojiKey] || 0) + 1,
          };
        },
        {} as Record<keyof typeof EmojiEnum, number>
      );

      setReactions(reactionCounts as ReactionCounts);
      setUserReactions(currentUserReactions || []);
    },
  });

  const [reactions, setReactions] = useState<ReactionCounts>({});
  const [userReactions, setUserReactions] = useState<EmojiEnum[]>([]);

  useEffect(() => {
    ReactTooltip.hide();
  }, [reactions]);

  const onSelectReaction = (emoji: EmojiEnum) => {
    const payload: DeleteReactionByEmojiParams | NewReaction = {
      objectId: Number(article?.id),
      objectType: 'article',
      emoji,
    };

    if (refetchTimeout.current !== null) {
      clearTimeout(refetchTimeout.current);
    }

    if (userReactions.includes(emoji)) {
      deleteReaction(payload, {
        onSuccess: (response) => {
          setReactions(response.data.reactionCounts);
          setUserReactions(response.data.currentUserReactions);
          queueRefetch();
        },
      });
    } else {
      createReaction(payload, {
        onSuccess: (response) => {
          setReactions(response.data.reactionCounts);
          setUserReactions(response.data.currentUserReactions);
          queueRefetch();
        },
      });
    }
  };

  useEffect(() => {
    return () => {
      if (refetchTimeout.current) {
        clearTimeout(refetchTimeout.current);
      }
    };
  }, []);

  if (!article) return null;

  const reactionEmojis = Object.values(EmojiEnum).sort();

  return (
    <div className="mb-4">
      <Swiper
        freeMode={true}
        wrapperClass="flex items-center"
        modules={[FreeMode]}
        slidesPerView="auto"
        spaceBetween={8}
        onSlideChange={swiper?.update}
        onSwiper={setSwiper}
      >
        <SwiperSlide className="!w-auto leading-[0]">
          <ReactionsSheet
            currentUserReactions={article.currentUserReactions}
            onClick={(emoji) => {
              swiper?.slideTo(reactionEmojis.indexOf(emoji));
              setTimeout(() => onSelectReaction(emoji), 300);
            }}
          >
            <div className="flex [&_svg]:h-6 [&_svg]:w-6 items-center justify-center w-12 px-3 py-2 rounded-[20px] bg-white cursor-pointer">
              <ReactionIcon />
            </div>
          </ReactionsSheet>
        </SwiperSlide>
        {reactionEmojis.map((emoji) => {
          const count = reactions[emoji];

          return (
            <SwiperSlide key={emoji} className="!w-auto">
              <Reaction
                animated={mobile}
                className={cx(
                  'bg-white flex items-center justify-center min-w-18 h-10 px-6 py-2 rounded-[20px] [&_svg]:h-6 [&_svg]:w-6 text-xs text-hover-primary border border-transparent shadow-atobi transition-all duration-300',
                  {
                    'bg-focus-background !border-focus':
                      userReactions?.includes(emoji),
                  }
                )}
                data-tip
                data-for="reactions"
                emoji={emoji}
                text={
                  <>
                    <span
                      className={cx(
                        'font-extrabold opacity-0 transition-all duration-300',
                        {
                          'ml-1.5 opacity-100': !!count,
                          'w-0': !count,
                        }
                      )}
                    >
                      {count}
                    </span>
                  </>
                }
                onClick={() => {
                  onSelectReaction(emoji);
                  swiper?.update();
                }}
                onMouseEnter={() => setActiveEmoji(emoji)}
                onMouseLeave={() => setActiveEmoji(undefined)}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <ReactTooltip
        class="react-tooltip"
        effect="solid"
        id="reactions"
        place="top"
        getContent={[
          () => {
            const reactionCount = activeEmoji
              ? reactions[activeEmoji]
              : undefined;
            return activeEmoji && reactionCount && reactionCount > 0 ? (
              <ReactionsList
                count={reactionCount}
                emoji={activeEmoji}
                objectId={article.id}
                objectType="article"
                selected={userReactions?.includes(activeEmoji)}
              />
            ) : (
              false
            );
          },
          0,
        ]}
      />
    </div>
  );
};

export default Reactions;
