import { useAuthenticatedUser } from 'app/api/auth/hooks';
import { UserProfile } from 'app/api/auth/types';
import useGetCommentQuery from 'app/api/comments/hooks/useCommentQuery';
import useCommentsQuery from 'app/api/comments/hooks/useCommentsQuery';
import useCreateCommentMutation from 'app/api/comments/hooks/useCreateComment';
import useDeleteCommentMutation from 'app/api/comments/hooks/useDeleteComment';
import useGetCommentAndParentsQuery from 'app/api/comments/hooks/useGetCommentAndParentsQuery';
import useUpdateCommentMutation from 'app/api/comments/hooks/useUpdateComment';
import { ComplaintTypes } from 'app/api/complaints/constants';
import BlockingContext from 'app/blockings/context';
import { selectors } from 'app/store/editor';
import { clearUrlHash } from 'app/utils';
import dayjs from 'dayjs';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  ActionAnswerPageMeta,
  ExtendedComment,
  Text,
} from 'submodules/common-ui/generated/api/gcs/api';

import useCommenAndParentsTree from './hooks/useCommentAndParentsTree';
import useCommentUrlHash from './hooks/useCommentUrlHash';

const uninitialisedFunction = () => new Error('Uninitialised Function');

export interface NestedComment extends ExtendedComment {
  isHidden?: boolean;
  children?: Array<NestedComment>;
}

export const CommentsContextContext = createContext<CommentsContextValues>({
  text: '',
  comments: [],
  commentAndParents: [],
  isCommentsAndParentsLoading: true,
  isCommentsLoading: true,
  isCommentsFetching: true,
  allowComments: true,
  isUserLoading: true,
  articleId: undefined,
  isRefetchingReplies: false,
  isLoading: false,
  setIsRefetchingReplies: uninitialisedFunction,
  setText: uninitialisedFunction,
  setUserLoading: uninitialisedFunction,
  fetchComments: uninitialisedFunction,
  onCreateComment: uninitialisedFunction,
  setCommentPopupId: uninitialisedFunction,
  onDeleteComment: uninitialisedFunction,
  setCommentAction: uninitialisedFunction,
  setCommentRepliesId: uninitialisedFunction,
  isHiddenOrBlocked: () => false,
});

interface CommentsContextValues {
  errorCommentObject?: NestedComment;
  comments: NestedComment[];
  commentAndParents: ExtendedComment[];
  isCommentsAndParentsLoading: boolean;
  isCommentsLoading: boolean;
  isCommentsFetching: boolean;
  text: string;
  currentUser?: UserProfile;
  commentPopupId?: number;
  metaComments?: ActionAnswerPageMeta;
  commentRepliesId?: number;
  commentAction?: CommentActionProps;
  allowComments: boolean;
  isUserLoading: boolean;
  articleId?: number;
  isRefetchingReplies: boolean;
  isLoading: boolean;
  setText: React.Dispatch<React.SetStateAction<string>>;
  fetchComments: () => void;
  onCreateComment: () => void;
  setCommentPopupId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setCommentAction: React.Dispatch<
    React.SetStateAction<CommentActionProps | undefined>
  >;
  setUserLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsRefetchingReplies: React.Dispatch<React.SetStateAction<boolean>>;
  setCommentRepliesId: React.Dispatch<React.SetStateAction<number | undefined>>;
  onDeleteComment: (id: number) => void;
  isHiddenOrBlocked: (
    id: number,
    userId: number,
    type: ComplaintTypes
  ) => boolean;
}

type ContextProps = {
  children: ReactNode;
};

interface CommentActionProps {
  commentId: number;
  type: 'edit' | 'reply';
}

const CommentsContextProvider = ({ children }: ContextProps) => {
  const { isHiddenOrBlocked } = useContext(BlockingContext);
  const { id } = useParams<{ id: string }>();
  const articleId = Number(id);

  const { commentId: _commentId } = useCommentUrlHash();

  const { data: currentUser } = useAuthenticatedUser();

  const allowComments = useSelector(selectors.getAllowComments);

  const [commentAction, setCommentAction] = useState<
    CommentActionProps | undefined
  >(undefined);
  const [text, setText] = useState<string>('');
  const [commentPopupId, setCommentPopupId] = useState<number | undefined>(
    undefined
  );
  const [commentRepliesId, setCommentRepliesId] = useState<number | undefined>(
    undefined
  );
  const [isUserLoading, setUserLoading] = useState<boolean>(false);
  const [errorCommentText, setErrorCommentText] = useState<string>('');
  const [isRefetchingReplies, setIsRefetchingReplies] =
    useState<boolean>(false);

  const isEditingEnabled = commentAction?.type === 'edit';
  const isReplyEnabled = commentAction?.type === 'reply';
  const currentUserLanguage = currentUser?.contentLanguage.uiLanguage ?? 'en';

  const {
    mutate: createComment,
    isLoading: isCreatingComment,
    error: createError,
  } = useCreateCommentMutation();
  const { mutate: deleteComment, isLoading: isDeletingComment } =
    useDeleteCommentMutation();
  const { mutate: updateComment, isLoading: isUpdatingComment } =
    useUpdateCommentMutation();

  const isLoading = isCreatingComment || isDeletingComment || isUpdatingComment;

  const errorCommentObject: NestedComment = {
    id: 0,
    objectId: 0,
    commentCount: 0,
    createdBy: currentUser?.id ?? 0,
    createdAt: dayjs().toString(),
    defaultLanguage: currentUserLanguage,
    updatedAt: null,
    parentCommentId: null,
    currentUserReactions: [],
    objectType: 'article',
    reactionCounts: {},
    variants: {
      [currentUserLanguage]: [
        {
          type: 'paragraph',
          children: [
            {
              type: 'text',
              format: {},
              value: errorCommentText,
            },
          ],
        },
      ],
    },
  };

  const {
    data: _comments,
    isLoading: isCommentsLoading,
    isFetching: isCommentsFetching,
    meta: metaComments,
    fetchNextPage: fetchComments,
    refetch: refetchComments,
  } = useCommentsQuery({
    objectId: articleId,
    objectType: 'article',
    page: 0,
    enabled: !!articleId && allowComments && !_commentId,
  });

  const {
    data: commentAndParentsData,
    isLoading: isCommentsAndParentsLoading,
  } = useGetCommentAndParentsQuery({
    commentId: Number(_commentId),
    enabled: _commentId !== undefined,
  });

  const { data: selectedComment } = useGetCommentQuery({
    commentId: commentAction?.commentId ?? 0,
    enabled: !!isEditingEnabled,
  });

  useEffect(() => {
    if (!selectedComment || commentAction?.type !== 'edit') return;

    const commentText = selectedComment?.variants[
      selectedComment.defaultLanguage
    ][0].children[0] as Text;

    const selectedCommentText = commentText.value;

    setText(selectedCommentText);
  }, [selectedComment, commentAction]);

  const commentAndParents = commentAndParentsData ?? [];

  const commentAndParentsTree = useCommenAndParentsTree(commentAndParents);

  const comments = useMemo(() => {
    const userComments = _commentId ? commentAndParentsTree : _comments;

    return userComments.map((comment) => {
      const isCommentHidden = isHiddenOrBlocked(
        comment.id,
        comment.createdBy,
        ComplaintTypes.articleComment
      );

      return {
        ...comment,
        isHidden: isCommentHidden,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_commentId, commentAndParentsTree, _comments, currentUser?.id]);

  useEffect(() => {
    if (!_commentId) return;

    const main = comments.find((comment) => !comment.parentCommentId);
    if (!main) return;

    setCommentAction({ commentId: main.id, type: 'reply' });
  }, [_commentId, comments]);

  const onSuccess = () => {
    clearUrlHash();

    setText('');
    if (!_commentId) refetchComments();
    if (!commentAction) return;

    if (commentAction.type === 'reply') {
      setCommentRepliesId(commentAction.commentId);
    }

    setIsRefetchingReplies(true);
  };

  const onError = () => {
    setErrorCommentText(text || errorCommentText);
    setText('');
  };

  const onCreateComment = () => {
    if (isEditingEnabled) {
      return updateComment(
        {
          commentId: commentAction.commentId,
          updatedComment: {
            comment: [
              {
                type: 'paragraph',
                children: [
                  {
                    value: text,
                    type: 'text',
                    format: {},
                  },
                ],
              },
            ],
            language: currentUserLanguage,
          },
        },
        {
          onSuccess,
          onError,
        }
      );
    }
    return createComment(
      {
        objectId: isReplyEnabled ? commentAction.commentId : articleId,
        objectType: isReplyEnabled ? 'comment' : 'article',
        language: currentUserLanguage,
        comment: [
          {
            type: 'paragraph',
            children: [
              {
                value: text || errorCommentText,
                type: 'text',
                format: {},
              },
            ],
          },
        ],
      },
      {
        onSuccess,
        onError,
      }
    );
  };

  const onDeleteComment = (commentId: number) => {
    deleteComment(commentId, {
      onSuccess: () => {
        refetchComments();
        if (commentRepliesId) setIsRefetchingReplies(true);
      },
    });
  };

  const contextValue: CommentsContextValues = {
    errorCommentObject: createError ? errorCommentObject : undefined,
    comments: comments.filter(({ isHidden }) => !isHidden),
    commentAndParents,
    isCommentsAndParentsLoading,
    isCommentsLoading,
    currentUser,
    isCommentsFetching,
    text,
    metaComments,
    commentPopupId,
    commentAction,
    commentRepliesId,
    allowComments,
    isUserLoading,
    articleId,
    isRefetchingReplies,
    isLoading,
    setIsRefetchingReplies,
    setUserLoading,
    setCommentRepliesId,
    setText,
    fetchComments,
    onCreateComment,
    setCommentPopupId,
    onDeleteComment,
    setCommentAction,
    isHiddenOrBlocked,
  };

  return (
    <CommentsContextContext.Provider value={contextValue}>
      {children}
    </CommentsContextContext.Provider>
  );
};

const useCommentsContext = () => {
  const context = useContext(CommentsContextContext);

  if (context === undefined)
    throw new Error(
      'useCommentsContext must be used within a CommentsContextProvider'
    );

  return context;
};

export { CommentsContextProvider, useCommentsContext };
export type { CommentsContextValues };
