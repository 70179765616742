import { request } from '../../request';

const resetPassword = ({
  ids,
  password,
  passwordChangeRequired,
  isAutoGenerate,
}: {
  ids: Array<number>;
  password: string | undefined;
  passwordChangeRequired: boolean;
  isAutoGenerate: boolean;
}) => {
  return request().post(
    `/api/v1/user-management/${
      isAutoGenerate ? 'bulk-reset-password' : 'bulk-create-password'
    }`,
    {
      ids,
      password,
      password_change_required: passwordChangeRequired,
    }
  );
};

export default resetPassword;
