import { useArticlesTranslation } from 'app/internationalization/hooks';
import useRecurringDateAsSchedule from 'app/pages/Editor/hooks/useRecurringDateAsSchedule';
import dayjs from 'dayjs';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  DailyScheduleFrequencyEnum,
  MonthlyScheduleFrequencyEnum,
  ScheduleWeekDay,
  WeeklyScheduleFrequencyEnum,
} from 'submodules/common-ui/generated/api/gcs';

import {
  RecurringArticleDate,
  RecurringArticleDropdownValue,
  RecurringArticleOption,
  RecurringArticleValue,
  days,
  months,
  weeks,
} from './types';

const uninitialisedFunction = () => new Error('Uninitialised Function');

export const RecurringArticleContext =
  createContext<RecurringArticleContextValues>({
    repeatOptions: [],
    recurringOptions: [],
    updateRecurringDate: uninitialisedFunction,
  });

type ContextProps = {
  existingRecurringDate?: RecurringArticleDate | null;
  children: ReactNode;
};

interface RecurringArticleContextValues {
  repeatOptions: RecurringArticleValue[];
  recurringOptions: RecurringArticleOption<RecurringArticleDropdownValue>[];
  recurringDate?: RecurringArticleDate | null;
  existingRecurringDate?: RecurringArticleDate | null;
  updateRecurringDate: (updatedFields: Partial<RecurringArticleDate>) => void;
}

const TIME_FORMAT = 'HH:00';

const RecurringArticleContextProvider = ({
  children,
  existingRecurringDate,
}: ContextProps) => {
  const { t } = useArticlesTranslation();
  const { getFormattedOptions } = useRecurringDateAsSchedule();

  const availableTime = dayjs().hour(9).format(TIME_FORMAT);
  const deadlineTime = dayjs().hour(18).format(TIME_FORMAT);

  const existingRecurringValue = existingRecurringDate?.recurring.value;

  const [recurringDate, setRecurringDate] = useState<
    RecurringArticleDate | undefined | null
  >(existingRecurringDate);

  const recurringValue = recurringDate?.recurring.value;
  const repeat = recurringDate?.repeat;
  const recurringStart = recurringDate?.start;
  const recurringEnd = recurringDate?.end;

  const isDaily = useCallback((value?: RecurringArticleDropdownValue) => {
    return value === DailyScheduleFrequencyEnum.Daily;
  }, []);

  const getDay = useCallback((value: ScheduleWeekDay) => {
    return days.find((day) => day.value === value)?.label;
  }, []);

  const getMonthlyWeekText = useCallback(
    ({ start, end }: RecurringArticleDate) => {
      if (start.day !== end.day || start.week !== end.week)
        return `${start.week?.label} ${start.day?.label}-${end.week?.label} ${end.day?.label}`;

      return `${start.week?.label} ${end.day?.label}`;
    },
    []
  );

  const isDailyOrWeekly =
    recurringValue === DailyScheduleFrequencyEnum.Daily ||
    recurringValue === WeeklyScheduleFrequencyEnum.Weekly;

  const getRecurring = useCallback(
    (frequency: RecurringArticleDropdownValue) =>
      recurringValue === frequency
        ? recurringDate
        : existingRecurringValue === frequency
          ? existingRecurringDate
          : undefined,
    [
      existingRecurringDate,
      existingRecurringValue,
      recurringDate,
      recurringValue,
    ]
  );

  const getWeek = useCallback(() => {
    const recurring = getRecurring(WeeklyScheduleFrequencyEnum.Weekly);
    if (!recurring || recurring?.repeat?.length === weeks.length)
      return t('each week');

    return getFormattedOptions(recurring.repeat, weeks);
  }, [getFormattedOptions, getRecurring, t]);

  const getWeekDay = useCallback(() => {
    const recurring = getRecurring(WeeklyScheduleFrequencyEnum.Weekly);
    if (recurring)
      return recurring.start?.day !== recurring.end?.day
        ? `${recurring.start?.day?.label}-${recurring.end?.day?.label}`
        : recurring.start?.day?.label;

    return days[0].label;
  }, [getRecurring]);

  const getMonth = useCallback(() => {
    const recurring = getRecurring(MonthlyScheduleFrequencyEnum.Monthly);
    if (recurring) return getFormattedOptions(recurring.repeat, months);

    return getFormattedOptions(months, months);
  }, [getFormattedOptions, getRecurring]);

  const getMonthlyWeek = useCallback(() => {
    const recurring = getRecurring(MonthlyScheduleFrequencyEnum.Monthly);
    if (recurring) return getMonthlyWeekText(recurring);

    return `${weeks[0].label} ${days[0].label}`;
  }, [getMonthlyWeekText, getRecurring]);

  const getDaily = useCallback(() => {
    const recurring = getRecurring(DailyScheduleFrequencyEnum.Daily);
    if (recurring) return getFormattedOptions(recurring.repeat, days);

    return `${getDay(ScheduleWeekDay.Monday)}-${getDay(ScheduleWeekDay.Saturday)}`;
  }, [getFormattedOptions, getDay, getRecurring]);

  const repeatOptions = useMemo((): RecurringArticleValue[] => {
    switch (recurringValue) {
      case DailyScheduleFrequencyEnum.Daily:
        return days;
      case WeeklyScheduleFrequencyEnum.Weekly:
        return weeks;
      case MonthlyScheduleFrequencyEnum.Monthly:
        return months;
      default:
        return [];
    }
  }, [recurringValue]);

  const defaultRepeatOptions = useMemo((): RecurringArticleValue[] => {
    switch (recurringValue) {
      case DailyScheduleFrequencyEnum.Daily:
        return days.filter((day) => day.value !== ScheduleWeekDay.Sunday);
      case WeeklyScheduleFrequencyEnum.Weekly:
        return weeks;
      case MonthlyScheduleFrequencyEnum.Monthly:
        return months;
      default:
        return [];
    }
  }, [recurringValue]);

  const recurringOptions = useMemo(
    (): RecurringArticleOption<RecurringArticleDropdownValue>[] => [
      {
        label: t('Daily ({{date}})', {
          date: getDaily(),
        }),
        value: DailyScheduleFrequencyEnum.Daily,
      },
      {
        label: t('Weekly ({{week}}; {{day}})', {
          week: getWeek(),
          day: getWeekDay(),
        }),
        value: WeeklyScheduleFrequencyEnum.Weekly,
      },
      {
        label: t('Monthly ({{month}}; {{week}})', {
          month: getMonth(),
          week: getMonthlyWeek(),
        }),
        value: MonthlyScheduleFrequencyEnum.Monthly,
      },
    ],
    [getDaily, getMonth, getMonthlyWeek, getWeek, getWeekDay, t]
  );

  const defaultValues: RecurringArticleDate = useMemo(
    () => ({
      start: {
        day: isDaily(recurringValue) ? undefined : days[0],
        week: isDailyOrWeekly ? undefined : weeks[0],
        time: availableTime,
      },
      end: {
        day: isDaily(recurringValue) ? undefined : days[0],
        week: isDailyOrWeekly ? undefined : weeks[0],
        time: deadlineTime,
      },
      recurring: recurringOptions[0],
      repeat: defaultRepeatOptions,
    }),
    [
      availableTime,
      deadlineTime,
      defaultRepeatOptions,
      isDaily,
      isDailyOrWeekly,
      recurringOptions,
      recurringValue,
    ]
  );

  const updateRecurringDate = useCallback(
    (updatedFields: Partial<RecurringArticleDate>) => {
      if (!recurringDate) return;
      setRecurringDate({ ...recurringDate, ...updatedFields });
    },
    [recurringDate]
  );

  const onRecurringUpdate = useCallback(() => {
    const exists = existingRecurringValue === recurringValue;

    updateRecurringDate({
      repeat: exists ? existingRecurringDate?.repeat : defaultRepeatOptions,
      start: exists ? existingRecurringDate?.start : defaultValues.start,
      end: exists ? existingRecurringDate?.end : defaultValues.end,
    });
  }, [
    existingRecurringValue,
    recurringValue,
    updateRecurringDate,
    existingRecurringDate,
    defaultRepeatOptions,
    defaultValues.start,
    defaultValues.end,
  ]);

  useEffect(() => {
    if (!recurringDate) setRecurringDate(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const selectedRecurringValue = recurringOptions.find(
      ({ value }) => value === recurringDate?.recurring.value
    );
    if (!selectedRecurringValue) return;

    updateRecurringDate({ recurring: selectedRecurringValue });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repeat, recurringStart, recurringEnd]);

  useEffect(() => {
    onRecurringUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recurringValue]);

  const contextValue: RecurringArticleContextValues = {
    repeatOptions,
    recurringOptions,
    recurringDate,
    existingRecurringDate,
    updateRecurringDate,
  };

  return (
    <RecurringArticleContext.Provider value={contextValue}>
      {children}
    </RecurringArticleContext.Provider>
  );
};

const useRecurringArticleContext = () => {
  const context = useContext(RecurringArticleContext);

  if (context === undefined)
    throw new Error(
      'useRecurringArticleContext must be used within a RecurringArticleContext'
    );

  return context;
};

export { RecurringArticleContextProvider, useRecurringArticleContext };
export type { RecurringArticleContextValues };
