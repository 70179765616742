import { cx } from '@emotion/css';
import useGetActionAnswer from 'app/api/articles/hooks/useGetActionAnswer';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { FC } from 'react';
import {
  DbMediaTaskAnswer,
  MediaTaskBlockV1,
} from 'submodules/common-ui/generated/api/gcs';

import AnswerContainer from './AnswerContainer';
import { useAnswersContext } from './context';
import MediaTask from './MediaTask';
import EmptyMediaTasks from './MediaTask/EmptyMediaTasks';
import ErrorLoadingMediaTasks from './MediaTask/ErrorLoadingMediaTasks';
import LoadingMediaTasks from './MediaTask/LoadingMediaTasks';

interface Props {
  block: MediaTaskBlockV1;
}

const MediaTaskAnswers: FC<Props> = ({ block }) => {
  const { t } = useArticlesTranslation();
  const { setSelectedTaskId, selectedTenant } = useAnswersContext();
  const { actionAnswers, isLoading, error } = useGetActionAnswer({
    id: block.id,
    originalArticleId: selectedTenant?.originalArticleId,
    perPage: 4,
  });

  const showMoreBtnStyling = cx('w-[342px] h-[48px] rounded-xl bg-hover-blue');

  return (
    <AnswerContainer block={block} onClick={() => setSelectedTaskId(block.id)}>
      <div className="grid grid-cols-2 [&>*]:col-span-1 [&>*:last-child:nth-child(odd)]:col-span-2 gap-2 relative">
        {!isLoading &&
          actionAnswers?.map(({ id, answer, userId, createdAt }) => (
            <MediaTask
              key={id}
              image={answer as DbMediaTaskAnswer}
              userId={userId}
              createdAt={createdAt}
            />
          ))}

        {isLoading && (
          <>
            <LoadingMediaTasks />
            <LoadingMediaTasks />
          </>
        )}

        {error && <ErrorLoadingMediaTasks />}

        {actionAnswers?.length === 0 && <EmptyMediaTasks />}
      </div>

      {!isLoading && (actionAnswers?.length ?? 0) > 0 && !error && (
        <button
          onClick={() => setSelectedTaskId(block.id)}
          className={cx(
            showMoreBtnStyling,
            'text-sm p-3 text-focus hover:bg-focus-background'
          )}
        >
          {t('Show More')}
        </button>
      )}

      {isLoading && (
        <>
          <LoadingMediaTasks />
          <LoadingMediaTasks />
          <div className={showMoreBtnStyling}></div>
        </>
      )}

      {actionAnswers?.length === 0 && <div className={showMoreBtnStyling} />}

      {error && (
        <>
          <ErrorLoadingMediaTasks />
          <div className={showMoreBtnStyling}></div>
        </>
      )}
    </AnswerContainer>
  );
};

export default MediaTaskAnswers;
