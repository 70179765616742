import { useArticlesTranslation } from 'app/internationalization/hooks';
import removeTimezone from 'app/utils/removeTimezone';
import dayjs from 'dayjs';
import capitalize from 'lodash/capitalize';
import { useMemo } from 'react';
import {
  ArticleStatusEnum,
  BasicArticleInfo,
} from 'submodules/common-ui/generated/api/gcs';

const useArticleStatus = (article?: BasicArticleInfo) => {
  const { t } = useArticlesTranslation();

  const isPublished =
    !article?.publishAt ||
    article.publishedInstantly ||
    dayjs(article.publishAt).isBefore(dayjs(removeTimezone(new Date())));

  const publishStatus = isPublished
    ? t(capitalize(ArticleStatusEnum.Published))
    : t('Scheduled');

  const statuses: Record<ArticleStatusEnum, string> = useMemo(
    () => ({
      [ArticleStatusEnum.Published]: publishStatus,
      [ArticleStatusEnum.Draft]: t(capitalize(ArticleStatusEnum.Draft)),
      [ArticleStatusEnum.Archived]: t(capitalize(ArticleStatusEnum.Archived)),
      [ArticleStatusEnum.Inbound]: t(capitalize(ArticleStatusEnum.Inbound)),
      [ArticleStatusEnum.Rejected]: t(capitalize(ArticleStatusEnum.Rejected)),
    }),
    [t, publishStatus]
  );

  return statuses[article?.status || ArticleStatusEnum.Draft];
};

export default useArticleStatus;
