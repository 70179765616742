import { request } from 'app/api/subdomainRequest';
import config from 'app/config';
import { FileApi } from 'submodules/common-ui/generated/api/sub-domain-config';

const createFileSlot = (subdomain: string) => {
  return new FileApi(undefined, config.env.subdomainApiUrl, request())
    .createFileSlot(subdomain)
    .then((response) => response.data);
};

export default createFileSlot;
