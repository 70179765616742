import { useNode } from '@craftjs/core';
import { css, cx } from '@emotion/css';
import useGetArticleBlock from 'app/api/articles/hooks/useGetArticleBlock';
import { useAuthenticatedUser } from 'app/api/auth/hooks';
import config from 'app/config';
import useUnblockNextSection from 'app/hooks/useUnblockNextSection';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import useAnswerActionMutation from 'app/pages/ArticleEditor/hooks/useAnswerActionMutation';
import { actions, selectors } from 'app/store/editor';
import logger from 'app/utils/logger';
import { CloseCircle } from 'iconsax-react';
import {
  ComponentProps,
  FC,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DbOpenQuestionTaskAnswer,
  Paragraph,
  PostActionAnswer,
  UserAnswerResponse,
  ActionAnswerResponse,
} from 'submodules/common-ui/generated/api/gcs';

import SimpleTask from '../../../../SimpleTask';
import { useCurrentSelectedInstance } from '../../context';
import ActionContainer from '../ActionContainer';
import EditorNavigation from '../EditorNavigation';

import ActionButton from './ActionButton';
import AddOwnAnswerButton from './AddOwnAnswerButton';
import AnswerActionScore, {
  EditorAnswerActionScore,
} from './AnswerActionScore';
import useButtonLabel from './useButtonLabel';
import useDisplayAnswer from './useDisplayAnswer';

interface OpenQuestionActionProps {
  actionId: string;
  selectedProps: ComponentProps<typeof SimpleTask>;
  onAnswer: (data: UserAnswerResponse, actionId: string) => void;
  isEditor?: boolean;
  NavigationContainer: ({
    children,
  }: {
    children: React.ReactNode;
  }) => JSX.Element;
}

const OpenQuestionAction: FC<OpenQuestionActionProps> = ({
  actionId,
  selectedProps,
  onAnswer,
  isEditor = false,
  NavigationContainer,
}) => {
  const { t } = useArticlesTranslation();
  const dispatch = useDispatch();

  const [answer, setAnswer] = useState('');
  const [isAnswered, setIsAnswered] = useState(false);
  const [isAddingOwnAnswer, setIsAddingOwnAnswer] = useState(false);

  const { data: user } = useAuthenticatedUser();

  const {
    mutate: answerAction,
    isLoading,
    isSuccess,
    reset,
  } = useAnswerActionMutation();

  const currentLanguage = useSelector(selectors.getActiveLanguage);

  const {
    block: contextBlock,
    refetch: contextRefetch,
    currentSelectedInstanceId,
    answeredByColleague,
    colleagueAnswer,
  } = useCurrentSelectedInstance();

  const { block, refetch } = useGetArticleBlock({
    articleId: selectedProps.articleId!,
    blockId: parseInt(actionId),
    enabled: !!selectedProps.articleId && !contextBlock,
    timezone: user?.timezone,
    live: '1',
  });

  const activeBlock = contextBlock || block;

  const activeRefetch = useMemo(
    () => (contextBlock ? contextRefetch : refetch),
    [contextBlock, refetch, contextRefetch]
  );

  const currentInstanceId =
    currentSelectedInstanceId ?? activeBlock?.currentInstanceId;

  const currentUserAnswersList = activeBlock?.currentUserAnswers || [];
  const currentInstanceAnswer = currentUserAnswersList.find(
    (currentUserAnswer: ActionAnswerResponse) =>
      currentUserAnswer.actionInstanceId === currentInstanceId
  );

  const isActionCompleted =
    !!currentInstanceAnswer && currentInstanceAnswer.revertedAt === null;

  const displayAnswer = useDisplayAnswer({
    isAddingOwnAnswer,
    isActionCompleted,
    answeredByColleague,
    currentInstanceAnswer,
    colleagueAnswer,
  });

  const title = activeBlock?.variants?.[currentLanguage]?.title;
  const description = activeBlock?.variants?.[currentLanguage]?.description;

  const completeAction = () => {
    if (isLoading || !user) return;
    const answerPayload: Paragraph = {
      type: 'paragraph',
      children: [
        {
          format: {
            bold: false,
            italic: false,
            underline: false,
          },
          type: 'text',
          value: answer,
        },
      ],
    };

    const payload: PostActionAnswer = {
      actionId: parseInt(actionId),
      language: currentLanguage,
      actionInstanceId: currentInstanceId,
      answer: answerPayload,
      timezone: user?.timezone,
    };

    answerAction(payload, {
      onError: (e) => logger.error(e),
      onSuccess: (data) => {
        onAnswer(data, actionId);
        dispatch(
          actions.setStats({
            mandatoryProgress: data.mandatoryProgress,
            mandatoryScore: data.totalMandatoryScore,
            progress: data.progress,
            score: data.score,
          })
        );
        setIsAnswered(true);
        reset();
        activeRefetch();
        setIsAddingOwnAnswer(false);
      },
    });
  };

  const getAnswerValue = useCallback((): string => {
    if (!displayAnswer) return '';
    const eachObject = displayAnswer.answer as DbOpenQuestionTaskAnswer;
    if (!eachObject?.children) return '';
    let combinedValue = '';
    for (const child of eachObject.children) {
      if (child.type === 'text') {
        combinedValue += child.value + ' ';
      }
    }
    return combinedValue.trim();
  }, [displayAnswer]);

  useEffect(() => {
    const initialValue = getAnswerValue();
    setAnswer(initialValue);
    setIsAnswered(!!initialValue);
  }, [getAnswerValue, currentInstanceId]);

  const isTooEarly = currentInstanceId === null;

  const buttonLabel = useButtonLabel(
    isLoading,
    isSuccess,
    isTooEarly,
    activeBlock?.type
  );

  const renderFooterContent = () => {
    if (isAddingOwnAnswer) {
      return (
        <ActionButton
          onClick={completeAction}
          disabled={isTooEarly}
          isTooEarly={isTooEarly}
          answer={answer}
          buttonLabel={buttonLabel}
        />
      );
    }

    if (answeredByColleague && !isActionCompleted) {
      return (
        <AddOwnAnswerButton
          answeredByColleague={answeredByColleague}
          isActionCompleted={isActionCompleted}
          onAddAnswer={() => setIsAddingOwnAnswer(true)}
        />
      );
    }

    return (
      !isActionCompleted && (
        <ActionButton
          onClick={completeAction}
          disabled={isTooEarly}
          isTooEarly={isTooEarly}
          answer={answer}
          buttonLabel={buttonLabel}
        />
      )
    );
  };

  const renderAnswerActionScore = () => {
    if (isEditor) {
      return <EditorAnswerActionScore totalScore={displayAnswer?.score ?? 0} />;
    }
    return (
      <AnswerActionScore
        selectedProps={selectedProps}
        showQuizScore={false}
        showProgressScore={false}
        totalScore={0}
        blockId={parseInt(actionId)}
      />
    );
  };

  const placeholder = t('Type your answer...');

  return (
    <>
      {renderAnswerActionScore()}
      <ActionContainer
        isMandatory={selectedProps.mandatory}
        type={'open_question_task'}
        canAnswer={true}
      >
        <div className="flex flex-col gap-2">
          <span
            className={cx('text-lg font-bold', css('word-break: break-word;'))}
          >
            {title}
          </span>
          <span className={cx('text-black', css('word-break: break-word;'))}>
            {description}
          </span>
        </div>
        <div className="bg-grayscale-bg-dark border-gray-dark border-[1px] rounded-lg w-full h-[110px] mt-4 relative">
          {!!answer && !isActionCompleted && (
            <button
              type="button"
              className="absolute top-3 right-3"
              onClick={() => setAnswer('')}
            >
              <CloseCircle
                size={20}
                variant="Bold"
                color={config.colors['grayscale-secondary']}
              />
            </button>
          )}
          <textarea
            value={answer}
            onChange={(e) => setAnswer(e.currentTarget.value)}
            className={cx(
              'resize-none bg-transparent w-full h-full outline-none text-sm px-4 py-3 ',
              {
                'text-grayscale-secondary text-sm':
                  isActionCompleted ||
                  (answeredByColleague && !isAddingOwnAnswer),
              }
            )}
            placeholder={placeholder}
            disabled={
              isActionCompleted || (answeredByColleague && !isAddingOwnAnswer)
            }
          />
        </div>

        {isAnswered && (
          <div className="flex flex-col p-3 justify-center items-start gap-1 self-stretch rounded-xl bg-hover-blue mt-5">
            <span className="flex items-center gap-1">
              <span>🚀</span>
              <span className="text-sm text-grayscale-primary font-bold">
                {t('Thanks for answering!')}
              </span>
            </span>
            <span className="text-xs text-grayscale-primary">
              {t('Keep up the good work!')}
            </span>
          </div>
        )}

        <div className="flex justify-between items-center w-full mt-6">
          <NavigationContainer>{renderFooterContent()}</NavigationContainer>
        </div>
      </ActionContainer>
    </>
  );
};

export const EditorOpenQuestionAction = () => {
  const {
    selectedProps,
    nodeId,
    actions: { setProp },
  } = useNode((node) => ({
    selectedProps: node.data.props as ComponentProps<typeof SimpleTask>,
    nodeId: node.id,
  }));
  const { setIsUnblocked } = useUnblockNextSection(nodeId);

  const { data: user } = useAuthenticatedUser();

  if (!user) return null;

  return (
    <OpenQuestionAction
      selectedProps={selectedProps}
      actionId={nodeId}
      onAnswer={(data) => {
        setProp((props: ComponentProps<typeof SimpleTask>) => {
          props.currentUserAnswers = [{ ...data, completedBy: user.id }];
          props.completed = true;
        });

        setIsUnblocked(true);
      }}
      isEditor={true}
      NavigationContainer={EditorNavigation}
    />
  );
};

export default OpenQuestionAction;
