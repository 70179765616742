import useGetCollaboratorsQuery from 'app/api/articleCollaborators/hooks/useGetArticleCollaboratorsInfiniteQuery';
import { ArticleCollaborator } from 'app/api/articleCollaborators/types';
import { InfiniteScroll, PageLoader } from 'app/components';
import Input from 'app/components/Input2';
import ActionToast from 'app/components/Toast/ActionToast';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { actions, selectors } from 'app/store/editor';
import { Collaborator, Language } from 'app/store/editor/types';
import debounce from 'lodash/debounce';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import AddedCollaborators from './AddedCollaborators';
import CollaboratorItem from './CollaboratorItem';

interface Props {
  language: Language;
  languageCollaborators: Collaborator[];
  onSave: () => void;
}

const LanguageCollaboratorsDropdown = ({
  languageCollaborators,
  language,
  onSave,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useArticlesTranslation();

  const selectedCollaborators = useSelector(selectors.getSelectedCollaborators);

  const [search, setSearch] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const { data, isLoading, hasNextPage, fetchNextPage } =
    useGetCollaboratorsQuery({
      role: 'collaborators',
      permissions: 'update',
      search,
    });

  const allCollaborators: ArticleCollaborator[] = useMemo(
    () => data || [],
    [data]
  );

  const onSearchClear = () => setSearch('');
  const onSearch = debounce(
    (value) => setSearch((prev) => (value === prev ? prev : value)),
    500
  );

  const isLanguageCollaboratorsNotEmpty = languageCollaborators.length > 0;
  const isAllCollaboratorsNotEmpty = allCollaborators.length > 0;

  const searchedCollaborators = search
    ? allCollaborators.filter(({ fullName }) =>
        fullName.toLowerCase().includes(search.toLowerCase())
      )
    : allCollaborators;

  useEffect(() => {
    if (!isSaving) return;
    onSave();
    setIsSaving(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaving]);

  const showToast = (text: string, onClick: () => void) => {
    toast.dismiss();
    toast.clearWaitingQueue();

    toast(
      <ActionToast action={`${t('Undo')}`} text={text} onClick={onClick} />,
      {
        position: 'bottom-center',
        autoClose: 5000,
        closeButton: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        containerId: 'Simple',
      }
    );
  };

  const addCollaborator = (collaborator: Collaborator) =>
    dispatch(actions.addCollaborator(collaborator));

  const assignLanguageForCollaborator = (id: number) =>
    dispatch(
      actions.addLanguageForCollaborator({
        collaboratorId: id,
        languageCode: language.code,
      })
    );

  const removeLanguageForCollaborator = (id: number) =>
    dispatch(
      actions.removeLanguageFromCollaborator({
        collaboratorId: id,
        languageCode: language.code,
      })
    );

  const removeCollaborator = (id: number) => {
    const collaborator = languageCollaborators.find((user) => user.id === id);
    if (!collaborator) return;

    removeLanguageForCollaborator(collaborator.id);

    showToast(
      `${t('{{ fullName }} removed from {{ languageName }}', {
        fullName: collaborator.fullName,
        languageName: language.name,
      })}`,
      () => assignLanguageForCollaborator(collaborator.id)
    );

    setIsSaving(true);
  };

  const assignCollaborator = (collaborator: ArticleCollaborator) => {
    const isCollaboratorExists = selectedCollaborators.some(
      ({ id }) => id === collaborator.id
    );
    isCollaboratorExists
      ? assignLanguageForCollaborator(collaborator.id)
      : addCollaborator({
          id: collaborator.id,
          fullName: collaborator.fullName,
          avatar: collaborator.avatars.small,
          languages: [language.code],
          email: collaborator.email,
        });

    showToast(
      `${t('{{ fullName }} added to {{ languageName }}', {
        fullName: collaborator.fullName,
        languageName: language.name,
      })}`,
      () => removeLanguageForCollaborator(collaborator.id)
    );

    setIsSaving(true);
  };

  return (
    <div
      role="presentation"
      onClick={(event) => event.stopPropagation()}
      className="rounded w-[260px] md:right-auto absolute bg-white z-50 transform -translate-y-2 shadow-atobi cursor-auto"
    >
      {isLanguageCollaboratorsNotEmpty && (
        <AddedCollaborators
          collaborators={languageCollaborators}
          removeCollaborator={removeCollaborator}
        />
      )}
      <div className="flex flex-col">
        <span className="text-grayscale-secondary text-xs font-bold pt-3 pb-2 px-3">
          {t('Assign one or more collaborators')}
        </span>
        <div className="px-3 pb-1">
          <Input
            className="h-10 rounded-[8px] outline-[1px]"
            placeholder={t('Search')}
            onChange={(e) => onSearch(e.target.value.toLowerCase())}
            onClear={onSearchClear}
            isSearch={true}
            searchIconSize={16}
          />
        </div>
        <div id="parent" className="overflow-y-auto z-10 h-[220px]">
          {isLoading && <PageLoader />}
          {isAllCollaboratorsNotEmpty && (
            <InfiniteScroll
              dataLength={searchedCollaborators?.length ?? 0}
              hasMore={!!hasNextPage}
              next={fetchNextPage}
              scrollableTarget="parent"
              loader={<PageLoader />}
            >
              {searchedCollaborators.map((collaborator) => (
                <CollaboratorItem
                  key={collaborator.id}
                  collaborator={collaborator}
                  languageCollaborators={languageCollaborators}
                  assignCollaborator={assignCollaborator}
                />
              ))}
            </InfiniteScroll>
          )}
          {!isAllCollaboratorsNotEmpty && !isLoading && (
            <div className="flex justify-center items-center h-[220px]">
              {t('No results')}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LanguageCollaboratorsDropdown;
