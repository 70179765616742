import { cx } from '@emotion/css';
import useDateTimeFormat from 'app/hooks/useDateTimeFormat';
import useUserQuery from 'app/pages/profiles/PublicProfile/useUserQuery';
import { selectors } from 'app/store/editor';
import { useSelector } from 'react-redux';
import {
  ActionTypeEnum,
  DbMultiChoiceAnswer,
  MultiChoiceBlockV1,
} from 'submodules/common-ui/generated/api/gcs';

import { useAnswersContext } from '../../context';
import UserDetails from '../UserDetails';

import ChoiceItem from './ChoiceItem';

interface Props {
  userId: number;
  answers: DbMultiChoiceAnswer;
  createdAt: string;
  score?: number | null;
}

const MultiChoiceTaskDeepDive = ({
  answers,
  createdAt,
  userId,
  score,
}: Props) => {
  const { layout, selectedTaskId, article } = useAnswersContext();

  const language = useSelector(selectors.getActiveLanguage);

  const { data: user, isLoading: isLoadingUser } = useUserQuery({ id: userId });

  const selectedTaskBlock = article?.blocks.find(
    (block) =>
      block.id === selectedTaskId && block.type === ActionTypeEnum.MultiChoice
  );

  const createdAtTime = useDateTimeFormat({
    timestamp: createdAt,
    type: 'dayMonthYearTime',
  });

  return (
    <div
      className={cx(
        'rounded-xl gap-4 bg-white shadow-atobi flex flex-col p-4',
        {
          'bg-hover-blue': isLoadingUser,
          'bg-grayscale-bg-dark': !isLoadingUser,
        }
      )}
    >
      <UserDetails
        isLoadingUser={isLoadingUser}
        user={user}
        createdAtTime={createdAtTime}
        layout={layout}
        score={score}
      />

      {answers.map((answer) => (
        <ChoiceItem
          key={answer.id}
          userChoice={answer}
          selectedTaskBlock={selectedTaskBlock as MultiChoiceBlockV1}
          language={language}
        />
      ))}
    </div>
  );
};

export default MultiChoiceTaskDeepDive;
