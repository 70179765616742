import { SuccessModal } from 'app/components';
import { SuccessModalProps } from 'app/components/SuccessModal';
import ScormAnswerModal from 'app/pages/ArticleEditor/components/blocks/tasks/components/ActionSettings/components/Modals/ScormAnswerModal';
import YesNoConfirmModal from 'app/pages/ArticleEditor/components/blocks/tasks/components/ActionSettings/components/Modals/YesNoConfirmModal';
import { selectors } from 'app/store/modal';
import { ModalProps, ModalTypes } from 'app/store/modal/types';
import { useSelector } from 'react-redux';

import ChannelModal from './ChannelModal/ChannelModal';
import ForwardModal from './ForwardModal';
import DeleteSharedArticleModal from './ForwardModal/DeleteSharedArticleModal';
import LanguagesModal, {
  LanguagesModalProps,
} from './LanguagesModal/LanguagesModal';
import ScheduleModal from './ScheduleModal';
import { SuccessModal as ShareSuccessModal } from './SuccessModal';

const ModalHandler = (props: ModalProps) => {
  const MODAL_COMPONENTS = {
    success_modal: SuccessModal,
    languages_modal: LanguagesModal,
    share_success_modal: ShareSuccessModal,
    forward_modal: ForwardModal,
    delete_shared_article_modal: DeleteSharedArticleModal,
    add_channel_modal: ChannelModal,
    schedule_modal: ScheduleModal,
    yes_no_not_possible_modal: YesNoConfirmModal,
    scorm_answer_modal: ScormAnswerModal,
  };

  const { modalType, modalProps } = useSelector(selectors.getOpenedModals);
  if (!modalType) {
    return null;
  }

  const SpecificModal = MODAL_COMPONENTS[modalType];

  if (modalType === ModalTypes.LANGUAGES_MODAL) {
    const { onSaveClick } = props as LanguagesModalProps;

    return <SpecificModal {...(props as any)} onSaveClick={onSaveClick} />;
  }

  if (modalType === ModalTypes.SUCCESS_MODAL) {
    const { onCancel, onSubmit } = props as SuccessModalProps;

    return (
      <SpecificModal
        {...(modalProps as any)}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
    );
  }

  return <SpecificModal {...(modalProps as any)} />;
};

export default ModalHandler;
