import { FormFields } from 'app/pages/Members/memberFormSchema';

import { request } from '../../request';

const updateMember = ({ member, id }: { member: FormFields; id: number }) => {
  const {
    firstName,
    lastName,
    locations,
    translationLanguage,
    uiLanguage,
    ...rest
  } = member;

  return request().patch(`/api/v1/user-management/${id}`, {
    ...rest,
    first_name: firstName,
    last_name: lastName,
    user_locations: locations,
    translation_language: translationLanguage,
    ui_language: uiLanguage,
    id: id,
  });
};

export default updateMember;
