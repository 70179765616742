import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import { ResponsiveImage } from 'app/components';
import useImageSource from 'app/hooks/useImageSource';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import useUIArticleLanguage from 'app/pages/Articles/hooks/useUIArticleLanguage';
import useArticleStatus from 'app/pages/Editor/hooks/useArticleStatus';
import DocumentError from 'assets/icons/document-error.svg?react';
import { ArrowCircleRight2 } from 'iconsax-react';
import capitalize from 'lodash/capitalize';
import {
  BasicArticleInfo,
  ArticleStatusEnum,
} from 'submodules/common-ui/generated/api/gcs';

const ArticleContainer = styled.div``;

interface LinkedArticleProps {
  article?: BasicArticleInfo;
  className?: string;
  endUser?: boolean;
  unavailable?: boolean;
  onClick(): void;
}

const LinkedArticle = ({
  article,
  className,
  endUser,
  unavailable,
  onClick,
}: LinkedArticleProps) => {
  const { t } = useArticlesTranslation();
  const { getMainLanguage } = useUIArticleLanguage();
  const status = useArticleStatus(article);

  const mainLanguage = getMainLanguage(article?.languages);
  const {
    directUrls,
    external,
    hasError,
    hasLoaded,
    imageSource,
    setHasError,
    setHasLoaded,
  } = useImageSource(article?.variants?.[mainLanguage]?.coverImage);

  return (
    <ArticleContainer
      className={cx(
        'relative flex gap-4 items-center cursor-pointer group',
        className
      )}
      onClick={onClick}
    >
      {!hasLoaded && !hasError && (
        <div className="h-10 min-w-18 w-18 rounded relative flex items-center justify-center border-2 border-white bg-focus-background text-focus">
          <DocumentError />
        </div>
      )}
      <div
        className={cx('relative', {
          hidden: (!hasLoaded && !hasError) || hasError,
        })}
      >
        <ResponsiveImage
          alt={imageSource}
          className={cx('border-2 border-white object-cover', {
            'h-10 min-w-18 w-18 rounded shadow-atobi': !endUser,
            'h-[108px] min-w-[192px] w-[192px] rounded-l-lg': endUser,
          })}
          directUrls={directUrls}
          external={external}
          small={0}
          src={imageSource}
          onLoad={() => {
            setHasLoaded(true);
            setHasError(false);
          }}
          onError={() => {
            setHasLoaded(true);
            setHasError(true);
          }}
        />
        {endUser && (
          <div className="bg-gradient-to-l from-white h-full w-[52px] absolute bottom-0 right-0"></div>
        )}
      </div>
      <p
        className={cx('flex-auto', {
          'text-grayscale-secondary': !article && !unavailable,
          'text-error': article && unavailable,
          truncate: !endUser,
          'my-6 line-clamp-2': endUser,
        })}
      >
        {article
          ? unavailable
            ? t('Oh no! The Article you’re linking is not accessible')
            : article.variants?.[mainLanguage]?.title
          : t('Link an article')}
      </p>
      {article && !endUser && !unavailable && (
        <p className="flex items-center text-grayscale-secondary">
          <span className="h-1 w-1 mr-3 rounded-full bg-gray-dark"></span>
          {status === capitalize(ArticleStatusEnum.Published)
            ? t('Live')
            : status === capitalize(ArticleStatusEnum.Inbound)
              ? t('Received')
              : status}
        </p>
      )}
      {endUser && !unavailable && (
        <>
          <div className="text-ceil mx-2 group-hover:text-hover-primary">
            <ArrowCircleRight2 size={26} />
          </div>
          <div className="h-[calc(100%-4px)] w-10 absolute top-0.5 right-0.5 p-0.5 bg-gradient-to-l from-hover-blue rounded-r-lg -z-10" />
        </>
      )}
    </ArticleContainer>
  );
};

export default LinkedArticle;
