import { css, cx } from '@emotion/css';
import config from 'app/config';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import dayjs from 'app/utils/dayjs';
import { ClipboardTick } from 'iconsax-react';
import { useCallback, useEffect, useRef } from 'react';
import { CoverImageArticleFileGet } from 'submodules/common-ui/generated/api/gcs/api';

interface CoverImageProps
  extends Pick<React.HTMLAttributes<HTMLDivElement>, 'className'> {
  coverImage: CoverImageArticleFileGet;
  progress?: number | null;
  isNew?: boolean;
  onLoad?: VoidFunction;
  onError?: VoidFunction;
}

const CoverImage = ({
  coverImage,
  isNew = false,
  progress,
  className,
  onLoad,
  onError,
}: CoverImageProps) => {
  const { t } = useArticlesTranslation();
  const urlExpired = (url: string) => {
    const _url = new URL(url);

    const expiry = _url.searchParams.get('se');

    return dayjs() > dayjs(expiry);
  };

  const getUrl = useCallback(() => {
    if (!coverImage) return '';

    if (coverImage.type === 'external') return coverImage.url;

    const directUrl =
      coverImage.directUrls?.small?.url ?? coverImage.directUrls?.original?.url;

    if (!directUrl || urlExpired(directUrl)) {
      return `${config.env.gcsApiUrl}/${coverImage.url}`;
    }

    return directUrl;
  }, [coverImage]);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const url = getUrl();

    const bgImg = new Image();

    bgImg.onload = () => {
      if (!ref.current) return;
      ref.current.style.backgroundImage = 'url(' + url + ')';
      onLoad?.();
    };

    bgImg.onerror = () => {
      onError?.();
    };

    bgImg.src = url;
  }, [getUrl, onLoad, onError]);

  return (
    <div className="border border-gray-silver-blue rounded-[14px]">
      <div className="relative overflow-hidden rounded-[13px]">
        <div
          ref={ref}
          className={cx(
            'flex bg-cover bg-no-repeat bg-center md:hover:scale-[1.2] md:transition-all md:duration-300 md:ease-in-out',
            className
          )}
        />

        {isNew && (
          <div className="flex items-center justify-center ml-2 mt-2 absolute top-0 left-0 rounded-lg bg-black opacity-[0.65]">
            <span className="text-white text-10 px-3 py-1.5">{t('NEW')}</span>
          </div>
        )}

        {Number(progress) > 0 && (
          <>
            <div className="flex items-center justify-center py-[5px] px-3 mr-2 mb-3 absolute bottom-0 right-0 rounded-lg text-white">
              <div className="w-full flex items-center gap-1 z-10">
                <ClipboardTick size={14} />
                <span className="text-10">{progress?.toFixed(0) || 0}%</span>
              </div>

              <div className="absolute w-full h-full bg-black opacity-[0.65] rounded-lg" />
            </div>

            <div className="w-full absolute bottom-0">
              <div className="relative w-full h-1">
                <div className="absolute w-full h-full bg-focus-background opacity-[0.45] rounded-bl-xl rounded-br-xl" />
                <div
                  className={cx(
                    'absolute h-full bg-focus rounded-bl-xl rounded-br-full',
                    { 'rounded-tr-full': progress !== 100 },
                    css(`
                    width: ${progress || 0}%;
                  `)
                  )}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CoverImage;
