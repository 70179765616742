import dayjs from 'dayjs';
import { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import {
  MonthlyScheduleFrequencyEnum,
  WeeklyScheduleFrequencyEnum,
} from 'submodules/common-ui/generated/api/gcs';

import {
  RecurringArticleAction,
  RecurringArticleDropdownValue,
  RecurringArticleOption,
  days,
  weeks,
} from '../types';

import RecurringArticleTimeAction from './RecurringArticleTimeAction';

interface Props {
  selection?: RecurringArticleAction;
  selectedDropdownOption?: RecurringArticleOption<RecurringArticleDropdownValue>;
  onChange: (item: RecurringArticleAction) => void;
}

const RecurringArticleTime = ({
  selection,
  selectedDropdownOption,
  onChange,
}: Props) => {
  const [isOpen, setIsOpen] = useState<{
    [key in keyof RecurringArticleAction]?: boolean;
  }>({
    day: false,
    week: false,
    time: false,
  });

  const handleToggle = (key: keyof RecurringArticleAction, open: boolean) =>
    setIsOpen(() => ({ [key]: open }));

  const hours = Array.from({ length: 24 }, (_, index) => index);
  const formattedHours = hours.map((hour) =>
    dayjs().hour(hour).format('HH:00')
  );

  const isWeekly =
    selectedDropdownOption?.value === WeeklyScheduleFrequencyEnum.Weekly;
  const isMonthly =
    selectedDropdownOption?.value === MonthlyScheduleFrequencyEnum.Monthly;

  return (
    <div className="w-fit">
      <OutsideClickHandler onOutsideClick={() => setIsOpen({})}>
        <div className="flex border border-gray-light rounded h-10 w-fit min-w-[104px] px-2">
          {isMonthly && selection?.week && (
            <RecurringArticleTimeAction
              item={{
                label: selection.week.label,
                value: selection.week.label,
              }}
              setIsOpen={(open) => handleToggle('week', open)}
              isOpen={!!isOpen.week}
              items={weeks.map(({ label }) => ({ label, value: label }))}
              setItem={(item) => {
                const week = weeks.find(({ label }) => label === item.label);
                onChange({ ...selection, week });
                setIsOpen({});
              }}
            />
          )}
          {(isWeekly || isMonthly) && selection?.day && (
            <RecurringArticleTimeAction
              item={selection.day}
              setIsOpen={(open) => handleToggle('day', open)}
              isOpen={!!isOpen.day}
              items={days}
              setItem={(item) => {
                onChange({ ...selection, day: item });
                setIsOpen({});
              }}
            />
          )}
          {selection && (
            <RecurringArticleTimeAction
              item={{ label: selection.time, value: selection.time }}
              setIsOpen={(open) => handleToggle('time', open)}
              isOpen={!!isOpen.time}
              items={formattedHours.map((hour) => ({
                label: hour,
                value: hour,
              }))}
              setItem={(item) => {
                onChange({ ...selection, time: item.value });
                setIsOpen({});
              }}
            />
          )}
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default RecurringArticleTime;
