/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RetrievedGlossaryEntitySchema } from 'submodules/common-ui/generated/api/gcs';

import { NAME } from './constants';
import { State } from './types';

const initialState: State = {
  glossaries: [],
};

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setGlossaries: (
      state,
      { payload }: PayloadAction<RetrievedGlossaryEntitySchema[]>
    ) => {
      state.glossaries = payload;
    },
  },
});

export default slice;
