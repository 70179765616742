import { cx } from '@emotion/css';
import { ChannelCoverImage as IChannelCoverImage } from 'app/api/channels/types';
import { PageLoader, ResponsiveImage } from 'app/components';
import useImageSource from 'app/pages/Channels/hooks/useCoverImage';
import { Gallery } from 'iconsax-react';

interface Props {
  image: IChannelCoverImage;
}

const ChannelModalImage = ({ image }: Props) => {
  const {
    directUrls,
    external,
    hasError,
    hasLoaded,
    imageSource,
    setHasError,
    setHasLoaded,
  } = useImageSource(image);

  return (
    <>
      {!hasLoaded && !hasError && (
        <div className="w-full h-full">
          <PageLoader />
        </div>
      )}
      {imageSource && (
        <ResponsiveImage
          alt={imageSource}
          className={cx('object-cover h-full w-full', {
            hidden: (!hasLoaded && !hasError) || hasError,
          })}
          directUrls={directUrls}
          external={external}
          small={0}
          src={imageSource}
          onLoad={() => {
            setHasLoaded(true);
            setHasError(false);
          }}
          onError={() => setHasError(true)}
        />
      )}
      {(hasError || !imageSource) && (
        <div className="w-full h-full flex items-center justify-center bg-hover-blue text-focus">
          <Gallery size={24} />
        </div>
      )}
    </>
  );
};

export default ChannelModalImage;
