import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import useGetSettingsQuery from 'app/api/pwaSettings/hooks/useGetSettingsQuery';
import { useSelector } from 'app/hooks';
import { selectors as authSelectors } from 'app/store/auth';
import { resolveSubdomain } from 'app/utils/subdomain';
import logoLg from 'assets/icons/logo-lg.svg';
import logo from 'assets/icons/logo.svg';
import React, { useMemo } from 'react';

interface LogoProps {
  size: 'xs' | 'sm' | 'lg' | 'md';
  visible?: boolean;
  className?: string;
}

const sizeMap: { [key: string]: { width: string; height: string } } = {
  sm: {
    width: '105px',
    height: '36px',
  },
  md: {
    width: '40px',
    height: '40px',
  },
  xs: {
    width: '32px',
    height: '32px',
  },
};

const StyledImage = styled.img<LogoProps>`
  width: ${({ size, visible }) => (visible ? sizeMap[size].width : '0px')};
  height: ${({ size, visible }) => (visible ? sizeMap[size]?.height : '0px')};
  opacity: ${({ visible }) => (visible ? '1' : '0')};
`;

const Logo: React.FC<LogoProps> = ({ size = 'sm', visible, className }) => {
  const { data } = useGetSettingsQuery({ enabled: false });
  const customLogo = useSelector(authSelectors.getSelectedTenant)?.logo;

  const logoToRender = useMemo(() => {
    const subdomain = resolveSubdomain();

    const fallback = size === 'sm' ? logo : logoLg;

    if (subdomain === 'app') {
      return customLogo ?? fallback;
    }

    return data?.logoUrl ?? customLogo ?? fallback;
  }, [customLogo, data, size]);

  return (
    <StyledImage
      visible={visible}
      size={size}
      src={logoToRender}
      alt="Atobi"
      className={cx('duration-300', className)}
    />
  );
};

export default Logo;
