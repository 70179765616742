import { useNewsArticleQuery } from 'app/api/news/hooks';
import { useParams } from 'react-router-dom';

import DefaultBreadcrumb from './DefaultBreadcrumb';

const NewsBreadcrumb = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = useNewsArticleQuery({
    id: Number(id),
    enabled: !Number.isNaN(Number(id)),
  });

  return <DefaultBreadcrumb to={window.location.href} title={data?.title} />;
};

export default NewsBreadcrumb;
