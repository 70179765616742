import narrowUnknown from 'app/utils/narrowUnknown';
import { useQuery } from 'react-query';

import { queryKeys, resources } from '../index';

interface Props {
  actionId: number;
  actionInstanceId?: number;
  originalArticleId?: number | null;
  enabled?: boolean;
}

const useGetActionAnswerSummary = ({
  actionId,
  actionInstanceId,
  originalArticleId,
  enabled = true,
}: Props) => {
  const {
    data,
    isLoading,
    isFetching,
    error: apiError,
    refetch,
  } = useQuery(
    queryKeys.actionAnswerSummary(actionId, actionInstanceId),
    () =>
      resources.getActionAnswerSummary(
        actionId,
        actionInstanceId,
        originalArticleId
      ),
    {
      enabled,
    }
  );

  return {
    summary: data,
    isLoading,
    isFetching,
    error: narrowUnknown(apiError),
    apiError,
    refetch,
  };
};

export { useGetActionAnswerSummary };
export default useGetActionAnswerSummary;
