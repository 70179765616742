import { useEditor, useNode } from '@craftjs/core';
import { cx } from '@emotion/css';
import useIsEditorEnabled from 'app/hooks/useIsEditorEnabled';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import apiToLexical from 'app/pages/ArticleEditor/helpers/lexical/apiToLexical';
import lexicalInitialState from 'app/pages/ArticleEditor/helpers/lexical/initialState';
import lexicalToApi from 'app/pages/ArticleEditor/helpers/lexical/lexicalToApi';
import dragArea from 'assets/icons/drag.svg';
import { Add, CloseCircle, TickCircle } from 'iconsax-react';
import { useCallback, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import {
  InternalFileGet,
  Paragraph,
} from 'submodules/common-ui/generated/api/gcs';

import LexicalInputWrapper from './Lexical/LexicalInputWrapper';
import QuizOptionImage from './QuizOptionImage';

export interface QuizOptionProps {
  imageSchema?: InternalFileGet;
  checked?: boolean;
  text?: Paragraph;
}

const QuizOption = ({
  checked = false,
  text,
  imageSchema,
}: QuizOptionProps) => {
  const { t } = useArticlesTranslation();
  const [emptyOptionsError, setEmptyOptionsError] = useState(false);

  const {
    connectors: { select },
    query: { node: getNode },
    actions: { delete: deleteNode },
    nodes,
  } = useEditor((state) => ({ nodes: state.nodes }));

  const {
    connectors: { connect, drag },
    actions: { setProp },
    nodeId,
    parent,
    container,
    index,
  } = useNode((node) => {
    let parents: Array<string> = [];
    let children: Array<string> = [];

    if (node.data?.parent) {
      const { ancestors, childNodes } = getNode(node.data.parent);
      parents = ancestors();
      children = childNodes();
    }

    return {
      container: node.data.parent,
      parent: parents[0] ?? null,
      nodeId: node.id,
      index: Array.from(children).indexOf(node.id) + 1,
    };
  });

  const { enabled } = useIsEditorEnabled();

  const getInitialInputstate = () => {
    if (!text) {
      return lexicalInitialState();
    }

    const parsedToEditor = apiToLexical(text);
    return JSON.stringify(parsedToEditor);
  };

  const removeOption = () => {
    if (!container) return;
    if (nodes[container]?.data?.nodes?.length === 1) return;
    deleteNode(nodeId);
  };

  const quizBlockSelected = useCallback(
    () => nodes[parent].events.selected,
    [nodes, parent]
  );

  const renderTooltip = () => (
    <div>
      <div className="font-bold font-sm text-center">
        {t('Mark as correct')}
      </div>
      <div className="font-xs text-center font-normal">
        {t('Grants a score of 100.')}
      </div>
    </div>
  );

  return (
    <div ref={(ref) => ref && connect(ref)}>
      <div
        ref={(ref) => ref && select(ref, parent)}
        className="flex items-center mt-2 relative"
      >
        <div
          ref={(ref) => ref && drag(ref)}
          className="absolute -left-5 bg-white opacity-0 hover:opacity-100"
        >
          <img
            className="h-4 w-4 opacity-70 cursor-pointer hover:opacity-100"
            src={dragArea}
            alt="Drag"
          />
        </div>
        <div className="font-sm font-normal text-gray-dark pl-0.5">
          {index.toLocaleString(undefined, {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}
        </div>
        <LexicalInputWrapper
          initialState={getInitialInputstate()}
          disabled={!enabled || !quizBlockSelected()}
          error={enabled ? emptyOptionsError : false}
          onChange={(state) => {
            const lexicalText = lexicalToApi(state.root.children);

            setEmptyOptionsError(false);
            if (
              lexicalText.children.length === 0 ||
              (lexicalText.children[0]?.type === 'text' &&
                !lexicalText.children[0].value)
            ) {
              setEmptyOptionsError(true);
            }
            setProp((props: QuizOptionProps) => {
              props.text = lexicalText;
            });
          }}
        />
        <div className="flex items-center">
          <div className="flex items-center">
            <button
              className={cx('p-0.5 rounded-md', {
                'bg-focus-background': checked,
              })}
              data-tip=""
              data-for="option"
              disabled={!enabled}
            >
              <TickCircle
                className="w-6 h-6 text-success"
                onClick={() =>
                  setProp((props: QuizOptionProps) => {
                    props.checked = true;
                  })
                }
              />
            </button>
            <button
              className={cx('p-0.5 rounded-md', {
                'bg-error-light': !checked,
              })}
              disabled={!enabled}
            >
              <CloseCircle
                className="w-5 h-5 text-error"
                onClick={() =>
                  setProp((props: QuizOptionProps) => {
                    props.checked = false;
                  })
                }
              />
            </button>
          </div>
          <div className="bg-gray-light h-4 w-[1px] mx-3" />
          <QuizOptionImage
            disabled={!enabled}
            error={enabled ? emptyOptionsError : false}
            imageSchema={imageSchema}
            onImageUploaded={(image, err) => {
              if (err) return;

              setProp((props: QuizOptionProps) => {
                props.imageSchema = image;
              });
            }}
          />
          <div className="bg-gray-light h-4 w-[1px] mx-3" />
          <Add
            data-tip={t('Delete option')}
            data-for="delete"
            onClick={() => {
              if (!enabled) return;

              removeOption();
            }}
            size={20}
            className={cx('ml-2 rotate-45 text-grayscale-secondary', {
              'hover:text-black': enabled,
            })}
          />
        </div>
      </div>

      {enabled && (
        <>
          <ReactTooltip effect="solid" class="react-tooltip" id="option">
            {renderTooltip()}
          </ReactTooltip>
          <ReactTooltip
            place="top"
            effect="solid"
            class="react-tooltip"
            id="delete"
          />
        </>
      )}
    </div>
  );
};

QuizOption.craft = {
  displayName: 'QuizOption',
};

export default QuizOption;
