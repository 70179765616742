import { Switch } from 'app/components';
import { useArticlesTranslation } from 'app/internationalization/hooks';
interface Props {
  checked: boolean;
  disabled?: boolean;
  onChange: VoidFunction;
}
const Mandatory = ({ checked, disabled = false, onChange }: Props) => {
  const { t } = useArticlesTranslation();
  return (
    <div className="flex items-end gap-1.5">
      <span className="text-xs text-gray-dark">{t('Mandatory')}</span>
      <Switch
        height={14}
        width={24}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
};

export default Mandatory;
