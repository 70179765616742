import { Gallery } from 'iconsax-react';

const ImageCounter = ({
  current,
  total,
}: {
  current: number;
  total: number;
}) => {
  return (
    <div className="w-1/3">
      <div className="inline-flex items-center justify-center p-4 mr-auto rounded-xl text-white bg-grayscale-secondary opacity-8">
        <Gallery size={14} />
        <span className="ml-0.5 text-xs">
          {current + 1}/{total}
        </span>
      </div>
    </div>
  );
};

export default ImageCounter;
