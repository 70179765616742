import { setRequestToken } from 'app/api';
import { LoginMethods } from 'app/api/auth/constants';
import createOpenIdManager from 'app/api/createOpenIdManager';
import config from 'app/config';
import useTenantsMutation from 'app/pages/Login/useTenantsMutation';
import { actions } from 'app/store/auth';
import { actions as configActions } from 'app/store/config';
import Message, { MessageKeys } from 'app/swTypes';
import dayjs from 'dayjs';
import { jwtDecode } from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

let isLoading = false;

const removeOidcItemsFromLocalStorage = () => {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key && key.startsWith('oidc')) {
      localStorage.removeItem(key);
    }
  }
};

const useOpenIdManager = () => {
  const dispatch = useDispatch();
  const { mutateAsync: getTenants } = useTenantsMutation();
  const navigate = useNavigate();

  const redirectCallback = async (originUrl?: string) => {
    const hasCodeParam = new URLSearchParams(location.search).get('code') || '';
    if (hasCodeParam && !isLoading) {
      isLoading = true;
      const openIdManager = createOpenIdManager(originUrl);
      let user = undefined;

      try {
        user = await openIdManager.signinRedirectCallback();
      } catch (error) {
        await openIdManager.signinRedirect();
      }
      if (!user) {
        return;
      }

      const { login_method }: { login_method: LoginMethods } = jwtDecode(
        user.access_token
      );
      setRequestToken(user.access_token);

      if (login_method === LoginMethods.EasyAccess) {
        dispatch(configActions.easyAccessToken(true));
      }

      const expiresAt = dayjs().add(user.expires_in, 'seconds').valueOf();

      dispatch(actions.tokenRetrieved(user.access_token));
      dispatch(actions.idTokenRetrieved(user.id_token));
      dispatch(actions.tokenExpiresAt(expiresAt));
      if (user.refresh_token) {
        dispatch(actions.refreshTokenRetrieved(user.refresh_token));
      }

      navigator.serviceWorker.controller?.postMessage({
        key: MessageKeys.tokenReady,
        value: {
          token: user.access_token,
          url: config.env.pushNotificationsApiUrl,
          vapidKey: config.env.vapidKey,
        },
      } satisfies Message);

      const { tenant, email }: { email: string; tenant: string } = jwtDecode(
        user.access_token
      );

      const tenantsResponse = await getTenants(email);
      const selectedTenant = tenantsResponse.data.data
        .filter((i) => i.name === tenant)
        .map(
          ({
            fqdn,
            tenantLogo: logo,
            name,
            tenantBackgroundImage: background,
            tenantFavicon: favicon,
            title: title,
          }) => ({
            url: `https://${fqdn}`,
            alias: name,
            logo,
            background,
            favicon,
            title,
          })
        )[0];
      dispatch(actions.tenantSelected(selectedTenant));
      navigate({ search: '' }, { replace: true });
      dispatch(configActions.originUrlChanged(undefined));
      isLoading = false;
      removeOidcItemsFromLocalStorage();
    }
  };

  return { redirectCallback };
};

export default useOpenIdManager;
