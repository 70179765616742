import { editorTypes } from 'app/router/constants';
import { selectors } from 'app/store/editor';
import {
  dropableRegionBlock,
  rootBlock,
  staticBlock,
} from 'app/utils/createCraftBlocks';
import sortBy from 'lodash/sortBy';
import uniqueId from 'lodash/uniqueId';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  ArticleShownAs,
  Block,
  TextBlockV1,
  TextBlockV1TypeEnum,
  TextVariantItem,
} from 'submodules/common-ui/generated/api/gcs';

import {
  getSerializedCoverImageBlock,
  getSerializedTitleBlock,
  getBlockNodes,
  ExtendedSerializedNode,
  getSerializedCategoriesBlock,
} from '../helpers/apiToCraft';
import { ACTION_BLOCKS } from '../helpers/blocks';
import BlockTypes from '../helpers/constants';

type UserBlocksObj = {
  [k: string]: ExtendedSerializedNode;
};

const useArticleBlocks = (mainLanguage: string, filters?: string[]) => {
  const selectedLanguage = useSelector(selectors.getActiveLanguage);
  const article = useSelector(selectors.getArticle);
  const shownAs = useSelector(selectors.getShownAs);
  const { mode } = useParams<{ mode: string }>();

  const isBlockTypeText = (block?: Block): block is TextBlockV1 => {
    return block?.type === TextBlockV1TypeEnum.Text;
  };

  const isBlockDisplayNameTextBlock = (
    block?: ExtendedSerializedNode
  ): boolean => {
    return block?.displayName === BlockTypes.TextBlock;
  };

  const isHeadingVariant = useCallback((variant?: TextVariantItem): boolean => {
    return variant?.type === 'heading' && variant.level === 1;
  }, []);

  const getHeadingVariant = useCallback(
    (key: string): TextVariantItem | undefined => {
      const textBlock = article?.blocks.find((block) => {
        if (isBlockTypeText(block)) {
          return block.id.toString() === key;
        }
      });

      if (isBlockTypeText(textBlock)) {
        return textBlock?.variants[selectedLanguage]?.items?.[0];
      }
    },
    [article?.blocks, selectedLanguage]
  );

  const extractSectionsKeys = useCallback(
    (
      userBlocksObj: UserBlocksObj,
      userBlocks: Map<string, ExtendedSerializedNode>
    ): string[][] => {
      const sections: string[][] = [];
      let currentSection: string[] = [];

      for (const [key] of userBlocks) {
        const block = userBlocksObj[key];

        if (isBlockDisplayNameTextBlock(block)) {
          const headingVariant = getHeadingVariant(key);

          if (isHeadingVariant(headingVariant)) {
            if (currentSection.length > 0) sections.push(currentSection);
            currentSection = [key];
            continue;
          }
        }

        currentSection.push(key);
      }

      if (currentSection.length > 0) sections.push(currentSection);

      return sections;
    },
    [getHeadingVariant, isHeadingVariant]
  );

  const getBlockTitle = useCallback(
    (blockId: string): string | undefined => {
      const headingVariant = getHeadingVariant(blockId);

      if (
        headingVariant &&
        isHeadingVariant(headingVariant) &&
        headingVariant.children?.[0].type === 'text'
      ) {
        return headingVariant.children?.[0].value;
      }

      return undefined;
    },
    [getHeadingVariant, isHeadingVariant]
  );

  const createSections = useCallback(
    (userBlocksObj: UserBlocksObj, sections: string[][]) => {
      let currentPosition = 0;

      sections.forEach((section, index) => {
        let isPreviousSectionCompleted = true;
        const headingSectionId = 'headingSection' + index;
        const headingSectionContainerId = uniqueId('headingSectionContainer');

        const filteredSection = section.filter((key) =>
          Object.values(BlockTypes).includes(userBlocksObj[key].displayName)
        );

        filteredSection.forEach((key) => {
          userBlocksObj[key].parent = headingSectionContainerId;
        });

        for (let prevIndex = 0; prevIndex < index; prevIndex++) {
          sections[prevIndex].forEach((key) => {
            const block = article?.blocks.find(
              (node) => node.id.toString() === key
            );
            if (block && 'completed' in block && !block.completed) {
              isPreviousSectionCompleted = false;
            }
          });
        }

        const props = {
          isSectionBlocked: !isPreviousSectionCompleted,
          title: getBlockTitle(section[0]),
        };

        userBlocksObj[headingSectionContainerId] = {
          parent: headingSectionId,
          id: headingSectionContainerId,
          position: currentPosition++,
          displayName: 'headingSectionContainer',
          hidden: false,
          isCanvas: true,
          nodes: filteredSection,
          linkedNodes: {},
          props: {},
          type: { resolvedName: 'HeadingSectionContainer' },
        };

        userBlocksObj[headingSectionId] = {
          parent: 'dropableRegion',
          id: headingSectionId,
          position: currentPosition++,
          type: { resolvedName: BlockTypes.HeadingSectionBlock },
          displayName: BlockTypes.HeadingSectionBlock,
          hidden: false,
          isCanvas: false,
          nodes: [],
          linkedNodes: {
            headingSectionContainer: headingSectionContainerId,
          },
          props,
        };
      });

      return userBlocksObj;
    },
    [article?.blocks, getBlockTitle]
  );

  const filterBlocks = useCallback(
    (userBlocksObj: UserBlocksObj) => {
      if (!userBlocksObj) return undefined;

      const filterCondition = (block: ExtendedSerializedNode) => {
        if (shownAs.value === ArticleShownAs.Training) {
          return (
            (block.displayName === 'HeadingSectionBlock' ||
              block.parent === 'dropableRegion') &&
            (!filters ? true : filters.includes(block.displayName))
          );
        } else {
          return (
            ![
              'QuizOption',
              'YesNoNaOption',
              'optionsContainer',
              'PollOption',
              'pollOptionsContainer',
              'headingSectionContainer',
            ].includes(block.displayName) &&
            (!filters ? true : filters.includes(block.displayName))
          );
        }
      };

      return Object.values(userBlocksObj).filter(filterCondition);
    },
    [shownAs, filters]
  );

  const sortByPosition = useCallback((blocks: ExtendedSerializedNode[]) => {
    if (!blocks) return undefined;

    return sortBy(blocks, 'position').map((o) => o.id);
  }, []);

  const getFilteredNodes = useCallback(
    (userBlocksObj: UserBlocksObj) => {
      const filteredBlocks = filterBlocks(userBlocksObj);

      if (!filteredBlocks) return undefined;

      return sortByPosition(filteredBlocks);
    },
    [filterBlocks, sortByPosition]
  );

  const blocks = useMemo(() => {
    const userBlocks = new Map<string, ExtendedSerializedNode>();

    article?.blocks.forEach((block) => {
      const blockNodes = getBlockNodes(
        block,
        selectedLanguage,
        !block.variants[selectedLanguage] ? mainLanguage : undefined
      );

      for (const [key, value] of Object.entries(blockNodes)) {
        userBlocks.set(key, value);
      }
    });

    const userBlocksObj = userBlocks ? Object.fromEntries(userBlocks) : {};

    if (
      shownAs.value === ArticleShownAs.Training &&
      mode !== editorTypes.edit
    ) {
      const sections: string[][] = extractSectionsKeys(
        userBlocksObj,
        userBlocks
      );

      createSections(userBlocksObj, sections);
    }

    const nodes = getFilteredNodes(userBlocksObj);

    const articleMainLanguage =
      article?.languages.filter((l) => l.isDefault)[0].language || 'en';

    const mainVariant = article?.variants[selectedLanguage]
      ? undefined
      : article?.variants[mainLanguage]
        ? mainLanguage
        : articleMainLanguage;

    if (
      shownAs.value === ArticleShownAs.Training &&
      mode === editorTypes.actions
    ) {
      const sortedBlocks = sortBy(Object.values(userBlocksObj), 'position');

      sortedBlocks
        .filter((block) => ACTION_BLOCKS.includes(block.displayName))
        .forEach((block, index, array) => {
          const prevBlock = array[index - 1];

          const isLocked = prevBlock
            ? !prevBlock.props.completed && !block.props.completed
            : false;

          block.props.isLocked = isLocked;
        });
    }

    return {
      ROOT: rootBlock(),
      staticBlock: staticBlock(
        !filters ? ['coverImage', 'title', 'categories'] : []
      ),
      ...getSerializedCoverImageBlock(selectedLanguage, mainVariant, article),
      ...getSerializedTitleBlock(selectedLanguage, mainVariant, article),
      ...getSerializedCategoriesBlock(article),
      dropableRegion: dropableRegionBlock(nodes, {
        className: 'min-h-30 pb-4 px-2',
      }),
      ...userBlocksObj,
    };
  }, [
    article,
    createSections,
    extractSectionsKeys,
    filters,
    getFilteredNodes,
    mainLanguage,
    mode,
    selectedLanguage,
    shownAs,
  ]);

  return { blocks, article };
};

export default useArticleBlocks;
