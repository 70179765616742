/* eslint-disable sonarjs/no-nested-template-literals */
import ChannelBreadcrumb from 'app/components/Breadcrumbs/ChannelBreadcrumb';
import { FilterLocationID } from 'app/store/kpis/types';

import { missionReportTypes, visualGuideTypes, editorTypes } from './constants';

interface Route {
  value: string;
  create: (...args: any) => string;
}

interface KeyToRouteMap {
  [key: string]: Route;
}

const idOrEmptyString = (id?: number | string) =>
  ['number', 'string'].includes(typeof id) ? id : '';

const createRoutes = <Routes extends KeyToRouteMap>(routes: Routes) => routes;

const routes = createRoutes({
  login: {
    value: '/',
    create: () => '/',
  },
  home: {
    value: '/home',
    create: () => '/home',
  },
  articleStudio: {
    value: '/article-studio',
    create: () => '/article-studio',
  },
  categoriesStudio: {
    value: '/categories-studio',
    create: () => '/categories-studio',
  },
  channels: {
    value: '/channels',
    create: () => '/channels',
  },
  relevantChannels: {
    value: '/relevant-channels',
    create: () => '/relevant-channels',
  },
  editor: {
    value: '/article-studio/editor',
    create: () => '/article-studio/editor',
  },
  editorArticle: {
    value: `/article-studio/editor/:id/:mode`,
    create: (articleId: string, mode: keyof typeof editorTypes) =>
      `/article-studio/editor/${articleId}/${mode}`,
  },
  audiences: {
    value: '/audiences',
    create: () => '/audiences',
  },
  audience: {
    value: '/audiences/:id',
    create: (audienceId: number) => `/audiences/${audienceId}`,
  },
  dashboards: {
    value: '/dashboards',
    create: () => `/dashboards`,
  },
  newsFeed: {
    value: `/news/:category`,
    create: (category = 'relevant') => `/news/${category}`,
  },
  newsArticle: {
    value: `/news/:category/:id`,
    create: (category = 'relevant', id: number) => `/news/${category}/${id}`,
  },
  wiki: {
    value: '/wiki',
    create: () => '/wiki',
  },
  privacyPolicy: {
    value: '/privacy-policy',
    create: () => '/privacy-policy',
  },
  resetPassword: {
    value: '/reset-password',
    create: (email: string) => `/reset-password?email=${email}`,
  },
  wikiArticle: {
    value: '/wiki/:id',
    create: (id: number) => `/wiki/${id}`,
  },
  adminDashboard: {
    value: '/admin',
    create: () => '/admin',
  },
  profile: {
    value: `/profile/:category`,
    create: (category = '') => `/profile/${category}`,
  },
  settingsList: {
    value: `/platform-settings`,
    create: () => `/platform-settings`,
  },
  glossary: {
    value: `/glossary`,
    create: () => `/glossary`,
  },
  linksList: {
    value: `/platform-settings/:subdomain/links`,
    create: (subdomain: string) => `/platform-settings/${subdomain}/links`,
  },
  user: {
    value: '/user/:id',
    create: (id: number) => `/user/${id}`,
  },
  missions: {
    value: '/missions',
    create: () => '/missions',
  },
  missionsList: {
    value: `/missions/:category`,
    create: (category: string) => `/missions/${category}`,
  },
  mission: {
    value: '/missions/mission/:id',
    create: (id: number) => `/missions/mission/${id}`,
  },
  missionReport: {
    value: `/missions/:id/report/:category/:teamId?`,
    create: (
      id: number,
      category: string = missionReportTypes.teams,
      teamId?: number
    ) => `/missions/${id}/report/${category}/${idOrEmptyString(teamId)}`,
  },
  missionActivity: {
    value: '/missions/:missionId/activity/:id',
    create: (missionId: number, activityId: number) =>
      `/missions/${missionId}/activity/${activityId}`,
  },
  socialFeed: {
    value: '/feed/:postId?',
    create: (postId?: number) => `/feed/${idOrEmptyString(postId)}`,
  },
  visualGuides: {
    value: '/guides',
    create: () => '/guides',
  },
  visualGuide: {
    value: `/guides/:id/:type/:userId?`,
    create: (
      id: number,
      type: string = visualGuideTypes.guide,
      userId?: number
    ) => `/guides/${id}/${type}/${idOrEmptyString(userId)}`,
  },
  kpis: {
    value: '/kpis',
    create: (location?: FilterLocationID) =>
      `/kpis${location ? `?location=${location}` : ''}`,
  },
  kpi: {
    value: '/kpis/:handle',
    create: (handle: string, location?: FilterLocationID) =>
      `/kpis/${location ? `${handle}?location=${location}` : handle}`,
  },
  kpiRankings: {
    value: '/kpis/:handle/rankings',
    create: (handle: string) => `/kpis/${handle}/rankings`,
  },
  notifications: {
    value: '/notifications',
    create: () => '/notifications',
  },
  chat: {
    value: '/chat/:id?',
    create: (id?: string) => `/chat/${idOrEmptyString(id)}`,
  },
  nikeSkuRedirect: {
    value: '/nike-sku/:redirectKey?/:tenant?',
    create: () => '/nike-sku',
  },
  atticusRedirect: {
    value: '/atticus',
    create: () => '/atticus',
  },
  relevantChannel: {
    value: '/relevant-channel/:id',
    create: (id: number) => `/relevant-channel/${id}`,
    breadcrumb: ChannelBreadcrumb,
  },
  relevantChannelPost: {
    value: '/relevant-channel/:id/:postId',
    create: (id: number, postId: number) => `/relevant-channel/${id}/${postId}`,
    breadcrumb: ChannelBreadcrumb,
  },
  upcomingActions: {
    value: '/upcoming-actions',
    create: () => '/upcoming-actions',
  },
  members: {
    value: '/members',
    create: () => '/members',
  },
});

export default routes;
