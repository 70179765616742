import { cx } from '@emotion/css';
import IntersectionInfiniteScroll from 'app/components/IntersectionInfiniteScroll';

import { useAnswersContext } from '../context';

import AnountOfAnswers from './AnountOfAnswers';
import SimpleTaskDeepDive from './SimpleTaskDeepDive';
import EmptyFilterSkeleton from './Skeletons/EmptyFilterSkeleton';
import ErrorSkeleton from './Skeletons/ErrorSkeleton';
import LoadingSkeleton from './Skeletons/LoadingSkeleton';

const OpenQuestionAnswersDeepDive = () => {
  const {
    layout,
    actionAnswers,
    actionAnswersMeta,
    isLoadingActionAnswers,
    isLoadingUserIds,
    actionAnswersError,
    isFetchingNextPage,
    fetchNextPage,
  } = useAnswersContext();

  const isLoading = isLoadingActionAnswers || isLoadingUserIds;

  const layoutStyle = cx({
    'flex flex-col gap-2 relative': layout === 'list',
    'grid relative gap-2 grid-cols-2 [&>*]:col-span-1 [&>*:last-child:nth-child(odd)]:col-span-2':
      layout === 'grid',
  });

  const currentPage = actionAnswersMeta?.currentPage || 0;
  const lastPage = actionAnswersMeta?.lastPage || 0;

  const numberOfSkeletons = layout === 'grid' ? 6 : 4;
  return (
    <>
      {isLoading || actionAnswersError ? (
        <div className="h-4 w-[122px] bg-hover-blue rounded-xl" />
      ) : actionAnswers?.length > 0 ? (
        <AnountOfAnswers />
      ) : (
        <div className={layoutStyle}>
          <EmptyFilterSkeleton skeletonContentCount={0} />
        </div>
      )}

      <IntersectionInfiniteScroll
        hasMore={currentPage < lastPage}
        next={fetchNextPage}
        isLoading={isFetchingNextPage}
      >
        <div className={layoutStyle}>
          {isLoading &&
            [...Array(numberOfSkeletons)].map((_, i) => (
              <LoadingSkeleton key={i} skeletonContentCount={0} />
            ))}

          {!isLoading &&
            !actionAnswersError &&
            actionAnswers?.map(({ id, completed, userId, createdAt }) => (
              <SimpleTaskDeepDive
                key={id}
                completed={completed as boolean}
                userId={userId}
                createdAt={createdAt}
              />
            ))}
        </div>
      </IntersectionInfiniteScroll>

      {actionAnswersError && (
        <div className={layoutStyle}>
          <ErrorSkeleton skeletonContentCount={0} />
        </div>
      )}
    </>
  );
};

export default OpenQuestionAnswersDeepDive;
