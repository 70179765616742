import { RefObject, useCallback, useLayoutEffect } from 'react';

const useOpenVertically = (ref: RefObject<HTMLDivElement>, open: boolean) => {
  const openUpOrDown = useCallback(() => {
    if (!ref || !ref.current) return;

    if (
      window.innerHeight -
        (ref.current.getBoundingClientRect().bottom -
          ref.current.scrollHeight) <
      ref.current.scrollHeight
    ) {
      ref.current.classList.replace('top-6', 'bottom-4');
      return;
    }

    ref.current.classList.replace('bottom-4', 'top-6');
  }, [ref]);

  useLayoutEffect(() => {
    if (!open) return;
    openUpOrDown();
  }, [open, openUpOrDown]);
};

export default useOpenVertically;
