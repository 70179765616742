import { LoginMethods } from 'app/api/auth/constants';
import { useAuthenticatedUser } from 'app/api/auth/hooks';
import useRefreshTokenMutation from 'app/api/auth/hooks/useRefreshTokenMutation';
import createOpenIdManager from 'app/api/createOpenIdManager';
import { PageLoader } from 'app/components';
import config from 'app/config';
import { useDispatch, useSelector, useUrlQuery } from 'app/hooks';
import useLoginMethod from 'app/hooks/useLoginMethod';
import useOpenIdManager from 'app/hooks/useOpenIdManager';
import {
  actions as authActions,
  selectors as authSelectors,
} from 'app/store/auth';
import {
  selectors as configSelectors,
  actions as configActions,
} from 'app/store/config';
import { resetState as resetReduxState } from 'app/store/utils';
import dayjs from 'app/utils/dayjs';
import { isMobileByUserAgent } from 'app/utils/isMobile';
import { jwtDecode } from 'jwt-decode';
import { ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface Props {
  children: ReactNode;
}

const isExpired = (expiresAt: number) => dayjs().isAfter(dayjs(expiresAt));

const getExpiresInTime = (expiresAt: number) =>
  dayjs(expiresAt)
    .subtract(config.env.silentRefreshTokenTimeInSeconds, 'seconds')
    .diff(dayjs());

let isSigning = false;

// eslint-disable-next-line sonarjs/cognitive-complexity
const AuthTokenHandler = ({ children }: Props) => {
  const hasCodeParam = useUrlQuery('code');
  const hasLogoutParam = useUrlQuery('logout');
  const hasFirstNameParam = useUrlQuery('firstName');
  const hasLastNameParam = useUrlQuery('lastName');
  const hasEmailParam = useUrlQuery('email');
  const authToken = useSelector(authSelectors.getToken);
  const authTokenExpiresAt = useSelector(authSelectors.getExpiry);
  const refreshToken = useSelector(authSelectors.getRefreshToken);
  const isTokenRefreshing = useSelector(configSelectors.isTokenRefreshing);
  const isLoggingOut = useSelector(configSelectors.isLoggingOut);
  const [isSilentRefresh, setIsSilentRefresh] = useState(false);
  const loggedInWithEasyAccessToken = useSelector(
    configSelectors.getIsEasyAccessToken
  );
  const dispatch = useDispatch();
  const [isEasyAccessLoginMethod, setIsEasyAccessLoginMethod] = useState(false);
  const location = useLocation();

  const { data: user } = useAuthenticatedUser();

  const { mutate: refetchToken } = useRefreshTokenMutation();

  const { isEasyAccessLogin } = useLoginMethod();

  useOpenIdManager().redirectCallback();

  const isEasyAccessPath = () =>
    !!(
      location.pathname.match('/relevant-channel/(\\d+)') ||
      location.pathname.match('/relevant-channels') ||
      location.pathname.match('/upcoming-actions') ||
      location.pathname.match('/home') ||
      location.pathname.match('/article-studio/editor/(\\d+)/actions?/?(\\w+)')
    );

  const currentUrl = window.location.href;

  useEffect(() => {
    if (
      !authToken ||
      !isEasyAccessPath() ||
      loggedInWithEasyAccessToken ||
      hasCodeParam ||
      !isEasyAccessLogin ||
      isMobileByUserAgent()
    ) {
      return;
    }

    isSigning = true;

    dispatch(authActions.tokenRetrieved(''));
    dispatch(authActions.idTokenRetrieved(''));
    dispatch(authActions.refreshTokenRetrieved(''));

    if (hasFirstNameParam && hasLastNameParam && hasEmailParam) {
      createOpenIdManager(currentUrl, undefined, 'login').signinRedirect();
      return;
    }

    createOpenIdManager(
      currentUrl,
      undefined,
      'select_account'
    ).signinRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInWithEasyAccessToken]);

  useEffect(() => {
    if (!authTokenExpiresAt || !refreshToken || isSilentRefresh) return;

    const timeout = setTimeout(() => {
      if (!isTokenRefreshing) {
        setIsSilentRefresh(true);
        refetchToken().then(() => {
          setIsSilentRefresh(false);
        });
      }
    }, getExpiresInTime(authTokenExpiresAt));

    return () => {
      clearTimeout(timeout);
    };
  }, [
    authTokenExpiresAt,
    refetchToken,
    refreshToken,
    isSilentRefresh,
    isTokenRefreshing,
  ]);

  useEffect(() => {
    if (!authToken) return;
    const { login_method }: { login_method: LoginMethods } =
      jwtDecode(authToken);
    if (login_method === LoginMethods.EasyAccess) {
      setIsEasyAccessLoginMethod(true);
    }
  }, [authToken]);

  if (
    (!hasLogoutParam || !isLoggingOut) &&
    !hasCodeParam &&
    !refreshToken &&
    authTokenExpiresAt &&
    isExpired(authTokenExpiresAt)
  ) {
    isSigning = true;
    createOpenIdManager(currentUrl)
      .signinRedirect()
      .then(() => (isSigning = false));
    return <PageLoader />;
  }

  if (
    refreshToken &&
    authToken &&
    typeof authTokenExpiresAt === 'number' &&
    isExpired(authTokenExpiresAt)
  ) {
    if (!isTokenRefreshing) {
      dispatch(configActions.isTokenRefreshingRetrieved(true));
      refetchToken();
    }

    return <PageLoader />;
  }

  if (!authToken) {
    if (
      (!hasLogoutParam || !isLoggingOut) &&
      !hasCodeParam &&
      !isTokenRefreshing &&
      !refreshToken &&
      !isSigning
    ) {
      isSigning = true;

      dispatch(configActions.originUrlChanged(window.location.href));
      createOpenIdManager(currentUrl)
        .signinRedirect()
        .then(() => (isSigning = false));
    }
    return <PageLoader />;
  }

  if (!loggedInWithEasyAccessToken && isEasyAccessLoginMethod) {
    return <PageLoader />;
  }

  if (isEasyAccessLogin && !isEasyAccessPath()) {
    resetReduxState();
    createOpenIdManager(currentUrl, undefined, 'login').signinRedirect();
    return <PageLoader />;
  }

  if (isTokenRefreshing || !user) {
    return <PageLoader />;
  }

  return <>{children}</>;
};

export default AuthTokenHandler;
