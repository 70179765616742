import last from 'lodash/last';
import { useInfiniteQuery } from 'react-query';
import { NonNegativeNumber } from 'submodules/common-ui/utils/nonNegativeNumber';

import { queryKeys, resources } from '../index';
import { GetCategoriesRequest } from '../types';

const useGetCategoriesQuery = (params: GetCategoriesRequest) => {
  const {
    data,
    isLoading,
    error,
    isPreviousData,
    refetch,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    queryKeys.categories(params),
    ({ pageParam }) =>
      resources.getCategories({
        ...params,
        page: NonNegativeNumber.create(pageParam || params.page).value,
      }),
    {
      refetchOnMount: false,
      keepPreviousData: true,
      refetchOnReconnect: false,
      getNextPageParam: (lastPage) => lastPage.data.meta.currentPage + 1,
    }
  );

  return {
    data: data?.pages.flatMap((x) => x.data.data) || [],
    meta: last(data?.pages)?.data.meta,
    isLoading,
    fetchNextPage,
    isPreviousData,
    refetch,
    error,
    hasNextPage,
  };
};

export default useGetCategoriesQuery;
