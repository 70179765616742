interface RadioButtonProps {
  checked: boolean;
  comment?: string;
  label: string;
  onClick(): void;
}

const RadioButton = ({
  checked,
  comment,
  label,
  onClick,
}: RadioButtonProps) => (
  <button
    type="button"
    className="flex items-center text-left"
    onClick={onClick}
  >
    <span className="h-6 min-w-6 w-6 mr-4 p-0.5 block rounded-full border border-grayscale-primary">
      {checked && (
        <span className="block w-full h-full rounded-full bg-focus"></span>
      )}
    </span>
    <span>
      {label}
      {comment && (
        <span className="text-xs text-grayscale-secondary">{comment}</span>
      )}
    </span>
  </button>
);

export default RadioButton;
