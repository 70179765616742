import styled from '@emotion/styled';

import CommentBottomMenu from './CommentBottomMenu';
import CommentHeader from './CommentHeader';
import CommentsList from './CommentsList';

const Comments = () => {
  return (
    <>
      <CommentHeader />
      <CommentsContainer className="flex-auto pt-5 overflow-y-auto">
        <CommentsList />
      </CommentsContainer>
      <CommentBottomMenu />
    </>
  );
};

const CommentsContainer = styled.div`
  & {
    scrollbar-width: thin;
  }

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export default Comments;
