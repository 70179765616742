import { request as apiRequest } from 'app/api';
import env from 'app/config/env';
import {
  HttpRequest,
  HttpRequestType,
  Player,
  PlayerAPI,
  PlayerConfig,
} from 'bitmovin-player';
import 'bitmovin-player/bitmovinplayer-ui.css';
import { UIFactory } from 'bitmovin-player-ui';
import { FC, useEffect, useRef, useState } from 'react';

export type BitmovinPlayerConfig = {
  dash?: string;
  hls?: string;
  progressive: string;
  poster?: string;
};
interface BitmovinPlayerProps {
  src?: string;
  isInternal?: boolean;
  defaultPlayerConfig?: BitmovinPlayerConfig;
  onError?: () => void;
  onLoadedData?: () => void;
  onClick?: (e: Event) => void;
}

const BitmovinPlayer: FC<BitmovinPlayerProps> = ({
  src,
  onError,
  onLoadedData,
  isInternal,
  defaultPlayerConfig,
}) => {
  const [player, setPlayer] = useState<PlayerAPI | null>(null);
  const [isBootingPlayer, setIsBootingPlayer] = useState<boolean>(false);

  const [configSource, setConfigSource] = useState<BitmovinPlayerConfig | null>(
    null
  );

  const playerConfig: PlayerConfig = {
    key: env.bitmovinKey,
    network: {
      preprocessHttpRequest: (type: HttpRequestType, request: HttpRequest) => {
        if (!isInternal) {
          return Promise.resolve(request);
        }

        const initialUrl = new URL(request.url);
        // Remove client(i.e. gcs, chat ) from path
        const pathParam = initialUrl.pathname.split('/').slice(2).join('/');

        return apiRequest()
          .get(`${src}?path=${pathParam}&response=url`)
          .then((response) => {
            request.url = response.data.url;
            return request;
          })
          .catch((e) => Promise.reject(e));
      },
    },
  };

  const playerDiv = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (defaultPlayerConfig) {
      return setConfigSource(defaultPlayerConfig);
    }
    if (!src) return;
    const getUrl = async () => {
      const response = await apiRequest().get(src + '?response=url');

      const { encodings, url: videoUrl } = response.data;

      const source = {
        dash: encodings?.mpd,
        hls: encodings?.m3u8,
        progressive: videoUrl,
        poster: encodings?.thumbnail,
      };
      setConfigSource(source);
    };
    getUrl();
  }, [defaultPlayerConfig, src]);

  useEffect(() => {
    if (!configSource || player) return;

    const setupPlayer = () => {
      if (!playerDiv.current || !configSource || player || isBootingPlayer)
        return;

      setIsBootingPlayer(true);

      const playerInstance = new Player(playerDiv.current, playerConfig);
      UIFactory.buildModernSmallScreenUI(playerInstance);
      playerInstance.load(configSource).then(
        () => {
          setPlayer(playerInstance);
          if (onLoadedData) onLoadedData();
        },
        () => onError
      );
    };
    setupPlayer();

    return () => {
      function destroyPlayer() {
        if (player != null) {
          player.destroy();
          setPlayer(null);
        }
      }

      destroyPlayer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configSource]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div id="player" ref={playerDiv} onClick={(e) => e.stopPropagation()} />
  );
};

export default BitmovinPlayer;
