import { cx } from '@emotion/css';
import useGetAudiencesQuery from 'app/api/audiences/hooks/useAudiencesQuery';
import { PageLoader, Switch, VerticalChevron } from 'app/components';
import config from 'app/config';
import { useDispatch, useSelector } from 'app/hooks';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { actions, selectors } from 'app/store/editor';
import { Audience } from 'app/store/editor/types';
import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import CheckLineIcon from 'remixicon-react/CheckLineIcon';

import AudienceDetails from '../AudienceDetails';
import SelectedOptions from '../SelectedOptions';

interface Props {
  goToStep: (step: number) => void;
  updateBlockAudiences: (audiencesId: number[]) => void;
}

const SecondStepForm: FC<Props> = ({ goToStep, updateBlockAudiences }) => {
  const { t } = useArticlesTranslation();
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const dispatch = useDispatch();
  const [dropdownSelectedAudiences, setDropdownSelectedAudiences] = useState<
    Audience[]
  >([]);
  const [selectedAudiences, setSelectedAudiences] = useState<Audience[]>([]);
  const selected = useSelector(selectors.getSelectedAudiences);
  const allowComments = useSelector(selectors.getAllowComments);

  const { data, isLoading: isLoadingAudiences } = useGetAudiencesQuery({
    page: 1,
    query: searchQuery,
    enabled: searchQuery !== '',
  });

  useEffect(() => {
    if (selected.length > 0) {
      setSelectedAudiences(selected);
    }
  }, [selected]);

  const audiences = useMemo(() => data ?? [], [data]);

  const suggestions = useMemo(() => {
    const unselectedAudiences = audiences.filter(
      (audience) => !selectedAudiences.some((prev) => prev.id === audience.id)
    );

    if (searchQuery === '') {
      return unselectedAudiences;
    }

    return unselectedAudiences.filter((audience) =>
      audience.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [audiences, searchQuery, selectedAudiences]);

  const handleSelectAudience = useCallback(
    (audience: Audience) => {
      setSelectedAudiences((prev) => [...prev, audience]);
      updateBlockAudiences([...selectedAudiences, audience].map((a) => a.id));
      setOpen(false);
    },
    [selectedAudiences, updateBlockAudiences]
  );

  const handleNextClick = useCallback(() => {
    dispatch(actions.updateAllAudiences(selectedAudiences));
    goToStep(2);
  }, [dispatch, goToStep, selectedAudiences]);

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(e.target.value);
    },
    [setSearchQuery]
  );

  const handleOutsideClick = useCallback(() => {
    setOpen(false);
    setDropdownSelectedAudiences([]);
  }, [setOpen, setDropdownSelectedAudiences]);

  const handleBackClick = useCallback(() => {
    if (selected.length > 0) {
      goToStep(2);
    } else {
      goToStep(0);
    }
  }, [goToStep, selected]);

  const isAudienceSelected = useCallback(
    (audienceId: number) => {
      return dropdownSelectedAudiences.some((prev) => prev.id === audienceId);
    },
    [dropdownSelectedAudiences]
  );

  const handleRemoveAudience = useCallback(
    (audienceId: number) => {
      setSelectedAudiences((prev) =>
        prev.filter((prevAudience) => prevAudience.id !== audienceId)
      );

      updateBlockAudiences(
        selectedAudiences
          .filter((prevAudience) => prevAudience.id !== audienceId)
          .map((audience) => audience.id)
      );
    },
    [selectedAudiences, updateBlockAudiences]
  );

  return (
    <div className="flex flex-col gap-4 items-center">
      <div className="relative w-full">
        <OutsideClickHandler onOutsideClick={handleOutsideClick}>
          <div
            role="presentation"
            onClick={() => setOpen(!open)}
            className="flex h-[46px] items-center gap-2 border rounded p-2 focus-within:border-gray-dark border-gray-dark"
          >
            <input
              name="audience"
              placeholder={t('Add audience') ?? 'Add audience'}
              value={searchQuery}
              onChange={handleSearchChange}
              autoComplete="off"
              className="w-full outline-none bg-transparent focus:outline-none text-grayscale-primary placeholder-grayscale-secondary::placeholder"
            />
            <div className="ml-auto mr-3">
              <VerticalChevron open={open} className="w-4 h-4" />
            </div>
          </div>
          {open && (
            <div className="absolute top-13 left-0 w-full bg-white border border-gray-light rounded shadow-md z-50">
              {isLoadingAudiences && (
                <div className="h-full">
                  <PageLoader />
                </div>
              )}

              <div
                className={cx(
                  'h-full overflow-auto max-h-[256px]',
                  selectedAudiences.length > 0 && 'max-h-[192px]'
                )}
              >
                {suggestions.length > 0 &&
                  suggestions.map((audience) => (
                    <button
                      type="button"
                      className={cx(
                        'w-full flex p-2 text-center items-center gap-2 cursor-pointer hover:bg-grayscale-secondary hover:bg-opacity-10 h-16',
                        {
                          'bg-grayscale-secondary bg-opacity-10':
                            isAudienceSelected(audience.id),
                        }
                      )}
                      key={audience.id}
                      onClick={() => {
                        handleSelectAudience({
                          id: audience.id,
                          name: audience.name,
                          members: audience.usersCount ?? 0,
                          isVisible: true,
                        });
                      }}
                    >
                      <AudienceDetails
                        id={audience.id}
                        name={audience.name}
                        userCount={audience.usersCount ?? 0}
                      />
                      {isAudienceSelected(audience.id) && (
                        <div className="ml-auto mr-3">
                          <CheckLineIcon className="text-focus" />
                        </div>
                      )}
                    </button>
                  ))}

                {(!audiences || suggestions.length === 0) &&
                  !isLoadingAudiences && (
                    <div className="flex p-2 items-center gap-2 cursor-pointer hover:bg-grayscale-secondary hover:bg-opacity-10 h-16">
                      <span className="text-sm text-grayscale-primary">
                        {t('No audiences found')}
                      </span>
                    </div>
                  )}
              </div>
            </div>
          )}
        </OutsideClickHandler>
      </div>

      {selectedAudiences.length > 0 && (
        <SelectedOptions
          audiences={selectedAudiences}
          handleRemoveAudience={handleRemoveAudience}
          showMore={null}
        />
      )}

      <div className="w-full">
        <span className="block font-bold text-grayscale-primary mb-2">
          {t('Allow comments')}
        </span>
        <label
          className="w-fit flex items-center gap-3 cursor-pointer"
          htmlFor="allow-comments"
        >
          <Switch
            id="allow-comments"
            onColor={config.colors.success}
            offColor={config.colors['gray-dark']}
            handleDiameter={21}
            height={25}
            width={40}
            checked={allowComments}
            onChange={(checked) => dispatch(actions.allowComments(checked))}
          />
          <span className="text-sm text-grayscale-secondary">
            {t('Allow audience to comment on article')}
          </span>
        </label>
      </div>

      <div className="flex w-full justify-center gap-2">
        <button
          type="button"
          className="w-56 h-12 rounded-xl bg-hover-blue text-hover-primary"
          onClick={handleBackClick}
        >
          {t('Back')}
        </button>
        <button
          type="button"
          className={cx(
            'ml-4 w-56 h-12   rounded-xl',
            selectedAudiences.length <= 0
              ? 'bg-gray-light cursor-not-allowed text-grayscale-secondary'
              : 'text-white bg-focus'
          )}
          disabled={selectedAudiences.length <= 0}
          onClick={handleNextClick}
        >
          {selected.length > 0 ? t('Update') : t('Next')}
        </button>
      </div>
    </div>
  );
};

export default SecondStepForm;
