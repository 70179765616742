import useAuthenticatedUser from 'app/api/auth/hooks/useAuthenticatedUser';
import useUpcomingActions from 'app/api/channels/hooks/useUpcomingActions';
import { UpcomingActionsFilter } from 'app/api/channels/types';
import { useMemo } from 'react';

import { UpcomingActionsRequiredFilter, requireFilterMap } from '../types';

const useNoDeadlineUpcomingActions = (
  required: UpcomingActionsRequiredFilter
) => {
  const { data: user } = useAuthenticatedUser();
  const filter = useMemo<UpcomingActionsFilter>(
    () => ({
      noDeadline: true,
      required: requireFilterMap[required],
      timezone: user?.timezone,
    }),
    [required, user?.timezone]
  );

  return useUpcomingActions(filter);
};

export default useNoDeadlineUpcomingActions;
