import Spinner from 'assets/icons/spinner.svg?react';
import { useEffect, useRef } from 'react';

declare type Fn = () => any;

interface Props {
  next: Fn;
  hasMore: boolean;
  isLoading: boolean;
  children: React.ReactNode;
}

const IntersectionInfiniteScroll = ({
  next,
  children,
  isLoading,
  hasMore,
}: Props) => {
  const loaderRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const target = entries[0];
      if (target.isIntersecting && hasMore) {
        next();
      }
    });

    const loader = loaderRef.current;

    if (loader) {
      observer.observe(loader);
    }

    return () => {
      if (loader) {
        observer.unobserve(loader);
      }
    };
  }, [next, hasMore]);

  return (
    <div className="w-full">
      <div>{children}</div>
      <div className="flex items-center justify-center py-2" ref={loaderRef}>
        {isLoading && (
          <Spinner className="w-6 h-6 animate-spin text-grayscale-secondary" />
        )}
      </div>
    </div>
  );
};

export default IntersectionInfiniteScroll;
