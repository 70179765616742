import { cx } from '@emotion/css';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import useOpenVertically from 'app/pages/ArticleEditor/hooks/useOpenVertically';
import { selectors } from 'app/store/editor';
import { Eye, EyeSlash, InfoCircle, Warning2 } from 'iconsax-react';
import { useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import CheckLineIcon from 'remixicon-react/CheckLineIcon';

interface PrivacyProps {
  isPublic?: boolean;
  disabled?: boolean;
  onClick: (isPublic: boolean) => void;
}

const Privacy = ({ isPublic, disabled, onClick }: PrivacyProps) => {
  const { t } = useArticlesTranslation();
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const article = useSelector(selectors.getArticle);
  const answerSharing = useSelector(selectors.getAnswerSharing);

  const isSharingEnabled = answerSharing ?? article?.answerSharing ?? false;
  const showSharingWarning = isSharingEnabled && article?.originalTenant;

  useOpenVertically(dropdownRef, open);

  const options = [
    {
      publicVisibility: true,
      text: (
        <Trans
          components={{ bold: <span className="font-bold" /> }}
          i18nKey="articles.Public to audience members"
        />
      ),
      Icon: Eye,
      click: () => onClick(true),
    },
    {
      publicVisibility: false,
      text: (
        <Trans
          components={{ bold: <span className="font-bold" /> }}
          i18nKey="articles.Private to article editors"
        />
      ),
      Icon: EyeSlash,
      trailing: true,
      click: () => onClick(false),
    },
  ];

  useEffect(() => {
    if (open) ReactTooltip.rebuild();
  }, [open]);

  const toggleDropdown = () => {
    if (disabled) return;
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
      <div className="relative">
        {isPublic || isPublic === undefined ? (
          <Eye
            size={20}
            className={cx({
              'text-gray-dark': open,
              'hover:text-grayscale-secondary cursor-pointer': !disabled,
            })}
            onClick={toggleDropdown}
          />
        ) : (
          <EyeSlash
            size={20}
            className={cx({
              'text-gray-dark': open,
              'hover:text-grayscale-secondary cursor-pointer': !disabled,
            })}
            onClick={toggleDropdown}
          />
        )}
        {open && (
          <div
            ref={dropdownRef}
            className="absolute bg-white rounded-lg z-50 shadow-lg pt-1 border border-gray-light bg-clip-padding left-0 top-7 transform -translate-y-2 shadow-atobi overflow-hidden"
          >
            <div className="flex items-center p-3">
              <span className="text-xs text-grayscale-primary font-bold">
                {t('Answer Visibility')}
              </span>
              <a
                target="_blank"
                rel="noroferrer noreferrer"
                href="https://help.atobi.io"
              >
                <InfoCircle
                  size={16}
                  className="ml-2 text-gray-dark"
                  data-tip={t('Help Center')}
                  data-for="privacyHelp"
                />
              </a>
            </div>
            {options.map((option, index) => {
              const { publicVisibility, text, Icon, trailing, click } = option;
              return (
                <button
                  key={index}
                  className={cx(
                    'text-xs w-full p-3 font-normal flex items-center whitespace-nowrap bg-transparent hover:bg-hover-blue text-grayscale-primary',
                    { 'mb-1.5': index < options.length - 1 }
                  )}
                  onClick={() => click()}
                >
                  <Icon size={16} className="text-grayscale-secondary mr-2" />
                  <span className="mr-2">{text}</span>
                  {trailing && showSharingWarning && (
                    <Warning2
                      size={12}
                      className="text-warning"
                      data-for="insights_info"
                      data-tip={t(
                        'Please note that answers to these actions will be shared with {{tenantName}}, and marking this action as “Private” will only have effect within your organization.',
                        { tenantName: article?.originalTenant?.name }
                      )}
                    />
                  )}
                  <CheckLineIcon
                    size={16}
                    className={cx('text-focus ml-auto', {
                      invisible: publicVisibility !== isPublic,
                    })}
                  />
                </button>
              );
            })}
          </div>
        )}
      </div>
      {showSharingWarning && (
        <ReactTooltip
          effect="solid"
          class="react-tooltip whitespace-normal w-[300px]"
          id="insights_info"
          place="bottom"
        />
      )}
      <ReactTooltip
        place="top"
        effect="solid"
        class="react-tooltip"
        id="privacyHelp"
      />
    </OutsideClickHandler>
  );
};

export default Privacy;
