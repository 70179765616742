import config from 'app/config';
import { selectors } from 'app/store/auth';
import Message, { CHANNEL_NAME, MessageKeys } from 'app/swTypes';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

const WebPushHelperHandler = () => {
  const token = useSelector(selectors.getToken);
  const expiry = useSelector(selectors.getExpiry) || 0;

  const [hasPushCapability, setHasPushCapability] = useState(false);
  const [pageWasClicked, setPageWasClicked] = useState(false);
  const [pageWasScrolled, setPageWasScrolled] = useState(false);

  const canAskForPush = useMemo(
    () =>
      hasPushCapability &&
      pageWasClicked &&
      pageWasScrolled &&
      Notification.permission === 'default',
    [hasPushCapability, pageWasClicked, pageWasScrolled]
  );

  useEffect(() => {
    if (!('PushManager' in window)) return;

    setHasPushCapability(true);
  }, []);

  useEffect(() => {
    const onPageClicked = () => setPageWasClicked(true);
    const onPageScrolled = () => setPageWasScrolled(true);

    document.addEventListener('click', onPageClicked);
    document.addEventListener('scroll', onPageScrolled, true);

    return () => {
      document.removeEventListener('click', onPageClicked);
      document.removeEventListener('scroll', onPageScrolled, true);
    };
  }, []);

  useEffect(() => {
    if (!canAskForPush || !token) return;

    new Promise((resolve, _) => {
      const permissionResult = Notification.requestPermission((result) => {
        resolve(result);
      });

      permissionResult.then((permission) => {
        if (permission === 'granted') {
          navigator.serviceWorker.controller?.postMessage({
            key: MessageKeys.pushPermissionGranted,
            value: {
              token,
              expiry,
              url: config.env.pushNotificationsApiUrl,
              vapidKey: config.env.vapidKey,
            },
          } satisfies Message);
        }
      });
    });
  }, [expiry, token, canAskForPush]);

  useEffect(() => {
    if (!token) return;

    const bc = new BroadcastChannel(CHANNEL_NAME);

    bc.onmessage = (message: MessageEvent<Message>) => {
      if (message.data.key === MessageKeys.resubscribe) {
        navigator.serviceWorker.controller?.postMessage({
          key: MessageKeys.resubscribe,
          value: {
            token,
            expiry,
            url: config.env.pushNotificationsApiUrl,
            vapidKey: config.env.vapidKey,
          },
        } satisfies Message);
      }
    };

    return () => bc.close();
  }, [expiry, token]);

  return <></>;
};

export default WebPushHelperHandler;
