const CHANNEL_NAME = 'ServiceWorker';

const MessageKeys = {
  unsubscribe: 'unsubscribe',
  resubscribe: 'resubscribe',
  tokenReady: 'tokenReady',
  pushPermissionGranted: 'pushPermissionGranted',
} as const;

interface Params {
  token: string;
  expiry?: number;
  url: string;
  vapidKey: string;
}

interface Message {
  key: keyof typeof MessageKeys;
  value: Params;
}

export default Message;
export { MessageKeys, CHANNEL_NAME };
