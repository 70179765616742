import config from 'app/config';
import axios from 'axios';

import { CheckTokensReponse } from '../types';

const checkTokens = (authToken: string, chatToken: string) => () => {
  return axios.get<CheckTokensReponse>(`/checktoken`, {
    baseURL: config.env.identityServerUri,
    headers: {
      authorization: `Bearer ${authToken}`,
      'acs-token': chatToken,
      'X-Requested-With': 'XMLHttpRequest',
    },
    withCredentials: true,
  });
};

export default checkTokens;
