import useGetActionInstance from 'app/api/articles/hooks/useGetActionInstance';
import useGetArticleBlock from 'app/api/articles/hooks/useGetArticleBlock';
import useAuthenticatedUser from 'app/api/auth/hooks/useAuthenticatedUser';
import { InfiniteScroll, ScrollbarContainer } from 'app/components';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { selectors } from 'app/store/editor';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import {
  ActionAnswerResponse,
  ActionInstance,
  GetUserAnswer,
} from 'submodules/common-ui/generated/api/gcs';

import ActionInstancesItem from './ActionInstancesItem';

interface Props {
  actionId: string;
  title?: string;
}

const ActionInstances = ({ actionId, title }: Props) => {
  const { t } = useArticlesTranslation();

  const { fetchNextPage, hasNextPage } = useGetActionInstance({
    actionId: parseInt(actionId),
  });
  const article = useSelector(selectors.getArticle);
  const { data: user } = useAuthenticatedUser();

  const { block } = useGetArticleBlock({
    articleId: article?.id as number,
    blockId: parseInt(actionId),
    enabled: true,
    timezone: user?.timezone,
    live: '1',
  });

  const selectedTaskBlockSchedule = block?.schedule;
  const selectedTaskBlockInstances = block?.instances;
  const currentUserAnswers = block?.currentUserAnswers;
  const currentInstanceId = block?.currentInstanceId;

  if (selectedTaskBlockSchedule?.frequency === 'once') {
    return null;
  }

  return (
    <div className="flex flex-col pb-4 px-6">
      <div className="bg-white rounded p-6">
        <div className="mb-2">
          <span className="text-lg">{t('History log.')}</span>
        </div>
        <ScrollbarContainer className="max-h-[300px]">
          <InfiniteScroll
            dataLength={selectedTaskBlockInstances?.length || 0}
            hasMore={!!hasNextPage}
            next={fetchNextPage}
            loader={<></>}
          >
            {selectedTaskBlockInstances?.map((instance: ActionInstance) => {
              const isCompletedByUser = currentUserAnswers?.some(
                (answer: ActionAnswerResponse) =>
                  answer.actionInstanceId === instance.id &&
                  answer.revertedAt === null
              );

              const latestColleagueAnswer = block?.answers
                ?.filter(
                  (answer: GetUserAnswer) =>
                    answer.actionInstanceId === instance.id &&
                    answer.completedBy !== user?.id &&
                    answer.revertedAt === null
                )
                ?.sort((a: GetUserAnswer, b: GetUserAnswer) =>
                  dayjs(b.createdAt).diff(dayjs(a.createdAt))
                )[0];

              return (
                <ActionInstancesItem
                  key={instance.id}
                  instance={instance}
                  completed={isCompletedByUser || !!latestColleagueAnswer}
                  isCompletedByUser={isCompletedByUser || false}
                  title={title}
                  currentInstanceId={currentInstanceId}
                  latestColleagueAnswer={latestColleagueAnswer}
                  userAvatarSrc={user?.avatars?.small || ''}
                />
              );
            })}
          </InfiniteScroll>
        </ScrollbarContainer>
      </div>
    </div>
  );
};

export default ActionInstances;
