import { ChatKey } from '../types';

import useBaseTranslation from './useBaseTranslation';

const useChatTranslation = () =>
  useBaseTranslation<ChatKey>(undefined, {
    keyPrefix: 'chat',
  });

export default useChatTranslation;
