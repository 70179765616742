import { cx } from '@emotion/css';
import { Icon as IconSax } from 'iconsax-react';
import React, { ButtonHTMLAttributes } from 'react';
import { RemixiconReactIconComponentType } from 'remixicon-react';

const variantClassNames = {
  primary: 'bg-focus text-white',
  secondary: 'bg-hover-blue text-focus',
};

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  variant?: keyof typeof variantClassNames;
  icon?: RemixiconReactIconComponentType | IconSax;
  width?: number;
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  loading,
  type,
  children,
  variant,
  className,
  width,
  icon: Icon,
  disabled,
  ...restProps
}) => (
  <button
    disabled={loading || disabled}
    className={cx(
      'rounded-xl flex items-center justify-center text-sm',
      // Buttons with icon have smaller y padding
      Icon ? 'py-2' : 'py-3',
      variant && variantClassNames[variant],
      className,
      width ? `w-[${width}px]` : 'w-full',
      {
        'opacity-75': loading,
        'hover:bg-hover-primary':
          variant === 'primary' && !loading && !disabled,
        'hover:bg-focus-background':
          variant === 'secondary' && !loading && !disabled,
      }
    )}
    onClick={onClick}
    type={type}
    {...restProps}
  >
    {children}
    {Icon && (
      <div className="w-8 h-8 relative flex justify-center items-center ml-4">
        <div className="absolute top-0 left-0 w-8 h-8 rounded bg-white z-10 opacity-20" />
        <Icon className="w-6 h-6" />
      </div>
    )}
  </button>
);

Button.defaultProps = {
  type: 'button',
};

export default Button;
