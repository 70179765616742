import useCommentTranslation from 'app/internationalization/hooks/useCommentTranslation';

const CommentDisableView = () => {
  const { t } = useCommentTranslation();

  return (
    <div className="w-full px-6 flex pb-5">
      <div className="h-[32px] w-[32px] bg-hover-blue rounded mr-2" />
      <div className="min-h-[86px] w-full bg-hover-blue rounded-tl-sm rounded-xl flex flex-col px-3 pt-3">
        <span className="pb-2 font-bold">{t('Comments')}</span>
        <span>{t('Comments are turned off')}</span>
      </div>
    </div>
  );
};

export default CommentDisableView;
