import i18n from 'app/internationalization';
import { isValidURL } from 'app/utils';
import { z } from 'zod';

const MIN_LENGTH = 5;

const articlePostSchema = z.object({
  content: z.string().min(MIN_LENGTH),
  uploads: z.array(z.unknown()),
  urls: z.array(
    z.object({
      url: z.string().refine((url) => isValidURL(url), {
        message: `${i18n.t('formErrors.Invalid URL')}`,
      }),
      name: z.string().min(MIN_LENGTH, {
        message: `${i18n.t(
          'formErrors.Name must contain at least {{minLength}} characters',
          { minLength: MIN_LENGTH }
        )}`,
      }),
    })
  ),
});

export default articlePostSchema;
