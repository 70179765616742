import { useEditor, useNode } from '@craftjs/core';
import { PageLoader, ResponsiveImage } from 'app/components';
import ActionToast from 'app/components/Toast/ActionToast';
import ToastContainer from 'app/components/Toast/ToastContainer';
import useFileDirectUrls from 'app/hooks/useFileDirectUrls';
import useFileUpload from 'app/hooks/useFileUpload';
import useIsEditorEnabled from 'app/hooks/useIsEditorEnabled';
import MediaViewer from 'app/pages/Editor/components/Answers/DeepDive/MediaViewer';
import ImageEditor from 'app/pages/Editor/components/ImageEditor';
import BlockTypes from 'app/pages/Editor/helpers/constants';
import { selectors as editorSelectors } from 'app/store/editor';
import { logger } from 'app/utils';
import { BrushSquare, GalleryAdd, Refresh } from 'iconsax-react';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  ExternalFile,
  InternalFileGet,
} from 'submodules/common-ui/generated/api/gcs/api';

import MAX_FILE_SIZE from '../../constants';
import BaseBlockContainer from '../BaseBlockContainer';
import MediaBlockError from '../MediaBlockError';
import MediaBlockPlaceholder from '../MediaBlockPlaceholder';
import MediaBlockOptionSettings, {
  AddedFile,
  UploadedFile,
} from '../MediaBlockSettings/MediaBlockOptionSettings';
import { SettingsState } from '../MediaBlockSettings/types';

interface ImageBlockProps {
  imageSchema?: InternalFileGet | ExternalFile;
  file?: File | string;
  error?: string;
  isUploading?: boolean;
  translationStatus: string;
}

const showToast = (text: string) =>
  toast(<ActionToast text={text} onClick={toast.dismiss} />, {
    position: 'bottom-center',
    autoClose: 8000,
    closeButton: false,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    containerId: 'Image',
  });

const ImageBlock = ({ imageSchema, file }: ImageBlockProps) => {
  const { t } = useTranslation();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [imageFileSrc, setImageFileSrc] = useState<string | null>(null);
  const [hasError, setHasError] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [showImageEditor, setShowImageEditor] = useState(false);
  const [editorImageSrc, setEditorImageSrc] = useState<string | null>(null);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);

  const canEdit = useSelector(editorSelectors.getCanEdit);

  const { enabled } = useIsEditorEnabled();
  const { directUrls } = useFileDirectUrls(imageSchema);
  const { upload, isLoading } = useFileUpload();

  const {
    actions: {
      selectNode,
      history: { ignore },
    },
  } = useEditor();

  const {
    connectors: { connect },
    selected,
    nodeId,
    actions: { setProp },
  } = useNode((node) => ({
    selected: node.events.selected,
    nodeId: node.id,
  }));

  useEffect(() => {
    ignore().setCustom(nodeId, (props: ImageBlockProps) => {
      props.isUploading = isLoading;
    });
  }, [isLoading, ignore, nodeId]);

  useEffect(() => {
    if (!file) return;

    if (typeof file === 'string') {
      setImageFileSrc(file);
      return;
    }

    const src = URL.createObjectURL(file);

    setImageFileSrc(src);
    setHasError(false);

    return () => URL.revokeObjectURL(src);
  }, [file]);

  useEffect(() => {
    if (imageSchema?.url && !file) {
      setHasError(false);
      setImageFileSrc(imageSchema.url);
    }
  }, [imageSchema, file]);

  const fileId = imageSchema?.type === 'internal' ? imageSchema.id : '';

  const onProcess = (img: File) => {
    selectNode(undefined);
    setShowImageEditor(false);
    if (img) {
      setImageFileSrc(URL.createObjectURL(img));
      uploadImage(img);
    }
  };

  const onImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const image = e?.target.files?.[0];
    if (!image) return logger.debug('MediaPicker onFileChange file is null');

    if (image.size > MAX_FILE_SIZE) {
      setProp((props: ImageBlockProps) => {
        props.file = undefined;
        props.imageSchema = undefined;
        props.error = `${t('Your file is too big. The limit is {{size}} MB', {
          size: 500,
        })}`;
      });
      return;
    }

    uploadImage(image);
  };

  const uploadImage = (image: File) => {
    upload(image, {
      onSuccess: (response) => {
        setProp((props: ImageBlockProps) => {
          props.error = '';
          props.file = image;
          props.imageSchema = {
            type: 'internal',
            id: response.id,
            url: '',
            name: image.name,
            translationStatus: 'draft',
          } as InternalFileGet;
        });
      },
      onError: logger.error,
    });
  };

  const openImageEditor = async () => {
    if (file) {
      setEditorImageSrc(imageFileSrc);
      setShowImageEditor(true);
      return;
    }

    if (!directUrls?.original) {
      showToast(t('Error. Failed to load file'));
      return;
    }

    setEditorImageSrc(directUrls?.original.url);
    setShowImageEditor(true);
  };

  const triggerInputClick = () => {
    if (!fileInputRef.current) return;

    fileInputRef.current.click();
  };

  const options = [
    {
      disabled: !imageFileSrc || !canEdit,
      Icon: BrushSquare,
      text: t('Edit'),
      onClick: imageFileSrc ? openImageEditor : undefined,
    },
    {
      Icon: Refresh,
      text: t('Replace'),
      onClick: triggerInputClick,
      disabled: !canEdit,
    },
  ];

  return (
    <div ref={(ref) => ref && connect(ref)} className="mx-2">
      {showImageEditor && editorImageSrc && (
        <ImageEditor
          image={editorImageSrc}
          onProcess={onProcess}
          onClose={() => setShowImageEditor(false)}
          onError={(err) => {
            showToast(err.error.message);
            setShowImageEditor(false);
          }}
        />
      )}
      <BaseBlockContainer
        deleteOnBackspace={enabled}
        nodeId={nodeId}
        options={options}
        selected={selected}
        type="Image"
      >
        {!imageFileSrc && !hasError && (
          <MediaBlockPlaceholder
            isLoading={isLoading}
            type="Image"
            TypeIcon={GalleryAdd}
            onChange={onImageChange}
          />
        )}
        {imageFileSrc && !hasLoaded && !hasError && <PageLoader />}
        {imageFileSrc && !hasError && (
          <ResponsiveImage
            alt={imageSchema?.name ?? 'img'}
            className="w-full rounded"
            directUrls={directUrls}
            external={imageSchema?.type === 'external'}
            src={imageFileSrc}
            onDoubleClick={() => triggerInputClick()}
            onError={() => setHasError(true)}
            onLoad={() => {
              setHasLoaded(true);
              setHasError(false);
            }}
            {...(!enabled && { onClick: () => setPreviewModalOpen(true) })}
          />
        )}
        {hasError && (
          <MediaBlockError onDoubleClick={triggerInputClick} fileId={fileId} />
        )}
        <input
          type="file"
          ref={fileInputRef}
          className="hidden"
          onChange={onImageChange}
          disabled={!enabled}
        />
      </BaseBlockContainer>

      {imageFileSrc && previewModalOpen && (
        <MediaViewer
          showUserInfo={false}
          closeModal={() => {
            setPreviewModalOpen(false);
          }}
          {...(fileId && { imgIds: [fileId] })}
          {...(!fileId && { externalSrc: imageFileSrc })}
        />
      )}
      <ToastContainer toastId="Image" />
    </div>
  );
};

const ImageSettings = () => {
  const { t } = useTranslation();
  const {
    actions: {
      history: { ignore },
    },
  } = useEditor();

  const {
    selectedProps,
    actions: { setProp },
    nodeId,
  } = useNode((node) => ({
    selectedProps: node.data.props,
    nodeId: node.id,
  }));

  const state: SettingsState = {
    id: selectedProps?.imageSchema?.id,
    url: selectedProps?.imageSchema?.url,
    name: selectedProps?.imageSchema?.name,
  };

  const getPropPayload = (
    data: string | UploadedFile | AddedFile
  ): {
    file: File | undefined;
    imageSchema: InternalFileGet | ExternalFile;
  } => {
    if (typeof data === 'string') {
      return {
        file: undefined,
        imageSchema: {
          type: 'external',
          name: '',
          url: data,
          translationStatus: 'draft',
        },
      };
    }

    if (data.type === 'uploaded') {
      return {
        file: data.data,
        imageSchema: {
          type: 'internal',
          id: data.id,
          url: '',
          name: data.data.name,
          translationStatus: 'draft',
        } as InternalFileGet,
      };
    }

    return {
      file: data.data,
      imageSchema: {
        type: 'internal',
        id: '',
        url: '',
        name: data.data.name,
        translationStatus: 'draft',
      } as InternalFileGet,
    };
  };

  return (
    <MediaBlockOptionSettings
      state={state}
      inputLabel={t('Image')}
      onChange={(data) => {
        ignore().setCustom(nodeId, (props: ImageBlockProps) => {
          if (typeof data !== 'string' && data.type === 'error') {
            props.isUploading = false;
            return;
          }

          props.isUploading = typeof data !== 'string' && data.type === 'added';
        });

        setProp((props: ImageBlockProps) => {
          if (typeof data !== 'string' && data.type === 'error') {
            props.file = undefined;
            props.imageSchema = undefined;
            props.error = data.error;
            return;
          }

          const { file, imageSchema } = getPropPayload(data);
          props.file = file;
          props.imageSchema = imageSchema;
          props.error = '';
        });
      }}
    />
  );
};

ImageBlock.craft = {
  displayName: BlockTypes.ImageBlock,
  related: {
    settings: ImageSettings,
  },
};

export { ImageBlock };
export default ImageBlock;
