import { Trans } from 'react-i18next';

const AtobiSupport = () => (
  <a href="mailto:help@atobi.io">
    <Trans
      components={{ u: <u className="text-focus" /> }}
      i18nKey="common.Contact Atobi Support"
    />
  </a>
);

export default AtobiSupport;
