import { request } from 'app/api/gcsRequest';
import config from 'app/config';
import {
  DeleteReactionByEmojiParams,
  ReactionApi,
} from 'submodules/common-ui/generated/api/gcs';

const deleteReaction = (params: DeleteReactionByEmojiParams) =>
  new ReactionApi(
    undefined,
    config.env.gcsApiUrl,
    request()
  ).deleteReactionByEmoji(params);

export default deleteReaction;
