import useGetActionAnswer from 'app/api/articles/hooks/useGetActionAnswer';
import { IntersectionInfiniteScroll } from 'app/components';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { createPortal } from 'react-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import { DbOpenQuestionTaskAnswer } from 'submodules/common-ui/generated/api/gcs';

import { useAnswersContext } from '../Answers/context';
import OpenQuestionDeepDive from '../Answers/DeepDive/OpenQuestionDeepDive';
import LoadingSkeleton from '../Answers/DeepDive/Skeletons/LoadingSkeleton';

import SummaryCategory from './SummaryCategory';
import { Category } from './types';

interface CategoryPreviewProps {
  onClose: () => void;
  category: Category;
}

const CategoryPreview = ({ onClose, category }: CategoryPreviewProps) => {
  const { t } = useArticlesTranslation();
  const { selectedTaskId } = useAnswersContext();

  const {
    actionAnswers,
    actionAnswersMeta,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
  } = useGetActionAnswer({
    id: selectedTaskId ?? 0,
    answerIds: category.answers,
  });

  const currentPage = actionAnswersMeta?.currentPage || 0;
  const lastPage = actionAnswersMeta?.lastPage || 0;

  return createPortal(
    <div className="absolute z-999 inset-y-0 right-0 w-[536px] bg-white shadow-[0_20px_24px_-4px_rgba(16,24,40,0.18)] h-screen overflow-y-scroll">
      <OutsideClickHandler onOutsideClick={onClose}>
        <div className="fixed bg-white w-full px-6 z-50">
          <div className="py-5">
            <button type="button" onClick={() => onClose()}>
              <CloseLineIcon />
            </button>
          </div>
        </div>
        <div className="pt-18 px-6">
          <SummaryCategory
            category={category}
            className="bg-gray-white-shadow !rounded-xl"
            containerClassName="!rounded-xl bg-gradient-to-b from-[#B549D0] to-[#7C48D0] p-px outline outline-gray-white"
          />
          <div className="text-gray-light-blue text-xs py-3">
            {t('{{totalAnswers}} answers', {
              totalAnswers: category.answers.length,
            })}
            :
          </div>
          <IntersectionInfiniteScroll
            hasMore={currentPage < lastPage}
            next={fetchNextPage}
            isLoading={isFetchingNextPage}
          >
            <div className="flex flex-col gap-2 relative">
              {isLoading &&
                [...Array(4)].map((_, i) => (
                  <LoadingSkeleton
                    key={i}
                    contentHeightList="40px"
                    contentHeightGrid="40px"
                  />
                ))}
              {actionAnswers.map(({ id, answer, userId, createdAt }) => (
                <OpenQuestionDeepDive
                  key={id}
                  answer={answer as DbOpenQuestionTaskAnswer}
                  userId={userId}
                  createdAt={createdAt}
                />
              ))}
            </div>
          </IntersectionInfiniteScroll>
        </div>
      </OutsideClickHandler>
    </div>,
    document.body
  );
};

export default CategoryPreview;
