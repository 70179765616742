import { NonNegativeNumber } from 'common-ui/utils/nonNegativeNumber';
import last from 'lodash/last';
import { useInfiniteQuery } from 'react-query';
import {
  BasicChannelInfo,
  SortOrderEnum,
} from 'submodules/common-ui/generated/api/gcs';

import { resources, queryKeys } from '../index';

const useGetRelevantChannelsQuery = ({
  ranking,
  timeZone,
  enabled,
  refetchOnWindowFocus = true,
  refetchOnMount = false,
}: {
  ranking: SortOrderEnum;
  timeZone: string;
  enabled?: boolean;
  onSuccess?: (data: BasicChannelInfo[]) => void;
  refetchOnWindowFocus?: boolean;
  refetchOnMount?: boolean;
}) => {
  const {
    data,
    isLoading,
    error,
    isFetching,
    isPreviousData,
    isFetchingNextPage,
    hasNextPage,
    refetch,
    fetchNextPage,
  } = useInfiniteQuery(
    queryKeys.relevantChannels(),
    ({ pageParam }) =>
      resources.getRelevantChannels(
        NonNegativeNumber.create(pageParam || 0).value,
        ranking,
        timeZone
      ),
    {
      enabled,
      refetchOnMount,
      refetchOnWindowFocus,
      refetchOnReconnect: false,
      getNextPageParam: (lastPage) => lastPage.data.meta.currentPage + 1,
    }
  );
  return {
    data,
    meta: last(data?.pages)?.data.meta,
    isFetching,
    isLoading,
    isPreviousData,
    hasNextPage,
    isFetchingNextPage,
    error,
    refetch,
    fetchNextPage,
  };
};
export default useGetRelevantChannelsQuery;
