import { cx } from '@emotion/css';
import config from 'app/config';
import useDateTimeFormat from 'app/hooks/useDateTimeFormat';
import useUserQuery from 'app/pages/profiles/PublicProfile/useUserQuery';
import { selectors as authSelectors } from 'app/store/auth';
import { actions } from 'app/store/modal';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DbMediaTaskAnswer } from 'submodules/common-ui/generated/api/gcs';

import { useAnswersContext } from '../../context';
import MediaViewer from '../MediaViewer';
import UserDetails from '../UserDetails';

import ImageStack from './ImageStack';

interface Props {
  userId: number;
  images: DbMediaTaskAnswer;
  createdAt: string;
}

const MediaTaskDeepDive: FC<Props> = ({ userId, images, createdAt }) => {
  const dispatch = useDispatch();
  const [mediaViewerOpen, setMediaViewerOpen] = useState(false);
  const { layout } = useAnswersContext();

  const tenant = useSelector(authSelectors.getSelectedTenant);
  const { data: user, isLoading: isLoadingUser } = useUserQuery({ id: userId });

  const imageUrl = images[0];
  const totalImages = images.length;

  const createdAtTime = useDateTimeFormat({
    timestamp: createdAt,
    type: 'dayMonthYearTime',
  });

  const baseUrl = `${config.env.gcsApiUrl}/file/${tenant?.alias ?? ''}`;

  return (
    <div
      className={cx(
        'rounded-xl gap-4 bg-white shadow-atobi flex flex-col p-4 relative',
        {
          'bg-hover-blue': isLoadingUser,
          'bg-grayscale-bg-dark': !isLoadingUser,
        }
      )}
    >
      {totalImages > 1 && (
        <ImageStack
          baseUrl={baseUrl}
          images={images}
          onClick={() => {
            setMediaViewerOpen(true);
            dispatch(actions.modalOpened());
          }}
        />
      )}

      <UserDetails
        isLoadingUser={isLoadingUser}
        user={user}
        createdAtTime={createdAtTime}
        layout={layout}
      />

      <button
        onClick={() => {
          setMediaViewerOpen(true);
          dispatch(actions.modalOpened());
        }}
        className={cx('w-full rounded-[10px]', {
          'h-[185px]': layout === 'grid',
          'h-[300px]': layout === 'list',
        })}
      >
        <img
          className="w-full h-full rounded-[10px] object-cover"
          src={`${baseUrl}/${imageUrl}`}
          alt="media-task"
        />
      </button>

      {mediaViewerOpen && (
        <MediaViewer
          userInfo={{
            userName: `${user?.firstName} ${user?.lastName}`,
            userAvatar: user?.avatars?.small ?? '',
            locationName: user?.location?.name ?? '',
            createdAt: createdAtTime ?? '',
          }}
          closeModal={() => {
            dispatch(actions.modalClosed());
            setMediaViewerOpen(false);
          }}
          imgIds={images}
        />
      )}
    </div>
  );
};

export default MediaTaskDeepDive;
