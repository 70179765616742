import { cx } from '@emotion/css';
import { ScrollbarContainer } from 'app/components';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { selectors } from 'app/store/editor';
import { GlobalEdit } from 'iconsax-react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import LanguageDropdownButton from './LanguageDropdownButton';

interface LanguageDropdownProps {
  onLanguageSettingsClick?: VoidFunction;
  disabled: boolean;
  beforeLanguageChange?: (code: string) => void;
  selectedLanguageCode?: string;
}

const LanguageDropdown = ({
  disabled,
  selectedLanguageCode,
  onLanguageSettingsClick,
  beforeLanguageChange,
}: LanguageDropdownProps) => {
  const { mode } = useParams<{ mode: string }>();
  const { t } = useArticlesTranslation();

  const selectedLanguages = useSelector(selectors.getSelectedLanguages);
  const useTemplateView = useSelector(selectors.getUseTemplateView);

  const isReview = mode === 'review';

  return (
    <div
      className={cx(
        'w-[213px] flex flex-col bg-white rounded absolute top-8 text-sm text-grayscale-secondary whitespace-nowrap cursor-default transform translate-y-5 border border-gray-light',
        { 'right-0 !top-4': isReview }
      )}
    >
      <span className="font-bold text-xs text-grayscale-primary py-3 pl-2">
        {t('Active Languages')}
      </span>
      <ScrollbarContainer className="max-h-[260px]">
        {selectedLanguages.length > 0 &&
          selectedLanguages.map((language, idx) => (
            <LanguageDropdownButton
              key={`${language.code}${idx}`}
              language={language}
              beforeLanguageChange={beforeLanguageChange}
              selectedLanguageCode={selectedLanguageCode}
            />
          ))}
      </ScrollbarContainer>
      {!useTemplateView && !isReview && (
        <>
          {selectedLanguages.length > 0 && (
            <hr className="border border-gray-light" />
          )}
          <button
            onClick={onLanguageSettingsClick}
            className={cx('flex items-center justify-start py-3 pr-4', {
              'hover:bg-focus-background': !disabled,
              'text-grayscale-secondary bg-gray-light': disabled,
            })}
            disabled={disabled}
          >
            <GlobalEdit size={20} className="ml-2 mr-1" />
            {t('Language Settings')}
          </button>
        </>
      )}
    </div>
  );
};

export default LanguageDropdown;
