import { useMatches } from 'react-router-dom';

import Seperator from './Seperator';

type Handle = { crumb: (pathname: string) => JSX.Element };

const Breadcrumbs = () => {
  const matches = useMatches();

  const crumbs = matches
    // first get rid of any matches that don't have handle and crumb
    .filter((match) => Boolean((match.handle as Handle)?.crumb))
    // now map them into an array of elements, passing the loader
    // data to each one
    .map((match) => (match.handle as Handle)?.crumb(match.pathname));

  return (
    <div>
      <div className="flex">
        {crumbs.map((crumb, index) => (
          <span
            key={index}
            className="whitespace-nowrap flex text-sm lg:text-base"
          >
            {index !== 0 && <Seperator />}
            {crumb}
          </span>
        ))}
      </div>
    </div>
  );
};

export default Breadcrumbs;
