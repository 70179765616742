import { cx } from '@emotion/css';
import { Location } from 'app/api/auth/types';
import DropdownInput from 'app/components/DropdownInput';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import TickMarkIcon from 'assets/icons/tickmark.svg?react';
import { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

interface LocationDropdownProps {
  locations: Location[];
  onSelectedLocation: (location: Location | undefined) => void;
}

const LocationDropdown = ({
  locations,
  onSelectedLocation,
}: LocationDropdownProps) => {
  const [open, setOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<
    Location | undefined
  >();

  useEffect(() => {
    if (locations.length === 1) {
      setSelectedLocation(locations[0]);
      onSelectedLocation(locations[0]);
    }
  }, [locations, onSelectedLocation]);

  const isDisabledLocationSelection = locations.length <= 1;

  const { t } = useArticlesTranslation();

  return (
    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
      <div className="relative">
        <DropdownInput
          text={
            selectedLocation
              ? (selectedLocation.name ?? '')
              : t('Choose location')
          }
          open={open}
          disabled={isDisabledLocationSelection}
          onClick={() => setOpen((prev) => !prev)}
          className="bg-grayscale-bg-dark h-[52px]"
        />
        {open && !isDisabledLocationSelection && (
          <div className="absolute top-[59px] left-0 right-0 bg-white border-gray-light border rounded">
            <div className="h-full overflow-auto max-h-[256px]">
              {locations.map((location) => {
                return (
                  <button
                    className="flex items-center justify-between py-2 px-5 hover:bg-focus-background w-full group"
                    key={location.id}
                    onClick={() => {
                      setSelectedLocation(location);
                      onSelectedLocation(location);
                      setOpen(false);
                    }}
                  >
                    {location.name}
                    <span
                      className={cx('group-hover:visible  text-focus', {
                        invisible: selectedLocation?.id !== location.id,
                      })}
                    >
                      <TickMarkIcon />
                    </span>
                  </button>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default LocationDropdown;
