import { cx } from '@emotion/css';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { Cd, FlashCircle, TickCircle } from 'iconsax-react';
import ReactTooltip from 'react-tooltip';
import CheckLineIcon from 'remixicon-react/CheckLineIcon';
import { TranslationStatusEnum } from 'submodules/common-ui/generated/api/gcs';

interface TranslationStatusDropdownContainerProps {
  className?: string;
  status: TranslationStatusEnum;
  onSelect: (value: 'approved' | 'draft') => void;
}

const TranslationStatusDropdownContainer = ({
  className,
  status,
  onSelect,
}: TranslationStatusDropdownContainerProps) => {
  const { t } = useArticlesTranslation();

  const checkIcon = <CheckLineIcon size={20} className="text-focus ml-auto" />;

  return (
    <div
      className={cx(
        'dropdown flex-col w-[170px] bg-white rounded absolute z-30 pb-1 whitespace-nowrap cursor-default shadow-atobi top-8 right-4',
        className
      )}
      role="presentation"
      onClick={(e) => e.stopPropagation()}
    >
      <span className="flex text-xs text-grayscale-primary pt-3 pb-2 pl-3 pr-4 font-bold">
        {t('Proofread Status')}
        {status === 'auto' && (
          <>
            <FlashCircle
              size={16}
              variant="Bold"
              className="text-ceil ml-2"
              data-for="translation"
              data-tip={t('Autotranslated')}
            />
            <ReactTooltip
              place="top"
              effect="solid"
              class="react-tooltip"
              id="translation"
            />
          </>
        )}
      </span>
      <button
        className={cx(
          'flex items-center hover:bg-hover-blue pl-3 pr-4 py-2 text-sm w-full',
          { 'bg-focus-background': status === 'approved' }
        )}
        onClick={() => onSelect('approved')}
      >
        <TickCircle size={16} variant="Bold" className="text-success" />
        <span className="pl-3">{t('Proofread')}</span>
        {status === 'approved' && checkIcon}
      </button>
      <button
        className={cx(
          'flex items-center hover:bg-hover-blue pl-3 pr-4 py-2 text-sm w-full',
          { 'bg-focus-background': status === 'draft' }
        )}
        onClick={() => onSelect('draft')}
      >
        <Cd size={16} variant="Bold" className="text-warning" />
        <span className="pl-3">{t('Not proofread')}</span>
        {status === 'draft' && checkIcon}
      </button>
    </div>
  );
};

export default TranslationStatusDropdownContainer;
