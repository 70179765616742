import { useEditor } from '@craftjs/core';
import { css, cx } from '@emotion/css';
import Spinner from 'app/components/Spinner';
import config from 'app/config';
import { Add, Danger, TickCircle } from 'iconsax-react';
import { ChangeEventHandler } from 'react';

interface UploadTabProps {
  label: string;
  isLoading: boolean;
  state:
    | { type: 'success'; fileName: string }
    | { type: 'error' }
    | { type: 'untouched'; fileName?: string };
  onChange: ChangeEventHandler<HTMLInputElement>;
}

const UploadTab = ({ state, label, isLoading, onChange }: UploadTabProps) => {
  const { currentNodeId: nodeId, nodes } = useEditor((editorState) => {
    const [currentNodeId] = editorState.events.selected;

    return {
      currentNodeId,
      nodes: editorState.nodes,
    };
  });

  const FileLabel = (): JSX.Element => {
    if (
      (state.type === 'untouched' && !state.fileName) ||
      state.type === 'error' ||
      error
    )
      return (
        <span
          className={cx(
            'flex items-center',
            error ? 'text-error' : 'text-focus'
          )}
        >
          <Add className="mr-1" size={24} />
          {label}
        </span>
      );

    return <div className="truncate">{state.fileName}</div>;
  };

  const error = nodes?.[nodeId]?.data.props?.error;
  const uploading = nodes?.[nodeId]?.data.props?.uploading;

  return (
    <div className="w-full">
      <label htmlFor="fileInput" className="flex w-full mt-3 cursor-pointer">
        <div
          className={cx(
            'flex items-center p-2.5 gap-2.5 bg-hover-blue w-full h-12 text-grayscale-primary text-sm rounded-lg',
            {
              'justify-center':
                state.type !== 'success' && !isLoading && !uploading,
              'border-[1px] border-error': state.type === 'error' || !!error,
              'border-[1px] border-success': state.type === 'success',
            },
            css([
              state.type === 'error' && {
                outlineColor: `${config.colors['error']}`,
              },
              state.type === 'success' && {
                outlineColor: `${config.colors['success']}`,
              },
            ])
          )}
        >
          {!error && (
            <>
              {(isLoading || uploading) && (
                <div className="min-w-6 max-w-6 h-6 flex items-center pointer-events-none">
                  <Spinner />
                </div>
              )}

              {state.type === 'success' && (
                <div className="min-w-6 max-w-6 h-6 flex items-center pointer-events-none">
                  <TickCircle className="text-success" />
                </div>
              )}
            </>
          )}
          <FileLabel />
        </div>
        <input
          id="fileInput"
          className="hidden"
          type="file"
          onChange={onChange}
        />
      </label>
      {error && (
        <div className="flex flex-row items-center gap-2 mt-2">
          <Danger className="min-w-6" size={24} color={config.colors.error} />
          <span className="whitespace-normal text-sm text-left text-error">
            {error}
          </span>
        </div>
      )}
    </div>
  );
};

export default UploadTab;
