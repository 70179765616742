import { captureException } from '@sentry/react';
import { logger } from 'app/utils';
import { QueryClient, QueryCache } from 'react-query';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: async (error: unknown) => {
      captureException(error);
      logger.error(error);
    },
  }),
  defaultOptions: {
    queries: {
      retry: 1,
    },
  },
});

export default queryClient;
