import { cx } from '@emotion/css';
import { useDispatch, useSelector } from 'app/hooks';
import useAppSelector from 'app/hooks/useSelector';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { actions, selectors } from 'app/store/editor';
import { FC, useEffect, useState } from 'react';
import { Channel, GetCategory } from 'submodules/common-ui/generated/api/gcs';

import CategoriesDropdown from '../CategoriesDropdown';
import ChannelsDropdown from '../ChannelsDropdown';

interface Props {
  goToStep: (step: number) => void;
}

const FirstStepForm: FC<Props> = ({ goToStep }) => {
  const { t } = useArticlesTranslation();
  const [selectedChannel, setSelectedChannel] = useState<Channel>();
  const [selectedCategories, setSelectedCategories] = useState<GetCategory[]>(
    []
  );
  const articleChannel = useAppSelector(selectors.getSelectedChannel);
  const articleCategories = useAppSelector(selectors.getSelectedCategories);
  const dispatch = useDispatch();
  const selectedAudiences = useSelector(selectors.getSelectedAudiences);

  useEffect(() => {
    if (articleChannel) {
      setSelectedChannel(articleChannel as Channel);
    }
  }, [articleChannel]);

  useEffect(() => {
    if (articleCategories) {
      setSelectedCategories(articleCategories);
    }
  }, [articleCategories]);

  const handleSelectChannel = (channel: Channel) => setSelectedChannel(channel);

  const handleRemoveCategory = (categoryId: number) => {
    setSelectedCategories((prev) =>
      prev.filter((category) => category.id !== categoryId)
    );
  };

  const handleNextClick = () => {
    dispatch(actions.addChannel(selectedChannel as Channel));
    dispatch(actions.addCategories(selectedCategories));

    if (selectedAudiences.length > 0) {
      goToStep(2);
    } else {
      goToStep(1);
    }
  };

  return (
    <div className="flex flex-col gap-4 items-center">
      <ChannelsDropdown
        selectedChannel={selectedChannel}
        onChannelSelect={handleSelectChannel}
      />
      <CategoriesDropdown
        selectedCategories={selectedCategories}
        onApplyCategories={setSelectedCategories}
        onRemoveCategory={handleRemoveCategory}
      />
      <div className="flex w-full justify-center gap-2">
        {selectedAudiences.length > 0 && (
          <button
            type="button"
            className="w-56 h-12 rounded-xl bg-hover-blue text-hover-primary"
            onClick={() => goToStep(2)}
          >
            {t('Back')}
          </button>
        )}
        <button
          type="button"
          className={cx(
            'ml-4 w-56 h-12 rounded-xl',
            !selectedChannel
              ? 'bg-gray-light cursor-not-allowed text-grayscale-secondary'
              : 'text-white bg-focus'
          )}
          onClick={handleNextClick}
          disabled={!selectedChannel}
        >
          {selectedAudiences.length > 0 ? t('Update') : t('Next')}
        </button>
      </div>
    </div>
  );
};

export default FirstStepForm;
