import { ErrorsKey } from '../types';

import useBaseTranslation from './useBaseTranslation';

const useErrorTranslation = () =>
  useBaseTranslation<ErrorsKey>(undefined, {
    keyPrefix: 'errors',
  });

export default useErrorTranslation;
