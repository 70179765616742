import { request } from 'app/api/gcsRequest';
import config from 'app/config';
import { ArticleApi, NewArticle } from 'common-ui/generated/api/gcs';

const createArticle = ({ article }: { article: NewArticle }) =>
  new ArticleApi(undefined, config.env.gcsApiUrl, request())
    .createNewArticle(article)
    .then((response) => response.data);

export { createArticle };
export default createArticle;
