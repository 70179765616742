import ArticleCard from 'app/components/ArticleCard';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import useUIArticleLanguage from 'app/pages/Articles/hooks/useUIArticleLanguage';
import { ListArticle } from 'submodules/common-ui/generated/api/gcs';

import CoverImage from '../CoverImage';

const ArticleResults = ({
  articles,
  userLanguage,
}: {
  articles: ListArticle[];
  userLanguage: string;
}) => {
  const { t } = useArticlesTranslation();
  const { getMainLanguage } = useUIArticleLanguage();

  return (
    <>
      <span className="text-xs text-gray-silver font-bold">
        {t('Articles').toUpperCase()}
      </span>
      <div className="flex flex-col gap-5 mt-5">
        {articles.map((article) => {
          const language = getMainLanguage(article.languages);
          return (
            <ArticleCard
              article={article}
              userLanguage={userLanguage}
              key={article.id}
              CoverImage={
                <CoverImage
                  className="w-[86px] h-[56px]"
                  coverImage={article.variants?.[language]?.coverImage}
                />
              }
            />
          );
        })}
      </div>
    </>
  );
};

export default ArticleResults;
