import { useArticlesTranslation } from 'app/internationalization/hooks';
import { Danger } from 'iconsax-react';

import LoadingMediaTasks from './LoadingMediaTasks';

const ErrorLoadingMediaTasks = () => {
  const { t } = useArticlesTranslation();

  return (
    <>
      <LoadingMediaTasks />
      <LoadingMediaTasks />

      <div className="absolute m-auto left-0 right-0 bottom-0 top-0 h-7 w-fit bg-light rounded-[6px] py-1 px-2 text-error flex gap-1 justify-center items-center">
        <Danger className="w-4 h-4" />
        <span className="text-sm">
          {t('Unable to receive answers, please try again later.')}
        </span>
      </div>
    </>
  );
};

export default ErrorLoadingMediaTasks;
