import { cx } from '@emotion/css';
import DropdownInput from 'app/components/DropdownInput';
import { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import {
  RecurringArticleDropdownValue,
  RecurringArticleOption,
} from '../types';

interface Props {
  selectedOption?: RecurringArticleOption<RecurringArticleDropdownValue>;
  options: RecurringArticleOption<RecurringArticleDropdownValue>[];
  onChange: (
    option: RecurringArticleOption<RecurringArticleDropdownValue>
  ) => void;
}

const RecurringArticleDropdown = ({
  options,
  selectedOption,
  onChange,
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOptionClick = (
    option: RecurringArticleOption<RecurringArticleDropdownValue>
  ) => {
    onChange(option);
    setIsOpen(false);
  };

  return (
    <div className="w-full relative mb-4">
      <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
        <DropdownInput
          text={selectedOption?.label ?? ''}
          open={isOpen}
          onClick={() => setIsOpen((prev) => !prev)}
        />
        {isOpen && (
          <div className="border border-gray-light rounded shadow-atobi absolute max-h-[270px] w-full bg-white overflow-y-auto z-10">
            {options.map((option) => (
              <button
                key={option.value}
                onClick={() => handleOptionClick(option)}
                className={cx(
                  `w-full py-2 px-3 text-left text-sm hover:bg-white-shadow focus:outline-none`,
                  {
                    'bg-focus-background':
                      option.value === selectedOption?.value,
                  }
                )}
              >
                {option.label}
              </button>
            ))}
          </div>
        )}
      </OutsideClickHandler>
    </div>
  );
};

export default RecurringArticleDropdown;
