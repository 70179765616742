import { Modal } from 'app/components';
import { actions } from 'app/store/modal';
import { Icon } from 'iconsax-react';
import { useDispatch } from 'react-redux';

import SuccessBody from './SuccessBody';

export interface SuccessModalProps {
  title: string;
  description: string;
  onClose?: VoidFunction;
  onCancel?: VoidFunction;
  onSubmit?: VoidFunction;
  submitLabel?: string;
  cancelLabel?: string;
  headerClassName?: string
  options?: {
    Icon: Icon;
    text: string;
  }[];
}

const SuccessModal = (props: SuccessModalProps) => {
  const { onClose } = props;
  const dispatch = useDispatch();

  const close = () => {
    if (!onClose) return dispatch(actions.hideModal());
    onClose();
  };

  return (
    <Modal onClose={close} className="w-[380px]">
      <SuccessBody {...props} onClose={close} />
    </Modal>
  );
};

export default SuccessModal;
