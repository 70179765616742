import useCommentTranslation from 'app/internationalization/hooks/useCommentTranslation';

const NoComments = () => {
  const { t } = useCommentTranslation();
  return (
    <div className="flex flex-col justify-center items-center h-full">
      <span className="font-bold text-lg">{t('No comments yet.')}</span>
      <span className="text-grayscale-secondary text-sm">
        {t('Be the first one to start a disscussion')}
      </span>
    </div>
  );
};

export default NoComments;
