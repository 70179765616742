import { useQuery } from 'react-query';

import { queryKeys } from '../index';
import getArticleCollaborators from '../resources/getArticleCollaborators';

const useGetArticleCollaboratorsQuery = ({
  role,
  permissions,
  search,
  enabled = true,
  perPage = 100,
  refetchOnWindowFocus = true,
}: {
  role: string;
  permissions: string;
  search?: string;
  enabled?: boolean;
  perPage?: number;
  refetchOnWindowFocus?: boolean;
}) => {
  const { data, isLoading, error, isFetching, refetch } = useQuery(
    queryKeys.collaborators(),
    () => getArticleCollaborators(role, permissions, search, perPage),
    { enabled, refetchOnWindowFocus }
  );

  return {
    data: data?.data?.data,
    isFetching,
    isLoading,
    error,
    refetch,
  };
};

export default useGetArticleCollaboratorsQuery;
