import { useEditor } from '@craftjs/core';
import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import config from 'app/config';
import { useScreenBreakpoint } from 'app/hooks';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import TextBlock from 'app/pages/ArticleEditor/components/blocks/TextBlock';
import { isMobile } from 'app/utils';
import ChaptersIcon from 'assets/icons/chapters.svg?react';
import CrossIcon from 'assets/icons/cross.svg?react';
import sortBy from 'lodash/sortBy';
import { useCallback, useMemo } from 'react';
import { Heading, Text } from 'submodules/common-ui/generated/api/gcs';

import BlockTypes from '../helpers/constants';

const Contents = styled.div`
  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 9px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${config.colors['gray-light']};
    border-radius: 9px;
  }
`;

interface EditorToCProps {
  onHide: () => void;
}

const TableOfContentsEditor = ({ onHide }: EditorToCProps) => {
  const { t } = useArticlesTranslation();

  const {
    selectedNodeId,
    actions: { addNodeTree, selectNode },
    query: { parseReactElement },
    nodes,
  } = useEditor((state) => ({
    selectedNodeId: [...state.events.selected][0],
    nodes: state.nodes,
  }));

  const breakpoint = useScreenBreakpoint();
  const mobile = isMobile(breakpoint);

  const textNodes = useMemo(
    () =>
      sortBy(
        Object.values(nodes).filter(
          (node) =>
            node.data.displayName === BlockTypes.TextBlock &&
            node.data.props?.nodes?.items?.[0]?.type === 'heading'
        ),
        (n) => n.data.props?.position
      ),
    [nodes]
  );

  const addHeadingBlock = useCallback(() => {
    const block = parseReactElement(
      <TextBlock
        initialType={{
          tag: 'h1',
          type: 'heading',
        }}
        translationStatus="draft"
      />
    ).toNodeTree();

    addNodeTree(block, 'dropableRegion');
  }, [addNodeTree, parseReactElement]);

  const handleNodeClick = useCallback(
    (nodeId: string) => {
      const element = document.getElementById(nodeId);
      if (element) {
        selectNode(nodeId);
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    },
    [selectNode]
  );

  return (
    <div
      className={cx(
        'flex flex-col w-full md:w-[314px] h-[420px] md:h-auto md:min-h-[400px] max-h-[calc(100%-148px)] px-4 py-5 md:px-[22px]',
        'text-grayscale-primary bg-white border md:border border-gray-light rounded-t-xl md:rounded-xl',
        'overflow-hidden top-[180px] max-h-[524px] lg:top-[222px] left-8',
        {
          'fixed z-50 top-[180px] lg:top-[164px] left-8': !mobile,
        }
      )}
    >
      <div className="flex justify-between items-center w-full mb-3">
        <div className="flex items-center gap-2 text-focus [&_svg]:h-5 [&_svg]:w-5 truncate">
          <ChaptersIcon className="min-w-5 [&_path]:fill-current" />
        </div>
        <button className="text-grayscale-primary" onClick={onHide}>
          <CrossIcon className="[&_path]:fill-current" />
        </button>
      </div>
      <Contents className="flex-auto -mr-4 pr-4 leading-none overflow-y-auto">
        {textNodes.map((node, index) => {
          const item = node.data.props?.nodes?.items?.[0] as Heading;

          return (
            <div
              role="presentation"
              onClick={() => handleNodeClick(node.id)}
              key={node.id}
              className={cx(
                'relative block py-3.5 cursor-pointer truncate',
                item?.level === 1
                  ? cx('font-bold', { 'mt-1.5': index > 0 })
                  : 'px-3'
              )}
            >
              <span
                className={cx(
                  'px-4.5 hover:text-focus transition-all duration-300',
                  'after:absolute after:left-0 after:top-1/2 after:h-4 after:w-0 after:-translate-y-1/2 after:transition-all after:duration-300',
                  {
                    'hover:pl-6': selectedNodeId !== node.id,
                    'pl-6 after:w-0.5 after:bg-focus after:rounded-xs text-focus':
                      selectedNodeId === node.id,
                  }
                )}
              >
                {(item?.children?.[0] as Text)?.value
                  ? (item?.children?.[0] as Text)?.value
                  : item?.level === 1
                    ? 'Heading'
                    : 'Subheading'}
              </span>
            </div>
          );
        })}
      </Contents>
      <button
        onClick={addHeadingBlock}
        className="bg-hover-blue rounded-lg p-3 h-12 text-sm text-focus"
      >
        {t('Add heading')}
      </button>
    </div>
  );
};

export default TableOfContentsEditor;
