import { NotificationsKey } from '../types';

import useBaseTranslation from './useBaseTranslation';

const useNotificationsTranslation = () =>
  useBaseTranslation<NotificationsKey>(undefined, {
    keyPrefix: 'notifications',
  });

export default useNotificationsTranslation;
