import BlockTypes from '../constants';

import { Props } from './types';

import {
  mapPollBlock,
  mapQuizBlock,
  mapScormBlock,
  mapTaskBlock,
  mapYesNoNaBlock,
} from '.';

const mapDraggableBlock = (data: Props) => {
  if (data.block.data.nodes.length <= 0) return null;

  const block = data.nodes[data.block.data.nodes[0]];

  switch (block.data.name) {
    case BlockTypes.SimpleTaskBlock:
      return mapTaskBlock({ ...data, block });
    case BlockTypes.PollBlock:
      return mapPollBlock({ ...data, block });
    case BlockTypes.QuizBlock:
      return mapQuizBlock({ ...data, block });
    case BlockTypes.YesNoNaBlock:
      return mapYesNoNaBlock({ ...data, block });
    case BlockTypes.ScormBlock:
      return mapScormBlock({ ...data, block });
    default:
      return null;
  }
};

export default mapDraggableBlock;
