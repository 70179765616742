import useGetLinksQuery from 'app/api/pwaSettings/hooks/useGetLinksQuery';
import { useSelector } from 'app/hooks';
import useLoginMethod from 'app/hooks/useLoginMethod';
import { selectors } from 'app/store/request';
import { resolveSubdomain } from 'app/utils/subdomain';

import CustomLink from '../CustomLink';
import { SidebarSections } from '../types';

import ModuleExpansion from './ModuleExpansion';

interface Props {
  isClipped: boolean;
  onClick?: () => void;
}

const CustomModule = ({ isClipped, onClick }: Props) => {
  const instanceIsReady = useSelector(selectors.getRequestInstanceReady);
  const { isEasyAccessLogin } = useLoginMethod();

  const { data: links } = useGetLinksQuery({
    enabled: instanceIsReady,
    subdomain: resolveSubdomain(),
  });

  if (!links || links?.length <= 0 || isEasyAccessLogin) return null;

  return (
    <ModuleExpansion
      title="Custom links"
      isClipped={isClipped}
      section={SidebarSections.CustomLinks}
    >
      {links.map((link) => (
        <CustomLink
          key={link.id}
          link={link}
          isClipped={isClipped}
          onClick={onClick}
        />
      ))}
    </ModuleExpansion>
  );
};

export default CustomModule;
