import { ArrowLeft2, ArrowRight2 } from 'iconsax-react';

interface NavigationButtonsProps {
  totalImages: number;
  onPreviousClick: () => void;
  onNextClick: () => void;
}

const NavigationButtons = ({
  totalImages,
  onNextClick,
  onPreviousClick,
}: NavigationButtonsProps) => {
  return (
    <div className="flex items-center justify-between w-full mb-auto">
      {totalImages > 1 && (
        <button
          onClick={onPreviousClick}
          className="flex items-center justify-center w-10 h-10 rounded-lg text-white bg-grayscale-secondary opacity-80 z-[1]"
        >
          <ArrowLeft2 size={24} />
        </button>
      )}

      {totalImages > 1 && (
        <button
          onClick={onNextClick}
          className="flex items-center justify-center w-10 h-10 rounded-lg text-white bg-grayscale-secondary opacity-80 z-[1]"
        >
          <ArrowRight2 size={24} />
        </button>
      )}
    </div>
  );
};

export default NavigationButtons;
