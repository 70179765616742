import { resources, queryKeys } from 'app/api/auth';
import { useQuery } from 'react-query';

const useCheckTokenQuery = (
  apiToken: string,
  chatToken: string,
  isEnabled = false
) => {
  const { data, isLoading, isError, refetch, status } = useQuery(
    queryKeys.checkTokens(),
    resources.checkTokens(apiToken, chatToken),
    {
      enabled: isEnabled,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return { data: data?.data, isLoading, isError, refetch, status };
};

export default useCheckTokenQuery;
