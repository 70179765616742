import Spinner from 'app/components/Spinner';
import { useCommonTranslation } from 'app/internationalization/hooks';
import { Icon } from 'iconsax-react';
import uniqueId from 'lodash/uniqueId';
import { ChangeEvent } from 'react';

interface MediaBlockPlaceholderProps {
  isLoading?: boolean;
  type: string;
  TypeIcon: Icon;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const MediaBlockPlaceholder = ({
  isLoading,
  type,
  TypeIcon,
  onChange,
}: MediaBlockPlaceholderProps) => {
  const uniq = uniqueId(type);
  const { t } = useCommonTranslation();

  return (
    <div className="flex flex-col items-center justify-center py-4 px-4 w-full h-[385px] bg-grayscale-bg-dark text-grayscale-secondary rounded-lg">
      {!isLoading ? (
        <label
          htmlFor={uniq}
          className="flex flex-col items-center justify-center cursor-pointer"
        >
          <TypeIcon size={80} variant="Linear" />
          <span>
            {t('Upload')} {type}
          </span>
          {onChange && (
            <input
              id={uniq}
              className="hidden"
              type="file"
              onChange={(e) => onChange(e)}
            />
          )}
        </label>
      ) : (
        <div className="flex flex-col items-center">
          <Spinner className="!m-8" />
          <span>{t('Uploading...')}</span>
        </div>
      )}
    </div>
  );
};

export default MediaBlockPlaceholder;
