import { cx } from '@emotion/css';
import { useDispatch, useSelector } from 'app/hooks';
import { actions, selectors } from 'app/store/editor';
import { Cd, FlashCircle, TickCircle } from 'iconsax-react';
import { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { TranslationStatusEnum } from 'submodules/common-ui/generated/api/gcs';

import TranslationStatusDropdownContainer from '../TranslationStatusDropdownContainer';

const getTranslationStatusIcon = (status: TranslationStatusEnum) => {
  switch (status) {
    case 'approved':
      return <TickCircle variant="Bold" size={22} className="text-white" />;
    case 'draft':
      return <Cd size={22} variant="Bold" className="text-white" />;
    case 'auto':
      return <FlashCircle size={22} variant="Bold" className="text-white" />;
  }
};

type TranslationStatusButtonProps = {
  blockId: string;
  isBlockSelected: boolean;
};

const TranslationStatusButton = ({
  blockId,
  isBlockSelected,
}: TranslationStatusButtonProps) => {
  const [showTranslationStatusDropdown, setShowTranslationStatusDropdown] =
    useState(false);
  const dispatch = useDispatch();

  const selectedLanguages = useSelector(selectors.getSelectedLanguages);
  const selectedLanguage = selectedLanguages.find((sl) => sl.active);

  const translationStatuses = useSelector(selectors.getTranslationStatus);
  const translationStatus =
    selectedLanguage &&
    translationStatuses[blockId] &&
    translationStatuses[blockId][selectedLanguage.code]
      ? translationStatuses[blockId][selectedLanguage.code]
      : 'draft';

  const collaborators = useSelector(selectors.getSelectedCollaborators).filter(
    (collaborator) =>
      collaborator.languages.includes(selectedLanguage?.code ?? '')
  );

  const getCollaboratorsCounter = () => {
    if (collaborators.length < 4) return;

    const collaborator = collaborators[3];
    return (
      <span className="w-4 h-4 -ml-2 border border-white rounded-sm relative">
        <img
          key={`${collaborator.fullName}3`}
          src={collaborator.avatar}
          alt="avatar"
          className="w-full h-full rounded-sm"
          data-tip={collaborator.fullName}
        />
        <span className="text-white text-2xs absolute inset-0 bg-grayscale-primary bg-opacity-80 rounded-sm">
          +{collaborators.length - 3}
        </span>
      </span>
    );
  };

  return (
    <div className="absolute -top-2.5 right-1.5 z-30">
      <button
        className={cx(
          'flex justify-center items-center rounded shadow-atobi text-focus group-hover:visible ',
          {
            invisible: !showTranslationStatusDropdown && !isBlockSelected,
            'bg-success': translationStatus === 'approved',
            'bg-warning': translationStatus === 'draft',
            'bg-ceil': translationStatus === 'auto',
            'pl-3': collaborators && !isBlockSelected,
          }
        )}
        onClick={() => setShowTranslationStatusDropdown(true)}
      >
        {collaborators &&
          !isBlockSelected &&
          collaborators.slice(0, 3).map((collaborator, index) => {
            return (
              <img
                key={`${collaborator.fullName}${index}`}
                src={collaborator.avatar}
                alt="avatar"
                className="w-4 h-4 -ml-2 border border-white rounded-sm"
                data-tip={collaborator.fullName}
              />
            );
          })}
        {collaborators.length > 3 &&
          !isBlockSelected &&
          getCollaboratorsCounter()}
        {getTranslationStatusIcon(translationStatus)}
      </button>
      {showTranslationStatusDropdown && selectedLanguage && (
        <OutsideClickHandler
          onOutsideClick={() => setShowTranslationStatusDropdown(false)}
        >
          <TranslationStatusDropdownContainer
            className="right-0 top-8 xl:left-0"
            status={translationStatus}
            onSelect={(status) => {
              dispatch(
                actions.updateTranslationStatus({
                  [blockId]: {
                    [selectedLanguage.code]: status,
                  },
                })
              );

              setShowTranslationStatusDropdown(false);
            }}
          ></TranslationStatusDropdownContainer>
        </OutsideClickHandler>
      )}
    </div>
  );
};

export default TranslationStatusButton;
