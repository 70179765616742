import { useAuthenticatedUser } from 'app/api/auth/hooks';
import useGetEnabledModules from 'app/api/auth/hooks/useGetEnabledModules';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import YesNoIcon from 'assets/icons/yes-no.svg?react';
import {
  Gallery,
  TickSquare,
  MessageQuestion,
  MessageText1,
  Chart2,
  Teacher,
} from 'iconsax-react';
import {
  MediaTaskBlockTypeEnum,
  MultiChoiceBlockRenderTypeEnum,
  MultiChoiceBlockTypeEnum,
  OpenQuestionTaskBlockTypeEnum,
  ScormTaskBlockTypeEnum,
  SimpleTaskBlockTypeEnum,
} from 'submodules/common-ui/generated/api/gcs';

import SideMenuActionContainer from '../../../SideMenuActionContainer';

import ActionBlockOption from './ActionBlockOption';

interface Props {
  onClose: () => void;
}

const ActionsDropdown = ({ onClose }: Props) => {
  const { t } = useArticlesTranslation();
  const { data: user } = useAuthenticatedUser();
  const { modules } = useGetEnabledModules();

  const isScormEnabled =
    (modules?.scormAddon?.enabled ?? false) && (user?.scormAddon ?? false);

  return (
    <SideMenuActionContainer title={t('Actions')} onClose={onClose}>
      <ActionBlockOption
        text="Task"
        Icon={TickSquare}
        type={SimpleTaskBlockTypeEnum.SimpleTask}
      />
      <ActionBlockOption
        text="Media Task"
        Icon={Gallery}
        type={MediaTaskBlockTypeEnum.MediaTask}
      />
      <ActionBlockOption
        text="Quiz"
        Icon={MessageQuestion}
        type={MultiChoiceBlockTypeEnum.MultiChoice}
        renderType={MultiChoiceBlockRenderTypeEnum.Quiz}
      />
      <ActionBlockOption
        text="Open Question"
        Icon={MessageText1}
        type={OpenQuestionTaskBlockTypeEnum.OpenQuestionTask}
      />
      <ActionBlockOption
        text="Yes/No Question"
        Icon={YesNoIcon}
        type={MultiChoiceBlockTypeEnum.MultiChoice}
        renderType={MultiChoiceBlockRenderTypeEnum.YesNoNa}
      />
      <ActionBlockOption
        text="Poll"
        Icon={Chart2}
        type={MultiChoiceBlockTypeEnum.MultiChoice}
        renderType={MultiChoiceBlockRenderTypeEnum.Poll}
      />
      <ActionBlockOption
        text="Scorm Package"
        Icon={Teacher}
        type={ScormTaskBlockTypeEnum.ScormTask}
        enabled={isScormEnabled}
      />
    </SideMenuActionContainer>
  );
};

export default ActionsDropdown;
