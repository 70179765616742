import { cx } from '@emotion/css';
import { ResponsiveImage } from 'app/components';
import useFileUpload from 'app/hooks/useFileUpload';
import Spinner from 'assets/icons/spinner.svg?react';
import { Danger, Gallery } from 'iconsax-react';
import { useState } from 'react';
import { CreatedFileSlot } from 'submodules/common-ui/generated/api/gcs';

interface Props {
  source: string | null;
  nodeId: string;
  error?: boolean;
  onUploadSuccess: (file: File, response: CreatedFileSlot) => void;
  onSelectImage: () => void;
  onUploadError: () => void;
}

const ScormCoverImage = ({
  source,
  nodeId,
  error,
  onUploadSuccess,
  onSelectImage,
  onUploadError,
}: Props) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);

  const { upload, isLoading } = useFileUpload();

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files?.[0];
    e.target.value = '';

    if (!file) return;

    onSelectImage();

    upload(file, {
      onSuccess: (response) => onUploadSuccess(file, response),
      onError: onUploadError,
    });
  };

  const statusContainerStyle =
    'absolute left-0 top-0 right-0 bottom-0 w-full h-full flex items-center justify-center z-10 bg-focus-background';

  return (
    <>
      <input
        onChange={onFileChange}
        type="file"
        id={nodeId}
        className="hidden"
        accept="image/*"
      />

      <label
        htmlFor={nodeId}
        className={cx(
          'w-[92px] h-10 rounded-lg bg-focus-background flex items-center justify-center text-ceil overflow-hidden',
          { 'border border-error text-error': error }
        )}
      >
        <div className="relative">
          {source ? (
            <ResponsiveImage
              className="object-cover h-full w-full"
              src={source}
              onError={() => setHasError(true)}
              onLoad={() => {
                setHasLoaded(true);
                setHasError(false);
              }}
              external
            />
          ) : (
            <Gallery size={20} />
          )}
          {((source && !hasLoaded && !hasError) || isLoading) && (
            <div className={statusContainerStyle}>
              <Spinner className="w-5 h-5 animate-spin text-focus" />
            </div>
          )}
          {hasError && (
            <div className={statusContainerStyle}>
              <Danger size={20} />
            </div>
          )}
        </div>
      </label>
    </>
  );
};

export default ScormCoverImage;
