import {
  PdfBlock,
  PdfBlockVariants,
} from 'submodules/common-ui/generated/api/gcs/api';

import { Props } from './types';

const mapPdfBlock = ({
  article,
  block,
  index,
  selectedLanguage,
  translationStatuses,
  blockId,
}: Props): PdfBlock | null => {
  if (!block.data.props.pdfSchema) return null;

  let variants: PdfBlockVariants = {};
  if (blockId) {
    article.blocks.forEach((b) => {
      if (b.id === blockId) {
        variants = { ...b.variants } as PdfBlockVariants;
      }
    });
  }

  Object.keys(variants).forEach((language) => {
    if (blockId)
      variants[language] = {
        ...variants[language],
        ...{
          translationStatus:
            translationStatuses[blockId][language] ??
            variants[language].translationStatus,
        },
      };
  });

  return {
    id: blockId,
    version: 1,
    parentId: null,
    position: index,
    type: 'pdf',
    variants: {
      ...variants,
      [selectedLanguage]: {
        type: block.data.props.pdfSchema.type,
        name: block.data.props.pdfSchema.name,
        url: block.data.props.pdfSchema.url,
        id: block.data.props.pdfSchema.id,
        tenantId: block.data.props.pdfSchema.tenantId ?? null,
        translationStatus: blockId
          ? translationStatuses[blockId][selectedLanguage] ?? 'draft'
          : 'draft',
      },
    },
  };
};

export default mapPdfBlock;
