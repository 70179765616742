import { request } from 'app/api/gcsRequest';
import config from 'app/config';
import { ActionAnswerApi } from 'common-ui/generated/api/gcs';

import { GetActionAnswerProps } from '../hooks/useGetActionAnswer';

const getActionAnswer = ({
  id,
  originalArticleId,
  page,
  perPage,
  sortScore,
  sortCreatedAt,
  actionInstanceId,
  userIds,
  answerIds,
}: GetActionAnswerProps) =>
  new ActionAnswerApi(
    undefined,
    config.env.gcsApiUrl,
    request()
  ).getActionAnswer(
    id,
    originalArticleId || undefined,
    undefined,
    undefined,
    page,
    perPage,
    sortScore,
    sortCreatedAt,
    actionInstanceId,
    undefined,
    userIds?.toString(),
    answerIds?.toString()
  );

export { getActionAnswer };
export default getActionAnswer;
