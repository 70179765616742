import { Icon as IconType, ArrowRight } from 'iconsax-react';

interface Props {
  Icon: IconType | React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  text: string;
  onClick?(): void;
}

const BlockOption = ({ Icon, text, onClick }: Props) => (
  <div className="mx-2.5">
    <button
      className="flex items-center w-full py-3 text-sm hover:bg-hover-blue hover:text-focus my-2 px-3 rounded justify-between group"
      onClick={onClick}
    >
      <div className="flex items-center gap-3.5">
        <Icon
          className="text-grayscale-secondary group-hover:text-focus"
          size={16}
        />
        {text}
      </div>
      <div>
        <ArrowRight size={16} className="group-hover:block hidden text-focus" />
      </div>
    </button>
  </div>
);

export default BlockOption;
