import { Button, Modal } from 'app/components';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { outsideRoutes } from 'app/router';
import CrossIcon from 'assets/icons/cross.svg?react';
import { People, Refresh, Repeat, TickCircle } from 'iconsax-react';
import { Trans } from 'react-i18next';

interface Props {
  onClose: () => void;
}

const GenerateScormPackageSuccessModal = ({ onClose }: Props) => {
  const { t } = useArticlesTranslation();

  return (
    <Modal className="w-[443px]" onClose={onClose} containerPaddingClass="">
      <div className="flex flex-col relative ">
        <button
          className="text-grayscale-secondary absolute right-4 top-4"
          onClick={onClose}
        >
          <CrossIcon />
        </button>
        <span className="flex font-bold text-sm mb-0.5 p-4">
          <TickCircle size={16} className="mr-2 text-success" />
          <span>{t('SCORM package successfully generated')}</span>
        </span>
        <div className="px-4">
          <div className="mb-4 text-xs text-grayscale-primary">
            {t(
              'Switch your partners to Atobi instead of sharing SCORM packages and unlock these powerful features'
            )}
            :
          </div>

          <div className="mb-2">
            <div className="flex">
              <Refresh size={16} className="mr-2 text-focus shrink-0" />
              <div className="text-xs">
                <span className="font-bold pr-1">{t('Save time')}:</span>
                <span>
                  {t(
                    'Audience members will always see the latest version of your article without needing a new SCORM package.'
                  )}
                </span>
              </div>
            </div>
          </div>

          <div className="mb-2">
            <div className="flex ">
              <Repeat size={16} className="mr-2 text-focus shrink-0" />
              <div className="text-xs">
                <span className="font-bold pr-1">
                  {t('Streamline operations')}:
                </span>
                <span>
                  {t(
                    'Use recurring actions and deadlines to simplify operations and workflows.'
                  )}
                </span>
              </div>
            </div>
          </div>

          <div className="pb-4">
            <div className="flex">
              <People size={16} className="mr-2 text-focus shrink-0" />
              <div className="text-xs">
                <span className="font-bold pr-1">
                  {t('Increase engagement')}:
                </span>
                <span>
                  {t(
                    "Atobi's reminders and notifications drive more completed tasks and fewer missed deadlines."
                  )}
                </span>
              </div>
            </div>
          </div>

          <div className="ml-auto w-[176px] my-4">
            <Button variant="primary" onClick={onClose} className="h-[48px]">
              {t('Got it')}
            </Button>
          </div>
        </div>
        <div className="text-grayscale-secondary text-xs bg-gray-white-shadow px-4 py-2.5">
          <Trans
            components={{
              a: (
                <a
                  target="_blank"
                  className="text-focus underline"
                  href={outsideRoutes.atobiHelpMail.create()}
                  rel="noreferrer"
                >
                  {t('Atobi Support')}
                </a>
              ),
            }}
            i18nKey="articles.Want your partners to use Atobi instead of SCORM? Contact Atobi Support"
          />
        </div>
      </div>
    </Modal>
  );
};

export default GenerateScormPackageSuccessModal;
