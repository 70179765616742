import { CommentsContextProvider } from 'app/pages/Comments/context';

import Reactions from '../Reactions';

import CommentBottomAction from './CommentBottomAction';

const EditorBottomActions = () => (
  <div className="p-4">
    <div className="bg-hover-blue rounded-lg pt-3 pb-4 px-4">
      <Reactions />
      <CommentsContextProvider>
        <CommentBottomAction />
      </CommentsContextProvider>
    </div>
  </div>
);

export default EditorBottomActions;
