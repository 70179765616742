import { useDispatch, useSelector } from 'app/hooks';
import { resetState as resetQueryState } from 'app/query/utils';
import { selectors as authSelectors, actions } from 'app/store/auth';
import { actions as configActions } from 'app/store/config';
import dayjs from 'app/utils/dayjs';
import { useMutation } from 'react-query';

import { refreshToken as refreshTokenResource } from '../resources';

const useRefreshTokenMutation = () => {
  const refreshToken = useSelector(authSelectors.getRefreshToken) ?? '';
  const dispatch = useDispatch();

  const { mutateAsync: mutate } = useMutation(
    refreshTokenResource(refreshToken),
    {
      onSuccess: ({ data: user }) => {
        const token = user.access_token;

        const expiresAt = dayjs().add(user.expires_in, 'seconds').valueOf();

        dispatch(actions.tokenRetrieved(token));
        dispatch(actions.idTokenRetrieved(user.id_token));
        dispatch(actions.tokenExpiresAt(expiresAt));
        if (user.refresh_token) {
          dispatch(actions.refreshTokenRetrieved(user.refresh_token));
        }
        dispatch(configActions.isTokenRefreshingRetrieved(false));
        resetQueryState();
      },
      onError: () => {
        dispatch(configActions.isTokenRefreshingRetrieved(false));
        dispatch(actions.refreshTokenRetrieved(''));
      },
    }
  );

  return { mutate };
};

export default useRefreshTokenMutation;
