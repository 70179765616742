import { cx, keyframes } from '@emotion/css';
import styled from '@emotion/styled';
import { Spinner } from 'app/components';
import SimpleToast from 'app/components/Toast/SimpleToast';
import config from 'app/config';
import { useISOLanguages } from 'app/hooks';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { actions, selectors } from 'app/store/editor';
import { Language } from 'app/store/editor/types';
import { actions as modalActions } from 'app/store/modal';
import { AxiosError } from 'axios';
import { Danger, LocationTick, TickCircle } from 'iconsax-react';
import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { useLanguageSettingsContext } from '../context';

import LanguageCollaborators from './LanguageCollaborators';
import LanguageSettings from './LanguageSettings';
import TranslationStatus from './TranslationStatus';

interface Props {
  language: Language;
  className?: string;
  onSave: () => void;
}

const showToast = (text: string, icon: ReactElement) => {
  toast.dismiss();

  toast(<SimpleToast text={text} Icon={icon} />, {
    position: 'bottom-center',
    autoClose: 8000,
    closeButton: false,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    containerId: 'Simple',
  });
};

const iconClass = 'h-5 w-5 mr-2.5 text-white';

const spinnerIcon = <Spinner className={cx(iconClass, 'animate-spin')} />;
const dangerIcon = <Danger className={iconClass} />;
const tickCircleIcon = <TickCircle size={20} className={iconClass} />;

const LanguageItem = ({ language, className, onSave }: Props) => {
  const dispatch = useDispatch();
  const translationStatuses = useSelector(selectors.getTranslationStatus);

  const { t } = useArticlesTranslation();
  const languages = useISOLanguages({ search: '' });

  const {
    isTranslatingAddedLanguage,
    translatingLanguageCode,
    isTranslating,
    isTranslatingSuccess,
    error,
    isTranslatingError,
    isTranslatingSuccessAddedLanguage,
    setTranslationStatus,
    hasArticleOwnerPermissions,
  } = useLanguageSettingsContext();

  const [parentRef, setParentRef] = useState<HTMLDivElement | null>();
  const [progress, setProgress] = useState<0 | 50 | 100>(0);
  const [isUpdatingTranslationStatus, setIsUpdatingTranslationStatus] =
    useState(false);

  const isMainLanguage = language.isDefault;
  const isSettingsVisible =
    parentRef && !isMainLanguage && hasArticleOwnerPermissions;

  const isTranslatingSelectedLanguage =
    translatingLanguageCode === language.code && isTranslating;
  const isTranslatingSelectedSuccess =
    translatingLanguageCode === language.code && isTranslatingSuccess;

  const isTranslationLoading =
    isTranslatingAddedLanguage(language.code) || isTranslatingSelectedLanguage;
  const isTranslationSuccess =
    isTranslatingSuccessAddedLanguage(language.code) ||
    isTranslatingSelectedSuccess;

  const isAllBlocksApproved = Object.values(translationStatuses).every(
    (translationStatus) => translationStatus[language.code] === 'approved'
  );

  const onLanguageChange = () => {
    dispatch(actions.setActiveLanguage(language.code));
    dispatch(modalActions.hideModal());
  };

  const onTranslationStatusSelect = (
    status: 'draft' | 'approved',
    languageName: string
  ) => {
    const translationStatus = setTranslationStatus(languageName, status);
    if (!translationStatus) return;
    dispatch(actions.updateTranslationStatus(translationStatus));
    setIsUpdatingTranslationStatus(true);
  };

  useEffect(() => {
    if (!isUpdatingTranslationStatus) return;
    onSave();
    setIsUpdatingTranslationStatus(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translationStatuses]);

  useEffect(() => {
    if (isTranslationLoading) return setProgress(50);
    if (isTranslationSuccess) return setProgress(100);
    if (isTranslatingError) return setProgress(0);
  }, [isTranslationLoading, isTranslationSuccess, isTranslatingError]);

  useEffect(() => {
    if (progress < 100) return;

    const progressTimeout = setTimeout(() => setProgress(0), 500);
    return () => clearTimeout(progressTimeout);
  }, [progress]);

  const clearToasts = () => {
    toast.dismiss();
    toast.clearWaitingQueue();
  };

  useEffect(() => {
    clearToasts();

    if (isTranslationLoading) return showToast(t('Translating'), spinnerIcon);

    if (isTranslatingError) {
      if (
        error instanceof AxiosError &&
        error?.response?.data === 'Target language not supported'
      ) {
        const response = JSON.parse(error?.config?.data);
        const unsupportedLanguage = languages.find(
          ({ code }) => code === response['toLanguage']
        );
        return showToast(
          t(`{{ language }} language is not supported`, {
            language: unsupportedLanguage?.name,
          }),
          dangerIcon
        );
      }

      showToast(t('Automatic translation failed'), dangerIcon);
    }

    if (isTranslationSuccess)
      showToast(
        t('Translation complete. Previous text overwritten'),
        tickCircleIcon
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTranslating, isTranslatingSuccess, isTranslatingError]);

  return (
    <StyledBox
      role="presentation"
      progress={progress}
      onClick={onLanguageChange}
      ref={setParentRef}
      className={cx(
        className,
        'px-3 py-2 flex justify-between items-center bg-grayscale-bg-dark rounded-sm hover:bg-hover-blue cursor-pointer relative',
        {
          'opacity-40': isTranslationLoading,
        }
      )}
    >
      <div className="flex flex-col max-w-[266px] z-10">
        <span className="text-grayscale-secondary text-sm truncate font-bold mb-1">
          {language.name}
        </span>
        {isMainLanguage ? (
          <div className="flex flex-row items-center">
            <LocationTick size={16} className="text-grayscale-secondary mr-1" />
            <span className="text-grayscale-secondary text-xs">
              {t('Main')}
            </span>
          </div>
        ) : (
          <TranslationStatus
            languageCode={language.code}
            onSelect={onTranslationStatusSelect}
          />
        )}
      </div>
      <div className="flex flex-row items-center z-10">
        {parentRef && (
          <LanguageCollaborators
            language={language}
            parentRef={parentRef}
            onSave={onSave}
          />
        )}
        {isSettingsVisible && (
          <LanguageSettings
            parentRef={parentRef}
            language={language}
            isAllBlocksApproved={isAllBlocksApproved}
          />
        )}
      </div>
    </StyledBox>
  );
};

const loadingBackground = keyframes`
  0% { background-color: transparent; }
  100% { background-color: ${config.colors['focus-background']}; }
`;

const StyledBox = styled.div<{ progress: number }>`
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: ${(props) => props.progress}%;
    height: 100%;
    background-color: ${(props) =>
      props.progress < 100 && config.colors['focus-background']};
    animation: ${(props) =>
      props.progress === 100 ? `${loadingBackground} 2s ease-out` : 'none'};
    transition: ${(props) => (props.progress > 0 ? 'width 0.5s ease' : 'none')};
  }
`;

export default LanguageItem;
