import { Nodes, Node } from '@craftjs/core/lib/interfaces';
import { Modal } from 'app/components';
import SubmitAndCancelButton from 'app/components/SubmitAndCancelButton';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import CrossIcon from 'assets/icons/cross.svg?react';
import { Trans } from 'react-i18next';

import BlockTypes from '../helpers/constants';

interface Props {
  nodes: Nodes;
  onClose: () => void;
  onSubmit: () => void;
}

const MissingActionsModal = ({ onClose, onSubmit, nodes }: Props) => {
  const { t } = useArticlesTranslation();

  const getTextBlockTitle = (node: Node) => {
    const linkedNodeId = nodes[node.id].data.linkedNodes['draggableContainer'];
    const drag = nodes[linkedNodeId];
    const title = node.data?.props?.nodes?.items?.[0]?.children?.[0]?.value;
    return drag?.data.nodes.length === 0 && title ? title : undefined;
  };

  const draggableTextNodes = Object.values(nodes)
    .filter(({ data }) => data.displayName === BlockTypes.TextBlock)
    .map(getTextBlockTitle)
    .filter(Boolean);

  return (
    <Modal className="w-[420px]" onClose={onClose}>
      <div className="flex flex-col relative">
        <button
          className="text-grayscale-secondary absolute right-0 top-0"
          onClick={onClose}
        >
          <CrossIcon />
        </button>
        <span className="block font-bold text-base mb-3">
          {t('Want to add more actions?')}
        </span>
        <div className="mb-5 text-sm">
          <Trans
            components={{ bold: <span className="font-bold" /> }}
            i18nKey="articles.For the best sequential unlocking experience, we advise adding actions below each header, such as a Task to confirm that the content was seen and understood."
          />
        </div>
        <div className="mb-5 text-sm">
          {t('These header(s) are missing actions')}
          {draggableTextNodes.length > 0 && (
            <ul className="ml-6 list-disc">
              {draggableTextNodes.map((title, key) => (
                <li className="mb-0.5" key={key}>
                  {title}
                </li>
              ))}
            </ul>
          )}
        </div>
        <SubmitAndCancelButton
          cancelLabel={t('Go back')}
          submitLabel={t('Skip')}
          onSubmit={onSubmit}
          onCancel={onClose}
        />
      </div>
    </Modal>
  );
};

export default MissingActionsModal;
