import { Input2 } from 'app/components';
import { useComponentsTranslation } from 'app/internationalization/hooks';
import { ChangeEvent } from 'react';

interface SearchInputProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  onClear: () => void;
}

const SearchInput: React.FC<SearchInputProps> = ({
  onChange,
  value,
  onClear,
}) => {
  const { t } = useComponentsTranslation();

  return (
    <Input2
      value={value}
      onChange={onChange}
      isSearch
      placeholder={`${t('Search...')}`}
      name="search"
      onClear={onClear}
    />
  );
};

export default SearchInput;
