import { queryKeys } from 'app/api/comments';
import isUndefined from 'lodash/isUndefined';
import { InfiniteData, useQueryClient } from 'react-query';
import { CommentPage, EmojiEnum } from 'submodules/common-ui/generated/api/gcs';

import handleReactionInComment from '../utils/handleReactionInComment';

const useCommentReaction = () => {
  const queryClient = useQueryClient();

  const setCommentReaction = ({
    objectId,
    parentId,
    currentPage,
    emoji,
    type,
  }: {
    parentId?: number | null;
    currentPage?: number;
    objectId: number;
    emoji: EmojiEnum;
    type: 'add' | 'remove';
  }) => {
    if (isUndefined(currentPage) || !parentId) return;
    const commentsQueryKey = queryKeys.comments(currentPage, parentId);

    const queryState = queryClient.getQueryState(commentsQueryKey);

    if (!queryState) return;

    queryClient.setQueryData<InfiniteData<CommentPage> | undefined>(
      commentsQueryKey,
      (previousData) => {
        if (!previousData) return;

        const currentPageIndex = previousData.pages[0].meta.currentPage;

        if (currentPageIndex !== -1) {
          const updatedPage = { ...previousData.pages[currentPageIndex] };

          const commentIndex = updatedPage.data.findIndex(
            (cmt) => cmt.id === objectId
          );

          if (commentIndex === -1) return previousData;

          updatedPage.data[commentIndex] = handleReactionInComment(
            updatedPage.data[commentIndex],
            emoji,
            type
          );

          previousData.pages[currentPageIndex] = updatedPage;
        }

        return previousData;
      }
    );
  };

  return {
    setCommentReaction,
  };
};

export default useCommentReaction;
