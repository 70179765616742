/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserStatus } from 'app/api/user/types';

import { NAME } from './constants';
import { State } from './types';

const initialState: State = {
  articleFilter: 'draft',
  audiencesTab: 'available',
  articleStudioTab: 'articles',
  templateFilter: 'all_templates',
  membersTab: UserStatus.active,
};

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setArticleFilter: (
      state,
      { payload }: PayloadAction<State['articleFilter']>
    ) => {
      state.articleFilter = payload;
    },
    setTemplateFilter: (
      state,
      { payload }: PayloadAction<State['templateFilter']>
    ) => {
      state.templateFilter = payload;
    },
    setMembersTab: (state, { payload }: PayloadAction<string>) => {
      state.audiencesTab = payload;
    },
    setArticleStudioTab: (
      state,
      { payload }: PayloadAction<State['articleStudioTab']>
    ) => {
      state.articleStudioTab = payload;
    },
    stateReset: () => initialState,
  },
});

export default slice;
