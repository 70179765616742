import { useEffect, useState } from 'react';
import {
  ArticleFileGet,
  CoverImageArticleFileGet,
  DirectUrls,
} from 'submodules/common-ui/generated/api/gcs/api';

const useFileDirectUrls = (
  file?: ArticleFileGet | CoverImageArticleFileGet
) => {
  const [directUrls, setDirectUrls] = useState<DirectUrls>();

  useEffect(() => {
    if (!file || !('directUrls' in file)) return;

    return setDirectUrls(file.directUrls);
  }, [file]);

  return { directUrls };
};

export default useFileDirectUrls;
