const QuizSkeleton = () => (
  <div className=" m-2 p-3 rounded border border-gray-light shadow-action">
    <div className="flex flex-col gap-3">
      <div className="flex items-center gap-3">
        <div className="h-5 w-5 bg-gradient-to-b from-[#B549D0]/20 to-[#7C48D0]/10 rounded-md"></div>
        <div className="h-5  w-full lg:w-3/4 columns-lg bg-gradient-to-b from-[#B549D0]/20 to-[#7C48D0]/10 rounded-md"></div>
      </div>
      <div className="flex items-center gap-3">
        <div className="h-5 w-5 bg-white"></div>
        <div className="h-5  w-full lg:w-3/4 columns-lg bg-gradient-to-b from-[#B549D0]/20 to-[#7C48D0]/10 rounded-md"></div>
      </div>
      <div className="flex items-center gap-3">
        <div className="h-5 w-5 bg-gradient-to-b from-[#B549D0]/20 to-[#7C48D0]/10 rounded-md"></div>
        <div className="h-5 w-full columns-lg bg-gradient-to-b from-[#B549D0]/20 to-[#7C48D0]/10 rounded-md"></div>
      </div>
      <div className="flex items-center gap-3">
        <div className="h-5 w-5 bg-gradient-to-b from-[#B549D0]/20 to-[#7C48D0]/10 rounded-md"></div>
        <div className="h-5 w-full columns-lg rounded-md bg-gradient-to-b from-[#B549D0]/20 to-[#7C48D0]/10"></div>
      </div>

      <div className="flex items-center gap-3 justify-between mt-2.5">
        <div className="flex items-center gap-2">
          <div className="h-4 w-[87px] bg-gradient-to-b from-[#B549D0]/20 to-[#7C48D0]/10 rounded-md"></div>
          <div className="h-4 w-[87px] bg-gradient-to-b from-[#B549D0]/20 to-[#7C48D0]/10 rounded-md"></div>
        </div>
        <div className="flex items-center gap-2">
          <div className="h-4 w-5 bg-gradient-to-b from-[#B549D0]/20 to-[#7C48D0]/10 rounded-md"></div>
          <div className="h-4 w-5 bg-gradient-to-b from-[#B549D0]/20 to-[#7C48D0]/10 rounded-md"></div>
        </div>
      </div>
    </div>
  </div>
);

export default QuizSkeleton;
