import { useArticlesTranslation } from 'app/internationalization/hooks';
import { FIRST_STEP } from 'app/utils/steps';
import { Calendar, Clipboard, Icon as IconSax, People } from 'iconsax-react';

interface Props {
  step: number;
  isArticleShared: boolean;
}

const ForwardArticleStepInfo = ({ step, isArticleShared }: Props) => {
  const { t } = useArticlesTranslation();

  const secondStepItems: { Icon: IconSax; text: string }[] = [
    {
      Icon: Clipboard,
      text: 'External team receives a copy of the article',
    },
    {
      Icon: Calendar,
      text: 'External team can schedule and publish the article',
    },
    {
      Icon: People,
      text: 'Collaborators & audiences you selected won’t be copied',
    },
  ];

  return (
    <div className="flex flex-col">
      {!isArticleShared && step === FIRST_STEP && (
        <div className="text-black text-xs mb-2">
          {secondStepItems.map(({ Icon, text }) => (
            <div key={text} className="flex flex-row gap-1 items-center mb-1">
              <Icon size={16} />
              {t(text)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ForwardArticleStepInfo;
