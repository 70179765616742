import { css, cx } from '@emotion/css';
import config from 'app/config';
import { HTMLAttributes, ReactNode } from 'react';

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  overflow?: boolean;
}

const ScrollbarContainer = ({
  children,
  className,
  overflow = false,
  ...props
}: Props) => (
  <div
    {...props}
    className={cx(
      className,
      { 'overflow-auto': !overflow },
      css`
        ${scrollBarStyles}
      `
    )}
  >
    {children}
  </div>
);

export const scrollBarStyles = `
  & {
    scrollbar-width: thin;
    scrollbar-color: ${config.colors['hover-blue']} transparent;
  }

  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${config.colors['hover-blue']};
    border-radius: 20px;
    border: 1px solid transparent;
  }
`;

export default ScrollbarContainer;
