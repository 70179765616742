import { ScrollbarContainer } from 'app/components';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { Audience } from 'app/store/editor/types';
import { EyeSlash } from 'iconsax-react';

import AudienceDetails from './AudienceDetails';
import AudienceOptions from './AudienceOptions';

const SelectedOptions = ({
  audiences,
  handleRemoveAudience,
  showMore,
}: {
  audiences: Audience[];
  handleRemoveAudience: (id: number) => void;
  showMore: boolean | null;
}) => {
  const { t } = useArticlesTranslation();
  return (
    <ScrollbarContainer className="max-h-[256px] overflow-y-scroll w-full">
      {(showMore || showMore === null ? audiences : audiences.slice(0, 2)).map(
        (audience) => {
          return (
            <div
              className="flex items-center gap-2 w-full h-[64px]"
              key={audience.id}
            >
              {audience.isVisible ? (
                <>
                  <AudienceDetails
                    id={audience.id}
                    name={audience.name}
                    userCount={audience.members ?? 0}
                  />
                  <AudienceOptions
                    audienceId={audience.id}
                    removeAudience={() => handleRemoveAudience(audience.id)}
                    enabled={true}
                  />
                </>
              ) : (
                <>
                  <div className="flex flex-col text-start">
                    <span className="text-sm text-grayscale-primary font-bold">
                      {t('Audience')} {audience.id}
                    </span>
                    <div className="flex items-center">
                      <EyeSlash
                        size={16}
                        className="text-grayscale-secondary"
                      />
                      <span className="text-sm text-grayscale-secondary ml-2">
                        {t('Details hidden for privacy reasons.')}
                      </span>
                    </div>
                  </div>
                  <AudienceOptions
                    audienceId={audience.id}
                    removeAudience={() => handleRemoveAudience(audience.id)}
                    enabled={false}
                  />
                </>
              )}
            </div>
          );
        }
      )}
    </ScrollbarContainer>
  );
};

export default SelectedOptions;
