import { CloseButton, Modal } from 'app/components';
import { useDispatch } from 'app/hooks';
import { actions as modalActions } from 'app/store/modal';

import ScormIframe from '../ScormIframe';

export type ScormAnswerModalProps = {
  actionId: string;
  languageCode: string;
};

const ScormAnswerModal = ({
  actionId,
  languageCode,
}: ScormAnswerModalProps) => {
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(modalActions.hideModal());
  };

  return (
    <Modal className="w-[90vw] h-[90vh]" onClose={onClose}>
      <CloseButton onClose={onClose} />
      <ScormIframe actionId={actionId} languageCode={languageCode} />
    </Modal>
  );
};

export default ScormAnswerModal;
