/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RecurringArticleDate } from 'app/pages/Articles/Components/RecuringArticleModal/types';
import merge from 'lodash/merge';
import uniqBy from 'lodash/uniqBy';
import {
  Article,
  TranslationStatusEnum,
  BasicChannelInfo,
  GetCategory,
  ArticleShownAs,
} from 'submodules/common-ui/generated/api/gcs';

import { NAME } from './constants';
import {
  ActionStats,
  ArticleShownAsAction,
  Audience,
  Collaborator,
  Language,
  State,
} from './types';

const initialState: State = {
  article: undefined,
  allowComments: true,
  audiences: [],
  collaborators: [],
  categories: [],
  editorTab: 'create',
  languages: [],
  channel: null,
  publishDate: undefined,
  archiveDate: undefined,
  canEdit: true,
  translationStatus: {},
  choiceHasFocus: false,
  useTemplateView: false,
  shownAs: { value: ArticleShownAs.Training },
  stats: {
    score: null,
    mandatoryScore: null,
    progress: 0,
    mandatoryProgress: 0,
  },
  recurringDate: undefined,
  answerSharing: undefined,
};

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    addLanguage: (state, { payload }: PayloadAction<Language>) => {
      state.languages = uniqBy(
        [
          ...state.languages,
          {
            name: payload.name,
            code: payload.code,
            isDefault: payload.isDefault,
            active: payload.active,
          },
        ],
        'code'
      );
    },
    removeLanguageFromCollaborator: (
      state,
      {
        payload,
      }: PayloadAction<{ collaboratorId: number; languageCode: string }>
    ) => {
      const { collaboratorId, languageCode } = payload;
      const collaborator = state.collaborators.find(
        ({ id }) => id === collaboratorId
      );
      if (!collaborator) return;

      collaborator.languages = collaborator.languages.filter(
        (language) => language !== languageCode
      );
    },
    addLanguageForCollaborator: (
      state,
      {
        payload,
      }: PayloadAction<{ collaboratorId: number; languageCode: string }>
    ) => {
      const { collaboratorId, languageCode } = payload;
      const collaborator = state.collaborators.find(
        ({ id }) => id === collaboratorId
      );
      if (!collaborator) return;

      collaborator.languages = [...collaborator.languages, languageCode];
    },
    removeLanguage: (state, { payload }: PayloadAction<string>) => {
      state.languages = state.languages.filter(
        (language) => language.code !== payload
      );
      state.collaborators = state.collaborators.map(
        (collaborator): Collaborator => {
          return {
            ...collaborator,
            languages: collaborator.languages.filter((l) => l !== payload),
          };
        }
      );
    },
    updateLanguages: (state, { payload }: PayloadAction<Language[]>) => {
      if (state.languages.length === 0) {
        state.languages = payload;
      }
    },
    setMainLanguage: (state, { payload }: PayloadAction<string>) => {
      state.languages = state.languages.map((language): Language => {
        return { ...language, isDefault: language.code === payload };
      });
    },
    setActiveLanguage: (state, { payload }: PayloadAction<string>) => {
      state.languages = state.languages.map((language) => {
        return { ...language, active: language.code === payload };
      });
    },
    addCollaborator: (state, { payload }: PayloadAction<Collaborator>) => {
      state.collaborators = uniqBy(
        [
          ...state.collaborators,
          {
            id: payload.id,
            fullName: payload.fullName,
            avatar: payload.avatar,
            languages: payload.languages,
            email: payload.email,
          },
        ],
        'id'
      );
    },
    allowComments: (state, { payload }: PayloadAction<boolean>) => {
      state.allowComments = payload;
    },
    setTemplateView: (state, { payload }: PayloadAction<boolean>) => {
      state.useTemplateView = payload;
    },
    updateCollaborators: (
      state,
      { payload }: PayloadAction<Collaborator[]>
    ) => {
      if (state.collaborators.length === 0) {
        state.collaborators = payload;
        return;
      }

      payload.forEach((collaborator) => {
        state.collaborators.forEach((current) => {
          if (current.id === collaborator.id) {
            current.fullName = collaborator.fullName;
            current.avatar = collaborator.avatar;
          }
        });
      });
    },
    updateCollaboratorLanguages: (
      state,
      { payload }: PayloadAction<{ id: number; code: string }>
    ) => {
      state.collaborators.forEach((c) => {
        if (c.id === payload.id) {
          if (!c.languages.includes(payload.code)) {
            c.languages = [...c.languages, payload.code];
            return;
          }

          if (c.languages.length - 1 > 0) {
            c.languages = c.languages.filter((l) => l !== payload.code);
          }
        }
      });
    },
    updateAllAudiences: (state, { payload }: PayloadAction<Audience[]>) => {
      state.audiences = payload;
    },
    addAudience: (state, { payload }: PayloadAction<Audience>) => {
      state.audiences = uniqBy(
        [
          ...state.audiences,
          {
            id: payload.id,
            name: payload.name,
            members: payload.members,
            isVisible: payload.isVisible,
          },
        ],
        'id'
      );
    },
    removeAudience: (state, { payload }: PayloadAction<number>) => {
      state.audiences = state.audiences.filter(
        (audience) => audience.id !== payload
      );
    },
    updateAudiences: (state, { payload }: PayloadAction<Audience[]>) => {
      const uniqueAudiences = payload.filter(
        (audience) => !state.audiences.some(({ id }) => id === audience.id)
      );

      state.audiences = [...state.audiences, ...uniqueAudiences];
    },
    addChannel: (
      state,
      { payload }: PayloadAction<BasicChannelInfo | null>
    ) => {
      state.channel = payload;
    },
    addCategories: (state, { payload }: PayloadAction<GetCategory[]>) => {
      state.categories = payload;
    },
    removeCategory: (state, { payload }: PayloadAction<number>) => {
      state.categories = state.categories.filter(
        (category) => category.id !== payload
      );
    },
    setPublishDate: (state, { payload }: PayloadAction<string | undefined>) => {
      state.publishDate = payload;
    },
    setArchiveDate: (state, { payload }: PayloadAction<string | undefined>) => {
      state.archiveDate = payload;
    },
    setCanEdit: (state, { payload }: PayloadAction<boolean>) => {
      state.canEdit = payload;
    },
    updateTranslationStatus: (
      state,
      {
        payload,
      }: PayloadAction<Record<string, Record<string, TranslationStatusEnum>>>
    ) => {
      state.translationStatus = merge(state.translationStatus, payload);
    },
    setStats: (state, { payload }: PayloadAction<ActionStats>) => {
      state.stats = payload;
    },
    setEditorTab: (state, { payload }: PayloadAction<string>) => {
      state.editorTab = payload;
    },
    setChoiceHasFocus: (state, { payload }: PayloadAction<boolean>) => {
      state.choiceHasFocus = payload;
    },
    setArticle: (state, { payload }: PayloadAction<Article>) => {
      state.article = payload;
    },
    setShownAs: (state, { payload }: PayloadAction<ArticleShownAsAction>) => {
      state.shownAs = payload;
    },
    setAnswerSharing: (state, { payload }: PayloadAction<boolean>) => {
      state.answerSharing = payload;
    },
    setRecurringDate: (
      state,
      { payload }: PayloadAction<RecurringArticleDate | undefined>
    ) => {
      state.recurringDate = payload;
    },
    stateReset: () => {
      return initialState;
    },
  },
});

export default slice;
