import { request } from 'app/api/gcsRequest';
import config from 'app/config';
import { ArticleApi } from 'common-ui/generated/api/gcs';

const deleteArticles = ({ ids }: { ids: number[] }) =>
  new ArticleApi(undefined, config.env.gcsApiUrl, request())
    .bulkDeleteArticles({ articleIds: ids })
    .then((response) => response.data);

export { deleteArticles };
export default deleteArticles;
