import useHomeTranslation from 'app/internationalization/hooks/useHomeTranslation';
import { SearchNormal1, CloseCircle } from 'iconsax-react';
import { useRef, useState } from 'react';

interface SearchBarProps {
  onChange: (value: string) => void;
}
const SearchBar = ({ onChange }: SearchBarProps) => {
  const { t } = useHomeTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState('');

  const triggerInputChange = () => {
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      'value'
    )?.set;
    if (!nativeInputValueSetter || !inputRef.current) return;

    nativeInputValueSetter.call(inputRef.current, '');

    const ev = new Event('input', { bubbles: true });
    inputRef.current.dispatchEvent(ev);
  };

  const handleInputChange = (value: string) => {
    setInputValue(value);
    onChange(value);
  };

  return (
    <div className="flex px-6 py-4 items-center w-full">
      <SearchNormal1 size={20} />
      <input
        ref={inputRef}
        onChange={(e) => handleInputChange(e.target.value)}
        className="w-full ml-2.5 outline-none"
        type="text"
        placeholder={t('Search articles or channels')}
      />
      {inputValue && (
        <button
          className="ml-auto"
          onClick={() => {
            onChange('');
            triggerInputChange();
          }}
        >
          <CloseCircle
            variant="Bold"
            className="w-5 h-5 text-grayscale-secondary"
          />
        </button>
      )}
    </div>
  );
};

export default SearchBar;
