import { useArticlesTranslation } from 'app/internationalization/hooks';
import useUserQuery from 'app/pages/profiles/PublicProfile/useUserQuery';
import dayjs from 'dayjs';
import { Shop } from 'iconsax-react';
import { FC } from 'react';
import {
  DbOpenQuestionTaskAnswer,
  Text,
} from 'submodules/common-ui/generated/api/gcs';

import { useAnswersContext } from '../context';

import LoadingOpenQuestionTasks from './LoadingOpenQuestionTasks';

interface Props {
  userId: number;
  answer: DbOpenQuestionTaskAnswer;
  createdAt: string;
}

const OpenQuestionTask: FC<Props> = ({ answer, userId, createdAt }) => {
  const { data: user, isLoading } = useUserQuery({ id: userId });
  const { selectedTenant } = useAnswersContext();
  const { t } = useArticlesTranslation();

  if (isLoading) return <LoadingOpenQuestionTasks />;

  return (
    <div className="flex gap-1">
      <div className="w-7 h-7 rounded bg-hover-blue flex items-center justify-center">
        {user?.avatars?.small ? (
          <img className="rounded-[10px]" src={user.avatars.small} alt="" />
        ) : (
          <Shop className="w-4 h-4 text-ceil" />
        )}
      </div>

      <div className="w-full rounded-ss-xs rounded-se-xl rounded-ee-xl rounded-es-xl bg-hover-blue py-[10px] px-3 flex flex-col gap-2.5">
        <div className="w-full flex flex-col gap-1">
          <div className="flex justify-between text-sm">
            <span className="text-grayscale-primary">
              {selectedTenant
                ? `${selectedTenant.tenantName} ${t('member')}`
                : `${user?.firstName} ${user?.lastName}`}
            </span>
            <span className="text-grayscale-secondary">
              {dayjs(createdAt).fromNow()}
            </span>
          </div>

          <div className="flex gap-1 items-center text-grayscale-secondary text-sm">
            <span>{user?.profession.name}</span>
            <span className="w-0.5 h-0.5 bg-gray-dark rounded-full" />
            <span>{user?.location?.name}</span>
          </div>
        </div>

        <span className="text-base text-grayscale-primary break-words min-w-0 overflow-hidden max-h-[200px]">
          {(answer?.children?.[0] as Text).value}
        </span>
      </div>
    </div>
  );
};

export default OpenQuestionTask;
