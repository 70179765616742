import { cx } from '@emotion/css';
import { useAuthenticatedUser } from 'app/api/auth/hooks';
import { ComplaintStatus, ComplaintTypes } from 'app/api/complaints/constants';
import { useComplaintQuery } from 'app/api/complaints/hooks';
import { useScreenBreakpoint } from 'app/hooks';
import { useComponentsTranslation } from 'app/internationalization/hooks';
import { isMobile } from 'app/utils';
import ReactIcon from 'assets/icons/react.svg?react';
import ReplyIcon from 'assets/icons/undo.svg?react';
import {
  Slash as BanIcon,
  EyeSlash as EyeOffIcon,
  Flag as FlagIcon,
  Translate as TranslateIcon,
} from 'iconsax-react';
import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import ReactTooltip from 'react-tooltip';
import DeleteTranslationIcon from 'remixicon-react/ArrowGoBackLineIcon';
import MoreIcon from 'remixicon-react/More2FillIcon';
import { EmojiEnum } from 'submodules/common-ui/generated/api/gcs';
import { Drawer } from 'vaul';

import ControlButton from '../ControlButton';
import { Emojis } from '../Reactions';
import Tooltip from '../Tooltip';

import BlockModal from './BlockModal';
import HideModal from './HideModal';
import ReportModal from './ReportModal';

interface ComplaintsPopupProps {
  complaintType: ComplaintTypes;
  complaintObjectId: number;
  creatorUserId?: number;
  creatorName?: string;
  hideLabel: string;
  hideDescription: string;
  isTranslated?: boolean;
  isLoading?: boolean;
  isChart?: boolean;
  reactLabel?: string | null;
  replyLabel?: string | null;
  showContextMenu?: boolean;
  contextMenuClassName?: string;
  onReact?(emoji: EmojiEnum): void;
  onReply?(): void;
  onTranslate?(): void;
}

const ComplaintsPopup: React.FC<ComplaintsPopupProps> = ({
  complaintType,
  complaintObjectId,
  creatorUserId,
  creatorName,
  isTranslated,
  isLoading,
  reactLabel,
  replyLabel,
  hideLabel,
  hideDescription,
  isChart = false,
  showContextMenu = true,
  contextMenuClassName,
  onReact,
  onReply,
  onTranslate,
}) => {
  const { data: user } = useAuthenticatedUser();
  const { t } = useComponentsTranslation();
  const { mutate: createComplaint } = useComplaintQuery();

  const [openReactions, setOpenReactions] = useState(false);
  const [shouldDisplayMore, setShouldDisplayMore] = useState(false);
  const [shouldDisplayReportModal, setShouldDisplayReportModal] =
    useState(false);
  const [shouldDisplayBlockModal, setShouldDisplayBlockModal] = useState(false);
  const [shouldDisplayHideModal, setShouldDisplayHideModal] = useState(false);

  const breakpoint = useScreenBreakpoint();
  const mobile = isMobile(breakpoint);

  const iconClassName = showContextMenu
    ? 'w-6 h-6 mr-4'
    : 'w-[18px] h-[18px] text-grayscale-secondary';

  const onCreateComplaint = (
    isUserBlock: boolean,
    status: ComplaintStatus,
    reportReson?: string
  ) => {
    const complaintId = isUserBlock ? creatorUserId : complaintObjectId;
    const type = isUserBlock ? ComplaintTypes.user : complaintType;

    if (!user || !complaintId) return;

    createComplaint(
      {
        type: type,
        reporter_id: user.id,
        reason_for_reporting: reportReson,
        complaint_object: {
          id: complaintId,
          status,
        },
      },
      {
        onSettled: () => {
          setShouldDisplayHideModal(false);
          setShouldDisplayBlockModal(false);
          setShouldDisplayReportModal(false);
        },
      }
    );
  };

  const renderAddReactionButton = () => (
    <Tooltip content={reactLabel} show={!showContextMenu}>
      <ControlButton
        className="rounded-sm"
        onClick={() => {
          setOpenReactions((prev) => !prev);
          ReactTooltip.hide();
        }}
      >
        <ReactIcon className={iconClassName} />
        {showContextMenu && (
          <span className="whitespace-nowrap">{reactLabel}</span>
        )}
      </ControlButton>
    </Tooltip>
  );

  return (
    <OutsideClickHandler onOutsideClick={() => setShouldDisplayMore(false)}>
      {shouldDisplayReportModal && (
        <ReportModal
          onReport={(reason) => {
            onCreateComplaint(false, ComplaintStatus.none, reason);
          }}
          onClose={() => setShouldDisplayReportModal(false)}
        />
      )}
      {shouldDisplayBlockModal && creatorName && (
        <BlockModal
          onBlock={() => onCreateComplaint(true, ComplaintStatus.blocked)}
          onClose={() => setShouldDisplayBlockModal(false)}
          creatorName={creatorName}
        />
      )}
      {shouldDisplayHideModal && (
        <HideModal
          title={hideLabel}
          description={hideDescription}
          onHide={() => onCreateComplaint(false, ComplaintStatus.hidden)}
          onClose={() => setShouldDisplayHideModal(false)}
        />
      )}
      {showContextMenu && (
        <button
          onClick={() => setShouldDisplayMore((prev) => !prev)}
          type="button"
          className={cx(contextMenuClassName, {
            'flex items-center text-xs text-grayscale-secondary mb-2': isChart,
          })}
        >
          <MoreIcon className={isChart ? 'w-4 h-4 mr-2' : ''} />
        </button>
      )}
      <div className="relative">
        {(shouldDisplayMore || !showContextMenu) && (
          <div
            className={cx(
              'gap-1.5 absolute z-10 transform text-sm translate-x-3 bg-light right-0 shadow-atobi rounded-[8px] border border-focus-background py-1 px-2',
              {
                flex: !showContextMenu,
                'bottom-0': isChart,
              }
            )}
          >
            {onReact && (
              <>
                {mobile ? (
                  <Drawer.Root
                    open={openReactions}
                    onOpenChange={setOpenReactions}
                  >
                    <Drawer.Trigger asChild>
                      {renderAddReactionButton()}
                    </Drawer.Trigger>
                    <Drawer.Portal>
                      <Drawer.Overlay
                        className="fixed inset-0 bg-grayscale-primary/20 z-50"
                        onClick={() => setOpenReactions(false)}
                      />
                      <Drawer.Content className="fixed bottom-0 left-0 right-0 flex flex-col outline-none z-50">
                        <Emojis
                          onClick={(emoji) => {
                            onReact(emoji);
                            setOpenReactions(false);
                          }}
                        />
                      </Drawer.Content>
                    </Drawer.Portal>
                  </Drawer.Root>
                ) : (
                  <OutsideClickHandler
                    onOutsideClick={() => setOpenReactions(false)}
                  >
                    {openReactions && (
                      <div className="absolute -top-2 -right-2">
                        <Emojis
                          onClick={(emoji) => {
                            onReact(emoji);
                            setOpenReactions(false);
                          }}
                        />
                      </div>
                    )}
                    {renderAddReactionButton()}
                  </OutsideClickHandler>
                )}
              </>
            )}

            {onReply && (
              <Tooltip content={replyLabel} show={!showContextMenu}>
                <ControlButton
                  disabled={isLoading}
                  className="rounded-sm"
                  onClick={onReply}
                >
                  <ReplyIcon className={iconClassName} />
                  {showContextMenu && (
                    <span className="whitespace-nowrap">{replyLabel}</span>
                  )}
                </ControlButton>
              </Tooltip>
            )}

            {creatorName && (
              <Tooltip
                content={t('Block {{creatorName}}', {
                  user: creatorName,
                })}
                show={!showContextMenu}
              >
                <ControlButton
                  disabled={isLoading}
                  className="rounded-sm"
                  onClick={() => setShouldDisplayBlockModal(true)}
                >
                  <BanIcon className={iconClassName} />
                  {showContextMenu && (
                    <span className="whitespace-nowrap">
                      {t('Block {{creatorName}}', {
                        user: creatorName,
                      })}
                    </span>
                  )}
                </ControlButton>
              </Tooltip>
            )}

            <Tooltip content={t('Report')} show={!showContextMenu}>
              <ControlButton
                disabled={isLoading}
                className="rounded-sm"
                onClick={() => {
                  setShouldDisplayReportModal(true);
                }}
              >
                <FlagIcon className={iconClassName} />
                {showContextMenu && (
                  <span className="whitespace-nowrap">{t('Report')}</span>
                )}
              </ControlButton>
            </Tooltip>

            <Tooltip content={t('Hide')} show={!showContextMenu}>
              <ControlButton
                disabled={isLoading}
                onClick={() => {
                  setShouldDisplayHideModal(true);
                }}
                className="rounded-sm"
              >
                <EyeOffIcon className={iconClassName} />
                {showContextMenu && (
                  <span className="whitespace-nowrap">{t('Hide')}</span>
                )}
              </ControlButton>
            </Tooltip>

            {!isChart && onTranslate && (
              <Tooltip content={t('Translate')} show={!showContextMenu}>
                <ControlButton
                  disabled={isLoading}
                  onClick={() => {
                    onTranslate();
                  }}
                  className="rounded-sm"
                >
                  {isTranslated ? (
                    <DeleteTranslationIcon className={iconClassName} />
                  ) : (
                    <TranslateIcon className={iconClassName} />
                  )}
                  {showContextMenu && (
                    <span className="whitespace-nowrap">{t('Translate')}</span>
                  )}
                </ControlButton>
              </Tooltip>
            )}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default ComplaintsPopup;
