import {
  ChangeEvent,
  FC,
  TextareaHTMLAttributes,
  useEffect,
  useRef,
} from 'react';

interface ResizeableTextareaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  defaultHeight?: number;
}

const ResizeableTextarea: FC<ResizeableTextareaProps> = ({
  style,
  defaultHeight = 200,
  value,
  ...restProps
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = `${defaultHeight}px`;
      const { scrollHeight } = textAreaRef.current;
      if (scrollHeight >= defaultHeight) {
        textAreaRef.current.style.height = `${scrollHeight}px`;
      }
    }
  }, [defaultHeight, value]);

  return (
    <textarea
      value={value}
      ref={textAreaRef}
      style={{ ...style }}
      {...restProps}
    />
  );
};

export default ResizeableTextarea;
