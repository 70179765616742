import { useEditor } from '@craftjs/core';
import { ScormProps } from 'app/pages/ArticleEditor/components/blocks/tasks/ScormBlock';
import { useMemo } from 'react';
import {
  Article,
  ScormTaskBlockBVariantsValueCourseStatusEnum,
} from 'submodules/common-ui/generated/api/gcs';

import BlockTypes from '../helpers/constants';

const useArticleCanBePublished = (article?: Article) => {
  const { nodes } = useEditor((__, query) => ({
    nodes: query.getNodes(),
  }));

  const scormNodeIds = useMemo(() => {
    if (!nodes?.['dropableRegion']) return [];

    return nodes['dropableRegion'].data.nodes.filter(
      (n) => nodes[n].data.displayName === BlockTypes.ScormBlock
    );
  }, [nodes]);

  const scormNodes = useMemo(() => {
    return scormNodeIds.map(
      (nodeId) => nodes?.[nodeId].data.props as ScormProps
    );
  }, [nodes, scormNodeIds]);

  const hasScormNodes = useMemo(
    () => scormNodeIds.length > 0,
    [scormNodeIds.length]
  );

  const canPublish = useMemo(() => {
    if (!article && hasScormNodes) return false;

    return !scormNodes.some(
      (n) =>
        n.courseStatus !== ScormTaskBlockBVariantsValueCourseStatusEnum.Complete
    );
  }, [article, hasScormNodes, scormNodes]);

  return {
    canPublish,
  };
};

export default useArticleCanBePublished;
