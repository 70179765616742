const removeTimezone = (date?: string | Date | null): string | undefined => {
  if (!date) return undefined;

  if (date instanceof Date) {
    const userTimezoneOffset =
      date !== null ? date?.getTimezoneOffset() * 60000 : null;

    const dateWithoutOffset =
      date !== null && userTimezoneOffset !== null
        ? new Date(date.getTime() - userTimezoneOffset)
        : null;

    return dateWithoutOffset?.toISOString();
  }

  if (date.length < 19) return undefined;

  return date.substring(0, 19);
};

export default removeTimezone;
