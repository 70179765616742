import { cx } from '@emotion/css';
import dayjs from 'app/utils/dayjs';
import { Calendar, CloseCircle } from 'iconsax-react';
import { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef } from 'react';

interface Props
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  date?: string | null;
  text?: string;
  className?: string;
  clearDate?: () => void;
}
const DatePickerInput = forwardRef<HTMLButtonElement, Props>(
  ({ date, disabled, text, onClick, clearDate, className }, ref) => (
    <div className={cx('relative group w-full', className)}>
      <button
        ref={ref}
        disabled={disabled}
        onClick={onClick}
        type="button"
        className={cx(
          'flex h-[46px] w-full items-center gap-2 border rounded p-2  focus-within:border-gray-dark border-gray-dark',
          {
            'bg-gray-dark': disabled,
          }
        )}
      >
        <Calendar
          size={16}
          className={cx({ 'mr-1': Boolean(date) || Boolean(text) })}
        />
        {date && (
          <span className="text-xs text-grayscale-primary">
            {dayjs(date).format('DD.MM.YY HH:mm')}
          </span>
        )}
        {!date && text && <span className="text-xs">{text}</span>}
      </button>
      {date && !disabled && (
        <CloseCircle
          onClick={clearDate}
          size={16}
          className="ml-1 invisible group-hover:visible absolute top-1/2 transform -translate-y-1/2 right-2 cursor-pointer"
        />
      )}
    </div>
  )
);

export default DatePickerInput;
