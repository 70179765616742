import { useArticlesTranslation } from 'app/internationalization/hooks';
import { Danger } from 'iconsax-react';

const Error = () => {
  const { t } = useArticlesTranslation();

  return (
    <div className="text-error p-2 border border-error flex flex-col gap-1 rounded mx-4 mt-3">
      <div className="flex items-center font-bold text-xs">
        <Danger size={12} className="mr-1" />
        {t('Oops! Something Went Wrong')}
      </div>
      <div className="text-xs">
        {t(
          "We couldn't analyze your answers. Please check your internet connection and try again. If this keeps happening, please contact our support team."
        )}
      </div>
    </div>
  );
};

export default Error;
