import { UpcomingActionsKey } from '../types';

import useBaseTranslation from './useBaseTranslation';

const useUpcomingActionsTranslation = () =>
  useBaseTranslation<UpcomingActionsKey>(undefined, {
    keyPrefix: 'upcomingActions',
  });

export default useUpcomingActionsTranslation;
