import { Element, useEditor, useNode } from '@craftjs/core';
import { cx } from '@emotion/css';
import useIsEditorEnabled from 'app/hooks/useIsEditorEnabled';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import AddOption from 'assets/icons/add-option.svg?react';
import { Dispatch, SetStateAction, useEffect } from 'react';
import {
  ActionTypeEnum,
  MultiChoiceBlockRenderTypeEnum,
} from 'submodules/common-ui/generated/api/gcs';

import CategoryDropdown from '../components/CategoryDropdown';
import Completion from '../components/Completion';
import Deadline from '../components/Deadline';
import Mandatory from '../components/Mandatory';
import Privacy from '../components/Privacy';
import Recurring from '../components/Recurring';
import TaskType from '../components/TaskType';
import Title from '../components/Title';

import PollFeedback from './components/Feedback';
import PollOption from './Option/PollOption';
import PollOptionsContainer from './Option/PollOptionsContainer';

import { PollBlockProps } from '.';

interface EditorUserModeProps {
  setBackspace: Dispatch<SetStateAction<boolean>>;
  setHasFocus: Dispatch<SetStateAction<boolean>>;
  setProp: (cb: any, throttleRate?: number | undefined) => void;
}

const EditorUserMode = ({
  setBackspace,
  setHasFocus,
  setProp,
}: EditorUserModeProps) => {
  const { t } = useArticlesTranslation();
  const {
    actions: { addNodeTree },
    query: { parseReactElement },
    nodes,
  } = useEditor((state) => ({ nodes: state.nodes }));

  const { selected, linkedNodes, pollProps } = useNode((node) => ({
    selected: node.events.selected,
    nodeId: node.id,
    linkedNodes: node.data.linkedNodes,
    pollProps: node.data.props as PollBlockProps,
  }));

  const { enabled } = useIsEditorEnabled();

  const pollOptionsContainer = linkedNodes['pollOptionsContainer'];

  const optionsNumber = nodes[pollOptionsContainer]?.data?.nodes?.length ?? 1;

  const addOption = () => {
    const block = parseReactElement(<PollOption />).toNodeTree();

    addNodeTree(block, pollOptionsContainer);
  };

  useEffect(() => {
    if (pollProps.mandatory === undefined)
      setProp((props: PollBlockProps) => {
        props.mandatory = true;
      });
  }, [pollProps.mandatory, setProp]);

  return (
    <div className="flex justify-between m-2 p-3 rounded border border-gray-light shadow-action">
      <div className="flex flex-col w-full">
        <div className="flex items-center">
          <TaskType
            type={ActionTypeEnum.MultiChoice}
            renderType={MultiChoiceBlockRenderTypeEnum.Poll}
            disabled={!enabled}
          />
          <Title
            onFocusChange={(focus) => setHasFocus(focus)}
            placeholder={`${t('Title (optional)')}`}
            className="font-bold placeholder:font-normal"
            description={pollProps.title}
            enabled={enabled}
            onChange={(value) =>
              setProp((props: PollBlockProps) => {
                props.title = value;
              })
            }
          />
        </div>
        <div className="flex mt-2 ml-[32px]">
          <Title
            onFocusChange={(focus) => setHasFocus(focus)}
            placeholder={`${t('Description')}*`}
            enabled={enabled}
            description={pollProps.description}
            error={!pollProps.description && !selected}
            onChange={(value) =>
              setProp((props: PollBlockProps) => {
                props.description = value;
              })
            }
          />
        </div>

        <Element id="pollOptionsContainer" is={PollOptionsContainer} canvas>
          <PollOption />
        </Element>

        {enabled && (
          <button
            className="flex mt-2 text-gray-dark w-fit hover:text-focus cursor-pointer"
            onClick={() => addOption()}
            disabled={!enabled}
          >
            <AddOption className="w-[22px] h-[22px]" />
            <span className="ml-3 text-sm">Add Option</span>
          </button>
        )}

        <div className="flex text-gray-dark mt-2.5 items-center">
          {(pollProps.schedule?.frequency === 'once' ||
            !pollProps.schedule) && (
            <Deadline
              disabled={!enabled}
              selected={pollProps.deadline}
              onChange={(value) =>
                setProp((props: PollBlockProps) => {
                  props.deadline = value ?? undefined;
                  props.schedule = undefined;
                })
              }
            />
          )}
          <Recurring
            disabled={!enabled}
            scheduleDate={pollProps.schedule}
            onChange={(date) =>
              setProp((props: PollBlockProps) => {
                props.schedule = date;
                props.deadline = undefined;
              })
            }
          />
          <CategoryDropdown
            onOpenCallback={setBackspace}
            disabled={!enabled}
            selectedCategories={pollProps.categories}
            onChange={(category) =>
              setProp((props: PollBlockProps) => {
                props.categories = category ? [category] : [];
              })
            }
          />
          <div
            className={cx('flex items-center ml-auto text-gray-dark gap-1.5', {
              hidden: !selected && !pollProps.deadline,
            })}
          >
            <PollFeedback
              optionsNumber={optionsNumber}
              correctAnswersNumber={pollProps.maxSelectedChoices}
              setNumberOfChoices={(value) =>
                setProp((props: PollBlockProps) => {
                  props.maxSelectedChoices = value;
                })
              }
            />
            <div className="bg-gray-light h-4 w-[1px]" />
            <Privacy
              disabled={!enabled}
              isPublic={pollProps.public}
              onClick={(value) =>
                setProp((props: PollBlockProps) => {
                  props.public = value;
                })
              }
            />
            <div className="bg-gray-light h-4 w-[1px]" />
            <Completion
              disabled={!enabled}
              required={pollProps.required}
              onClick={(value) =>
                setProp((props: PollBlockProps) => {
                  props.required = value;
                })
              }
            />
            <div className="bg-gray-light h-4 w-[1px]" />
            <Mandatory
              disabled={!enabled}
              checked={!!pollProps.mandatory}
              onChange={() =>
                setProp((props: PollBlockProps) => {
                  props.mandatory = !pollProps.mandatory;
                })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditorUserMode;
