import { resources } from 'app/api/articles';
import { useMutation } from 'react-query';

const useAnswerActionMutation = () => {
  const { mutate, isLoading, isError, data, isSuccess, reset } = useMutation(
    resources.answerAction
  );

  return { mutate, reset, isLoading, isError, isSuccess, data };
};

export default useAnswerActionMutation;
