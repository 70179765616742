import { cx } from '@emotion/css';
import { Switch } from 'app/components';
import { useDispatch, useSelector } from 'app/hooks';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { outsideRoutes } from 'app/router';
import { actions, selectors } from 'app/store/editor';
import { Danger, InfoCircle, Warning2 } from 'iconsax-react';
import { Trans } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { ArticleShownAs } from 'submodules/common-ui/generated/api/gcs';

import SideMenuActionContainer from '../../../SideMenuActionContainer';

interface Props {
  onClose: () => void;
}

const ChronologicalDropdown = ({ onClose }: Props) => {
  const dispatch = useDispatch();
  const { t } = useArticlesTranslation();
  const shownAs = useSelector(selectors.getShownAs);
  const answerSharing = useSelector(selectors.getAnswerSharing);
  const article = useSelector(selectors.getArticle);

  const answerSharingEnabled = answerSharing ?? article?.answerSharing ?? false;

  const onSwitch = (check: boolean) => {
    dispatch(
      actions.setShownAs({
        value: check ? ArticleShownAs.Training : ArticleShownAs.Article,
        toggled: true,
      })
    );
  };

  const onAnswerSharingSwitch = (check: boolean) => {
    dispatch(actions.setAnswerSharing(check));
  };

  return (
    <SideMenuActionContainer title={t('Settings')} onClose={onClose}>
      <div className="flex flex-col">
        <span className="my-2 text-sm text-grayscale-primary">
          {t('Actions settings')}
        </span>
        <div className="flex items-center justify-between mb-2">
          <div className="flex items-center text-grayscale-secondary">
            <span className="text-sm mr-1">{t('Sequential actions')}</span>
            <InfoCircle data-for="info" data-tip size={16} />
            <ReactTooltip
              effect="solid"
              class="react-tooltip w-[236px] whitespace-normal"
              id="info"
              place="bottom"
              clickable
              delayHide={100}
            >
              <Trans
                components={{
                  a: (
                    <a
                      target="_blank"
                      className="underline"
                      href={outsideRoutes.chronologicalActions.create()}
                      rel="noreferrer"
                    >
                      Learn more
                    </a>
                  ),
                }}
                i18nKey="articles.By enabling this the members will be required to sequentially complete actions before advancing in the article. Learn more"
              />
            </ReactTooltip>
          </div>
          <Switch
            height={16}
            width={24}
            checked={shownAs.value === ArticleShownAs.Training}
            onChange={onSwitch}
          />
        </div>
        <div className="border border-focus-background bg-gray-white-shadow rounded p-2 flex items-center">
          <div className="mr-1">
            <Warning2 size={12} className="text-warning" />
          </div>
          <span className="text-xs text-grayscale-primary w-[190px] whitespace-normal">
            {t('Sequential actions are not supported by the native app yet.')}
          </span>
        </div>
        {article?.originalTenant && (
          <>
            <span className="mb-2 mt-3 text-sm text-grayscale-primary">
              {t('Atobi Connect settings')}
            </span>
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center text-grayscale-secondary">
                <span className="text-sm mr-1">
                  {t('Sharing article insights')}
                </span>
                <InfoCircle data-for="info2" data-tip size={16} />
                <ReactTooltip
                  effect="solid"
                  class="react-tooltip whitespace-normal w-[320px]"
                  id="info2"
                  place="bottom"
                  delayHide={100}
                >
                  <span>
                    {t(
                      'Keep this enabled to share the insights, results, and answers from this article with the original owner who forwarded it to you. Note The owner will be notified if you choose to opt out of this setting.'
                    )}
                  </span>
                </ReactTooltip>
              </div>
              <Switch
                height={16}
                width={24}
                checked={answerSharingEnabled}
                onChange={onAnswerSharingSwitch}
              />
            </div>

            <div
              className={cx(
                'max-h-0 transition-all duration-300 ease-in-out overflow-hidden',
                {
                  'max-h-[300px]': !answerSharingEnabled,
                }
              )}
            >
              <div className="border border-focus-background bg-gray-white-shadow rounded p-2 flex items-center">
                <div className="mr-1">
                  <Danger size={12} className="text-error" />
                </div>
                <span className="text-xs text-grayscale-primary w-[190px] whitespace-normal">
                  {t(
                    'The insights are currently not being shared with the external partner. Enable sharing to maintain the connection.'
                  )}
                </span>
              </div>
            </div>
          </>
        )}
      </div>
    </SideMenuActionContainer>
  );
};

export default ChronologicalDropdown;
