import styled from '@emotion/styled';
import config from 'app/config';
import { Collaborator } from 'app/store/editor/types';
import CrossIcon from 'assets/icons/cross.svg?react';
import { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';

interface Props {
  collaborators: Collaborator[];
  removeCollaborator: (id: number) => void;
}

const AddedCollaborators = ({ collaborators, removeCollaborator }: Props) => {
  const [onHoverId, setOnHoverId] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (onHoverId) ReactTooltip.rebuild();
  }, [onHoverId]);

  return (
    <div className="p-3 bg-hover-blue rounded-t gap-2 grid grid-cols-6">
      {collaborators.map((collaborator) => (
        <div
          role="presentation"
          onClick={() => removeCollaborator(collaborator.id)}
          data-for="collaborator"
          data-tip={collaborator.fullName}
          key={collaborator.id}
          className="relative"
          onMouseEnter={() => setOnHoverId(collaborator.id)}
          onMouseLeave={() => setOnHoverId(undefined)}
        >
          <img
            src={collaborator.avatar}
            alt="avatar"
            className="w-8 h-8 rounded"
            data-tip={collaborator.fullName}
          />
          {onHoverId === collaborator.id && (
            <div className="absolute inset-0 rounded flex items-center justify-center cursor-pointer">
              <div className="bg-black opacity-40 absolute inset-0 rounded" />
              <StyledCrossIcon className="z-10" />
            </div>
          )}
        </div>
      ))}
      <ReactTooltip
        place="top"
        effect="solid"
        class="react-tooltip"
        id="collaborator"
      />
    </div>
  );
};

const StyledCrossIcon = styled(CrossIcon)`
  path {
    stroke: ${config.colors.white};
    fill: ${config.colors.white};
  }
`;

export default AddedCollaborators;
