import { useNode } from '@craftjs/core';
import { css, cx } from '@emotion/css';
import config from 'app/config';
import { useComponentsTranslation } from 'app/internationalization/hooks';
import { CloseCircle, TickCircle } from 'iconsax-react';
import { ComponentProps } from 'react';

import SimpleTask from '../../../../SimpleTask';
import ActionContainer from '../ActionContainer';
import EditorNavigation from '../EditorNavigation';

const YesNoQuestionViewerAction = () => {
  const { t: ct } = useComponentsTranslation();

  const { selectedProps } = useNode((node) => ({
    selectedProps: node.data.props as ComponentProps<typeof SimpleTask>,
  }));

  const { title, description } = selectedProps;

  return (
    <ActionContainer
      isMandatory={selectedProps.mandatory}
      canAnswer={false}
      type={'yes_no_task'}
    >
      <div className="flex flex-col">
        <span
          className={cx('text-lg font-bold', css('word-break: break-word;'))}
        >
          {title}
        </span>
        <span className={cx('text-black', css('word-break: break-word;'))}>
          {description}
        </span>
      </div>
      <div className="flex flex-col gap-3 mt-4">
        <div className="rounded-lg border-gray-dark border opacity-50">
          <div className="flex p-4">
            <TickCircle className="mr-1" color={config.colors.success} />
            <span className="font-bold">{ct('Yes')}</span>
          </div>
        </div>
        <div className="rounded-lg border-gray-dark border opacity-50">
          <div className="flex p-4">
            <CloseCircle className="mr-1" color={config.colors.error} />
            <span className="font-bold">{ct('No')}</span>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center w-full mt-6">
        <EditorNavigation />
      </div>
    </ActionContainer>
  );
};

export default YesNoQuestionViewerAction;
