import { cx } from '@emotion/css';
import { selectors } from 'app/store/editor';
import dayjs from 'dayjs';
import { ArrowDown2, SmsTracking } from 'iconsax-react';
import { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useSelector } from 'react-redux';

const SharedCommentPopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const article = useSelector(selectors.getArticle);

  return (
    <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
      <div className="flex flex-col items-end fixed bottom-0 right-0 mr-6 mb-6 z-10">
        {isOpen && (
          <div className="absolute bottom-full flex flex-col p-2 bg-white rounded-[24px] rounded-br-[9px] max-w-[392px] w-max z-10 border border-gray-light transform translate-y-1 shadow-atobi">
            <div className="flex flex-col rounded-xl rounded-br-[1px] bg-gray px-4 py-2">
              <div className="flex">
                <SmsTracking size={20} />
                <span className="ml-2 text-sm font-bold">
                  {article?.originalTenant?.name ?? ''}
                </span>
              </div>

              <span className="my-1 text-sm">
                {article?.sharingComment ?? ''}
              </span>
              <span className="text-xs">
                {dayjs(article?.createdAt).format('DD MMM YYYY')}
              </span>
            </div>
          </div>
        )}

        <button
          className={cx(
            'flex items-center justify-center w-10 h-10 bg-light rounded-[12px] border border-gray-light shadow-atobi',
            { 'rounded-tr-[4px]': isOpen }
          )}
          onClick={() => setIsOpen((prev) => !prev)}
        >
          {isOpen && <ArrowDown2 size={24} className="text-focus" />}
          {!isOpen && <SmsTracking size={24} className="text-focus" />}
        </button>
      </div>
    </OutsideClickHandler>
  );
};

export default SharedCommentPopup;
