import { cx } from '@emotion/css';

interface ImageStackProps {
  images: string[];
  baseUrl: string;
  onClick: () => void;
}

const ImageStack = ({ images, baseUrl, onClick }: ImageStackProps) => {
  const totalImages = images.length;
  return (
    <button
      className="absolute bottom-0 right-0 w-[126px] h-[104px] mr-5 mb-5"
      onClick={onClick}
    >
      <div className="relative w-full h-full">
        {images.map((image, index) => {
          const rotateClass = index === 0 ? '' : `rotate-[${index * 2}deg]`;
          return (
            <div className={cx(rotateClass)} key={image}>
              <img
                className="w-[126px] h-[104px] rounded-[10px] object-fit absolute"
                src={`${baseUrl}/${image}`}
                alt="media-task"
              />
            </div>
          );
        })}
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border border-white border-opacity-25 rounded-lg">
          <div className="w-full h-full relative px-3 py-1.5">
            <span className="relative block z-[1] text-white text-10 font-semibold">
              + {totalImages - 1}
            </span>
            <div className="absolute top-0 left-0 w-full h-full rounded-lg bg-black opacity-[0.65]" />
          </div>
        </div>
      </div>
    </button>
  );
};

export default ImageStack;
