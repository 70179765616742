import { request } from 'app/api/gcsRequest';
import config from 'app/config';
import {
  ReactionApi,
  EmojiEnum,
  ReactionObjectTypeEnum,
} from 'submodules/common-ui/generated/api/gcs';

const getReactions = ({
  objectType,
  objectId,
  page,
  createdAt = 'asc',
  emoji,
  perPage,
}: {
  objectType: ReactionObjectTypeEnum;
  objectId: number;
  page: number;
  createdAt?: 'asc' | 'desc';
  perPage?: number;
  emoji?: EmojiEnum;
}) =>
  new ReactionApi(undefined, config.env.gcsApiUrl, request())
    .getReactions(objectType, objectId, page, perPage, emoji, createdAt)
    .then((response) => response.data);

export default getReactions;
