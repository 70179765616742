import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import config from 'app/config';
import noop from 'lodash/noop';
import { ReactNode, useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

interface SideMenuButtonProps {
  children: (onClose: () => void) => ReactNode;
  first?: boolean;
  selected?: boolean;
  disabled?: boolean;
  isBottom?: boolean;
  onClick: VoidFunction;
}

const SideMenuButton = ({
  children,
  first,
  selected = false,
  disabled = false,
  isBottom = false,
  onClick,
}: SideMenuButtonProps) => {
  const [isSelected, setSelected] = useState(selected);

  useEffect(() => {
    setSelected(selected);
  }, [selected]);

  return (
    <OutsideClickHandler
      onOutsideClick={() => (!selected ? setSelected(false) : noop)}
    >
      <StyledSideMenuButton
        className={cx('flex justify-center w-full', {
          'mt-2': !first,
          'opacity-[.4] cursor-auto pointer-events-none': disabled,
        })}
        isSelected={isSelected}
        isBottom={isBottom}
        role="button"
        onClick={() => {
          if (disabled) return;

          setSelected((prev) => (selected ? (prev ? prev : !prev) : true));
          onClick();
        }}
      >
        <div
          className={cx('p-1', {
            'bg-focus-background rounded-lg': isSelected && !disabled,
            'rounded-lg hover:bg-hover-blue': !disabled,
          })}
        >
          {children(() => setSelected(false))}
        </div>
      </StyledSideMenuButton>
    </OutsideClickHandler>
  );
};

interface Props {
  isSelected: boolean;
  isBottom: boolean;
}

const StyledSideMenuButton = styled.div`
  border: 2px solid transparent;

  ${({ isSelected, isBottom }: Props) => {
    if (!isSelected) return;

    return `
      opacity: 1;
      color: ${config.colors.focus};
      
      .dropdown {
        display: flex;
        top: ${isBottom ? 'unset' : '0'};
        bottom: ${isBottom ? '0' : 'unset'};
        right: -20px;
        transform: translateX(-85px);
      }

      & > svg {
        background-color: ${config.colors['focus-background']};
      }
    `;
  }}
`;

export default SideMenuButton;
