const LoadingMediaTasks = () => {
  return (
    <div className="rounded-xl gap-2 shadow-atobi flex flex-col bg-hover-blue">
      <div className="p-2 flex flex-col gap-2 text-grayscale-secondary">
        <div className="flex gap-1 items-center">
          <div className="h-4 w-[108px] bg-focus-background rounded-xl" />
        </div>
        <div className="flex gap-1 items-center">
          <div className="h-4 w-[125px] bg-focus-background rounded-xl" />
        </div>

        <div className="w-full rounded-[10px] h-[185px] bg-focus-background" />
      </div>
    </div>
  );
};

export default LoadingMediaTasks;
