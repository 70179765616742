import { request } from 'app/api/gcsRequest';
import config from 'app/config';
import { ArticleApi, UpdatedArticle } from 'common-ui/generated/api/gcs';

const updateArticle = ({ article }: { article: UpdatedArticle }) =>
  new ArticleApi(undefined, config.env.gcsApiUrl, request())
    .updateArticle(article)
    .then((response) => response.data);

export { updateArticle };
export default updateArticle;
