import { cx } from '@emotion/css';
import useDateTimeFormat from 'app/hooks/useDateTimeFormat';
import useUserQuery from 'app/pages/profiles/PublicProfile/useUserQuery';
import { FC } from 'react';
import {
  DbOpenQuestionTaskAnswer,
  Text,
} from 'submodules/common-ui/generated/api/gcs';

import { useAnswersContext } from '../../context';
import UserDetails from '../UserDetails';

interface Props {
  userId: number;
  answer: DbOpenQuestionTaskAnswer;
  createdAt: string;
}

const OpenQuestionDeepDive: FC<Props> = ({ userId, answer, createdAt }) => {
  const { layout } = useAnswersContext();

  const { data: user, isLoading: isLoadingUser } = useUserQuery({ id: userId });

  const createdAtTime = useDateTimeFormat({
    timestamp: createdAt,
    type: 'dayMonthYearTime',
  });

  return (
    <div
      className={cx(
        'rounded-xl gap-4 bg-white shadow-atobi flex flex-col p-4',
        {
          'bg-hover-blue': isLoadingUser,
          'bg-grayscale-bg-dark': !isLoadingUser,
        }
      )}
    >
      <UserDetails
        isLoadingUser={isLoadingUser}
        user={user}
        createdAtTime={createdAtTime}
        layout={layout}
      />

      <span className="bg-grayscale-bg-dark px-3 py-2.5 rounded-lg border-[1px] border-focus-background text-sm break-words">
        {(answer?.children?.[0] as Text).value}
      </span>
    </div>
  );
};

export default OpenQuestionDeepDive;
