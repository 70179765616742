import { useQuery } from 'react-query';

import { resources, queryKeys } from '../index';

interface UseGetProfessionsQueryProps {
  enabled?: boolean;
}

const useGetProfessionsQuery = ({
  enabled = true,
}: UseGetProfessionsQueryProps) => {
  const { data, isLoading, error, isFetching, isPreviousData } = useQuery(
    queryKeys.professions(),
    () => resources.getProfessions(),
    { enabled, refetchOnWindowFocus: false }
  );

  return {
    data: data?.data?.data,
    isFetching,
    isLoading,
    isPreviousData,
    error,
  };
};

export default useGetProfessionsQuery;
