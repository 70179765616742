import { EmojiEnum } from 'submodules/common-ui/generated/api/gcs/api';

export default {
  reactions: (page: number, objectId: number, emoji?: EmojiEnum) => [
    'reactions',
    page,
    objectId,
    emoji,
  ],
};
