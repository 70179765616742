import { SerializedLexicalState } from 'app/components/LexicalEditor/types';

const lexicalInitialState = (): string => {
  const state: SerializedLexicalState = {
    root: {
      children: [
        {
          children: [
            {
              detail: 0,
              format: 0,
              mode: 'normal',
              style: '',
              text: '',
              type: 'text',
              version: 1,
            },
          ],
          direction: 'ltr',
          format: '',
          indent: 0,
          type: 'paragraph',
          version: 1,
        },
      ],
      direction: 'ltr',
      format: '',
      indent: 0,
      type: 'root',
      version: 1,
    },
  };
  return JSON.stringify(state);
};

export default lexicalInitialState;
