import useGetSharingConnectionQuery from 'app/api/articleSharing/hooks/useGetSharingConnectionQuery';
import Modal from 'app/components/Modal';
import SubmitAndCancelButton from 'app/components/SubmitAndCancelButton';
import SimpleToast from 'app/components/Toast/SimpleToast';
import { useDispatch } from 'app/hooks';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { actions as modalActions } from 'app/store/modal';
import { ModalTypes } from 'app/store/modal/types';
import CrossIcon from 'assets/icons/cross.svg?react';
import { toast } from 'react-toastify';

import useDeleteSharedArticleMutation from '../../hooks/mutations/useDeleteSharedArticleMutation';

export interface DeleteSharedArticleModalProps {
  articleId: number;
  articleOwner: number;
  receiverName: string;
  tenantId: number;
}

const DeleteSharedArticleModal = ({
  articleId,
  articleOwner,
  receiverName,
  tenantId,
}: DeleteSharedArticleModalProps) => {
  const dispatch = useDispatch();
  const { t } = useArticlesTranslation();

  const { mutate, isLoading } = useDeleteSharedArticleMutation();
  const { refetch } = useGetSharingConnectionQuery({
    enabled: false,
  });

  const deleteSharingcConnection = () =>
    mutate(
      { articleId, tenantIds: [tenantId] },
      {
        onSuccess: (_) => {
          refetch?.();
          showToast();
          openForwardModal();
        },
      }
    );

  const openForwardModal = () =>
    dispatch(
      modalActions.showModal({
        modalType: ModalTypes.FORWARD_MODAL,
        modalProps: { articleOwner, articleId },
      })
    );

  const showToast = () =>
    toast(<SimpleToast text={t('Article has been withdrawn')} />, {
      position: 'bottom-center',
      autoClose: 8000,
      closeButton: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      containerId: 'Simple',
    });

  return (
    <Modal onClose={openForwardModal}>
      <div className="flex flex-col max-w-[424px] relative">
        <span className="font-bold text-lg text-grayscale-primary mb-4">
          {t('Withdraw Article?')}
        </span>
        <button
          className="absolute right-0 top-0"
          onClick={() => dispatch(modalActions.hideModal())}
        >
          <CrossIcon />
        </button>
        {t(
          '1 article will be withdrawn and  deleted from editors and audiences in {{tenant}}',
          {
            tenant: receiverName,
          }
        )}
        <SubmitAndCancelButton
          className="mt-7"
          isSubmitDisabled={isLoading}
          cancelLabel={t('Cancel')}
          onCancel={openForwardModal}
          submitLabel={t('Withdraw')}
          onSubmit={deleteSharingcConnection}
        />
      </div>
    </Modal>
  );
};

export default DeleteSharedArticleModal;
