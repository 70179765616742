import { ArrowLeft2 } from 'iconsax-react';

import { useAnswersContext } from '../context';

const BackButton = () => {
  const { setSelectedTaskId } = useAnswersContext();
  return (
    <button
      onClick={() => setSelectedTaskId(undefined)}
      className="inline-block p-3 text-focus bg-hover-blue rounded-xl"
    >
      <ArrowLeft2 size={24} />
    </button>
  );
};

export default BackButton;
