import { useEditor, useNode } from '@craftjs/core';
import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import { useDispatch, useScreenBreakpoint, useSelector } from 'app/hooks';
import useIsViewer from 'app/pages/ArticleEditor/hooks/useIsViewer';
import useArticleActionBlocks from 'app/pages/Articles/hooks/useArticleActionBlocks';
import { actions } from 'app/store/comments';
import { selectors as modalSelectors } from 'app/store/modal';
import { isMobile } from 'app/utils';
import { ArrowDown } from 'iconsax-react';
import { ComponentProps, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useParams } from 'react-router-dom';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import {
  ActionTypeEnum,
  MultiChoiceBlockRenderTypeEnum,
} from 'submodules/common-ui/generated/api/gcs';
import { Drawer } from 'vaul';

import PollBlock from '../../Poll';
import QuizBlock from '../../Quiz';
import SimpleTask from '../../SimpleTask';
import YesNoNaBlock from '../../YesNoNa';

import ActionInstances from './components/ActionInstances';
import { EditorOpenQuestionAction } from './components/Answer/OpenQuestionAction';
import { EditorPollAnswerAction } from './components/Answer/PollAnswerAction';
import { EditorQuizAnswerAction } from './components/Answer/QuizAnswerAction';
import { EditorScormAnswerAction } from './components/Answer/ScormAnswerAction';
import { EditorSimpleTaskAnswerAction } from './components/Answer/SimpleTaskAnswerAction';
import { EditorYesNoQuestionAction } from './components/Answer/YesNoAnswerAction';
import { EditorYesNoNaQuestionAction } from './components/Answer/YesNoNaAnswerAction';
import HeaderContainer from './components/HeaderContainer';
import OpenQuestionViewerAction from './components/Viewer/OpenQuestionViewerAction';
import PollAnswerViewerAction from './components/Viewer/PollAnswerViewerAction';
import QuizViewerAction from './components/Viewer/QuizViewerAction';
import ScormViewerAction from './components/Viewer/ScormViewerAction';
import SimpleTaskViewerAction from './components/Viewer/SimpleTaskViewerAction';
import ViewerHeader from './components/Viewer/ViewerHeader';
import YesNoNaQuestionViewerAction from './components/Viewer/YesNoNaQuestionViewerAction';
import YesNoQuestionViewerAction from './components/Viewer/YesNoQuestionViewerAction';
import { CurrentInstanceProvider } from './context';
const ActionsSettings = () => {
  const { mode } = useParams<{ mode: string }>();

  const dispatch = useDispatch();

  const { selectedProps, id } = useNode((node) => ({
    selectedProps: node.data.props as ComponentProps<
      | typeof QuizBlock
      | typeof SimpleTask
      | typeof YesNoNaBlock
      | typeof PollBlock
    >,
  }));

  const { audiences, type, title, description } = selectedProps;

  let renderType = '';

  if ('renderType' in selectedProps) {
    renderType = selectedProps.renderType;
  }

  const {
    nodes,
    actions: { selectNode },
  } = useEditor((state, _) => ({
    nodes: state.nodes,
  }));

  const blocks = useArticleActionBlocks(nodes);
  const completedActions = blocks.map((task) => task.data.props?.completed);

  const { isViewer } = useIsViewer(audiences);
  const { modalType } = useSelector(modalSelectors.getOpenedModals);
  const modalsOpened = useSelector(modalSelectors.getOpenedModals);

  const breakpoint = useScreenBreakpoint();
  const mobile = isMobile(breakpoint);

  useEffect(() => {
    dispatch(actions.hideCommentModal());
  }, [dispatch]);

  const onOutsideClick = () => {
    if (modalType || modalsOpened) return;

    selectNode(undefined);
  };

  const renderMultiChoiceViewerAction = () => {
    // eslint-disable-next-line sonarjs/no-small-switch
    switch (renderType) {
      case MultiChoiceBlockRenderTypeEnum.YesNoNa:
        return <YesNoNaQuestionViewerAction />;
      case MultiChoiceBlockRenderTypeEnum.Poll:
        return <PollAnswerViewerAction />;
      default:
        return (
          <QuizViewerAction
            title={title}
            isMandatory={selectedProps.mandatory}
            description={description}
            choices={
              'choices' in selectedProps ? selectedProps.choices : undefined
            }
          />
        );
    }
  };

  const renderMultiChoiceAnswerAction = () => {
    // eslint-disable-next-line sonarjs/no-small-switch
    switch (renderType) {
      case MultiChoiceBlockRenderTypeEnum.YesNoNa:
        return <EditorYesNoNaQuestionAction />;
      case MultiChoiceBlockRenderTypeEnum.Poll:
        return <EditorPollAnswerAction />;
      default:
        return <EditorQuizAnswerAction />;
    }
  };

  const renderViewerAction = () => {
    switch (type) {
      case ActionTypeEnum.SimpleTask:
      case ActionTypeEnum.MediaTask:
        return <SimpleTaskViewerAction type={type} />;
      case ActionTypeEnum.MultiChoice:
        return renderMultiChoiceViewerAction();
      case ActionTypeEnum.OpenQuestionTask:
        return <OpenQuestionViewerAction />;
      case ActionTypeEnum.YesNoTask:
        return <YesNoQuestionViewerAction />;
      case ActionTypeEnum.ScormTask:
        return <ScormViewerAction />;
      default:
        break;
    }
  };

  const renderAnswerAction = () => {
    switch (type) {
      case 'simple_task':
      case 'media_task':
        return <EditorSimpleTaskAnswerAction />;
      case 'multi_choice':
        return renderMultiChoiceAnswerAction();
      case 'open_question_task':
        return <EditorOpenQuestionAction />;
      case 'yes_no_task':
        return <EditorYesNoQuestionAction />;
      case 'scorm_task':
        return <EditorScormAnswerAction />;
      default:
        break;
    }
  };

  const renderActionSettings = () => (
    <StyledWrapper
      className={cx(
        'flex flex-col w-full lg:w-[450px] md:min-h-[550px] md:mb-[50px] lg:mt-[96px]  bg-hover-blue lg:rounded-xl lg:-translate-x-8',
        {
          'fixed right-0 top-0 bottom-0 overflow-y-scroll overflow-x-hidden z-50':
            !mobile,
          'h-full overflow-auto': mobile,
        }
      )}
    >
      <div>
        {mobile ? (
          <div className="bg-white px-4 py-3.5 flex justify-between items-center">
            <Drawer.Close className="flex justify-center items-center h-10 w-10 bg-white text-grayscale-secondary rounded-lg shadow-atobi">
              <ArrowDown />
            </Drawer.Close>

            <HeaderContainer completedActions={completedActions} />
          </div>
        ) : (
          <div className="bg-white px-4 py-3.5 flex justify-between items-center">
            <button
              className="h-8 w-8 lg:h-10 lg:w-10 flex justify-center items-center rounded bg-white border border-gray-light"
              onClick={() => selectNode(undefined)}
            >
              <CloseLineIcon />
            </button>
            <HeaderContainer completedActions={completedActions} />
          </div>
        )}
      </div>

      <div className="px-6 mb-12">
        {isViewer ? (
          <>
            <ViewerHeader />
            {renderViewerAction()}
          </>
        ) : (
          <>{renderAnswerAction()}</>
        )}
      </div>
      <ActionInstances actionId={id} title={title || description} />
    </StyledWrapper>
  );

  if (mode !== 'actions' || selectedProps.isLocked) return <></>;

  return mobile ? (
    <Drawer.Portal>
      <Drawer.Overlay className="fixed inset-0 bg-grayscale-primary/20" />
      <Drawer.Content className="fixed inset-0 outline-none z-50">
        <CurrentInstanceProvider actionId={id}>
          {renderActionSettings()}
        </CurrentInstanceProvider>
      </Drawer.Content>
    </Drawer.Portal>
  ) : (
    <OutsideClickHandler onOutsideClick={onOutsideClick}>
      <CurrentInstanceProvider actionId={id}>
        {renderActionSettings()}
      </CurrentInstanceProvider>
    </OutsideClickHandler>
  );
};

const StyledWrapper = styled.div`
  & {
    scrollbar-width: thin;
  }

  &::-webkit-scrollbar {
    width: 0px;
  }

  & > div {
    width: 100%;
  }
`;

export default ActionsSettings;
