import { cx } from '@emotion/css';
import SubmitAndCancelButton from 'app/components/SubmitAndCancelButton';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { Minus } from 'iconsax-react';

import { useRecurringArticleContext } from '../context';
import { RecurringArticleDate } from '../types';

import RecurringArticleDropdown from './RecurringArticleDropdown';
import RecurringArticleRepeat from './RecurringArticleRepeat';
import RecurringArticleTime from './RecurringArticleTime';

interface Props {
  onSubmit: (date: RecurringArticleDate) => void;
  onCancel: () => void;
}

const RecurringArticleView = ({ onCancel, onSubmit }: Props) => {
  const { t } = useArticlesTranslation();

  const {
    recurringOptions,
    repeatOptions,
    recurringDate,
    existingRecurringDate,
    updateRecurringDate,
  } = useRecurringArticleContext();

  return (
    <div className="flex flex-col">
      <RecurringArticleDropdown
        options={recurringOptions}
        selectedOption={recurringDate?.recurring}
        onChange={(recurring) => updateRecurringDate({ recurring })}
      />
      <RecurringArticleRepeat
        recurringValue={recurringDate?.recurring.value}
        selectedValue={recurringDate?.repeat}
        options={repeatOptions}
        onChange={(repeat) => updateRecurringDate({ repeat })}
      />
      <div className="flex flex-row">
        <div className="flex flex-col">
          <span className="text-xs text-grayscale-secondary mb-1">
            {t('Action available on')}
          </span>
          <RecurringArticleTime
            selectedDropdownOption={recurringDate?.recurring}
            selection={recurringDate?.start}
            onChange={(start) => updateRecurringDate({ start })}
          />
        </div>
        <div className="flex items-center text-grayscale-secondary mt-4">
          <Minus size={12} />
        </div>
        <div className="flex flex-col">
          <span className="text-xs text-grayscale-secondary mb-1">
            {t('Action deadline')}
          </span>
          <RecurringArticleTime
            selectedDropdownOption={recurringDate?.recurring}
            selection={recurringDate?.end}
            onChange={(end) => updateRecurringDate({ end })}
          />
        </div>
      </div>
      <div className="mb-4">
        <span className="text-xs text-grayscale-secondary">
          {t("(member's local time)")}
        </span>
      </div>
      <SubmitAndCancelButton
        className={cx('mx-auto', {
          'w-[163px]': !existingRecurringDate,
          'w-full': !!existingRecurringDate,
        })}
        isCancelButtonHidden={!existingRecurringDate}
        cancelLabel={t('Delete recurring')}
        submitLabel={t('Add')}
        onCancel={onCancel}
        onSubmit={() => {
          if (recurringDate) onSubmit(recurringDate);
        }}
      />
    </div>
  );
};

export default RecurringArticleView;
