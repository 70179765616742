import { css, cx } from '@emotion/css';
import config from 'app/config';
import { selectors } from 'app/store/editor';
import { useSelector } from 'react-redux';
import { GetChoiceSchema, Text } from 'submodules/common-ui/generated/api/gcs';

import ActionContainer from '../ActionContainer';
import EditorNavigation from '../EditorNavigation';

interface QuizViewerActionProps {
  title?: string;
  description?: string;
  choices?: GetChoiceSchema[];
  isMandatory?: boolean;
}
const QuizViewerAction = ({
  title = '',
  description = '',
  choices,
  isMandatory = true,
}: QuizViewerActionProps) => {
  const currentLanguage = useSelector(selectors.getActiveLanguage);

  return (
    <ActionContainer
      isMandatory={isMandatory}
      canAnswer={false}
      type="multi_choice"
    >
      <div className="flex flex-col">
        <span
          className={cx('text-lg font-bold', css('word-break: break-word;'))}
        >
          {title}
        </span>
        <span className={cx('text-black', css('word-break: break-word;'))}>
          {description}
        </span>
      </div>

      {choices &&
        choices.map((choice, index) => {
          return (
            <div
              key={index}
              className="flex items-center justify-between min-h-10 bg-grayscale-bg-dark border border-gray-dark rounded mt-3 py-[10px] px-4"
            >
              <span className="text-sm text-black">
                {
                  (
                    choice.variants[currentLanguage]?.answer
                      ?.children[0] as Text
                  )?.value
                }
              </span>
              {choice.variants[currentLanguage]?.image?.url && (
                <img
                  className="max-h-10 w-auto"
                  src={`${config.env.gcsApiUrl}/${choice.variants[currentLanguage].image?.url}}`}
                  alt=""
                />
              )}
            </div>
          );
        })}
      <div className="flex justify-between items-center w-full mt-6">
        <EditorNavigation />
      </div>
    </ActionContainer>
  );
};

export default QuizViewerAction;
