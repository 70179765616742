import { cx } from '@emotion/css';

export enum BadgeColor {
  Primary = 'primary',
  Secondary = 'secondary',
  Black = 'black',
  Warning = 'warning',
  Success = 'success',
  Focus = 'focus',
  Error = 'error',
}

interface Props {
  type: BadgeColor;
  title: string;
  titleSize?: '10' | 'xs';
  className?: string;
}

const Badge = ({ title, type, className, titleSize = 'xs' }: Props) => (
  <div className={cx('inline-block relative', className)}>
    <div
      className={cx(
        '-top-2 left-[calc(100%-12px)] flex items-center justify-center h-[22px] min-w-[22px] px-1.5 font-medium rounded-[10px] border-[0.5px]',
        `text-${titleSize}`,
        {
          'text-focus bg-hover-blue border-focus-background':
            type === BadgeColor.Primary,
          'text-ceil bg-hover-blue border-focus-background':
            type === BadgeColor.Secondary,
          'text-white bg-grayscale-primary border-focus-background':
            type === BadgeColor.Black,
          'text-warning bg-warning-white border-warning':
            type === BadgeColor.Warning,
          'text-success bg-success-background border-success':
            type === BadgeColor.Success,
          'text-focus bg-hover-blue border-focus': type === BadgeColor.Focus,
          'text-error bg-error-light border-error': type === BadgeColor.Error,
        }
      )}
    >
      {title}
    </div>
  </div>
);

export default Badge;
