import styled from '@emotion/styled';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { useEffect, useState } from 'react';

import { useAnswersContext } from '../Answers/context';

import GradientText from './GradientText';

const readAnswersPerMinute = 3;

const StyledSkeleton = styled.div`
  background: linear-gradient(270deg, #e8d8ec, #eaeaea, #c48bdf);
  background-size: 600% 600%;

  animation: GradientLoading 5s ease infinite;

  @keyframes GradientLoading {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

interface GeneratingSkeletonProps {
  isGenerating: boolean;
}

const GeneratingSkeleton = ({ isGenerating }: GeneratingSkeletonProps) => {
  const { actionAnswersMeta } = useAnswersContext();
  const [showMessage, setShowMessage] = useState(0);
  const { t } = useArticlesTranslation();

  const messages = [
    t('Reading {{answersCount}} answers...', {
      answersCount: actionAnswersMeta?.total,
    }),
    actionAnswersMeta?.total &&
      t('Saving you {{minutesCount}} minutes...', {
        minutesCount: Math.ceil(actionAnswersMeta.total / readAnswersPerMinute),
      }),
    t('Finding trends...'),
    t('Identifing emotions and opinions...'),
  ];

  useEffect(() => {
    const interval = setTimeout(() => {
      setShowMessage(showMessage === messages.length - 1 ? 0 : showMessage + 1);
    }, 3000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMessage]);

  return (
    <div>
      {isGenerating && (
        <div className="px-4 pt-3">
          <GradientText
            className="text-xs "
            text={`${messages[showMessage]} 🪄`}
          />
        </div>
      )}

      <div className="flex flex-col gap-3 p-4">
        <StyledSkeleton className="h-5 lg:w-11/12 w-full columns-lg rounded-md bg-gradient-to-b from-[#B549D0]/20 to-[#7C48D0]/10"></StyledSkeleton>
        <StyledSkeleton className="h-5 lg:w-10/12 w-full columns-lg rounded-md bg-gradient-to-b from-[#B549D0]/20 to-[#7C48D0]/10 opacity-75"></StyledSkeleton>
        <StyledSkeleton className="h-5 lg:w-9/12 w-full columns-lg rounded-md bg-gradient-to-b from-[#B549D0]/20 to-[#7C48D0]/10 opacity-[0.55]"></StyledSkeleton>
      </div>
    </div>
  );
};

export default GeneratingSkeleton;
