import { useNode } from '@craftjs/core';
import { cx, css } from '@emotion/css';
import useGetArticleBlock from 'app/api/articles/hooks/useGetArticleBlock';
import { useAuthenticatedUser } from 'app/api/auth/hooks';
import config from 'app/config';
import useUnblockNextSection from 'app/hooks/useUnblockNextSection';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import useAnswerActionMutation from 'app/pages/ArticleEditor/hooks/useAnswerActionMutation';
import { actions, selectors } from 'app/store/editor';
import logger from 'app/utils/logger';
import {
  ComponentProps,
  useEffect,
  useState,
  useCallback,
  useMemo,
  FC,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CheckLineIcon from 'remixicon-react/CheckLineIcon';
import {
  DbMultiChoiceAnswer,
  GetChoiceSchema,
  PostActionAnswer,
  Text,
  UserAnswerResponse,
  ActionAnswerResponse,
  ActionTypeEnum,
  MultiChoiceBlockRenderTypeEnum,
} from 'submodules/common-ui/generated/api/gcs';

import QuizBlock from '../../../../Quiz';
import { useCurrentSelectedInstance } from '../../context';
import ActionContainer from '../ActionContainer';
import EditorNavigation from '../EditorNavigation';

import ActionButton from './ActionButton';
import AddOwnAnswerButton from './AddOwnAnswerButton';
import AnswerActionScore, {
  EditorAnswerActionScore,
} from './AnswerActionScore';
import AnswerFeedback from './AnswerFeedback';
import useButtonLabel from './useButtonLabel';
import useDisplayAnswer from './useDisplayAnswer';

interface QuizAnswerActionProps {
  actionId: string;
  selectedProps: ComponentProps<typeof QuizBlock>;
  onAnswer: (data: UserAnswerResponse, actionId: string) => void;
  isEditor?: boolean;
  NavigationContainer: ({
    children,
  }: {
    children: React.ReactNode;
  }) => JSX.Element;
}

const QuizAnswerAction: FC<QuizAnswerActionProps> = ({
  actionId,
  selectedProps,
  onAnswer,
  isEditor = false,
  NavigationContainer,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const { t } = useArticlesTranslation();
  const dispatch = useDispatch();

  const {
    block: contextBlock,
    refetch: contextRefetch,
    currentSelectedInstanceId,
    answeredByColleague,
    colleagueAnswer,
  } = useCurrentSelectedInstance();

  const { data: user } = useAuthenticatedUser();

  const { block, refetch } = useGetArticleBlock({
    articleId: selectedProps.articleId!,
    blockId: parseInt(actionId),
    enabled: !!selectedProps.articleId && !contextBlock,
    timezone: user?.timezone,
    live: '1',
  });

  const activeBlock = contextBlock || block;

  const activeRefetch = useMemo(
    () => (contextBlock ? contextRefetch : refetch),
    [contextBlock, refetch, contextRefetch]
  );

  const currentInstanceId =
    currentSelectedInstanceId ?? activeBlock?.currentInstanceId;

  const currentUserAnswersList = activeBlock?.currentUserAnswers || [];
  const currentInstanceAnswer = currentUserAnswersList.find(
    (currentUserAnswer: ActionAnswerResponse) =>
      currentUserAnswer.actionInstanceId === currentInstanceId
  );

  const isActionCompleted =
    !!currentInstanceAnswer && currentInstanceAnswer.revertedAt === null;

  const [isAddingOwnAnswer, setIsAddingOwnAnswer] = useState(false);

  const displayAnswer = useDisplayAnswer({
    isAddingOwnAnswer,
    isActionCompleted,
    answeredByColleague,
    currentInstanceAnswer,
    colleagueAnswer,
  });

  const {
    mutate: answerAction,
    isLoading,
    isSuccess,
    isError,
    reset,
  } = useAnswerActionMutation();

  const [selectedChoices, setSelectedChoices] = useState<GetChoiceSchema[]>([]);

  const currentLanguage = useSelector(selectors.getActiveLanguage);

  const title = activeBlock?.variants?.[currentLanguage]?.title;
  const description = activeBlock?.variants?.[currentLanguage]?.description;
  const choices = activeBlock?.choices;
  const explanation = activeBlock?.variants?.[currentLanguage]?.explanation;
  const minCorrectAnswers = selectedProps.minCorrectAnswers ?? 1;

  const userAnswerChoices = displayAnswer?.answer as DbMultiChoiceAnswer;
  const isCorrect = userAnswerChoices?.some((uac) => uac.correct);
  const selectedCorrectChoices =
    userAnswerChoices?.filter((uac) => uac.correct)?.length ?? 0;

  const isSubmitButtonDisabled = isLoading || selectedChoices.length === 0;
  const isTooEarly = currentInstanceId === null;

  const shouldShowFeedback =
    (displayAnswer?.score !== undefined &&
      (isActionCompleted || answeredByColleague)) ||
    isSuccess ||
    isError;

  const showIsError =
    isError || (isSuccess && minCorrectAnswers === 0 && !isCorrect);
  const showIsLoop = minCorrectAnswers > 0 && isError;
  const feedbackScore = displayAnswer?.score || 0;

  const completeAction = useCallback(() => {
    if (selectedChoices.length === 0) return;

    const payload: PostActionAnswer = {
      actionId: parseInt(actionId),
      language: currentLanguage,
      actionInstanceId: currentInstanceId,
      answer: selectedChoices.map((sc) => sc.id),
      timezone: user?.timezone,
    };

    answerAction(payload, {
      onError: (e) => logger.error(e),
      onSuccess: (data) => {
        onAnswer(data, actionId);
        dispatch(
          actions.setStats({
            mandatoryProgress: data.mandatoryProgress,
            mandatoryScore: data.totalMandatoryScore,
            progress: data.progress,
            score: data.score,
          })
        );
        reset();
        activeRefetch();
        setIsAddingOwnAnswer(false);
      },
    });
  }, [
    actionId,
    selectedChoices,
    currentInstanceId,
    user?.timezone,
    answerAction,
    currentLanguage,
    dispatch,
    reset,
    onAnswer,
    activeRefetch,
  ]);

  const onChoiceClick = (choice: GetChoiceSchema) => {
    const shouldShowColleagueAnswer = answeredByColleague && !isAddingOwnAnswer;

    if (isActionCompleted || shouldShowColleagueAnswer) {
      return;
    }

    const selected = isSelected(choice);

    reset();

    if (selected) {
      setSelectedChoices((prev) => {
        return prev.filter((p) => p.id !== choice.id);
      });

      return;
    }

    setSelectedChoices((prev) => [...prev, choice]);
  };

  const isSelected = (choice: GetChoiceSchema): boolean => {
    if (!selectedChoices) return false;

    return selectedChoices.some((sc) => sc.id === choice.id);
  };

  const defaultState = (choice: GetChoiceSchema) => {
    return (
      !isSelected(choice) &&
      (!(isActionCompleted || (answeredByColleague && !isAddingOwnAnswer)) ||
        !userAnswerChoices?.some((uac: { id: number }) => uac.id === choice.id))
    );
  };

  const defaultCorrectState = (choice: GetChoiceSchema) => {
    return (
      !isSelected(choice) &&
      choice.correct &&
      !(isActionCompleted || (answeredByColleague && !isAddingOwnAnswer))
    );
  };

  const defaultSelectedState = (choice: GetChoiceSchema) =>
    isSelected(choice) && !isError && !isSuccess;

  const errorAfterSubmit = (choice: GetChoiceSchema) =>
    isSelected(choice) &&
    !choice.correct &&
    (isError || (isSuccess && minCorrectAnswers === 0));

  const defaultErrorState = (choice: GetChoiceSchema) =>
    !isSelected(choice) &&
    (isActionCompleted || (answeredByColleague && !isAddingOwnAnswer)) &&
    !choice.correct &&
    userAnswerChoices?.some((uac: { id: number }) => uac.id === choice.id);

  const defaultSuccessState = (choice: GetChoiceSchema) =>
    (isActionCompleted || (answeredByColleague && !isAddingOwnAnswer)) &&
    choice.correct &&
    (userAnswerChoices?.some((uac: { id: number }) => uac.id === choice.id) ||
      minCorrectAnswers === 0);

  const successAfterSubmit = (choice: GetChoiceSchema) =>
    (isSelected(choice) &&
      choice.correct &&
      (isActionCompleted ||
        isError ||
        (answeredByColleague && !isAddingOwnAnswer))) ||
    (!isSelected(choice) &&
      choice.correct &&
      (isActionCompleted || (answeredByColleague && !isAddingOwnAnswer)) &&
      isSuccess);

  const buttonLabel = useButtonLabel(
    isLoading,
    isSuccess,
    isTooEarly,
    activeBlock?.type
  );

  const renderFooterContent = () => {
    if (isAddingOwnAnswer) {
      return (
        <ActionButton
          onClick={completeAction}
          disabled={isSubmitButtonDisabled || isTooEarly}
          isTooEarly={isTooEarly}
          answer={
            selectedChoices.length > 0
              ? selectedChoices.map((sc) => sc.id).join(', ')
              : ''
          }
          buttonLabel={buttonLabel}
        />
      );
    }

    if (answeredByColleague && !isActionCompleted) {
      return (
        <AddOwnAnswerButton
          answeredByColleague={answeredByColleague}
          isActionCompleted={isActionCompleted}
          onAddAnswer={() => setIsAddingOwnAnswer(true)}
        />
      );
    }

    return (
      !(isActionCompleted || answeredByColleague) && (
        <ActionButton
          onClick={completeAction}
          disabled={isSubmitButtonDisabled || isTooEarly}
          isTooEarly={isTooEarly}
          answer={
            selectedChoices.length > 0
              ? selectedChoices.map((sc) => sc.id).join(', ')
              : ''
          }
          buttonLabel={buttonLabel}
        />
      )
    );
  };

  const renderAnswerActionScore = () => {
    if (isEditor) {
      return <EditorAnswerActionScore totalScore={displayAnswer?.score ?? 0} />;
    }

    return (
      <AnswerActionScore
        selectedProps={selectedProps}
        showQuizScore={false}
        showProgressScore={false}
        totalScore={0}
        blockId={parseInt(actionId)}
      />
    );
  };

  useEffect(() => {
    if (actionId) {
      setSelectedChoices([]);
      reset();
      setIsAddingOwnAnswer(false);
    }
  }, [actionId, reset]);

  const taskCorrectChoices = choices?.filter(
    ({ correct }: GetChoiceSchema) => correct
  ).length;
  const taskSelectedCorrectChoices = selectedChoices.filter(
    ({ correct }: GetChoiceSchema) => correct
  ).length;

  const breakWordStyle = css('word-break: break-word;');
  return (
    <>
      {renderAnswerActionScore()}
      <ActionContainer
        isMandatory={selectedProps.mandatory}
        type={ActionTypeEnum.MultiChoice}
        renderType={MultiChoiceBlockRenderTypeEnum.Quiz}
        canAnswer={true}
        isComplete={isActionCompleted || answeredByColleague}
      >
        <div className="flex flex-col gap-2">
          <span className={cx('text-lg font-bold', breakWordStyle)}>
            {title}
          </span>
          <span className={cx('text-black', breakWordStyle)}>
            {description}
          </span>
        </div>

        {(isActionCompleted || answeredByColleague) &&
          !isError &&
          isSuccess &&
          (displayAnswer?.answer as DbMultiChoiceAnswer)[0].correct && (
            <span className="text-sm text-success mt-3">
              <span className="font-bold">{taskSelectedCorrectChoices}</span>/
              <span className="font-bold">{taskCorrectChoices}</span>{' '}
              {t('is correct')}
            </span>
          )}

        {((!isActionCompleted && !answeredByColleague && isError) ||
          ((isActionCompleted || answeredByColleague) &&
            isSuccess &&
            minCorrectAnswers === 0 &&
            !isCorrect)) && (
          <span className="text-sm text-error mt-3">
            <span className="font-bold">{taskSelectedCorrectChoices}</span>/
            <span className="font-bold">{taskCorrectChoices}</span>{' '}
            {t('is correct')}
          </span>
        )}
        {choices &&
          choices.map((choice: GetChoiceSchema, index: number) => {
            const choiceVariants = choice.variants;
            const variant = choiceVariants?.[currentLanguage]
              ? choiceVariants[currentLanguage]
              : choiceVariants[Object.keys(choiceVariants)[0]];

            return (
              <button
                key={index}
                onClick={() => onChoiceClick(choice)}
                disabled={Boolean(
                  isActionCompleted ||
                    (answeredByColleague && !isAddingOwnAnswer)
                )}
                className={cx(
                  'flex flex-col w-full min-h-10 border rounded mt-3 py-[10px] px-4 relative text-left',
                  {
                    'border-gray-dark bg-grayscale-bg-dark':
                      (defaultState(choice) && !choice.correct) ||
                      defaultCorrectState(choice),
                    'border-focus bg-focus-background':
                      defaultSelectedState(choice),
                    'border-error bg-error-light':
                      errorAfterSubmit(choice) || defaultErrorState(choice),
                    'border-success':
                      defaultSuccessState(choice) || successAfterSubmit(choice),
                    'bg-success-background':
                      (defaultSuccessState(choice) ||
                        successAfterSubmit(choice)) &&
                      (selectedCorrectChoices > 0 || minCorrectAnswers > 0),
                  }
                )}
              >
                <span className={cx('text-black text-sm', breakWordStyle)}>
                  {(variant.answer?.children[0] as Text)?.value}
                </span>
                {variant.image?.url && (
                  <img
                    className="max-h-[210px] w-auto mt-3 rounded self-center"
                    src={`${config.env.gcsApiUrl}/${variant.image?.url}}`}
                    alt=""
                  />
                )}

                {(isActionCompleted ||
                  (answeredByColleague && !isAddingOwnAnswer)) &&
                  choice.correct &&
                  minCorrectAnswers === 0 &&
                  selectedCorrectChoices === 0 && (
                    <div className="absolute flex items-center justify-center w-4 h-4 rounded-full bg-success bottom-0 left-1/2 translate-y-[9px] -translate-x-1/2">
                      <CheckLineIcon className="w-3 h-3 text-white" />
                    </div>
                  )}
              </button>
            );
          })}

        {shouldShowFeedback && (
          <AnswerFeedback
            isSubmitted={isSuccess || isError}
            isError={showIsError}
            isLoop={showIsLoop}
            score={feedbackScore}
            explanation={explanation}
          />
        )}
        <div className="flex justify-between items-center w-full mt-6">
          <NavigationContainer>{renderFooterContent()}</NavigationContainer>
        </div>
      </ActionContainer>
    </>
  );
};

export const EditorQuizAnswerAction = () => {
  const {
    selectedProps,
    nodeId,
    actions: { setProp },
  } = useNode((node) => ({
    selectedProps: node.data.props as ComponentProps<typeof QuizBlock>,
    nodeId: node.id,
  }));
  const { setIsUnblocked } = useUnblockNextSection(nodeId);

  const { data: user } = useAuthenticatedUser();

  if (!user) return null;

  return (
    <QuizAnswerAction
      selectedProps={selectedProps}
      actionId={nodeId}
      onAnswer={(data) => {
        setProp((props: ComponentProps<typeof QuizBlock>) => {
          props.currentUserAnswers = [{ ...data, completedBy: user.id }];
          props.completed = true;
        });

        setIsUnblocked(true);
      }}
      isEditor={true}
      NavigationContainer={EditorNavigation}
    />
  );
};

export default QuizAnswerAction;
