import { SerializedNode } from '@craftjs/core/lib/interfaces/nodes';
import config from 'app/config';
import ArticleLinkBlock from 'app/pages/ArticleEditor/components/blocks/ArticleLinkBlock';
import CategoriesBlock from 'app/pages/ArticleEditor/components/blocks/CategoriesBlock';
import CoverImageBlock from 'app/pages/ArticleEditor/components/blocks/CoverImageBlock';
import FileBlock from 'app/pages/ArticleEditor/components/blocks/FileBlock';
import ImageBlock from 'app/pages/ArticleEditor/components/blocks/ImageBlock';
import PDFBlock from 'app/pages/ArticleEditor/components/blocks/PDFBlock';
import PollBlock from 'app/pages/ArticleEditor/components/blocks/tasks/Poll';
import PollOption from 'app/pages/ArticleEditor/components/blocks/tasks/Poll/Option/PollOption';
import QuizBlock from 'app/pages/ArticleEditor/components/blocks/tasks/Quiz';
import QuizOption from 'app/pages/ArticleEditor/components/blocks/tasks/Quiz/Option/QuizOption';
import ScormBlock from 'app/pages/ArticleEditor/components/blocks/tasks/ScormBlock';
import SimpleTask from 'app/pages/ArticleEditor/components/blocks/tasks/SimpleTask';
import YesNoNaBlock from 'app/pages/ArticleEditor/components/blocks/tasks/YesNoNa';
import YesNoNaOption from 'app/pages/ArticleEditor/components/blocks/tasks/YesNoNa/Option/YesNoNaOption';
import TextBlock from 'app/pages/ArticleEditor/components/blocks/TextBlock';
import TitleBlock from 'app/pages/ArticleEditor/components/blocks/TitleBlock';
import UrlBlock from 'app/pages/ArticleEditor/components/blocks/UrlBlock';
import VideoBlock from 'app/pages/ArticleEditor/components/blocks/VideoBlock';
import uniqueId from 'lodash/uniqueId';
import { ComponentProps } from 'react';
import {
  Article,
  ArticleLinkBlockV1,
  ArticleLinkVariantUnavailable,
  BasicArticleInfo,
  Block,
  FileBlockV1,
  GetYesNoTaskBlockV1,
  ImageBlockV1,
  MediaTaskBlockV1,
  MultiChoiceBlockRenderTypeEnum,
  MultiChoiceBlockV1,
  MultiChoiceBlockV1RenderTypeEnum,
  OpenQuestionTaskBlockV1,
  PdfBlockV1,
  ScormTaskBlockV1,
  SimpleTaskBlockV1,
  TextBlockV1,
  UrlBlockV1,
  VideoBlockV1,
} from 'submodules/common-ui/generated/api/gcs';

import BlockTypes from './constants';

export const SEPARATOR = '_||_';

export type ExtendedSerializedNode = SerializedNode & {
  id: string;
  position: number;
};

const getMultiChoiceBlock = (
  block: MultiChoiceBlockV1,
  variant: string,
  mainVariant?: string
) => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (block.renderType) {
    case MultiChoiceBlockRenderTypeEnum.YesNoNa:
      return getSerializedYesNoNaBlock(block, variant, mainVariant);
    case MultiChoiceBlockRenderTypeEnum.Poll:
      return getSerializedPollBlock(block, variant, mainVariant);
    default:
      return getSerializedQuizBlock(block, variant, mainVariant);
  }
};

const getBlockNodes = (
  block: Block,
  variant: string,
  mainVariant?: string
): Record<string, ExtendedSerializedNode> => {
  if (!block) return {};
  if (!('variants' in block)) return {};

  switch (block.type) {
    case 'open_question_task':
    case 'simple_task':
    case 'media_task':
    case 'yes_no_task':
      return getSerializedTaskBlock(block, variant, mainVariant);
    case 'article_link':
      return getSerializedArticleLinkBlock(block, variant, mainVariant);
    case 'url':
      return getSerializedUrlBlock(block, variant, mainVariant);
    case 'image':
      return getSerializedImageBlock(block, variant, mainVariant);
    case 'video':
      return getSerializedVideoBlock(block, variant, mainVariant);
    case 'text':
      return getSerializedTextBlock(block, variant, mainVariant);
    case 'pdf':
      return getSerializedPDFBlock(block, variant, mainVariant);
    case 'file':
      return getSerializedFileBlock(block, variant, mainVariant);
    case 'multi_choice':
      return getMultiChoiceBlock(block, variant, mainVariant);
    case 'scorm_task':
      return getSerializedScormBlock(block, variant, mainVariant);
    default:
      return {};
  }
};

const getSerializedUrlBlock = (
  block: UrlBlockV1,
  variant: string,
  mainVariant?: string
): Record<string, ExtendedSerializedNode> => {
  const language = mainVariant ?? variant;
  const props: ComponentProps<typeof UrlBlock> = {
    name: block.variants[language].name,
    url: block.variants[language].url,
    isValidUrl: true,
  };

  return {
    [block.id]: {
      parent: 'dropableRegion',
      id: block.id.toString(),
      position: block.position,
      type: { resolvedName: BlockTypes.UrlBlock },
      displayName: BlockTypes.UrlBlock,
      hidden: false,
      isCanvas: false,
      nodes: [],
      linkedNodes: {},
      props: props,
    },
  };
};

const getSerializedImageBlock = (
  block: ImageBlockV1,
  variant: string,
  mainVariant?: string
): Record<string, ExtendedSerializedNode> => {
  const language = mainVariant ?? variant;

  const imageSchema = block.variants[language];

  const props: ComponentProps<typeof ImageBlock> = {
    translationStatus: block.variants?.[language]?.translationStatus ?? 'draft',
  };

  if (imageSchema.type === 'internal') {
    props.imageSchema = {
      url: `${config.env.gcsApiUrl}/${imageSchema.url}`,
      name: imageSchema.name,
      type: imageSchema.type,
      tenantId: imageSchema.tenantId,
      id: imageSchema.id,
      translationStatus: 'draft',
      directUrls: imageSchema.directUrls,
    };
  } else {
    props.imageSchema = {
      url: imageSchema.url,
      name: imageSchema.name,
      type: imageSchema.type,
      translationStatus: 'draft',
    };
  }

  const type = imageSchema.url.match(/^https:\/\/media\d+\.giphy\.com/)
    ? BlockTypes.GiphyBlock
    : BlockTypes.ImageBlock;

  return {
    [block.id]: {
      parent: 'dropableRegion',
      id: block.id.toString(),
      position: block.position,
      type: { resolvedName: type },
      displayName: type,
      hidden: false,
      isCanvas: false,
      nodes: [],
      linkedNodes: {},
      props,
    },
  };
};

const getSerializedVideoBlock = (
  block: VideoBlockV1,
  variant: string,
  mainVariant?: string
): Record<string, ExtendedSerializedNode> => {
  const language = mainVariant ?? variant;
  const videoSchema = block.variants[language];

  const props: ComponentProps<typeof VideoBlock> = {
    translationStatus: block.variants?.[language]?.translationStatus ?? 'draft',
  };

  if (videoSchema.type === 'internal') {
    props.videoSchema = {
      url: `${config.env.gcsApiUrl}/${videoSchema.url}`,
      name: videoSchema.name,
      type: videoSchema.type,
      tenantId: videoSchema.tenantId,
      id: videoSchema.id,
      translationStatus: 'draft',
    };
  } else {
    props.videoSchema = {
      url: videoSchema.url,
      name: videoSchema.name,
      type: videoSchema.type,
      translationStatus: 'draft',
    };
  }

  return {
    [block.id]: {
      parent: 'dropableRegion',
      id: block.id.toString(),
      position: block.position,
      type: { resolvedName: BlockTypes.VideoBlock },
      displayName: BlockTypes.VideoBlock,
      hidden: false,
      isCanvas: false,
      nodes: [],
      linkedNodes: {},
      props,
    },
  };
};

const getSerializedPDFBlock = (
  block: PdfBlockV1,
  variant: string,
  mainVariant?: string
): Record<string, ExtendedSerializedNode> => {
  const language = mainVariant ?? variant;

  const pdfSchema = block.variants[language];

  const props: ComponentProps<typeof PDFBlock> = {
    translationStatus: block.variants?.[language]?.translationStatus ?? 'draft',
  };
  if (pdfSchema.type === 'internal') {
    props.pdfSchema = {
      directUrls: pdfSchema.directUrls,
      url: `${config.env.gcsApiUrl}/${pdfSchema.url}`,
      name: pdfSchema.name,
      type: pdfSchema.type,
      id: pdfSchema.id,
      translationStatus: 'draft',
      tenantId: pdfSchema.tenantId,
    };
  } else {
    props.pdfSchema = {
      url: pdfSchema.url,
      name: pdfSchema.name,
      type: pdfSchema.type,
      translationStatus: 'draft',
    };
  }

  return {
    [block.id]: {
      parent: 'dropableRegion',
      id: block.id.toString(),
      position: block.position,
      type: { resolvedName: BlockTypes.PDFBlock },
      displayName: BlockTypes.PDFBlock,
      hidden: false,
      isCanvas: false,
      nodes: [],
      linkedNodes: {},
      props,
    },
  };
};

const getSerializedFileBlock = (
  block: FileBlockV1,
  variant: string,
  mainVariant?: string
): Record<string, ExtendedSerializedNode> => {
  const language = mainVariant ?? variant;
  const fileSchema = block.variants[language];
  const props: ComponentProps<typeof FileBlock> = {
    translationStatus: block.variants?.[language]?.translationStatus ?? 'draft',
    fileSchema: {
      ...fileSchema,
      url: `${config.env.gcsApiUrl}/${fileSchema.url}`,
    },
  };

  return {
    [block.id]: {
      parent: 'dropableRegion',
      id: block.id.toString(),
      position: block.position,
      type: { resolvedName: BlockTypes.FileBlock },
      displayName: BlockTypes.FileBlock,
      hidden: false,
      isCanvas: false,
      nodes: [],
      linkedNodes: {},
      props,
    },
  };
};

const getSerializedTaskBlock = (
  block:
    | SimpleTaskBlockV1
    | MediaTaskBlockV1
    | OpenQuestionTaskBlockV1
    | GetYesNoTaskBlockV1,
  variant: string,
  mainVariant?: string
): Record<string, ExtendedSerializedNode> => {
  const language = mainVariant ?? variant;

  const props: ComponentProps<typeof SimpleTask> = {
    audiences: block.audiences,
    public: block.public,
    mandatory: block.mandatory,
    required: block.required,
    deadline: block.deadline ?? undefined,
    categories: block.categories,
    description: block.variants[language]?.description,
    title: block.variants[language]?.title,
    type: block.type,
    translationStatus: block.variants?.[language]?.translationStatus ?? 'draft',
    currentUserAnswers: block.currentUserAnswers,
    completed: block.completed,
    schedule: block.schedule,
  };

  return {
    [block.id]: {
      parent: 'dropableRegion',
      id: block.id.toString(),
      position: block.position,
      type: { resolvedName: BlockTypes.SimpleTaskBlock },
      displayName: BlockTypes.SimpleTaskBlock,
      hidden: false,
      isCanvas: false,
      nodes: [],
      linkedNodes: {},
      props,
    },
  };
};

const getSerializedQuizBlock = (
  block: MultiChoiceBlockV1,
  variant: string,
  mainVariant?: string
): Record<string, ExtendedSerializedNode> => {
  const language = mainVariant ?? variant;

  const props: ComponentProps<typeof QuizBlock> = {
    audiences: block.audiences,
    public: block.public,
    mandatory: block.mandatory,
    required: block.required,
    deadline: block.deadline ?? undefined,
    description: block.variants[language]?.description,
    type: block.type,
    categories: block.categories,
    explanation: block.variants[language]?.explanation,
    title: block.variants[language]?.title,
    minCorrectAnswers: block.minCorrectAnswers,
    translationStatus: block.variants?.[language]?.translationStatus ?? 'draft',
    choices: block.choices,
    currentUserAnswers: block.currentUserAnswers,
    completed: block.completed,
    renderType: MultiChoiceBlockV1RenderTypeEnum.Quiz,
    schedule: block.schedule,
  };

  const optionsContainerId = uniqueId();

  const choices = block.choices.reduce<Record<string, ExtendedSerializedNode>>(
    (acc, bc) => {
      const optionProps: ComponentProps<typeof QuizOption> = {
        checked: bc.correct ?? undefined,
        text: bc.variants[language]?.answer,
        imageSchema: {
          id: bc.variants[language]?.image?.id ?? '',
          name: bc.variants[language]?.image?.name ?? '',
          url: bc.variants[language]?.image?.url ?? '',
          translationStatus:
            bc.variants[language]?.image?.translationStatus ?? 'draft',
          type: 'internal',
          tenantId: bc.variants[language]?.image?.tenantId ?? -1,
          directUrls: bc.variants[language]?.image?.directUrls,
          message: bc.variants[language]?.image?.message,
        },
      };
      const id = `${block.id}${SEPARATOR}${bc.id}`;
      return {
        ...acc,
        [id]: {
          id,
          position: 0,
          displayName: 'QuizOption',
          hidden: false,
          isCanvas: false,
          nodes: [],
          linkedNodes: {},
          props: optionProps,
          parent: optionsContainerId,
          type: { resolvedName: 'QuizOption' },
        },
      };
    },
    {}
  );

  return {
    ...choices,
    [optionsContainerId]: {
      id: optionsContainerId,
      position: 0,
      displayName: 'optionsContainer',
      hidden: false,
      isCanvas: true,
      nodes: block.choices.map((c) => `${block.id}${SEPARATOR}${c.id}`),
      linkedNodes: {},
      props: {},
      parent: block.id.toString(),
      type: { resolvedName: 'QuizOptionsContainer' },
    },

    [block.id]: {
      parent: 'dropableRegion',
      id: block.id.toString(),
      position: block.position,
      type: { resolvedName: BlockTypes.QuizBlock },
      displayName: BlockTypes.QuizBlock,
      hidden: false,
      isCanvas: false,
      nodes: [],
      linkedNodes: { optionsContainer: optionsContainerId },
      props,
    },
  };
};

const getSerializedYesNoNaBlock = (
  block: MultiChoiceBlockV1,
  variant: string,
  mainVariant?: string
): Record<string, ExtendedSerializedNode> => {
  const language = mainVariant ?? variant;

  const props: ComponentProps<typeof YesNoNaBlock> = {
    audiences: block.audiences,
    public: block.public,
    mandatory: block.mandatory,
    required: block.required,
    deadline: block.deadline ?? undefined,
    description: block.variants[language]?.description,
    type: block.type,
    renderType: MultiChoiceBlockRenderTypeEnum.YesNoNa,
    categories: block.categories,
    explanation: block.variants[language]?.explanation,
    title: block.variants[language]?.title,
    minCorrectAnswers: block.minCorrectAnswers,
    translationStatus: block.variants?.[language]?.translationStatus ?? 'draft',
    choices: block.choices,
    currentUserAnswers: block.currentUserAnswers,
    completed: block.completed,
    schedule: block.schedule,
  };

  const optionsContainerId = uniqueId();

  const choices = block.choices.reduce<Record<string, ExtendedSerializedNode>>(
    (acc, bc) => {
      const optionProps: ComponentProps<typeof YesNoNaOption> = {
        correct: bc.correct,
        text: bc.variants[language]?.answer,
      };
      const id = `${block.id}${SEPARATOR}${bc.id}`;
      return {
        ...acc,
        [id]: {
          id,
          position: 0,
          displayName: 'YesNoNaOption',
          hidden: false,
          isCanvas: false,
          nodes: [],
          linkedNodes: {},
          props: optionProps,
          parent: optionsContainerId,
          type: { resolvedName: 'YesNoNaOption' },
        },
      };
    },
    {}
  );

  return {
    ...choices,
    [optionsContainerId]: {
      id: optionsContainerId,
      position: 0,
      displayName: 'optionsContainer',
      hidden: false,
      isCanvas: true,
      nodes: block.choices.map((c) => `${block.id}${SEPARATOR}${c.id}`),
      linkedNodes: {},
      props: {},
      parent: block.id.toString(),
      type: { resolvedName: 'YesNoNaOptionsContainer' },
    },

    [block.id]: {
      parent: 'dropableRegion',
      id: block.id.toString(),
      position: block.position,
      type: { resolvedName: BlockTypes.YesNoNaBlock },
      displayName: BlockTypes.YesNoNaBlock,
      hidden: false,
      isCanvas: false,
      nodes: [],
      linkedNodes: { optionsContainer: optionsContainerId },
      props,
    },
  };
};

const getSerializedPollBlock = (
  block: MultiChoiceBlockV1,
  variant: string,
  mainVariant?: string
): Record<string, ExtendedSerializedNode> => {
  const language = mainVariant ?? variant;

  const props: ComponentProps<typeof PollBlock> = {
    audiences: block.audiences,
    public: block.public,
    mandatory: block.mandatory,
    required: block.required,
    deadline: block.deadline ?? undefined,
    categories: block.categories,
    description: block.variants[language]?.description,
    type: block.type,
    title: block.variants[language]?.title,
    maxSelectedChoices: block.maxSelectedChoices,
    translationStatus: block.variants[language]?.translationStatus,
    choices: block.choices,
    currentUserAnswers: block.currentUserAnswers,
    completed: block.completed,
    renderType: MultiChoiceBlockRenderTypeEnum.Poll,
    schedule: block.schedule,
  };

  const optionsContainerId = uniqueId();

  const choices = block.choices.reduce<Record<string, ExtendedSerializedNode>>(
    (acc, bc) => {
      const optionProps: ComponentProps<typeof PollOption> = {
        text: bc.variants[language]?.answer,
        imageSchema: {
          id: bc.variants[language]?.image?.id ?? '',
          name: bc.variants[language]?.image?.name ?? '',
          url: bc.variants[language]?.image?.url ?? '',
          translationStatus:
            bc.variants[language]?.image?.translationStatus ?? 'draft',
          type: 'internal',
          tenantId: bc.variants[language]?.image?.tenantId ?? -1,
        },
      };
      const id = `${block.id}${SEPARATOR}${bc.id}`;
      return {
        ...acc,
        [id]: {
          id,
          position: 0,
          displayName: 'PollOption',
          hidden: false,
          isCanvas: false,
          nodes: [],
          linkedNodes: {},
          props: optionProps,
          parent: optionsContainerId,
          type: { resolvedName: 'PollOption' },
        },
      };
    },
    {}
  );

  return {
    ...choices,
    [optionsContainerId]: {
      id: optionsContainerId,
      position: 0,
      displayName: 'pollOptionsContainer',
      hidden: false,
      isCanvas: true,
      nodes: block.choices.map((c) => `${block.id}${SEPARATOR}${c.id}`),
      linkedNodes: {},
      props: {},
      parent: block.id.toString(),
      type: { resolvedName: 'PollOptionsContainer' },
    },

    [block.id]: {
      parent: 'dropableRegion',
      id: block.id.toString(),
      position: block.position,
      type: { resolvedName: BlockTypes.PollBlock },
      displayName: BlockTypes.PollBlock,
      hidden: false,
      isCanvas: false,
      nodes: [],
      linkedNodes: { pollOptionsContainer: optionsContainerId },
      props,
    },
  };
};

const getSerializedArticleLinkBlock = (
  block: ArticleLinkBlockV1,
  variant: string,
  mainVariant?: string
): Record<string, ExtendedSerializedNode> => {
  const language = mainVariant ?? variant;
  const props: ComponentProps<typeof ArticleLinkBlock> = {
    articleLink: { ...block.variants[language] } as unknown as BasicArticleInfo,
    unavailable:
      !block.variants?.[language] ||
      !!(block.variants[language] as ArticleLinkVariantUnavailable)?.message,
  };

  return {
    [block.id]: {
      parent: 'dropableRegion',
      id: block.id.toString(),
      position: block.position,
      type: { resolvedName: BlockTypes.ArticleLinkBlock },
      displayName: BlockTypes.ArticleLinkBlock,
      hidden: false,
      isCanvas: false,
      nodes: [],
      linkedNodes: {},
      props,
    },
  };
};

const getSerializedTextBlock = (
  block: TextBlockV1,
  variant: string,
  mainVariant?: string
): Record<string, ExtendedSerializedNode> => {
  const language = mainVariant ?? variant;
  const props: ComponentProps<typeof TextBlock> = {
    nodes: block.variants[language],
    translationStatus: block.variants?.[language]?.translationStatus ?? 'draft',
    position: block.position,
  };

  return {
    [block.id]: {
      parent: 'dropableRegion',
      id: block.id.toString(),
      position: block.position,
      type: { resolvedName: BlockTypes.TextBlock },
      displayName: BlockTypes.TextBlock,
      hidden: false,
      isCanvas: false,
      nodes: [],
      linkedNodes: {},
      props,
    },
  };
};

const getSerializedTitleBlock = (
  variant: string,
  mainVariant?: string,
  article?: Article
): Record<string, ExtendedSerializedNode> => {
  const language = mainVariant ?? variant;
  const props: ComponentProps<typeof TitleBlock> = {
    text: article?.variants[language]?.title ?? '',
  };

  return {
    ['title']: {
      parent: 'staticBlock',
      id: 'title',
      position: 1,
      type: { resolvedName: BlockTypes.TitleBlock },
      displayName: BlockTypes.TitleBlock,
      hidden: false,
      isCanvas: false,
      nodes: [],
      linkedNodes: {},
      props,
    },
  };
};

const getSerializedCategoriesBlock = (
  article?: Article
): Record<string, ExtendedSerializedNode> => {
  const props: ComponentProps<typeof CategoriesBlock> = {
    article,
  };

  return {
    ['categories']: {
      parent: 'staticBlock',
      id: 'categories',
      position: 2,
      type: { resolvedName: BlockTypes.CategoriesBlock },
      displayName: BlockTypes.CategoriesBlock,
      hidden: false,
      isCanvas: false,
      nodes: [],
      linkedNodes: {},
      props,
    },
  };
};

const getSerializedCoverImageBlock = (
  variant: string,
  mainVariant?: string,
  article?: Article
): Record<string, ExtendedSerializedNode> => {
  const language = mainVariant ?? variant;
  const props: ComponentProps<typeof CoverImageBlock> = {};

  if (article && article.variants[language]?.coverImage) {
    props.imageSchema = {
      ...article.variants[language].coverImage,
      url:
        article.variants[language].coverImage.type === 'internal'
          ? `${config.env.gcsApiUrl}/${article.variants[language].coverImage.url}`
          : article.variants[language].coverImage.url,
    };
  }

  return {
    ['coverImage']: {
      parent: 'staticBlock',
      id: 'coverImage',
      position: 0,
      type: { resolvedName: BlockTypes.CoverImageBlock },
      displayName: BlockTypes.CoverImageBlock,
      hidden: false,
      isCanvas: false,
      nodes: [],
      linkedNodes: {},
      props,
    },
  };
};

const getSerializedScormBlock = (
  block: ScormTaskBlockV1,
  variant: string,
  mainVariant?: string
): Record<string, ExtendedSerializedNode> => {
  const language = mainVariant ?? variant;

  const props: ComponentProps<typeof ScormBlock> = {
    audiences: block.audiences,
    public: block.public,
    mandatory: block.mandatory,
    required: block.required,
    deadline: block.deadline ?? undefined,
    categories: block.categories,
    description: block.variants[language]?.description,
    title: block.variants[language]?.title,
    type: block.type,
    translationStatus: block.variants?.[language]?.translationStatus ?? 'draft',
    currentUserAnswers: block.currentUserAnswers,
    completed: block.completed,
    image: block.variants?.[language]?.image,
    package: block.variants?.[language]?.package,
    courseStatus: block.variants?.[language]?.courseStatus,
    articleId: block.articleId,
    schedule: block.schedule,
  };

  return {
    [block.id]: {
      parent: 'dropableRegion',
      id: block.id.toString(),
      position: block.position,
      type: { resolvedName: BlockTypes.ScormBlock },
      displayName: BlockTypes.ScormBlock,
      hidden: false,
      isCanvas: false,
      nodes: [],
      linkedNodes: {},
      props,
    },
  };
};

export {
  getBlockNodes,
  getSerializedCoverImageBlock,
  getSerializedTitleBlock,
  getSerializedCategoriesBlock,
};

export default getBlockNodes;
