import { Response } from 'app/api/types';

import { request } from '../../request';
import { LocationResponse } from '../types';

const getLocations = () =>
  request().get<Response<Array<LocationResponse>>>(
    `/api/v1/locations/user/locationsWithPermission`
  );

export { getLocations };
export default getLocations;
