import { by639_1 } from 'iso-language-codes';

/*
    Usage of the hacky constant below has been approved by CTO.
    Unfortunately, due to incompatibilities with current version 
    on Laravel API, some languages are provided as country code names and require re-mapping.
*/
export const COUNTRY_TO_LANGUAGE_MAP = new Map([
  ['gr', 'el'],
  ['dk', 'da'],
  ['cz', 'cs'],
  ['fl', 'nl'],
  ['ua', 'uk'],
]);

export function remapApiLanguageCodeToGcsLanguage(
  apiLanguageCode: string
): string {
  apiLanguageCode = apiLanguageCode.toLocaleLowerCase().trim();

  if (apiLanguageCode === 'se') apiLanguageCode = 'sv';

  if (typeof by639_1[apiLanguageCode as keyof typeof by639_1] !== 'undefined') {
    return by639_1[apiLanguageCode as keyof typeof by639_1].iso639_1;
  }

  return COUNTRY_TO_LANGUAGE_MAP.get(apiLanguageCode) ?? 'en';
}
