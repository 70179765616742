import dayjs from './dayjs';
import removeTimezone from './removeTimezone';

const formatDate = (date?: string | null, format?: string): string => {
  if (!date) return '-';

  return dayjs(removeTimezone(date)).format(format ?? 'DD.MM.YYYY');
};

export default formatDate;
