import { useQuery } from 'react-query';

import { resources, queryKeys } from '../index';
import { GetAudienceUsersProps } from '../resources/getAudienceUsersIds';

const useGetAudienceUsersIdsQuery = (params: GetAudienceUsersProps) => {
  const { id, locations, professions } = params;
  const { data, isLoading, error, isFetching, refetch } = useQuery(
    queryKeys.audienceUsersIds(id, locations, professions),
    () => resources.getAudienceUsersIds(params),
    {
      enabled: id !== undefined,
      refetchOnWindowFocus: false,
    }
  );

  return {
    data: data?.data.data.ids,
    isFetching,
    isLoading,
    error,
    refetch,
  };
};

export default useGetAudienceUsersIdsQuery;
