import { request } from 'app/api/gcsRequest';
import config from 'app/config';
import { ActionAnswerApi, PostActionAnswer } from 'common-ui/generated/api/gcs';

const answerAction = (answer: PostActionAnswer) =>
  new ActionAnswerApi(undefined, config.env.gcsApiUrl, request())
    .postActionAnswer(answer)
    .then((response) => response.data);

export { answerAction };
export default answerAction;
