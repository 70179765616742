import { css, cx } from '@emotion/css';
import { CloseCircle, SearchNormal1, Icon as IconType } from 'iconsax-react';
import { InputHTMLAttributes, ReactNode, useRef, useState } from 'react';
import { UseFormRegister } from 'react-hook-form';

interface SearchProps extends InputHTMLAttributes<HTMLInputElement> {
  placeholder: string;
  isSearch?: boolean;
  searchIconSize?: number;
  Icon?: IconType;
  IconTrail?: ReactNode;
  onClear?: VoidFunction;
  register?: UseFormRegister<any>;
}

const Input2 = ({
  placeholder,
  isSearch,
  onClear,
  onChange,
  register,
  name,
  className,
  Icon,
  IconTrail,
  searchIconSize,
  ...rest
}: SearchProps) => {
  const [value, setValue] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);

  const triggerInputChange = () => {
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      'value'
    )?.set;
    if (!nativeInputValueSetter || !inputRef.current) return;

    nativeInputValueSetter.call(inputRef.current, '');

    const ev = new Event('input', { bubbles: true });
    inputRef.current.dispatchEvent(ev);
  };

  return (
    <div className="w-full rounded-xl relative">
      {isSearch && (
        <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
          <SearchNormal1
            size={searchIconSize ?? 16}
            className="text-gray-light-blue"
          />
        </div>
      )}
      {Icon && (
        <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
          <Icon className="text-gray-light-blue" />
        </div>
      )}
      {value !== '' && onClear && (
        <button
          className="flex absolute inset-y-0 right-0 items-center mr-4"
          onClick={(e) => {
            e.stopPropagation();
            triggerInputChange();
            onClear();
            setValue('');
          }}
        >
          <CloseCircle
            variant="Bold"
            className="w-5 h-5 text-grayscale-secondary"
          />
        </button>
      )}
      <input
        {...rest}
        {...(register && name ? register(name) : {})}
        {...(!register
          ? {
              onChange: (e) => {
                onChange?.(e);
                setValue(e.target.value);
              },
              ref: inputRef,
            }
          : {})}
        type={isSearch ? 'search' : 'text'}
        className={cx(
          className,
          'w-full flex-1 px-4 py-3.5 text-sm rounded-xl bg-grayscale-bg-dark outline outline-gray-white hover:outline-gray-silver focus-visible:outline-focus',
          { 'pl-10': isSearch || !!Icon, 'pr-10': !!IconTrail },
          css(`
              &&::-webkit-search-decoration,
              &&::-webkit-search-cancel-button,
              &&::-webkit-search-results-button,
              &&::-webkit-search-results-decoration {
            -webkit-appearance:none;
          }`)
        )}
        placeholder={placeholder}
      />
      {IconTrail && (
        <div className="flex absolute inset-y-0 right-3.5 items-center pl-3">
          {IconTrail}
        </div>
      )}
    </div>
  );
};

export default Input2;
