import { request } from 'app/api/gcsRequest';
import config from 'app/config';
import {
  RelevantChannelApi,
  SortOrderEnum,
} from 'submodules/common-ui/generated/api/gcs';

const getRelevantChannels = (
  page: number,
  ranking: SortOrderEnum,
  timeZone: string
) =>
  new RelevantChannelApi(
    undefined,
    config.env.gcsApiUrl,
    request()
  ).getRelevantChannelPage(timeZone, page, 20, ranking, {});

export { getRelevantChannels };
export default getRelevantChannels;
