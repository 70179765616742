import { cx } from '@emotion/css';
import { useCommonTranslation } from 'app/internationalization/hooks';
import { Icon as IconType } from 'iconsax-react';

import Tooltip from '../Tooltip';

export interface DropdownItemProps {
  text?: string;
  description?: string;
  Icon?: IconType;
  disabled?: boolean;
  tag?: boolean;
  showTooltip?: boolean;
  tooltipText?: string;
  onClick?: VoidFunction;
}

const DropdownItem = ({
  text,
  Icon,
  disabled = false,
  tag,
  description,
  showTooltip = false,
  tooltipText = '',
  onClick,
}: DropdownItemProps) => {
  const { t } = useCommonTranslation();

  return (
    <Tooltip content={tooltipText} fixedWidth centerText show={showTooltip}>
      <button
        className={cx(
          'text-sm w-full py-2 px-3 font-normal flex items-center whitespace-nowrap text-grayscale-primary',
          { 'bg-gray-light': disabled },
          { 'hover:bg-hover-blue': !disabled }
        )}
        onClick={(e) => {
          e.stopPropagation();
          onClick?.();
        }}
        disabled={disabled}
      >
        {Icon && <Icon className="mr-3" size={16} />}
        <div className="text-left flex flex-col ">
          <span>{text}</span>
          {tag && (
            <div className="flex bg-hover-blue px-1 py-1 ml-2 mr-4 rounded-sm">
              <span className="text-xs text-focus">{t('Coming Soon')}</span>
            </div>
          )}
          {description && (
            <span
              className={cx('text-sm', {
                'text-grayscale-secondary': !disabled,
              })}
            >
              {description}
            </span>
          )}
        </div>
      </button>
    </Tooltip>
  );
};

export default DropdownItem;
