import { request } from 'app/api/gcsRequest';
import config from 'app/config';
import {
  RusticiRegistrationApi,
  ScormRegistrationRequest,
} from 'common-ui/generated/api/gcs';

const addScormRegistration = (scormRegistration: ScormRegistrationRequest) =>
  new RusticiRegistrationApi(undefined, config.env.gcsApiUrl, request())
    .addRegistration(scormRegistration)
    .then((response) => response.data);

export { addScormRegistration };
export default addScormRegistration;
