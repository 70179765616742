import { cx } from '@emotion/css';
import Spinner from 'assets/icons/spinner.svg?react';
import { forwardRef, useEffect, useState } from 'react';

const Image = forwardRef<HTMLImageElement, { src: string }>(({ src }, ref) => {
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => setHasLoaded(false), [src]);

  return (
    <>
      {!hasLoaded && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <Spinner className="w-10 h-10 text-white animate-spin" />
        </div>
      )}
      <img
        ref={ref}
        className={cx(
          'max-h-[80vh] max-w-[85vw] mt-8 rounded-xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2',
          { hidden: !hasLoaded }
        )}
        src={src}
        alt="Answer large"
        onLoad={() => setHasLoaded(true)}
        onError={() => setHasLoaded(true)}
      />
    </>
  );
});

export default Image;
