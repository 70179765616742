import { UserSquare, LocationTick, CalendarCircle } from 'iconsax-react';

interface UserInfoProps {
  userAvatar: string;
  userName: string;
  locationName: string;
  createdAt: string;
}

const UserInfo = ({
  userAvatar,
  userName,
  locationName,
  createdAt,
}: UserInfoProps) => {
  return (
    <div className="flex w-1/3 justify-center">
      <div className="inline-flex items-center p-3 bg-grayscale-secondary rounded-lg text-white text-xs">
        <div className="flex items-center border-r border-white mr-1">
          {userAvatar ? (
            <img className="w-4 h-4 rounded-sm" src={userAvatar} alt="User" />
          ) : (
            <UserSquare size={16} />
          )}
          <span className="text-sm font-bold mx-1 capitalize">{userName}</span>
        </div>
        <LocationTick size={14} />
        <span className="mx-1">{locationName}</span>
        •
        <CalendarCircle size={14} className="mx-1" />
        <span>{createdAt.replaceAll('/', '.')}</span>
      </div>
    </div>
  );
};

export default UserInfo;
