import { cx } from '@emotion/css';
import { ArticleCollaborator } from 'app/api/articleCollaborators/types';
import config from 'app/config';
import { Collaborator } from 'app/store/editor/types';
import CheckLineIcon from 'remixicon-react/CheckLineIcon';

interface Props {
  collaborator: ArticleCollaborator;
  languageCollaborators: Collaborator[];
  assignCollaborator: (collaborator: ArticleCollaborator) => void;
}

const CollaboratorItem = ({
  collaborator,
  languageCollaborators,
  assignCollaborator,
}: Props) => {
  const isCollaboratorLanguageCollaborator = languageCollaborators.some(
    ({ id }) => id === collaborator.id
  );

  return (
    <button
      onClick={() => assignCollaborator(collaborator)}
      disabled={isCollaboratorLanguageCollaborator}
      className={cx(
        'flex items-center justify-between w-full hover:bg-focus-background p-3',
        {
          'bg-focus-background': isCollaboratorLanguageCollaborator,
        }
      )}
    >
      <div className="flex">
        <img
          className="rounded w-8 h-8"
          src={collaborator.avatars.small}
          alt={collaborator.fullName}
        />
        <div className="flex flex-col justify-center min-w-0 ml-3 max-w-[170px]">
          <div className="flex mb-0.5">
            <span className="text-sm text-ellipsis whitespace-nowrap overflow-hidden ">
              {collaborator.fullName}
            </span>
          </div>
          <div className="flex mb-0.5">
            <span className="text-sm text-grayscale-secondary text-ellipsis whitespace-nowrap overflow-hidden ">
              {collaborator.profession.name} in {collaborator.location.name}
            </span>
          </div>
        </div>
      </div>
      {isCollaboratorLanguageCollaborator && (
        <CheckLineIcon size={20} color={config.colors.focus} />
      )}
    </button>
  );
};

export default CollaboratorItem;
