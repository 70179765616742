import { useAuthenticatedUser } from 'app/api/auth/hooks';
import { InfiniteScroll } from 'app/components';
import { useModulesTranslation } from 'app/internationalization/hooks';
import { routes } from 'app/router';
import { getChannelTitle } from 'app/utils';
import { Notepad2, TaskSquare, Clipboard } from 'iconsax-react';
import { BasicRelevantChannelInfo } from 'submodules/common-ui/generated/api/gcs';

import { Link } from '../types';

import ChannelLink from './ChannelLink';

interface Props {
  isClipped: boolean;
  channels: BasicRelevantChannelInfo[];
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  badgeData: {
    [x: string]: number | undefined;
  };
}

const ChannelLinks = ({
  badgeData,
  isClipped,
  channels,
  fetchNextPage,
  hasNextPage,
}: Props) => {
  const { t } = useModulesTranslation();
  const { data: user } = useAuthenticatedUser();

  const getChannelsNavLinks = (): Array<Link> => {
    const upcomingActionsNavLink: Link = {
      title: t('Upcoming Actions'),
      Icon: () => <TaskSquare size={24} />,
      to: routes.upcomingActions.create(),
      feature: 'channelsView',
    };

    if (!channels?.length) return [upcomingActionsNavLink];

    const channelsLinks: Link[] = channels.map((channel) => ({
      title: getChannelTitle(channel, user?.contentLanguage.uiLanguage),
      Icon:
        channel.shownAs === 'feed'
          ? () => <Clipboard size={24} />
          : () => <Notepad2 size={24} />,
      to: routes.relevantChannel.create(channel.id),
      isActive: (route: string) => route === `/relevant-channel/${channel.id}`,
      feature: 'social',
    }));

    return [upcomingActionsNavLink, ...channelsLinks];
  };

  return (
    <InfiniteScroll
      scrollableTarget="parent"
      dataLength={channels.length}
      hasMore={!!hasNextPage}
      next={fetchNextPage}
      loader={<></>}
    >
      {getChannelsNavLinks().map((link, key) => (
        <ChannelLink
          {...link}
          key={key}
          isClipped={isClipped}
          badgeName={
            link.badgeName ? badgeData[link.badgeName]?.toString() : undefined
          }
        />
      ))}
    </InfiniteScroll>
  );
};

export default ChannelLinks;
