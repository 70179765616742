import * as Sentry from '@sentry/react';
import config from 'app/config';

if (config.env.sentryDns) {
  Sentry.init({
    dsn: config.env.sentryDns,
    environment: config.env.sentryEnv,
    release: config.env.appVersion,
    integrations: [Sentry.browserTracingIntegration()],
  });
}
