import { cx } from '@emotion/css';
import { Icon as IconType } from 'iconsax-react';
import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { RemixiconReactIconComponentType } from 'remixicon-react';
interface StyledNavLinkProps extends NavLinkProps {
  Icon: RemixiconReactIconComponentType | IconType;
  isClipped: boolean;
  badge?: string | number;
  onClick?: VoidFunction;
  tooltipText?: string;
  children: React.ReactNode;
}

const NavigationLink: React.FC<StyledNavLinkProps> = ({
  children,
  Icon,
  badge,
  isClipped,
  onClick,
  tooltipText,
  ...restProps
}) => (
  <NavLink
    onClick={() => onClick?.()}
    className={({ isActive }) =>
      cx(
        'overflow-hidden py-3 rounded-[10px] px-4 mb-1 flex text-sm items-center',
        {
          'hover:bg-gray-silver-blue': !isActive,
          'bg-focus-background hover:bg-focus-background text-focus font-bold':
            isActive,
        }
      )
    }
    {...restProps}
  >
    <div
      data-for="link"
      data-tip={tooltipText}
      className="flex justify-center items-center mr-2"
    >
      <Icon size={18} />
    </div>
    <span className={cx(isClipped ? 'opacity-0 transition-all' : 'flex-1')}>
      {children}
    </span>
    {isClipped && (
      <ReactTooltip
        place="right"
        effect="solid"
        class="react-tooltip"
        id="link"
      />
    )}
    {Boolean(badge) && !isClipped && (
      <span className="rounded-full ml-auto text-white bg-error w-6 h-4 flex items-center justify-center text-xs">
        {badge}
      </span>
    )}
  </NavLink>
);

export default NavigationLink;
