import { cx } from '@emotion/css';
import { ProfessionsList, ScrollbarContainer } from 'app/components';
import Input2 from 'app/components/Input2';
import {
  useArticlesTranslation,
  useComponentsTranslation,
} from 'app/internationalization/hooks';
import { ArrowRotateRight } from 'iconsax-react';
import { useState } from 'react';

import { useAnswersContext } from '../context';

const Professions = () => {
  const [search, setSearch] = useState('');
  const { t } = useComponentsTranslation();
  const { t: at } = useArticlesTranslation();

  const {
    professions,
    selectedProfessions,
    onProfessionChange,
    clearProfessions,
  } = useAnswersContext();

  const totalSelected = selectedProfessions.length;

  return (
    <>
      <div className="flex mt-4 mb-3 items-center text-sm">
        <span className="font-bold">{at('Professions')}</span>

        <div
          className={cx(
            'ml-2 px-1 py-0.5 rounded-full text-focus bg-hover-blue',
            { invisible: totalSelected === 0 }
          )}
        >
          {totalSelected}
        </div>
        {totalSelected > 0 && (
          <button
            onClick={clearProfessions}
            className="flex ml-auto text-grayscale-secondary items-center"
          >
            <ArrowRotateRight size={14} />
            <span className="text-sm ml-0.5">{t('Clear')}</span>
          </button>
        )}
      </div>
      <Input2
        onChange={(e) => setSearch(e.target.value)}
        onClear={() => setSearch('')}
        placeholder={t('Search...')}
        isSearch={true}
        className="h-10 rounded-xl"
        searchIconSize={16}
      />
      <ScrollbarContainer className="max-h-[168px] mt-3">
        <ProfessionsList
          professions={professions}
          onChange={onProfessionChange}
          selectedProfessions={selectedProfessions}
          searchValue={search}
        />
      </ScrollbarContainer>
    </>
  );
};

export default Professions;
