export type LanguageApprovalStatus = 'approved' | 'draft';

interface LanguageStatus {
  type: LanguageApprovalStatus;
  color: string;
  text: string;
}

export const languageStatuses: LanguageStatus[] = [
  {
    type: 'approved',
    color: 'text-success',
    text: 'Ready for publishing',
  },
  {
    type: 'draft',
    color: 'text-orange',
    text: 'Partially proofread',
  },
];
