import { cx } from '@emotion/css';
import useGetActionAnswer from 'app/api/articles/hooks/useGetActionAnswer';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { Danger } from 'iconsax-react';
import { FC } from 'react';
import { SimpleTaskBlockV1 } from 'submodules/common-ui/generated/api/gcs';

import AnswerContainer from './AnswerContainer';
import { useAnswersContext } from './context';

interface Props {
  block: SimpleTaskBlockV1;
}

const SimpleTaskAnswers: FC<Props> = ({ block }) => {
  const { t } = useArticlesTranslation();

  const { setSelectedTaskId, selectedTenant } = useAnswersContext();

  const { actionAnswers, error, isLoading } = useGetActionAnswer({
    id: block.id,
    originalArticleId: selectedTenant?.originalArticleId,
  });

  const showMoreBtnStyling = cx('w-[342px] h-[48px] rounded-xl bg-hover-blue');

  return (
    <AnswerContainer block={block}>
      {error && (
        <div className="h-7 w-fit bg-light rounded-[6px] py-1 px-2 text-error flex gap-1 justify-center items-center">
          <Danger className="w-4 h-4" />
          <span className="text-sm">
            {t('Unable to receive answers, please try again later.')}
          </span>
        </div>
      )}

      {actionAnswers?.length === 0 && (
        <div className="h-7 w-fit bg-light rounded-[6px] py-1 px-2 text-sm text-grayscale-secondary">
          {t('There are no answers to this action yet.')}
        </div>
      )}

      {!isLoading && (actionAnswers?.length ?? 0) > 0 && !error && (
        <button
          onClick={() => setSelectedTaskId(block.id)}
          className={cx(
            showMoreBtnStyling,
            'text-sm p-3 text-focus hover:bg-focus-background'
          )}
        >
          {t('Show More')}
        </button>
      )}

      {(isLoading || actionAnswers?.length === 0) && (
        <div className={showMoreBtnStyling}></div>
      )}
    </AnswerContainer>
  );
};

export default SimpleTaskAnswers;
