import { useAuthenticatedUser } from 'app/api/auth/hooks';
import isEqual from 'lodash/isEqual';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from 'react-query';
import {
  GetUserAnswers,
  UncompletedAction,
  UserAnswerResponse,
} from 'submodules/common-ui/generated/api/gcs';

import {
  useNoDeadlineUpcomingActions,
  useOthersUpcomingActions,
  useThisWeekUpcomingActions,
} from './hooks';
import { UpcomingActionsRequiredFilter, UpcomingActionsQuery } from './types';

export type ColumnType = 'week' | 'upcoming' | 'anytime';

interface UpcomingActionsContextValues {
  noDeadlineUpcomingActions: UpcomingActionsQuery;
  othersUpcomingActions: UpcomingActionsQuery;
  thisWeekUpcomingActions: UpcomingActionsQuery;
  noDeadlineUpcomingActionsData: UncompletedAction[];
  othersUpcomingActionsData: UncompletedAction[];
  thisWeekUpcomingActionsData: UncompletedAction[];
  currentAction: UncompletedAction | undefined;
  columnType: ColumnType | undefined;
  filterValue: UpcomingActionsRequiredFilter;
  onClickAction: (action: UncompletedAction, columnType: ColumnType) => void;
  onCloseAction: () => void;
  onAnswer: (data: UserAnswerResponse, actionId: string) => void;
  onUndo: (actionId: string) => void;
  setFilterValue: Dispatch<SetStateAction<UpcomingActionsRequiredFilter>>;
}

const initialUpcompingActionsValue: UpcomingActionsQuery = {
  data: [],
  error: undefined,
  isFetching: false,
  isLoading: true,
  isPreviousData: false,
  meta: undefined,
  refetch: function <TPageData>(
    _options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ): Promise<QueryObserverResult<unknown, unknown>> {
    throw new Error('Function not implemented.');
  },
  fetchNextPage: function (
    _options?: FetchNextPageOptions | undefined
  ): Promise<InfiniteQueryObserverResult<unknown, unknown>> {
    throw new Error('Function not implemented.');
  },
};

export const UpcomingActionsContext =
  createContext<UpcomingActionsContextValues>({
    noDeadlineUpcomingActions: initialUpcompingActionsValue,
    othersUpcomingActions: initialUpcompingActionsValue,
    thisWeekUpcomingActions: initialUpcompingActionsValue,
    noDeadlineUpcomingActionsData: initialUpcompingActionsValue.data,
    othersUpcomingActionsData: initialUpcompingActionsValue.data,
    thisWeekUpcomingActionsData: initialUpcompingActionsValue.data,
    currentAction: undefined,
    columnType: undefined,
    filterValue: 'all',
    onClickAction: () => null,
    onCloseAction: () => null,
    onAnswer: () => null,
    onUndo: () => null,
    setFilterValue: () => null,
  });

type ContextProps = {
  children: ReactNode;
};

export type UncompletedActionData = UncompletedAction & {
  completed?: boolean;
  currentUserAnswers?: GetUserAnswers;
};

const UpcomingActionsContextProvider = ({ children }: ContextProps) => {
  const { data: user } = useAuthenticatedUser();
  const [filterValue, setFilterValue] =
    useState<UpcomingActionsRequiredFilter>('all');

  const [noDeadlineUpcomingActionsData, setNoDeadlineUpcomingActionsData] =
    useState<UncompletedActionData[]>([]);
  const [othersUpcomingActionsData, setOthersUpcomingActionsData] = useState<
    UncompletedActionData[]
  >([]);
  const [thisWeekUpcomingActionsData, setThisWeekUpcomingActionsData] =
    useState<UncompletedActionData[]>([]);

  const [currentAction, setCurrentAction] = useState<UncompletedActionData>();
  const [columnType, setColumnType] = useState<ColumnType>();

  const noDeadlineUpcomingActions = useNoDeadlineUpcomingActions(filterValue);
  const othersUpcomingActions = useOthersUpcomingActions(filterValue);
  const thisWeekUpcomingActions = useThisWeekUpcomingActions(filterValue);

  const onClickAction = (action: UncompletedAction, column: ColumnType) => {
    setColumnType(column);
    setCurrentAction(action);
  };

  const onCloseAction = () => {
    setCurrentAction(undefined);
    setColumnType(undefined);
  };

  const handleAnswerStatus = ({
    data,
    actionId,
  }: {
    data?: UserAnswerResponse;
    actionId: string;
  }) => {
    let setData;
    if (columnType === 'anytime') setData = setNoDeadlineUpcomingActionsData;
    if (columnType === 'upcoming') setData = setOthersUpcomingActionsData;
    if (columnType === 'week') setData = setThisWeekUpcomingActionsData;

    if (!setData || !user) return;

    setData((actionsData) => {
      const index = actionsData.findIndex((d) => d.id === parseInt(actionId));
      if (index === -1) return actionsData;
      actionsData[index].completed = !!data;
      actionsData[index].currentUserAnswers = data
        ? [{ ...data, completedBy: user.id }]
        : [];
      return actionsData;
    });
    setCurrentAction((actionData) => {
      if (!actionData) return undefined;
      return {
        ...actionData,
        completed: !!data,
        currentUserAnswers: data ? [{ ...data, completedBy: user.id }] : [],
      };
    });
  };

  const onAnswer = (data: UserAnswerResponse, actionId: string) => {
    handleAnswerStatus({ data, actionId });
  };

  const onUndo = (actionId: string) => {
    handleAnswerStatus({ actionId });
  };

  useEffect(() => {
    if (
      isEqual(noDeadlineUpcomingActions.data, noDeadlineUpcomingActionsData) &&
      noDeadlineUpcomingActions.data
    )
      return;
    setNoDeadlineUpcomingActionsData(noDeadlineUpcomingActions.data);
  }, [noDeadlineUpcomingActions.data, noDeadlineUpcomingActionsData]);

  useEffect(() => {
    if (
      isEqual(othersUpcomingActions.data, othersUpcomingActionsData) &&
      othersUpcomingActions.data
    )
      return;
    setOthersUpcomingActionsData(othersUpcomingActions.data);
  }, [othersUpcomingActions.data, othersUpcomingActionsData]);

  useEffect(() => {
    if (
      isEqual(thisWeekUpcomingActions.data, thisWeekUpcomingActionsData) &&
      thisWeekUpcomingActions.data
    )
      return;
    setThisWeekUpcomingActionsData(thisWeekUpcomingActions.data);
  }, [thisWeekUpcomingActions.data, thisWeekUpcomingActionsData]);

  return (
    <UpcomingActionsContext.Provider
      value={{
        noDeadlineUpcomingActions,
        othersUpcomingActions,
        thisWeekUpcomingActions,
        noDeadlineUpcomingActionsData,
        othersUpcomingActionsData,
        thisWeekUpcomingActionsData,
        currentAction,
        columnType,
        filterValue,
        onClickAction,
        onCloseAction,
        onAnswer,
        onUndo,
        setFilterValue,
      }}
    >
      {children}
    </UpcomingActionsContext.Provider>
  );
};

const useUpcomingActionsContext = () => {
  const context = useContext(UpcomingActionsContext);

  if (context === undefined) {
    throw new Error(
      'useUpcomingActionsContext must be used within a UpcomingActionsContextProvider'
    );
  }

  return context;
};

export { UpcomingActionsContextProvider, useUpcomingActionsContext };
export type { UpcomingActionsContextValues };
