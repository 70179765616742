import { Element, useEditor, useNode } from '@craftjs/core';
import { cx } from '@emotion/css';
import { useSelector } from 'app/hooks';
import useIsEditorEnabled from 'app/hooks/useIsEditorEnabled';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { selectors } from 'app/store/editor';
import AddOption from 'assets/icons/add-option.svg?react';
import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import {
  ActionTypeEnum,
  MultiChoiceBlockRenderTypeEnum,
} from 'submodules/common-ui/generated/api/gcs';

import CategoryDropdown from '../components/CategoryDropdown';
import Completion from '../components/Completion';
import Deadline from '../components/Deadline';
import Feedback from '../components/Feedback';
import Mandatory from '../components/Mandatory';
import Privacy from '../components/Privacy';
import Recurring from '../components/Recurring';
import TaskType from '../components/TaskType';
import Title from '../components/Title';

import QuizOption from './Option/QuizOption';
import QuizOptionsContainer from './Option/QuizOptionsContainer';
import QuizSkeleton from './QuizSkeleton';

import { QuizBlockProps } from '.';

interface EditorUserModeProps {
  setBackspace: Dispatch<SetStateAction<boolean>>;
  setHasFocus: Dispatch<SetStateAction<boolean>>;
  setProp: (cb: any, throttleRate?: number | undefined) => void;
}

const EditorUserMode = ({
  setBackspace,
  setHasFocus,
  setProp,
}: EditorUserModeProps) => {
  const { t } = useArticlesTranslation();
  const {
    actions: { addNodeTree },
    query: { parseReactElement, node: getNode },
    nodes,
  } = useEditor((state) => ({ nodes: state.nodes }));

  const { selected, linkedNodes, quizProps, nodeId } = useNode((node) => ({
    selected: node.events.selected,
    nodeId: node.id,
    linkedNodes: node.data.linkedNodes,
    quizProps: node.data.props as QuizBlockProps,
  }));

  const currentLanguage = useSelector(selectors.getActiveLanguage);

  const { enabled } = useIsEditorEnabled();

  const optionsContainer = linkedNodes['optionsContainer'];
  const optionsNumber = nodes[optionsContainer]?.data?.nodes?.length ?? 1;
  const maxCorrectAnswersNumber =
    nodes[optionsContainer]?.data?.nodes?.filter(
      (n) => nodes[n]?.data.props.checked
    ).length ?? 0;

  const addOption = () => {
    const block = parseReactElement(<QuizOption />).toNodeTree();

    addNodeTree(block, optionsContainer);
  };

  const noneCheckedError = useCallback(() => {
    const { descendants } = getNode(nodeId);
    const descendantsNodes = descendants(true);

    return (
      !nodes[nodeId].events.selected &&
      !descendantsNodes.some(
        (n) =>
          nodes[n]?.data?.name === 'QuizOption' &&
          nodes[n]?.data?.props?.checked
      )
    );
  }, [getNode, nodeId, nodes]);

  useEffect(() => {
    if (!quizProps.minCorrectAnswers) return;

    if (quizProps.minCorrectAnswers > maxCorrectAnswersNumber) {
      setProp(
        (props: QuizBlockProps) =>
          (props.minCorrectAnswers = maxCorrectAnswersNumber)
      );
    }
  }, [maxCorrectAnswersNumber, quizProps, setProp]);

  useEffect(() => {
    if (quizProps.mandatory === undefined)
      setProp((props: QuizBlockProps) => {
        props.mandatory = true;
      });
  }, [quizProps.mandatory, setProp]);

  if (quizProps.isSkeleton) {
    return <QuizSkeleton />;
  }

  return (
    <div className="flex justify-between m-2 p-3 rounded border border-gray-light shadow-action">
      <div className="flex flex-col w-full">
        <div className="flex items-center">
          <TaskType
            type={ActionTypeEnum.MultiChoice}
            renderType={MultiChoiceBlockRenderTypeEnum.Quiz}
            disabled={!enabled}
          />
          <Title
            onFocusChange={(focus) => setHasFocus(focus)}
            placeholder={`${t('Title (optional)')}`}
            className="font-bold placeholder:font-normal"
            description={quizProps.title}
            enabled={enabled}
            onChange={(value) =>
              setProp((props: QuizBlockProps) => {
                props.title = value;
              })
            }
          />
        </div>
        <div className="flex mt-2 ml-[32px]">
          <Title
            onFocusChange={(focus) => setHasFocus(focus)}
            placeholder={`${t('Type your quiz question here')}*`}
            enabled={enabled}
            description={quizProps.description}
            onChange={(value) =>
              setProp((props: QuizBlockProps) => {
                props.description = value;
              })
            }
          />
        </div>

        <Element id="optionsContainer" is={QuizOptionsContainer} canvas>
          {quizProps.choices &&
            quizProps.choices.map((choice) => {
              return (
                <QuizOption
                  key={choice.id}
                  text={choice.variants[currentLanguage].answer}
                  checked={!!choice.correct}
                />
              );
            })}

          {!quizProps.choices && <QuizOption />}
        </Element>

        {noneCheckedError() && (
          <div className="text-error text-xs pt-2">
            {t('Please mark at least one answer as correct')}
          </div>
        )}

        {enabled && (
          <button
            className="flex mt-2 text-gray-dark w-fit hover:text-focus cursor-pointer"
            onClick={() => addOption()}
            disabled={!enabled}
          >
            <AddOption className="w-[22px] h-[22px]" />
            <span className="ml-3 text-sm">Add Option</span>
          </button>
        )}

        <div className="flex text-gray-dark mt-2.5 items-center">
          {(quizProps.schedule?.frequency === 'once' ||
            !quizProps.schedule) && (
            <Deadline
              disabled={!enabled}
              selected={quizProps.deadline}
              onChange={(value) =>
                setProp((props: QuizBlockProps) => {
                  props.deadline = value ?? undefined;
                  props.schedule = undefined;
                })
              }
            />
          )}
          <Recurring
            disabled={!enabled}
            scheduleDate={quizProps.schedule}
            onChange={(date) =>
              setProp((props: QuizBlockProps) => {
                props.schedule = date;
                props.deadline = undefined;
              })
            }
          />
          <CategoryDropdown
            onOpenCallback={setBackspace}
            disabled={!enabled}
            selectedCategories={quizProps.categories}
            onChange={(category) =>
              setProp((props: QuizBlockProps) => {
                props.categories = category ? [category] : [];
              })
            }
          />
          <div
            className={cx('flex items-center ml-auto text-gray-dark gap-1.5', {
              hidden: !selected && !quizProps.deadline,
            })}
          >
            <Feedback
              onFocusChange={(focus) => setHasFocus(focus)}
              explanation={quizProps.explanation}
              optionsNumber={optionsNumber}
              maxCorrectAnswersNumber={maxCorrectAnswersNumber}
              correctAnswersNumber={quizProps.minCorrectAnswers}
              setCorrectAnswersNumber={(value) =>
                setProp((props: QuizBlockProps) => {
                  props.minCorrectAnswers = value;
                })
              }
              onFeedbackChange={(value) =>
                setProp((props: QuizBlockProps) => {
                  props.explanation = value;
                })
              }
            />
            <div className="bg-gray-light h-4 w-[1px]" />
            <Privacy
              disabled={!enabled}
              isPublic={quizProps.public}
              onClick={(value) =>
                setProp((props: QuizBlockProps) => {
                  props.public = value;
                })
              }
            />
            <div className="bg-gray-light h-4 w-[1px]" />
            <Completion
              disabled={!enabled}
              required={quizProps.required}
              onClick={(value) =>
                setProp((props: QuizBlockProps) => {
                  props.required = value;
                })
              }
            />
            <div className="bg-gray-light h-4 w-[1px]" />
            <Mandatory
              disabled={!enabled}
              checked={!!quizProps.mandatory}
              onChange={() =>
                setProp((props: QuizBlockProps) => {
                  props.mandatory = !quizProps.mandatory;
                })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditorUserMode;
