import useGetSettingsQuery from './useGetSettingsQuery';

const useGetSettings = () => {
  const { data: settings, isLoading } = useGetSettingsQuery({});

  return {
    settings,
    isLoading,
  };
};

export default useGetSettings;
