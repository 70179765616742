import { request } from 'app/api/gcsRequest';
import config from 'app/config';
import { ActionInstanceApi, SortOrderEnum } from 'common-ui/generated/api/gcs';

const getActionInstance = ({
  actionId,
  page,
  start,
  startFrom,
  startTo,
  perPage = 20,
}: {
  actionId: number;
  page?: number;
  start?: SortOrderEnum;
  startFrom?: string;
  startTo?: string;
  perPage?: number;
}) =>
  new ActionInstanceApi(
    undefined,
    config.env.gcsApiUrl,
    request()
  ).getActionInstance(actionId, page, perPage, start, startFrom, startTo);

export { getActionInstance };
export default getActionInstance;
