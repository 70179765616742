import { cx } from '@emotion/css';
import { Element3, RowVertical } from 'iconsax-react';

import { useAnswersContext } from '../context';

const ChangeLayoutButtons = () => {
  const { layout, setLayout } = useAnswersContext();
  return (
    <div className="flex p-1 ml-4 bg-hover-blue rounded-xl gap-1">
      <button
        onClick={() => setLayout('list')}
        className={cx('p-2 rounded-lg', {
          'bg-focus text-white': layout === 'list',
          'bg-focus-background text-focus': layout !== 'list',
        })}
      >
        <RowVertical size={24} />
      </button>
      <button
        onClick={() => setLayout('grid')}
        className={cx('p-2 rounded-lg', {
          'bg-focus text-white': layout === 'grid',
          'bg-focus-background text-focus': layout !== 'grid',
        })}
      >
        <Element3 size={24} />
      </button>
    </div>
  );
};

export default ChangeLayoutButtons;
