import { cx } from '@emotion/css';
import { actions } from 'app/store/navigation';
import { useDispatch } from 'react-redux';
import { Link } from 'submodules/common-ui/generated/api/sub-domain-config';

const CustomLink = ({
  link,
  isClipped,
  onClick,
}: {
  link: Link;
  isClipped: boolean;
  onClick?: VoidFunction;
}) => {
  const dispatch = useDispatch();

  const onLinkCLick = () => {
    onClick?.();

    if (link.opensIn === 'new_window') {
      window.open(link.url, '_blank');
      return;
    }

    dispatch(actions.setCustomLink(link.url));
  };

  return (
    <a
      role="presentation"
      className="overflow-hidden rounded py-3 px-4 mb-1 flex text-sm items-center hover:bg-hover-blue cursor-pointer"
      onClick={onLinkCLick}
    >
      {link?.icon && (
        <img
          className={cx('w-6 h-6', {
            'mr-2': !isClipped,
          })}
          src={link.icon?.url}
          alt="Link icon"
        />
      )}

      <span
        className={cx(isClipped ? 'opacity-0 hidden transition-all' : 'flex-1')}
      >
        <div className="whitespace-nowrap ml-1">{link.name}</div>
      </span>
    </a>
  );
};

export default CustomLink;
