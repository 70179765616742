import { useEditor, useNode } from '@craftjs/core';
import { css, cx } from '@emotion/css';
import ActionToast from 'app/components/Toast/ActionToast';
import useIsEditorEnabled from 'app/hooks/useIsEditorEnabled';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import useCanChangeArticleStructure from 'app/pages/Editor/hooks/useCanChangeArticleStructure';
import useCloneTree from 'app/pages/Editor/hooks/useCloneTree';
import { editorTypes } from 'app/router/constants';
import { selectors } from 'app/store/editor';
import { KeyboardEvent, ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import useIsSelected from '../hooks/useIsSelected';

import ContextualMenu, { OptionProps as Option } from './blocks/ContextualMenu';
import TranslationStatusButton from './blocks/TranslationStatusButton';
import ConfirmDeleteBlockModal from './ConfirmDeleteBlockModal';
import DragHandle from './DragHandle';

type UserBlockTypes =
  | 'Image'
  | 'GIF'
  | 'PDF'
  | 'Video'
  | 'Text'
  | 'Url'
  | 'Title'
  | 'Task'
  | 'MultiTask'
  | 'Article Linking'
  | 'File'
  | 'Poll'
  | 'Scorm'
  | 'HeadingSection';

interface BaseBlockContainerProps {
  children: ReactNode;
  deleteOnBackspace?: boolean;
  draggable?: boolean;
  nodeId: string;
  options?: Option[];
  readOptions?: Option[];
  selected: boolean;
  showActions?: boolean;
  showTranslationStatus?: boolean;
  type: UserBlockTypes;
  focused?: boolean;
  className?: string;
}

const BaseBlockContainer = ({
  children,
  deleteOnBackspace = true,
  draggable = true,
  nodeId,
  options,
  readOptions,
  selected,
  showActions = true,
  showTranslationStatus = true,
  type,
  focused,
  className,
}: BaseBlockContainerProps) => {
  const { mode } = useParams<{ mode: string }>();
  const { t } = useArticlesTranslation();
  const { enabled } = useIsEditorEnabled();
  const { actions, query } = useEditor();
  const [showConfirmDeleteBlockModal, setShowConfirmDeleteBlockModal] =
    useState(false);
  const {
    connectors: { drag },
  } = useNode();
  const { undo } = actions.history;

  const { cloneTree } = useCloneTree();
  const { canChangeArticleStructure } = useCanChangeArticleStructure();
  const article = useSelector(selectors.getArticle);

  useIsSelected(nodeId);

  const deleteNode = (confirmed = false) => {
    if (!nodeId || !canChangeArticleStructure) return;

    if ((article?.isShared || article?.originalTenant) && !confirmed) {
      setShowConfirmDeleteBlockModal(true);
      return;
    }

    actions.delete(nodeId);
    toast.dismiss();

    toast(
      <ActionToast
        action={`${t('Cancel')}`}
        text={t('{{type}} block was deleted', {
          type: type,
        })}
        onClick={undo}
      />,
      {
        position: 'bottom-center',
        autoClose: 8000,
        closeButton: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        containerId: 'DeletedBlockPopup',
      }
    );
  };

  const moveBlock = (to: 'up' | 'down') => {
    if (!canChangeArticleStructure) return;

    const nodes = query.getNodes().dropableRegion.data.nodes;
    const currentRegion = nodes.findIndex((node) => node === nodeId);

    if (currentRegion === -1) return;

    if (to === 'up') {
      if (currentRegion === 0) return;
      actions.move(String(nodeId), 'dropableRegion', currentRegion - 1);
    } else {
      const indexToMove =
        currentRegion > nodes.length - 1 ? currentRegion : currentRegion + 1;
      const nodeToMove = nodes[indexToMove];
      actions.move(nodeToMove, 'dropableRegion', indexToMove - 1);
    }
  };

  const onKeyDown = ({ code }: KeyboardEvent<HTMLDivElement>) => {
    if (!canChangeArticleStructure || !selected) return;

    const isTextBlockFocused = type === 'Text' && focused;

    if (code === 'Backspace' && deleteOnBackspace) return deleteNode();
    if (isTextBlockFocused) return;
    if (code === 'ArrowUp') return moveBlock('up');
    if (code === 'ArrowDown') return moveBlock('down');
  };

  const readOptionsVisible = () => {
    const validMode = mode == editorTypes.actions || mode === editorTypes.view;

    const blocksWithReadOptions: Array<UserBlockTypes> = ['PDF'];

    return validMode && blocksWithReadOptions.includes(type);
  };

  const isReadOptionsVisible = readOptionsVisible();

  return (
    <div
      className={cx(
        'block-container border border-transparent rounded-lg relative cursor-default group outline-none',
        {
          'cursor-default': !enabled,
          'bg-gradient-block bg-origin-border': enabled && selected,
          'mt-4': type !== 'Title',
          'cursor-pointer':
            mode == editorTypes.actions && (type === 'Image' || type === 'Url'),
        },
        css(selected && 'background-clip: content-box, border-box'),
        className
      )}
      id={nodeId}
      role="button"
      tabIndex={0}
      onKeyDown={onKeyDown}
    >
      {(enabled || isReadOptionsVisible) && (
        <>
          {selected && (
            <ContextualMenu
              nodeId={nodeId}
              showActions={enabled && showActions}
              showTranslationStatus={enabled && showTranslationStatus}
              additionalOptions={options}
              readOptions={isReadOptionsVisible ? readOptions : []}
              onDelete={deleteNode}
              onDuplicate={() => {
                if (!canChangeArticleStructure) return;

                const node = query.node(nodeId).get();
                if (node.data.parent) {
                  const tree = cloneTree(nodeId);
                  actions.addNodeTree(tree, node.data.parent);
                  actions.selectNode(tree.rootNodeId);
                }
              }}
              onMoveDown={() => moveBlock('down')}
              onMoveUp={() => moveBlock('up')}
            />
          )}
          {!selected && showTranslationStatus && !isReadOptionsVisible && (
            <TranslationStatusButton
              blockId={nodeId}
              isBlockSelected={selected}
            />
          )}
          {draggable && !isReadOptionsVisible && (
            <DragHandle drag={drag} selected={selected} moveBlock={moveBlock} />
          )}
        </>
      )}

      {children}

      {showConfirmDeleteBlockModal && (
        <ConfirmDeleteBlockModal
          onCancelClick={() => setShowConfirmDeleteBlockModal(false)}
          onConfirmClick={() => deleteNode(true)}
        />
      )}
    </div>
  );
};

export default BaseBlockContainer;
