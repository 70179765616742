import BlockTypes from './constants';

const ACTION_BLOCKS = [
  BlockTypes.PollBlock,
  BlockTypes.QuizBlock,
  BlockTypes.SimpleTaskBlock,
  BlockTypes.YesNoNaBlock,
  BlockTypes.ScormBlock,
];

const ATTACHMENT_BLOCKS = [
  BlockTypes.PDFBlock,
  BlockTypes.FileBlock,
  BlockTypes.ArticleLinkBlock,
  BlockTypes.UrlBlock,
];

const MEDIA_BLOCKS = [
  BlockTypes.ImageBlock,
  BlockTypes.VideoBlock,
  BlockTypes.GiphyBlock,
];

export { ACTION_BLOCKS, ATTACHMENT_BLOCKS, MEDIA_BLOCKS };
