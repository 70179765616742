import { useArticlesTranslation } from 'app/internationalization/hooks';

import LoadingMediaTasks from './LoadingMediaTasks';

const EmptyMediaTasks = () => {
  const { t } = useArticlesTranslation();

  return (
    <>
      <LoadingMediaTasks />
      <LoadingMediaTasks />

      <div className="absolute m-auto left-0 right-0 bottom-0 top-0 h-7 w-fit bg-light rounded-[6px] py-1 px-2 text-sm text-grayscale-secondary">
        {t('There are no answers to this action yet.')}
      </div>
    </>
  );
};

export default EmptyMediaTasks;
