import { useNode } from '@craftjs/core';
import { css, cx } from '@emotion/css';
import { ResponsiveImage } from 'app/components';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { ExportSquare } from 'iconsax-react';
import { ComponentProps } from 'react';

import ScormBlock from '../../../../ScormBlock';
import ActionContainer from '../ActionContainer';
import EditorNavigation from '../EditorNavigation';

const ScormViewerAction = () => {
  const { selectedProps } = useNode((node) => ({
    selectedProps: node.data.props as ComponentProps<typeof ScormBlock>,
  }));

  const { t } = useArticlesTranslation();

  const { title, description, image } = selectedProps;

  return (
    <ActionContainer
      isMandatory={selectedProps.mandatory}
      canAnswer={false}
      type={'scorm_task'}
    >
      <div className="flex flex-col">
        <span
          className={cx('text-lg font-bold', css('word-break: break-word;'))}
        >
          {title}
        </span>
        <span className={cx('text-black', css('word-break: break-word;'))}>
          {description}
        </span>
      </div>
      <div className="pt-6">
        <div className="h-[154px] w-full">
          {image && (
            <ResponsiveImage
              className="h-full w-full object-cover rounded-t-lg"
              directUrls={image.directUrls}
            />
          )}
        </div>
        <div className="w-full h-12 bg-hover-blue rounded-b-lg flex justify-center items-center">
          <div className="text-focus text-sm flex items-center gap-2">
            <span>{t('Scorm course (assignees only)')}</span>
            <ExportSquare size={24} />
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center w-full mt-6">
        <EditorNavigation />
      </div>
    </ActionContainer>
  );
};

export default ScormViewerAction;
