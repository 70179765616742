interface ActionButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
}

const ActionButton = ({ children, onClick, disabled }: ActionButtonProps) => {
  return (
    <button
      type="button"
      className="flex items-center bg-white border border-focus-background bg-white py-1 px-2 text-sm rounded leading-5"
      onClick={() => onClick?.()}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default ActionButton;
