import { Response } from '../../types';
import { ActivityTypes } from '../constants';
import {
  PreMappedQuizAnswer,
  PreMappedActivity,
  PreMappedQuizContent,
} from '../types';

const mapActivity = (activity: Response<PreMappedActivity>) => {
  if (activity.data.type_id !== ActivityTypes.quiz) {
    return {
      ...activity,
      data: {
        ...activity.data,
        answers: [],
      },
    };
  }
  const { content } = activity.data;

  const preMapped = content as PreMappedQuizContent;

  const question = preMapped.question;
  const answers = preMapped.answers;
  return {
    ...activity,
    data: {
      ...activity.data,
      content: question,
      answers:
        Object.values(answers)?.[0]?.map(
          ({ correct, ...answer }: PreMappedQuizAnswer) => ({
            ...answer,
            correct: correct === '1',
          })
        ) || [],
    },
  };
};

export default mapActivity;
