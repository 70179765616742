import { request } from 'app/api/gcsRequest';
import config from 'app/config';
import {
  CopyArticleApi,
  CopyArticleRequest,
} from 'common-ui/generated/api/gcs';

const duplicateArticle = (data: CopyArticleRequest) =>
  new CopyArticleApi(undefined, config.env.gcsApiUrl, request())
    .copyArticle(data)
    .then((response) => response.data);

export { duplicateArticle };
export default duplicateArticle;
