import { WikiKey } from '../types';

import useBaseTranslation from './useBaseTranslation';

const useWikiTranslation = () =>
  useBaseTranslation<WikiKey>(undefined, {
    keyPrefix: 'wiki',
  });

export default useWikiTranslation;
