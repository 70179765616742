import { css, cx } from '@emotion/css';
import { SystemModules } from 'app/api/auth/constants';
import { useAuthenticatedUser } from 'app/api/auth/hooks';
import { useUnreadThreadsAmount } from 'app/api/chat/hooks';
import { useSelector } from 'app/hooks';
import { selectors as authSelectors } from 'app/store/auth';
import { selectors as chatSelectors } from 'app/store/chat';
import { selectors } from 'app/store/request';
import { SidebarLeft, SidebarRight } from 'iconsax-react';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Logo from '../Logo';

import SidebarNavigation from './SidebarNavigation';
import { BADGE_KEYS, Variant, getWidth } from './types';
import useUnseenNewsAmountQuery from './useUnseenNewsAmountQuery';
interface NavigationProps {
  toggle: () => void;
  variant: Variant;
}

const OPEN_NAVIGATION_VARIANTS: Array<Variant> = ['desktop'];

const Navigation: React.FC<NavigationProps> = ({ toggle, variant }) => {
  const location = useLocation();
  const instanceIsReady = useSelector(selectors.getRequestInstanceReady);
  const { data: user } = useAuthenticatedUser();

  const { data: unseenAmount, refetch } = useUnseenNewsAmountQuery(
    Boolean(user)
  );
  const cachedChatToken = useSelector(chatSelectors.getChatToken);
  const { unreadThreadsAmount } = useUnreadThreadsAmount(!!cachedChatToken);
  const tenant = useSelector(authSelectors.getSelectedTenant);

  useEffect(() => {
    if (variant === 'mobile') {
      toggle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (
      variant === 'desktop' &&
      instanceIsReady &&
      user &&
      !user.hiddenModules?.[SystemModules.News]
    )
      refetch();
  }, [variant, refetch, instanceIsReady, user]);

  const badgeData = {
    [BADGE_KEYS.unseenNewsAmount]: unseenAmount,
    [BADGE_KEYS.unreadThreadsAmount]: unreadThreadsAmount,
  };

  const width = getWidth(variant);
  const menuOpen = OPEN_NAVIGATION_VARIANTS.includes(variant);

  return (
    <div className="duration-300">
      <div
        className={cx(
          'flex flex-col duration-300 pt-2 z-50 bg-gray-white-blue relative t-0 h-screen border-gray-light',
          css`
            width: ${width};
          `
        )}
      >
        <div
          className={cx('flex p-3', {
            'justify-between items-center mr-2': menuOpen,
            'flex-col mx-auto': !menuOpen,
          })}
        >
          <div className={cx('flex items-center', { 'mb-3': !menuOpen })}>
            <Logo
              size="xs"
              visible
              className={cx('object-contain', { 'mr-1': menuOpen })}
            />
            {menuOpen && (
              <span className="text-base text-dark-blue font-bold">
                {tenant?.title ?? 'Atobi'}
              </span>
            )}
          </div>

          <button
            className="text-grayscale-secondary flex justify-center my-auto"
            onClick={toggle}
          >
            {menuOpen ? <SidebarLeft size={16} /> : <SidebarRight size={16} />}
          </button>
        </div>
        <SidebarNavigation
          isClipped={variant === Variant.DesktopClipped}
          badgeData={badgeData}
        />
      </div>
    </div>
  );
};
export default Navigation;
