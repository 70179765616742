import { cx } from '@emotion/css';
import useDateTimeFormat from 'app/hooks/useDateTimeFormat';
import useUserQuery from 'app/pages/profiles/PublicProfile/useUserQuery';
import { TickCircle } from 'iconsax-react';
import { FC } from 'react';

import { useAnswersContext } from '../../context';
import UserDetails from '../UserDetails';

interface Props {
  userId: number;
  completed: boolean;
  createdAt: string;
}

const SimpleTaskDeepDive: FC<Props> = ({ userId, completed, createdAt }) => {
  const { layout } = useAnswersContext();

  const { data: user, isLoading: isLoadingUser } = useUserQuery({ id: userId });

  const createdAtTime = useDateTimeFormat({
    timestamp: createdAt,
    type: 'dayMonthYearTime',
  });

  return (
    <div
      className={cx(
        'rounded-xl gap-4 bg-white shadow-atobi flex p-4 items-center',
        {
          'bg-hover-blue': isLoadingUser,
          'bg-grayscale-bg-dark': !isLoadingUser,
        }
      )}
    >
      <UserDetails
        isLoadingUser={isLoadingUser}
        user={user}
        createdAtTime={createdAtTime}
        layout={layout}
      />
      {completed && (
        <div className="icon-container text-success ml-auto">
          <TickCircle className="h-5 w-5" />
        </div>
      )}
    </div>
  );
};

export default SimpleTaskDeepDive;
