import {
  ArticleLinkBlock,
  ArticleLinkBlockVariants,
} from 'submodules/common-ui/generated/api/gcs/api';

import { Props } from './types';

const mapArticleLinkBlock = ({
  article,
  block,
  index,
  selectedLanguage,
  blockId,
}: Props): ArticleLinkBlock | null => {
  if (!block.data.props.articleLink?.id || block.data.props.unavailable) {
    return null;
  }

  let variants: ArticleLinkBlockVariants = {};
  if (blockId) {
    article.blocks.forEach((b) => {
      if (b.id === blockId) {
        variants = { ...b.variants } as ArticleLinkBlockVariants;
      }
    });
  }

  Object.keys(variants).forEach((language) => {
    if (blockId) {
      variants[language] = {
        ...variants[language],
        ...{
          id: variants[language].id,
        },
      };
    }
  });

  return {
    id: blockId,
    version: 1,
    parentId: null,
    type: 'article_link',
    position: index,
    variants: {
      ...variants,
      [selectedLanguage]: {
        id: block.data.props.articleLink.id,
      },
    },
  };
};

export default mapArticleLinkBlock;
