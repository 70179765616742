import { useEffect, useRef, useState } from 'react';
interface TypewriterProps {
  text: string;
  delay: number;
  onFinish?: () => void;
}

const Typewriter = ({ text, delay, onFinish }: TypewriterProps) => {
  const [currentText, setCurrentText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFinished, setIsFinished] = useState(false);

  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (divRef.current) {
      divRef.current.innerHTML = currentText;
    }
  }, [currentText]);

  useEffect(() => {
    if (currentIndex >= text.length) return;

    const timeout = setTimeout(() => {
      if (text[currentIndex] !== '<') {
        setCurrentText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
        return;
      }
      for (let i = currentIndex; i < text.length; i++) {
        if (text[i] !== '>') continue;
        setCurrentText(
          (prevText) => prevText + text.substring(currentIndex, i + 1)
        );
        setCurrentIndex((_) => i + 1);
        break;
      }
    }, delay);

    return () => clearTimeout(timeout);
  }, [currentIndex, delay, text]);

  useEffect(() => {
    if (currentIndex === text.length && !isFinished) {
      setIsFinished(true);
      onFinish?.();
    }
  }, [currentIndex, isFinished, onFinish, text]);

  return <div ref={divRef} />;
};

export default Typewriter;
