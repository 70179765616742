import last from 'lodash/last';
import { useInfiniteQuery } from 'react-query';
import {
  ReactionObjectTypeEnum,
  SortOrderEnum,
} from 'submodules/common-ui/generated/api/gcs';
import { NonNegativeNumber } from 'submodules/common-ui/utils/nonNegativeNumber';

import { queryKeys, resources } from '../index';

const useCommentsQuery = ({
  objectType,
  objectId,
  page,
  enabled,
  createdAt = 'desc',
}: {
  objectType: ReactionObjectTypeEnum;
  objectId: number;
  page: number;
  createdAt?: SortOrderEnum;
  enabled?: boolean;
}) => {
  const {
    data,
    isLoading,
    error,
    isFetching,
    isPreviousData,
    refetch,
    fetchNextPage,
  } = useInfiniteQuery(
    queryKeys.comments(NonNegativeNumber.create(page).value, objectId),
    ({ pageParam }) =>
      resources.getComments({
        objectId,
        objectType,
        page: NonNegativeNumber.create(pageParam || 0).value,
        createdAt,
      }),
    {
      enabled,
      keepPreviousData: true,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      getNextPageParam: (lastPage) => lastPage.meta.currentPage + 1,
    }
  );

  return {
    data: data?.pages.flatMap((x) => x.data) || [],
    meta: last(data?.pages)?.meta,
    isFetching,
    isLoading,
    isPreviousData,
    error,
    refetch,
    fetchNextPage,
  };
};

export default useCommentsQuery;
