import { useAuthenticatedUser } from 'app/api/auth/hooks';
import Badge, { BadgeColor } from 'app/components/Badge';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { routes } from 'app/router';
import { Trans } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const ModalFooter = () => {
  const { t } = useArticlesTranslation();

  const {
    data: user,
    hasCreateArticlePermission,
    hasUpdateArticlePermission,
  } = useAuthenticatedUser();

  const hasGlossaryDescription =
    user?.isOwner || hasCreateArticlePermission || hasUpdateArticlePermission;

  if (!hasGlossaryDescription) return null;

  return (
    <div className="flex justify-between w-full items-center bg-grayscale-bg-dark rounded-b-lg p-2 mt-5">
      <Badge title={t('NEW')} type={BadgeColor.Black} className="mr-2" />
      <div className="text-grayscale-secondary text-sm">
        <Trans
          components={{
            a: (
              <NavLink
                target="_blank"
                className="font-bold underline"
                rel="noreferrer"
                to={routes.glossary.create()}
              >
                {t('Atobi Glossary.')}
              </NavLink>
            ),
          }}
          i18nKey="articles.Improve the precision of automatic translations and keep your brand consistent with Atobi Glossary."
        />
      </div>
    </div>
  );
};

export default ModalFooter;
