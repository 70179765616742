import useArticlesTranslation from 'app/internationalization/hooks/useArticlesTranslation';
import { Add } from 'iconsax-react';
import { FC } from 'react';

interface AddOwnAnswerButtonProps {
  answeredByColleague: boolean;
  isActionCompleted: boolean;
  onAddAnswer: () => void;
}

const AddOwnAnswerButton: FC<AddOwnAnswerButtonProps> = ({
  answeredByColleague,
  isActionCompleted,
  onAddAnswer,
}) => {
  const { t } = useArticlesTranslation();

  if (!answeredByColleague || isActionCompleted) {
    return null;
  }

  return (
    <button
      type="button"
      className="flex items-center justify-center w-full h-12 mx-5 rounded-xl bg-focus text-white"
      onClick={onAddAnswer}
    >
      <Add className="w-6 h-6" />
      {t('Add your own answer')}
    </button>
  );
};

export default AddOwnAnswerButton;
