import { cx, css } from '@emotion/css';
import { PageLoader } from 'app/components';
import config from 'app/config';
import useFileUpload from 'app/hooks/useFileUpload';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import logger from 'app/utils/logger';
import { Trash, Gallery } from 'iconsax-react';
import uniqueId from 'lodash/uniqueId';
import { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { InternalFileGet } from 'submodules/common-ui/generated/api/gcs';

interface PollOptionImageProps {
  imageSchema?: InternalFileGet;
  error?: boolean;
  disabled: boolean;
  onImageUploaded: (result?: InternalFileGet, error?: boolean) => void;
}

const PollOptionImage = ({
  imageSchema,
  error = false,
  disabled,
  onImageUploaded,
}: PollOptionImageProps) => {
  const { t } = useArticlesTranslation();

  const [locaImgSrc, setLocalImgSrc] = useState<string | undefined>(undefined);
  const [apiImgSrc, setApiImgSrc] = useState<string | undefined>(undefined);

  const { upload, isLoading } = useFileUpload();

  const uploadImage = (image: File) => {
    upload(image, {
      onSuccess: (response) => {
        const result = {
          type: 'internal',
          id: response.id,
          url: '',
          name: image.name,
          translationStatus: 'draft',
        } as InternalFileGet;
        onImageUploaded(result);

        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = function () {
          setLocalImgSrc(reader.result as string);
        };
      },
      onError: () => {
        onImageUploaded(undefined, true);
        logger.error;
      },
    });
  };

  useEffect(() => {
    if (!imageSchema || !imageSchema.id || !imageSchema.url) return;
    setApiImgSrc(`${config.env.gcsApiUrl}/${imageSchema.url}`);
  }, [imageSchema]);

  const uniq = uniqueId();

  if (isLoading)
    return (
      <div className="w-5 h-5 mr-2">
        <PageLoader />
      </div>
    );

  return (
    <label htmlFor={uniq} className={cx({ 'cursor-pointer': !disabled })}>
      {locaImgSrc || apiImgSrc ? (
        <div
          className={cx(
            'w-[66px] h-10 rounded-sm relative',
            css(`
                &{
                    background-image: url("${locaImgSrc ?? apiImgSrc}");
                    background-size: cover;
                }
                &:hover svg{
                    display: block;
                }
                &:hover div{
                    background-color: #fff;
                    border: 1px solid ${config.colors.focus};
                    opacity: .8;
                }
            `)
          )}
        >
          {!disabled && (
            <>
              <div className="w-full h-full rounded-sm" />
              <Trash
                size={20}
                className="text-focus hidden absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
              />
            </>
          )}
        </div>
      ) : (
        <Gallery
          data-tip={t('Upload image')}
          data-for="upload"
          size={20}
          className={cx({
            'text-ceil': !error,
            'text-error': error,
            'cursor-pointer hover:text-focus': !disabled,
          })}
        />
      )}

      <input
        disabled={disabled}
        id={uniq}
        className="hidden"
        type="file"
        accept="image/*"
        onClick={(e) => {
          if (locaImgSrc || apiImgSrc) {
            e.preventDefault();
            setLocalImgSrc('');
            setApiImgSrc('');
          }
        }}
        onChange={(e) => {
          const image = e.target.files?.[0];
          if (!image || !image.type.includes('image/')) return;

          uploadImage(image);
          e.target.value = '';
        }}
      />
      {!disabled && (
        <ReactTooltip
          place="top"
          effect="solid"
          class="react-tooltip"
          id="upload"
        />
      )}
    </label>
  );
};

export default PollOptionImage;
