import config from 'app/config';
import { LinksApi } from 'submodules/common-ui/generated/api/sub-domain-config';

import { createCustomInstance } from '../../request';

const getLinks = (subdomain: string) => {
  const instance = createCustomInstance({
    disableCamelCaseConversion: false,
    url: config.env.subdomainApiUrl,
  });

  return new LinksApi(undefined, config.env.subdomainApiUrl, instance).getLinks(
    subdomain
  );
};

export { getLinks };
export default getLinks;
