import { useNode, Node, useEditor } from '@craftjs/core';
import { cx } from '@emotion/css';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import {
  ACTION_BLOCKS,
  ATTACHMENT_BLOCKS,
  MEDIA_BLOCKS,
} from 'app/pages/Editor/helpers/blocks';
import BlockTypes from 'app/pages/Editor/helpers/constants';
import { ReactNode, useEffect, useMemo } from 'react';

const DraggableContainer = ({
  children,
  parentId,
}: {
  children?: ReactNode;
  parentId: string;
}) => {
  const { t } = useArticlesTranslation();

  const { nodes } = useEditor((state) => ({
    nodes: state.nodes,
  }));

  const {
    id,
    actions: { setProp },
    connectors: { connect, drag },
  } = useNode();

  const isActionBlockNext = useMemo(() => {
    const dropableRegionNodes = nodes['dropableRegion'].data.nodes;

    const parentBlock = nodes[parentId];
    const parentBlockIndex = dropableRegionNodes.indexOf(parentBlock.id);
    const nextBlockId = dropableRegionNodes[parentBlockIndex + 1];
    const nextBlock = nodes[nextBlockId];

    const blocks = [...ATTACHMENT_BLOCKS, ...ACTION_BLOCKS, ...MEDIA_BLOCKS];

    return !!(nextBlock && blocks.includes(nextBlock.data.displayName));
  }, [nodes, parentId]);

  useEffect(() => {
    setProp((props: { isHidden: boolean }) => {
      props.isHidden = isActionBlockNext;
    });
  }, [isActionBlockNext, setProp]);

  if (isActionBlockNext) return null;

  return (
    <div
      id={id}
      ref={(ref) => ref && connect(drag(ref))}
      className={cx({
        'flex items-center justify-center border min-h-[72px] border-gray-light p-2 border-dashed mt-2 rounded':
          !children,
      })}
    >
      {children || (
        <span className="text-gray-dark text-sm">
          {t('Drag and drop actions')}
        </span>
      )}
    </div>
  );
};

DraggableContainer.craft = {
  displayName: BlockTypes.DraggableContainer,
  rules: {
    canMoveIn: (incomingNodes: Node[], _self: Node) => {
      const node = incomingNodes[0];

      return ACTION_BLOCKS.includes(node.data.displayName);
    },
    canMoveOut: () => false,
  },
};

export default DraggableContainer;
