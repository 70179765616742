import { cx } from '@emotion/css';

interface GradientTextProps {
  text: string;
  className?: string;
  disabled?: boolean;
}

const GradientText = ({ text, className, disabled }: GradientTextProps) => {
  return (
    <span
      className={cx(
        {
          'bg-gradient-to-b from-[#B549D0] to-[#7C48D0] text-transparent bg-clip-text':
            !disabled,
          'text-grayscale-secondary': disabled,
        },
        className
      )}
    >
      {text}
    </span>
  );
};

export default GradientText;
