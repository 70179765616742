import { editorTypes } from 'app/router/constants';
import { selectors } from 'app/store/editor';
import { useSelector } from 'react-redux';

import Backlinks from '../Backlinks';
import EditorCanvas from '../components/EditorCanvas';
import SharedCommentPopup from '../components/SharedCommentPopUp';
import TopBar from '../components/TopBar/TopBar';

const ViewMode = () => {
  const article = useSelector(selectors.getArticle);
  const useTemplateView = useSelector(selectors.getUseTemplateView);

  return (
    <>
      {useTemplateView && <TopBar />}
      <EditorCanvas mode={editorTypes.view}>
        <Backlinks
          articleId={article?.id}
          className="flex-auto flex flex-col justify-end mt-5 lg:mt-0 lg:mb-5"
        />
      </EditorCanvas>

      {article?.sharingComment && <SharedCommentPopup />}
    </>
  );
};

export default ViewMode;
