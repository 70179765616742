import { resources, queryKeys } from 'app/api/user';
import { selectData } from 'app/utils';
import { useQuery } from 'react-query';

const useUserQuery = ({
  id,
  enabled = true,
}: {
  id: number;
  enabled?: boolean;
}) => {
  const { data, isLoading, isError } = useQuery(
    queryKeys.getUser(id),
    () => resources.getUser(id),
    { enabled, refetchOnWindowFocus: false }
  );

  return { data: selectData(data), isLoading, isError };
};

export default useUserQuery;
