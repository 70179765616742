import { queryKeys } from 'app/api/articles';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import useTranslateArticleMutation from 'app/pages/Articles/hooks/useTranslateArticleMutation';
import queryClient from 'app/query';
import { selectors } from 'app/store/editor';
import CrossIcon from 'assets/icons/cross.svg?react';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Alert from '../Alert';
import Modal from '../Modal';
import SubmitAndCancelButton from '../SubmitAndCancelButton';

interface Props {
  draftLanguages: string[];
  onClose: () => void;
  onSave: (isGoingBack?: boolean, afterSave?: () => void) => void;
}

const SaveAndTranslateModal = ({ onClose, onSave, draftLanguages }: Props) => {
  const { t } = useArticlesTranslation();
  const { mutateAsync: translate } = useTranslateArticleMutation();

  const [isLoading, setIsLoading] = useState(false);

  const article = useSelector(selectors.getArticle);

  const handleAutoTranslate = useCallback(async () => {
    if (!article) return;

    for (const toLanguage of draftLanguages)
      await translate({ articleId: Number(article.id), toLanguage });

    queryClient.invalidateQueries(queryKeys.article(article.id));
  }, [article, draftLanguages, translate]);

  const autoTranslateNonDefaultLanguages = useCallback(async () => {
    if (!article) return;

    const langs = article.languages
      .filter((l) => !l.isDefault)
      .map((l) => l.language);

    for (const toLanguage of langs)
      await translate({ articleId: Number(article.id), toLanguage });

    queryClient.invalidateQueries(queryKeys.article(article.id));
    queryClient.invalidateQueries(['articles']);
    onClose();
  }, [article, onClose, translate]);

  useEffect(() => {
    if (!isLoading || draftLanguages.length <= 0) return;

    handleAutoTranslate();
    setIsLoading(false);
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [article]);

  const handleSave = () => {
    setIsLoading(true);
    onSave();
  };
  const handleTranslateAndSave = () => {
    setIsLoading(true);
    onSave(false, autoTranslateNonDefaultLanguages);
  };

  return (
    <Modal width="md" onClose={onClose}>
      <div className="flex flex-col relative">
        <button
          className=" text-grayscale-secondary absolute right-0 top-0"
          onClick={onClose}
        >
          <CrossIcon />
        </button>
        <span className="block font-bold text-lg mb-3">
          {t('Save & translate changes?')}
        </span>
        <p className="mb-3 text-base">
          {t(
            "Article contains other languages, you can choose to save & auto-translate blocks you've added."
          )}
        </p>
        <Alert variant="error" className="mb-6 font-bold text-xs">
          {t('All non proofread blocks will be auto-translated as well')}
        </Alert>
        <SubmitAndCancelButton
          cancelLabel={t('Save without translating')}
          submitLabel={t('Save & Auto-translate')}
          isCancelDisabled={isLoading}
          isSubmitDisabled={isLoading}
          onSubmit={handleTranslateAndSave}
          onCancel={() => {
            handleSave();
            onClose();
          }}
        />
      </div>
    </Modal>
  );
};

export default SaveAndTranslateModal;
