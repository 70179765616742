import { request } from 'app/api/aiServiceRequest';
import config from 'app/config';
import { SummaryApi } from 'submodules/common-ui/generated/api/ai-service';

const getSummary = (articleId: number, actionId: number, language: string) =>
  new SummaryApi(undefined, config.env.aiServiceApiUrl, request()).getSummary(
    articleId,
    actionId,
    language
  );

export { getSummary };
export default getSummary;
