import { css, keyframes } from '@emotion/css';
import { useCallback, useMemo, useState } from 'react';

export type Animation = 'shake';

let animateTimeout: NodeJS.Timeout;

const useAnimate = (animation: Animation, duration?: number) => {
  const [animated, setAnimated] = useState(false);

  const animate = useCallback(() => {
    animateTimeout && clearTimeout(animateTimeout);

    setAnimated(true);
    animateTimeout = setTimeout(() => setAnimated(false), duration ?? 300);
  }, [duration]);

  const animationClassName = useMemo(() => {
    if (!animated) return;

    if (animation === 'shake') {
      return css(`animation: ${shake} ${duration ?? 300}ms`);
    }
  }, [animated, animation, duration]);

  return { animationClassName, animate };
};

const shake = keyframes`
  0% { transform: translate3d(0, 0, 0) }
  25% { transform: translate3d(5px, 0, 0) }
  50% { transform: translate3d(-5px, 0, 0) }
  75% { transform: translate3d(5px, 0, 0) }
  100% { transform: translate3d(0, 0, 0) }
`;

export default useAnimate;
