import useGetBacklinksQuery from 'app/api/articles/hooks/useGetBacklinksQuery';
import { useAuthenticatedUser } from 'app/api/auth/hooks';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { editorTypes } from 'app/router/constants';
import routes from 'app/router/routes';
import { selectors } from 'app/store/editor';
import { HTMLAttributes, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Backlink,
  UnavailableBacklink,
} from 'submodules/common-ui/generated/api/gcs';

import BacklinkCard from './Backlink';
import Skeleton from './Skeleton';

interface BacklinksProps {
  articleId?: number;
  className?: string;
}

const Backlinks = ({ articleId, className }: BacklinksProps) => {
  const navigate = useNavigate();
  const { data: user } = useAuthenticatedUser();
  const { mode } = useParams<{ mode: string }>();
  const { data, isLoading } = useGetBacklinksQuery({
    articleId: Number(articleId),
    enabled: articleId !== undefined,
    filter: {
      timezone: mode !== editorTypes.edit ? user?.timezone : undefined,
      live: mode !== editorTypes.edit ? '1' : undefined,
    },
  });
  const selectedLanguages = useSelector(selectors.getSelectedLanguages);
  const mainLanguage =
    selectedLanguages.find((sl) => sl.isDefault)?.code ?? 'en';
  const backlinks = data?.filter(
    (backlink) => !(backlink as UnavailableBacklink).message
  );

  if (isLoading)
    return (
      <Container className={className}>
        {Array.from({ length: 4 }).map((_, index) => (
          <Skeleton className="w-[168px]" key={index} />
        ))}
      </Container>
    );

  if (!backlinks || backlinks.length === 0) return null;

  return (
    <Container className={className}>
      {backlinks.map((backlink, index) => (
        <BacklinkCard
          key={index}
          backlink={
            (backlink as Backlink).variants[mainLanguage] ??
            (backlink as Backlink).variants['en']
          }
          onClick={() =>
            navigate(
              routes.editorArticle.create(
                backlink.id.toString(),
                mode === 'view' ? 'view' : 'actions'
              )
            )
          }
        />
      ))}
    </Container>
  );
};

const Container = ({
  className,
  children,
}: HTMLAttributes<HTMLDivElement> & PropsWithChildren) => {
  const { t } = useArticlesTranslation();

  return (
    <div className={className}>
      <div className="w-full lg:w-[792px] p-4 bg-white rounded-t-lg lg:rounded-lg shadow-atobi">
        <h2 className="text-lg lg:text-lg2 text-grayscale-primary font-bold">
          {t('Related content')}
        </h2>
        <p className="text-grayscale-secondary">
          {t('Articles that might be relevant to you')}
        </p>
        <div className="flex flex-wrap mt-4 gap-4 items-start">{children}</div>
      </div>
    </div>
  );
};

export default Backlinks;
