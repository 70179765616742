import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import { ResizeableTextarea } from 'app/components';
import config from 'app/config';
import useCommentTranslation from 'app/internationalization/hooks/useCommentTranslation';
import { Send as SendMessageIcon } from 'iconsax-react';

import { useCommentsContext } from '../context';

import CommentActionMenu from './CommentActionMenu';

const CommentBottomMenu = () => {
  const { t } = useCommentTranslation();
  const {
    allowComments,
    commentAction,
    currentUser,
    text,
    isLoading,
    onCreateComment,
    setCommentAction,
    setText,
  } = useCommentsContext();

  const onSubmit = () => {
    onCreateComment();
    if (commentAction) setCommentAction(undefined);
  };

  return (
    <div className="flex flex-col items-center sticky z-50 bottom-0">
      <CommentActionMenu />
      <div
        className={cx(
          'flex items-center py-4 px-6 bg-white w-full shadow-atobi sticky z-50 bottom-0',
          {
            'opacity-50': !allowComments,
          }
        )}
      >
        <img
          className="w-10 h-10 rounded mr-3"
          src={currentUser?.avatars?.small}
          alt="avatar"
        />
        <div className="relative w-full leading-[0]">
          <StyledResizeableTextarea
            disabled={!allowComments}
            defaultHeight={40}
            name="comment"
            placeholder={t('Add a comment') || ''}
            className="bg-grayscale-bg-dark text-black w-full h-auto max-h-30 overflow-auto resize-none focus:outline-none focus:border-focus pl-4 p-2 pr-10 rounded border border-gray-dark text-sm"
            value={text}
            onChange={({ target }) => setText(target.value)}
            autoFocus
          />
          {text && (
            <button
              className="absolute right-2 bottom-2"
              onClick={onSubmit}
              disabled={isLoading}
            >
              <SendMessageIcon
                color={config.colors.focus}
                height={16}
                width={16}
              />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const StyledResizeableTextarea = styled(ResizeableTextarea)`
  & {
    scrollbar-width: thin;
  }

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export default CommentBottomMenu;
