import { useNode } from '@craftjs/core';
import { css, cx } from '@emotion/css';
import useGetArticleBlock from 'app/api/articles/hooks/useGetArticleBlock';
import { useAuthenticatedUser } from 'app/api/auth/hooks';
import config from 'app/config';
import useUnblockNextSection from 'app/hooks/useUnblockNextSection';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import useAnswerActionMutation from 'app/pages/ArticleEditor/hooks/useAnswerActionMutation';
import { actions, selectors } from 'app/store/editor';
import {
  actions as modalActions,
  selectors as modalSelectors,
} from 'app/store/modal';
import logger from 'app/utils/logger';
import { CloseCircle, Flag, MinusCirlce, TickCircle } from 'iconsax-react';
import {
  ComponentProps,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DbMultiChoiceAnswer,
  GetChoiceSchema,
  PostActionAnswer,
  Text,
  UserAnswerResponse,
  ActionAnswerResponse,
} from 'submodules/common-ui/generated/api/gcs';

import YesNoNaBlock from '../../../../YesNoNa';
import { useCurrentSelectedInstance } from '../../context';
import ActionContainer from '../ActionContainer';
import EditorNavigation from '../EditorNavigation';
import { YesNoConfirmModalProps } from '../Modals/YesNoConfirmModal';

import ActionButton from './ActionButton';
import AddOwnAnswerButton from './AddOwnAnswerButton';
import AnswerActionScore, {
  EditorAnswerActionScore,
} from './AnswerActionScore';
import AnswerFeedback from './AnswerFeedback';
import useButtonLabel from './useButtonLabel';
import useDisplayAnswer from './useDisplayAnswer';

interface YesNoQuestionProps {
  actionId: string;
  selectedProps: ComponentProps<typeof YesNoNaBlock>;
  onAnswer: (data: UserAnswerResponse, actionId: string) => void;
  onUndo: (actionId: string) => void;
  isEditor?: boolean;
  NavigationContainer: ({
    children,
  }: {
    children: React.ReactNode;
  }) => JSX.Element;
}

const YesNoNaAnswerAction: FC<YesNoQuestionProps> = ({
  actionId,
  selectedProps,
  onAnswer,
  isEditor = false,
  NavigationContainer,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const { t } = useArticlesTranslation();

  const dispatch = useDispatch();

  const [answer, setAnswer] = useState<GetChoiceSchema>();
  const [isAddingOwnAnswer, setIsAddingOwnAnswer] = useState(false);

  const {
    mutate: answerAction,
    isLoading,
    isSuccess,
    reset,
    isError,
  } = useAnswerActionMutation();

  const currentLanguage = useSelector(selectors.getActiveLanguage);

  const { modalProps } = useSelector(modalSelectors.getOpenedModals);

  const { data: user } = useAuthenticatedUser();

  const {
    block: contextBlock,
    currentSelectedInstanceId,
    refetch: contextRefetch,
    answeredByColleague,
    colleagueAnswer,
  } = useCurrentSelectedInstance();

  const { block, refetch } = useGetArticleBlock({
    articleId: selectedProps.articleId!,
    blockId: parseInt(actionId),
    enabled: !!selectedProps.articleId && !contextBlock,
    timezone: user?.timezone,
    live: '1',
  });

  const activeBlock = contextBlock || block;

  const activeRefetch = useMemo(
    () => (contextBlock ? contextRefetch : refetch),
    [contextBlock, refetch, contextRefetch]
  );

  const currentInstanceId =
    currentSelectedInstanceId ?? activeBlock?.currentInstanceId;

  const currentUserAnswersList = activeBlock?.currentUserAnswers || [];
  const currentInstanceAnswer = currentUserAnswersList.find(
    (currentUserAnswer: ActionAnswerResponse) =>
      currentUserAnswer.actionInstanceId === currentInstanceId
  );

  const isActionCompleted =
    !!currentInstanceAnswer && currentInstanceAnswer.revertedAt === null;

  const displayAnswer = useDisplayAnswer({
    isAddingOwnAnswer,
    isActionCompleted,
    answeredByColleague,
    currentInstanceAnswer,
    colleagueAnswer,
  });

  const shouldShowFeedback =
    (displayAnswer?.score !== undefined && isActionCompleted) ||
    isError ||
    isSuccess ||
    (answeredByColleague && !isAddingOwnAnswer);

  const showIsError =
    isError ||
    (isSuccess &&
      (selectedProps.minCorrectAnswers ?? 0) === 0 &&
      !(displayAnswer?.answer as DbMultiChoiceAnswer)?.[0]?.correct);
  const showIsLoop = (selectedProps.minCorrectAnswers ?? 0) > 0 && isError;

  const feedbackScore = displayAnswer?.score || 0;

  const ableToAnswer = useMemo(() => !isActionCompleted, [isActionCompleted]);

  const title = activeBlock?.variants?.[currentLanguage]?.title;
  const description = activeBlock?.variants?.[currentLanguage]?.description;

  const completeAction = useCallback(() => {
    if (!activeBlock?.choices) return;

    const selectedAnswer = answer ?? activeBlock.choices[2];
    if (!selectedAnswer) return;

    const payload: PostActionAnswer = {
      actionId: parseInt(actionId),
      language: currentLanguage,
      actionInstanceId: currentInstanceId,
      answer: [selectedAnswer.id],
      timezone: user?.timezone,
    };

    answerAction(payload, {
      onError: (e) => logger.error(e),
      onSuccess: (data) => {
        onAnswer(data, actionId);
        dispatch(
          actions.setStats({
            mandatoryProgress: data.mandatoryProgress,
            mandatoryScore: data.totalMandatoryScore,
            progress: data.progress,
            score: data.score,
          })
        );
        reset();
        activeRefetch();
        setIsAddingOwnAnswer(false);
      },
    });
  }, [
    actionId,
    answer,
    currentInstanceId,
    user?.timezone,
    answerAction,
    activeBlock?.choices,
    currentLanguage,
    dispatch,
    reset,
    onAnswer,
    activeRefetch,
  ]);

  const onSelectAnswer = (choice: GetChoiceSchema) => {
    if (!ableToAnswer) return;
    setAnswer(choice);
  };

  const isSelectedAnswer = (choice: GetChoiceSchema): boolean => {
    if (isAddingOwnAnswer || !answeredByColleague) {
      return choice.id === answer?.id;
    }

    if (displayAnswer) {
      const userAnswerChoices = displayAnswer.answer as DbMultiChoiceAnswer;
      return userAnswerChoices.some(
        (selectedChoice) => selectedChoice.id === choice.id
      );
    }

    return false;
  };

  const isTooEarly = currentInstanceId === null;
  const buttonLabel = useButtonLabel(
    isLoading,
    isSuccess,
    isTooEarly,
    activeBlock?.type
  );

  const renderFooterContent = () => {
    const hasAnswer = !!answer;

    if (isAddingOwnAnswer) {
      return (
        <ActionButton
          onClick={completeAction}
          disabled={!hasAnswer || isTooEarly}
          isTooEarly={isTooEarly}
          answer={hasAnswer ? 'Has answer' : ''}
          buttonLabel={buttonLabel}
        />
      );
    }

    if (answeredByColleague && !isActionCompleted) {
      return (
        <AddOwnAnswerButton
          answeredByColleague={answeredByColleague}
          isActionCompleted={isActionCompleted}
          onAddAnswer={() => setIsAddingOwnAnswer(true)}
        />
      );
    }

    return (
      ableToAnswer && (
        <ActionButton
          onClick={completeAction}
          disabled={!hasAnswer || isTooEarly}
          isTooEarly={isTooEarly}
          answer={hasAnswer ? 'Has answer' : ''}
          buttonLabel={buttonLabel}
        />
      )
    );
  };

  const renderAnswerActionScore = () => {
    if (isEditor) return <EditorAnswerActionScore totalScore={0} />;
    return (
      <AnswerActionScore
        selectedProps={selectedProps}
        showQuizScore={false}
        showProgressScore={false}
        totalScore={0}
        blockId={parseInt(actionId)}
      />
    );
  };

  useEffect(() => {
    if (currentInstanceAnswer) {
      const choiceItem = activeBlock?.choices?.find(
        (choice: GetChoiceSchema) =>
          choice.id ===
          (currentInstanceAnswer.answer as DbMultiChoiceAnswer)?.[0].id
      );
      return setAnswer(choiceItem);
    }
    setAnswer(undefined);
  }, [activeBlock?.choices, currentInstanceAnswer]);

  useEffect(() => {
    const { actionType } = modalProps as YesNoConfirmModalProps;
    if (actionType === 'confirm') {
      dispatch(modalActions.hideModal());
      completeAction();
    }
  }, [activeBlock?.choices, completeAction, dispatch, modalProps]);

  const renderChoiceIcon = (index: number) => {
    switch (index) {
      case 0:
        return <TickCircle className="mr-1" color={config.colors.success} />;
      case 1:
        return <CloseCircle className="mr-1" color={config.colors.error} />;
      case 2:
        return <MinusCirlce className="mr-1" color={config.colors.warning} />;
    }
  };

  const userAnsweredAsNa = () =>
    (displayAnswer?.answer as DbMultiChoiceAnswer)?.[0]?.correct === null;

  return (
    <>
      {renderAnswerActionScore()}
      <ActionContainer
        isMandatory={selectedProps.mandatory}
        type={'yes_no_task'}
        canAnswer={true}
      >
        <div className="flex flex-col gap-2">
          <span
            className={cx('text-lg font-bold', css('word-break: break-word;'))}
          >
            {title}
          </span>
          <span className={cx('text-black', css('word-break: break-word;'))}>
            {description}
          </span>
        </div>
        <div className="flex flex-col gap-3 mt-4">
          {userAnsweredAsNa() ? (
            <div className="pt-6 flex  items-center cursor-pointer">
              <Flag className="mr-1" size={20} color={config.colors.focus} />
              <span className="text-sm font-custom">
                {t('Marked as not possible to answer')}
              </span>
            </div>
          ) : (
            <>
              {activeBlock?.choices &&
                activeBlock.choices.map(
                  (choice: GetChoiceSchema, index: number) => {
                    const choiceVariants = choice.variants;
                    const variant = choiceVariants?.[currentLanguage]
                      ? choiceVariants[currentLanguage]
                      : choiceVariants[Object.keys(choiceVariants)[0]];

                    return (
                      <button
                        key={index}
                        onClick={() => onSelectAnswer(choice)}
                        disabled={Boolean(
                          isActionCompleted ||
                            (answeredByColleague && !isAddingOwnAnswer)
                        )}
                        className={cx('rounded-lg border', {
                          'border-focus bg-focus-background':
                            isSelectedAnswer(choice),
                          'border-gray-dark': !isSelectedAnswer(choice),
                        })}
                      >
                        <div className="flex p-4">
                          {renderChoiceIcon(index)}
                          <span className="font-bold">
                            {variant.answer?.children?.map(
                              (text) => (text as Text).value
                            )}
                          </span>
                          {variant.image?.url && (
                            <img
                              className="max-h-[210px] w-auto mt-3 rounded self-center"
                              src={`${config.env.gcsApiUrl}/${variant.image?.url}}`}
                              alt=""
                            />
                          )}
                        </div>
                      </button>
                    );
                  }
                )}
            </>
          )}
        </div>

        {shouldShowFeedback && (
          <AnswerFeedback
            isSubmitted={isSuccess || isError}
            isError={showIsError}
            isLoop={showIsLoop}
            score={feedbackScore}
            explanation={activeBlock?.variants?.[currentLanguage]?.explanation}
          />
        )}

        <div className="flex justify-between items-center w-full mt-6">
          <NavigationContainer>{renderFooterContent()}</NavigationContainer>
        </div>
      </ActionContainer>
    </>
  );
};

export const EditorYesNoNaQuestionAction = () => {
  const {
    selectedProps,
    nodeId,
    actions: { setProp },
  } = useNode((node) => ({
    selectedProps: node.data.props as ComponentProps<typeof YesNoNaBlock>,
    nodeId: node.id,
  }));
  const { setIsUnblocked } = useUnblockNextSection(nodeId);

  const { data: user } = useAuthenticatedUser();

  if (!user) return null;

  return (
    <YesNoNaAnswerAction
      selectedProps={selectedProps}
      actionId={nodeId}
      onAnswer={(data) => {
        setProp((props: ComponentProps<typeof YesNoNaBlock>) => {
          props.currentUserAnswers = [{ ...data, completedBy: user.id }];
          props.completed = true;
        });

        setIsUnblocked(true);
      }}
      onUndo={() => {
        setProp((props: ComponentProps<typeof YesNoNaBlock>) => {
          props.currentUserAnswers = [];
          props.completed = false;
        });
      }}
      isEditor={true}
      NavigationContainer={EditorNavigation}
    />
  );
};

export default YesNoNaAnswerAction;
