import useAuthenticatedUser from 'app/api/auth/hooks/useAuthenticatedUser';
import { useCallback } from 'react';

const useIsViewer = (audiences: Array<number>) => {
  const { data: user } = useAuthenticatedUser();

  const isViewer = useCallback((): boolean => {
    if (audiences.length === 0) return true;

    const userAudienceIds = user?.audiences.map((ua) => ua.id) ?? [];

    return audiences.every((qa) => !userAudienceIds.includes(qa));
  }, [audiences, user?.audiences]);

  const _isViewer = isViewer();

  return {
    isViewer: _isViewer,
  };
};

export default useIsViewer;
