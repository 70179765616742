import { cx } from '@emotion/css';
import { FC, ReactNode } from 'react';

interface ActionButtonProps {
  onClick: () => void;
  disabled: boolean;
  isTooEarly: boolean;
  answer: string;
  buttonLabel: ReactNode;
}

const ActionButton: FC<ActionButtonProps> = ({
  onClick,
  disabled,
  isTooEarly,
  answer,
  buttonLabel,
}) => {
  return (
    <button
      type="button"
      className={cx(
        'flex items-center justify-center w-full h-12 mx-5 rounded-xl bg-focus',
        {
          'bg-gray-light text-grayscale-secondary': isTooEarly || !answer,
          'text-white': !!answer && !isTooEarly,
        }
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {buttonLabel}
    </button>
  );
};

export default ActionButton;
