import { UpcomingActionsFilter } from './types';

export default {
  channels: ({
    ranking,
    refetchOnWindowFocus,
    refetchOnMount,
  }: {
    ranking: 'asc' | 'desc';
    refetchOnWindowFocus: boolean;
    refetchOnMount: boolean;
  }) => ['channels', ranking, refetchOnWindowFocus, refetchOnMount],
  channel: (channelId: number, live?: '1') => ['channel', channelId, live],
  relevantChannels: () => ['relevantChannels'],
  upcomingActions: (filter?: UpcomingActionsFilter) => [
    'upcomingActions',
    filter,
  ],
};
