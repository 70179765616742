import { request } from 'app/api/gcsRequest';
import config from 'app/config';
import { CommentApi, NewComment } from 'submodules/common-ui/generated/api/gcs';

const createComment = (newComment: NewComment) =>
  new CommentApi(undefined, config.env.gcsApiUrl, request()).createComment(
    newComment
  );

export default createComment;
