import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import { useFeatures } from '@paralleldrive/react-feature-toggles';
import { SisenseContextProvider } from '@sisense/sdk-ui';
import useGetWebAccessToken from 'app/api/sisense/hooks/useGetWebAccessToken';
import { Navigation, ScrollbarContainer, TopMenu } from 'app/components';
import MobileNavigation, {
  BOTTOM_MENU_HEIGHT,
} from 'app/components/MobileNavigation/MobileNavigation';
import MobileTopMenu, {
  MOBILE_TOP_MENU_HEIGHT,
} from 'app/components/MobileTopMenu/MobileTopMenu';
import MobileSideMenu from 'app/components/Navigation/MobileSideMenu';
import {
  SIDEBAR_ANIMATION_DURATION,
  SIDEBAR_ANIMATION_TRANSLATION,
} from 'app/components/Navigation/types';
import AndroidChromePopup, {
  MOBILE_PWAINSTALL_HEIGHT,
} from 'app/components/PWAInstallPopup/AndroidChromePopup';
import ChromePopup from 'app/components/PWAInstallPopup/ChromePopup';
import IosPopup from 'app/components/PWAInstallPopup/IosPopup';
import ToastContainer from 'app/components/Toast/ToastContainer';
import config from 'app/config';
import { ChatContextProvider } from 'app/context/ChatContext';
import AuthTokenHandler from 'app/handlers/AuthTokenHandler';
import IframedCheckHandler from 'app/handlers/IframedCheckHandler';
import { useDispatch, useScreenBreakpoint, useSelector } from 'app/hooks';
import { routes } from 'app/router';
import { selectors, actions } from 'app/store/navigation';
import { isActiveFeatureName, isMobile as resolveIsMobile } from 'app/utils';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { ChatEventsHandler, ChatTokenHandler } from '../../handlers';
import CallProvider from '../../pages/VideoChat/providers/CallProvider';

export const resolveVariant = (isVisible: boolean, isMobile: boolean) => {
  if (isVisible) {
    if (isMobile) return 'mobile';

    return 'desktop';
  }

  return isMobile ? 'mobile-hidden' : 'desktop-clipped';
};

const AuthenticatedPageLayout = () => {
  const breakpoint = useScreenBreakpoint();
  const isMobile = resolveIsMobile(breakpoint);
  const dispatch = useDispatch();
  const features = useFeatures();
  const isSidebarVisible = useSelector(selectors.getIsSidebarOpen);
  const tabsHidden = useSelector(selectors.tabsHidden);
  const isFullPage = useSelector(selectors.getIsFullPage);
  const mobilePwaPopupHidden = useSelector(selectors.getIsMobilePwaPopupHidden);
  const variant = resolveVariant(isSidebarVisible, isMobile);
  const isVideoChatEnabled = isActiveFeatureName('videoChat', features);
  const location = useLocation();

  const { token: sisenseToken } = useGetWebAccessToken();

  useEffect(() => {
    if (isMobile) {
      dispatch(actions.sideMenuDisplayChanged(false));
    }
  }, [dispatch, isMobile, location]);

  const toggleSidebar = () =>
    dispatch(actions.sideMenuDisplayChanged(!isSidebarVisible));

  const isArticleEditor = location.pathname.includes(routes.editor.create());
  const isChatThread =
    location.pathname.includes('/chat/') &&
    location.pathname.split('/').filter((a: string) => a !== '').length === 2;

  const renderHandlers = () => {
    return (
      <AuthTokenHandler>
        <IframedCheckHandler>
          <ChatTokenHandler>
            <ChatContextProvider>
              <ChatEventsHandler>
                {sisenseToken ? (
                  <SisenseContextProvider
                    wat={sisenseToken}
                    url={config.env.sisenseUrl}
                    showRuntimeErrors={false}
                  >
                    {renderContent()}
                  </SisenseContextProvider>
                ) : (
                  renderContent()
                )}
              </ChatEventsHandler>
            </ChatContextProvider>
          </ChatTokenHandler>
        </IframedCheckHandler>
      </AuthTokenHandler>
    );
  };

  const renderContent = () => (
    <>
      {!isMobile && <ChromePopup />}
      <div className="flex w-full">
        {!isFullPage && !isArticleEditor && (
          <>
            {isMobile ? (
              <>
                <MobileSideMenu toggle={toggleSidebar} variant={variant} />
                {!isChatThread && <MobileNavigation />}
              </>
            ) : (
              <Navigation toggle={toggleSidebar} variant={variant} />
            )}
          </>
        )}
        <div
          className={cx(
            'flex flex-col items-center w-full md:h-screen overflow-x-hidden',
            {
              'md:py-3 md:pr-3 md:bg-gray-white-blue': !isArticleEditor,
            }
          )}
        >
          <ContentContainer
            isArticleEditor={isArticleEditor}
            className={cx(
              'flex flex-col items-center w-full overflow-x-hidden flex-1',
              {
                'md:border md:rounded-[14px] md:border-gray-silver-blue':
                  !isArticleEditor,
              }
            )}
          >
            {!isFullPage && !isChatThread && !isArticleEditor && isMobile && (
              <div className="w-full z-[11] fixed md:hidden">
                <AndroidChromePopup />
                <MobileTopMenu
                  toggleSidebar={toggleSidebar}
                  tabsHidden={tabsHidden}
                />
              </div>
            )}

            {!isFullPage && !isArticleEditor && !isMobile && (
              <div className="w-full hidden md:block">
                <TopMenu toggleSidebar={toggleSidebar} isMobile={isMobile} />
              </div>
            )}

            <ScrollbarContainer
              className={cx(
                'w-full h-full flex relative bg-white',
                isMobile && isSidebarVisible
                  ? SIDEBAR_ANIMATION_TRANSLATION
                  : SIDEBAR_ANIMATION_DURATION,
                {
                  'md:rounded-b-[14px]': !isArticleEditor,
                  'justify-center': !isSidebarVisible,
                }
              )}
              overflow={isMobile}
            >
              <ContentWrapper
                className="w-full flex justify-center"
                isChatThread={isChatThread}
                isFullPage={isFullPage}
                installHidden={mobilePwaPopupHidden}
                tabsHidden={tabsHidden}
              >
                <>
                  <IosPopup />
                  <Outlet />
                </>
              </ContentWrapper>
            </ScrollbarContainer>
          </ContentContainer>
        </div>
      </div>
      <ToastContainer toastId="ArticleRejection" />
    </>
  );

  const ContentContainer = styled.div<{ isArticleEditor: boolean }>`
    ${(props) =>
      !props.isArticleEditor &&
      `@media (min-width: 768px) {
          box-shadow: -3px 0px 4px 2px rgba(230, 230, 230, 0.25);
        }`}
  `;

  const ContentWrapper = styled.div`
    ${(props: {
      isChatThread: boolean;
      isFullPage: boolean;
      installHidden: boolean;
      tabsHidden: boolean;
    }) => {
      if (!props.isChatThread && !props.isFullPage) {
        return `
        @media (max-width: 768px) {
          margin-bottom: calc(${props.tabsHidden ? '0px' : BOTTOM_MENU_HEIGHT} + env(safe-area-inset-bottom));
          margin-top:${
            !props.installHidden
              ? `calc(${MOBILE_TOP_MENU_HEIGHT} + ${MOBILE_PWAINSTALL_HEIGHT})`
              : MOBILE_TOP_MENU_HEIGHT
          };
        }`;
      }
    }}
  `;

  return isVideoChatEnabled ? (
    <CallProvider>{renderHandlers()}</CallProvider>
  ) : (
    renderHandlers()
  );
};

export default AuthenticatedPageLayout;
