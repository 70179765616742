import { cx } from '@emotion/css';
import useGetActionAnswer from 'app/api/articles/hooks/useGetActionAnswer';
import useGetActionAnswerSummary from 'app/api/articles/hooks/useGetActionAnswerSummary';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { Danger } from 'iconsax-react';
import { FC } from 'react';
import { MultiChoiceBlockV1 } from 'submodules/common-ui/generated/api/gcs';

import AnswerContainer from './AnswerContainer';
import { useAnswersContext } from './context';
import LoadingPollOption from './PollTask/LoadingPollOption';
import PollOption from './PollTask/PollOption';

interface Props {
  block: MultiChoiceBlockV1;
}

const PollTaskAnswers: FC<Props> = ({ block }) => {
  const { t } = useArticlesTranslation();

  const { setSelectedTaskId, selectedTenant } = useAnswersContext();

  const { actionAnswers, isLoading, error } = useGetActionAnswer({
    id: block.id,
    originalArticleId: selectedTenant?.originalArticleId,
  });

  const { summary, isLoading: isLoadingSummary } = useGetActionAnswerSummary({
    actionId: block.id,
    originalArticleId: selectedTenant?.originalArticleId,
  });

  const showMoreBtnStyling = cx('w-[342px] h-[48px] rounded-xl bg-hover-blue');

  const getChoiceSummary = (choiceId: number) => {
    if (!summary || !summary.choices) return undefined;
    return summary.choices.find((choice) => choice.choiceId === choiceId);
  };

  return (
    <AnswerContainer block={block}>
      {!isLoading &&
        !isLoadingSummary &&
        block.choices.map((option) => (
          <PollOption
            option={option}
            key={option.id}
            choiceSummary={getChoiceSummary(option.id)}
          />
        ))}

      {(isLoading ||
        isLoadingSummary ||
        error ||
        actionAnswers?.length === 0) && (
        <div className="relative">
          <LoadingPollOption />
          <LoadingPollOption />

          {error && (
            <div className="absolute m-auto left-0 right-0 bottom-0 top-0 h-7 w-fit bg-light rounded-[6px] py-1 px-2 text-error flex gap-1 justify-center items-center">
              <Danger className="w-4 h-4" />
              <span className="text-sm">
                {t('Unable to receive answers, please try again later.')}
              </span>
            </div>
          )}

          {actionAnswers?.length === 0 && (
            <div className="absolute m-auto left-0 right-0 bottom-0 top-0 h-7 w-fit bg-light rounded-[6px] py-1 px-2 text-sm text-grayscale-secondary">
              {t('There are no answers to this action yet.')}
            </div>
          )}
        </div>
      )}

      {!isLoading &&
        !isLoadingSummary &&
        (actionAnswers?.length ?? 0) > 0 &&
        !error && (
          <button
            onClick={() => setSelectedTaskId(block.id)}
            className={cx(
              showMoreBtnStyling,
              'text-sm p-3 text-focus hover:bg-focus-background'
            )}
          >
            {t('Show More')}
          </button>
        )}

      {(isLoading || isLoadingSummary || actionAnswers?.length === 0) && (
        <div className={showMoreBtnStyling}></div>
      )}
    </AnswerContainer>
  );
};

export default PollTaskAnswers;
