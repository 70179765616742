import { cx } from '@emotion/css';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { ActionInstance } from 'submodules/common-ui/generated/api/gcs';

import { useAnswersContext } from '../../context';

interface Props {
  instance: ActionInstance;
  frequency?: string;
  instanceLoading: boolean;
}

const Instance: React.FC<Props> = ({
  instance,
  frequency,
  instanceLoading,
}) => {
  const { setSelectedInstanceId, selectedInstanceId } = useAnswersContext();

  const isDaily = frequency === 'daily';
  const isSelected = instance.id === selectedInstanceId;

  useEffect(() => {
    return () => {
      setSelectedInstanceId(undefined);
    };
  }, [setSelectedInstanceId]);

  const handleInstanceClick = () => {
    if (isSelected) {
      setSelectedInstanceId(undefined);
    } else {
      setSelectedInstanceId(instance.id);
    }
  };

  const formatDate = (date: string) => {
    return {
      day: dayjs(date).format('DD'),
      month: dayjs(date).format('MMM'),
      year: dayjs(date).format('YYYY'),
    };
  };

  const startDates = formatDate(instance.start);
  const endDates = formatDate(instance.end);

  return (
    <>
      {instanceLoading ? (
        isDaily ? (
          <div className="inline-flex justify-end items-center gap-3">
            <button className="flex flex-col justify-center rounded-lg border-hover-blue items-center bg-hover-blue p-2.5 gap-1">
              <div className="w-2 h-2 bg-[#CFCFEA] rounded-full"></div>
              <div className="flex justify-center items-end gap-2">
                <div className="flex flex-col justify-center items-start rounded-md bg-focus-background w-[28px] h-[52px]"></div>
              </div>
            </button>
          </div>
        ) : (
          <div className="inline-flex justify-end items-center gap-3">
            <button className="flex flex-col justify-center rounded-lg border-hover-blue items-center bg-hover-blue p-2.5 gap-1">
              <div className="w-2 h-2 bg-[#CFCFEA] rounded-full"></div>
              <div className="flex justify-center items-end gap-2">
                <div className="flex flex-col justify-center items-start rounded-md bg-focus-background w-[28px] h-[52px]"></div>
                <div className="w-px h-14 bg-focus-background"></div>
                <div className="flex flex-col justify-center items-start rounded-md bg-focus-background w-[28px] h-[52px]"></div>
              </div>
            </button>
          </div>
        )
      ) : (
        <div className="flex items-center justify-end gap-2">
          <button
            onClick={handleInstanceClick}
            className={cx(
              'flex flex-col justify-center items-center gap-1 p-2.5 border rounded-xl',
              {
                'bg-focus-background border-ceil': isSelected,
                'border-focus-background hover:bg-hover-blue': !isSelected,
              }
            )}
          >
            <div className="w-2 h-2 bg-[#CFCFEA] rounded-full"></div>

            {isDaily ? (
              <div className="flex justify-center items-end gap-2">
                <div
                  className={cx('flex flex-col justify-center items-center', {
                    'text-hover-primary': isSelected,
                    'text-focus': !isSelected,
                  })}
                >
                  <span
                    className={cx('text-sm', {
                      'font-bold': isSelected,
                    })}
                  >
                    {startDates.day}
                  </span>
                  <span className="text-sm">{startDates.month}</span>
                  <span className="text-sm">{startDates.year}</span>
                </div>
              </div>
            ) : (
              <div className="flex justify-center items-end gap-2">
                <div
                  className={cx('flex flex-col justify-center items-end', {
                    'text-hover-primary': isSelected,
                    'text-focus': !isSelected,
                  })}
                >
                  <span
                    className={cx('text-sm', {
                      'font-bold': isSelected,
                    })}
                  >
                    {startDates.day}
                  </span>
                  <span className="text-sm">{startDates.month}</span>
                  <span className="text-sm">{startDates.year}</span>
                </div>

                <div
                  className={cx('w-px h-14', {
                    'bg-hover-primary': isSelected,
                    'bg-focus-background': !isSelected,
                  })}
                ></div>

                <div
                  className={cx('flex flex-col justify-center items-start', {
                    'text-hover-primary': isSelected,
                    'text-focus': !isSelected,
                  })}
                >
                  <span
                    className={cx('text-sm', {
                      'font-bold': isSelected,
                    })}
                  >
                    {endDates.day}
                  </span>
                  <span className="text-sm">{endDates.month}</span>
                  <span className="text-sm">{endDates.year}</span>
                </div>
              </div>
            )}
          </button>
        </div>
      )}
    </>
  );
};

export default Instance;
