import { useQuery } from 'react-query';

import { resources, queryKeys } from '../index';

interface Props {
  enabled?: boolean;
}

const useGetConfigurationsQuery = ({ enabled = true }: Props) => {
  const { data, isLoading, error, isFetching, refetch } = useQuery(
    queryKeys.configurations(),
    () => resources.getConfigurations(),
    { enabled }
  );

  return {
    data: data?.data,
    isFetching,
    isLoading,
    error,
    refetch,
  };
};

export default useGetConfigurationsQuery;
