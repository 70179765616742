import { cx } from '@emotion/css';
import { useAuthenticatedUser } from 'app/api/auth/hooks';
import { Spinner } from 'app/components';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import useUserQuery from 'app/pages/profiles/PublicProfile/useUserQuery';
import dayjs from 'app/utils/dayjs';
import { Calendar, TickSquare } from 'iconsax-react';
import {
  ActionInstance,
  GetUserAnswer,
} from 'submodules/common-ui/generated/api/gcs';

import { useCurrentSelectedInstance } from '../context';

interface Props {
  instance: ActionInstance;
  title?: string;
  completed?: boolean;
  isCompletedByUser: boolean;
  latestColleagueAnswer: GetUserAnswer | undefined;
  userAvatarSrc: string;
  currentInstanceId: number;
}

const ActionInstancesItem = ({
  instance,
  title,
  completed,
  currentInstanceId,
  latestColleagueAnswer,
  isCompletedByUser,
  userAvatarSrc,
}: Props) => {
  const { t } = useArticlesTranslation();
  const { data: user } = useAuthenticatedUser();
  const {
    currentSelectedInstanceId: contextInstanceId,
    setCurrentSelectedInstanceId,
  } = useCurrentSelectedInstance();

  const colleagueId = latestColleagueAnswer?.completedBy || 0;

  const { data: colleagueProfile, isLoading: isProfileLoading } = useUserQuery({
    id: colleagueId,
    enabled: colleagueId !== 0,
  });

  const avatarSrc =
    !isCompletedByUser && latestColleagueAnswer
      ? colleagueProfile?.avatars?.small
      : userAvatarSrc;

  const getDate = (date: string | null) => dayjs(date).format('DD.MM.YYYY');

  const handleClick = () => {
    setCurrentSelectedInstanceId(instance.id);
  };

  const currentSelectedInstanceId = contextInstanceId ?? currentInstanceId;

  const isSelected = currentSelectedInstanceId === instance.id;

  return (
    <button
      onClick={handleClick}
      className={cx(
        'text-left w-full flex justify-between items-center p-3 mb-2 rounded-lg text-grayscale-secondary border border-focus-background shadow-action',
        {
          'bg-focus-background': isSelected,
          'bg-gray-dark-shadow': !isSelected,
        }
      )}
    >
      <div
        className={cx('flex items-center', {
          'opacity-50': completed,
        })}
      >
        <TickSquare size={20} className="mr-3" />
        <div className="flex flex-col">
          <span
            className={cx('text-grayscale-primary text-base font-bold', {
              'line-through': completed,
            })}
          >
            {title}
          </span>
          <div className="flex items-center">
            <Calendar className="mr-2" size={16} />
            <span className="text-sm">
              {t('Deadline was {{date}}', {
                date: getDate(instance.end),
              })}
            </span>
          </div>
        </div>
      </div>
      {isProfileLoading ? (
        <span className="w-5 h-5 flex">
          <Spinner className="w-5 h-5 animate-spin self-center" />
        </span>
      ) : (
        <img
          className="rounded h-7 w-7"
          src={avatarSrc}
          alt={user?.firstName}
        />
      )}
    </button>
  );
};

export default ActionInstancesItem;
