import { cx } from '@emotion/css';
import useDateTimeFormat from 'app/hooks/useDateTimeFormat';
import useUserQuery from 'app/pages/profiles/PublicProfile/useUserQuery';
import capitalize from 'lodash/capitalize';
import { DbYesNoTaskAnswer } from 'submodules/common-ui/generated/api/gcs';

import { useAnswersContext } from '../../context';
import UserDetails from '../UserDetails';

interface Props {
  userId: number;
  answer: DbYesNoTaskAnswer;
  createdAt: string;
}

const YesNoDeepDive = ({ userId, answer, createdAt }: Props) => {
  const { layout } = useAnswersContext();

  const { data: user, isLoading: isLoadingUser } = useUserQuery({ id: userId });

  const createdAtTime = useDateTimeFormat({
    timestamp: createdAt,
    type: 'dayMonthYearTime',
  });

  return (
    <div
      className={cx(
        'rounded-xl gap-4 bg-white shadow-atobi flex flex-col p-4',
        {
          'bg-hover-blue': isLoadingUser,
          'bg-grayscale-bg-dark': !isLoadingUser,
        }
      )}
    >
      <UserDetails
        isLoadingUser={isLoadingUser}
        user={user}
        createdAtTime={createdAtTime}
        layout={layout}
      />

      <span className="bg-grayscale-bg-dark px-3 py-2.5 rounded-lg border-[1px] border-focus-background text-sm break-words">
        {capitalize(answer)}
      </span>
    </div>
  );
};

export default YesNoDeepDive;
