import {
  EmojiEnum,
  ExtendedComment,
} from 'submodules/common-ui/generated/api/gcs';

const handleReactionInComment = (
  state: ExtendedComment,
  reaction: EmojiEnum,
  type: 'add' | 'remove'
) => {
  let newState: ExtendedComment = { ...state };
  const newReactionCounts = { ...newState.reactionCounts };
  let reactionCountsValue;

  const newReaction = newReactionCounts[reaction];

  if (newReaction) {
    reactionCountsValue = type === 'add' ? newReaction + 1 : newReaction - 1;
  } else {
    reactionCountsValue = type === 'add' ? 1 : 0;
  }

  if (reactionCountsValue === 0) {
    delete newReactionCounts[reaction];
  } else {
    newReactionCounts[reaction] = reactionCountsValue;
  }

  const currentUserReactions = [...newState.currentUserReactions];

  let newCurrentUserReactions: EmojiEnum[] = [];

  if (type === 'add') {
    newCurrentUserReactions = [...currentUserReactions, reaction];
  }

  if (type === 'remove') {
    newCurrentUserReactions = currentUserReactions.filter(
      (ur) => ur !== reaction
    );
  }

  newState = {
    ...newState,
    reactionCounts: newReactionCounts,
    currentUserReactions: newCurrentUserReactions,
  };
  return newState;
};

export default handleReactionInComment;
