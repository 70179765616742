import { useArticlesTranslation } from 'app/internationalization/hooks';

import SideMenuActionContainer from '../../../SideMenuActionContainer';

import TextBlockOption from './TextBlockOption';

interface Props {
  onClose: () => void;
}

const TextBlockDropdown = ({ onClose }: Props) => {
  const { t } = useArticlesTranslation();

  return (
    <SideMenuActionContainer title={t('Text Styles')} onClose={onClose}>
      <TextBlockOption
        type={{
          tag: 'h1',
          type: 'heading',
        }}
        className={'text-lg font-bold'}
        text={t('Heading')}
      />
      <TextBlockOption
        type={{
          tag: 'h2',
          type: 'heading',
        }}
        className={'font-bold'}
        text={t('Subheading')}
      />
      <TextBlockOption
        type={{
          type: 'paragraph',
        }}
        className={'text-xs'}
        text={t('Paragraph')}
      />
      <TextBlockOption
        type={{
          tag: 'ul',
          type: 'list',
        }}
        className={'text-xs'}
        text={`• ${t('Bullet')}`}
      />
      <TextBlockOption
        type={{
          tag: 'ol',
          type: 'list',
        }}
        className={'text-xs'}
        text={`1. ${t('Number')}`}
      />
    </SideMenuActionContainer>
  );
};

export default TextBlockDropdown;
