import { useQuery } from 'react-query';

import { resources } from '..';
import queryKeys from '../queryKeys';
import { BacklinkFilter } from '../types';

const useGetBacklinksQuery = ({
  articleId,
  enabled,
  filter,
}: {
  articleId: number;
  enabled?: boolean;
  filter?: BacklinkFilter;
}) => {
  const { data, error, isLoading } = useQuery(
    queryKeys.backlinks(articleId, filter),
    () => resources.getBacklinks(articleId, filter),
    {
      enabled,
    }
  );

  return {
    data: data?.data,
    error,
    isLoading,
  };
};

export default useGetBacklinksQuery;
