import { cx } from '@emotion/css';
import { PublicUserProfile } from 'app/api/user/types';
import RecurringCalendarIcon from 'app/components/RecurringCalendarIcon';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import dayjs from 'app/utils/dayjs';
import { Calendar, Shop } from 'iconsax-react';
import { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef } from 'react';

interface Props
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  date?: string | null;
  text?: string;
  isRecurring?: boolean | null;
  answeredByColleague: boolean;
  colleagueProfile?: PublicUserProfile;
}
const DatePickerInput = forwardRef<HTMLButtonElement, Props>(
  (
    {
      className,
      date,
      disabled,
      text,
      onClick,
      isRecurring,
      answeredByColleague,
      colleagueProfile,
    },
    ref
  ) => {
    const { t } = useArticlesTranslation();

    return (
      <button
        ref={ref}
        className={cx('flex items-center mr-2', className)}
        disabled={disabled}
        onClick={onClick}
        type="button"
      >
        {isRecurring ? (
          <RecurringCalendarIcon
            className={cx({ 'mr-1': Boolean(date) || Boolean(text) })}
          />
        ) : (
          <Calendar
            size={16}
            className={cx({ 'mr-1': Boolean(date) || Boolean(text) })}
          />
        )}
        <span className="text-xs text-grayscale-secondary flex gap-1">
          {date && <>{dayjs(date).format('DD.MM.YY')}</>}
          {!date && text && <span className="text-xs">{text}</span>}
          {answeredByColleague && colleagueProfile && (
            <>
              <Shop size={16} />
              {t('Completed by {{firstName}}', {
                firstName: colleagueProfile.firstName,
              })}
            </>
          )}
        </span>
      </button>
    );
  }
);

export default DatePickerInput;
