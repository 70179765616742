import { cx } from '@emotion/css';
import { useIsVisible } from 'app/hooks';
import { RefObject, useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { Popover } from 'react-tiny-popover';
import { EmojiEnum } from 'submodules/common-ui/generated/api/gcs/api';
import { Drawer } from 'vaul';

import Emojis from './Emojis';

interface ReactionsSheetProps {
  children: JSX.Element;
  currentUserReactions: EmojiEnum[];
  mobileContainerClassName?: HTMLDivElement['className'];
  parentRef?: RefObject<HTMLDivElement>;
  onClick(emoji: EmojiEnum): void;
}

const ReactionsSheet = ({
  onClick,
  children,
  mobileContainerClassName,
  currentUserReactions,
  parentRef,
}: ReactionsSheetProps) => {
  const [open, setOpen] = useState(false);
  const isMobile = window.matchMedia('(max-width: 768px)').matches;

  const renderChildren = () => (
    <button type="button" onClick={() => setOpen((prev) => !prev)}>
      {children}
    </button>
  );

  const isVisible = useIsVisible(parentRef);

  useEffect(() => {
    if (isMobile || isVisible) return;

    setOpen(false);
  }, [isMobile, isVisible]);

  if (isMobile) {
    return (
      <Drawer.Root open={open} onOpenChange={setOpen} disablePreventScroll>
        <Drawer.Trigger asChild>{renderChildren()}</Drawer.Trigger>
        <Drawer.Portal>
          <Drawer.Overlay
            className="fixed inset-0 bg-grayscale-primary/20 z-50"
            onClick={() => setOpen(false)}
          />
          <Drawer.Content
            className={cx(
              'fixed bottom-0 left-0 right-0 flex flex-col outline-none z-50',
              mobileContainerClassName
            )}
          >
            <Emojis
              selectedEmojis={currentUserReactions}
              onClick={(emoji) => {
                onClick(emoji);
                setOpen(false);
              }}
            />
          </Drawer.Content>
        </Drawer.Portal>
      </Drawer.Root>
    );
  }

  return (
    <Popover
      align="start"
      containerClassName="px-2 md:px-0 z-[10]"
      content={() => (
        <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
          <Emojis
            selectedEmojis={currentUserReactions}
            className="my-4"
            onClick={(emoji) => {
              onClick(emoji);
              setOpen(false);
            }}
          />
        </OutsideClickHandler>
      )}
      isOpen={open}
      positions={['top', 'bottom']}
      reposition
    >
      {renderChildren()}
    </Popover>
  );
};

export default ReactionsSheet;
