/* eslint-disable import/prefer-default-export */
import { RootState } from '../types';

import { NAME } from './constants';

export const getSelectedAudiences = (state: RootState) => state[NAME].audiences;
export const getSelectedCollaborators = (state: RootState) =>
  state[NAME].collaborators;
export const getSelectedLanguages = (state: RootState) => state[NAME].languages;

export const getShownAs = (state: RootState) => state[NAME].shownAs;

export const getActiveLanguage = (state: RootState) => {
  const active = state[NAME].languages.find((lang) => lang.active)?.code;
  const main = state[NAME].languages.find((lang) => lang.isDefault)?.code;

  return active ?? main ?? 'en';
};

export const getSelectedChannel = (state: RootState) => state[NAME].channel;

export const getSelectedCategories = (state: RootState) =>
  state[NAME].categories;

export const getPublishDate = (state: RootState) => state[NAME].publishDate;
export const getArchiveDate = (state: RootState) => state[NAME].archiveDate;

export const getRecurringDate = (state: RootState) => state[NAME].recurringDate;

export const getCanEdit = (state: RootState) => state[NAME].canEdit;

export const getTranslationStatus = (state: RootState) =>
  state[NAME].translationStatus;

export const getActionStats = (state: RootState) => state[NAME].stats;

export const getEditorTab = (state: RootState) => state[NAME].editorTab;

export const getAllowComments = (state: RootState) => state[NAME].allowComments;

export const getUseTemplateView = (state: RootState) => state[NAME].useTemplateView;

export const getChoiceHasFocus = (state: RootState) =>
  state[NAME].choiceHasFocus;

export const getArticle = (state: RootState) => state[NAME].article;

export const getAnswerSharing = (state: RootState) => state[NAME].answerSharing;
