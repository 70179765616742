import styled from '@emotion/styled';
import { Add, ArrowLeft2, ArrowRight2 } from 'iconsax-react';
import { createPortal } from 'react-dom';

interface ImageModalProps {
  srcs: Array<string>;
  selectedImage: number;
  closeModal: () => void;
  onNextClick: () => void;
  onPreviousClick: () => void;
}

const ImageModal = ({
  closeModal,
  onNextClick,
  onPreviousClick,
  selectedImage,
  srcs,
}: ImageModalProps) => {
  const ModalContainer = styled.div`
    background-color: rgba(34, 34, 34, 0.3);
  `;

  return createPortal(
    <ModalContainer className="flex justify-center items-center fixed z-50 top-0 left-0 w-screen h-screen">
      <div className="flex items-center justify-center relative w-full h-full">
        <div className="flex items-center justify-center">
          <div className="flex items-center justify-center w-[34px] h-6 absolute left-1/2 top-[14vh] rounded-full text-white bg-grayscale-secondary opacity-8 -translate-x-1/2">
            <span className="text-sm">
              {selectedImage + 1}/{srcs.length}
            </span>
          </div>
          <button
            onClick={closeModal}
            className="flex items-center justify-center w-10 h-10 absolute right-[10vw] top-[5vh] rounded text-white bg-grayscale-secondary opacity-80"
          >
            <Add size={24} className="rotate-45" />
          </button>
          <button
            onClick={onNextClick}
            className="flex items-center justify-center w-10 h-10 absolute left-[10vw] rounded-full text-white bg-grayscale-secondary opacity-80"
          >
            <ArrowLeft2 size={24} />
          </button>
          <img
            className="max-h-[80vh] max-w-[90vw]"
            src={srcs[selectedImage]}
            alt="Answer large"
          />
          <button
            onClick={onPreviousClick}
            className="flex items-center justify-center w-10 h-10 absolute right-[10vw] rounded-full text-white bg-grayscale-secondary opacity-80"
          >
            <ArrowRight2 size={24} />
          </button>
        </div>
      </div>
    </ModalContainer>,
    document.body
  );
};

export default ImageModal;
