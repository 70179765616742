import { NonNegativeNumber } from 'common-ui/utils/nonNegativeNumber';
import last from 'lodash/last';
import { useInfiniteQuery } from 'react-query';

import { queryKeys } from '../index';
import getArticleCollaborators from '../resources/getArticleCollaborators';

const useGetArticleCollaboratorsInfiniteQuery = ({
  role,
  permissions,
  enabled,
  search = '',
  keepPreviousData,
  page = 0,
  refetchOnWindowFocus,
}: {
  role: string;
  permissions: string;
  enabled?: boolean;
  search: string;
  keepPreviousData?: boolean;
  nodeId?: string;
  page?: number;
  refetchOnWindowFocus?: boolean;
}) => {
  const {
    data,
    isLoading,
    error,
    isFetching,
    isPreviousData,
    hasNextPage,
    refetch,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    queryKeys.collaborators(NonNegativeNumber.create(page).value, search),
    ({ pageParam }) =>
      getArticleCollaborators(
        role,
        permissions,
        search,
        25,
        NonNegativeNumber.create(pageParam || page).value
      ),
    {
      getNextPageParam: (lastPage) =>
        lastPage.data.meta.currentPage === lastPage.data.meta.lastPage
          ? undefined
          : lastPage.data.meta.currentPage + 1,
      enabled,
      keepPreviousData,
      refetchOnWindowFocus,
    }
  );

  return {
    data: data?.pages.flatMap((pg) => pg.data.data),
    meta: last(data?.pages)?.data.meta,
    isFetching,
    isLoading,
    isPreviousData,
    refetch,
    fetchNextPage,
    error,
    hasNextPage,
    isFetchingNextPage,
  };
};

export default useGetArticleCollaboratorsInfiniteQuery;
