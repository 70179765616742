import { FormFields } from 'app/pages/Members/memberFormSchema';

import { request } from '../../request';

const storeMember = ({
  firstName,
  lastName,
  translationLanguage,
  uiLanguage,
  locations,
  customPassword,
  passwordChangeRequired,
  ...rest
}: FormFields) =>
  request().post(`/api/v1/user-management/`, {
    ...rest,
    first_name: firstName,
    last_name: lastName,
    translation_language: translationLanguage,
    ui_language: uiLanguage,
    user_locations: locations,
    custom_password: customPassword,
    password_change_required: passwordChangeRequired,
  });

export default storeMember;
