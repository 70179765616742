/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  DEFAULT_OPEN_SECTIONS,
  SidebarSection,
  SidebarSections,
} from 'app/components/Navigation/types';

import { NAME } from './constants';
import { SidebarState, State } from './types';

const initializeSidebarState = (): SidebarState => {
  const state: SidebarState = {} as SidebarState;
  Object.values(SidebarSections).forEach((section) => {
    state[section] = { expanded: DEFAULT_OPEN_SECTIONS.includes(section) };
  });
  return state;
};

const initialState: State = {
  isSideMenuOpen: true,
  isFullPage: false,
  customLink: null,
  mobilePwaPopupHidden: true,
  isSearchOpen: false,
  tabsHidden: false,
  sidebarState: initializeSidebarState(),
};

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    sideMenuDisplayChanged: (state, { payload }: PayloadAction<boolean>) => {
      state.isSideMenuOpen = payload;
    },
    setFullPage: (state, { payload }: PayloadAction<boolean>) => {
      state.isFullPage = payload;
    },
    setCustomLink: (state, { payload }: PayloadAction<string | null>) => {
      state.customLink = payload;
    },
    setMobilePwaPopupHidden: (state, { payload }: PayloadAction<boolean>) => {
      state.mobilePwaPopupHidden = payload;
    },
    setIsSearchOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isSearchOpen = payload;
    },
    setTabsHidden: (state, { payload }: PayloadAction<boolean>) => {
      state.tabsHidden = payload;
    },
    setSidebarState: (
      state,
      { payload }: PayloadAction<{ section: SidebarSection; expanded: boolean }>
    ) => {
      const { section, expanded } = payload;
      if (state.sidebarState[section]) {
        state.sidebarState[section].expanded = expanded;
      }
    },
    stateReset: () => initialState,
  },
});

export default slice;
