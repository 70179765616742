import CloseIcon from 'assets/icons/cross.svg?react';
import { ReactNode } from 'react';

import DropdownContainer from './components/dropdowns/DropdownContainer';

interface Props {
  children: ReactNode;
  title: string;
  onClose: () => void;
}

const SideMenuActionContainer = ({ children, onClose, title }: Props) => (
  <DropdownContainer>
    <div className="flex justify-between items-center">
      <span className="flex font-bold text-xs text-grayscale-primary">
        {title}
      </span>
      <CloseIcon
        className="cursor-pointer text-gray-silver w-4 h-4"
        onClick={onClose}
      />
    </div>
    {children}
  </DropdownContainer>
);

export default SideMenuActionContainer;
