import { cx } from '@emotion/css';
import { SisenseFilter } from 'app/api/sisense/types';
import config from 'app/config';
import { useEffect, useRef } from 'react';

interface SisenseDashboardProps {
  className?: HTMLDivElement['className'];
  dashboard: string;
  filters?: SisenseFilter[];
  token: string;
  settings?: {
    showLeftPane?: boolean;
    showToolbar?: boolean;
    showRightPane?: boolean;
  };
  onLoad?: () => void;
}

declare global {
  interface Window {
    'sisense.embed': any;
  }
}

const SisenseDashboard = ({
  dashboard,
  filters,
  token,
  settings,
  className,
  onLoad,
}: SisenseDashboardProps) => {
  const iFrameElement = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    const { SisenseFrame, enums } = window['sisense.embed'];

    const sisenseFrame = new SisenseFrame({
      url: config.env.sisenseUrl,
      dashboard,
      wat: token,
      settings: {
        showLeftPane: false,
        showToolbar: true,
        showRightPane: settings?.showRightPane ?? true,
        ...settings,
      },
      element: iFrameElement.current,
    });

    sisenseFrame.render();
    filters &&
      sisenseFrame.dashboard.on(enums.DashboardEventType.LOADED, () =>
        sisenseFrame.dashboard.applyFilters(filters)
      );
  }, [dashboard, filters, token, settings]);

  return (
    <iframe
      id="sisense-iframe"
      ref={iFrameElement}
      className={cx(
        'md:container md:border md:border-gray-silver-blue md:rounded-[14px] p-0',
        className
      )}
      width="100%"
      height="100%"
      title="sisense-iframe"
      onLoad={onLoad}
    />
  );
};

export default SisenseDashboard;
