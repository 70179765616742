import { cx } from '@emotion/css';
import { useAuthenticatedUser } from 'app/api/auth/hooks';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { actions as editorActions, selectors } from 'app/store/editor';
import { Language } from 'app/store/editor/types';
import { LocationTick } from 'iconsax-react';
import { useDispatch, useSelector } from 'react-redux';

interface Props {
  language: Language;
  selectedLanguageCode?: string;
  beforeLanguageChange?: (code: string) => void;
}

const LanguageDropdownButton = ({
  language,
  selectedLanguageCode,
  beforeLanguageChange,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useArticlesTranslation();
  const { data: user } = useAuthenticatedUser();

  const collaborators = useSelector(selectors.getSelectedCollaborators);
  const selectedLanguages = useSelector(selectors.getSelectedLanguages);

  const isUserLanguageCollaborator = (languageCode: string) =>
    collaborators.some(
      (collaborator) =>
        collaborator.id === user?.id &&
        collaborator.languages.includes(languageCode)
    );

  const isUserCollaboratorInAllLanguages = selectedLanguages.every(({ code }) =>
    isUserLanguageCollaborator(code)
  );

  const isUserCollaborator = isUserLanguageCollaborator(language.code);

  const onLanguageClick = (languageCode: string) => {
    if (beforeLanguageChange) return beforeLanguageChange(languageCode);
    dispatch(editorActions.setActiveLanguage(languageCode));
  };

  return (
    <button
      onClick={() => onLanguageClick(language.code)}
      className={cx('w-full flex items-center hover:bg-hover-blue p-3', {
        'bg-focus-background': language.code === selectedLanguageCode,
        'justify-between':
          isUserCollaborator && !isUserCollaboratorInAllLanguages,
      })}
    >
      <div className="overflow-hidden min-w-0 text-ellipsis whitespace-nowrap flex">
        <div className="w-5 h-5 flex items-center justify-center border border-grayscale-secondary rounded-sm p-1">
          <span className="text-10">{language.code.toUpperCase()}</span>
        </div>
        <div
          className={cx('flex items-start px-2 overflow-hidden', {
            'border-r border-r-gray-dark': language.isDefault,
          })}
        >
          <span className="text-sm text-ellipsis overflow-hidden">
            {language.name}
          </span>
        </div>
        {language.isDefault && (
          <div className={cx('flex items-center px-2')}>
            <LocationTick size={16} />
            <span className="ml-1">{t('Main')}</span>
          </div>
        )}
      </div>
      {isUserCollaborator && !isUserCollaboratorInAllLanguages && (
        <img
          src={user?.avatars?.small}
          alt="avatar"
          className="w-5 h-5 rounded-sm"
        />
      )}
    </button>
  );
};

export default LanguageDropdownButton;
