import { request } from 'app/api/gcsRequest';
import config from 'app/config';
import {
  ChannelApi,
  UpdatedChannel,
} from 'submodules/common-ui/generated/api/gcs';

const updateChannel = (updatedChannel: UpdatedChannel) =>
  new ChannelApi(undefined, config.env.gcsApiUrl, request()).updateChannel(
    updatedChannel
  );

export default updateChannel;
