import { request } from 'app/api/gcsRequest';
import config from 'app/config';
import { CommentApi } from 'submodules/common-ui/generated/api/gcs';

const getComment = (commentId: number) =>
  new CommentApi(undefined, config.env.gcsApiUrl, request()).getCommentById(
    commentId
  );

export { getComment };
export default getComment;
