import { cx } from '@emotion/css';
import { routes } from 'app/router';
import { actions } from 'app/store/navigation';
import { getChannelTitle } from 'app/utils';
import { Notepad2, Clipboard } from 'iconsax-react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BasicRelevantChannelInfo } from 'submodules/common-ui/generated/api/gcs';

const ChannelRow = ({ channel }: { channel: BasicRelevantChannelInfo }) => {
  const {
    i18n: { language: userLanguage },
  } = useTranslation();

  const isMobile = window.matchMedia('(max-width: 768px)').matches;
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const onClick = () => {
    dispatch(actions.setIsSearchOpen(false));

    if (isMobile) {
      return navigate(routes.relevantChannel.create(channel.id));
    }

    navigate(routes.relevantChannel.create(channel.id));
  };

  const channelTitle = getChannelTitle(channel, userLanguage);
  return (
    <button
      className={cx('flex items-center py-[14px] pr-3 mt-2')}
      key={channel.id}
      onClick={onClick}
    >
      {channel.shownAs === 'cards' ? (
        <Notepad2 size={20} />
      ) : (
        <Clipboard size={20} />
      )}
      <span className="text-sm ml-2">{channelTitle}</span>
    </button>
  );
};

export default ChannelRow;
