import { Element } from '@craftjs/core';

import HeadingSectionContainer from './HeadingSectionsContainer';

const EditorUserMode = () => {
  return (
    <Element id="headingSectionContainer" is={HeadingSectionContainer} canvas />
  );
};

export default EditorUserMode;
