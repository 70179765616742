import { ScrollbarContainer } from 'app/components';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { useState } from 'react';
import { SharingStatusOverviewResponse } from 'submodules/common-ui/generated/api/gcs';

import SharingStatusRow from './SharingStatusRow';

interface SharingStatusOverviewProps {
  statusOverview: SharingStatusOverviewResponse;
  articleOwner: number;
  articleId: number;
  sender?: string;
}

const SharingStatusOverview = ({
  statusOverview,
  articleOwner,
  articleId,
  sender,
}: SharingStatusOverviewProps) => {
  const { t } = useArticlesTranslation();

  const [openCommentId, setOpenCommentId] = useState<number | undefined>(
    undefined
  );

  return (
    <div className="flex flex-col mt-4">
      <span className="text-sm font-bold mb-2">{t('Forwarded to')}</span>
      <ScrollbarContainer className="max-h-[300px]">
        {statusOverview.map((status) => (
          <SharingStatusRow
            key={status.tenantId}
            sharingStatus={status}
            articleId={articleId}
            articleOwner={articleOwner}
            isOpen={status.tenantId === openCommentId}
            setOpenComment={setOpenCommentId}
            sender={sender}
          />
        ))}
      </ScrollbarContainer>
    </div>
  );
};

export default SharingStatusOverview;
