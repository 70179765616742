import { css, cx } from '@emotion/css';
import useGetSettings from 'app/api/pwaSettings/hooks/useGetSettings';
import { useCommonTranslation } from 'app/internationalization/hooks';
import { actions } from 'app/store/navigation';
import NotFound from 'assets/images/not-found.svg';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';

import useInstallChromePwa from './useInstallChromePwa';

export const MOBILE_PWAINSTALL_HEIGHT = '80px';

const AndroidChromePopup = () => {
  const { t } = useCommonTranslation();
  const dispatch = useDispatch();
  const {
    hidden,
    installEvent,
    isInstalled,
    isMobile,
    isSafari,
    onCancel,
    handleOnInstall,
  } = useInstallChromePwa();
  const { settings } = useGetSettings();

  const iconUrl = settings?.favicons.find(
    (favicon) => favicon.size === '32x32'
  )?.url;

  useEffect(() => {
    if (isInstalled || isSafari || hidden || !isMobile || !installEvent) {
      dispatch(actions.setMobilePwaPopupHidden(true));
      return;
    }

    dispatch(actions.setMobilePwaPopupHidden(false));

    return () => {
      dispatch(actions.setMobilePwaPopupHidden(true));
    };
  }, [dispatch, hidden, installEvent, isInstalled, isMobile, isSafari]);

  if (isInstalled || isSafari || hidden || !isMobile || !installEvent) {
    return null;
  }

  return (
    <div
      className={cx(
        'flex w-full p-4 items-center justify-start bg-white border-b-[0.5px] border-gray-light z-999',
        css(`height: ${MOBILE_PWAINSTALL_HEIGHT}`)
      )}
    >
      <button onClick={onCancel}>
        <CloseLineIcon className="text-grayscale-secondary" size={20} />
      </button>

      <div className="flex items-center ml-4">
        <div className="w-12 h-12">
          <img
            className="w-full h-full rounded"
            src={iconUrl ?? NotFound}
            alt=""
          />
        </div>

        <div className="flex flex-col ml-3">
          <span className="text-sm font-bold">{t('Stay in the loop')}</span>
          <span className="text-sm text-grayscale-secondary">
            {t('Install the App')}
          </span>
        </div>
      </div>

      <button
        onClick={handleOnInstall}
        className="ml-auto py-1 px-3 bg-focus text-sm text-white rounded"
      >
        {t('Install')}
      </button>
    </div>
  );
};

export default AndroidChromePopup;
