import { useNode } from '@craftjs/core';
import { css, cx } from '@emotion/css';
import { useAuthenticatedUser } from 'app/api/auth/hooks';
import config from 'app/config';
import useUnblockNextSection from 'app/hooks/useUnblockNextSection';
import {
  useArticlesTranslation,
  useComponentsTranslation,
} from 'app/internationalization/hooks';
import useAnswerActionMutation from 'app/pages/ArticleEditor/hooks/useAnswerActionMutation';
import useUserAnswer from 'app/pages/ArticleEditor/hooks/useUserAnswer';
import { actions, selectors } from 'app/store/editor';
import {
  actions as modalActions,
  selectors as modalSelectors,
} from 'app/store/modal';
import { ModalTypes } from 'app/store/modal/types';
import logger from 'app/utils/logger';
import Spinner from 'assets/icons/spinner.svg?react';
import { CloseCircle, Flag, TickCircle } from 'iconsax-react';
import {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  PostActionAnswer,
  UserAnswerResponse,
} from 'submodules/common-ui/generated/api/gcs';

import SimpleTask from '../../../../SimpleTask';
import ActionContainer from '../ActionContainer';
import EditorNavigation from '../EditorNavigation';
import { YesNoConfirmModalProps } from '../Modals/YesNoConfirmModal';

import AnswerActionScore, {
  EditorAnswerActionScore,
} from './AnswerActionScore';

type YesNoAnswerOption = 'yes' | 'no' | 'n/a';

interface YesNoQuestionProps {
  actionId: string;
  selectedProps: ComponentProps<typeof SimpleTask>;
  onAnswer: (data: UserAnswerResponse, actionId: string) => void;
  onUndo: (actionId: string) => void;
  isEditor?: boolean;
  NavigationContainer: ({
    children,
  }: {
    children: React.ReactNode;
  }) => JSX.Element;
}

const YesNoAnswerAction = ({
  actionId,
  selectedProps,
  onAnswer,
  isEditor = false,
  NavigationContainer,
}: YesNoQuestionProps) => {
  const { t } = useArticlesTranslation();
  const { t: ct } = useComponentsTranslation();

  const dispatch = useDispatch();
  const btnRef = useRef<HTMLButtonElement>(null);

  const [answer, setAnswer] = useState<YesNoAnswerOption>();

  const {
    mutate: answerAction,
    isLoading,
    isSuccess,
    reset,
  } = useAnswerActionMutation();

  const currentLanguage = useSelector(selectors.getActiveLanguage);

  const { modalProps } = useSelector(modalSelectors.getOpenedModals);

  const { title, description, currentUserAnswers, completed } = selectedProps;

  const userAnswer = useUserAnswer({ currentUserAnswers });

  const { data: user } = useAuthenticatedUser();

  const ableToAnswer = useMemo(() => !completed, [completed]);

  const completeAction = useCallback(() => {
    let currentAnswer: YesNoAnswerOption = 'n/a';
    if (answer) {
      currentAnswer = answer;
    } else {
      setAnswer('n/a');
    }

    const payload: PostActionAnswer = {
      actionId: parseInt(actionId),
      language: currentLanguage,
      answer: currentAnswer,
      timezone: user?.timezone,
    };

    answerAction(payload, {
      onError: (e) => logger.error(e),
      onSuccess: (data) => {
        onAnswer(data, actionId);
        dispatch(
          actions.setStats({
            mandatoryProgress: data.mandatoryProgress,
            mandatoryScore: data.totalMandatoryScore,
            progress: data.progress,
            score: data.score,
          })
        );

        reset();
      },
    });
  }, [
    actionId,
    answer,
    user?.timezone,
    answerAction,
    currentLanguage,
    dispatch,
    reset,
    onAnswer,
  ]);

  const onSelectAnswer = (option: YesNoAnswerOption) => {
    if (!ableToAnswer) return;
    setAnswer(option);
  };

  const onClickNotPossible = () => {
    dispatch(
      modalActions.showModal({
        modalType: ModalTypes.YES_NO_NOT_POSSIBLE_MODAL,
        modalProps: {
          actionType: undefined,
        },
      })
    );
  };

  const renderFooterContent = () => {
    return (
      <>
        {ableToAnswer && (
          <button
            ref={btnRef}
            type="button"
            className={cx(
              'flex items-center justify-center w-full h-12 mx-5 rounded-xl bg-focus',
              {
                'bg-gray-light text-grayscale-secondary': !answer,
                'text-white': !!answer,
              }
            )}
            onClick={completeAction}
            disabled={!answer}
          >
            {isLoading ? (
              <Spinner className="w-6 h-6 animate-spin text-white" />
            ) : isSuccess ? (
              <TickCircle className="w-6 h-6 text-white" />
            ) : (
              t('Confirm')
            )}
          </button>
        )}
      </>
    );
  };

  const renderAnswerActionScore = () => {
    if (isEditor) return <EditorAnswerActionScore totalScore={0} />;
    return (
      <AnswerActionScore
        selectedProps={selectedProps}
        showQuizScore={false}
        showProgressScore={false}
        totalScore={0}
      />
    ); // TODO: check show quiz score
  };

  useEffect(() => {
    if (userAnswer) {
      const currentAnswer = userAnswer.answer as YesNoAnswerOption;
      return setAnswer(currentAnswer);
    }
    setAnswer(undefined);
  }, [userAnswer]);

  useEffect(() => {
    const { actionType } = modalProps as YesNoConfirmModalProps;
    if (actionType === 'confirm') {
      dispatch(modalActions.hideModal());
      completeAction();
    }
  }, [completeAction, dispatch, modalProps]);

  return (
    <>
      {renderAnswerActionScore()}
      <ActionContainer
        isMandatory={selectedProps.mandatory}
        type={'yes_no_task'}
        canAnswer={true}
      >
        <span
          className={cx('text-lg font-bold', css('word-break: break-word;'))}
        >
          {title}
        </span>
        <span className={cx('text-black', css('word-break: break-word;'))}>
          {description}
        </span>
        {userAnswer?.answer !== 'n/a' ? (
          <div className="flex flex-col gap-3 mt-4">
            <div
              onClick={() => onSelectAnswer('yes')}
              role="presentation"
              className={cx(
                'rounded-lg border-gray-dark border cursor-pointer',
                {
                  'bg-[#5da2702c] border-success': answer === 'yes',
                  'hover:border-success': ableToAnswer,
                }
              )}
            >
              <div className="flex p-4">
                <TickCircle className="mr-1" color={config.colors.success} />
                <span className="font-bold">{ct('Yes')}</span>
              </div>
            </div>
            <div
              onClick={() => onSelectAnswer('no')}
              role="presentation"
              className={cx(
                'rounded-lg border-gray-dark border cursor-pointer',
                {
                  'bg-[#A23E482c] border-error': answer === 'no',
                  'hover:border-error': ableToAnswer,
                }
              )}
            >
              <div className="flex p-4">
                <CloseCircle className="mr-1" color={config.colors.error} />
                <span className="font-bold">{ct('No')}</span>
              </div>
            </div>
            {ableToAnswer && (
              <div
                onClick={onClickNotPossible}
                role="presentation"
                className="px-4 py-1 flex justify-center items-center cursor-pointer"
              >
                <Flag className="mr-1" size={20} />
                <span className="text-sm font-custom">
                  {t('Not possible to answer')}
                </span>
              </div>
            )}
          </div>
        ) : (
          <div className="pt-6 flex  items-center cursor-pointer">
            <Flag className="mr-1" size={20} color={config.colors.focus} />
            <span className="text-sm font-custom">
              {t('Marked as not possible to answer')}
            </span>
          </div>
        )}

        <div className="flex justify-between items-center w-full mt-6">
          <NavigationContainer>{renderFooterContent()}</NavigationContainer>
        </div>
      </ActionContainer>
    </>
  );
};

export const EditorYesNoQuestionAction = () => {
  const {
    selectedProps,
    nodeId,
    actions: { setProp },
  } = useNode((node) => ({
    selectedProps: node.data.props as ComponentProps<typeof SimpleTask>,
    nodeId: node.id,
  }));
  const { setIsUnblocked } = useUnblockNextSection(nodeId);

  const { data: user } = useAuthenticatedUser();

  if (!user) return null;

  return (
    <YesNoAnswerAction
      selectedProps={selectedProps}
      actionId={nodeId}
      onAnswer={(data) => {
        setProp((props: ComponentProps<typeof SimpleTask>) => {
          props.currentUserAnswers = [{ ...data, completedBy: user.id }];
          props.completed = true;
        });

        setIsUnblocked(true);
      }}
      onUndo={() => {
        setProp((props: ComponentProps<typeof SimpleTask>) => {
          props.currentUserAnswers = [];
          props.completed = false;
        });
      }}
      isEditor={true}
      NavigationContainer={EditorNavigation}
    />
  );
};

export default YesNoAnswerAction;
