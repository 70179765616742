import { cx } from '@emotion/css';
import ToastContainer from 'app/components/Toast/ToastContainer';
import { selectors } from 'app/store/editor';
import MagicPen from 'assets/icons/magicpen.svg?react';
import { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useSelector } from 'react-redux';

import useCanChangeArticleStructure from '../../hooks/useCanChangeArticleStructure';

import BlockOptions from './BlockOptions';

const BlockGeneration = () => {
  const activeTab = useSelector(selectors.getEditorTab);
  const { canChangeArticleStructure } = useCanChangeArticleStructure();
  const [open, setOpen] = useState(false);

  const onClose = () => setOpen(false);

  document.addEventListener('keydown', (e) => {
    if (
      (e.code === 'KeyK' && e.metaKey) ||
      (e.code === 'KeyL' && e.ctrlKey && e.shiftKey)
    ) {
      setOpen(true);
    }
  });

  if (activeTab !== 'create') return null;

  return (
    <OutsideClickHandler onOutsideClick={onClose}>
      <div
        className={cx('flex flex-col items-end fixed bottom-6 right-0', {
          'pointer-events-none opacity-40': !canChangeArticleStructure,
        })}
      >
        {open && <BlockOptions onClose={onClose} />}
        {!open && (
          <button
            className="flex items-center justify-center p-4 text-sm rounded-[26px] border border-focus-background shadow-block bg-white left-1/2 -translate-x-1/2"
            onClick={() =>
              setOpen((prev) => (canChangeArticleStructure ? !prev : false))
            }
          >
            <MagicPen />
          </button>
        )}
        <ToastContainer toastId="GenerateToastId" />
      </div>
    </OutsideClickHandler>
  );
};

export default BlockGeneration;
