import { cx } from '@emotion/css';
import { ChangeEvent, useEffect, useRef } from 'react';

interface TitleProps {
  className?: string;
  description?: string;
  error?: boolean;
  enabled: boolean;
  placeholder: string;
  isLocked?: boolean;
  onChange: (value: string) => void;
  onFocusChange?: (focus: boolean) => void;
}

const Title = ({
  className,
  description,
  error = false,
  enabled,
  placeholder,
  isLocked,
  onChange,
  onFocusChange,
}: TitleProps) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const minHeight = 20;
  const maxHeight = 40;

  const onDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.target.style.height = `${minHeight}px`;

    if (e.target.scrollHeight <= maxHeight) {
      e.target.style.height = `${e.target.scrollHeight}px`;
      onChange(e.target.value);
      return;
    }

    if (e.target.scrollHeight > maxHeight) {
      e.target.style.height = `${maxHeight}px`;
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (
      !textAreaRef ||
      !textAreaRef.current ||
      !description ||
      description === ''
    )
      return;

    if (textAreaRef.current.scrollHeight <= maxHeight) {
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!enabled) {
    return (
      <span
        className={cx(
          'text-sm w-full mr-4 [overflow-wrap:anywhere]',
          isLocked ? 'text-grayscale-secondary' : 'text-black',
          className
        )}
      >
        {description}
      </span>
    );
  }

  return (
    <textarea
      ref={textAreaRef}
      onFocus={() => onFocusChange?.(true)}
      onBlur={() => onFocusChange?.(false)}
      rows={1}
      value={description ?? ''}
      className={cx(
        'text-sm text-black placeholder-grayscale-secondary w-full mr-4 rounded-sm resize-none overflow-hidden disabled:bg-white outline-none focus-visible:border focus-visible:border-black',
        { 'border border-error placeholder:text-error rounded-sm': error },
        className
      )}
      placeholder={placeholder}
      onChange={onDescriptionChange}
    />
  );
};

export default Title;
