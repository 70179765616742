import { cx } from '@emotion/css';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import FloatingTextFormatToolbarPlugin from 'app/components/LexicalEditor/components/FloatingToolbarPlugin';
import useEditorFocus from 'app/pages/ArticleEditor/hooks/useEditorFocus';
import {
  COMMAND_PRIORITY_LOW,
  INSERT_PARAGRAPH_COMMAND,
  LineBreakNode,
} from 'lexical';
import { useEffect, useRef } from 'react';

interface TextEditorProps {
  enabled: boolean;
  error: boolean;
  placeholder?: React.ReactNode;
}

const LexicalInput = ({ enabled, error, placeholder }: TextEditorProps) => {
  const ref = useRef(null);

  const [editor] = useLexicalComposerContext();

  useEditorFocus();

  useEffect(() => editor.setEditable(enabled), [editor, enabled]);

  useEffect(() => {
    return editor.registerCommand(
      INSERT_PARAGRAPH_COMMAND,
      () => {
        return true;
      },
      COMMAND_PRIORITY_LOW
    );
  }, [editor]);

  editor.registerNodeTransform(LineBreakNode, (node) => {
    node.remove();
  });

  return (
    <RichTextPlugin
      contentEditable={
        <div className="relative" ref={ref}>
          <FloatingTextFormatToolbarPlugin
            anchorElem={ref.current ?? undefined}
            paragraphOnly={true}
          />
          <ContentEditable className="relative text-sm text-black w-full resize-none overflow-hidden disabled:bg-white outline-none focus-visible:border focus-visible:border-black rounded-sm cursor-text disabled:cursor-auto" />
        </div>
      }
      ErrorBoundary={LexicalErrorBoundary}
      placeholder={
        <span
          tabIndex={-1}
          className={cx(
            'absolute bottom-0 left-0 text-sm pointer-events-none',
            {
              'text-error': error,
              'text-grayscale-secondary': !error,
            }
          )}
        >
          {placeholder}
        </span>
      }
    />
  );
};

export default LexicalInput;
