import { SerializedLexicalText } from 'app/components/LexicalEditor/types';
import { SerializedLexicalNode } from 'lexical';
import { Paragraph } from 'submodules/common-ui/generated/api/gcs';

const getFormat = (format: SerializedLexicalText['format']) => {
  const isBold = format === 1 || format === 3 || format === 9 || format === 11;
  const isItalic =
    format === 2 || format === 3 || format === 10 || format === 11;
  const underline =
    format === 8 || format === 9 || format === 10 || format === 11;

  return typeof format === 'number'
    ? {
        bold: isBold,
        italic: isItalic,
        underline: underline,
      }
    : undefined;
};

const lexicalToApi = (editor: SerializedLexicalNode[]): Paragraph => {
  const blockItem = editor[0] as SerializedLexicalText;
  const { children: pChildren } = blockItem;
  const itemChildren = pChildren ?? [];

  return {
    children: (itemChildren ?? []).map((each) => {
      return {
        type: 'text',
        format: getFormat(each.format) ?? {},
        value: each.text ?? '',
      };
    }),

    type: 'paragraph',
  };
};
export { getFormat, lexicalToApi };
export default lexicalToApi;
