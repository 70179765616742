import { cx } from '@emotion/css';
import { useAuthenticatedUser } from 'app/api/auth/hooks';
import { Button, PageLoader, Switch } from 'app/components';
import ActionToast from 'app/components/Toast/ActionToast';
import SimpleToast from 'app/components/Toast/SimpleToast';
import { useISOLanguages } from 'app/hooks';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { routes } from 'app/router';
import { actions, selectors } from 'app/store/editor';
import dayjs from 'app/utils/dayjs';
import {
  ArrowDown2,
  ArrowRight2,
  Danger,
  Maximize4,
  SmsTracking,
  Translate,
} from 'iconsax-react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Article,
  ArticleStatusEnum,
  UpdatedArticle,
  UpdatedBlock,
} from 'submodules/common-ui/generated/api/gcs/api';

import useUpdateArticleMutation from '../hooks/mutations/useUpdateArticleMutation';

const ReviewPopUp = () => {
  const { t } = useArticlesTranslation();
  const [expanded, setExpanded] = useState(true);
  const [settingsExpanded, setSettingsExpanded] = useState(false);
  const [sharingEnabled, setSharingEnabled] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data: user } = useAuthenticatedUser();

  const { mutate: updateArticle, isLoading } = useUpdateArticleMutation();

  const article = useSelector(selectors.getArticle);

  const languages = useISOLanguages({ search: '' });

  const articleLanguagesNames =
    article?.languages.map((l) => {
      const language = languages.find((lang) => lang.code === l.language);
      return language?.name;
    }) ?? [];

  useEffect(() => {
    if (!article) return;

    setSharingEnabled(article.answerSharing);
  }, [article]);

  const updatedArticle = (
    status: ArticleStatusEnum,
    _article: Article
  ): UpdatedArticle => {
    return {
      ..._article,
      answerSharing: sharingEnabled,
      blocks: _article.blocks as UpdatedBlock[],
      publishAt: _article.publishAt,
      archiveAt: _article.archiveAt ?? null,
      channelId: _article.channel?.id ?? null,
      status: status,
      categories: _article.categories.map((category) => category.id),
      ...(user?.id &&
        status === 'draft' && {
          users: [
            {
              id: user?.id,
              languages: _article.languages.map((l) => l.language),
            },
          ],
        }),
    };
  };

  const cancelReject = () => {
    if (!article) return;

    updateArticle(
      { article: updatedArticle('inbound', article) },
      {
        onSuccess: (result) => {
          dispatch(actions.setArticle(result));
        },
      }
    );
  };

  const approveArticle = () => {
    if (!article) return;

    updateArticle(
      { article: updatedArticle('draft', article) },
      {
        onSuccess: () => {
          navigate(routes.editorArticle.create(article.id.toString(), 'edit'), {
            replace: true,
          });
          toast(
            <SimpleToast
              text={t('Article approved. You’re now editing the article.')}
            />,
            {
              position: 'bottom-center',
              autoClose: 8000,
              closeButton: false,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              containerId: 'Simple',
            }
          );
        },
      }
    );
  };

  const rejectArticle = () => {
    if (!article) return;

    updateArticle(
      { article: updatedArticle('rejected', article) },
      {
        onSuccess: () => {
          toast(
            <ActionToast
              action={`${t('Undo')}`}
              text={t('Article was rejected and removed')}
              onClick={cancelReject}
            />,
            {
              position: 'bottom-center',
              autoClose: 8000,
              closeButton: false,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              containerId: 'ArticleRejection',
            }
          );
          navigate(routes.articleStudio.create(), { replace: true });
        },
      }
    );
  };

  return (
    <div className="flex flex-col w-[534px] fixed bottom-0 right-0 mr-6 mb-6">
      <div className="w-full p-4 bg-white rounded-2xl border border-gray-light shadow-atobi">
        <div className="relative">
          <span className="text-lg font-bold">
            👋 {t('Review Article from')} {article?.originalTenant?.name ?? '-'}
          </span>
          <button
            className="absolute right-0 top-0 transition-all duration-500 ease-in-out hover:mt-1"
            onClick={() => setExpanded((prev) => !prev)}
          >
            {expanded ? (
              <ArrowDown2 className="text-focus" size={24} />
            ) : (
              <Maximize4 size={24} />
            )}
          </button>
        </div>

        <div
          className={cx(
            'max-h-0 transition-all duration-300 ease-in-out overflow-hidden',
            {
              'max-h-[600px]': expanded,
            }
          )}
        >
          <div className="flex flex-col">
            <span className="text-sm mt-3">
              <strong>{t('Approve')}</strong>{' '}
              {t('to unlock editing and publishing.')}{' '}
              <strong>{t('Reject')}</strong> {t('to remove from Inbox.')}
            </span>

            <div className="flex text-[10px] text-grayscale-secondary mt-2">
              <Danger size={12} className="mr-1" />
              <span>
                {t(
                  'The external partner will know that you’ve rejected the article.'
                )}
              </span>
            </div>
          </div>

          <div
            className={cx('flex flex-col rounded-xl mt-2', {
              'bg-gray': Boolean(article?.sharingComment),
              'py-2 px-4': Boolean(article?.sharingComment),
            })}
          >
            <div className="flex">
              <SmsTracking size={20} />
              <span className="ml-2 text-sm font-bold">
                {article?.originalTenant?.name ?? ''}
              </span>
            </div>

            {article?.sharingComment && (
              <>
                <span className="my-1 text-sm">{article.sharingComment}</span>
                <span className="text-xs">
                  {dayjs(article.createdAt).format('DD MMM YYYY')}
                </span>
              </>
            )}
          </div>

          <div className="flex flex-col px-2 py-3 mt-3 bg-white border border-focus-background rounded-xl">
            <div className="flex gap-2 items-center justify-start">
              <Translate size={16} />
              <span className="text-xs font-bold">
                {t('Active article languages')} (
                {article?.languages.length ?? 0})
              </span>
            </div>
            <span className="text-sm mt-1">
              {articleLanguagesNames.map((language) => language).join(', ')}
            </span>
          </div>

          <div className="flex flex-col  mt-3 bg-white border border-focus-background rounded-xl">
            <div className="flex gap-2 items-center justify-start px-2 py-3">
              <button onClick={() => setSettingsExpanded((prev) => !prev)}>
                <ArrowRight2
                  className={cx({
                    'transform rotate-90': settingsExpanded,
                  })}
                  size={16}
                />
              </button>
              <span className="text-xs font-bold">
                {t('Advanced settings')}
              </span>
            </div>

            <div
              className={cx(
                'max-h-0 transition-all duration-300 ease-in-out overflow-hidden',
                {
                  'max-h-[100px]': settingsExpanded,
                }
              )}
            >
              <div
                className={cx(
                  'flex items-center px-2 pb-3 border-t border-t-focus-background'
                )}
              >
                <div className="flex flex-col">
                  <span className="text-xs font-bold mt-2">
                    {t('Share article insights, results and answers with')}{' '}
                    {article?.originalTenant?.name ?? '-'}
                  </span>
                  <span className="text-xs text-grayscale-secondary">
                    {t(
                      'The external partner will know if you choose to opt out of this.'
                    )}
                  </span>
                </div>
                <Switch
                  className="ml-auto"
                  width={39}
                  height={24}
                  checked={sharingEnabled}
                  onChange={(checked) => setSharingEnabled(checked)}
                />
              </div>
            </div>
          </div>

          {article?.answerSharing && !sharingEnabled && (
            <div className="flex mt-3 text-error">
              <Danger size={12} className="mr-1" />
              <span className="text-10">
                {t(
                  'The insights sharing feature is disabled. {{tenantName}} will be notified that you have opted out of sharing.',
                  { tenantName: article?.originalTenant?.name ?? '-' }
                )}
              </span>
            </div>
          )}

          {isLoading && (
            <div className="flex w-full items-center justify-center mt-6">
              <div className="h-12">
                <PageLoader />
              </div>
            </div>
          )}
        </div>
        {!isLoading && (
          <div className="flex mt-4">
            <Button
              className="w-[170px] h-12 rounded-xl text-sm border-transparent bg-hover-blue hover:bg-focus-background text-focus"
              onClick={rejectArticle}
            >
              {t('Reject')}
            </Button>

            <Button
              className={
                'w-[170px] h-12 ml-3.5 rounded-xl text-sm border-transparent bg-focus hover:bg-hover-primary text-white'
              }
              onClick={approveArticle}
            >
              {t('Approve')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReviewPopUp;
