import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import { selectors } from 'app/store/editor';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import {
  ActionAnswerResponse,
  GetChoiceSchema,
  Text,
  AnswerChoiceSummary,
} from 'submodules/common-ui/generated/api/gcs';
interface Props {
  option: GetChoiceSchema;
  actionAnswers?: ActionAnswerResponse[];
  choiceSummary?: AnswerChoiceSummary;
}

const QuizOption: FC<Props> = ({
  option: { correct, id, variants },
  choiceSummary,
}) => {
  const languange = useSelector(selectors.getActiveLanguage);
  const percentage = choiceSummary ? choiceSummary.choicePercentage : 0;

  const imageUrl = variants[languange]?.image?.directUrls?.small?.url;
  const answerText = (variants[languange]?.answer?.children?.[0] as Text)
    ?.value;

  return (
    <div
      className={cx(
        'h-10 border rounded bg-grayscale-bg-dark text-hover-primary text-sm relative',
        {
          'border-success': correct,
          'border-gray-light': !correct,
        }
      )}
    >
      <StyledQuizOptionBar
        className={cx('h-full rounded', {
          'bg-success-background': correct,
          'bg-focus-background': !correct,
        })}
        percentage={percentage}
      />
      <div
        id={id.toString()}
        className={cx(
          'absolute h-full w-full flex justify-between items-center z-10 left-0 top-0 right-0 bottom-0',
          {
            'pe-3': !!imageUrl,
            'px-3 py-2.5': !imageUrl,
          }
        )}
      >
        <div className="flex items-center gap-2 truncate">
          {imageUrl && (
            <img
              className="w-[66px] h-[40px] object-cover rounded-s shrink-0"
              src={imageUrl}
              alt="choice"
            />
          )}
          {answerText && <span className="truncate">{answerText}</span>}
        </div>
        <span>{percentage}%</span>
      </div>
    </div>
  );
};

type QuizOptionBarProps = {
  percentage: number;
} & React.HTMLAttributes<HTMLDivElement>;

const StyledQuizOptionBar = styled('div')<QuizOptionBarProps>(
  ({ percentage }) => ({
    width: `${percentage}%`,
  })
);

export default QuizOption;
