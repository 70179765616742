import { request } from 'app/api/gcsRequest';
import config from 'app/config';
import { ActionAnswerSummaryApi } from 'common-ui/generated/api/gcs';

const getActionAnswerSummary = (
  actionId: number,
  actionInstanceId?: number,
  originalArticleId?: number | null
) =>
  new ActionAnswerSummaryApi(undefined, config.env.gcsApiUrl, request())
    .getActionAnswerSummary(
      actionId,
      actionInstanceId,
      originalArticleId || undefined
    )
    .then((response) => response.data);

export { getActionAnswerSummary };
export default getActionAnswerSummary;
