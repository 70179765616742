import { cx } from '@emotion/css';
import { forwardRef } from 'react';

interface Props {
  onClick?: () => void;
  isActive?: boolean;
  isStatic?: boolean;
  title: string;
}

const CategoryButton = forwardRef<HTMLDivElement, Props>(
  ({ isActive, onClick, title, isStatic }, ref) => (
    <div
      ref={ref}
      role="presentation"
      onClick={onClick}
      className={cx(
        `border-2 text-sm border-focus-background rounded-full w-fit px-2 py-1.5 flex gap-2 group items-center whitespace-nowrap`,
        {
          'bg-ceil text-white': isActive,
          'bg-hover-blue text-ceil': !isActive,
          'hover:bg-focus-background': !isActive && !isStatic,
          'cursor-pointer': !!onClick,
        }
      )}
    >
      {title}
    </div>
  )
);

export default CategoryButton;
