import { useAuthenticatedUser } from 'app/api/auth/hooks';
import useTranslateArticleMutation from 'app/pages/Articles/hooks/useTranslateArticleMutation';
import { selectors } from 'app/store/editor';
import { Language } from 'app/store/editor/types';
import { createContext, ReactNode, useContext, useState } from 'react';
import { UseMutateAsyncFunction } from 'react-query';
import { useSelector } from 'react-redux';
import {
  Article,
  TranslationStatusEnum,
} from 'submodules/common-ui/generated/api/gcs';

const uninitialisedFunction = () => new Error('Uninitialised Function');
const uninitialisedPromiseFunction = () =>
  Promise.reject(new Error('Uninitialized Function'));

export const LanguageSettingsContext = createContext<LanguageSettingsValues>({
  isTranslating: false,
  isTranslatingError: false,
  isTranslatingSuccess: false,
  addedDropdownLanguages: [],
  hasArticleOwnerPermissions: true,
  setTranslationStatus: () => undefined,
  isTranslatingAddedLanguage: () => false,
  isTranslatingSuccessAddedLanguage: () => false,
  setAddedDropdownLanguages: uninitialisedFunction,
  translate: uninitialisedPromiseFunction,
  setPopovers: uninitialisedFunction,
  setTranslatingLanguageCode: uninitialisedFunction,
});

interface LanguageSettingsValues {
  translate: UseMutateAsyncFunction<
    Article,
    unknown,
    {
      articleId: number;
      toLanguage: string;
    },
    unknown
  >;
  translatingLanguageCode?: string;
  translatedArticle?: Article;
  isTranslating: boolean;
  isTranslatingError: boolean;
  isTranslatingSuccess: boolean;
  error?: unknown;
  popovers?: Popovers;
  addedDropdownLanguages: Language[];
  hasArticleOwnerPermissions: boolean;
  isTranslatingAddedLanguage: (languageCode: string) => boolean;
  isTranslatingSuccessAddedLanguage: (languageCode: string) => boolean;
  setAddedDropdownLanguages: React.Dispatch<React.SetStateAction<Language[]>>;
  setPopovers: React.Dispatch<React.SetStateAction<Popovers | undefined>>;
  setTranslatingLanguageCode: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  setTranslationStatus: (
    languageName: string,
    status: 'draft' | 'approved' | 'auto'
  ) => Record<string, Record<string, TranslationStatusEnum>> | undefined;
}

type ContextProps = {
  children: ReactNode;
};

interface Popovers {
  openSettingsPopover?: string;
  openCollaboratorsPopover?: string;
}

const LanguageSettingsProvider = ({ children }: ContextProps) => {
  const {
    data: user,
    hasCreateArticlePermission,
    hasConnectAccessPermission,
  } = useAuthenticatedUser();

  const article = useSelector(selectors.getArticle);

  const {
    mutateAsync: translate,
    isLoading: isTranslating,
    isError: isTranslatingError,
    isSuccess: isTranslatingSuccess,
    error,
    data: translatedArticle,
  } = useTranslateArticleMutation();

  const [popovers, setPopovers] = useState<Popovers | undefined>({
    openSettingsPopover: undefined,
    openCollaboratorsPopover: undefined,
  });

  const [addedDropdownLanguages, setAddedDropdownLanguages] = useState<
    Language[]
  >([]);

  const [translatingLanguageCode, setTranslatingLanguageCode] = useState<
    string | undefined
  >(undefined);

  const isLanguageAdded = (languageCode: string) =>
    addedDropdownLanguages.some((language) => language.code === languageCode);

  const setTranslationStatus = (
    languageCode: string,
    status: 'draft' | 'approved' | 'auto'
  ) => {
    const translationStatus = article?.blocks.reduce(
      (acc: Record<string, Record<string, TranslationStatusEnum>>, block) => {
        if (acc[block.id] === undefined) acc[block.id] = {};

        acc[block.id][languageCode] = status;
        return acc;
      },
      {}
    );

    if (!translationStatus) return;

    if (translationStatus['title'] === undefined)
      translationStatus['title'] = {};

    translationStatus['title'][languageCode] = status;

    return translationStatus;
  };

  const isTranslatingAddedLanguage = (languageCode: string) =>
    isLanguageAdded(languageCode) && isTranslating;
  const isTranslatingSuccessAddedLanguage = (languageCode: string) =>
    isLanguageAdded(languageCode) && isTranslatingSuccess;

  const isArticleOwner = article?.createdBy === user?.id;
  const hasArticleOwnerPermissions =
    (isArticleOwner && hasCreateArticlePermission) ||
    hasConnectAccessPermission;

  const contextValue: LanguageSettingsValues = {
    translatedArticle,
    isTranslating,
    isTranslatingError,
    isTranslatingSuccess,
    addedDropdownLanguages,
    error,
    popovers,
    translatingLanguageCode,
    hasArticleOwnerPermissions,
    setTranslatingLanguageCode,
    isTranslatingSuccessAddedLanguage,
    isTranslatingAddedLanguage,
    setAddedDropdownLanguages,
    translate,
    setPopovers,
    setTranslationStatus,
  };

  return (
    <LanguageSettingsContext.Provider value={contextValue}>
      {children}
    </LanguageSettingsContext.Provider>
  );
};

const useLanguageSettingsContext = () => {
  const context = useContext(LanguageSettingsContext);

  if (context === undefined)
    throw new Error(
      'useLanguageSettingsContext must be used within a LanguageSettingsProvider'
    );

  return context;
};

export { LanguageSettingsProvider, useLanguageSettingsContext };
export type { LanguageSettingsValues };
