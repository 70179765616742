import { useCommentsContext } from '../context';
import useFetchComments from '../hooks/hooks';

import CommentDisableView from './CommentDisableView';
import CommentItem from './CommentItem';
import CommentSkeleton from './CommentSkeleton';
import NoComments from './NoComments';

const CommentsList = () => {
  const {
    comments,
    isCommentsAndParentsLoading,
    isCommentsLoading,
    metaComments,
    fetchComments,
    isCommentsFetching,
    allowComments,
    isUserLoading,
    errorCommentObject,
    articleId,
  } = useCommentsContext();

  const isLoading =
    isCommentsLoading || isUserLoading || isCommentsAndParentsLoading;
  const isCommentsEmpty =
    !isLoading && !errorCommentObject && comments.length === 0;

  const commentSkeletons = Array.from({ length: 5 }).map((_, key) => (
    <CommentSkeleton key={key} />
  ));

  const { hasMorePages, threshold } = useFetchComments(
    fetchComments,
    isLoading,
    isCommentsFetching,
    metaComments
  );

  if (!allowComments) return <CommentDisableView />;
  if (isCommentsEmpty) return <NoComments />;

  return (
    <>
      {isLoading && <>{commentSkeletons}</>}
      <div className="flex flex-col">
        {errorCommentObject && <CommentItem comment={errorCommentObject} />}
        {comments
          .filter((comment) => comment.objectId === articleId)
          .map((comment) => (
            <CommentItem key={comment.id} comment={comment} />
          ))}
      </div>
      {hasMorePages &&
        (!isCommentsFetching ? (
          <div ref={threshold}></div>
        ) : (
          <CommentSkeleton />
        ))}
    </>
  );
};

export default CommentsList;
