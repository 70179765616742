import config from 'app/config';
import SwitchLib, { ReactSwitchProps } from 'react-switch';

const Switch = ({ onColor, height, ...rest }: ReactSwitchProps) => (
  <SwitchLib
    height={height ?? 32}
    onColor={onColor ?? config.colors.success}
    boxShadow={undefined}
    handleDiameter={height ? height - 4 : 28}
    checkedIcon={false}
    uncheckedIcon={false}
    {...rest}
  />
);

export default Switch;
