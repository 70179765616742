import { useEditor } from '@craftjs/core';
import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import postScormLmsMessage from 'app/api/postScormLmsMessage';
import { ScormLmsMessage } from 'app/api/types';
import { useScreenBreakpoint, useSelector } from 'app/hooks';
import CommentsView from 'app/pages/Comments';
import useCommentUrlHash from 'app/pages/Comments/hooks/useCommentUrlHash';
import { editorTypes } from 'app/router/constants';
import { actions as commentActions } from 'app/store/comments';
import { actions as editorActions, selectors } from 'app/store/editor';
import { ActionStats } from 'app/store/editor/types';
import { isMobile } from 'app/utils';
import isIframed from 'app/utils/isIframed';
import { createElement, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ActionTypeEnum } from 'submodules/common-ui/generated/api/gcs';
import { Drawer } from 'vaul';

import Backlinks from '../Backlinks';
import EditorCanvas from '../components/EditorCanvas';
import ModalHandler from '../components/ModalHandler';
import PreviewModePicker, {
  PreviewMode,
} from '../components/PreviewModePicker';
import PreviewNavigation from '../components/PreviewNavigation';
import { ACTION_BLOCKS } from '../helpers/blocks';

import ActionsView from './ActionsView';

const ActionsMode = () => {
  const dispatch = useDispatch();
  const breakpoint = useScreenBreakpoint();
  const mobile = isMobile(breakpoint);
  const [previewMode, setPreviewMode] = useState(PreviewMode.Read);
  const [showTableContents, setShowTableContents] = useState(false);
  const { mandatoryProgress, mandatoryScore } = useSelector(
    selectors.getActionStats
  );

  const { commentId } = useCommentUrlHash();

  const article = useSelector(selectors.getArticle);
  const showComments = useSelector(({ comments }) => comments.isVisible);

  const {
    actions: { selectNode },
    selected: selectedProps,
    task: selectedTask,
  } = useEditor((state) => {
    const [currentNodeId] = state.events.selected;
    let selected;
    let task;

    if (currentNodeId) {
      const node = state.nodes[currentNodeId];
      selected = {
        id: currentNodeId,
        name: node.data.name,
        settings: node.related && node.related.settings,
      };
      task = ACTION_BLOCKS.includes(node.data.displayName)
        ? node.data.props
        : undefined;
    }

    return {
      task,
      selected,
    };
  });

  const actionBlocks = useMemo(
    () =>
      article?.blocks.filter((block) =>
        (Object.values(ActionTypeEnum) as string[]).includes(block.type)
      ),
    [article?.blocks]
  );

  useEffect(() => {
    if (!commentId) return;

    dispatch(commentActions.showCommentModal());
  }, [commentId, dispatch]);

  useEffect(() => {
    const stats: ActionStats = {
      mandatoryProgress: article?.myMandatoryProgress,
      mandatoryScore: article?.myMandatoryScore,
      progress: article?.myProgress,
      score: article?.myScore,
    };

    dispatch(editorActions.setStats(stats));
  }, [article, dispatch]);

  useEffect(() => {
    if (previewMode === PreviewMode.Read || mobile) return;

    setPreviewMode(PreviewMode.Read);
  }, [previewMode, mobile]);

  const animate = () => Boolean(Settings) || showComments || showTableContents;

  const Settings =
    selectedProps &&
    ACTION_BLOCKS.includes(selectedProps.name) &&
    selectedProps.settings &&
    createElement(selectedProps.settings);

  useEffect(() => {
    if (!isIframed || actionBlocks === undefined) return;

    if (mandatoryScore) {
      const message: ScormLmsMessage = {
        key: 'articleScore',
        score: mandatoryScore,
      };
      postScormLmsMessage(message);
    }

    if (mandatoryProgress === 100) {
      const message: ScormLmsMessage = {
        key: 'articleStatus',
        status: 'completed',
      };

      postScormLmsMessage(message);
    }
  }, [actionBlocks, mandatoryProgress, mandatoryScore]);

  const renderActionsMode = () => (
    <>
      {(!mobile || previewMode === PreviewMode.Read) && (
        <>
          <PreviewNavigation onShowTableContents={setShowTableContents} />
          {showComments && (
            <StyledWrapper className="fixed md:bottom-6 right-0 lg:right-8 flex flex-col w-full h-full md:h-[calc(100%-96px)] lg:w-[456px] lg:max-h-[672px] bg-white lg:border lg:border-gray-light lg:rounded-xl overflow-hidden z-50">
              <CommentsView />
            </StyledWrapper>
          )}
          <EditorCanvas
            animate={
              !selectedTask || selectedTask?.isLocked ? false : animate()
            }
            className="mt-[54px] lg:mt-[96px]"
            mode={editorTypes.actions}
          >
            <>
              {Settings}
              <Backlinks
                articleId={article?.id}
                className={cx(
                  'flex-auto flex flex-col justify-end mt-5 lg:mt-0 lg:mb-5 transition duration-300 ease-in-out transform',
                  {
                    'lg:-translate-x-[50px]': animate(),
                  }
                )}
              />
            </>
          </EditorCanvas>
        </>
      )}
      {actionBlocks && actionBlocks.length > 0 && mobile && (
        <>
          <PreviewModePicker
            previewMode={previewMode}
            className="fixed bottom-8 left-1/2 -translate-x-1/2 z-40"
            onClick={(mode) => setPreviewMode(mode)}
          />
          {previewMode === PreviewMode.Action && (
            <ActionsView onClick={() => setPreviewMode(PreviewMode.Read)}>
              {Settings}
            </ActionsView>
          )}
        </>
      )}
      <ModalHandler />
    </>
  );

  return mobile ? (
    <Drawer.Root
      onClose={() => setTimeout(() => selectNode(undefined), 250)}
      modal={false}
      dismissible={false}
    >
      {renderActionsMode()}
    </Drawer.Root>
  ) : (
    renderActionsMode()
  );
};

const StyledWrapper = styled.div`
  & {
    scrollbar-width: thin;
  }

  &::-webkit-scrollbar {
    width: 0px;
  }
`;

export default ActionsMode;
