import { cx } from '@emotion/css';
import { ScrollbarContainer } from 'app/components';
import ArrowDropDownLineIcon from 'remixicon-react/ArrowDownSFillIcon';

interface Props<T> {
  items: T[];
  isOpen: boolean;
  item: T;
  setIsOpen: (open: boolean) => void;
  setItem: (item: T) => void;
}

const RecurringArticleTimeAction = <
  T extends { label: string; value: string },
>({
  items,
  isOpen,
  item,
  setIsOpen,
  setItem,
}: Props<T>) => (
  <button className="my-auto w-full" onClick={() => setIsOpen(!isOpen)}>
    <div className="flex justify-center items-center">
      <span className="text-sm text-left text-grayscale-primary">
        {item.label}
      </span>
      <div className="ml-auto mr-1">
        <ArrowDropDownLineIcon
          size={16}
          className={cx('transform text-grayscale-secondary', {
            'rotate-180': isOpen,
          })}
        />
      </div>
    </div>
    {isOpen && (
      <ScrollbarContainer className="w-min border border-gray-light rounded shadow-atobi absolute max-h-[188px] bg-white overflow-y-auto z-10">
        {items.map((option) => (
          <button
            key={option.value}
            onClick={() => setItem(option)}
            className={cx(
              `w-full py-2 px-3 text-left text-sm focus:outline-none`,
              {
                'bg-focus-background': option.value === item.value,
              }
            )}
          >
            {option.value}
          </button>
        ))}
      </ScrollbarContainer>
    )}
  </button>
);

export default RecurringArticleTimeAction;
