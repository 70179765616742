import { useNode } from '@craftjs/core';
import { css, cx } from '@emotion/css';
import config from 'app/config';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import BlockTypes from 'app/pages/Editor/helpers/constants';
import { isValidURL } from 'app/utils';
import { InfoCircle, Link1 } from 'iconsax-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import BaseBlockContainer from '../BaseBlockContainer';

export interface UrlBlockProps {
  name?: string;
  url?: string;
  isValidUrl?: boolean;
}

const UrlBlock = ({ name, url, isValidUrl = true }: UrlBlockProps) => {
  const {
    id,
    connectors: { connect },
    selected,
    actions: { setProp },
  } = useNode((state) => ({
    selected: state.events.selected,
  }));

  const [mounted, setMounted] = useState(false);
  const [urlValue, setUrlValue] = useState('');
  const [faviconUrl, setFaviconUrl] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [debouncedValue] = useDebounce(urlValue, 1000);

  const { t } = useArticlesTranslation();

  const { mode } = useParams<{ mode: string }>();

  const getFaviconUrl = useCallback(async (favUrl: string) => {
    try {
      const faviconSource = new URL('/favicon.ico', favUrl).toString();

      const img = new Image();
      img.src = faviconSource;

      img.onload = function () {
        setFaviconUrl(faviconSource);
      };

      img.onerror = function () {
        setFaviconUrl('');
      };
    } catch (e) {
      setFaviconUrl('');
    }
  }, []);

  const onClickUrlBlock = () => {
    window.open(url, '_blank');
  };

  useEffect(() => {
    if (!debouncedValue) return setFaviconUrl('');

    const isValid = isValidURL(debouncedValue);
    setProp((props: UrlBlockProps) => {
      props.isValidUrl = isValid;
    });

    getFaviconUrl(debouncedValue);
  }, [debouncedValue, getFaviconUrl, setProp]);

  useEffect(() => {
    if (!mounted) {
      if (url) getFaviconUrl(url);
      setMounted(true);
    }
  }, [getFaviconUrl, mounted, url]);

  const placeholder = {
    name: t('Type link name'),
    url: t('Enter URL'),
  };

  const isActionsMode = mode === 'actions';

  const nameClassName = useMemo(() => {
    return cx(
      'w-full  text-focus font-bold mb-1 bg-transparent outline-none block px-2',
      {
        'border-error border-[1px] rounded placeholder:text-error':
          !selected && !name,
        'placeholder:text-grayscale-secondary': selected,
      }
    );
  }, [name, selected]);

  const urlClassName = useMemo(() => {
    return cx('grow text-sm bg-transparent outline-none block', {
      'placeholder:text-error': !selected && !url,
      'placeholder:text-grayscale-secondary': selected,
    });
  }, [selected, url]);

  return (
    <div ref={(ref) => ref && connect(ref)}>
      <BaseBlockContainer
        selected={selected}
        nodeId={id}
        type={'Url'}
        deleteOnBackspace={!isEditing || (!name && !url)}
      >
        <div
          role="presentation"
          onClick={() => isActionsMode && onClickUrlBlock()}
          className="bg-hover-blue rounded flex items-center gap-4 m-2 py-4 px-6"
        >
          <Link1 size={24} color={config.colors.focus} />

          <div className="grow">
            {isActionsMode ? (
              <span className={nameClassName}>{name}</span>
            ) : (
              <input
                className={nameClassName}
                placeholder={placeholder.name}
                value={name ?? ''}
                onFocus={() => setIsEditing(true)}
                onBlur={() => setIsEditing(false)}
                disabled={isActionsMode}
                onChange={(e) => {
                  setProp((props: UrlBlockProps) => {
                    props.name = e.target.value;
                  });
                }}
              />
            )}

            <div
              className={cx('flex items-center gap-2 w-full px-2', {
                'border-error border-[1px] rounded placeholder:text-error':
                  !selected && !url,
                'placeholder:text-grayscale-secondary': selected,
              })}
            >
              {!!faviconUrl && (
                <img className="h-5 w-5" src={faviconUrl} alt="" />
              )}
              {isActionsMode ? (
                <span
                  className={cx(urlClassName, css('word-break: break-word;'))}
                >
                  {decodeURIComponent(url ?? '')}
                </span>
              ) : (
                <input
                  className={urlClassName}
                  placeholder={placeholder.url}
                  value={url ?? ''}
                  onFocus={() => setIsEditing(true)}
                  onBlur={() => setIsEditing(false)}
                  disabled={isActionsMode}
                  onChange={(e) => {
                    setUrlValue(e.target.value);
                    setProp((props: UrlBlockProps) => {
                      props.url = e.target.value;
                    });
                  }}
                />
              )}
            </div>

            {!isValidUrl && (
              <span className="text-sm text-error flex gap-1 items-center mt-1">
                <InfoCircle size={16} />
                <span>Invalid link</span>
              </span>
            )}
          </div>
        </div>
      </BaseBlockContainer>
    </div>
  );
};

UrlBlock.craft = {
  displayName: BlockTypes.UrlBlock,
};

export { UrlBlock };
export default UrlBlock;
