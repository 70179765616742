import { useEditor, useNode } from '@craftjs/core';
import { PageLoader } from 'app/components';
import useFileDirectUrls from 'app/hooks/useFileDirectUrls';
import useFileUpload from 'app/hooks/useFileUpload';
import useIsEditorEnabled from 'app/hooks/useIsEditorEnabled';
import BlockTypes from 'app/pages/Editor/helpers/constants';
import { selectors as authSelectors } from 'app/store/auth';
import { selectors } from 'app/store/editor';
import { logger } from 'app/utils';
import {
  ArrowCircleDown2,
  DocumentText1,
  Maximize3,
  Refresh,
} from 'iconsax-react';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import { useSelector } from 'react-redux';
import {
  ExternalFile,
  InternalFileGet,
} from 'submodules/common-ui/generated/api/gcs/api';

import MAX_FILE_SIZE from '../../constants';
import useDownloadFile from '../../hooks/useDownloadFile';
import BaseBlockContainer from '../BaseBlockContainer';
import MediaBlockError from '../MediaBlockError';
import MediaBlockLoading from '../MediaBlockLoading';
import MediaBlockPlaceholder from '../MediaBlockPlaceholder';
import MediaBlockOptionSettings, {
  AddedFile,
  UploadedFile,
} from '../MediaBlockSettings/MediaBlockOptionSettings';
import { SettingsState } from '../MediaBlockSettings/types';

interface PDFBlockProps {
  pdfSchema?: InternalFileGet | ExternalFile;
  file?: File;
  error?: string;
  translationStatus: string;
  isUploading?: boolean;
}

const PDFBlock = ({ pdfSchema, file, error }: PDFBlockProps) => {
  const { t } = useTranslation();

  const pdfRef = useRef<HTMLDivElement>(null);
  const pdfInputRef = useRef<HTMLInputElement>(null);

  const [fileSrc, setFileSrc] = useState<string | null>(null);
  const [numPages, setNumPages] = useState(1);

  const canEdit = useSelector(selectors.getCanEdit);

  const { upload, isLoading } = useFileUpload();
  const { enabled } = useIsEditorEnabled();

  const { directUrls } = useFileDirectUrls(pdfSchema);

  const {
    actions: {
      history: { ignore },
    },
  } = useEditor();

  const {
    connectors: { connect },
    actions: { setProp },
    selected,
    nodeId,
  } = useNode((node) => ({
    selected: node?.events?.selected,
    nodeId: node.id,
  }));

  const tenant = useSelector(authSelectors.getSelectedTenant);
  const fileId = pdfSchema?.type === 'internal' ? pdfSchema.id : '';
  const fileName = pdfSchema?.name ?? '';

  const { downloadFileToClient } = useDownloadFile({
    fileSchema: pdfSchema,
    tenantAlias: tenant?.alias,
    fileId,
    fileName,
  });

  useEffect(() => {
    ignore().setCustom(nodeId, (props: PDFBlockProps) => {
      props.isUploading = isLoading;
    });
  }, [isLoading, ignore, nodeId]);

  useEffect(() => {
    if (!fileSrc && directUrls?.original) {
      setFileSrc(directUrls.original.url);
    }
  }, [fileSrc, directUrls?.original]);

  const triggerInputClick = () => {
    if (!pdfInputRef.current || !enabled) return;

    pdfInputRef.current.click();
  };

  useEffect(() => {
    if (file) {
      const src = URL.createObjectURL(file);
      setFileSrc(src);
      return () => URL.revokeObjectURL(src);
    }
  }, [file]);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  const onPDFChange = (e: ChangeEvent<HTMLInputElement>) => {
    const pdf = e?.target.files?.[0];
    if (!pdf) return logger.debug('MediaPicker onFileChange file is null');

    if (pdf.size > MAX_FILE_SIZE) {
      setProp((props: PDFBlockProps) => {
        props.file = undefined;
        props.pdfSchema = undefined;
        props.error = `${t('Your file is too big. The limit is {{size}} MB', {
          size: 500,
        })}`;
      });
      return;
    }

    uploadPDF(pdf);
  };

  const uploadPDF = (pdf: File) => {
    upload(pdf, {
      onSuccess: (response) => {
        setProp((props: PDFBlockProps) => {
          props.error = '';
          props.file = pdf;
          props.pdfSchema = {
            type: 'internal',
            id: response.id,
            url: '',
            name: pdf.name,
            translationStatus: 'draft',
          } as InternalFileGet;
        });
      },
      onError: logger.error,
    });
  };

  const PdfView = () => {
    return (
      <Document
        file={fileSrc}
        loading={<MediaBlockLoading />}
        error={<MediaBlockError />}
        className="rounded z-0 w-full p-4 bg-cream"
        onLoadSuccess={({ numPages: pages }) => setNumPages(pages)}
        onLoadError={(err) => logger.error(err.message)}
      >
        <div className="h-[200px] lg:h-[400px] overflow-y-scroll scroll-hidden">
          {Array.from({ length: numPages }).map((_, i) => (
            <Page
              key={`pdf${i}`}
              pageNumber={i + 1}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              loading={<PageLoader />}
              width={
                pdfRef.current?.offsetWidth
                  ? pdfRef.current.offsetWidth - 32
                  : 0
              }
            />
          ))}
        </div>
      </Document>
    );
  };

  return (
    <div ref={(ref) => ref && connect(ref)} className="mx-2">
      <BaseBlockContainer
        deleteOnBackspace={enabled}
        nodeId={nodeId}
        readOptions={[
          {
            disabled: !pdfSchema,
            download: true,
            Icon: ArrowCircleDown2,
            text: t('Download'),
            onClick: downloadFileToClient,
          },
          {
            disabled: !fileSrc,
            Icon: Maximize3,
            text: t('Fullscreen'),
            onClick: () => {
              if (!fileSrc) return;
              window.open(fileSrc, '_blank');
            },
          },
        ]}
        options={[
          {
            Icon: Refresh,
            text: t('Replace'),
            onClick: triggerInputClick,
            disabled: !canEdit,
          },
        ]}
        selected={selected}
        type="PDF"
      >
        <div ref={pdfRef}>
          {error && (
            <MediaBlockError
              error={error}
              onDoubleClick={triggerInputClick}
              fileId={fileId}
            />
          )}
          {!fileSrc && directUrls?.original && <PageLoader />}
          {!fileSrc && !directUrls?.original && (
            <MediaBlockPlaceholder
              isLoading={isLoading}
              type={t('PDF file')}
              TypeIcon={DocumentText1}
              onChange={onPDFChange}
            />
          )}

          {fileSrc && (
            <div role="presentation" onDoubleClick={() => triggerInputClick()}>
              <PdfView />
            </div>
          )}
          <input
            type="file"
            ref={pdfInputRef}
            className="hidden"
            onChange={onPDFChange}
            disabled={!enabled}
          />
        </div>
      </BaseBlockContainer>
    </div>
  );
};

const PDFSettings = () => {
  const {
    actions: {
      history: { ignore },
    },
  } = useEditor();

  const {
    selectedProps,
    nodeId,
    actions: { setProp },
  } = useNode((node) => {
    return {
      selectedProps: node.data.props,
      nodeId: node.id,
    };
  });

  const { t } = useTranslation();

  const state: SettingsState = {
    id: selectedProps?.pdfSchema?.id,
    url: selectedProps?.pdfSchema?.url,
    name: selectedProps?.pdfSchema?.name,
  };

  const getPropPayload = (
    data: string | UploadedFile | AddedFile
  ): { file: File | undefined; pdfSchema: InternalFileGet | ExternalFile } => {
    if (typeof data === 'string') {
      return {
        file: undefined,
        pdfSchema: {
          type: 'external',
          name: '',
          url: data,
          translationStatus: 'draft',
        },
      };
    }

    if (data.type === 'uploaded') {
      return {
        file: data.data,
        pdfSchema: {
          type: 'internal',
          id: data.id,
          url: '',
          name: data.data.name,
          translationStatus: 'draft',
        } as InternalFileGet,
      };
    }

    return {
      file: data.data,
      pdfSchema: {
        type: 'internal',
        id: '',
        url: '',
        name: data.data.name,
        translationStatus: 'draft',
      } as InternalFileGet,
    };
  };

  return (
    <MediaBlockOptionSettings
      state={state}
      inputLabel={t('PDF')}
      hasEnterUrlOption={false}
      onChange={(data) => {
        ignore().setCustom(nodeId, (props: PDFBlockProps) => {
          if (typeof data !== 'string' && data.type === 'error') {
            props.isUploading = false;
            return;
          }

          props.isUploading = typeof data !== 'string' && data.type === 'added';
        });
        setProp((props: PDFBlockProps) => {
          if (typeof data !== 'string' && data.type === 'error') {
            props.file = undefined;
            props.pdfSchema = undefined;
            props.error = data.error;
            return;
          }

          const { file, pdfSchema } = getPropPayload(data);
          props.file = file;
          props.pdfSchema = pdfSchema;
          props.error = '';
        });
      }}
    />
  );
};

PDFBlock.craft = {
  diplayName: BlockTypes.PDFBlock,
  related: {
    settings: PDFSettings,
  },
};

export { PDFBlock };
export default PDFBlock;
