import { cx } from '@emotion/css';
import { selectors } from 'app/store/editor';
import { useSelector } from 'react-redux';
import {
  AnswerChoiceSummary,
  GetChoiceSchema,
  Text,
} from 'submodules/common-ui/generated/api/gcs';

interface Props {
  option: GetChoiceSchema;
  choiceSummary?: AnswerChoiceSummary;
}

const PollOption = ({ option: { variants }, choiceSummary }: Props) => {
  const languange = useSelector(selectors.getActiveLanguage);
  const percentage = choiceSummary ? choiceSummary.choicePercentage : 0;

  const imageUrl = variants[languange]?.image?.directUrls?.small?.url;
  const answerText = (variants[languange]?.answer?.children?.[0] as Text)
    ?.value;

  return (
    <div className="h-10 border rounded bg-grayscale-bg-dark text-hover-primary text-sm relative border-gray-light">
      <div
        className={cx(
          'h-full rounded rounded-se-[0] rounded-ee-[0] bg-focus-background',
          {
            [`w-[${percentage}%]`]: percentage > 0,
            'w-0': percentage === 0,
          }
        )}
      />
      <div
        className={cx(
          'absolute h-full w-full flex justify-between items-center z-10 left-0 top-0 right-0 bottom-0',
          {
            'pe-3': !!imageUrl,
            'px-3 py-2.5': !imageUrl,
          }
        )}
      >
        <div className="flex items-center gap-2 truncate">
          {imageUrl && (
            <img
              className="w-[66px] h-[40px] object-cover rounded-s"
              src={imageUrl}
              alt="choice"
            />
          )}
          {answerText && <span className="truncate">{answerText}</span>}
        </div>

        <span>{percentage}%</span>
      </div>
    </div>
  );
};

export default PollOption;
