import narrowUnknown from 'app/utils/narrowUnknown';
import last from 'lodash/last';
import { useInfiniteQuery } from 'react-query';
import { SortOrderEnum } from 'submodules/common-ui/generated/api/gcs';

import { resources, queryKeys } from '../index';

export interface GetActionAnswerProps {
  id: number;
  page?: number;
  perPage?: number;
  userIds?: number[];
  answerIds?: number[];
  sortScore?: SortOrderEnum;
  sortCreatedAt?: SortOrderEnum;
  actionInstanceId?: number;
  enabled?: boolean;
  originalArticleId?: number | null;
}

const useGetActionAnswer = ({
  id,
  perPage,
  sortScore,
  sortCreatedAt,
  actionInstanceId,
  userIds,
  enabled = true,
  originalArticleId,
  answerIds,
}: GetActionAnswerProps) => {
  const {
    data,
    isLoading,
    isFetchingNextPage,
    error: apiError,
    refetch,
    fetchNextPage,
  } = useInfiniteQuery(
    queryKeys.actionAnswer(
      id,
      userIds,
      sortScore,
      sortCreatedAt,
      actionInstanceId,
      answerIds
    ),
    ({ pageParam }) =>
      resources.getActionAnswer({
        id,
        originalArticleId,
        page: pageParam || 0,
        perPage,
        sortScore,
        sortCreatedAt,
        actionInstanceId,
        userIds,
        answerIds,
      }),
    {
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => lastPage.data.meta.currentPage + 1,
      enabled,
    }
  );

  return {
    actionAnswers: data?.pages.flatMap((x) => x.data.data) || [],
    actionAnswersMeta: last(data?.pages)?.data.meta,
    isLoading,
    isFetchingNextPage,
    error: narrowUnknown(apiError),
    apiError,
    refetch,
    fetchNextPage,
  };
};

export { useGetActionAnswer };
export default useGetActionAnswer;
