import { cx } from '@emotion/css';
import config from 'app/config';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { actions, selectors } from 'app/store/editor';
import { ArrowRight2 } from 'iconsax-react';
import { useDispatch, useSelector } from 'react-redux';
import { Article } from 'submodules/common-ui/generated/api/gcs';

interface Props {
  article?: Article;
}

const TopBarTabsIndicatorContainer = ({ article }: Props) => {
  const { t } = useArticlesTranslation();
  const dispatch = useDispatch();
  const activeTab = useSelector(selectors.getEditorTab);

  const tabs: { [key: string]: string } = {
    create: t('Create'),
    results: t('Insights'),
  };

  const actionButton = ({
    selected,
    disabled,
    onClick,
    title,
  }: {
    title: string;
    selected: boolean;
    onClick: () => void;
    disabled?: boolean;
  }) => (
    <button
      disabled={disabled}
      onClick={onClick}
      className={cx('py-1.5 px-3 text-sm', {
        'rounded-lg text-focus bg-focus-background font-bold': selected,
        'text-grayscale-secondary': disabled,
      })}
    >
      {title}
    </button>
  );

  const wasOrIsPublished =
    article?.status === 'published' ||
    (article?.status === 'archived' && article.publishAt !== null);
  return (
    <div className="flex items-center">
      {actionButton({
        title: tabs.create,
        onClick: () => dispatch(actions.setEditorTab('create')),
        selected: 'create' === activeTab,
      })}

      <>
        <ArrowRight2 size={16} color={config.colors.black} className="mx-1" />
        {actionButton({
          title: tabs.results,
          disabled: !article?.isShared && !wasOrIsPublished,
          onClick: () => dispatch(actions.setEditorTab('results')),
          selected: 'results' === activeTab,
        })}
      </>
    </div>
  );
};

export default TopBarTabsIndicatorContainer;
