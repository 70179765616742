import { css, cx } from '@emotion/css';
import { useAuthenticatedUser } from 'app/api/auth/hooks';
import { useSelector } from 'app/hooks';
import Search from 'app/pages/Home/components/Search';
import { routes } from 'app/router';
import { selectors } from 'app/store/navigation';
import { ArrowLeft2, TextalignJustifyleft } from 'iconsax-react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import Breadcrumbs from '../Breadcrumbs';
import {
  SIDEBAR_ANIMATION_DURATION,
  SIDEBAR_ANIMATION_TRANSLATION,
} from '../Navigation/types';

export const MOBILE_TOP_MENU_HEIGHT = '55px';

interface MobileTopMenuProps {
  toggleSidebar: VoidFunction;
  tabsHidden: boolean;
}

const MobileTopMenu = ({ toggleSidebar, tabsHidden }: MobileTopMenuProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { data } = useAuthenticatedUser();
  const isSidebarVisible = useSelector(selectors.getIsSidebarOpen);

  const backHistory = [
    {
      match: '/relevant-channel/(\\d+)/(\\d+)',
      pathName: '/relevant-channel/:id/:postId',
      to: (channelId: number) => routes.relevantChannel.create(channelId),
    },
    {
      match: '/relevant-channel/(\\d+)',
      pathName: '/relevant-channel/:id',
      to: () => routes.relevantChannels.create(),
    },
    {
      match: '/article-studio/editor/(\\d+)/actions',
      pathName: '/article-studio/editor/:id/actions',
      to: (channelId: number) => routes.relevantChannel.create(channelId),
    },
  ];

  const onBackPress = () => {
    const back = backHistory.find((item) => pathname.match(item.match));

    if (!back) return navigate(-1);

    const route = matchPath(back.pathName, pathname);

    const id = Number(route?.params.id);
    if (Number.isNaN(id)) return;

    navigate(back.to(id), { replace: true });
  };

  return (
    <div
      className={cx(
        'w-full bg-white z-50 border-b-[0.5px] border-gray-silver-blue',
        isSidebarVisible
          ? SIDEBAR_ANIMATION_TRANSLATION
          : SIDEBAR_ANIMATION_DURATION,
        css(`height: ${MOBILE_TOP_MENU_HEIGHT}`)
      )}
    >
      <div className="flex items-center justify-between pt-3.5 px-4">
        <div className="flex items-center">
          {tabsHidden ? (
            <button className="mr-3" onClick={onBackPress}>
              <ArrowLeft2 size={22} />
            </button>
          ) : (
            <button
              className="bg-white mr-3"
              type="button"
              onClick={toggleSidebar}
            >
              <TextalignJustifyleft />
            </button>
          )}
          <Breadcrumbs />
        </div>
        <div className="flex items-center">
          <Search iconClassName="mr-3" />
          <button onClick={() => navigate(routes.profile.create('view'))}>
            <img
              className="w-7 h-7 rounded-lg"
              src={data?.avatars.small}
              alt={'Avatar'}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default MobileTopMenu;
