import { Node, useEditor } from '@craftjs/core';
import { cx } from '@emotion/css';
import { useAuthenticatedUser } from 'app/api/auth/hooks';
import BeforeLeaveModal from 'app/components/BeforeLeaveModal';
import SaveAndTranslateModal from 'app/components/SaveAndTranslateModal';
import SimpleToast from 'app/components/Toast/SimpleToast';
import ToastContainer from 'app/components/Toast/ToastContainer';
import { useSelector } from 'app/hooks';
import {
  useArticlesTranslation,
  useCommonTranslation,
} from 'app/internationalization/hooks';
import { routes } from 'app/router';
import { editorTypes } from 'app/router/constants';
import { actions, selectors } from 'app/store/editor';
import { actions as modalActions } from 'app/store/modal';
import { ModalTypes } from 'app/store/modal/types';
import Spinner from 'assets/icons/spinner.svg?react';
import dayjs from 'dayjs';
import { Calendar, Danger, Repeat, TickCircle } from 'iconsax-react';
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  useNavigate,
  useLocation,
  useParams,
  BlockerFunction,
  useBlocker,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { TranslationStatusEnum } from 'submodules/common-ui/generated/api/gcs';

import BlockGeneration from '../components/BlockGenerate/BlockGeneration';
import ConvertToTemplateModal from '../components/ConvertToTemplateModal';
import EditorCanvas from '../components/EditorCanvas';
import GenerateScormPackageModal from '../components/GenerateScormPackageModal/GenerateScormPackageModal';
import GenerateScormPackageSuccessModal from '../components/GenerateScormPackageModal/GenerateScormPackageSuccessModal';
import { LanguagesModalProps } from '../components/LanguagesModal/LanguagesModal';
import MissingActionsModal from '../components/MissingActionsModal';
import ModalHandler from '../components/ModalHandler';
import OverwriteModal from '../components/OverwriteModal';
import PublishModalStepper from '../components/PublishModalStepper/PublishModalStepper';
import SaveBeforeLanguageChangeModal, {
  SaveBeforeLanguageType,
} from '../components/SaveBeforeLanguageChangeModal';
import SideMenu from '../components/SideMenu/SideMenu';
import TopBar from '../components/TopBar/TopBar';
import BlockTypes from '../helpers/constants';
import mandatoryFieldsMissing from '../helpers/mandatoryFields';
import useCanChangeArticleStructure from '../hooks/useCanChangeArticleStructure';
import useCurrentArticleLanguage from '../hooks/useCurrentArticleLanguage';
import useEditorShortcuts from '../hooks/useEditorShortcuts';
import useMutateArticle from '../hooks/useMutateArticle';

const Editor = () => {
  const { id: articleId } = useParams<{ id?: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useArticlesTranslation();
  const { t: ct } = useCommonTranslation();
  const {
    data: user,
    hasCreateArticlePermission,
    hasUpdateArticlePermission,
  } = useAuthenticatedUser();

  const {
    nodes,
    actions: { selectNode },
  } = useEditor((_, editor) => ({
    nodes: editor.getNodes(),
  }));

  const [initialNodes, setInitialNodes] = useState<Record<string, Node>>({});
  const [overwriteModalOpen, setOverwriteModalOpen] = useState(false);
  const [autoTranslateModalOpen, setAutoTranslateModalOpen] = useState(false);
  const [beforeLeaveModalOpen, setBeforeLeaveModalOpen] = useState(false);
  const [stepPublishModalOpen, setStepPublishModalOpen] = useState(false);
  const [missingActionsModalOpen, setMissingActionsModalOpen] = useState(false);
  const [beforeLanguageModal, setBeforeLanguageModal] = useState<
    SaveBeforeLanguageType | undefined
  >(undefined);
  const [templateModalOpen, setTemplateModalOpen] = useState(false);
  const [generateScormPackageModalOpen, setGenerateScormPackageModalOpen] =
    useState(false);
  const [
    generateScormPackageSuccessModalOpen,
    setGenerateScormPackageSuccessModalOpen,
  ] = useState(false);
  const [saveToastText] = useState(location?.state?.toastText);
  const [requiredLanguage, setRequiredLanguage] = useState<string | null>(null);

  const selectedCollaborators = useSelector(selectors.getSelectedCollaborators);
  const selectedLanguages = useSelector(selectors.getSelectedLanguages);
  const language = useSelector(selectors.getActiveLanguage);
  const mainLanguage = selectedLanguages.find((l) => l.isDefault)?.code ?? 'en';
  const currentLanguageCode = useCurrentArticleLanguage();

  const recurringDate = useSelector(selectors.getRecurringDate);

  const publishDate = useSelector(selectors.getPublishDate);
  const archiveDate = useSelector(selectors.getArchiveDate);

  const userLanguages = useMemo(
    () =>
      selectedCollaborators.filter((sc) => sc.id === user?.id)[0]?.languages ??
      [],
    [selectedCollaborators, user?.id]
  );

  const article = useSelector(selectors.getArticle);
  const articleOwner = article?.createdBy ?? user?.id;

  const {
    articleState,
    publishArticle,
    saveArticle,
    saveNewArticle,
    scheduleArticle,
    cancelArticleSchedule,
    archiveArticle,
    unarchiveArticle,
    templateArticle,
    isCreatingArticle,
    isUpdatingArticle,
  } = useMutateArticle({ article, nodes });

  const { onKeyDown } = useEditorShortcuts();
  const { canChangeArticleStructure } = useCanChangeArticleStructure();

  useEffect(() => {
    if (!canChangeArticleStructure) return;

    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown, canChangeArticleStructure]);

  useEffect(() => {
    if (!article) return;

    const hasLanguage = userLanguages.includes(language);
    const canEdit =
      article.createdBy === user?.id ||
      hasCreateArticlePermission ||
      hasUpdateArticlePermission ||
      user?.permissions.modules.connectAccess ||
      hasLanguage;

    dispatch(actions.setCanEdit(canEdit));
  }, [
    userLanguages,
    language,
    article,
    dispatch,
    user,
    hasCreateArticlePermission,
    hasUpdateArticlePermission,
  ]);

  useEffect(() => {
    setInitialNodes({});
  }, [language, article]);

  useEffect(() => {
    setInitialNodes((prev) => {
      if (prev && Object.keys(prev).length > 0) return prev;

      return nodes;
    });
  }, [nodes]);

  useEffect(() => {
    dispatch(actions.allowComments(article?.isReactingAllowed ?? true));
  }, [dispatch, article]);

  const getTranslationStatuses = useCallback(() => {
    if (!article) return undefined;

    const translationStatuses = article.blocks.reduce(
      (
        accumulator: Record<string, Record<string, TranslationStatusEnum>>,
        block
      ) => {
        if (accumulator[block.id] === undefined) {
          accumulator[block.id] = {};
        }
        if (block?.type !== 'article_link' && block?.type !== 'multi_file') {
          const statuses = Object.keys(block.variants).reduce(
            (
              variantStatuses: Record<string, TranslationStatusEnum>,
              variantLanguage: string
            ) => {
              variantStatuses[variantLanguage] =
                block.variants[variantLanguage]?.translationStatus;
              return variantStatuses;
            },
            {}
          );
          accumulator[block.id] = statuses;
        }
        return accumulator;
      },
      {}
    );

    Object.keys(article.variants).forEach((variantLanguage) => {
      if (translationStatuses['title'] === undefined) {
        translationStatuses['title'] = {};
      }
      translationStatuses['title'][variantLanguage] =
        article.variants[variantLanguage].translationStatus;
    });

    return translationStatuses;
  }, [article]);

  const getDraftTranslationStatuses = useCallback(() => {
    const translationStatuses = getTranslationStatuses();
    if (!translationStatuses) return;

    const draftLanguages: string[] = Object.keys(translationStatuses).reduce<
      string[]
    >((acc, key) => {
      const statusObj = translationStatuses[key];
      const languagesWithDraftInStatus = Object.keys(statusObj).filter(
        (lang) => statusObj[lang] === 'draft'
      );
      languagesWithDraftInStatus.forEach((lang) => {
        if (!acc.includes(lang)) acc.push(lang);
      });
      return acc;
    }, []);

    return draftLanguages.filter((lang) => lang !== mainLanguage);
  }, [getTranslationStatuses, mainLanguage]);

  useEffect(() => {
    const translationStatus = getTranslationStatuses();
    if (translationStatus)
      dispatch(actions.updateTranslationStatus(translationStatus));
  }, [dispatch, article, getTranslationStatuses]);

  useEffect(() => {
    dispatch(actions.addChannel(article?.channel ?? null));
  }, [article?.channel, dispatch]);

  useEffect(() => {
    dispatch(actions.addCategories(article?.categories ?? []));
  }, [article?.categories, dispatch]);

  useEffect(() => {
    if (!saveToastText) return;

    showArticleSavedToast(saveToastText, <TickCircle className={iconClass} />);
  }, [saveToastText]);

  useEffect(() => {
    if (!articleState.state) return;

    switch (articleState.state) {
      case 'created':
        onArticleCreated();
        break;
      case 'createdPublished':
        onArticleCreated(true);
        break;
      case 'updated':
        onArticleUpdated();
        break;
      case 'published':
        onArticlePublished();
        break;
      case 'scheduled':
        onArticleScheduled();
        break;
      case 'createdScheduled':
        onArticleScheduled(true);
        break;
      case 'archived':
        onArticleArchived();
        break;
      case 'unarchived':
        onArticleUnarchived();
        break;
      case 'template':
        onArticleTemplate();
        break;
      case 'scheduleCanceled':
        showArticleSavedToast(
          'Your schedule has been canceled',
          <TickCircle size={20} className={iconClass} />
        );
        break;
      case 'conflict':
        setOverwriteModalOpen(true);
        toast.dismiss();
        break;
      case 'error':
        onError();
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleState]);

  const onBlock: BlockerFunction = () => {
    if (hasUnsavedChanges() && !isCreatingArticle && !isUpdatingArticle) {
      canShowAutoTranslateModal()
        ? setAutoTranslateModalOpen(true)
        : setBeforeLeaveModalOpen(true);
      return true;
    }
    return false;
  };

  const canShowAutoTranslateModal = () => {
    const hasMainLanguageAccess = userLanguages.includes(mainLanguage);
    const isMainLanguageSelected = currentLanguageCode === mainLanguage;
    const containsOnlyMainLanguage =
      article?.languages.every((lang) => lang.language === mainLanguage) ??
      true;

    const initialNodeIds = Object.keys(initialNodes);
    const currentNodeIds = Object.keys(nodes);

    const isNewBlockAdded = currentNodeIds.length > initialNodeIds.length;

    return (
      !containsOnlyMainLanguage &&
      isNewBlockAdded &&
      hasMainLanguageAccess &&
      isMainLanguageSelected
    );
  };

  const hasUnsavedVariant = (): boolean => {
    if (coverImage === null || title === null) return true;

    if (
      article?.blocks.some(
        (block) => block.type !== 'article_link' && !block.variants[language]
      )
    ) {
      return true;
    }

    return hasUnsavedChanges();
  };

  const hasUnsavedChanges = useCallback(() => {
    const normalizedInitialNodes = Object.keys(initialNodes).map((e) => ({
      data: initialNodes[e].data.props,
    }));

    const normalizedNodes = Object.keys(nodes).map((e) => ({
      data: nodes[e].data.props,
    }));

    return !isEqual(normalizedInitialNodes, normalizedNodes);
  }, [initialNodes, nodes]);

  const blocker = useBlocker(onBlock);
  const unBlock = () => {
    if (blocker.state === 'blocked') blocker.proceed();
  };

  const onLeave = (): void => {
    unBlock();

    navigate(routes.articleStudio.create(), { replace: true });
  };

  const onSavePress = (isGoingBack?: boolean, afterSave?: () => void) => {
    if (!canSubmit()) return;

    selectNode(undefined);

    showArticleSavedToast(
      ct('Saving...'),
      <Spinner className={cx(iconClass, 'animate-spin')} />
    );

    if (article && articleId) {
      saveArticle()?.then(() => {
        if (afterSave) {
          afterSave();
        }
        if (
          typeof isGoingBack === 'boolean' &&
          isGoingBack &&
          !article.isTemplate
        ) {
          unBlock();
          navigate(routes.articleStudio.create(), { replace: true });
        }
        window.scrollTo(0, 0);
      });
    } else {
      saveNewArticle({ isPublish: false });
    }
  };

  const showArticleSavedToast = (
    text: string,
    icon: JSX.Element,
    containerId?: string
  ) => {
    toast.dismiss();

    toast(<SimpleToast text={text} Icon={icon} />, {
      position: 'bottom-center',
      autoClose: 8000,
      closeButton: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      containerId: containerId || 'Simple',
    });
  };

  const onLanguageLeave = () => {
    if (!currentLanguageCode) return;

    if (requiredLanguage) {
      dispatch(actions.setActiveLanguage(requiredLanguage));
      setRequiredLanguage(null);
    }

    if (currentLanguageCode === mainLanguage) {
      setBeforeLanguageModal(undefined);
      return;
    }

    dispatch(
      actions.updateCollaboratorLanguages({
        code: currentLanguageCode,
        id: article?.createdBy ?? user?.id ?? 0,
      })
    );

    dispatch(actions.removeLanguage(currentLanguageCode));
    dispatch(actions.setActiveLanguage(mainLanguage));

    setBeforeLanguageModal(undefined);
  };

  const onSuccessCancel = () => {
    if (!articleId) return;
    const currentLocation = window.location.origin;
    const articleActionLocation = routes.editorArticle.create(
      articleId,
      editorTypes.actions
    );
    navigator.clipboard.writeText(`${currentLocation}${articleActionLocation}`);
    toast(<SimpleToast text={t('Link copied')} />, {
      position: 'bottom-center',
      autoClose: 8000,
      closeButton: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      containerId: 'Simple',
    });
  };

  const onSuccessSubmit = () => {
    if (!articleId) return;
    navigate(routes.editorArticle.create(articleId, editorTypes.actions), {
      replace: true,
    });
    dispatch(modalActions.hideModal());
  };

  const showPublishSuccessModal = () => {
    const resultChannelName =
      articleState.result?.channel?.title[mainLanguage]?.title;
    const articleChannelName = article?.channel?.title[mainLanguage]?.title;

    const channelName = resultChannelName || articleChannelName;

    const getDescription = () => {
      if (recurringDate)
        return t('Your article is published and actions will recur {{date}}', {
          date: recurringDate.recurring.label,
        });

      if (channelName)
        return t('You can find it in {{channel}}', {
          channel: channelName,
        });

      return '';
    };

    dispatch(
      modalActions.showModal({
        modalType: ModalTypes.SUCCESS_MODAL,
        modalProps: {
          title: t('Your article is published.'),
          cancelLabel: t('Copy link'),
          submitLabel: t('View published article'),
          description: getDescription(),
        },
      })
    );
  };

  const onArticleCreated = (isPublish?: boolean) => {
    if (!articleState?.result?.id) return;

    setBeforeLeaveModalOpen(false);
    setBeforeLanguageModal(undefined);

    unBlock();

    if (isPublish) showPublishSuccessModal();

    navigate(
      routes.editorArticle.create(articleState.result.id.toString(), 'edit'),
      {
        state: {
          ...(!isPublish && { toastText: t('Article Saved') }),
        },
        replace: true,
      }
    );
  };

  const onError = () =>
    showArticleSavedToast(
      articleState?.errorMessage ?? t('Error. Failed to save article'),
      <Danger className={iconClass} />
    );

  const onArticleTemplate = () => {
    setInitialNodes({});
    showArticleSavedToast(
      t('Your article is template'),
      <TickCircle size={20} className={iconClass} />
    );
  };

  const onArticleUpdated = () => {
    setBeforeLeaveModalOpen(false);
    setBeforeLanguageModal(undefined);
    showArticleSavedToast(
      article?.isTemplate
        ? t('Template updated.')
        : recurringDate
          ? t(
              'Your article is updated & will repeat {{schedule}} starting from today',
              { schedule: recurringDate.recurring.label }
            )
          : t('Your article is updated'),
      <TickCircle size={20} className={iconClass} />,
      'Update'
    );
    setInitialNodes({});
  };

  const onArticleArchived = () => {
    setStepPublishModalOpen(false);
    setInitialNodes({});
    showArticleSavedToast(
      t('Your article is archived'),
      <TickCircle size={20} className={iconClass} />
    );
  };

  const onArticleUnarchived = () => {
    setStepPublishModalOpen(false);
    setInitialNodes({});
    showArticleSavedToast(
      t('Your article is unarchived'),
      <TickCircle size={20} className={iconClass} />
    );
  };

  const onArticlePublished = () => {
    setStepPublishModalOpen(false);
    setInitialNodes({});
    showPublishSuccessModal();
  };

  const onPublishClick = () => {
    const hasContainerNodes = Object.values(nodes)
      .filter((node) => node.data.displayName === BlockTypes.DraggableContainer)
      .every((node) => node.data.nodes.length > 0 || node.data.props.isHidden);

    if (!hasContainerNodes) return setMissingActionsModalOpen(true);

    setStepPublishModalOpen(true);
  };

  const onArticleScheduled = (isCreate?: boolean) => {
    setStepPublishModalOpen(false);
    setInitialNodes({});

    const DATE_FORMAT = 'DD MMM YYYY';

    dispatch(
      modalActions.showModal({
        modalType: ModalTypes.SUCCESS_MODAL,
        modalProps: {
          title: t('Article is scheduled for publishing!'),
          description: t('The article will'),
          headerClassName: 'font-bold',
          options: [
            {
              Icon: Calendar,
              text: archiveDate
                ? t('Publish on {{publishDate}} & archive {{archiveDate}}', {
                    publishDate: dayjs(publishDate).format(DATE_FORMAT),
                    archiveDate: dayjs(archiveDate).format(DATE_FORMAT),
                  })
                : t('Publish on {{publishDate}}', {
                    publishDate: dayjs(publishDate).format(DATE_FORMAT),
                  }),
            },
            ...(recurringDate
              ? [
                  {
                    Icon: Repeat,
                    text: t('Recur {{date}} from the publish date', {
                      date: recurringDate.recurring.label,
                    }),
                  },
                ]
              : []),
          ],
        },
      })
    );

    dispatch(actions.setArchiveDate(undefined));
    dispatch(actions.setPublishDate(undefined));

    if (isCreate && articleState?.result?.id) {
      navigate(
        routes.editorArticle.create(articleState.result.id.toString(), 'edit'),
        { replace: true }
      );
    }
  };

  const canSubmit = (): boolean => {
    if (!mandatoryFieldsMissing(nodes)) return true;

    showArticleSavedToast(
      t('Error. Mandatory fields missing.'),
      <Danger className={iconClass} />
    );

    return false;
  };

  const convertArticleToTemplate = () => {
    if (!canSubmit()) return;
    selectNode(undefined);
    templateArticle();
  };

  const onCloseMissingActions = () => {
    const draggableTextNodes = Object.values(nodes).filter(
      ({ data }) =>
        data.displayName === BlockTypes.DraggableContainer &&
        data.nodes.length <= 0
    );

    draggableTextNodes.forEach((node) => {
      const container = document.getElementById(node.id);
      if (container)
        container.classList.add('!border-[0.5px]', '!border-error');
    });

    setMissingActionsModalOpen(false);
  };

  const onLanguageSettingsClick = () => {
    if (!article?.id || !articleId) return setBeforeLanguageModal('settings');
    if (canShowAutoTranslateModal()) return setAutoTranslateModalOpen(true);

    dispatch(
      modalActions.showModal({
        modalType: ModalTypes.LANGUAGES_MODAL,
        modalProps: {
          article,
        } as LanguagesModalProps,
      })
    );
  };

  const onShareClick = () => {
    if (!article?.id || !articleOwner) return;
    if (canShowAutoTranslateModal()) return setAutoTranslateModalOpen(true);

    dispatch(
      modalActions.showModal({
        modalType: ModalTypes.FORWARD_MODAL,
        modalProps: { articleOwner, articleId: article.id },
      })
    );
  };

  const iconClass = 'h-5 w-5 mr-2.5 text-white';
  const coverImage = nodes['coverImage']?.data.props.imageSchema || null;
  const title = nodes['title']?.data.props.text || null;

  return (
    <>
      <TopBar
        showPublishSuccessModal={showPublishSuccessModal}
        onLanguageSettingsClick={onLanguageSettingsClick}
        onShareClick={onShareClick}
        isLoading={isCreatingArticle || isUpdatingArticle}
        onSaveClick={() =>
          canShowAutoTranslateModal()
            ? setAutoTranslateModalOpen(true)
            : onSavePress()
        }
        onPublishClick={() =>
          canShowAutoTranslateModal()
            ? setAutoTranslateModalOpen(true)
            : onPublishClick()
        }
        onTemplateClick={() =>
          canShowAutoTranslateModal()
            ? setAutoTranslateModalOpen(true)
            : setTemplateModalOpen(true)
        }
        beforeLanguageChange={
          hasUnsavedVariant()
            ? (code: string) => {
                setRequiredLanguage(code);
                setBeforeLanguageModal('language');
              }
            : undefined
        }
        onGenerateScormPackageClick={() =>
          setGenerateScormPackageModalOpen(true)
        }
      />
      <EditorCanvas mode={editorTypes.edit} hasClickOutside>
        <SideMenu />
      </EditorCanvas>
      <BlockGeneration />
      {beforeLeaveModalOpen && (
        <BeforeLeaveModal
          onLeaveClick={onLeave}
          onSaveClick={onSavePress}
          isSaving={isCreatingArticle || isUpdatingArticle}
          saveDisabled={coverImage === null || title === null}
          onClose={() => setBeforeLeaveModalOpen(false)}
        />
      )}
      {autoTranslateModalOpen && (
        <SaveAndTranslateModal
          draftLanguages={getDraftTranslationStatuses() || []}
          onSave={onSavePress}
          onClose={() => setAutoTranslateModalOpen(false)}
        />
      )}
      {missingActionsModalOpen && (
        <MissingActionsModal
          nodes={nodes}
          onClose={onCloseMissingActions}
          onSubmit={() => {
            setMissingActionsModalOpen(false);
            setStepPublishModalOpen(true);
          }}
        />
      )}
      {beforeLanguageModal && (
        <SaveBeforeLanguageChangeModal
          type={beforeLanguageModal}
          onLeaveClick={onLanguageLeave}
          onSaveClick={onSavePress}
          isSaving={isCreatingArticle || isUpdatingArticle}
          saveDisabled={coverImage === null || title === null}
          onClose={() => setBeforeLanguageModal(undefined)}
        />
      )}
      {templateModalOpen && (
        <ConvertToTemplateModal
          isSubmitSuccess={articleState.state === 'template'}
          onSubmit={convertArticleToTemplate}
          disabled={isUpdatingArticle}
          onClose={() => setTemplateModalOpen(false)}
        />
      )}
      {overwriteModalOpen && (
        <OverwriteModal
          onClose={() => setOverwriteModalOpen(false)}
          overwrite={() => articleState?.retryFn?.({ overwrite: true })}
        />
      )}
      {stepPublishModalOpen && (
        <PublishModalStepper
          onClose={() => {
            setStepPublishModalOpen(false);
            dispatch(actions.setPublishDate(article?.publishAt ?? undefined));
            dispatch(actions.setArchiveDate(article?.archiveAt ?? undefined));
          }}
          publish={() => {
            if (!canSubmit()) return;
            selectNode(undefined);
            publishArticle();
            setStepPublishModalOpen(false);
          }}
          schedule={() => {
            if (!canSubmit()) return;
            selectNode(undefined);
            scheduleArticle();
            setStepPublishModalOpen(false);
          }}
          archive={() => {
            if (!canSubmit()) return;
            selectNode(undefined);
            archiveArticle();
          }}
          unArchive={() => {
            if (!canSubmit()) return;
            selectNode(undefined);
            unarchiveArticle();
          }}
          update={() => {
            if (!canSubmit()) return;
            selectNode(undefined);
            saveArticle();
            setStepPublishModalOpen(false);
          }}
          cancelSchedule={() => cancelArticleSchedule()}
          article={article}
        />
      )}
      {generateScormPackageModalOpen && (
        <GenerateScormPackageModal
          onClose={() => setGenerateScormPackageModalOpen(false)}
          onFinish={() => {
            setGenerateScormPackageModalOpen(false);
            setGenerateScormPackageSuccessModalOpen(true);
          }}
        />
      )}
      {generateScormPackageSuccessModalOpen && (
        <GenerateScormPackageSuccessModal
          onClose={() => setGenerateScormPackageSuccessModalOpen(false)}
        />
      )}
      <ToastContainer toastId="DeletedBlockPopup" />
      <ToastContainer toastId="Languages" />
      <ToastContainer toastId="Simple" />
      <ToastContainer toastId="Update" />
      <ModalHandler
        onCancel={onSuccessCancel}
        onSubmit={onSuccessSubmit}
        onSaveClick={onSavePress}
      />
    </>
  );
};

export default Editor;
