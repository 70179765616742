import ReactTooltip from 'react-tooltip';

interface Props {
  id: string;
  tooltipText: string;
  onClick: () => void;
  children: React.ReactNode;
}

const ActionButton = ({ children, id, onClick, tooltipText }: Props) => (
  <>
    <button
      data-for={id}
      data-tip={tooltipText}
      className="p-1 hover:text-focus hover:bg-hover-blue hover:rounded-sm"
      onClick={onClick}
    >
      {children}
    </button>
    <ReactTooltip
      place="top"
      effect="solid"
      className="react-tooltip"
      id={id}
    />
  </>
);

export default ActionButton;
