import styled from '@emotion/styled';
import PrivacyPolicyLink from 'app/components/PrivacyPolicyLink';
import { useSelector } from 'app/hooks';
import useLoginMethod from 'app/hooks/useLoginMethod';
import { useComponentsTranslation } from 'app/internationalization/hooks';
import { outsideRoutes, routes } from 'app/router';
import { selectors } from 'app/store/auth';
import React from 'react';
import { Link } from 'react-router-dom';

const UserMenuContainer = styled.div`
  transform: translate(-80%, 100%);
  width: 248px;
`;

interface UserMenuProps {
  name: string;
  logout: () => void;
}

const UserMenu: React.FC<UserMenuProps> = ({ name, logout }) => {
  const { t } = useComponentsTranslation();
  const selectedTenant = useSelector(selectors.getSelectedTenant);

  const { isEasyAccessLogin } = useLoginMethod();

  return (
    <UserMenuContainer className="absolute z-10 left-0 -bottom-1 bg-light rounded-lg shadow-atobi overflow-hidden">
      <div className="p-4 border-b border-gray-light">
        <p className="font-bold text-sm line-clamp-2 break-all">{name}</p>
        <p className="text-grayscale-secondary text-xs line-clamp-2 break-all">
          {selectedTenant?.title}
        </p>
      </div>
      {!isEasyAccessLogin && (
        <div className="flex flex-col border-b border-gray-light ">
          <Link
            className="hover:bg-hover-blue hover:text-focus px-4 py-2"
            to={routes.profile.create('view')}
          >
            {t('View Profile')}
          </Link>
          <Link
            className="hover:bg-hover-blue hover:text-focus px-4 py-2"
            to={routes.profile.create('edit')}
          >
            {t('Edit Profile')}
          </Link>
        </div>
      )}
      <div className="flex flex-col ">
        <a
          target="_blank"
          className="hover:bg-hover-blue hover:text-focus px-4 py-2"
          href={outsideRoutes.atobiHelp.create()}
          rel="noreferrer"
        >
          {t('Help center')}
        </a>
        <PrivacyPolicyLink
          className="hover:bg-hover-blue hover:text-focus px-4 py-2"
          text={`${t('View Privacy Policy')}`}
        />
        <button
          type="button"
          onClick={logout}
          className="text-left text-error hover:bg-error-light px-4 py-2"
        >
          {t('Sign Out')}
        </button>
      </div>
    </UserMenuContainer>
  );
};

export default UserMenu;
