import { useQueries } from 'react-query';

import { resources } from '..';
import queryKeys from '../queryKeys';

const useGetAudiencesListQuery = ({
  ids,
  enabled = true,
}: {
  ids: number[];
  enabled?: boolean;
}) => {
  const queries = useQueries(
    ids.map((id) => ({
      queryKey: queryKeys.audience(id),
      queryFn: () => resources.getAudience(id),
      enabled,
    }))
  );

  const data = queries.map((query) => query.data?.data?.data);
  const isLoading = queries.some((query) => query.isLoading);
  const isFetching = queries.some((query) => query.isFetching);
  const error = queries.find((query) => query.error);

  const refetch = () => {
    queries.forEach((query) => query.refetch());
  };

  return {
    data,
    isLoading,
    isFetching,
    error,
    refetch,
  };
};

export default useGetAudiencesListQuery;
