import { LoginMethods } from 'app/api/auth/constants';
import { selectors as authSelectors } from 'app/store/auth';
import { jwtDecode } from 'jwt-decode';
import { useSelector } from 'react-redux';

const useLoginMethod = () => {
  const authToken = useSelector(authSelectors.getToken);

  if (!authToken) {
    return { loginMethod: null, isEasyAccessLogin: false };
  }

  const { login_method }: { login_method: LoginMethods } = jwtDecode(authToken);

  return {
    loginMethod: login_method,
    isEasyAccessLogin: login_method === LoginMethods.EasyAccess,
  };
};

export default useLoginMethod;
