import useCommentTranslation from 'app/internationalization/hooks/useCommentTranslation';
import { ArrowUp2 as ArrowUp, ArrowDown2 as ArrowDown } from 'iconsax-react';
import { useEffect } from 'react';
import { ActionAnswerPageMeta } from 'submodules/common-ui/generated/api/gcs';

import { NestedComment, useCommentsContext } from '../context';
import useFetchComments from '../hooks/hooks';

import CommentItem from './CommentItem';
import CommentSkeleton from './CommentSkeleton';

interface Props {
  mainComment: NestedComment;
  replies: NestedComment[];
  fetch: () => void;
  isLoading: boolean;
  isFetching: boolean;
  meta?: ActionAnswerPageMeta;
}

const CommentReplies = ({
  mainComment,
  replies,
  fetch,
  isFetching,
  isLoading,
  meta,
}: Props) => {
  const { t } = useCommentTranslation();
  const { commentRepliesId, setCommentRepliesId } = useCommentsContext();

  const reply = mainComment.children;
  const isReply = reply && reply.length > 0;
  const isRepliesVisible = commentRepliesId === mainComment.id;
  const replyNumber = isReply ? 1 : replies.length;
  const shownReplies = isReply ? reply : replies;

  const { hasMorePages, threshold } = useFetchComments(
    fetch,
    isLoading,
    isFetching,
    meta
  );

  useEffect(() => {
    if (isReply) setCommentRepliesId(mainComment.id);
  }, [isReply, mainComment.id, setCommentRepliesId]);

  const toggleReplies = () =>
    setCommentRepliesId(isRepliesVisible ? undefined : mainComment.id);

  const expandText = isRepliesVisible
    ? t('Hide replies')
    : `${t('View')} ${replyNumber} ${
        replyNumber <= 1 ? t('reply') : t('replies')
      }`;

  return (
    <div className="flex flex-col mt-4 pl-10">
      {isRepliesVisible && (
        <>
          {shownReplies.map((comment) => (
            <CommentItem key={comment.id} comment={comment} />
          ))}
          {hasMorePages &&
            (!isFetching ? <div ref={threshold}></div> : <CommentSkeleton />)}
        </>
      )}
      <button
        className="w-fit flex items-center text-focus"
        onClick={toggleReplies}
      >
        <span className="text-sm mr-1">{expandText}</span>
        {isRepliesVisible ? (
          <ArrowUp className="w-5 h-5" />
        ) : (
          <ArrowDown className="w-5 h-5" />
        )}
      </button>
    </div>
  );
};

export default CommentReplies;
