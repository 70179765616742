import {
  GetCategory,
  ScormTaskBlock,
  ScormTaskBlockBVariants,
} from 'submodules/common-ui/generated/api/gcs/api';

import { Props } from './types';

const mapScormBlock = ({
  article,
  block,
  index,
  selectedLanguage,
  translationStatuses,
  schedule,
  blockId,
}: Props): ScormTaskBlock | null => {
  let variants: ScormTaskBlockBVariants = {};

  if (blockId) {
    article.blocks.forEach((b) => {
      if (b.id === blockId) {
        variants = { ...b.variants } as ScormTaskBlockBVariants;
      }
    });
  }

  Object.keys(variants).forEach((language) => {
    if (blockId)
      variants[language] = {
        ...variants[language],
        ...{
          translationStatus:
            translationStatuses[blockId][language] ??
            variants[language].translationStatus,
        },
      };
  });

  return {
    id: blockId,
    version: 1,
    parentId: null,
    position: index,
    type: block.data.props.type,
    categories:
      block.data.props.categories?.map(
        (category: GetCategory) => category.id
      ) ?? [],
    deadline: block.data.props.deadline,
    required: block.data.props.required,
    public: block.data.props.public,
    audiences: block.data.props.audiences,
    mandatory: block.data.props.mandatory,
    schedule,
    variants: {
      ...variants,
      [selectedLanguage]: {
        description: block.data.props.description,
        translationStatus: 'draft',
        package: block.data.props.package,
        image: block.data.props.image,
        courseStatus: block.data.props.courseStatus,
        title: block.data.props.title ?? '',
      },
    } as ScormTaskBlockBVariants,
  } as ScormTaskBlock;
};

export default mapScormBlock;
