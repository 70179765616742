import { selectors } from 'app/store/editor';
import { useSelector } from 'react-redux';

import AnswerHeader from '../AnswerHeader';
import { useAnswersContext } from '../context';

import BackButton from './BackButton';

const Header = () => {
  const language = useSelector(selectors.getActiveLanguage);

  const { selectedTaskId, article } = useAnswersContext();

  if (!article) return null;

  const block = article.blocks.filter((b) => b.id === selectedTaskId)?.[0];

  if (!block) return null;

  const renderAnswerHeader = () => {
    if (
      block.type !== 'media_task' &&
      block.type !== 'open_question_task' &&
      block.type !== 'multi_choice' &&
      block.type !== 'simple_task' &&
      block.type !== 'yes_no_task'
    ) {
      return null;
    }

    return (
      <>
        <AnswerHeader
          deadline={block.deadline}
          blockType={block.type}
          isPublic={block.public}
          mandatory={block.mandatory ?? false}
          required={block.required}
          {...(block.type === 'multi_choice' && {
            renderType: block.renderType,
          })}
        />
        <div className="flex flex-col gap-1 p-2 rounded-xl border border-hover-blue w-full">
          <span className="text-sm font-bold">
            {block.variants?.[language]?.title ?? ''}
          </span>
          <span className="text-xs text-grayscale-secondary">
            {block.variants?.[language]?.description ?? ''}
          </span>
        </div>
      </>
    );
  };

  return (
    <div className="flex items-start justify-start gap-3 w-full">
      <BackButton />
      {renderAnswerHeader()}
    </div>
  );
};

export default Header;
