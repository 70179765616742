/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NAME } from './constants';
import { State } from './types';

const initialState: State = {
  originUrl: undefined,
  isEasyAccessToken: false,
  isTokenRefreshing: null,
  isLoggingOut: null,
};

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    originUrlChanged: (
      state,
      { payload }: PayloadAction<string | undefined>
    ) => {
      state.originUrl = payload;
    },
    easyAccessToken: (state, { payload }: PayloadAction<boolean>) => {
      state.isEasyAccessToken = payload;
    },
    isTokenRefreshingRetrieved: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isTokenRefreshing = payload;
    },
    isLoggingOutRetrieved: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoggingOut = payload;
    },
    stateReset: () => initialState,
  },
});

export default slice;
