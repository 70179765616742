import { request } from 'app/api/gcsRequest';
import config from 'app/config';
import {
  CommentApi,
  UpdatedComment,
} from 'submodules/common-ui/generated/api/gcs';

const updateComment = ({
  commentId,
  updatedComment,
}: {
  commentId: number;
  updatedComment: UpdatedComment;
}) =>
  new CommentApi(undefined, config.env.gcsApiUrl, request()).updateComment(
    commentId,
    updatedComment
  );

export default updateComment;
