import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import config from 'app/config';
import {
  ReactElement,
  ReactNode,
  cloneElement,
  isValidElement,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';

interface Props {
  children: ReactNode;
  content?: string | ReactElement | null;
  show?: boolean;
  fixedWidth?: boolean;
  centerText?: boolean;
  delay?: number;
}

const Tooltip = ({
  children,
  centerText,
  content,
  fixedWidth,
  show,
  delay,
}: Props) => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  const handleMouseEnter = () => {
    if (!tooltipRef.current || !anchorRef.current) return;

    if (timer) clearTimeout(timer);

    const bounds = anchorRef.current.getBoundingClientRect();

    tooltipRef.current.style.visibility = 'visible';
    tooltipRef.current.style.left = `${bounds.left + bounds.width / 2}px`;
    tooltipRef.current.style.top = `${bounds.top + bounds.height}px`;
    tooltipRef.current.style.transform = 'translateX(-50%)';

    if (fixedWidth) {
      tooltipRef.current.style.width = anchorRef.current.offsetWidth + 'px';
    }
  };

  const handleMouseLeave = () => {
    if (!tooltipRef.current) return;

    if (delay) {
      return setTimer(
        setTimeout(() => {
          if (!tooltipRef.current) return;
          tooltipRef.current.style.visibility = 'hidden';
        }, delay)
      );
    }

    tooltipRef.current.style.visibility = 'hidden';
  };

  const anchorProps = {
    onMouseOver: handleMouseEnter,
    ref: anchorRef,
  };

  const anchor = isValidElement(children) ? (
    cloneElement(children, anchorProps)
  ) : (
    <span {...anchorProps}>{children}</span>
  );

  return (
    <div onMouseLeave={handleMouseLeave}>
      {anchor}
      {show &&
        createPortal(
          <div
            style={{
              visibility: 'hidden',
              display: 'inline-block',
              position: 'fixed',
              zIndex: 1000,
              marginTop: '5px',
            }}
            ref={tooltipRef}
            onMouseEnter={() => {
              if (!timer) return;

              clearTimeout(timer);
            }}
          >
            <Container
              ref={contentRef}
              className={cx(
                '!rounded !opacity-100 bg-gray-extra-dark text-white px-4 py-2 w-fit text-sm',
                {
                  'whitespace-nowrap inline-block': !fixedWidth,
                  'text-center': centerText,
                }
              )}
            >
              {content}
            </Container>
          </div>,
          document.body
        )}
    </div>
  );
};

export default Tooltip;

const Container = styled.div`
  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent ${config.colors['gray-extra-dark']}
      transparent;
  }
`;
