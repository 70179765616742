import { useQuery } from 'react-query';

import { resources, queryKeys } from '../index';

const useGetCommentQuery = ({
  commentId,
  enabled,
}: {
  commentId: number;
  enabled: boolean;
}) => {
  const { data, error } = useQuery(
    queryKeys.comment(commentId),
    () => resources.getComment(commentId),
    { enabled }
  );

  return {
    data: data?.data,
    error,
  };
};

export default useGetCommentQuery;
