import { useQuery } from 'react-query';

import { resources, queryKeys } from '../index';

const useGetChannelQuery = (
  channelId: number,
  enabled?: boolean,
  live?: '1'
) => {
  const { data, isLoading, error, isFetching, isPreviousData, refetch } =
    useQuery(
      queryKeys.channel(channelId, live),
      () => resources.getChannel(channelId, live),
      { enabled }
    );

  return {
    data: data?.data,
    isFetching,
    isLoading,
    isPreviousData,
    refetch,
    error,
  };
};

export default useGetChannelQuery;
