import narrowUnknown from 'app/utils/narrowUnknown';
import last from 'lodash/last';
import { useInfiniteQuery } from 'react-query';
import { SortOrderEnum } from 'submodules/common-ui/generated/api/gcs';

import { resources, queryKeys } from '../index';

const useGetActionInstance = ({
  actionId,
  start,
  startFrom,
  startTo,
  perPage,
  enabled = true,
}: {
  actionId: number;
  start?: SortOrderEnum;
  startFrom?: string;
  startTo?: string;
  perPage?: number;
  enabled?: boolean;
}) => {
  const {
    data,
    isLoading,
    refetch,
    fetchNextPage,
    hasNextPage,
    error: apiError,
  } = useInfiniteQuery(
    queryKeys.actionInstance(actionId, start, startFrom, startTo),
    ({ pageParam }) =>
      resources.getActionInstance({
        actionId,
        page: pageParam || 0,
        start,
        startFrom,
        startTo,
        perPage,
      }),
    {
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => lastPage.data.meta.currentPage + 1,
      enabled,
    }
  );

  return {
    actionInstances: data?.pages.flatMap((x) => x.data.data) || [],
    actionInstancesMeta: last(data?.pages)?.data.meta,
    isLoading,
    error: narrowUnknown(apiError),
    apiError,
    hasNextPage,
    refetch,
    fetchNextPage,
  };
};

export { useGetActionInstance };
export default useGetActionInstance;
