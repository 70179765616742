import { css, cx } from '@emotion/css';
import config from 'app/config';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { EmojiEnum } from 'submodules/common-ui/generated/api/gcs/api';

import Reaction from './Reaction';

interface ReactionSheetProps {
  className?: string;
  selectedEmojis?: EmojiEnum[];
  onClick(emoji: EmojiEnum): void;
}

const Emojis = ({
  className,
  selectedEmojis = [],
  onClick,
}: ReactionSheetProps) => {
  const { t } = useArticlesTranslation();

  return (
    <div
      className={cx(
        'grid gap-1.5 w-full p-3 text-grayscale-secondary bg-white border border-focus-background rounded-t-xl md:rounded-xl shadow-atobi',
        css('grid-template-columns: auto auto auto auto;'),
        className
      )}
    >
      {Object.values(EmojiEnum)
        .sort()
        .map((emoji, index) => (
          <div
            key={index}
            className={cx(
              'flex justify-center rounded-[8px] min-w-18 h-18 px-1 py-2 hover:bg-focus-background cursor-pointer',
              css(`border-color: ${config.colors.focus}52;`),
              {
                'bg-focus-background border': selectedEmojis.includes(emoji),
              }
            )}
          >
            <Reaction
              className="flex flex-col items-center gap-2 text-xs lowercase"
              emoji={emoji}
              text={t(emoji)}
              onClick={() => onClick(emoji)}
            />
          </div>
        ))}
    </div>
  );
};

export default Emojis;
