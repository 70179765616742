import { useArticlesTranslation } from 'app/internationalization/hooks';

import { useAnswersContext } from '../context';

const AnountOfAnswers = () => {
  const { t } = useArticlesTranslation();
  const { actionAnswers } = useAnswersContext();
  return (
    <p className="text-xs text-gray-light-blue">
      {t('Showing {{totalResults}} results', {
        totalResults: actionAnswers?.length,
      })}
    </p>
  );
};

export default AnountOfAnswers;
