import { useEditor, useNode } from '@craftjs/core';
import { cx } from '@emotion/css';
import useGetActionAnswerSummary from 'app/api/articles/hooks/useGetActionAnswerSummary';
import useGetActionInstance from 'app/api/articles/hooks/useGetActionInstance';
import { useAuthenticatedUser } from 'app/api/auth/hooks';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { useAnswersContext } from 'app/pages/Editor/components/Answers/context';
import BlockTypes from 'app/pages/Editor/helpers/constants';
import useUserQuery from 'app/pages/profiles/PublicProfile/useUserQuery';
import { useUpcomingActionsContext } from 'app/pages/UpcomingActions/context';
import removeTimezone from 'app/utils/removeTimezone';
import dayjs from 'dayjs';
import {
  Shop,
  Profile2User,
  Calendar,
  Repeat,
  InfoCircle,
  Clock,
} from 'iconsax-react';
import { ComponentProps } from 'react';
import ReactTooltip from 'react-tooltip';
import CheckLineIcon from 'remixicon-react/CheckLineIcon';

import SimpleTask, { SimpleTaskProps } from '../../../../SimpleTask';
import { useCurrentSelectedInstance } from '../../context';

interface AnswerActionScoreProps {
  totalScore: number | null;
  selectedProps: SimpleTaskProps;
  showQuizScore: boolean;
  showProgressScore?: boolean;
  blockId?: number;
}

interface EditorAnswerActionScoreProps {
  totalScore: number | null;
  showProgressScore?: boolean;
}

const AnswerActionScore = ({
  selectedProps,
  blockId,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}: AnswerActionScoreProps) => {
  const { t } = useArticlesTranslation();
  const { data: user } = useAuthenticatedUser();
  const { required, deadline, schedule } = selectedProps;
  const {
    currentSelectedInstance,
    isActionCompleted,
    block,
    answeredByColleague,
    colleagueId,
    currentInstanceId,
  } = useCurrentSelectedInstance();

  const { currentAction } = useUpcomingActionsContext();

  const { selectedTenant } = useAnswersContext();

  const startFrom = dayjs().add(1, 'day').format('YYYY-MM-DD');

  const actionId = blockId ?? block?.id;

  const { data: colleagueProfile } = useUserQuery({
    id: colleagueId || 0,
    enabled: colleagueId !== undefined,
  });

  const { actionInstances } = useGetActionInstance({
    actionId: actionId ?? -1,
    startFrom,
    start: 'asc',
    perPage: 1,
    enabled: actionId !== undefined,
  });

  const { summary } = useGetActionAnswerSummary({
    actionId: actionId ?? -1,
    originalArticleId: selectedTenant?.originalArticleId,
    actionInstanceId: currentAction?.instanceId || currentInstanceId,
    enabled: actionId !== undefined,
  });

  const nextInstance = actionInstances?.[0];

  const timeToNextInstance = nextInstance
    ? dayjs(nextInstance.start).fromNow(true)
    : null;

  const isRecurring = schedule && schedule?.frequency !== 'once';

  const DeadlineIcon =
    currentSelectedInstance && isRecurring ? Repeat : Calendar;

  const effectiveDeadline =
    currentSelectedInstance && isRecurring
      ? currentSelectedInstance.end
      : deadline;

  const isOverdue =
    effectiveDeadline &&
    dayjs(removeTimezone(effectiveDeadline)).isBefore(dayjs(), 'day');

  const deadlineLabel =
    currentSelectedInstance && isRecurring
      ? `${t('Repeats')} ${schedule?.frequency}`
      : t('Deadline');

  const formattedDeadline = effectiveDeadline
    ? isOverdue
      ? `${t('Deadline was')} ${dayjs(removeTimezone(effectiveDeadline)).format(
          'DD.MM.YYYY'
        )}`
      : dayjs(removeTimezone(effectiveDeadline)).format('DD.MM.YYYY [at] HH:mm')
    : currentSelectedInstance
      ? t('No deadline')
      : nextInstance
        ? `${t('Available in')} ${timeToNextInstance}`
        : t('No deadline');

  return (
    <div className="flex w-full p-3 flex-col items-start gap-3 bg-white rounded-[20px] border border-focus-background mt-[30px] mb-[40px]">
      <div className="flex items-start gap-2.5 w-full">
        <div className="w-full flex flex-col p-3 gap-2.5 rounded-[14px] border border-focus-background">
          <div className="flex justify-between items-center">
            <span className="text-xs font-bold lg:text-sm text-grayscale-primary">
              {t('Assignee')}
            </span>
            <div>
              <Profile2User size={16} className="text-gray-silver" />
            </div>
          </div>

          {required === 1 ? (
            <div className="flex items-center gap-2">
              <span className="justify-center items-center flex rounded-lg border-focus-background bg-hover-blue w-6 h-6">
                <Shop size={16} className="text-focus" />
              </span>

              <span className="flex items-center gap-1 text-grayscale-secondary text-xs">
                {t('Your store')}
                <div>
                  <InfoCircle
                    size={12}
                    data-for="react"
                    data-tip={t(
                      'At least one answer from the store is required. However, all assignees are still encouraged to contribute their answers.'
                    )}
                  />
                  <ReactTooltip
                    place="bottom"
                    class="react-tooltip w-[300px]"
                    id="react"
                  />
                </div>
              </span>
            </div>
          ) : (
            <div className="flex items-center gap-2">
              <img
                className="w-6 h-6 rounded"
                src={user?.avatars?.small}
                alt="avatar"
              />
              <span className="text-xs text-grayscale-secondary">
                {required === 1 ? 'Your store' : 'You'}
              </span>
            </div>
          )}
        </div>

        <div className="w-full flex flex-col p-3 gap-2.5 rounded-[14px] border border-focus-background h-[80px]">
          <div className="flex justify-between items-center">
            <span className="text-xs font-bold lg:text-sm text-grayscale-primary">
              {deadlineLabel}
            </span>
            <div>
              <DeadlineIcon size={16} className="text-gray-silver" />
            </div>
          </div>
          <div className="flex items-center gap-2">
            <span
              className={cx('text-xs', {
                'text-error': Boolean(isOverdue),
                'text-grayscale-secondary': Boolean(!isOverdue),
              })}
            >
              {formattedDeadline}
            </span>
          </div>
        </div>
      </div>
      {isOverdue && !isActionCompleted && !answeredByColleague && (
        <div className="flex h-[40px] w-full p-2.5 items-center gap-1.5 rounded-lg bg-error-light">
          <span className="flex gap-2.5 items-center justify-center rounded-full w-4 h-4 bg-error">
            <Calendar size={10} color="white" />
          </span>
          <span className="flex items-center gap-1.5 text-error text-xs font-bold font-Lato">
            {t('Overdue')}
          </span>
        </div>
      )}

      {required === -1 &&
        !isActionCompleted &&
        summary?.completionPercentage !== undefined &&
        summary?.completionPercentage >= 20 &&
        !isOverdue && (
          <div className="flex h-[40px] w-full p-2.5 items-center gap-1.5 rounded-lg bg-warning-white">
            <span className="flex gap-2.5 items-center justify-center rounded-full w-4 h-4 bg-warning">
              <Clock size={10} color="white" />
            </span>
            <span className="flex items-center gap-1.5 text-error text-xs font-bold font-Lato">
              {t(
                '{{percentage}}% of your colleagues have also completed this action',
                { percentage: summary.completionPercentage }
              )}
            </span>
          </div>
        )}

      {(isActionCompleted || answeredByColleague) && (
        <div className="flex h-[40px] w-full p-2.5 items-center justify-between gap-1.5 rounded-lg bg-success-background">
          <div className="flex items-center gap-1.5">
            <span className="flex gap-2.5 items-center justify-center rounded-full w-4 h-4 bg-success">
              <CheckLineIcon size={20} color="white" />
            </span>
            <span className="flex items-center gap-1.5 text-grayscale-primary text-xs font-bold font-Lato">
              {answeredByColleague && colleagueProfile
                ? t('Completed by {{firstName}}', {
                    firstName: colleagueProfile.firstName,
                  })
                : t('Completed')}
            </span>
          </div>
          <div className="flex w-5 h-5 justify-center items-center">
            {answeredByColleague && colleagueProfile ? (
              <img
                className="w-5 h-5 rounded"
                src={colleagueProfile.avatars?.small}
                alt="avatar"
                data-tip={t('Completed by {{firstName}}', {
                  firstName: colleagueProfile.firstName,
                })}
                data-for="completedBy"
              />
            ) : (
              <img
                className="w-5 h-5 rounded"
                src={user?.avatars?.small}
                alt="avatar"
                data-tip={t('Completed by {{firstName}}/You', {
                  firstName: user?.firstName,
                })}
                data-for="completedBy"
              />
            )}
            <ReactTooltip
              place="top"
              effect="solid"
              class="react-tooltip"
              id="completedBy"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export const EditorAnswerActionScore = ({
  totalScore,
  showProgressScore = true,
}: EditorAnswerActionScoreProps) => {
  const { nodes } = useEditor((state) => ({ nodes: state.nodes }));

  const { selectedProps } = useNode((node) => ({
    selectedProps: node.data.props as ComponentProps<typeof SimpleTask>,
  }));

  const showQuizScore = Object.keys(nodes).some(
    (nodeId) => nodes[nodeId].data.displayName === BlockTypes.QuizBlock
  );

  return (
    <AnswerActionScore
      totalScore={totalScore}
      showQuizScore={showQuizScore}
      selectedProps={selectedProps}
      showProgressScore={showProgressScore}
    />
  );
};

export default AnswerActionScore;
