import { useMissionQuery } from 'app/api/missions/hooks';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import DefaultBreadcrumb from './DefaultBreadcrumb';

const MissionActivityBreadcrumb = () => {
  const { id: missionId } = useParams<{ id: string }>();
  const { data } = useMissionQuery({
    id: Number(missionId),
    enabled: !Number.isNaN(Number(missionId)),
  });

  const currentSeries = useMemo(() => {
    if (!data) return undefined;
    return data.series.find(({ activities }) =>
      activities.find(({ id }) => id === Number(missionId))
    );
  }, [data, missionId]);

  return (
    <DefaultBreadcrumb to={window.location.href} title={currentSeries?.name} />
  );
};

export default MissionActivityBreadcrumb;
