import { ComponentsKey } from '../types';

import useBaseTranslation from './useBaseTranslation';

const useComponentsTranslation = () =>
  useBaseTranslation<ComponentsKey>(undefined, {
    keyPrefix: 'components',
  });

export default useComponentsTranslation;
