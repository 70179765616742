/* eslint-disable import/prefer-default-export */
import { RootState } from '../types';

import { NAME } from './constants';

export const getArticleStudioTab = (state: RootState) =>
  state[NAME].articleStudioTab;

export const getArticleFilter = (state: RootState) => state[NAME].articleFilter;

export const getTemplateFilter = (state: RootState) =>
  state[NAME].templateFilter;

export const getAudiencesTab = (state: RootState) => state[NAME].audiencesTab;

export const getMembersTab = (state: RootState) => state[NAME].membersTab;
