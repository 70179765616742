import { Location } from 'app/api/auth/types';
import useGetLocationsQuery from 'app/api/locations/hooks/useLocationsQuery';
import { FullLocationTree } from 'app/api/locations/types';
import { useEffect, useState } from 'react';

interface UseLocationsProps {
  locations?: Location[];
}

const useLocations = ({ locations }: UseLocationsProps) => {
  const { data, error, isLoading } = useGetLocationsQuery();
  const [locationsTree, setLocationsTree] = useState<Array<FullLocationTree>>();

  useEffect(() => {
    if (!locations && !data) return;

    const audienceLocationToFullLocation = data?.filter((location) =>
      locations?.some((l) => l.id === location.id)
    );

    const currentLocations =
      audienceLocationToFullLocation &&
      audienceLocationToFullLocation?.length > 0
        ? audienceLocationToFullLocation
        : data;

    const idToLocationMap: Record<number, FullLocationTree> = {};

    currentLocations?.forEach(
      (location) =>
        (idToLocationMap[location.id] = { ...location, children: [] })
    );

    const dataTree: Array<FullLocationTree> = [];
    currentLocations?.forEach((location) => {
      if (location.parentId && !idToLocationMap[location.parentId]) {
        idToLocationMap[location.id].parentId = null;
      }

      if (location.parentId && idToLocationMap[location.parentId]) {
        idToLocationMap[location.parentId].children.push(
          idToLocationMap[location.id]
        );
        return;
      }

      dataTree.push(idToLocationMap[location.id]);
    });

    setLocationsTree(dataTree);
  }, [locations, data]);

  return {
    locationsTree,
    data,
    error,
    isLoading,
  };
};

export default useLocations;
