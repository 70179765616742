import { cx } from '@emotion/css';
import useGetActionAnswer from 'app/api/articles/hooks/useGetActionAnswer';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { Magicpen } from 'iconsax-react';
import { FC } from 'react';
import {
  DbOpenQuestionTaskAnswer,
  OpenQuestionTaskBlockV1,
} from 'submodules/common-ui/generated/api/gcs';

import { MINIMUM_REQUIRED_ANSWERS } from '../SmartSummary/constants';

import AnswerContainer from './AnswerContainer';
import { useAnswersContext } from './context';
import OpenQuestionTask from './OpenQuestionTask';
import EmptyOpenQuestionTasks from './OpenQuestionTask/EmptyOpenQuestionTasks';
import ErrorOpenQuestionTasks from './OpenQuestionTask/ErrorOpenQuestionTasks';
import LoadingOpenQuestionTasks from './OpenQuestionTask/LoadingOpenQuestionTasks';

interface Props {
  block: OpenQuestionTaskBlockV1;
}

const OpenQuestionTaskAnswers: FC<Props> = ({ block }) => {
  const { setSelectedTaskId, selectedTenant } = useAnswersContext();
  const { t } = useArticlesTranslation();

  const { actionAnswers, isLoading, error, actionAnswersMeta } =
    useGetActionAnswer({
      id: block.id,
      originalArticleId: selectedTenant?.originalArticleId,
    });

  const showMoreBtnStyling =
    'rounded-xl bg-hover-blue text-sm p-3 text-focus enabled:hover:bg-focus-background basis-1/2';

  const isSummarizeEnabled =
    actionAnswersMeta && actionAnswersMeta?.total >= MINIMUM_REQUIRED_ANSWERS;

  const openSummarize = () => {
    const el = document.getElementById('editorCanvas');
    el?.scrollIntoView({ behavior: 'smooth' });
    setSelectedTaskId(block.id);
  };

  return (
    <AnswerContainer block={block}>
      <div className="flex flex-col gap-2 relative">
        {!isLoading &&
          actionAnswers?.map(({ id, answer, userId, createdAt }) => (
            <OpenQuestionTask
              key={id}
              answer={answer as DbOpenQuestionTaskAnswer}
              userId={userId}
              createdAt={createdAt}
            />
          ))}

        {isLoading && (
          <>
            <LoadingOpenQuestionTasks />
            <LoadingOpenQuestionTasks />
          </>
        )}

        {error && <ErrorOpenQuestionTasks />}

        {actionAnswers?.length === 0 && <EmptyOpenQuestionTasks />}
      </div>
      <div className="grid grid-cols-2 gap-1">
        {!isLoading && (actionAnswers?.length ?? 0) > 0 && !error && (
          <button
            onClick={() => setSelectedTaskId(block.id)}
            className={showMoreBtnStyling}
          >
            {t('Show More')}
          </button>
        )}
        <button
          type="button"
          className={cx(
            showMoreBtnStyling,
            'text-sm flex items-center ml-2 justify-center gap-1.5'
          )}
          disabled={!isSummarizeEnabled}
          onClick={() => openSummarize()}
        >
          <Magicpen
            size={24}
            className={cx({
              'text-[#7C48D0]': isSummarizeEnabled,
              'text-grayscale-secondary': !isSummarizeEnabled,
            })}
          />
          <span
            className={cx({
              'bg-gradient-to-b from-[#B549D0] to-[#7C48D0] text-transparent bg-clip-text pl-1.5':
                isSummarizeEnabled,
              'text-grayscale-secondary': !isSummarizeEnabled,
            })}
          >
            {t('Summarize')}
          </span>
        </button>
      </div>
    </AnswerContainer>
  );
};

export default OpenQuestionTaskAnswers;
