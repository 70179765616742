import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import { useFeatures } from '@paralleldrive/react-feature-toggles';
import { SystemModules } from 'app/api/auth/constants';
import { useAuthenticatedUser } from 'app/api/auth/hooks';
import useGetEnabledModules from 'app/api/auth/hooks/useGetEnabledModules';
import { useUnreadThreadsAmount } from 'app/api/chat/hooks';
import { useSelector } from 'app/hooks';
import useLoginMethod from 'app/hooks/useLoginMethod';
import { useModulesTranslation } from 'app/internationalization/hooks';
import { outsideRoutes } from 'app/router';
import routes from 'app/router/routes';
import { selectors as authSelectors } from 'app/store/auth';
import { selectors as chatSelectors } from 'app/store/chat';
import { selectors as navigationSelectors } from 'app/store/navigation';
import { selectors } from 'app/store/request';
import {
  ExportSquare,
  Graph,
  LogoutCurve,
  ShieldTick,
  SidebarRight,
  User,
} from 'iconsax-react';
import React, { useEffect, useMemo } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import Logo from '../Logo';
import ScrollbarContainer from '../ScrollbarContainer';

import useModuleFeatureCheck from './hooks/useModuleFeatureCheck';
import CustomModule from './modules/CustomModule';
import { getLegacyLinks } from './modules/links';
import NavigationModule from './modules/NavigationModule';
import NavigationLink from './NavigationLink';
import {
  BADGE_KEYS,
  Link,
  SIDEBAR_ANIMATION_DURATION,
  SidebarSections,
  Variant,
} from './types';
import useUnseenNewsAmountQuery from './useUnseenNewsAmountQuery';

interface NavigationProps {
  toggle: () => void;
  variant: Variant;
}

const MobileSideMenu: React.FC<NavigationProps> = ({ toggle, variant }) => {
  const instanceIsReady = useSelector(selectors.getRequestInstanceReady);
  const isSideBarOpen = useSelector(navigationSelectors.getIsSidebarOpen);
  const { data: user, showAdvancedDashboard } = useAuthenticatedUser();
  const { isEasyAccessLogin } = useLoginMethod();

  const { t } = useModulesTranslation();
  const { data: unseenAmount, refetch } = useUnseenNewsAmountQuery(
    Boolean(user)
  );
  const { logout } = useAuthenticatedUser();

  const cachedChatToken = useSelector(chatSelectors.getChatToken);
  const { unreadThreadsAmount } = useUnreadThreadsAmount(!!cachedChatToken);
  const { checkActiveList, checkDiscontinuedList } = useModuleFeatureCheck();
  const tenant = useSelector(authSelectors.getSelectedTenant);

  const { modules } = useGetEnabledModules();
  const features = useFeatures();

  useEffect(() => {
    if (!instanceIsReady) return;
    if (
      user &&
      (user.hiddenModules?.[SystemModules.News] ||
        user.discontinuedModules?.[SystemModules.News])
    ) {
      return;
    }
    refetch();
  }, [refetch, instanceIsReady, user]);

  const badgeData = {
    [BADGE_KEYS.unseenNewsAmount]: unseenAmount,
    [BADGE_KEYS.unreadThreadsAmount]: unreadThreadsAmount,
  };

  const modulesNavLinks = useMemo(
    (): Array<Link> => [
      {
        title: t('Nike Product Hub'),
        Icon: ExportSquare,
        to: routes.nikeSkuRedirect.create(),
        feature: 'nikeSKU',
      },
      {
        title: t('adidas Product Learning'),
        Icon: ExportSquare,
        to: routes.atticusRedirect.create(),
        feature: 'atticus',
      },
    ],
    [t]
  );

  const oldLinks = getLegacyLinks({
    newsHidden: false,
    socialHidden: false,
  }).filter((link) => {
    link.title !== 'Missions' &&
      link.title !== 'News' &&
      link.title !== 'Wiki' &&
      link.title !== 'Social Feed';
  });

  const profileLink = useMemo<Link>(() => {
    return {
      title: t('Profile settings'),
      Icon: User,
      to: routes.profile.create('view'),
    };
  }, [t]);

  return (
    <Container
      className={cx(
        'z-999 shadow-atobi fixed bg-gray-white-blue h-[100dvh]',
        isSideBarOpen && instanceIsReady
          ? `w-[70%] ${SIDEBAR_ANIMATION_DURATION}`
          : `w-0 ${SIDEBAR_ANIMATION_DURATION} opacity-0`
      )}
    >
      <OutsideClickHandler
        onOutsideClick={() => isSideBarOpen && toggle()}
        display="contents"
      >
        <div className="flex flex-col overflow-hidden h-full">
          <div className="flex justify-between items-center px-4 pt-2 mb-2.5">
            <div className="flex items-center">
              <Logo size="md" visible className="object-contain" />
              <span className="text-base text-dark-blue font-bold">
                {tenant?.title ?? 'Atobi'}
              </span>
            </div>
            <button className="text-grayscale-secondary" onClick={toggle}>
              <SidebarRight size={16} />
            </button>
          </div>
          <ScrollbarContainer className="overflow-y-scroll px-2 h-full">
            <nav className="flex flex-col h-full text-grayscale-primary">
              <CustomModule isClipped={variant === Variant.DesktopClipped} />
              {!isEasyAccessLogin &&
                features.includes('sisense') &&
                (modules?.kpiDashboard?.enabled || showAdvancedDashboard) && (
                  <NavigationLink
                    Icon={Graph}
                    isClipped={false}
                    to={routes.dashboards.create()}
                    onClick={toggle}
                  >
                    <div className="flex">
                      <div className="whitespace-nowrap ml-1">
                        {t('Dashboards')}
                      </div>
                    </div>
                  </NavigationLink>
                )}
              <NavigationModule
                header="Modules"
                badgeData={badgeData}
                isClipped={variant === Variant.DesktopClipped}
                onClick={toggle}
                items={oldLinks.filter(checkActiveList)}
                section={SidebarSections.Modules}
              />
              <NavigationModule
                header="Discontinued"
                badgeData={badgeData}
                isClipped={variant === Variant.DesktopClipped}
                items={modulesNavLinks.filter(checkDiscontinuedList)}
                onClick={toggle}
                section={SidebarSections.Discontinued}
              />
            </nav>
          </ScrollbarContainer>
          <div className="flex flex-col mt-auto pb-4 px-2">
            <div className="border-t border-t-gray-silver-blue">
              {!isEasyAccessLogin && (
                <div className="mt-1">
                  <NavigationLink
                    key={profileLink.to}
                    Icon={profileLink.Icon}
                    isClipped={false}
                    to={profileLink.to}
                    onClick={() => toggle()}
                  >
                    <div className="flex">
                      <div className="whitespace-nowrap ml-1">
                        {profileLink.title}
                      </div>
                    </div>
                  </NavigationLink>
                </div>
              )}
              <a
                target="_blank"
                className="flex items-center px-4 py-3 text-sm hover:bg-gray-silver-blue rounded-[10px]"
                href={outsideRoutes.atobiPrivacyPolicy.create()}
                rel="noreferrer"
              >
                <div className="mr-2 text-dark-blue">
                  <ShieldTick size={18} />
                </div>
                <div className="whitespace-nowrap ml-1">
                  {t('Privacy policy')}
                </div>
              </a>

              <button
                className="overflow-hidden rounded py-3 px-4 mb-1 flex text-sm items-center hover:bg-hover-blue text-error"
                onClick={() => logout()}
              >
                <div className="flex justify-center items-center mr-2">
                  <LogoutCurve size={18} />
                </div>

                <div className="whitespace-nowrap ml-1">Log out</div>
              </button>
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    </Container>
  );
};

const Container = styled.div`
  box-shadow: rgba(16, 24, 40, 0.18) 0 0 20px 0 inset;
`;

export default MobileSideMenu;
