import { withProfiler, ErrorBoundary } from '@sentry/react';
import 'app/internationalization';
import React from 'react';
import { QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { BlockingProvider } from './blockings';
import { GenericError, ToastContainer } from './components';
import Head from './components/Head';
import CustomLinkModal from './components/Navigation/CustomLinkModal';
import { Provider as FeatureFlagProvider } from './featureFlags';
import {
  RequestInstanceHandler,
  PageScrollHandler,
  SentryTenantTagHandler,
  SessionConfigHandler,
} from './handlers';
import WebPushHelperHandler from './handlers/WebPushHelperHandler';
import './index.css';
import queryClient from './query';
import './sentry';
import { store, persistor } from './store';

const App: React.FC = () => (
  <div className="App">
    <ToastContainer />
    <ErrorBoundary fallback={<GenericError />}>
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={<div>Loading</div>}>
          <SentryTenantTagHandler>
            <QueryClientProvider client={queryClient}>
              <SessionConfigHandler>
                <RequestInstanceHandler>
                  <Head />
                  <WebPushHelperHandler />
                  <CustomLinkModal />
                  <FeatureFlagProvider>
                    <BlockingProvider>
                      <PageScrollHandler>
                        <Outlet />
                      </PageScrollHandler>
                    </BlockingProvider>
                  </FeatureFlagProvider>
                </RequestInstanceHandler>
              </SessionConfigHandler>
            </QueryClientProvider>
          </SentryTenantTagHandler>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </div>
);

export default withProfiler(App);
