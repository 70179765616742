import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { ListItemNode, ListNode } from '@lexical/list';
import {
  InitialEditorStateType,
  LexicalComposer,
} from '@lexical/react/LexicalComposer';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { editorTypes } from 'app/router/constants';
import {
  EditorState,
  LexicalEditor as LexicalEditType,
  ParagraphNode,
  SerializedEditorState,
} from 'lexical';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import AutoFocusPlugin from './components/AutoFocus';
import FloatingTextFormatToolbarPlugin from './components/FloatingToolbarPlugin';
import HasFocus from './components/HasFocus';
import TextEditor from './components/TextEditor';
import lexicalEditorTheme from './theme';

interface LexicalEditorProps {
  initialState?: InitialEditorStateType;
  type?: 'plain' | 'rich';
  onChange: (e: SerializedEditorState) => void;
  focus?: boolean;
  placeholder?: string;
  disabled?: boolean;
}

const LexicalEditor = ({
  initialState,
  type = 'rich',
  onChange,
  focus,
  placeholder,
  disabled = false,
}: LexicalEditorProps) => {
  const { mode } = useParams<{ mode: string }>();

  const onEditorChange = (
    state: EditorState,
    lexicalEditor: LexicalEditType
  ) => {
    lexicalEditor.update(() => {
      onChange(state.toJSON());
    });
  };

  const enabled =
    mode !== editorTypes.view && mode !== editorTypes.review && !disabled;

  const [floatingAnchorElem, setFloatingAnchorElem] =
    useState<HTMLDivElement | null>(null);

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  return (
    <div className="relative rounded-sm">
      <LexicalComposer
        initialConfig={{
          namespace: 'editor',
          theme: lexicalEditorTheme,

          onError(error) {
            throw error;
          },
          nodes: [
            ParagraphNode,
            HeadingNode,
            ListNode,
            ListItemNode,
            QuoteNode,
            CodeNode,
            CodeHighlightNode,
            TableNode,
            TableCellNode,
            TableRowNode,
          ],
          editorState: initialState,
        }}
      >
        {type === 'rich' && floatingAnchorElem ? (
          <FloatingTextFormatToolbarPlugin anchorElem={floatingAnchorElem} />
        ) : (
          <></>
        )}
        {focus ? <AutoFocusPlugin /> : <></>}
        {enabled ? <HasFocus /> : <></>}
        <ListPlugin />

        <TextEditor
          enabled={enabled}
          type={type}
          placeholder={placeholder}
          onRef={onRef}
        />
        <OnChangePlugin onChange={onEditorChange} />
      </LexicalComposer>
    </div>
  );
};
export default LexicalEditor;
