import { cx } from '@emotion/css';
import {
  useArticlesTranslation,
  useCommonTranslation,
} from 'app/internationalization/hooks';
import {
  ArrowLeft2 as ArrowLeft,
  ArrowRight2 as ArrowRight,
} from 'iconsax-react';
import isEqual from 'lodash/isEqual';
import { useEffect, useRef, useState } from 'react';
import {
  DailyScheduleFrequencyEnum,
  MonthlyScheduleFrequencyEnum,
  WeeklyScheduleFrequencyEnum,
} from 'submodules/common-ui/generated/api/gcs';

import { RecurringArticleDropdownValue, RecurringArticleValue } from '../types';

interface Props {
  recurringValue?: RecurringArticleDropdownValue;
  selectedValue?: RecurringArticleValue[];
  onChange: (option: RecurringArticleValue[]) => void;
  options: RecurringArticleValue[];
}

const RecurringArticleRepeat = ({
  options,
  selectedValue,
  recurringValue,
  onChange,
}: Props) => {
  const { t } = useArticlesTranslation();
  const { t: tc } = useCommonTranslation();

  const ref = useRef<HTMLDivElement>(null);

  const [arrow, setArrow] = useState<{ left: boolean; right: boolean }>({
    left: false,
    right: false,
  });

  useEffect(() => {
    const current = ref.current;
    if (!current) return;

    const handleScroll = () =>
      setArrow({
        left: current.scrollLeft > 0,
        right:
          current.scrollLeft < current.scrollWidth - current.clientWidth - 40,
      });

    current.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => current.removeEventListener('scroll', handleScroll);
  }, [options]);

  const scrollToStart = () =>
    ref.current?.firstElementChild?.scrollIntoView({
      behavior: 'smooth',
      inline: 'start',
    });

  const scrollToEnd = () =>
    ref.current?.lastElementChild?.scrollIntoView({
      behavior: 'smooth',
      inline: 'end',
    });

  const unselectedOptions = options.filter(
    (item) => !selectedValue?.find((option) => isEqual(option, item))
  );

  const recurringValues = {
    [DailyScheduleFrequencyEnum.Daily]: 'day',
    [WeeklyScheduleFrequencyEnum.Weekly]: 'week',
    [MonthlyScheduleFrequencyEnum.Monthly]: 'month',
  };

  return (
    <div className="mb-4">
      <span className="text-grayscale-secondary text-xs mb-1">
        {t('Repeat on {{time}}', {
          time: recurringValue ? t(recurringValues[recurringValue]) : undefined,
        })}
      </span>
      <div className="flex flex-row items-center relative w-full">
        {arrow.left && (
          <button
            className="flex items-center justify-center w-12 h-[36px] text-ceil bg-focus-background"
            onClick={scrollToStart}
          >
            <ArrowLeft size={16} />
          </button>
        )}
        <div className="flex flex-row overflow-x-hidden" ref={ref}>
          {options.map((option, index) => (
            <button
              key={option.value}
              onClick={() => {
                if (!selectedValue) return;

                const isSelected = !unselectedOptions.includes(option);

                if (isSelected && selectedValue.length === 1) return;

                const updatedValue = isSelected
                  ? selectedValue.filter((item) => !isEqual(item, option))
                  : [...selectedValue, option];

                const reorderedValues = options.filter((opt) =>
                  updatedValue.includes(opt)
                );
                onChange(reorderedValues);
              }}
              className={cx(
                'py-2 px-[10px] h-[36px] w-12 border border-focus-background font-bold text-sm hover:text-ceil hover:bg-hover-blue',
                {
                  'bg-grayscale-bg-dark text-ceil':
                    unselectedOptions.includes(option),
                  'bg-hover-blue text-focus':
                    !unselectedOptions.includes(option),
                  'rounded-tl-sm rounded-bl-sm': index === 0,
                  'rounded-tr-sm rounded-br-sm': index === options.length - 1,
                }
              )}
            >
              {tc(option.label)}
            </button>
          ))}
        </div>
        {arrow.right && (
          <button
            className="flex items-center justify-center w-12 h-[36px] text-ceil bg-focus-background"
            onClick={scrollToEnd}
          >
            <ArrowRight size={16} />
          </button>
        )}
      </div>
    </div>
  );
};
export default RecurringArticleRepeat;
