import { cx } from '@emotion/css';

const Skeleton = ({ className }: { className?: string }) => (
  <div className={cx('flex flex-col', className)}>
    <div className="bg-hover-blue rounded w-full h-[112px]" />
    <div className="w-[80%] h-4 mt-2 bg-hover-blue rounded" />
  </div>
);

export default Skeleton;
