import { useMissionQuery } from 'app/api/missions/hooks';
import { useComponentsTranslation } from 'app/internationalization/hooks';
import { useParams } from 'react-router-dom';

import DefaultBreadcrumb from './DefaultBreadcrumb';

const MissionReportBreadcrumb = () => {
  const { id: missionId } = useParams<{ id: string }>();
  const { data } = useMissionQuery({
    id: Number(missionId),
    enabled: !Number.isNaN(Number(missionId)),
  });
  const { t } = useComponentsTranslation();
  return (
    <DefaultBreadcrumb
      to={window.location.href}
      title={data?.name && `${t('{{name}} Report', { name: data.name })}`}
    />
  );
};

export default MissionReportBreadcrumb;
