import ArticleCard from 'app/components/ArticleCard';
import useHomeTranslation from 'app/internationalization/hooks/useHomeTranslation';
import useUIArticleLanguage from 'app/pages/Articles/hooks/useUIArticleLanguage';

import ChannelRow from '../ChannelRow';
import CoverImage from '../CoverImage';

import { Results } from '.';

const RecentResults = ({
  results,
  userLanguage,
}: {
  results: Results;
  userLanguage: string;
}) => {
  const { t } = useHomeTranslation();
  const { getMainLanguage } = useUIArticleLanguage();
  return (
    <>
      <span className="text-xs text-gray-silver font-bold">
        {t('Recent').toUpperCase()}
      </span>
      <div className="flex flex-col gap-5 mt-5">
        {results.articles.map((article) => {
          const language = getMainLanguage(article.languages);
          return (
            <ArticleCard
              recent
              article={article}
              userLanguage={userLanguage}
              key={article.id}
              CoverImage={
                <CoverImage
                  className="w-[86px] h-[56px]"
                  coverImage={article.variants?.[language]?.coverImage}
                />
              }
            />
          );
        })}
      </div>

      {results.channels.map((channel) => (
        <ChannelRow channel={channel} key={channel.id} />
      ))}
    </>
  );
};

export default RecentResults;
