import useGetProfessionsQuery from 'app/api/professions/hooks/useProfessionsQuery';
import { Profession } from 'app/api/professions/types';
import Checkbox from 'app/components/Checkbox';
import { useEffect, useMemo, useState } from 'react';

interface ProfessionsListProps {
  selectedProfessions: number[];
  searchValue: string;
  professions?: Profession[];
  onChange: (id: number) => void;
}

const ProfessionsList = ({
  selectedProfessions,
  searchValue,
  professions,
  onChange,
}: ProfessionsListProps) => {
  const [filteredProfessions, setFilteredProfessions] = useState<
    Profession[] | undefined
  >();

  const { data } = useGetProfessionsQuery({
    enabled:
      professions === undefined || (professions && professions?.length === 0),
  });

  const professionsList = useMemo(
    () => (professions && professions.length > 0 ? professions : data),
    [data, professions]
  );

  useEffect(() => {
    const filtered = professionsList?.filter((profession) =>
      profession.name
        .toLocaleLowerCase()
        .includes(searchValue.toLocaleLowerCase())
    );

    setFilteredProfessions(filtered);
  }, [searchValue, professionsList]);

  return (
    <>
      {filteredProfessions?.map((profession) => {
        return (
          <div className="flex items-center mt-3.5" key={profession.id}>
            <Checkbox
              id={profession.name}
              onChange={() => onChange(profession.id)}
              checked={selectedProfessions.includes(profession.id)}
            />
            <span
              data-tip={profession.name}
              className="ml-2 text-sm text-grayscale-primary whitespace-nowrap overflow-hidden overflow-ellipsis"
            >
              {profession.name}
            </span>
          </div>
        );
      })}
    </>
  );
};

export default ProfessionsList;
