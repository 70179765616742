import { cx } from '@emotion/css';
import { $createHeadingNode, HeadingTagType } from '@lexical/rich-text';
import { $wrapNodes } from '@lexical/selection';
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  LexicalEditor,
} from 'lexical';
import { FC, RefObject, useEffect, useRef } from 'react';
import CheckLineIcon from 'remixicon-react/CheckLineIcon';

import { EditorBlockName } from '../types';

interface DropdownOptionsProps {
  editor: LexicalEditor;
  blockType: EditorBlockName;
  toolbarRef: RefObject<HTMLDivElement>;
  setShowBlockOptionsDropDown: (value: boolean) => void;
}

const DropdownOptions: FC<DropdownOptionsProps> = ({
  editor,
  blockType,
  toolbarRef,
  setShowBlockOptionsDropDown,
}) => {
  const dropDownRef = useRef<HTMLDivElement>(null);

  const formatParagraph = () => {
    if (blockType !== 'paragraph') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createParagraphNode());
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatTitle = (title: HeadingTagType) => {
    if (blockType !== title) {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode(title));
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  useEffect(() => {
    const dropDown = dropDownRef.current;
    const toolbar = toolbarRef.current;

    if (dropDown !== null && toolbar !== null) {
      const handle = (event: MouseEvent) => {
        const target = event.target as Node;
        if (!dropDown.contains(target) && !toolbar.contains(target)) {
          setShowBlockOptionsDropDown(false);
        }
      };
      document.addEventListener('click', handle);

      return () => {
        document.removeEventListener('click', handle);
      };
    }
  }, [dropDownRef, setShowBlockOptionsDropDown, toolbarRef]);

  const formats = [
    {
      format: 'h1',
      title: 'Heading',
      onClick: formatTitle,
    },
    {
      format: 'h2',
      title: 'Subheading',
      onClick: formatTitle,
    },
    {
      format: 'paragraph',
      title: 'Paragraph',
      onClick: formatParagraph,
    },
  ];

  return (
    <div
      className="flex flex-col absolute bg-white z-10 gap-1 p-1 shadow-atobi rounded top-11 left-3 min-w-[118px]"
      ref={dropDownRef}
    >
      {formats.map(({ format, title, onClick }, index) => (
        <button
          key={index}
          className={cx(
            'min-h-[36px] py-2 px-3 flex justify-between items-center p-2 shadow-atobi rounded',
            {
              'bg-focus-background': blockType === format,
              'hover:bg-hover-blue': blockType !== format,
            }
          )}
          onClick={() => onClick(format as HeadingTagType)}
        >
          <span className="p-0 m-0 text-xs">{title}</span>
          {blockType === format && (
            <CheckLineIcon className="text-focus" size={16} />
          )}
        </button>
      ))}
    </div>
  );
};

export default DropdownOptions;
