import { types as baseTypes } from 'app/api';
import TypedIconButton from 'app/components/TypedIconButton';
import { useTranslations } from 'app/hooks';

import FileContents from './FileContents';

interface CommentProps {
  text: string;
  files?: Array<baseTypes.ApiFile>;
  likesCount: number;
  likedByMe: boolean;
  onLike: () => void;
}

const Comment: React.FC<CommentProps> = ({
  text,
  files,
  likedByMe,
  likesCount,
  onLike,
}) => {
  const {
    translations: [translatedText],
    toggleTranslation,
    isTranslating,
    isTranslated,
  } = useTranslations([text]);

  return (
    <div>
      <div className="ml-10 mt-2">
        {files && <FileContents files={files} />}
        <p className="text-grayscale-primary text-sm break-words">
          {translatedText}
        </p>
        <div className="flex text-grayscale-secondary items-center mt-2">
          <TypedIconButton
            onClick={onLike}
            isActive={likedByMe}
            variant="like"
          />
          <p className="text-xs ml-1">{likesCount}</p>
          <TypedIconButton
            onClick={toggleTranslation}
            isActive={isTranslated}
            isLoading={isTranslating}
            variant="translate"
            className="ml-4"
          />
        </div>
      </div>
    </div>
  );
};

export default Comment;
