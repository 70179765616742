import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import config from 'app/config';
import { selectors } from 'app/store/auth';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';

import ActionButtons from './ActionButtons';
import Image from './Image';
import ImageCounter from './ImageCounter';
import NavigationButtons from './NavigationButtons';
import useMediaViewerActions from './useMediaViewerActions';
import UserInfo from './UserInfo';

type UserInfo = {
  createdAt: string;
  locationName: string;
  userAvatar: string;
  userName: string;
};
interface MediaViewerProps {
  userInfo?: UserInfo;
  imgIds?: Array<string>;
  showUserInfo?: boolean;
  externalSrc?: string;
  closeModal: () => void;
}

const MediaViewer = ({
  userInfo,
  imgIds,
  showUserInfo = true,
  externalSrc,
  closeModal,
}: MediaViewerProps) => {
  const tenant = useSelector(selectors.getSelectedTenant);
  const [imageRef, setImageRef] = useState<HTMLImageElement | null>(null);

  const {
    selectedImage,
    downloadFile,
    downloadExternalFile,
    onNextClick,
    onPreviousClick,
    zoomImage,
  } = useMediaViewerActions({
    imageIds: imgIds ?? [],
    imageRef: imageRef,
  });

  const completeSrcs: Array<string> = [];

  imgIds?.forEach((id) => {
    completeSrcs.push(
      `${config.env.gcsApiUrl}/file/${tenant?.alias ?? ''}/${id}`
    );
  });

  useEffect(() => {
    const onEscapeClick = (ev: KeyboardEvent) => {
      if (ev.key === 'Escape') {
        closeModal();
      }
    };

    document.addEventListener('keydown', onEscapeClick);

    return () => document.removeEventListener('keydown', onEscapeClick);
  }, [closeModal]);

  const src = externalSrc ?? completeSrcs[selectedImage];

  return createPortal(
    <ModalContainer className="flex justify-center items-center fixed z-50 top-0 left-0 w-screen h-screen">
      <div className="flex flex-col items-center justify-center relative w-full h-full p-6">
        <div className="flex items-center justify-center w-full mb-auto relative z-[1]">
          <ImageCounter
            current={selectedImage}
            total={externalSrc ? 1 : completeSrcs.length}
          />
          <div
            className={cx({
              'invisible w-full': !showUserInfo,
              contents: showUserInfo,
            })}
          >
            <UserInfo
              createdAt={userInfo?.createdAt ?? ''}
              locationName={userInfo?.locationName ?? ''}
              userAvatar={userInfo?.userAvatar ?? ''}
              userName={userInfo?.userName ?? ''}
            />
          </div>
          <ActionButtons
            closeModal={closeModal}
            zoomImage={zoomImage}
            downloadImage={
              externalSrc
                ? () => downloadExternalFile(externalSrc)
                : downloadFile
            }
          />
        </div>

        <NavigationButtons
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
          totalImages={completeSrcs.length}
        />

        {src && <Image ref={(ref) => setImageRef(ref)} src={src} />}
      </div>
    </ModalContainer>,
    document.body
  );
};

const ModalContainer = styled.div`
  background-color: rgba(34, 34, 34, 0.8);
  backdrop-filter: blur(20px);
`;

export default MediaViewer;
