import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import { useScreenBreakpoint } from 'app/hooks';
import { actions, selectors } from 'app/store/navigation';
import { isMobile } from 'app/utils';
import { useDispatch, useSelector } from 'react-redux';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';

import { BOTTOM_MENU_HEIGHT } from '../MobileNavigation/MobileNavigation';
import { MOBILE_TOP_MENU_HEIGHT } from '../MobileTopMenu/MobileTopMenu';
import Modal from '../Modal';

const CustomLinkModal = () => {
  const dispatch = useDispatch();
  const url = useSelector(selectors.getCustomLink);
  const breakpoint = useScreenBreakpoint();
  const _isMobile = isMobile(breakpoint);

  const close = () => dispatch(actions.setCustomLink(null));

  if (!url) return <></>;

  return (
    <div className="z-999 relative">
      <Modal onClose={close} containerPaddingClass="p-0" overflow={_isMobile}>
        <Container
          className="flex flex-col items-center relative w-[90vw]"
          isMobile={_isMobile}
        >
          <button
            className={cx(
              'flex justify-center items-center rounded bg-white shadow-atobi text-grayscale-secondary',
              {
                'fixed top-[0.5vh] h-10 w-10': !_isMobile,
                'absolute -top-7 h-7 w-7': _isMobile,
              }
            )}
            onClick={close}
          >
            <CloseLineIcon />
          </button>
          <iframe className="w-full h-full" title="Unique" src={url} />
        </Container>
      </Modal>
    </div>
  );
};

const Container = styled.div`
  height: ${(props: { isMobile: boolean }) =>
    props.isMobile
      ? `calc(90vh - ${BOTTOM_MENU_HEIGHT} - ${MOBILE_TOP_MENU_HEIGHT});`
      : `90vh`};
`;

export default CustomLinkModal;
