import { cx } from '@emotion/css';
import { useAuthenticatedUser } from 'app/api/auth/hooks';
import useGetChannelsQuery from 'app/api/channels/hooks/useChannelsQuery';
import useGetSettingsQuery from 'app/api/pwaSettings/hooks/useGetSettingsQuery';
import { InfiniteScroll, PageLoader, VerticalChevron } from 'app/components';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { Global } from 'iconsax-react';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import { Channel } from 'submodules/common-ui/generated/api/gcs';

import ChannelModalImage from '../../ChannelModal/ChannelModalImage';

interface Props {
  selectedChannel?: Channel;
  onChannelSelect: (channel: Channel) => void;
}

const ChannelsDropdown: FC<Props> = ({ selectedChannel, onChannelSelect }) => {
  const { t } = useArticlesTranslation();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  const { data: settings } = useGetSettingsQuery({});
  const { data: user } = useAuthenticatedUser();

  const easyAccessEnabled =
    settings?.tenants?.find((ct) => ct.tenant === user?.client)?.easyAccess ??
    false;

  const getChannelTitle = useCallback(
    (channel: Channel) => {
      const defaultCategoryNameKey = Object.keys(channel.title).find(
        (key) => channel.title[key].isDefault
      );

      const userLanguage = user?.contentLanguage.uiLanguage ?? '';
      return (
        channel?.title?.[userLanguage]?.title ??
        (defaultCategoryNameKey
          ? channel?.title?.[defaultCategoryNameKey]?.title
          : '')
      );
    },
    [user?.contentLanguage.uiLanguage]
  );

  useEffect(() => {
    if (selectedChannel) {
      setSearch(getChannelTitle(selectedChannel));
    }
  }, [selectedChannel, getChannelTitle]);

  const {
    data: channels,
    isLoading: isLoadingChannels,
    fetchNextPage,
    meta,
  } = useGetChannelsQuery({
    ranking: 'desc',
    shownAs: 'cards',
    refetchOnMount: true,
  });

  const suggestions = useMemo(() => {
    if (search === '') return channels;
    if (selectedChannel && getChannelTitle(selectedChannel) === search)
      return channels;

    return channels?.filter((channel) =>
      Object.keys(channel.title).find(
        (key) =>
          channel.title[key].isDefault &&
          channel.title[key].title
            .toLowerCase()
            .includes(search.trim().toLowerCase())
      )
    );
  }, [selectedChannel, search, channels, getChannelTitle]);

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
    },
    []
  );

  const handleSelectChannel = useCallback(
    (channel: Channel) => {
      onChannelSelect(channel);
      setSearch(getChannelTitle(channel));
    },
    [onChannelSelect, getChannelTitle]
  );

  const handleOutsideClick = () => {
    setOpen(false);

    if (!selectedChannel) return;

    if (getChannelTitle(selectedChannel) === search) return;

    setSearch(getChannelTitle(selectedChannel));
  };

  const currentPage = meta?.currentPage || 0;
  const lastPage = meta?.lastPage || 0;

  return (
    <div className="relative w-full">
      <label>
        <span className="font-bold text-sm">{t('Channel')}</span>
        <span className="text-error">*</span>
      </label>
      <OutsideClickHandler onOutsideClick={handleOutsideClick}>
        <div
          role="presentation"
          onClick={() => setOpen(!open)}
          className={cx('flex h-[46px] items-center gap-2 border rounded p-2', {
            'border-focus': !!selectedChannel,
            'focus-within:border-gray-dark border-gray-dark': !selectedChannel,
          })}
        >
          {selectedChannel && (
            <div className="h-full min-w-[56px] max-w-[56px] rounded bg-hover-blue overflow-hidden">
              <ChannelModalImage image={selectedChannel.coverImage} />
            </div>
          )}
          <input
            name="channel"
            placeholder={t('Add channel') ?? 'Add channel'}
            value={search}
            onChange={handleSearchChange}
            autoComplete="off"
            className="w-full outline-none bg-transparent focus:outline-none text-grayscale-primary placeholder-grayscale-secondary::placeholder"
          />
          <div className="ml-auto mr-3">
            <VerticalChevron open={open} className="w-4 h-4" />
          </div>
        </div>
        {open && (
          <div
            id="parent"
            className="absolute top-13 left-0 w-full bg-white border border-gray-light rounded shadow-md z-50 max-h-[256px] overflow-auto"
          >
            {isLoadingChannels && (
              <div className="h-full">
                <PageLoader />
              </div>
            )}

            <InfiniteScroll
              scrollableTarget="parent"
              dataLength={suggestions.length}
              hasChildren={suggestions.length > 0}
              hasMore={currentPage < lastPage}
              next={fetchNextPage}
              loader={<></>}
            >
              {suggestions.map((channel) => (
                <button
                  type="button"
                  key={channel.id}
                  className="flex items-center gap-2 w-full h-16 p-2 cursor-pointer hover:bg-grayscale-secondary hover:bg-opacity-10"
                  onClick={() => {
                    handleSelectChannel(channel as Channel);
                    setOpen(false);
                  }}
                >
                  <div className="h-[45px] min-w-[90px] max-w-[90px] rounded bg-hover-blue overflow-hidden">
                    <ChannelModalImage image={channel.coverImage} />
                  </div>
                  <div className="flex flex-col items-start">
                    <span className="text-sm text-grayscale-primary">
                      {getChannelTitle(channel as Channel)}
                    </span>
                    {easyAccessEnabled && channel.easyAccess && (
                      <div className="flex items-center text-grayscale-secondary">
                        <Global size={16} />
                        <span className="text-sm ml-1">{t('Easy-access')}</span>
                      </div>
                    )}
                  </div>
                </button>
              ))}
            </InfiniteScroll>

            {(!suggestions || suggestions.length === 0) &&
              !isLoadingChannels && (
                <div className="flex p-2 items-center gap-2 cursor-pointer hover:bg-grayscale-secondary hover:bg-opacity-10 h-16">
                  <span className="text-sm text-grayscale-primary">
                    {t('No channels found')}
                  </span>
                </div>
              )}
          </div>
        )}
      </OutsideClickHandler>
      {easyAccessEnabled && selectedChannel?.easyAccess && (
        <div
          className="flex items-center w-full p-2 mt-2 bg-gray-white-shadow rounded border border-focus-background cursor-pointer"
          role="presentation"
          onClick={() =>
            window.open(
              'https://help.atobi.io/article/190-what-is-easy-access-to-channels',
              '_blank'
            )
          }
        >
          <Global size={24} className="text-focus mr-2" />
          <span className="text-xs">
            <Trans
              components={{
                span: <span className="text-focus">here.</span>,
              }}
              i18nKey="articles.The selected channel is an Easy-access channel which means it’s publicly available through URL. You can read more about Easy-access channels here."
            />
          </span>
        </div>
      )}
      <span className="text-xs text-grayscale-secondary">
        {t('Channel contains articles organized by topic.')}
      </span>
    </div>
  );
};

export default ChannelsDropdown;
