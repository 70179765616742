import { NewsKey } from '../types';

import useBaseTranslation from './useBaseTranslation';

const useNewsTranslation = () =>
  useBaseTranslation<NewsKey>(undefined, {
    keyPrefix: 'news',
  });

export default useNewsTranslation;
