import { createCustomInstance } from 'app/api/request';
import config from 'app/config';
import { SubdomainConfigurationApi } from 'submodules/common-ui/generated/api/sub-domain-config';

const getConfigurations = () => {
  const instance = createCustomInstance({
    disableCamelCaseConversion: true,
    url: config.env.subdomainApiUrl,
  });
  return new SubdomainConfigurationApi(
    undefined,
    config.env.subdomainApiUrl,
    instance
  ).getSubdomainConfiguration();
};

export { getConfigurations };
export default getConfigurations;
