import { SystemModules } from 'app/api/auth/constants';
import { FeatureFlagKey } from 'app/config/types';

export const SIDEBAR_WIDTH = `250px`;
export const SIDEBAR_WIDTH_HIDDEN = '0px';
export const SIDEBAR_WIDTH_CLIPPED = '70px';

export const SIDEBAR_ANIMATION_DURATION = 'duration-500';
export const SIDEBAR_ANIMATION_TRANSLATION = `translate-x-[70%] ${SIDEBAR_ANIMATION_DURATION}`;

export type SidebarSection =
  (typeof SidebarSections)[keyof typeof SidebarSections];

export const SidebarSections = {
  General: 'GENERAL',
  ContentManagement: 'CONTENT_MANAGEMENT',
  Organization: 'ORGANIZATION',
  Modules: 'MODULES',
  Discontinued: 'DISCONTINUED',
  CustomLinks: 'CUSTOM_LINKS',
  Channels: 'CHANNELS',
} as const;

export const DEFAULT_OPEN_SECTIONS: SidebarSection[] = [
  SidebarSections.Channels,
];
export interface Link {
  title: string;
  Icon: React.ComponentType;
  to: string;
  badgeName?: string;
  isActive?: (currentRoute: string) => boolean;
  feature?: FeatureFlagKey;
  tag?: boolean;
  moduleKey?: SystemModules;
  isEasyAccessLoginAccessible?: boolean;
}

export const Variant = {
  Desktop: 'desktop',
  Mobile: 'mobile',
  DesktopClipped: 'desktop-clipped',
  MobileHidden: 'mobile-hidden',
} as const;

export const getWidth = (variant: Variant) => {
  switch (variant) {
    case Variant.MobileHidden:
      return SIDEBAR_WIDTH_HIDDEN;
    case Variant.Mobile:
      return SIDEBAR_WIDTH;
    case Variant.Desktop:
      return SIDEBAR_WIDTH;
    case Variant.DesktopClipped:
      return SIDEBAR_WIDTH_CLIPPED;
    default:
      return SIDEBAR_WIDTH_HIDDEN;
  }
};

export type Variant = (typeof Variant)[keyof typeof Variant];

export const BADGE_KEYS = {
  unseenNewsAmount: 'unseenNewsAmount',
  unreadThreadsAmount: 'unreadThreadsAmount',
};
