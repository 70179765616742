import { Element, useNode } from '@craftjs/core';
import LexicalEditor from 'app/components/LexicalEditor';
import { useSelector } from 'app/hooks';
import useIsEditorEnabled from 'app/hooks/useIsEditorEnabled';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import apiToEditor from 'app/pages/ArticleEditor/helpers/lexical/apiToEditor';
import editorToApi from 'app/pages/ArticleEditor/helpers/lexical/editorToApi';
import BlockTypes from 'app/pages/Editor/helpers/constants';
import { selectors } from 'app/store/editor';
import { ArticleShownAs, TextBlockVariant } from 'common-ui/generated/api/gcs';
import { useEffect, useMemo, useState } from 'react';

import generateInitialState, {
  InitialType,
} from '../../helpers/lexical/generateInitialState';
import BaseBlockContainer from '../BaseBlockContainer';
import DraggableContainer from '../DraggableContainer';

interface TextBlockProps {
  nodes?: TextBlockVariant;
  initialType?: InitialType;
  translationStatus: string;
  position?: number;
}

const TextBlock = ({ nodes, initialType }: TextBlockProps) => {
  const { t } = useArticlesTranslation();
  const { enabled } = useIsEditorEnabled();
  const [initialState, setInitialState] = useState<string | undefined>(
    undefined
  );
  const [focused, setFocused] = useState<boolean>();

  const article = useSelector(selectors.getArticle);
  const shownAs = useSelector(selectors.getShownAs);

  const {
    id,
    selected,
    connectors: { connect },
    actions: { setProp },
  } = useNode((state) => ({
    selected: state.events.selected,
  }));

  useEffect(() => {
    if (nodes) {
      const parsedToEditor = apiToEditor(nodes);
      const initialStateString = JSON.stringify(parsedToEditor);
      setInitialState(initialStateString);
    } else {
      const initialLexicalState = generateInitialState(initialType);
      setInitialState(initialLexicalState);
    }
  }, [initialType, nodes]);

  useEffect(() => {
    if (!selected) setFocused(undefined);
  }, [selected]);

  const disabled = useMemo(() => !enabled || !focused, [enabled, focused]);

  const hasArticleTextBlock = useMemo(
    () => article?.blocks.some((block) => block.id.toString() === id),
    [article?.blocks, id]
  );

  const hasDragContainer = useMemo(
    () =>
      shownAs.value === ArticleShownAs.Training &&
      enabled &&
      !hasArticleTextBlock &&
      initialType?.type === 'heading',
    [shownAs.value, enabled, hasArticleTextBlock, initialType?.type]
  );

  const placeholder = useMemo(() => {
    switch (initialType?.tag ?? initialType?.type) {
      case 'h1':
        return t('Heading');
      case 'h2':
        return t('Subheading');
      case 'paragraph':
        return t('Paragraph');
      default:
        return undefined;
    }
  }, [initialType, t]);

  return (
    <div
      ref={(ref) => ref && connect(ref)}
      role="presentation"
      onClick={() => {
        if (!enabled || !selected) return;

        if (focused === undefined) {
          setFocused(false);
          return;
        }

        if (focused === false) {
          setFocused(true);
        }
      }}
    >
      <BaseBlockContainer
        deleteOnBackspace={enabled && selected && !focused}
        nodeId={id}
        selected={selected}
        type="Text"
        focused={focused}
      >
        {initialState && (
          <LexicalEditor
            initialState={initialState}
            focus={!!initialType}
            disabled={disabled}
            placeholder={placeholder}
            onChange={(state) => {
              setProp((props: TextBlockProps) => {
                props.nodes = editorToApi(state.root.children);
              });
            }}
          />
        )}
      </BaseBlockContainer>
      {hasDragContainer && (
        <Element
          parentId={id}
          id="draggableContainer"
          is={DraggableContainer}
          canvas
        />
      )}
    </div>
  );
};

TextBlock.craft = {
  displayName: BlockTypes.TextBlock,
};

export { TextBlock };
export default TextBlock;
