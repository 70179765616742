import { useScreenBreakpoint } from 'app/hooks';
import { useComponentsTranslation } from 'app/internationalization/hooks';
import { isMobile } from 'app/utils';
import CrossIcon from 'assets/icons/cross.svg?react';

import Modal from '../Modal';
import SubmitAndCancelButton from '../SubmitAndCancelButton';

interface DeleteModalProps {
  onClose: () => void;
  onDelete: () => void;
  title: string;
  description?: string;
}

const DeleteModal = ({
  onClose,
  onDelete,
  title,
  description,
}: DeleteModalProps) => {
  const { t } = useComponentsTranslation();
  const breakpoint = useScreenBreakpoint();
  const _isMobile = isMobile(breakpoint);

  const onSubmit = () => {
    onDelete();
    onClose();
  };

  return (
    <Modal onClose={onClose} width={_isMobile ? 'sm' : 'md'} className="p-6">
      <div className="flex items-center mb-4 justify-between">
        <p className="text-grayscale-primary text-lg font-bold">{title}</p>
        <button
          className="text-grayscale-secondary flex items-center justify-center w-10 h-10"
          onClick={onClose}
        >
          <CrossIcon />
        </button>
      </div>
      {description && (
        <p className="text-grayscale-primary text-base max-w-[328px]">
          {description}
        </p>
      )}
      <SubmitAndCancelButton
        className="mt-7"
        cancelLabel={t('Cancel')}
        submitLabel={t('Delete')}
        onCancel={onClose}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

export default DeleteModal;
