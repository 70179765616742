import { cx } from '@emotion/css';
import AtobiSupport from 'app/components/AtobiSupport';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { FIRST_STEP, SECOND_STEP } from 'app/utils/steps';

interface ModalFooterProps {
  step: number;
  submitDisabled: boolean;
  isArticleShared: boolean;
  selectedTenants?: boolean;
  onSubmit: () => void;
}

const ModalFooter = ({
  submitDisabled,
  step,
  isArticleShared,
  selectedTenants = false,
  onSubmit,
}: ModalFooterProps) => {
  const { t } = useArticlesTranslation();

  const isSelecting =
    (step === FIRST_STEP && selectedTenants) || step === SECOND_STEP;
  const isViewing = step === FIRST_STEP && isArticleShared && !selectedTenants;

  return (
    <div
      className={cx('flex flex-col items-center justify-center w-full', {
        'pb-4': step === SECOND_STEP,
      })}
    >
      {(isSelecting || isViewing) && (
        <button
          onClick={onSubmit}
          disabled={submitDisabled}
          className={cx(
            'w-[176px] h-12 rounded-xl text-sm border-transparent self-end mr-3',
            {
              'bg-focus text-white': !submitDisabled,
              'bg-gray-light text-grayscale-secondary': submitDisabled,
            }
          )}
        >
          {isViewing && t('Done')}
          {step === FIRST_STEP && selectedTenants && t('Next')}
          {step === SECOND_STEP && t('Forward')}
        </button>
      )}
      {step === FIRST_STEP && (
        <div className="flex w-full items-center h-10 mt-4 bg-grayscale-bg-dark text-grayscale-secondary rounded-b-lg">
          <span className="ml-3 mr-5 text-xs">
            {t('Looking to connect with a team that isn’t on the list?')}{' '}
            <AtobiSupport />
          </span>
        </div>
      )}
    </div>
  );
};

export default ModalFooter;
