import { CategoriesKey } from '../types';

import useBaseTranslation from './useBaseTranslation';

const useCategoriesTranslation = () =>
  useBaseTranslation<CategoriesKey>(undefined, {
    keyPrefix: 'categories',
  });

export default useCategoriesTranslation;
