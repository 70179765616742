import { Modal, PrivacyPolicyLink, ResizeableTextarea } from 'app/components';
import { useScreenBreakpoint } from 'app/hooks';
import { useComponentsTranslation } from 'app/internationalization/hooks';
import { isMobile } from 'app/utils';
import CrossIcon from 'assets/icons/cross.svg?react';
import { InfoCircle } from 'iconsax-react';
import { useState } from 'react';
import { Trans } from 'react-i18next';

import SubmitAndCancelButton from '../SubmitAndCancelButton';

interface ReportModalProps {
  onClose: () => void;
  onReport: (reason: string) => void;
}

const ReportModal = ({ onClose, onReport }: ReportModalProps) => {
  const { t } = useComponentsTranslation();
  const [reportValue, setReportValue] = useState('');
  const breakpoint = useScreenBreakpoint();
  const _isMobile = isMobile(breakpoint);

  const onSubmit = () => {
    onReport(reportValue);
    onClose();
  };

  return (
    <Modal onClose={onClose} className="p-6" width={_isMobile ? 'sm' : 'md'}>
      <div className="flex items-center mb-4 justify-between">
        <p className="text-grayscale-primary text-lg font-bold">
          {t('Report Inappropriate Behavior')}
        </p>
        <button
          className="bg-light text-grayscale-secondary shadow-atobi flex items-center justify-center w-10 h-10 rounded-lg"
          onClick={onClose}
        >
          <CrossIcon />
        </button>
      </div>
      <div className="bg-grayscale-bg-dark rounded-[8px] flex items-center p-3 mb-6">
        <InfoCircle className="w-7 h-7 mr-3" />
        <div>
          <Trans
            components={{
              a: (
                <PrivacyPolicyLink
                  text={`${t('Acceptable Use Policy')}`}
                  className="text-focus"
                />
              ),
            }}
            i18nKey="components.Our Acceptable Use Policy specifies what we do and don’t allow in Atobi."
          />
        </div>
      </div>
      <p className="text-grayscale-primary">
        {t(
          'If the member is sharing content that goes against the policy, please specify how (e.g. sexually explicit, harassment, hate speech, spam).'
        )}
      </p>
      <p className="text-grayscale-primary mt-3">
        {t(
          'We will review your report, and we’ll make sure to take disciplinary action if needed.'
        )}
      </p>
      <ResizeableTextarea
        defaultHeight={120}
        onChange={(e) => setReportValue(e.target.value)}
        className="bg-grayscale-bg-dark border focus:text-black border-gray-dark mb-7 w-full h-auto overflow-auto resize-none focus:outline-none text-grayscale-secondary p-4 rounded-lg mt-6"
        placeholder={`${t(
          'Please specify reason of the complaint. Thank you!'
        )}`}
        value={reportValue}
        autoFocus
      />
      <SubmitAndCancelButton
        cancelLabel={t('Cancel')}
        submitLabel={t('Report')}
        onCancel={onClose}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

export default ReportModal;
