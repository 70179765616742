import { useEditor, useNode } from '@craftjs/core';
import { cx } from '@emotion/css';
import useIsEditorEnabled from 'app/hooks/useIsEditorEnabled';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import apiToLexical from 'app/pages/ArticleEditor/helpers/lexical/apiToLexical';
import lexicalInitialState from 'app/pages/ArticleEditor/helpers/lexical/initialState';
import lexicalToApi from 'app/pages/ArticleEditor/helpers/lexical/lexicalToApi';
import dragArea from 'assets/icons/drag.svg';
import { Add } from 'iconsax-react';
import { useCallback } from 'react';
import ReactTooltip from 'react-tooltip';
import {
  InternalFileGet,
  Paragraph,
} from 'submodules/common-ui/generated/api/gcs';

import LexicalInputWrapper from './Lexical/LexicalInputWrapper';
import PollOptionImage from './PollOptionImage';

export interface PollOptionProps {
  imageSchema?: InternalFileGet;
  text?: Paragraph;
}

const PollOption = ({ text, imageSchema }: PollOptionProps) => {
  const { t } = useArticlesTranslation();

  const {
    connectors: { select },
    query: { node: getNode },
    actions: { delete: deleteNode },
    nodes,
  } = useEditor((state) => ({ nodes: state.nodes }));

  const {
    connectors: { connect, drag },
    actions: { setProp },
    nodeId,
    parent,
    container,
    index,
  } = useNode((node) => {
    let parents: Array<string> = [];
    let children: Array<string> = [];

    if (node.data?.parent) {
      const { ancestors, childNodes } = getNode(node.data.parent);
      parents = ancestors();
      children = childNodes();
    }

    return {
      container: node.data.parent,
      parent: parents[0] ?? null,
      nodeId: node.id,
      index: Array.from(children).indexOf(node.id) + 1,
    };
  });

  const { enabled } = useIsEditorEnabled();

  const getInitialInputstate = () => {
    if (!text) {
      return lexicalInitialState();
    }

    const parsedToEditor = apiToLexical(text);
    return JSON.stringify(parsedToEditor);
  };

  const removeOption = () => {
    if (!container) return;
    if (nodes[container]?.data?.nodes?.length === 1) return;
    deleteNode(nodeId);
  };

  const pollBlockSelected = useCallback(
    () => nodes[parent].events.selected,
    [nodes, parent]
  );

  const emptyOptionsError = useCallback(() => {
    return (
      !nodes[parent].events.selected &&
      !nodes[nodeId].data.props?.text?.children[0]?.value &&
      !nodes[nodeId].data.props?.imageSchema
    );
  }, [nodeId, parent, nodes]);

  const isError = emptyOptionsError();

  return (
    <div ref={(ref) => ref && connect(ref)}>
      <div
        ref={(ref) => ref && select(ref, parent)}
        className="flex items-center mt-2 relative gap-2"
      >
        <div
          ref={(ref) => ref && drag(ref)}
          className="absolute -left-5 bg-white opacity-0 hover:opacity-100"
        >
          <img
            className="h-4 w-4 opacity-70 cursor-pointer hover:opacity-100"
            src={dragArea}
            alt="Drag"
          />
        </div>
        <div className="font-sm font-normal text-gray-dark pl-0.5">
          {index.toLocaleString(undefined, {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}
        </div>
        <LexicalInputWrapper
          initialState={getInitialInputstate()}
          disabled={!enabled || !pollBlockSelected()}
          error={enabled ? isError : false}
          placeholder={t('Option')}
          onChange={(state) => {
            setProp((props: PollOptionProps) => {
              props.text = lexicalToApi(state.root.children);
            });
          }}
        />

        <PollOptionImage
          disabled={!enabled}
          error={enabled ? isError : false}
          imageSchema={imageSchema}
          onImageUploaded={(image, err) => {
            if (err) return;

            setProp((props: PollOptionProps) => {
              props.imageSchema = image;
            });
          }}
        />

        <Add
          data-tip={t('Delete option')}
          data-for="delete"
          onClick={() => {
            if (!enabled) return;
            removeOption();
          }}
          size={20}
          className={cx('rotate-45 text-grayscale-secondary', {
            'hover:text-black': enabled,
          })}
        />
      </div>

      {enabled && (
        <>
          <ReactTooltip
            place={emptyOptionsError() ? 'left' : 'top'}
            effect="solid"
            class="react-tooltip h-16 text-center"
            id="option"
          />
          <ReactTooltip
            place="top"
            effect="solid"
            class="react-tooltip h-16 text-center"
            id="delete"
          />
        </>
      )}
    </div>
  );
};

PollOption.craft = {
  displayName: 'PollOption',
};

export default PollOption;
