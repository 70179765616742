import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import useGetSummarizeQuery from 'app/api/aiService/hooks/useGetSummarizeQuery';
import useSummarizeMutation from 'app/api/aiService/hooks/useSummarizeMutation';
import { useAuthenticatedUser } from 'app/api/auth/hooks';
import config from 'app/config';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { selectors } from 'app/store/editor';
import Spinner from 'assets/icons/spinner.svg?react';
import { Book1, InfoCircle, Magicpen, Refresh } from 'iconsax-react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SummarySchema } from 'submodules/common-ui/generated/api/ai-service';

import { useAnswersContext } from '../Answers/context';

import ActionButton from './ActionButton';
import { MINIMUM_REQUIRED_ANSWERS } from './constants';
import Error from './Error';
import GeneratingSkeleton from './GeneratingSkeleton';
import GradientText from './GradientText';
import Summary from './Summary';
import UserAvatar from './UserAvatar';

const SmartSummary = () => {
  const { actionAnswers, actionAnswersMeta } = useAnswersContext();
  const [summary, setSummary] = useState<SummarySchema | null>(null);
  const article = useSelector(selectors.getArticle);
  const { selectedTaskId } = useAnswersContext();
  const { data: user } = useAuthenticatedUser();
  const { t } = useArticlesTranslation();

  const isInsufficientAnswers =
    (actionAnswersMeta?.total &&
      actionAnswersMeta?.total < MINIMUM_REQUIRED_ANSWERS) ||
    false;

  const { data, isLoading } = useGetSummarizeQuery({
    articleId: article?.id ?? 0,
    actionId: selectedTaskId ?? 0,
    language: user?.contentLanguage.uiLanguage ?? 'en',
    enabled: !isInsufficientAnswers,
  });

  const {
    mutate: generateSummary,
    isLoading: isGenerating,
    isError: isGeneratingError,
    data: generatedSummary,
  } = useSummarizeMutation();

  useEffect(() => {
    if (!data) return;

    setSummary(data);
  }, [data]);

  useEffect(() => {
    if (!generatedSummary) return;

    setSummary(generatedSummary.data);
  }, [generatedSummary]);

  const userIds = actionAnswers?.slice(0, 3).map((answer) => answer.userId);

  const newAnswers =
    actionAnswersMeta && summary?.answersCount
      ? actionAnswersMeta?.total - (summary?.answersCount ?? 0)
      : 0;

  const handleGenerateSummary = () => {
    if (isInsufficientAnswers) return;

    generateSummary({
      articleId: article?.id ?? 0,
      actionId: selectedTaskId ?? 0,
      language: user?.contentLanguage.uiLanguage ?? 'en',
    });
  };

  const getActionButton = () => {
    if (isLoading || (newAnswers < 1 && summary)) {
      return <></>;
    }

    if (isGenerating) {
      return (
        <ActionButton>
          <Spinner className="w-3.5 h-3.5 animate-spin text-[#7C48D0] mr-1" />
          <GradientText text={t('Summarizing')} />
        </ActionButton>
      );
    }

    const buttonText = isGeneratingError
      ? t('Retry')
      : newAnswers > 0
        ? t('Update')
        : t('Summarize');

    return (
      <ActionButton
        onClick={() => handleGenerateSummary()}
        disabled={isInsufficientAnswers}
      >
        <Magicpen
          size={14}
          className={cx('mr-1', {
            'text-[#7C48D0]': !isInsufficientAnswers,
            'text-grayscale-secondary': isInsufficientAnswers,
          })}
        />
        <GradientText text={buttonText} disabled={isInsufficientAnswers} />
      </ActionButton>
    );
  };

  const getContent = () => {
    if (isInsufficientAnswers) {
      return (
        <div className="flex items-center px-4 text-xs text-gray-light-blue pt-3">
          {t('At least {{answers}} answers needed to enable Smart Summary', {
            answers: MINIMUM_REQUIRED_ANSWERS,
          })}
        </div>
      );
    }
    if (isGenerating || isLoading)
      return <GeneratingSkeleton isGenerating={isGenerating} />;
    if (isGeneratingError) return <Error />;

    if (summary && !isGenerating)
      return (
        <Summary
          summary={summary}
          expanded={!!generatedSummary}
          isNewSummary={!!generatedSummary}
        />
      );

    return (
      <div className="flex items-center px-4 text-xs text-gray-light-blue pt-3">
        <div className="flex relative mr-3">
          {userIds?.length &&
            userIds.map((id) => <UserAvatar userId={id} key={id} />)}
        </div>
        {actionAnswersMeta?.total} {t('answers to summarize')}
      </div>
    );
  };

  return (
    <div className="border-[3px] border-focus-background rounded-xl my-6">
      <div className="rounded-xl bg-gradient-to-b from-[#B549D0] to-[#7C48D0] p-px outline outline-gray-white">
        <div className="rounded-xl bg-gray-white-shadow py-4">
          <div className="flex items-center justify-between px-4">
            <h1>
              <span className="flex items-center">
                <Book1 size={20} className="pr-1" />
                <span className="font-bold text-sm">{t('Smart Summary')}</span>

                <span className="group relative">
                  <InfoCircle size={12} className="ml-1 text-gray-dark" />
                  <Tooltip className="!rounded !opacity-100 top-6 left-2 bg-gray-extra-dark absolute z-999 hidden group-hover:inline-block text-white text-sm px-4 py-2 w-[283px] whitespace-normal text-center -translate-x-1/2">
                    <div className="max-w-xs whitespace-normal text-center">
                      {t(
                        'This feature summarizes open-answer action responses by analyzing their sentiment and categorizing the feedback, allowing you to quickly understand the overall mood and key insights from each category.'
                      )}
                    </div>
                  </Tooltip>
                </span>
              </span>
            </h1>
            <div className="flex items-center text-10 text-gray-light-blue items-end gap-2.5">
              {newAnswers > 0 && (
                <div className="flex items-center gap-1">
                  <Refresh size={14} />{' '}
                  {t('Update to analyse {{newAnswers}} new answers', {
                    newAnswers,
                  })}
                </div>
              )}
              <div>{getActionButton()}</div>
            </div>
          </div>
          <div className="text-xs text-gray-light-blue px-4 pb-3">
            {t(
              'A summary gives you a quick overview of key themes and saves you time.'
            )}
          </div>
          <hr className="border-t border-gray-light " />
          {getContent()}
        </div>
      </div>
    </div>
  );
};

const Tooltip = styled.div`
  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 50%;
    border-width: 5px;
    border-style: solid;
    transform: translateX(-50%);
    border-color: transparent transparent ${config.colors['gray-extra-dark']}
      transparent;
  }
`;
export default SmartSummary;
