import { ArticleFilters } from 'app/pages/Articles/context';
import { NonNegativeNumber } from 'common-ui/utils/nonNegativeNumber';
import last from 'lodash/last';
import { useTranslation } from 'react-i18next';
import { InfiniteData, useInfiniteQuery } from 'react-query';
import { ArticlePage } from 'submodules/common-ui/generated/api/gcs/api';

import { resources, queryKeys } from '../index';

interface GetArticlesQueryProps {
  enabled?: boolean;
  filters: ArticleFilters;
  keepPreviousData?: boolean;
  nodeId?: string;
  page?: number;
  refetchOnWindowFocus?: boolean;
  onSuccess?: (data: InfiniteData<ArticlePage>) => void;
}

const useGetArticlesQuery = ({
  enabled,
  filters,
  keepPreviousData,
  nodeId,
  page = 0,
  refetchOnWindowFocus,
  onSuccess,
}: GetArticlesQueryProps) => {
  const {
    i18n: { language: userLanguage },
  } = useTranslation();
  const {
    data,
    isLoading,
    error,
    isFetching,
    isPreviousData,
    hasNextPage,
    refetch,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    queryKeys.articles(
      NonNegativeNumber.create(page).value,
      filters,
      nodeId,
      userLanguage
    ),
    ({ pageParam }) =>
      resources.getArticles(
        NonNegativeNumber.create(pageParam || page).value,
        filters,
        userLanguage
      ),
    {
      getNextPageParam: (lastPage) => lastPage.meta.currentPage + 1,
      enabled,
      keepPreviousData,
      refetchOnWindowFocus,
      onSuccess,
    }
  );

  return {
    data: data?.pages
      .map((pg) => pg.data)
      .flat()
      .map((item) => item)
      .flat(),
    meta: last(data?.pages)?.meta,
    isFetching,
    isLoading,
    isPreviousData,
    refetch,
    fetchNextPage,
    error,
    hasNextPage,
    isFetchingNextPage,
  };
};

export default useGetArticlesQuery;
