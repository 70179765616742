import { useNode } from '@craftjs/core';
import { css, cx } from '@emotion/css';
import { useSelector } from 'app/hooks';
import { selectors } from 'app/store/editor';
import { ComponentProps, useState } from 'react';
import {
  ActionTypeEnum,
  DbMultiChoiceAnswer,
  GetUserAnswers,
  MultiChoiceBlockRenderTypeEnum,
} from 'submodules/common-ui/generated/api/gcs';

import PollBlock from '../../../../Poll';
import ActionContainer from '../ActionContainer';
import PollAnswerOption from '../Answer/PollAnswerOption';

const PollAnswerViewerAction = () => {
  const { selectedProps } = useNode((node) => ({
    selectedProps: node.data.props as ComponentProps<typeof PollBlock>,
  }));

  const {
    title,
    description,
    choices = [],
    currentUserAnswers = [],
  } = selectedProps;

  const currentLanguage = useSelector(selectors.getActiveLanguage);

  const [selectedOptionsIds, setSelectedOptionsIds] = useState<number[]>([]);

  const handleOnOptionPress = (id: number) => {
    return setSelectedOptionsIds([id]);
  };

  const getChoicePercentage = (
    optionId: number,
    userAnswers: GetUserAnswers
  ) => {
    let count = 0;
    let percentage = 0;
    for (const eachAnswer of userAnswers) {
      const answer = eachAnswer.answer as DbMultiChoiceAnswer;
      const answerId = answer.find((asw) => asw.id === optionId)?.id;
      if (answerId === optionId) count++;
      percentage = (count / answer.length) * 100;
    }

    if (Number.isInteger(percentage)) return percentage;
    return Number(percentage.toFixed(2));
  };

  return (
    <ActionContainer
      isMandatory={selectedProps.mandatory}
      canAnswer={true}
      type={ActionTypeEnum.MultiChoice}
      renderType={MultiChoiceBlockRenderTypeEnum.Poll}
    >
      <div className="flex flex-col">
        <span
          className={cx('text-lg font-bold', css('word-break: break-word;'))}
        >
          {title}
        </span>
        <span className={cx('text-black', css('word-break: break-word;'))}>
          {description}
        </span>
      </div>

      <div className="flex flex-col gap-2 mt-4">
        {choices.map((choice) => {
          const variant = choice.variants[currentLanguage];
          return (
            <PollAnswerOption
              imageUri={variant.image}
              canAnswer={false}
              isSelected={selectedOptionsIds.includes(choice.id)}
              onClick={() => handleOnOptionPress(choice.id)}
              text={variant.answer}
              key={choice.id}
              choicePercentage={getChoicePercentage(
                choice.id,
                currentUserAnswers
              )}
            />
          );
        })}
      </div>
    </ActionContainer>
  );
};

export default PollAnswerViewerAction;
