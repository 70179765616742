import { cx } from '@emotion/css';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';

import SubmitAndCancelButton from '../SubmitAndCancelButton';

import { SuccessModalProps } from '.';

const SuccessBody = ({
  title,
  description,
  cancelLabel,
  onCancel,
  onClose,
  onSubmit,
  submitLabel,
  options,
  headerClassName,
}: SuccessModalProps) => {
  const isSubmitVisible = cancelLabel && onCancel && onSubmit && submitLabel;

  return (
    <>
      <div className="flex relative items-center justify-center">
        <div
          className={cx(
            'flex flex-col items-center text-center',
            headerClassName
          )}
        >
          <span className="text-[40px]">&#10024;</span>
          <span className="font-bold text-lg mt-2">{title}</span>
          <span className="mt-2">{description}</span>
        </div>
        {onClose && (
          <button
            className="h-6 w-6 text-grayscale-secondary absolute right-0 top-0"
            onClick={onClose}
          >
            <CloseLineIcon />
          </button>
        )}
      </div>
      {options && (
        <div className="flex flex-col items-center justify-center mt-3">
          {options.map(({ Icon, text }) => (
            <div className="flex items-center mb-1" key={text}>
              <Icon size={20} className="mr-2 text-grayscale-secondary" />
              <span className="text-sm">{text}</span>
            </div>
          ))}
        </div>
      )}
      {isSubmitVisible && (
        <SubmitAndCancelButton
          className="mt-7"
          cancelLabel={cancelLabel}
          submitLabel={submitLabel}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      )}
    </>
  );
};

export default SuccessBody;
