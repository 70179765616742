import { useArticlesTranslation } from 'app/internationalization/hooks';
import { Chart2 } from 'iconsax-react';
import { useState } from 'react';

import BlockOption from './BlockOption';
import Prompt from './Prompt';

const BlockOptions = ({ onClose }: { onClose: () => void }) => {
  const { t } = useArticlesTranslation();
  const [selectedBlock, setSelectedBlock] = useState<string | undefined>();

  const options = [
    {
      Icon: Chart2,
      text: t('Generate Quiz'),
      onClick: () => setSelectedBlock('quiz'),
    },
  ];

  return (
    <>
      {selectedBlock && (
        <Prompt
          selectedBlock={selectedBlock}
          goBack={() => setSelectedBlock(undefined)}
          onClose={onClose}
        />
      )}
      {!selectedBlock && (
        <div className="translate-x-[-36px] absolute bottom-3 right-0 w-xl max-w-screen text-grayscale-primary bg-white border border-focus-background rounded-xl shadow-block">
          {options.map((option, index) => (
            <BlockOption key={index} {...option} />
          ))}
          <div className="text-gray-light-blue text-sm border-t border-gray-silver-blue p-4">
            🪄 {t('What would you like to do?')}
          </div>
        </div>
      )}
    </>
  );
};

export default BlockOptions;
