import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { ActionTypeEnum } from 'submodules/common-ui/generated/api/gcs';

import SmartSummary from '../../SmartSummary';
import { useAnswersContext } from '../context';

import ChangeLayoutButtons from './ChangeLayoutButtons';
import Header from './Header';
import InstancePicker from './InstancePicker';
import ListDeepDiveAnswers from './ListDeepDiveAnswers';
import SortButton from './SortButton';

const DeepDive = () => {
  const {
    selectedAudiences,
    selectedTaskId,
    locations,
    actionAnswersMeta,
    article,
  } = useAnswersContext();
  const [showSmartSummary, setShowSmartSummary] = useState(false);

  useEffect(() => {
    const selectedTaskBlock = article?.blocks.find(
      (block) =>
        block.id === selectedTaskId &&
        (Object.values(ActionTypeEnum) as string[]).includes(block.type)
    );

    if (!actionAnswersMeta || selectedAudiences.length !== 0 || locations) {
      return;
    }
    if (selectedTaskBlock?.type !== ActionTypeEnum.OpenQuestionTask) return;

    setShowSmartSummary(true);
  }, [
    actionAnswersMeta,
    article?.blocks,
    locations,
    selectedAudiences.length,
    selectedTaskId,
  ]);

  return (
    <DeepDiveContainer>
      <Header />
      {showSmartSummary && <SmartSummary />}
      <FiltersContainer>
        <SortButton />
        <ChangeLayoutButtons />
      </FiltersContainer>
      <InstancePicker />
      <ListDeepDiveAnswers />
    </DeepDiveContainer>
  );
};

const DeepDiveContainer = styled.div`
  width: 804px;
  height: 100%;
  padding: 24px;
  border-radius: 36px;
`;

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 12px;
`;

export default DeepDive;
