import useUserQuery from 'app/pages/profiles/PublicProfile/useUserQuery';

interface UserAvatarProps {
  userId: number;
}

const UserAvatar = ({ userId }: UserAvatarProps) => {
  const { data: user, isLoading: isLoadingUser } = useUserQuery({ id: userId });

  if (isLoadingUser) return <></>;

  return (
    <img
      className="rounded object-cover h-6 w-6 shadow-blue -mr-1"
      src={user?.avatars?.small}
      alt="User's avatar"
    />
  );
};

export default UserAvatar;
