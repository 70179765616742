import { cx } from '@emotion/css';
import { FileType } from 'app/utils/types';
import AudioTypeIcon from 'assets/icons/file-type-audio.svg?react';
import DocumentTypeIcon from 'assets/icons/file-type-document.svg?react';
import ImageTypeIcon from 'assets/icons/file-type-image.svg?react';
import OtherTypeIcon from 'assets/icons/file-type-others.svg?react';
import PdfTypeIcon from 'assets/icons/file-type-pdf.svg?react';
import SheetTypeIcon from 'assets/icons/file-type-sheet.svg?react';
import VideoTypeIcon from 'assets/icons/file-type-video.svg?react';
import React from 'react';
import FilePlaceholderIcon from 'remixicon-react/File2LineIcon';
import ExcelIcon from 'remixicon-react/FileExcel2LineIcon';
import ImageIcon from 'remixicon-react/FileGifLineIcon';
import PdfIcon from 'remixicon-react/FilePdfLineIcon';
import PowerPointIcon from 'remixicon-react/FilePpt2LineIcon';
import WordIcon from 'remixicon-react/FileWord2LineIcon';

interface FileIconProps {
  type: FileType;
  updated?: boolean;
}

const FileIcon: React.FC<FileIconProps> = ({ updated, type }) => {
  const baseClassName = updated ? 'min-w-8' : 'file_icon w-6 h-6';
  const textFocus = 'text-focus';

  if (updated) {
    switch (type) {
      case 'pdf':
        return <PdfTypeIcon className={baseClassName} />;
      case 'audio':
        return <AudioTypeIcon className={baseClassName} />;
      case 'image':
        return <ImageTypeIcon className={baseClassName} />;
      case 'video':
        return <VideoTypeIcon className={baseClassName} />;
      case 'word':
        return <DocumentTypeIcon className={baseClassName} />;
      case 'excel':
        return <SheetTypeIcon className={baseClassName} />;
      default:
        return <OtherTypeIcon className={baseClassName} />;
    }
  }

  switch (type) {
    case 'pdf':
      return <PdfIcon className={cx(baseClassName, 'text-error')} />;
    case 'excel':
      return <ExcelIcon className={cx(baseClassName, 'text-greenExcel')} />;
    case 'word':
      return <WordIcon className={cx(baseClassName, textFocus)} />;
    case 'image':
      return <ImageIcon className={cx(baseClassName, textFocus)} />;
    case 'powerpoint':
      return <PowerPointIcon className={cx(baseClassName, 'text-error')} />;
    default:
      return <FilePlaceholderIcon className={cx(baseClassName, textFocus)} />;
  }
};

export default FileIcon;
