import config from 'app/config';
import { ImgHTMLAttributes } from 'react';
import { DirectUrls } from 'submodules/common-ui/generated/api/gcs/api';

interface ResponsiveImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  directUrls?: DirectUrls;
  external?: boolean;
  chat?: boolean;
  large?: number;
  medium?: number;
  small?: number;
}

const ResponsiveImage = ({
  alt,
  directUrls,
  external,
  large,
  medium,
  small,
  src,
  chat = false,
  ...restProps
}: ResponsiveImageProps) => {
  const addSrc = (source?: string) => {
    return (img: HTMLImageElement) => {
      if (!img) return;
      img.src = source ?? '';
    };
  };

  if (!src && !directUrls) return <></>;

  const encodedSrc = encodeURI(src || '');

  if (
    external ||
    encodedSrc.startsWith('blob:') ||
    encodedSrc.startsWith('data:')
  ) {
    return <img alt={alt} src={src} {...restProps} />;
  }

  if (large === undefined && medium === undefined && small === undefined) {
    return (
      <picture>
        {directUrls?.large?.url && (
          <source
            media={`(min-width: ${config.screenSizes.sm})`}
            srcSet={directUrls.large.url}
          />
        )}
        {directUrls?.medium?.url && (
          <source
            media={`(min-width: ${config.screenSizes.xs})`}
            srcSet={directUrls.medium.url}
          />
        )}
        {directUrls?.small?.url && (
          <source media={`(min-width: 0px)`} srcSet={directUrls.small.url} />
        )}
        <img
          ref={addSrc(directUrls?.original?.url ?? src)}
          alt={alt}
          {...restProps}
        />
      </picture>
    );
  }

  return (
    <picture>
      {large !== undefined && large >= 0 && (
        <source
          media={`(min-width: ${large}px)`}
          srcSet={
            directUrls?.large?.url ?? `${chat ? src : encodedSrc}?size=large`
          }
        />
      )}
      {medium !== undefined && medium >= 0 && (
        <source
          media={`(min-width: ${medium}px)`}
          srcSet={
            directUrls?.medium?.url ?? `${chat ? src : encodedSrc}?size=medium`
          }
        />
      )}
      {small !== undefined && small >= 0 && (
        <source
          media={`(min-width: ${small}px)`}
          srcSet={
            directUrls?.small?.url ?? `${chat ? src : encodedSrc}?size=small`
          }
        />
      )}
      <img
        ref={addSrc(directUrls?.original?.url ?? src)}
        alt={alt}
        {...restProps}
      />
    </picture>
  );
};

export default ResponsiveImage;
