/* eslint-disable no-console */

import { EnvConfig } from './types';
import { isLogLevelKey, isValidEnvironment } from './utils';

const tenantApiUrl = import.meta.env.REACT_APP_TENANT_API_URL;
const pushNotificationsApiUrl = import.meta.env
  .REACT_APP_PUSH_NOTIFICATION_API_URL;
const gcsApiUrl = import.meta.env.REACT_APP_GCS_API_URL;
const privacyPolicyUrl = import.meta.env.REACT_APP_PRIVACY_POLICY_URL;
const sentryDns = import.meta.env.REACT_APP_SENTRY_DNS;
const sentryEnv = import.meta.env.REACT_APP_SENTRY_ENVIRONMENT;
const chatUri = import.meta.env.REACT_APP_CHAT_URI;
const logLevel = import.meta.env.REACT_APP_LOG_LEVEL;
const nikeSKUUri = import.meta.env.REACT_APP_NIKE_SKU_URI;
const nikeSkuApiUrlTemplate = import.meta.env
  .REACT_APP_NIKE_SKU_API_URL_TEMPLATE;
const appEnv = import.meta.env.REACT_APP_ENVIRONMENT;
const nikeSkuEncryptionKey = import.meta.env.REACT_APP_NIKE_SKU_ENCRYPTION_KEY;
const identityServerUri = import.meta.env.REACT_APP_IDENTITY_SERVER_URI;
const identityServerClientId = import.meta.env
  .REACT_APP_IDENTITY_SERVER_CLIENT_ID;
const silentRefreshTokenTimeInSeconds = import.meta.env
  .REACT_APP_SILENT_REFRESH_TOKEN_TIME_IN_SECONDS;
const identityServerScopes = import.meta.env.REACT_APP_IDENTITY_SERVER_SCOPES;
const atticusUrl = import.meta.env.REACT_APP_ATTICUS_URL;
const sisenseUrl = import.meta.env.REACT_APP_SISENSE_URL;
const sisenseToken = import.meta.env.REACT_APP_SISENSE_TOKEN;
const giphyToken = import.meta.env.REACT_APP_GIPHY_TOKEN;
const subdomainApiUrl = import.meta.env.REACT_APP_SUBDOMAIN_API_URI;
const bitmovinKey = import.meta.env.REACT_APP_BITMOVIN_KEY;
const subdomainPublicAssetsUrl = import.meta.env
  .REACT_APP_SUBDOMAIN_PUBLIC_ASSETS_URI;
const vapidKey = import.meta.env.REACT_APP_VAPID_KEY;
const microsoftClarityProjectId = import.meta.env
  .REACT_APP_MICROSOFT_CLARITY_PROJECT_ID;
const aiServiceApiUrl = import.meta.env.REACT_APP_AI_SERVICE_API_URL;
const appInsightsKey = import.meta.env.REACT_APP_INSIGHTS_KEY;
const appVersion = import.meta.env.REACT_APP_VERSION;

if (!tenantApiUrl) throw new Error('REACT_APP_TENANT_API_URL is not set');
if (!gcsApiUrl) throw new Error('REACT_APP_GCS_API_URL is not set');
if (!pushNotificationsApiUrl)
  throw new Error('REACT_APP_PUSH_NOTIFICATION_API_URL is not set');
if (!privacyPolicyUrl)
  throw new Error('REACT_APP_PRIVACY_POLICY_URL is not set');
if (!sentryEnv) throw new Error('REACT_APP_SENTRY_ENVIRONMENT is not set');
if (!chatUri) throw new Error('REACT_APP_CHAT_URI is not set');
if (!nikeSKUUri) throw new Error('REACT_APP_NIKE_SKU_URI is not set');
if (!nikeSkuApiUrlTemplate)
  throw new Error('REACT_APP_NIKE_SKU_API_URL_TEMPLATE is not set');
if (!appEnv) throw new Error('REACT_APP_ENVIRONMENT is not set');
if (!nikeSkuEncryptionKey)
  throw new Error('REACT_APP_NIKE_SKU_ENCRYPTION_KEY is not set');
if (!identityServerUri)
  throw new Error('REACT_APP_IDENTITY_SERVER_URI is not set');
if (!identityServerClientId)
  throw new Error('REACT_APP_IDENTITY_SERVER_CLIENT_ID is not set');
if (!silentRefreshTokenTimeInSeconds)
  throw new Error('REACT_APP_SILENT_REFRESH_TOKEN_TIME_IN_SECONDS is not set');
if (!identityServerScopes)
  throw new Error('REACT_APP_IDENTITY_SERVER_SCOPES is not set');
if (!atticusUrl) throw new Error('REACT_APP_ATTICUS_URL is not set');
if (!sisenseUrl) throw new Error('REACT_APP_SISENSE_URL is not set');
if (!sisenseToken) throw new Error('REACT_APP_SISENSE_TOKEN is not set');
if (!giphyToken) throw new Error('REACT_APP_GIPHY_TOKEN is not set');
if (!bitmovinKey) throw new Error('REACT_APP_BITMOVIN_KEY is not set');
if (!aiServiceApiUrl)
  throw new Error('REACT_APP_AI_SERVICE_API_URL is not set');
if (!subdomainApiUrl) throw new Error('REACT_APP_SUBDOMAIN_API_URI is not set');
if (!bitmovinKey) throw new Error('REACT_APP_BITMOVIN_KEY is not set');
if (!appInsightsKey) throw new Error('REACT_APP_INSIGHTS_KEY is not set');
if (!subdomainPublicAssetsUrl)
  throw new Error('REACT_APP_SUBDOMAIN_PUBLIC_ASSETS_URI is not set');
if (!vapidKey) throw new Error('REACT_APP_VAPID_KEY is not set');

if (!isValidEnvironment(appEnv)) {
  throw new Error(`REACT_APP_ENVIRONMENT has unknown value: ${appEnv}`);
}

if (!logLevel || !isLogLevelKey(logLevel)) {
  throw new Error(
    `REACT_APP_LOG_LEVEL unknown value or undefined: ${logLevel}`
  );
}

const env: EnvConfig = {
  tenantApiUrl,
  gcsApiUrl,
  pushNotificationsApiUrl,
  privacyPolicyUrl,
  sentryDns,
  sentryEnv,
  chatUri,
  logLevel,
  nikeSKUUri,
  nikeSkuApiUrlTemplate,
  appEnv,
  nikeSkuEncryptionKey,
  identityServerUri,
  identityServerClientId,
  identityServerScopes,
  silentRefreshTokenTimeInSeconds,
  atticusUrl,
  sisenseUrl,
  sisenseToken,
  giphyToken,
  subdomainApiUrl,
  bitmovinKey,
  subdomainPublicAssetsUrl,
  vapidKey,
  microsoftClarityProjectId,
  aiServiceApiUrl,
  appInsightsKey,
  appVersion,
};

export default env;
