/* eslint-disable import/prefer-default-export */

import { RootState } from '../types';

import { NAME } from './constants';
import { SidebarState } from './types';

export const getIsSidebarOpen = (state: RootState) =>
  state[NAME].isSideMenuOpen;

export const getIsFullPage = (state: RootState) => state[NAME].isFullPage;

export const getCustomLink = (state: RootState) => state[NAME].customLink;

export const getIsMobilePwaPopupHidden = (state: RootState) =>
  state[NAME].mobilePwaPopupHidden;

export const isSearchOpen = (state: RootState) => state[NAME].isSearchOpen;

export const tabsHidden = (state: RootState) => state[NAME].tabsHidden;

export const selectSideMenuModulesState = (state: RootState): SidebarState =>
  state[NAME].sidebarState;
