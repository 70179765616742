import styled from '@emotion/styled';
import config from 'app/config';
import { Calendar, RepeatCircle } from 'iconsax-react';

interface Props {
  className?: string;
}

const RecurringCalendarIcon = ({ className }: Props) => (
  <div className={className}>
    <Calendar size={16} className="text-grayscale-secondary" />
    <StyledRepeatCircle
      size={10}
      className="absolute top-0 left-1.5 bg-grayscale-secondary rounded-full [&_path]:stroke-[white]"
    />
  </div>
);

const StyledRepeatCircle = styled(RepeatCircle)`
  path:last-child {
    stroke: ${config.colors['grayscale-secondary']};
  }
`;

export default RecurringCalendarIcon;
