import { cx } from '@emotion/css';
import { ArrowDown, ArrowUp } from 'iconsax-react';
import { FC, useState } from 'react';

interface Props {
  title: string;
  description: string;
}

const AnswerTitle: FC<Props> = ({ description, title }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="rounded-xl p-2 gap-1 w-full border border-hover-blue hover:border-focus-background flex flex-col bg-grayscale-bg-dark relative group">
      <span
        className={cx('text-sm text-primary font-bold', {
          truncate: !isExpanded,
          'break-words': isExpanded,
        })}
      >
        {title}
      </span>
      <span
        className={cx('text-xs text-grayscale-secondary', {
          truncate: !isExpanded,
          'break-words': isExpanded,
        })}
      >
        {description}
      </span>
      <button
        className="w-4 h-4 rounded-full bg-hover-blue absolute flex justify-center items-center -bottom-2 mx-auto left-0 right-0 invisible group-hover:visible"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {isExpanded ? (
          <ArrowUp className="h-2 w-2" />
        ) : (
          <ArrowDown className="h-2 w-2" />
        )}
      </button>
    </div>
  );
};

export default AnswerTitle;
