import { cx, css } from '@emotion/css';
import { SystemModules } from 'app/api/auth/constants';
import useAuthenticatedUser from 'app/api/auth/hooks/useAuthenticatedUser';
import useChannels from 'app/api/channels/hooks/useChannels';
import { NotificationFilter } from 'app/api/notifications/constants';
import { useNotificationsQuery } from 'app/api/notifications/hooks';
import { useSelector } from 'app/hooks';
import useLoginMethod from 'app/hooks/useLoginMethod';
import { useModulesTranslation } from 'app/internationalization/hooks';
import routes from 'app/router/routes';
import { actions, selectors } from 'app/store/navigation';
import ChaptersIcon from 'assets/icons/chapters.svg?react';
import {
  Clipboard,
  Messages2,
  Shop,
  Notification,
  DocumentText1,
} from 'iconsax-react';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { matchPath, useLocation, NavLink } from 'react-router-dom';
import LikeIcon from 'remixicon-react/Heart3LineIcon';

import {
  SIDEBAR_ANIMATION_DURATION,
  SIDEBAR_ANIMATION_TRANSLATION,
} from '../Navigation/types';
import NotificationsIcon from '../TopMenu/NotificationsIcon';
export const BOTTOM_MENU_HEIGHT = '55px';

const MobileNavigation = () => {
  const { t } = useModulesTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const { data: user } = useAuthenticatedUser();
  const { channels } = useChannels();
  const { isEasyAccessLogin } = useLoginMethod();

  const feedChannel = channels.find((channel) => channel.shownAs === 'feed');

  const channelIsFeedDefault = useMemo(() => {
    const match = matchPath(routes.relevantChannel.value, location.pathname);

    if (!match) return false;
    if (Number.isNaN(match.params.id)) return false;

    return channels.some(
      (channel) =>
        channel.id === feedChannel?.id &&
        channel.id === Number(match.params.id) &&
        channel.shownAs === 'feed'
    );
  }, [channels, feedChannel?.id, location.pathname]);

  const toggleSidebar = () => dispatch(actions.sideMenuDisplayChanged(false));

  const isSidebarVisible = useSelector(selectors.getIsSidebarOpen);

  const { data: notifications } = useNotificationsQuery(
    NotificationFilter.unread,
    Boolean(user && !user.hiddenModules?.[SystemModules.Notifications])
  );

  const defaultFeedChannel = routes.relevantChannel.create(
    feedChannel?.id ?? 0
  );

  const newsEnabled =
    !user?.hiddenModules?.[SystemModules.News] &&
    !user?.discontinuedModules?.[SystemModules.News];

  const homeEnabled =
    user?.hiddenModules?.[SystemModules.News] ||
    user?.discontinuedModules?.[SystemModules.News];

  const socialEnabled =
    !user?.hiddenModules?.[SystemModules.Social] &&
    !user?.discontinuedModules?.[SystemModules.Social];

  const links = [
    {
      title: t('Home'),
      Icon: Shop,
      to: routes.home.create(),
      isActive: (currentRoute: string) =>
        currentRoute.includes(routes.home.create()),
      enabled: homeEnabled,
      isEasyAccessLoginAccessible: true,
    },
    {
      title: t('News'),
      Icon: DocumentText1,
      to: routes.newsFeed.create(),
      isActive: (currentRoute: string) =>
        currentRoute.includes(routes.newsFeed.create()),
      enabled: newsEnabled,
      isEasyAccessLoginAccessible: false,
    },
    {
      title: t('Social Feed'),
      Icon: LikeIcon,
      to: routes.socialFeed.create(),
      isActive: (currentRoute: string) =>
        currentRoute.includes(routes.socialFeed.create()),
      enabled: socialEnabled,
      isEasyAccessLoginAccessible: false,
    },
    {
      title: t('Chat'),
      Icon: Messages2,
      to: routes.chat.create(),
      moduleKey: SystemModules.Messenger,
      isActive: (currentRoute: string) =>
        currentRoute.includes(routes.chat.create()),
      enabled: true,
      isEasyAccessLoginAccessible: false,
    },
    {
      title: t('Channels'),
      Icon: ChaptersIcon,
      to: routes.relevantChannels.create(),
      isActive: (currentRoute: string) =>
        currentRoute.includes(routes.relevantChannels.create()),
      enabled: homeEnabled,
      isEasyAccessLoginAccessible: true,
    },
    {
      title: t('Feed'),
      Icon: Clipboard,
      to: defaultFeedChannel,
      enabled: feedChannel?.id && homeEnabled,
      isActive: (currentRoute: string) =>
        currentRoute.includes('/relevant-channel/') && channelIsFeedDefault,
      isEasyAccessLoginAccessible: false,
    },
    {
      title: t('Notifications'),
      Icon: Notification,
      to: routes.notifications.create(),
      enabled: true,
      isActive: (currentRoute: string) =>
        currentRoute.includes(routes.notifications.create()),
      isEasyAccessLoginAccessible: false,
    },
  ];

  const tabbedRouteActive = links.some(({ isActive }) =>
    isActive(location.pathname)
  );

  useEffect(() => {
    dispatch(actions.setTabsHidden(!tabbedRouteActive));
  }, [dispatch, tabbedRouteActive]);

  if (!user || !tabbedRouteActive) return null;

  return (
    <div
      className={cx(
        'fixed bottom-0 w-full z-50 shadow-card bg-white',
        isSidebarVisible
          ? SIDEBAR_ANIMATION_TRANSLATION
          : SIDEBAR_ANIMATION_DURATION,
        css(`padding-bottom: env(safe-area-inset-bottom);`)
      )}
    >
      <div
        className={cx(
          'flex items-center justify-evenly  w-full bg-white',
          css(`height: ${BOTTOM_MENU_HEIGHT};`)
        )}
      >
        {links.map((link) => {
          const { title, Icon, to, isActive, enabled } = link;

          const notificationRoute = to.includes(routes.notifications.create());

          if (!enabled) return;
          if (isEasyAccessLogin && !link.isEasyAccessLoginAccessible) return;
          if (
            notificationRoute &&
            user.hiddenModules?.[SystemModules.Notifications]
          )
            return;

          return (
            <NavLink
              className={cx(
                'flex flex-col items-center justify-center w-1/5 h-full border-t-2',
                {
                  'border-t-focus': isActive(location.pathname),
                  'border-t-transparent': !isActive(location.pathname),
                }
              )}
              key={to}
              to={to}
              onClick={() => {
                navigator?.vibrate?.(50);
                toggleSidebar();
              }}
            >
              {notificationRoute ? (
                <NotificationsIcon
                  isActive={isActive(location.pathname)}
                  unreadPosition="left"
                  hasUnread={
                    isActive(location.pathname)
                      ? false
                      : Boolean(notifications?.find(({ read }) => !read))
                  }
                  iconClassName={cx({
                    'text-grayscale-secondary': !isActive(location.pathname),
                  })}
                />
              ) : (
                <Icon
                  {...(isActive(location.pathname) && { variant: 'Bold' })}
                  className={cx('w-6 h-6', {
                    'text-grayscale-secondary': !isActive(location.pathname),
                    'text-focus fill-current [&_path]:fill-current': isActive(
                      location.pathname
                    ),
                  })}
                />
              )}

              <span
                className={cx('text-10 line-clamp-1 mt-1', {
                  'text-grayscale-secondary': !isActive(location.pathname),
                  'text-focus fill-current': isActive(location.pathname),
                })}
              >
                {title}
              </span>
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};

export default MobileNavigation;
