const BlockTypes = {
  ImageBlock: 'ImageBlock',
  VideoBlock: 'VideoBlock',
  GiphyBlock: 'GiphyBlock',
  TextBlock: 'TextBlock',
  CoverImageBlock: 'CoverImageBlock',
  TitleBlock: 'TitleBlock',
  UrlBlock: 'UrlBlock',
  PDFBlock: 'PDFBlock',
  SimpleTaskBlock: 'SimpleTask',
  QuizBlock: 'QuizBlock',
  ArticleLinkBlock: 'ArticleLinkBlock',
  FileBlock: 'FileBlock',
  YesNoNaBlock: 'YesNoNaBlock',
  PollBlock: 'PollBlock',
  ScormBlock: 'ScormBlock',
  CategoriesBlock: 'CategoriesBlock',
  HeadingSectionBlock: 'HeadingSectionBlock',
  DraggableContainer: 'DraggableContainer'
};

export default BlockTypes;
