import { VerticalChevron } from 'app/components';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { ArrangeVertical } from 'iconsax-react';

import { useAnswersContext } from '../context';

const SortButton = () => {
  const { t } = useArticlesTranslation();
  const {
    sortScore,
    setSortScore,
    sortCreatedAt,
    setSortCreatedAt,
    selectedTaskId,
    article,
  } = useAnswersContext();

  const selectedTaskBlock = article?.blocks.find(
    (block) => block.id === selectedTaskId
  );

  const isMultiChoice =
    selectedTaskBlock?.type === 'multi_choice' &&
    selectedTaskBlock?.renderType !== 'poll';

  const [currentSort, setCurrentSort] = isMultiChoice
    ? [sortScore, setSortScore]
    : [sortCreatedAt, setSortCreatedAt];

  const toggleSort = () => {
    const newSortOrder = currentSort === 'desc' ? 'asc' : 'desc';
    setCurrentSort(newSortOrder);
  };

  const sortingText = isMultiChoice
    ? currentSort === 'desc'
      ? t('Score (high to low)')
      : t('Score (low to high)')
    : currentSort === 'desc'
      ? t('Most recent')
      : t('Least recent');

  return (
    <button
      type="button"
      className="flex items-center justify-between h-12 ml-auto p-3 text-focus text-sm bg-hover-blue rounded-xl"
      onClick={toggleSort}
    >
      <ArrangeVertical className="mr-1.5" size={16} variant="Bold" />
      <span className="whitespace-nowrap">{sortingText}</span>
      <VerticalChevron
        className="ml-4"
        size={16}
        open={currentSort === 'asc'}
      />
    </button>
  );
};

export default SortButton;
