const removeHashFromUrlIfImmediate = (url: string): string => {
  try {
    // Directly check if the URL ends with a lone hash i.e. https://app.atobi.io/#
    if (url.endsWith('#')) {
      url = url.slice(0, -1); // Remove the trailing hash
    }
    let urlObj = new URL(url);

    // Check if the hash is immediately after the hostname or has additional path, or is a lone hash
    if (urlObj.pathname === '/' && urlObj.hash) {
      // Construct new URL without the hash and avoiding double slashes or trailing slash for lone hash
      const newPath = urlObj.hash.replace(/^#\//, ''); // Remove '#/' or leave empty
      const newUrl = newPath ? `${urlObj.origin}/${newPath}` : urlObj.origin; // Ensure no double slashes or trailing slash
      urlObj = new URL(newUrl);
    }

    return urlObj.toString();
  } catch (error) {
    return url; // Return the original URL if invalid
  }
};

export default removeHashFromUrlIfImmediate;
