import { cx } from '@emotion/css';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import useOpenVertically from 'app/pages/ArticleEditor/hooks/useOpenVertically';
import { InfoCircle, Profile2User, Shop } from 'iconsax-react';
import { useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import ReactTooltip from 'react-tooltip';
import CheckLineIcon from 'remixicon-react/CheckLineIcon';

interface CompletionProps {
  required?: number;
  disabled?: boolean;
  onClick(value: number): void;
}

const Completion = ({ disabled, required, onClick }: CompletionProps) => {
  const { t } = useArticlesTranslation();
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOpenVertically(dropdownRef, open);

  const options = [
    {
      id: -1,
      text: (
        <Trans
          components={{ bold: <span className="font-bold" /> }}
          i18nKey="articles.All members in audience"
        />
      ),
      Icon: Profile2User,
      click: () => onClick(-1),
    },
    {
      id: 1,
      text: (
        <Trans
          components={{ bold: <span className="font-bold" /> }}
          i18nKey="articles.One member per location"
        />
      ),
      Icon: Shop,
      click: () => onClick(1),
    },
  ];

  useEffect(() => {
    if (open) ReactTooltip.rebuild();
  }, [open]);

  const toggleDropdown = () => {
    if (disabled) return;
    setOpen((prevOpen) => !prevOpen);
  };

  const iconClassName = cx({
    'text-gray-dark': open,
    'group-hover/icon:text-grayscale-secondary cursor-pointer': !disabled,
  });

  return (
    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
      <div className="relative leading-none">
        <button
          className={cx('relative h-5 w-5 group/icon', {
            'cursor-auto': disabled,
          })}
          onClick={toggleDropdown}
        >
          {required === 1 ? (
            <Shop size={20} className={iconClassName} />
          ) : (
            <Profile2User size={20} className={iconClassName} />
          )}
        </button>
        {open && (
          <div
            ref={dropdownRef}
            className="absolute bg-white rounded-lg z-50 shadow-lg py-1 border border-gray-light bg-clip-padding left-0 top-7 transform -translate-y-2 shadow-atobi overflow-hidden"
          >
            <div className="flex items-center p-3">
              <span className="text-xs text-grayscale-primary font-bold">
                {t('Required completion by')}
              </span>
              <a
                target="_blank"
                rel="noroferrer noreferrer"
                href="https://help.atobi.io"
              >
                <InfoCircle
                  size={16}
                  className="ml-2 text-gray-dark"
                  data-tip={t('Help Center')}
                  data-for="requiredHelp"
                />
              </a>
            </div>
            {options.map((option, index) => {
              const { id, text, Icon, click } = option;
              return (
                <button
                  key={index}
                  className={cx(
                    'text-xs w-full p-3 font-normal flex items-center whitespace-nowrap bg-transparent hover:bg-hover-blue text-grayscale-primary',
                    { 'mb-1.5': index < options.length - 1 }
                  )}
                  onClick={() => click()}
                >
                  <Icon size={16} className="text-grayscale-secondary mr-2" />
                  <span className="mr-2">{text}</span>

                  <CheckLineIcon
                    size={16}
                    className={cx('text-focus ml-auto', {
                      invisible: required !== id,
                    })}
                  />
                </button>
              );
            })}
          </div>
        )}
      </div>
      <ReactTooltip
        place="top"
        effect="solid"
        class="react-tooltip"
        id="requiredHelp"
      />
    </OutsideClickHandler>
  );
};

export default Completion;
