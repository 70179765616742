import useGetSettingsQuery from 'app/api/pwaSettings/hooks/useGetSettingsQuery';
import { PageLoader, RequestError } from 'app/components';
import isIframed from 'app/utils/isIframed';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const IframedCheckHandler = ({ children }: Props) => {
  const { data: setting, isLoading } = useGetSettingsQuery({
    enabled: true,
  });

  if (!isIframed) {
    return <>{children}</>;
  }

  if (isLoading) {
    return <PageLoader />;
  }

  const allowedIframed = setting?.allowedIframeUrls?.includes(
    document.location.ancestorOrigins[0]
  );

  if (allowedIframed) {
    return <>{children}</>;
  }

  return <RequestError status="iframeNotAllowed" />;
};
export default IframedCheckHandler;
