import { NodeData, useEditor } from '@craftjs/core';
import { css, cx } from '@emotion/css';
import styled from '@emotion/styled';
import useGetAudiencesQuery from 'app/api/audiences/hooks/useAudiencesQuery';
import { Switch } from 'app/components';
import { useDispatch, useSelector } from 'app/hooks';
import useAppSelector from 'app/hooks/useSelector';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import ArticleDetailsLanguages from 'app/pages/Articles/Components/ArticleDetails/ArticleDetailsLanguages';
import { RecurringArticleDate } from 'app/pages/Articles/Components/RecuringArticleModal/types';
import useArticleActionBlocks from 'app/pages/Articles/hooks/useArticleActionBlocks';
import useGetCategoryName from 'app/pages/Editor/hooks/useGetCategoryName';
import useRecurringDateAsSchedule from 'app/pages/Editor/hooks/useRecurringDateAsSchedule';
import { actions, selectors } from 'app/store/editor';
import dayjs from 'dayjs';
import { MessageEdit } from 'iconsax-react';
import isEqual from 'lodash/isEqual';
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  Article,
  ArticleShownAs,
  ArticleStatusEnum,
  Channel,
} from 'submodules/common-ui/generated/api/gcs';

import Datepicker from '../Datepicker';
import RecurringAction from '../RecurringAction';
import SelectedOptions from '../SelectedOptions';

import useBlockSchedulingInfo from './useBlockSchedulingInfo';

interface Props {
  goToStep: (step: number) => void;
  cancelSchedule: VoidFunction;
  article?: Article;
  isSubmitDisabled: boolean;
  toggleRecuring: boolean;
  toggleSchedule: boolean;
  setToggleSchedule: Dispatch<SetStateAction<boolean>>;
  recurringDate?: RecurringArticleDate;
  update: VoidFunction;
  updateBlockAudiences: (audiencesId: number[]) => void;
  setToggleRecurring: (value: boolean) => void;
  getTasks: () => NodeData[];
}

const ThirdStepForm: FC<Props> = ({
  goToStep,
  article,
  cancelSchedule,
  isSubmitDisabled,
  toggleRecuring,
  recurringDate,
  toggleSchedule,
  update,
  updateBlockAudiences,
  setToggleSchedule,
}) => {
  const { t } = useArticlesTranslation();
  const publishDate = useSelector(selectors.getPublishDate);
  const archiveDate = useSelector(selectors.getArchiveDate);
  const articleChannel = useAppSelector(selectors.getSelectedChannel);
  const selectedCategories = useAppSelector(selectors.getSelectedCategories);
  const selected = useSelector(selectors.getSelectedAudiences);
  const dispatch = useDispatch();
  const [showMore, setShowMore] = useState(false);
  const languages = useSelector(selectors.getSelectedLanguages);
  const { getCategoryName } = useGetCategoryName();
  const { getRecurringDate } = useRecurringDateAsSchedule();
  const shownAs = useSelector(selectors.getShownAs);

  const { nodes } = useEditor((state, editor) => ({
    canRedo: editor.history.canRedo(),
    canUndo: editor.history.canUndo(),
    nodeId: [...state.events.selected][0],
    nodes: state.nodes,
  }));

  const { isLoading } = useGetAudiencesQuery({
    page: 1,
    query: '',
    enabled: false,
  });

  const actionBlocks = useArticleActionBlocks(nodes);

  const { totalBlocks, recurringBlocks, consistentSchedule } =
    useBlockSchedulingInfo(actionBlocks, getRecurringDate);

  const getChannelTitle = useCallback((channel: Channel) => {
    const defaultChannelTitleKey = Object.keys(channel.title).find(
      (key) => channel.title[key].isDefault
    );

    return defaultChannelTitleKey
      ? channel.title[defaultChannelTitleKey].title
      : '';
  }, []);

  const articleStatus: ArticleStatusEnum = useMemo(() => {
    if (!article) {
      return ArticleStatusEnum.Draft;
    }

    return article.status;
  }, [article]);

  const isRecurringUpdated = article?.blocks?.some(
    (block) =>
      'schedule' in block &&
      block.schedule &&
      !isEqual(getRecurringDate(block.schedule), recurringDate)
  );

  const hasChanges = useMemo(() => {
    if (!article) {
      return false;
    }

    return (
      isRecurringUpdated ||
      !isEqual(article.categories, selectedCategories) ||
      !isEqual(article.channel, articleChannel) ||
      !isEqual(
        [...article.audiences].sort(),
        selected.map((audience) => audience.id).sort()
      ) ||
      (publishDate && !dayjs(article.publishAt).isSame(publishDate)) ||
      (archiveDate && !dayjs(article.archiveAt).isSame(archiveDate))
    );
  }, [
    article,
    isRecurringUpdated,
    selectedCategories,
    articleChannel,
    selected,
    publishDate,
    archiveDate,
  ]);

  const submitButtonText = useMemo(() => {
    switch (articleStatus) {
      case ArticleStatusEnum.Draft:
        return toggleSchedule ? t('Schedule') : t('Publish now');
      case ArticleStatusEnum.Published:
        return dayjs() < dayjs(article?.publishAt)
          ? t('Cancel Scheduling')
          : t('Archive Now');
      case ArticleStatusEnum.Archived:
        return t('Unarchive & Move to draft');
    }
  }, [article?.publishAt, articleStatus, toggleSchedule, t]);

  const coverImage = nodes['coverImage']?.data.props.imageSchema || null;
  const title = nodes['title']?.data.props.text || null;

  const isSubmitButtonDisabled =
    coverImage === null || title === null || isSubmitDisabled;

  const isPublished = dayjs().isAfter(publishDate || article?.publishAt);

  const handlePublishDateChange = useCallback(
    (date?: string | null) => {
      if (!date) return dispatch(actions.setPublishDate(undefined));

      if (dayjs(date).isAfter(dayjs(archiveDate || article?.archiveAt))) {
        dispatch(
          actions.setArchiveDate(dayjs(date).add(7, 'day').toISOString())
        );
      }

      dispatch(actions.setPublishDate(date));
    },
    [article?.archiveAt, dispatch, archiveDate]
  );

  const handleRemoveAudience = useCallback(
    (audienceId: number) => {
      dispatch(actions.removeAudience(audienceId));
      updateBlockAudiences(
        selected
          .filter((audience) => audience.id !== audienceId)
          .map((audience) => audience.id)
      );
    },
    [dispatch, selected, updateBlockAudiences]
  );

  const handleRecurring = useCallback(
    (check: boolean) => {
      const hasScheduleOrDeadline = actionBlocks.some(
        (task) =>
          task.data.props.deadline ||
          (task.data.props.schedule &&
            task.data.props.schedule.frequency !== 'once')
      );
      if (hasScheduleOrDeadline && !recurringDate) {
        return goToStep(5);
      }
      if (!check && recurringDate) {
        return goToStep(4);
      }
      if (check) {
        goToStep(3);
      }
    },
    [recurringDate, goToStep, actionBlocks]
  );

  return (
    <div className="flex flex-col gap-4 items-center">
      {!showMore && (
        <>
          <span className="block font-bold text-grayscale-primary w-full text-sm">
            {t('Publish to')}
          </span>

          <div className="w-full rounded-lg border border-gray-light py-4 px-3 relative flex flex-col gap-3">
            <div className="absolute top-2 right-2">
              <button
                disabled={isLoading}
                type="button"
                onClick={() => {
                  goToStep(0);
                }}
              >
                <MessageEdit size={24} className="text-grayscale-secondary" />
              </button>
            </div>
            <div className="flex text-sm gap-3 items-center">
              <span className="font-bold text-grayscale-primary text-xs">
                {t('Channel')}
              </span>
              <span>{getChannelTitle(articleChannel as Channel)}</span>
            </div>
            <div className="flex text-sm gap-3">
              <span className="font-bold text-grayscale-primary text-xs">
                {t('Categories')}
              </span>
              <div className="flex flex-wrap gap-2">
                {selectedCategories.map((category) => (
                  <div
                    key={category.id}
                    className="flex items-center bg-hover-blue border-2 border-focus-background hover:bg-focus-background rounded-full px-3 py-2 h-[36px] gap-2"
                  >
                    <span className="text-sm text-ceil w-full">
                      {getCategoryName(category)}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
      <div className="w-full rounded-lg border border-gray-light py-4 px-3 relative">
        <div className="absolute top-2 right-2">
          <button
            type="button"
            disabled={isLoading}
            onClick={() => {
              goToStep(1);
            }}
          >
            <MessageEdit size={24} className="text-grayscale-secondary" />
          </button>
        </div>
        <span className="font-bold text-grayscale-primary text-xs">
          {t('Audiences')}
        </span>
        {isLoading ? (
          <Skeleton className="flex flex-col w-full gap-3 mt-2">
            <div className="flex w-full gap-2">
              <div className="flex flex-col w-full gap-1">
                <div className="bg-hover-blue h-6 w-[70%] rounded-lg" />
                <div className="bg-hover-blue h-6 w-[50%] rounded-lg" />
              </div>
              <div className="bg-hover-blue h-7 w-[30%] rounded-lg" />
            </div>
            <div className="flex w-full gap-2">
              <div className="flex flex-col w-full gap-1">
                <div className="bg-hover-blue h-6 w-[70%] rounded-lg" />
                <div className="bg-hover-blue h-6 w-[50%] rounded-lg" />
              </div>
              <div className="bg-hover-blue h-7 w-[30%] rounded-lg" />
            </div>

            <div className="bg-hover-blue h-3 w-[15%] rounded-lg" />
          </Skeleton>
        ) : (
          <>
            {selected && selected.length > 0 && (
              <SelectedOptions
                audiences={selected}
                handleRemoveAudience={handleRemoveAudience}
                showMore={showMore}
              />
            )}

            {selected && selected.length > 2 && (
              <button
                type="button"
                className="text-xs text-focus cursor-pointer"
                onClick={() => setShowMore((prev) => !prev)}
              >
                {showMore ? t('Show less') : t('Show more')}
              </button>
            )}
          </>
        )}
      </div>
      {languages?.length > 1 && article && (
        <div className="w-full rounded-lg border border-gray-light py-4 px-3 relative">
          <ArticleDetailsLanguages article={article} />
        </div>
      )}
      <div className="w-full rounded-lg border border-gray-light py-4 px-3 flex flex-col gap-2">
        {articleStatus === ArticleStatusEnum.Draft && (
          <div className="flex justify-between items-center">
            <span className="font-bold text-grayscale-primary text-xs">
              {t('Schedule publishing for later')}
            </span>
            <Switch
              checked={toggleSchedule}
              onChange={() =>
                setToggleSchedule((prev) => {
                  if (prev) {
                    cancelSchedule();
                    return false;
                  }

                  return true;
                })
              }
              height={24}
              width={39}
              handleDiameter={21}
            />
          </div>
        )}
        {(toggleSchedule || articleStatus !== ArticleStatusEnum.Draft) && (
          <>
            <div
              className={cx(
                'flex justify-between gap-2',
                css({
                  ['& > .react-datepicker-wrapper']: {
                    width: '100% !important',
                  },
                })
              )}
            >
              <div className="flex flex-col w-full">
                <div className="text-xs">
                  <span className="ext-grayscale-secondary">
                    {isPublished ? t('Published') : t('Publishing')}
                  </span>
                  <span className="text-error">*</span>
                </div>
                <Datepicker
                  onChange={handlePublishDateChange}
                  disabled={isPublished}
                  selected={publishDate}
                />
              </div>

              {(articleStatus === ArticleStatusEnum.Draft ||
                archiveDate ||
                article?.archiveAt) && (
                <div className="flex flex-col w-full">
                  <span className="text-xs text-grayscale-secondary">
                    Archiving (optional)
                  </span>
                  <Datepicker
                    onChange={(date) =>
                      dispatch(actions.setArchiveDate(date ?? undefined))
                    }
                    selected={archiveDate}
                    publishDate={publishDate}
                  />
                </div>
              )}
            </div>
            <span className="text-xs text-grayscale-secondary">
              {t('(members local time)')}
            </span>
          </>
        )}
      </div>

      {actionBlocks.length > 0 &&
        shownAs.value !== ArticleShownAs.Training &&
        (articleStatus === ArticleStatusEnum.Draft ||
          articleStatus === ArticleStatusEnum.Published) && (
          <RecurringAction
            checked={toggleRecuring}
            onSwitch={(check) => {
              if (isLoading) return;
              handleRecurring(check);
            }}
            onEdit={() => {
              if (isLoading) return;
              goToStep(3);
            }}
            recurringBlocks={recurringBlocks}
            totalBlocks={totalBlocks}
            consistentSchedule={consistentSchedule}
            actionBlocks={actionBlocks}
          />
        )}
      <div className="flex w-full justify-center gap-2">
        {toggleSchedule && (
          <button
            type="button"
            className="w-56 h-12 rounded-xl bg-hover-blue text-hover-primary"
            onClick={() => {
              setToggleSchedule(false);
              cancelSchedule();
            }}
          >
            {t('Cancel Scheduling')}
          </button>
        )}
        <button
          type="submit"
          disabled={isSubmitButtonDisabled}
          className={cx(
            'w-56 h-12 rounded-xl text-white bg-focus border-transparent',
            {
              'bg-gray-light text-grayscale-secondary': isSubmitButtonDisabled,
            }
          )}
        >
          {submitButtonText}
        </button>
        {articleStatus === ArticleStatusEnum.Published && (
          <button
            type="button"
            disabled={!hasChanges}
            onClick={update}
            className={cx(
              'w-56 h-12 rounded-xl text-white bg-focus border-transparent',
              {
                'bg-gray-light text-grayscale-secondary': !hasChanges,
              }
            )}
          >
            {t('Update')}
          </button>
        )}
      </div>
    </div>
  );
};

const Skeleton = styled.div`
  animation: pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;

  @keyframes pulse {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.6;
    }
  }
`;

export default ThirdStepForm;
