import { useNode } from '@craftjs/core';
import { css, cx } from '@emotion/css';
import styled from '@emotion/styled';
import useGetArticleBlock from 'app/api/articles/hooks/useGetArticleBlock';
import { useAuthenticatedUser } from 'app/api/auth/hooks';
import useFileUpload from 'app/hooks/useFileUpload';
import useUnblockNextSection from 'app/hooks/useUnblockNextSection';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import useAnswerActionMutation from 'app/pages/ArticleEditor/hooks/useAnswerActionMutation';
import useRevertAnswerMutation from 'app/pages/ArticleEditor/hooks/useReverAnswerMutation';
import { selectors as authSelectors } from 'app/store/auth';
import { actions, selectors } from 'app/store/editor';
import logger from 'app/utils/logger';
import Spinner from 'assets/icons/spinner.svg?react';
import { Add, Refresh2 } from 'iconsax-react';
import { ChangeEvent, useRef, useState, useCallback, useMemo, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  PostActionAnswer,
  ActionTypeEnum,
  UserAnswerResponse,
  ActionAnswerResponse,
} from 'submodules/common-ui/generated/api/gcs';

import { SimpleTaskProps } from '../../../../SimpleTask';
import { useCurrentSelectedInstance } from '../../context';
import { ArticleActionNavigation } from '../../types';
import ActionContainer from '../ActionContainer';
import EditorNavigation from '../EditorNavigation';
import MediaViewer from '../MediaViewer/MediaViewer';

import AddOwnAnswerButton from './AddOwnAnswerButton';
import AnswerActionScore, {
  EditorAnswerActionScore,
} from './AnswerActionScore';
import useButtonLabel from './useButtonLabel';

interface SimpleTaskAnswerActionProps {
  type: ActionTypeEnum;
  actionId: string;
  selectedProps: SimpleTaskProps;
  onAnswer: (data: UserAnswerResponse, actionId: string) => void;
  onUndo: (actionId: string) => void;
  isEditor?: boolean;
  NavigationContainer: ({
    children,
    isSubmitting,
  }: ArticleActionNavigation) => JSX.Element;
}

const SimpleTaskAnswerAction: FC<SimpleTaskAnswerActionProps> = ({
  type,
  actionId,
  selectedProps,
  onAnswer,
  onUndo,
  isEditor = false,
  NavigationContainer,
}) => {
  const { t } = useArticlesTranslation();

  const tenant = useSelector(authSelectors.getSelectedTenant);
  const dispatch = useDispatch();

  const btnRef = useRef<HTMLButtonElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const { upload, isLoading: isUploading } = useFileUpload();
  const {
    mutate: answerAction,
    isLoading,
    isSuccess,
    reset,
  } = useAnswerActionMutation();

  const { mutate: revertAnswer, isLoading: isUndoLoading } =
    useRevertAnswerMutation();

  const currentLanguage = useSelector(selectors.getActiveLanguage);

  const [imgs, setImgs] = useState<Array<string>>([]);
  const [imgIds, setImgIds] = useState<Array<string>>([]);

  const { data: user } = useAuthenticatedUser();

  const [isAddingOwnAnswer, setIsAddingOwnAnswer] = useState(false);

  const {
    block: contextBlock,
    currentSelectedInstanceId,
    refetch: contextRefetch,
    answeredByColleague,
    colleagueAnswer,
  } = useCurrentSelectedInstance();

  const { block, refetch } = useGetArticleBlock({
    articleId: selectedProps.articleId!,
    blockId: parseInt(actionId),
    enabled: !!selectedProps.articleId && !contextBlock,
    timezone: user?.timezone,
    live: '1',
  });

  const activeBlock = contextBlock || block;

  const activeRefetch = useMemo(
    () => (contextBlock ? contextRefetch : refetch),
    [contextBlock, refetch, contextRefetch]
  );

  const currentInstanceId =
    currentSelectedInstanceId ?? activeBlock?.currentInstanceId;

  const currentUserAnswersList = activeBlock?.currentUserAnswers || [];
  const currentInstanceAnswer = currentUserAnswersList.find(
    (currentUserAnswer: ActionAnswerResponse) =>
      currentUserAnswer.actionInstanceId === currentInstanceId
  );

  const isActionCompleted =
    !!currentInstanceAnswer && currentInstanceAnswer.revertedAt === null;

  const title = activeBlock?.variants?.[currentLanguage]?.title;
  const description = activeBlock?.variants?.[currentLanguage]?.description;

  const uploadFiles = (files: FileList, index = 0) => {
    const file = files[index];

    if (!file) {
      return;
    }

    setImgs((prev) => [...prev, URL.createObjectURL(file)]);

    upload(file, {
      onSuccess: (response) => {
        setImgIds((prev) => [...prev, response.id]);

        if (index !== 0 && index + 1 >= files.length) {
          return;
        }

        uploadFiles(files, index + 1);
      },
      onError: logger.error,
    });
  };

  const onImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target?.files) {
      return;
    }

    uploadFiles(e.target.files);
  };

  const completeAction = useCallback(() => {
    if ((type === 'media_task' && imgIds.length === 0) || isLoading) {
      return;
    }

    const payload: PostActionAnswer = {
      actionId: parseInt(actionId),
      language: currentLanguage,
      actionInstanceId: currentInstanceId,
      answer: type === 'media_task' ? imgIds : '',
      timezone: user?.timezone,
    };

    answerAction(payload, {
      onError: (e) => logger.error(e),
      onSuccess: (data) => {
        onAnswer(data, actionId);

        dispatch(
          actions.setStats({
            mandatoryProgress: data.mandatoryProgress,
            mandatoryScore: data.totalMandatoryScore,
            progress: data.progress,
            score: data.score,
          })
        );

        setImgs([]);
        setImgIds([]);
        reset();
        activeRefetch();
      },
    });
  }, [
    actionId,
    imgIds,
    currentInstanceId,
    user?.timezone,
    answerAction,
    currentLanguage,
    dispatch,
    reset,
    onAnswer,
    activeRefetch,
    isLoading,
    type,
  ]);

  const undoAnswer = (id: number) => {
    if (isUndoLoading) {
      return;
    }
    revertAnswer(
      { answerId: id, timezone: user?.timezone },
      {
        onSuccess: (_) => {
          onUndo(actionId);
          setImgs([]);
          setImgIds([]);
          activeRefetch();
        },
      }
    );
  };

  const renderAnswerActionScore = () => {
    if (isEditor) {
      return <EditorAnswerActionScore totalScore={0} />;
    }
    return (
      <AnswerActionScore
        selectedProps={selectedProps}
        showQuizScore={false}
        showProgressScore={false}
        totalScore={0}
        blockId={parseInt(actionId)}
      />
    );
  };

  const isTooEarly = currentInstanceId === null;

  const buttonLabel = useButtonLabel(
    isLoading,
    isSuccess,
    isTooEarly,
    activeBlock?.type
  );

  const renderFooterContent = () => {
    return (
      <>
        {answeredByColleague && !isAddingOwnAnswer ? (
          <AddOwnAnswerButton
            answeredByColleague={answeredByColleague}
            isActionCompleted={isActionCompleted}
            onAddAnswer={() => setIsAddingOwnAnswer(true)}
          />
        ) : (
          !isActionCompleted && (
            <button
              ref={btnRef}
              type="button"
              className={cx(
                'flex items-center justify-center w-full h-12 mx-5 rounded-xl bg-focus',
                {
                  'bg-gray-light text-grayscale-secondary':
                    (type === 'media_task' && imgs.length === 0) || isTooEarly,
                  'text-white':
                    (type === 'media_task' && imgs.length > 0 && !isTooEarly) ||
                    !isTooEarly,
                }
              )}
              onClick={completeAction}
              disabled={isTooEarly}
            >
              {buttonLabel}
            </button>
          )
        )}

        {isActionCompleted &&
          currentInstanceAnswer?.completed &&
          type === 'simple_task' && (
            <button
              onClick={() => undoAnswer(currentInstanceAnswer.id)}
              className="flex items-center justify-center w-full h-12 mx-5 rounded-xl bg-gray-light text-focus text-sm"
            >
              {isUndoLoading ? (
                <Spinner className="w-6 h-6 animate-spin text-white" />
              ) : (
                <>
                  <Refresh2 size={21} className="mr-1.5 text-focus" />
                  {t('Undo')}
                </>
              )}
            </button>
          )}
      </>
    );
  };

  return (
    <>
      {renderAnswerActionScore()}
      <ActionContainer
        isMandatory={selectedProps.mandatory}
        type={type}
        canAnswer={true}
      >
        <div className="flex flex-col gap-2">
          <span
            className={cx('text-lg font-bold', css('word-break: break-word;'))}
          >
            {title}
          </span>
          <span className={cx('text-black', css('word-break: break-word;'))}>
            {description}
          </span>
        </div>

        {type === 'media_task' && (
          <>
            {answeredByColleague && !isAddingOwnAnswer ? (
              <div className="">
                <MediaViewer
                  imgsToUpload={[]}
                  tenantName={tenant?.alias ?? ''}
                  type={type}
                  answer={colleagueAnswer?.answer}
                  isLoading={false}
                />
              </div>
            ) : (
              !isLoading &&
              !isActionCompleted && (
                <label
                  htmlFor="mediaTaskUpload"
                  className="flex items-center justify-center w-full rounded-xl border border-dashed border-ceil text-ceil py-8 mt-4"
                >
                  {isUploading ? (
                    <div className="flex items-center justify-center gap-1">
                      <span>{t('Uploading')}</span>
                      <Spinner className="w-4 h-4 animate-spin text-ceil" />
                    </div>
                  ) : (
                    <span>+ {t('Upload photo')}</span>
                  )}
                  <input
                    ref={inputRef}
                    id="mediaTaskUpload"
                    type="file"
                    multiple
                    accept="image/*"
                    className="hidden"
                    onChange={onImageChange}
                  />
                </label>
              )
            )}
            {(isLoading || isActionCompleted) && (
              <MediaViewer
                imgsToUpload={imgs}
                tenantName={tenant?.alias ?? ''}
                type={type}
                answer={currentInstanceAnswer?.answer}
                isLoading={isLoading}
              />
            )}
            {!isLoading && !isActionCompleted && (
              <ImageGrid>
                {imgs.length > 0 &&
                  imgs.map((src, index) => (
                    <div className="relative" key={index}>
                      <button
                        onClick={() => {
                          setImgs((prev) => prev.filter((_, i) => i !== index));
                        }}
                        className="flex items-center justify-center w-6 h-6 bg-fade-blue absolute right-[-10px] top-[-10px] rounded-full cursor-pointer"
                      >
                        <div className="flex items-center justify-center w-5 h-5 bg-grayscale-secondary rounded-full">
                          <Add size={12} className="rotate-45 text-white" />
                        </div>
                      </button>
                      <img
                        src={src}
                        alt="img"
                        className="w-16 h-16 rounded aspect-[2.2/1]"
                      />
                    </div>
                  ))}
              </ImageGrid>
            )}
          </>
        )}

        <div className="flex justify-between items-center w-full mt-6">
          <NavigationContainer isSubmitting={isLoading || isUndoLoading}>
            {renderFooterContent()}
          </NavigationContainer>
        </div>

        {isActionCompleted && (
          <div className="flex flex-col p-3 justify-center items-start gap-1 self-stretch rounded-xl bg-hover-blue mt-5">
            <span className="flex items-center gap-1">
              <span>🚀</span>
              <span className="text-sm text-grayscale-primary font-bold">
                {t('Thanks for answering!')}
              </span>
            </span>
            <span className="text-xs text-grayscale-primary">
              {t('Keep up the good work!')}
            </span>
          </div>
        )}
      </ActionContainer>
    </>
  );
};

export const EditorSimpleTaskAnswerAction = () => {
  const {
    selectedProps,
    nodeId,
    actions: { setProp },
  } = useNode((node) => ({
    selectedProps: node.data.props as SimpleTaskProps,
    nodeId: node.id,
  }));
  const { setIsUnblocked } = useUnblockNextSection(nodeId);

  const { data: user } = useAuthenticatedUser();

  if (!user) return null;

  return (
    <SimpleTaskAnswerAction
      selectedProps={selectedProps}
      actionId={nodeId}
      onAnswer={(data) => {
        setProp((props: SimpleTaskProps) => {
          props.currentUserAnswers = [{ ...data, completedBy: user.id }];
          props.completed = true;
        });

        setIsUnblocked(true);
      }}
      onUndo={() => {
        setProp((props: SimpleTaskProps) => {
          props.currentUserAnswers = [];
          props.completed = false;
        });
      }}
      type={selectedProps.type}
      isEditor={true}
      NavigationContainer={EditorNavigation}
    />
  );
};

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 64px);
  row-gap: 8px;
  justify-content: space-between;
  margin-top: 24px;
`;

export default SimpleTaskAnswerAction;
