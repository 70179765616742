import { useNode } from '@craftjs/core';
import { useScreenBreakpoint } from 'app/hooks';
import useIsEditorEnabled from 'app/hooks/useIsEditorEnabled';
import useDownloadFile from 'app/pages/ArticleEditor/hooks/useDownloadFile';
import BlockTypes from 'app/pages/Editor/helpers/constants';
import { selectors as authSelectors } from 'app/store/auth';
import { isMobile } from 'app/utils';
import { ArrowDown, Refresh } from 'iconsax-react';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  GetCategory,
  GetUserAnswers,
  ActionTypeEnum,
  InternalFileGet,
  ActionSchedule,
} from 'submodules/common-ui/generated/api/gcs';
import { Drawer } from 'vaul';

import BaseBlockContainer from '../../../BaseBlockContainer';
import { OptionProps } from '../../ContextualMenu';
import ActionsSettings from '../components/ActionSettings';

import EditorUserMode, { EditorUserModeRef } from './EditorUserMode';
import EndUserMode from './EndUserMode';

export interface ScormProps {
  audiences: number[];
  public: boolean;
  mandatory?: boolean;
  required: number;
  type: ActionTypeEnum;
  translationStatus: string;
  deadline?: string;
  categories?: GetCategory[];
  description?: string;
  title?: string;
  currentUserAnswers?: GetUserAnswers;
  completed: boolean;
  image?: InternalFileGet;
  package?: InternalFileGet;
  courseId?: string;
  courseStatus?: string;
  articleId?: number;
  isLocked?: boolean;
  schedule?: ActionSchedule | null;
}

const ScormBlock = (scorm: ScormProps) => {
  const { mode } = useParams<{ mode: string }>();
  const { enabled } = useIsEditorEnabled();
  const [hasFocus, setHasFocus] = useState<boolean>(false);
  const [backspace, setBackspace] = useState<boolean>(false);

  const editorUserModeRef = useRef<EditorUserModeRef>(null);

  const {
    props,
    actions: { setProp },
    connectors: { connect },
    id,
    selected,
  } = useNode((state) => ({
    selected: state.events.selected,
    props: state.data.props as ScormProps,
  }));

  const tenant = useSelector(authSelectors.getSelectedTenant);

  const { downloadFileToClient } = useDownloadFile({
    fileSchema: props.package,
    tenantAlias: tenant?.alias,
    fileId: props.package?.id ?? '',
    fileName: props.package?.name ?? '',
  });

  const breakpoint = useScreenBreakpoint();
  const mobile = isMobile(breakpoint);

  const renderEndUserMode = () => {
    if (mobile && !scorm.isLocked)
      return (
        <Drawer.Trigger asChild>
          <div>
            <EndUserMode />
          </div>
        </Drawer.Trigger>
      );

    return <EndUserMode />;
  };

  const additionalOptions: OptionProps[] = [
    { Icon: ArrowDown, text: 'Download', onClick: downloadFileToClient },
    {
      Icon: Refresh,
      text: 'Replace',
      onClick: editorUserModeRef.current?.replaceUploadFile,
    },
  ];

  return (
    <div ref={(ref) => ref && connect(ref)}>
      <BaseBlockContainer
        deleteOnBackspace={enabled && !hasFocus && !backspace}
        nodeId={id}
        selected={selected}
        type="Scorm"
        options={additionalOptions}
      >
        {mode === 'actions' ? (
          renderEndUserMode()
        ) : (
          <EditorUserMode
            setHasFocus={setHasFocus}
            setProp={setProp}
            setBackspace={setBackspace}
            ref={editorUserModeRef}
          />
        )}
      </BaseBlockContainer>
    </div>
  );
};

ScormBlock.craft = {
  displayName: BlockTypes.ScormBlock,
  related: {
    settings: ActionsSettings,
  },
};

export default ScormBlock;
