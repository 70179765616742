import { ClipboardText } from 'iconsax-react';
import { FC } from 'react';

import { useCurrentSelectedInstance } from '../context';

interface Props {
  completedActions: boolean[];
}

const HeaderContainer: FC<Props> = ({ completedActions }) => {
  const { article } = useCurrentSelectedInstance();

  return (
    <div className="flex flex-end gap-2 items-center">
      <div className="px-2 py-[7px] rounded-lg border-focus-background bg-hover-blue text-ceil flex items-center">
        <ClipboardText size={16} className="mr-1.5" />
        <span className="text-sm">
          {completedActions.filter((isComplete) => isComplete).length}/
          {completedActions.length}
        </span>
      </div>

      <div className="flex justify-center items-center gap-3">
        {article?.myProgress !== null && article?.myProgress !== undefined && (
          <div className="flex py-[7px] px-2 items-center gap-1.5 rounded-lg bg-hover-blue">
            <span className="text-ceil text-sm">
              Score: {article.myProgress}%
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderContainer;
