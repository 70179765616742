import { request } from 'app/api/gcsRequest';
import config from 'app/config';
import { ArticleApi } from 'common-ui/generated/api/gcs';

import { ArticleFilter } from '../types';

const getArticle = (id: number, filter: ArticleFilter) =>
  new ArticleApi(undefined, config.env.gcsApiUrl, request())
    .getArticle(
      id,
      filter.include,
      filter.timezone,
      filter.live,
      filter.language,
      filter.originalArticleId
    )
    .then((response) => response.data);

export { getArticle };
export default getArticle;
