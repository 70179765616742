import { ArticlesKey } from '../types';

import useBaseTranslation from './useBaseTranslation';

const useArticlesTranslation = () =>
  useBaseTranslation<ArticlesKey>(undefined, {
    keyPrefix: 'articles',
  });

export default useArticlesTranslation;
