import {
  InitialEditorStateType,
  LexicalComposer,
} from '@lexical/react/LexicalComposer';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import lexicalEditorTheme from 'app/pages/ArticleEditor/helpers/lexical/theme';
import { editorTypes } from 'app/router/constants';
import {
  EditorState,
  LexicalEditor as LexicalEditType,
  ParagraphNode,
  SerializedEditorState,
} from 'lexical';
import { useParams } from 'react-router-dom';

import LexicalInput from './LexicalInput';

interface LexicalEditorProps {
  initialState?: InitialEditorStateType;
  onChange: (e: SerializedEditorState) => void;
  disabled?: boolean;
  error: boolean;
}

const LexicalInputWrapper = ({
  initialState,
  onChange,
  disabled = false,
  error,
}: LexicalEditorProps) => {
  const { mode } = useParams<{ mode: string }>();

  const onEditorChange = (
    state: EditorState,
    lexicalEditor: LexicalEditType
  ) => {
    lexicalEditor.update(() => {
      onChange(state.toJSON());
    });
  };

  const enabled =
    mode !== editorTypes.view && mode !== editorTypes.review && !disabled;

  return (
    <div className="relative rounded-sm w-full px-2">
      <LexicalComposer
        initialConfig={{
          namespace: 'editor',
          theme: lexicalEditorTheme,

          onError(err) {
            throw err;
          },
          nodes: [ParagraphNode],
          editable: enabled,
          editorState: initialState,
        }}
      >
        <LexicalInput enabled={enabled} error={error} />
        <OnChangePlugin onChange={onEditorChange} />
      </LexicalComposer>
    </div>
  );
};
export default LexicalInputWrapper;
