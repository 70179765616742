import { request } from 'app/api/gcsRequest';
import config from 'app/config';
import {
  CategoryApi,
  ArchiveCategoryParams,
} from 'submodules/common-ui/generated/api/gcs';

const archiveCategories = (params: ArchiveCategoryParams) =>
  new CategoryApi(undefined, config.env.gcsApiUrl, request()).archiveCategories(
    params
  );

export default archiveCategories;
