import { useArticlesTranslation } from 'app/internationalization/hooks';
import removeTimezone from 'app/utils/removeTimezone';
import dayjs from 'dayjs';
import { Profile2User, Calendar, Eye } from 'iconsax-react';

import { useCurrentSelectedInstance } from '../../context';

const ViewerHeader = () => {
  const { t } = useArticlesTranslation();
  const { currentSelectedInstance, block } = useCurrentSelectedInstance();

  const effectiveDeadline = currentSelectedInstance
    ? currentSelectedInstance.end
    : block?.deadline;

  const formattedDeadline = effectiveDeadline
    ? dayjs(removeTimezone(effectiveDeadline)).format('DD.MM.YYYY [at] HH:mm')
    : t('No deadline');

  return (
    <div className="flex w-full p-3 flex-col items-start gap-3 bg-white rounded-[20px] border border-focus-background mt-[30px] mb-10">
      <div className="flex items-start gap-2.5 w-full">
        <div className="w-full flex flex-col p-3 gap-2.5 rounded-[14px] border border-focus-background h-[80px]">
          <div className="flex justify-between items-center">
            <span className="text-xs font-bold lg:text-sm text-grayscale-primary">
              {t('Assignee')}
            </span>
            <div>
              <Profile2User size={16} className="text-gray-silver" />
            </div>
          </div>

          <div className="flex items-center gap-1">
            <span className="text-xs">All members</span>
            <span className="text-xs text-grayscale-secondary">
              in audience
            </span>
          </div>
        </div>

        <div className="w-full flex flex-col p-3 gap-2.5 rounded-[14px] border border-focus-background h-[80px]">
          <div className="flex justify-between items-center">
            <span className="text-xs font-bold lg:text-sm text-grayscale-primary">
              {t('Deadline')}
            </span>
            <div>
              <Calendar size={16} className="text-gray-silver" />
            </div>
          </div>
          <div className="flex items-center gap-2">
            <span className="text-xs text-grayscale-secondary">
              {formattedDeadline}
            </span>
          </div>
        </div>
      </div>

      <div className="flex h-[40px] w-full p-2.5 items-center gap-1.5 rounded-lg bg-hover-blue">
        <span className="flex gap-2.5 items-center justify-center rounded-full w-4 h-4 bg-focus">
          <Eye size={10} color="white" />
        </span>
        <span className="flex items-center gap-1 text-xs font-bold font-Lato">
          {t("You are a viewer and can't complete this.")}
        </span>
      </div>
    </div>
  );
};

export default ViewerHeader;
