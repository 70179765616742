import { useMemo } from 'react';
import { GetUserAnswers } from 'submodules/common-ui/generated/api/gcs';
interface Props {
  currentUserAnswers?: GetUserAnswers;
}
const useUserAnswer = ({ currentUserAnswers }: Props) =>
  useMemo(
    () => currentUserAnswers?.find((ua) => ua.revertedAt === null),
    [currentUserAnswers]
  );

export default useUserAnswer;
