import { useNode } from '@craftjs/core';
import BlockTypes from 'app/pages/Editor/helpers/constants';
import { useParams } from 'react-router-dom';

import BaseBlockContainer from '../../BaseBlockContainer';

import EditorUserMode from './EditorUserMode';
import EndUserMode from './EndUserMode';

interface HeadingSectionBlockProps {
  isSectionBlocked?: boolean;
  title: string;
}

const HeadingSectionBlock = ({
  isSectionBlocked = false,
  title,
}: HeadingSectionBlockProps) => {
  const { mode } = useParams<{ mode: string }>();

  const {
    connectors: { connect },
    id,
  } = useNode();

  return (
    <div ref={(ref) => ref && connect(ref)}>
      <BaseBlockContainer
        nodeId={id}
        type="HeadingSection"
        selected={false}
        deleteOnBackspace={false}
        showTranslationStatus={false}
      >
        {mode === 'actions' ? (
          <EndUserMode isSectionBlocked={isSectionBlocked} title={title} />
        ) : (
          <EditorUserMode />
        )}
      </BaseBlockContainer>
    </div>
  );
};

HeadingSectionBlock.craft = {
  displayName: BlockTypes.HeadingSectionBlock,
};

export default HeadingSectionBlock;
