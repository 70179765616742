import { NonNegativeNumber } from 'common-ui/utils/nonNegativeNumber';
import last from 'lodash/last';
import { useInfiniteQuery } from 'react-query';

import { resources, queryKeys } from '../index';
import { UpcomingActionsFilter } from '../types';

const useUpcomingActions = (
  filter?: UpcomingActionsFilter,
  refetchOnWindowFocus = true,
  perPage = 10
) => {
  const {
    data,
    isLoading,
    error,
    isFetching,
    isPreviousData,
    refetch,
    fetchNextPage,
  } = useInfiniteQuery(
    queryKeys.upcomingActions(filter),
    ({ pageParam }) =>
      resources.getUpcomingActions(
        NonNegativeNumber.create(pageParam || 0).value,
        perPage,
        filter
      ),
    {
      getNextPageParam: (lastPage) => lastPage.data.meta.currentPage + 1,
      refetchOnWindowFocus,
    }
  );

  return {
    data:
      data?.pages
        .map((page) => page.data)
        .flat()
        .map((item) => item.data)
        .flat() ?? [],
    meta: last(data?.pages)?.data.meta,
    isFetching,
    isLoading,
    isPreviousData,
    error,
    refetch,
    fetchNextPage,
  };
};

export default useUpcomingActions;
