import { cx } from '@emotion/css';
import useGetActionInstance from 'app/api/articles/hooks/useGetActionInstance';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { selectors } from 'app/store/editor';
import Calendar from 'assets/icons/calendar.svg?react';
import RepeatCircle from 'assets/icons/repeat-circle.svg?react';
import { Danger, ArrowLeft, ArrowRight } from 'iconsax-react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ActionTypeEnum } from 'submodules/common-ui/generated/api/gcs';
import { Swiper as SwiperClass } from 'swiper';
import 'swiper/css';
import 'swiper/css/free-mode';
import { FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useAnswersContext } from '../../context';

import Instance from './Instance';

const InstancePicker = () => {
  const article = useSelector(selectors.getArticle);

  const { selectedTaskId, selectedTenant } = useAnswersContext();

  const selectedTaskBlock = article?.blocks.find(
    (block) =>
      block.id === selectedTaskId &&
      (Object.values(ActionTypeEnum) as string[]).includes(block.type)
  );
  const selectedTaskBlockSchedule =
    selectedTaskBlock && 'schedule' in selectedTaskBlock
      ? selectedTaskBlock.schedule
      : undefined;

  const { actionInstances, isLoading, error } = useGetActionInstance({
    actionId: selectedTaskId || 0,
    start: 'asc',
    perPage: 100,
    enabled:
      !!selectedTaskId &&
      !selectedTenant &&
      selectedTaskBlockSchedule?.frequency !== 'once',
  });
  const [isBeginning, setIsBeginning] = useState<boolean>(true);
  const [isEnd, setIsEnd] = useState<boolean>(false);
  const [swiper, setSwiper] = useState<SwiperClass>();

  const { t } = useArticlesTranslation();

  if (selectedTaskBlockSchedule?.frequency === 'once' || selectedTenant) {
    return null;
  }

  return (
    <div className="flex flex-col justify-center items-start gap-3 mb-3">
      {isLoading ? (
        <div className="flex items-center gap-3">
          <div className="flex p-1.5 justify-center items-center gap-2.5 rounded-lg bg-hover-blue">
            <div className="w-6 h-6"></div>
          </div>
          <div className="flex flex-col items-start bg-hover-blue w-[157px] h-[36px] rounded-lg"></div>
        </div>
      ) : (
        <div className="flex gap-3 items-center">
          <div className="relative p-1.5 bg-hover-blue rounded-lg w-[36px] h-[36px] flex justify-center items-center">
            <Calendar />
            <div className="absolute top-[5px] left-[20px] h-3 w-3 rounded-full bg-hover-blue">
              <RepeatCircle />
            </div>
          </div>
          <div>
            <p className="text-xs text-grayscale-secondary">
              Repeats {selectedTaskBlockSchedule?.frequency}
            </p>
            <p className="text-sm">
              Finish today before {selectedTaskBlockSchedule?.end.time}
            </p>
          </div>
        </div>
      )}

      {!error && !isLoading && (
        <div className="relative w-full">
          <Swiper
            className="!ml-0 !mr-0"
            freeMode={true}
            modules={[FreeMode]}
            slidesPerView="auto"
            spaceBetween={8}
            onReachBeginning={() => setIsBeginning(true)}
            onReachEnd={() => setIsEnd(true)}
            onSlideChange={() => {
              if (!swiper) return;
              setIsEnd(swiper.isEnd);
              setIsBeginning(swiper.isBeginning);
              swiper?.update();
            }}
            onSwiper={setSwiper}
          >
            {actionInstances?.map((instance, index) => (
              <SwiperSlide key={index} className="!w-auto">
                <Instance
                  instance={instance}
                  frequency={selectedTaskBlockSchedule?.frequency}
                  instanceLoading={isLoading}
                />
              </SwiperSlide>
            ))}
          </Swiper>

          <div
            className={cx(
              'absolute left-0 top-1/2 transform -translate-y-1/2 z-10 ml-[-10px] flex p-2 justify-center items-center gap-2.5 rounded-lg bg-focus-background cursor-pointer',
              { 'opacity-0 !-z-10': isBeginning },
              { 'opacity-0 hover:opacity-100': !isBeginning }
            )}
            role="presentation"
            onClick={() => swiper?.slidePrev()}
          >
            <ArrowLeft size={20} className="text-focus" />
          </div>

          <div
            className={cx(
              'absolute right-0 top-1/2 transform -translate-y-1/2 z-10 ml-[-10px] flex p-2 justify-center items-center gap-2.5 rounded-lg bg-focus-background cursor-pointer',
              { 'opacity-0 !-z-10': isEnd },
              { 'opacity-0 hover:opacity-100': !isEnd }
            )}
            role="presentation"
            onClick={() => swiper?.slideNext()}
          >
            <ArrowRight size={20} className="text-focus" />
          </div>
        </div>
      )}

      {error && (
        <div className="flex w-full justify-center items-start gap-3">
          <div className="flex px-4 py-3 justify-center items-center gap-2.5 bg-[#FEFFFE] rounded-lg shadow-atobi">
            <div className="flex flex-col justify-center items-center gap-1">
              <div className="flex justify-center items-center gap-1">
                <Danger className="w-4 h-4 text-error" />
                <span className="font-bold text-sm text-error text-center">
                  {t('Unable to load recurring actions.')}
                </span>
              </div>
              <span className="text-grayscale-secondary text-xs text-center">
                {t('Refresh the page or try again later.')}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InstancePicker;
