import { cx } from '@emotion/css';

import Spinner from '../Spinner';

interface PageLoaderProps {
  className?: string;
}

const PageLoader = ({ className }: PageLoaderProps) => (
  <div
    className={cx('flex items-center justify-center w-full h-full', className)}
  >
    <Spinner />
  </div>
);

export default PageLoader;
