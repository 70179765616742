import styled from '@emotion/styled';
import useGetWebAccessToken from 'app/api/sisense/hooks/useGetWebAccessToken';
import { PageLoader, SisenseDashboard } from 'app/components';
import { useScreenBreakpoint } from 'app/hooks';
import { isMobile } from 'app/utils';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import { Drawer } from 'vaul';

interface Props {
  postId: number;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
}

const sisenseDashboard = '65dca2db52c1280033d426e2';

const ArticleEngagement = ({ postId, isOpen, onOpenChange }: Props) => {
  const breakpoint = useScreenBreakpoint();
  const mobile = isMobile(breakpoint);
  const { token, isLoading } = useGetWebAccessToken(isOpen);

  const [isLoaded, setIsLoaded] = useState(false);

  const renderSisenseDashboard = () => {
    if (isLoading || !token) return <></>;
    return (
      <>
        <SisenseDashboard
          className="absolute h-full left-0 top-0"
          dashboard={sisenseDashboard}
          filters={[
            {
              disabled: false,
              isCascading: false,
              jaql: {
                column: 'article_id',
                datatype: 'numeric',
                dim: '[dim_articles.article_id]',
                table: 'dim_articles',
                title: 'article_id',
                filter: {
                  members: [postId.toString()],
                },
              },
            },
          ]}
          settings={{ showRightPane: false, showToolbar: false }}
          token={token}
          onLoad={() => setIsLoaded(true)}
        />
        {!isLoaded && (
          <div className="absolute left-0 right-0 top-0 bottom-0 flex justify-center items-center">
            <PageLoader />
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    if (!isOpen) setIsLoaded(false);
  }, [isOpen]);

  return mobile ? (
    <Drawer.NestedRoot open={isOpen} onOpenChange={onOpenChange}>
      <Drawer.Portal>
        <Drawer.Overlay
          className="fixed inset-0 bg-grayscale-primary/20 z-50"
          onClick={() => onOpenChange(false)}
        />
        <Drawer.Content className="fixed bottom-0 left-0 right-0 flex flex-col outline-none z-50">
          <div className="min-h-[90vh] bg-white flex flex-col">
            <div className="flex justify-end p-2">
              <Drawer.Trigger>
                <CloseLineIcon
                  className="min-w-6 text-grayscale-primary cursor-pointer"
                  size={24}
                />
              </Drawer.Trigger>
            </div>
            <div className="flex-1 relative">{renderSisenseDashboard()}</div>
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.NestedRoot>
  ) : (
    <>
      {isOpen &&
        createPortal(
          <ModalContainer className="fixed z-50 top-0 left-0 w-screen h-screen">
            <div className="flex items-center w-full h-full justify-center">
              <OutsideClickHandler onOutsideClick={() => onOpenChange(false)}>
                <div className="bg-white h-[90vh] min-w-[588px] w-[90vw] relative flex flex-col">
                  <button
                    className="flex justify-end p-2"
                    onClick={() => onOpenChange(false)}
                  >
                    <CloseLineIcon
                      className="min-w-6 text-grayscale-primary cursor-pointer"
                      size={24}
                    />
                  </button>

                  <div className="relative flex-1">
                    {renderSisenseDashboard()}
                  </div>
                </div>
              </OutsideClickHandler>
            </div>
          </ModalContainer>,
          document.body
        )}
    </>
  );
};

const ModalContainer = styled.span`
  background-color: rgba(34, 34, 34, 0.3);
`;

export default ArticleEngagement;
