import { cx } from '@emotion/css';

import Button from '../Button';

interface Props {
  cancelLabel: string;
  submitLabel: string;
  onSubmit: () => void;
  onCancel: () => void;
  className?: string;
  isSubmitDisabled?: boolean;
  isCancelDisabled?: boolean;
  isCancelButtonHidden?: boolean;
}

const SubmitAndCancelButton = ({
  cancelLabel,
  onCancel,
  onSubmit,
  submitLabel,
  className,
  isSubmitDisabled,
  isCancelDisabled,
  isCancelButtonHidden,
}: Props) => (
  <div className={cx('flex', className)}>
    {!isCancelButtonHidden && (
      <Button
        disabled={isCancelDisabled}
        variant="secondary"
        className="w-3/6 h-12"
        onClick={onCancel}
      >
        {cancelLabel}
      </Button>
    )}
    <Button
      disabled={isSubmitDisabled}
      variant="primary"
      onClick={onSubmit}
      className={cx('w-3/6 h-12', {
        'bg-gray-light text-grayscale-secondary': isSubmitDisabled,
        'ml-4': !isCancelButtonHidden,
      })}
    >
      {submitLabel}
    </Button>
  </div>
);

export default SubmitAndCancelButton;
