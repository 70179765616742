import { useNode } from '@craftjs/core';
import { css, cx } from '@emotion/css';
import styled from '@emotion/styled';
import useGetArticle from 'app/api/articles/hooks/useGetArticle';
import { PageLoader, ResponsiveImage } from 'app/components';
import config from 'app/config';
import { useDispatch, useScreenBreakpoint } from 'app/hooks';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import useUserAnswer from 'app/pages/ArticleEditor/hooks/useUserAnswer';
import useUIArticleLanguage from 'app/pages/Articles/hooks/useUIArticleLanguage';
import { actions as modalActions } from 'app/store/modal';
import { ModalTypes } from 'app/store/modal/types';
import { isMobile } from 'app/utils';
import { Danger, ExportSquare } from 'iconsax-react';
import { ComponentProps, FC, useCallback, useMemo, useState } from 'react';

import ScormBlock from '../../../../ScormBlock';
import ActionContainer from '../ActionContainer';
import ScormAnswerDrawer from '../Drawers/ScormAnswerDrawer';
import EditorNavigation from '../EditorNavigation';

import AnswerActionScore, {
  EditorAnswerActionScore,
} from './AnswerActionScore';

interface ScormAnswerActionProps {
  actionId: string;
  languageCode: string;
  selectedProps: ComponentProps<typeof ScormBlock>;
  isEditor?: boolean;
  NavigationContainer: ({
    children,
  }: {
    children: React.ReactNode;
  }) => JSX.Element;
}

const ScormAnswerAction: FC<ScormAnswerActionProps> = ({
  selectedProps,
  isEditor = false,
  actionId,
  languageCode,
  NavigationContainer,
}) => {
  const { title, description, image, currentUserAnswers } = selectedProps;

  const userAnswer = useUserAnswer({ currentUserAnswers });

  const [showScormDrawer, setShowScormDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const { t } = useArticlesTranslation();

  const breakpoint = useScreenBreakpoint();
  const mobile = isMobile(breakpoint);

  const dispatch = useDispatch();

  const renderAnswerActionScore = () => {
    if (isEditor)
      return (
        <EditorAnswerActionScore totalScore={0} showProgressScore={false} />
      );
    return (
      <AnswerActionScore
        selectedProps={selectedProps}
        showQuizScore={false}
        showProgressScore={false}
        totalScore={0}
        blockId={parseInt(actionId)}
      />
    );
  };

  const onClickScorm = useCallback(() => {
    if (mobile) return setShowScormDrawer(true);

    dispatch(
      modalActions.showModal({
        modalType: ModalTypes.SCORM_ANSWER_MODAL,
        modalProps: {
          actionId,
          languageCode,
        },
      })
    );
  }, [actionId, dispatch, mobile, languageCode]);

  const onLoad = () => {
    setIsLoading(false);
    setHasError(false);
  };

  const onError = () => {
    setIsLoading(false);
    setHasError(true);
  };

  const statusScormLabel = useMemo(() => {
    if (!userAnswer) return t('Start course');
    if (userAnswer.completed) return t('Course completed');
    return t('Continue course');
  }, [t, userAnswer]);

  return (
    <>
      {renderAnswerActionScore()}
      <ActionContainer
        isMandatory={selectedProps.mandatory}
        type={'scorm_task'}
        canAnswer={true}
      >
        <div className="flex flex-col gap-2">
          <span
            className={cx('text-lg font-bold', css('word-break: break-word;'))}
          >
            {title}
          </span>
          <span className={cx('text-black', css('word-break: break-word;'))}>
            {description}
          </span>
        </div>

        <div role="presentation" onClick={onClickScorm} className="pt-6">
          <div className="h-[154px] w-full relative">
            {image && (
              <ResponsiveImage
                className="h-full w-full object-cover rounded-t-lg"
                directUrls={image.directUrls}
                onLoad={onLoad}
                onError={onError}
              />
            )}
            {isLoading && (
              <StatusContainer className="w-full h-full">
                <PageLoader />
              </StatusContainer>
            )}
            {hasError && (
              <StatusContainer className="w-full h-full">
                <Danger size={30} />
              </StatusContainer>
            )}
          </div>
          <div className="w-full h-12 bg-hover-blue rounded-b-lg flex justify-center items-center">
            <div className="text-focus text-sm flex items-center gap-2">
              <span>{statusScormLabel}</span>
              <ExportSquare size={24} />
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center w-full mt-6">
          <NavigationContainer>
            <></>
          </NavigationContainer>
        </div>
      </ActionContainer>
      <ScormAnswerDrawer
        open={showScormDrawer}
        actionId={actionId}
        languageCode={languageCode}
        onClose={() => setShowScormDrawer(false)}
      />
    </>
  );
};

export const EditorScormAnswerAction = () => {
  const { selectedProps, nodeId } = useNode((node) => ({
    selectedProps: node.data.props as ComponentProps<typeof ScormBlock>,
    nodeId: node.id,
  }));
  const { article } = useGetArticle({
    id: Number(selectedProps.articleId!),
    enabled: true,
    filter: {},
  });
  const { getMainLanguage } = useUIArticleLanguage();
  const mainLanguage = getMainLanguage(article?.languages);

  return (
    <ScormAnswerAction
      selectedProps={selectedProps}
      actionId={nodeId}
      languageCode={mainLanguage}
      isEditor={true}
      NavigationContainer={EditorNavigation}
    />
  );
};

const StatusContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 10;
  background-color: ${config.colors['white-shadow']};
  justify-content: center;
  display: flex;
  align-items: center;
`;

export default ScormAnswerAction;
