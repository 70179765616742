import { KeyPrefix } from 'i18next';
import { useTranslation, UseTranslationOptions } from 'react-i18next';

const useBaseTranslation = <TKey extends string>(
  namespace?: TKey,
  options?: UseTranslationOptions<KeyPrefix<TKey>>
) => {
  const { t, ...rest } = useTranslation<TKey, KeyPrefix<TKey>>(
    namespace,
    options
  );

  return {
    ...rest,
    t,
  };
};

export default useBaseTranslation;
