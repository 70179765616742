import { request } from 'app/api/gcsRequest';
import config from 'app/config';
import { RelevantCategoriesApi } from 'submodules/common-ui/generated/api/gcs';

export type GetCategoriesRequest = {
  channelId: string;
  page: number;
  per_page: number;
};

const getRelevantCategories = ({
  channelId,
  page,
  per_page,
}: GetCategoriesRequest) =>
  new RelevantCategoriesApi(
    undefined,
    config.env.gcsApiUrl,
    request()
  ).getRelevantCategoriesPage(channelId, page, per_page);

export default getRelevantCategories;
