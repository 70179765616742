export default {
  audiences: (page: number, query: string) => ['audiences', page, query],
  audience: (id: number) => ['audience', id],
  audienceUsers: (id: number, page: number, per_page: number) => [
    'audienceUsers',
    id,
    page,
    per_page,
  ],
  audienceUsersIds: (
    id: number,
    locations?: number[],
    professions?: number[],
    includeParentsLocations?: boolean
  ) => ['audienceUsers', id, locations, professions, includeParentsLocations],
  users: (
    locations: number[],
    professions: number[],
    includeParentsLocations: boolean
  ) => [
    'usersFromLocationsProfessions',
    locations,
    professions,
    includeParentsLocations,
  ],
};
