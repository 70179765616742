import { cx } from '@emotion/css';
import { ScrollbarContainer, VerticalChevron } from 'app/components';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import useOpenVertically from 'app/pages/ArticleEditor/hooks/useOpenVertically';
import dayjs from 'app/utils/dayjs';
import { Clock } from 'iconsax-react';
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import CheckLineIcon from 'remixicon-react/CheckLineIcon';

const DatePickerTimeInput = forwardRef<
  HTMLButtonElement,
  {
    onChange?: (value: string) => void;
    date?: string | null;
    minDate?: Date;
  }
>(({ onChange, date, minDate }, ref) => {
  const [open, setOpen] = useState(false);
  const selected = useMemo(
    () => (date ? dayjs(date).format('HH:00') : '12:00'),
    [date]
  );

  const dropdownRef = useRef<HTMLDivElement>(null);

  const { t } = useArticlesTranslation();

  useOpenVertically(dropdownRef, open);

  useEffect(() => {
    return () => setOpen(false);
  }, []);

  const currentDateTime = dayjs();
  const selectedTime = (time: string) => selected === time;

  const handleClick = (time: string) => {
    onChange?.(time);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
      <div className="flex justify-between">
        <div className="flex items-center text-grayscale-secondary text-sm mr-5">
          <Clock size={16} className="mr-2" />
          <span>{t('Set Time (members local time)')}</span>
        </div>

        <div
          className="flex items-center justify-center relative bg-hover-blue rounded w-[88px] py-1 ml-4"
          role="presentation"
          onClick={() => setOpen((prev) => !prev)}
        >
          <span className="mr-2 text-sm text-focus">{selected}</span>
          <VerticalChevron size={12} className="text-focus" open={open} />
          {open && (
            <div ref={dropdownRef} className="absolute top-6 -left-1">
              <ScrollbarContainer className="rounded h-[250px]">
                <div className="flex flex-col bg-white w-[88px]">
                  {[...Array(24)].map((_, index) => {
                    const hour = index < 10 ? `0${index}` : `${index}`;
                    const time = `${hour}:00`;

                    const fullDateTime = dayjs(date)
                      .set('hour', index)
                      .startOf('hour');

                    const isCurrentHour = currentDateTime.isSame(
                      fullDateTime,
                      'hour'
                    );

                    const isBeforeMinDate = minDate
                      ? fullDateTime.isBefore(minDate)
                      : false;

                    const isPastTime =
                      !isCurrentHour &&
                      currentDateTime.isAfter(fullDateTime) &&
                      currentDateTime.isSame(fullDateTime, 'day');

                    const isDisabled = isBeforeMinDate || isPastTime;

                    return (
                      <button
                        ref={ref}
                        disabled={isDisabled}
                        key={index}
                        className={cx(
                          'text-sm w-full h-[36px] px-3 font-normal flex items-center justify-between whitespace-nowrap text-black',
                          { 'rounded-t': index === 0 },
                          { 'rounded-b': index === 23 },
                          { 'bg-gray': isDisabled },
                          { 'bg-transparent hover:bg-hover-blue': !isDisabled }
                        )}
                        onClick={() => handleClick(time)}
                      >
                        <span>{time}</span>
                        {selectedTime(time) && (
                          <CheckLineIcon size={20} className="text-focus" />
                        )}
                      </button>
                    );
                  })}
                </div>
              </ScrollbarContainer>
            </div>
          )}
        </div>
      </div>
    </OutsideClickHandler>
  );
});

export default DatePickerTimeInput;
