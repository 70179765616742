import { request } from 'app/api/identityRequest';
import config from 'app/config';

import { TokenResponse } from '../types';

export const BASE_CONFIG = {
  apiUrl: config.env.identityServerUri,
};

const getWebAccessToken = () => {
  return request().post<TokenResponse>(`/sisense/webaccesstoken`);
};

export default getWebAccessToken;
