import { cx } from '@emotion/css';
import { ScrollbarContainer, VerticalChevron } from 'app/components';
import useOpenVertically from 'app/pages/ArticleEditor/hooks/useOpenVertically';
import { ClipboardTick, InfoCircle } from 'iconsax-react';
import { useRef, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import Button from './Button';

interface FeedbackProps {
  explanation?: string;
  maxCorrectAnswersNumber?: number;
  correctAnswersNumber?: number;
  optionsNumber?: number;
  onFeedbackChange: (value: string) => void;
  setCorrectAnswersNumber: (value: number) => void;
  onFocusChange: (focus: boolean) => void;
}
const Feedback = ({
  onFeedbackChange,
  onFocusChange,
  setCorrectAnswersNumber,
  explanation = '',
  maxCorrectAnswersNumber = 0,
  optionsNumber = 1,
  correctAnswersNumber = 0,
}: FeedbackProps) => {
  const [currentCharacters, setCurrentCharacters] = useState(
    explanation?.length ?? 0
  );

  const [open, setOpen] = useState(false);
  const [answersOpen, setAnswersOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useOpenVertically(dropdownRef, answersOpen);

  const maxCharacters = 250;

  return (
    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
      <div className="relative">
        <ClipboardTick
          onClick={() => {
            setOpen((prev) => !prev);
          }}
          size={20}
          className={cx({ 'text-grayscale-secondary': open })}
        />

        {open && (
          <div className="w-[302px] absolute bg-white rounded-lg z-50 shadow-lg m-0 bg-clip-padding left-2 bottom-4 transform -translate-y-2 shadow-atobi text-black">
            <div className="p-3">
              <div className="flex flex-col mb-2">
                <span className="text-sm font-bold">Feedback Message</span>
                <span className="text-sm">
                  This appears when the quiz is completed.
                </span>
              </div>
              <div className="relative border border-gray-dark rounded max-w-[286px] h-[120px]">
                <textarea
                  onFocus={() => onFocusChange(true)}
                  onBlur={() => onFocusChange(false)}
                  value={explanation}
                  maxLength={maxCharacters}
                  onChange={(e) => {
                    setCurrentCharacters(e.target.value.length);
                    onFeedbackChange(e.target.value);
                  }}
                  className="bg-grayscale-bg-dark pl-4 py-2.5 w-full h-full text-sm rounded resize-none pe-[47px]"
                />
                <span className="absolute right-0 bottom-0 text-xs text-grayscale-secondary mr-4 mb-2.5">
                  {currentCharacters}/{maxCharacters}
                </span>
              </div>
            </div>
            <hr className="text-gray-dark" />
            <div className="p-3">
              <div className="flex items-center">
                <span className="text-sm font-bold">Quiz Threshold</span>
                <a
                  target="_blank"
                  rel="noroferrer noreferrer"
                  href="https://help.atobi.io/article/148-articles-actions"
                >
                  <InfoCircle size={16} className="ml-2 text-gray-dark" />
                </a>
              </div>
              <div className="flex items-center mt-[14px]">
                <span className="text-sm">
                  Required correct answers to pass:
                </span>
                <div
                  className="flex items-center justify-center relative bg-hover-blue rounded p-2 ml-4"
                  role="presentation"
                  onClick={() => setAnswersOpen((prev) => !prev)}
                >
                  <span className="mr-2 text-sm text-focus">
                    {correctAnswersNumber}
                  </span>
                  <VerticalChevron
                    size={12}
                    className="text-focus"
                    open={answersOpen}
                  />
                  {answersOpen && (
                    <OutsideClickHandler
                      onOutsideClick={() => setAnswersOpen(false)}
                    >
                      <div ref={dropdownRef} className="absolute left-0 top-7">
                        <ScrollbarContainer className="rounded h-[250px]">
                          <div className="flex flex-col bg-white rounded">
                            {[...Array(optionsNumber)].map((_, index) => {
                              return (
                                <Button
                                  key={index}
                                  disabled={index + 1 > maxCorrectAnswersNumber}
                                  index={index + 1}
                                  correctAnswersNumber={correctAnswersNumber}
                                  maxCorrectAnswers={maxCorrectAnswersNumber}
                                  optionsNumber={optionsNumber}
                                  onClick={() =>
                                    setCorrectAnswersNumber(index + 1)
                                  }
                                />
                              );
                            })}
                          </div>
                        </ScrollbarContainer>
                      </div>
                    </OutsideClickHandler>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default Feedback;
