import { queryKeys } from 'app/api/articles';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import queryClient from 'app/query';
import { selectors } from 'app/store/editor';
import { actions as modalActions } from 'app/store/modal';
import { ModalTypes } from 'app/store/modal/types';
import { FIRST_STEP, SECOND_STEP } from 'app/utils/steps';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useShareArticleMutation from '../../hooks/mutations/useShareArticleMutation';

import AdvancedSettings from './AdvancedSettings';
import ForwardArticleComment from './ForwardArticleComment';
import ForwardArticleStepInfo from './ForwardArticleStepInfo';
import ForwardArticleSummary from './ForwardArticleSummary';
import ForwardArticleTenant from './ForwardArticleTenant';
import useForwardArticleSteps from './hooks/useForwardArticleSteps';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';
import { SelectOption } from './MultiSelect';

interface Props {
  articleOwner: number;
  articleId: number;
  onClose: () => void;
}

const ForwardArticleView = ({ articleId, articleOwner, onClose }: Props) => {
  const { t } = useArticlesTranslation();
  const dispatch = useDispatch();

  const { mutate: shareArticle, isLoading } = useShareArticleMutation();

  const article = useSelector(selectors.getArticle);
  const isArticleShared = !!article?.isShared;

  const [step, setStep] = useState<number>(FIRST_STEP);
  const [selectedTennants, setSelectedTennants] = useState<SelectOption[]>([]);
  const [comment, setComment] = useState<string>('');
  const [allowResharing, setAllowResharing] = useState<boolean>(true);
  const [answerSharing, setAnswerSharing] = useState<boolean>(true);

  const { isCommentVisible } = useForwardArticleSteps({
    step,
    isShared: isArticleShared,
  });

  useEffect(() => {
    if (!article) return;

    setAnswerSharing(article.answerSharing);
  }, [article]);

  useEffect(() => {
    if (selectedTennants.length === 0) {
      return setStep(1);
    }
    return setStep(2);
  }, [selectedTennants]);

  const previousStep = useCallback(() => {
    setStep((prev) => prev - 1);
  }, []);

  const nextStep = useCallback(() => {
    setStep((prev) => prev + 1);
  }, []);

  const onSubmit = () =>
    shareArticle(
      {
        articleId,
        receiverTenants: selectedTennants.map(({ value }) => value),
        sharingComment: comment,
        includeAssets: true,
        allowResharing,
        answerSharing,
      },
      {
        onSuccess: () => {
          if (!isArticleShared) {
            queryClient.invalidateQueries(queryKeys.article(articleId));
          }
          dispatch(
            modalActions.showModal({
              modalType: ModalTypes.SHARE_SUCCESS_MODAL,
              modalProps: {},
            })
          );
        },
      }
    );

  const handleClick = () => {
    if (step === SECOND_STEP) return onSubmit();

    if (selectedTennants.length > 0) return nextStep();

    return onClose();
  };

  return (
    <>
      <ModalHeader step={step} previousStep={previousStep} onClose={onClose} />
      {step === FIRST_STEP && (
        <div className="relative border-b border-b-gray-light-blue/[0.26] mt-6">
          <button className="absolute bottom-[-1px] w-fit px-0.5 pb-2 border-b-[2px] border-b-black  mx-4 text-xs font-bold">
            {t('Forward externally')}
          </button>
        </div>
      )}
      <div className="flex flex-col gap-4 p-4">
        <ForwardArticleTenant
          articleId={articleId}
          articleOwner={
            step === FIRST_STEP && isArticleShared ? articleOwner : undefined
          }
          selectedTennants={selectedTennants}
          setSelectedTennants={setSelectedTennants}
        />

        {step === FIRST_STEP && (
          <ForwardArticleStepInfo
            isArticleShared={isArticleShared}
            step={step}
          />
        )}
        {isCommentVisible && <ForwardArticleComment onChange={setComment} />}
        {step === SECOND_STEP && <ForwardArticleSummary />}
        {step === SECOND_STEP && (
          <AdvancedSettings
            allowResharing={allowResharing}
            sharingEnabled={answerSharing}
            setAllowResharing={setAllowResharing}
            setSharingEnabled={setAnswerSharing}
          />
        )}
      </div>
      <ModalFooter
        isArticleShared={isArticleShared}
        onSubmit={handleClick}
        step={step}
        submitDisabled={isLoading}
        selectedTenants={Boolean(selectedTennants.length > 0)}
      />
    </>
  );
};

export default ForwardArticleView;
