import { cx, keyframes } from '@emotion/css';
import styled from '@emotion/styled';
import RollingCounter from 'app/components/RollingCounter';
import { useAnimate, useScreenBreakpoint } from 'app/hooks';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { isMobile } from 'app/utils';
import { useEffect } from 'react';

interface AnswerFeedbackProps {
  isSubmitted: boolean;
  isError: boolean;
  isLoop: boolean;
  score: number;
  explanation?: string;
}

const fadeOutInSeconds = 1.2;
const slideDownInSeconds = 1;

const AnswerFeedback = ({
  isError,
  isLoop,
  score,
  explanation,
  isSubmitted,
}: AnswerFeedbackProps) => {
  const { t } = useArticlesTranslation();
  const { animationClassName, animate } = useAnimate('shake', 1000);

  const breakpoint = useScreenBreakpoint();
  const mobile = isMobile(breakpoint);

  useEffect(() => {
    if (mobile && isSubmitted) {
      navigator?.vibrate?.(1000);
      animate();
    }
  }, [animate, mobile, isSubmitted]);

  const displayMessage = score > 0 && score < 100;

  return (
    <Container
      className={cx(
        'flex flex-col w-full bg-focus-background rounded-lg p-4 mt-6',
        animationClassName
      )}
    >
      <div className="flex items-center flex-col pb-2">
        <span className="quiz-score text-xs">{t('Quiz Score')}</span>
        <div className="rolling-counter text-sm">
          <RollingCounter value={Math.ceil(score)} />
        </div>
        {displayMessage && (
          <p className="text-xs mt-2">{t('Keep up the good work!')}</p>
        )}
      </div>

      {isError && isLoop ? (
        <div className="flex flex-col mt-2">
          <div className="flex items-center mb-1">
            <div className="text-lg mr-1">&#129488;</div>
            <span className="text-base font-bold">{t('Please try again')}</span>
          </div>
          <p className="text-sm">{t('Give it another shot and keep going!')}</p>
        </div>
      ) : (
        explanation && <p className="text-sm mt-2">{explanation}</p>
      )}
    </Container>
  );
};

const slideFromTopToBottom = keyframes`
  0% {
    margin-top: -35px;
  }
  100% {
    margin-top: 0;
  }
`;

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const Container = styled.div`
  .quiz-score {
    animation: ${fadeIn} ${fadeOutInSeconds}s;
  }
  .rolling-counter {
    animation:
      ${slideFromTopToBottom} ${slideDownInSeconds}s ease-in-out,
      ${fadeIn} ${fadeOutInSeconds}s;
  }
`;

export default AnswerFeedback;
