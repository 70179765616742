import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import useGetArticlesQuery from 'app/api/articles/hooks/useArticles';
import { SystemModules } from 'app/api/auth/constants';
import { useAuthenticatedUser } from 'app/api/auth/hooks';
import { ScrollbarContainer } from 'app/components';
import { selectors } from 'app/store/channels';
import {
  actions,
  selectors as navigationSelectors,
} from 'app/store/navigation';
import { getChannelTitle } from 'app/utils';
import { SearchNormal1 } from 'iconsax-react';
import debounce from 'lodash/debounce';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDispatch, useSelector } from 'react-redux';
import {
  BasicRelevantChannelInfo,
  ListArticle,
} from 'submodules/common-ui/generated/api/gcs';

import Empty from '../Empty';
import SekeletonRow from '../SkeletonRow';

import ArticleResults from './ArticleResults';
import ChannelResults from './ChannelResults';
import RecentResults from './RecentResults';
import SearchBar from './SearchBar';

const storageKey = 'lastSearchResult';

export type Results = {
  articles: ListArticle[];
  channels: BasicRelevantChannelInfo[];
};

const Search = ({ iconClassName }: { iconClassName?: string }) => {
  const [search, setSearch] = useState('');
  const { data: user } = useAuthenticatedUser();
  const {
    i18n: { language: userLanguage },
  } = useTranslation();

  const dispatch = useDispatch();

  const channels = useSelector(selectors.getRelevantChannels);
  const open = useSelector(navigationSelectors.isSearchOpen);

  const onClick = (isOpen: boolean) =>
    dispatch(actions.setIsSearchOpen(isOpen));

  const newsEnabled =
    !user?.hiddenModules?.[SystemModules.News] &&
    !user?.discontinuedModules?.[SystemModules.News];

  const { data: articles, isLoading: isLoadingArticles } = useGetArticlesQuery({
    enabled: search !== '' && !newsEnabled,
    refetchOnWindowFocus: false,
    filters: {
      title: search,
      live: '1',
      timezone: user?.timezone,
    },
    onSuccess: (data) =>
      saveLastSearchResult(
        data.pages[0].data.filter(
          (article) => article.channel?.shownAs === 'cards'
        )
      ),
  });

  if (newsEnabled) return null;

  const searchedChannels = channels.filter((channel) => {
    if (!search) return false;

    const channelTitle = getChannelTitle(channel, userLanguage);

    return channelTitle.toLowerCase().includes(search.toLowerCase());
  });

  const debounceSearch = debounce((value) => setSearch(value), 500);

  const saveLastSearchResult = (data: ListArticle[]) => {
    if (data.length === 0 && searchedChannels.length === 0) return;

    const lastSearchResults: Results = {
      articles: data,
      channels: searchedChannels,
    };

    localStorage.setItem(storageKey, JSON.stringify(lastSearchResults));
  };

  const getLastSearchResult = (): Results | undefined => {
    const lastSearchResults = localStorage.getItem(storageKey);

    if (!lastSearchResults) return;

    return JSON.parse(lastSearchResults) satisfies Results;
  };

  const recentSearchResults = getLastSearchResult();

  const recentResultsVisible = recentSearchResults && search === '';
  const articleResultsVisible = articles && articles.length > 0;
  const channelResultsVisible = Boolean(
    !isLoadingArticles && searchedChannels.length > 0 && search
  );
  const isEmpty = Boolean(
    articles?.length === 0 && searchedChannels.length === 0
  );

  const contentContainerVisible =
    recentResultsVisible ||
    articleResultsVisible ||
    channelResultsVisible ||
    isLoadingArticles ||
    isEmpty;

  return (
    <>
      <button onClick={() => onClick(!open)} className={cx(iconClassName)}>
        <SearchNormal1 size={20} />
      </button>

      {open &&
        createPortal(
          <ModalContainer className="flex justify-center items-center fixed z-50 top-0 left-0 w-screen h-screen">
            <div className="flex justify-center items-start w-full h-full">
              <OutsideClickHandler
                onOutsideClick={() => onClick(false)}
                display="contents"
              >
                <div className="flex flex-col w-full max-w-[420px] md:max-w-[720px] max-h-[400px] mt-[15%] mx-3 md:mx-0 rounded-[18px] border border-focus-background bg-white shadow-atobi">
                  <SearchBar onChange={debounceSearch} />
                  <ScrollbarContainer
                    className={cx({
                      'flex flex-col px-3 pb-2': contentContainerVisible,
                    })}
                  >
                    {recentResultsVisible && (
                      <RecentResults
                        results={recentSearchResults}
                        userLanguage={userLanguage}
                      />
                    )}

                    {isEmpty && <Empty />}

                    {isLoadingArticles &&
                      [...Array(4)].map((_, index) => (
                        <SekeletonRow key={index} index={index} />
                      ))}

                    {articleResultsVisible && (
                      <ArticleResults
                        articles={articles}
                        userLanguage={userLanguage}
                      />
                    )}

                    {channelResultsVisible && (
                      <ChannelResults channels={searchedChannels} />
                    )}
                  </ScrollbarContainer>
                </div>
              </OutsideClickHandler>
            </div>
          </ModalContainer>,
          document.body
        )}
    </>
  );
};

const ModalContainer = styled.div`
  background-color: rgba(249, 250, 251, 0.5);
  backdrop-filter: blur(5px);
`;
export default Search;
