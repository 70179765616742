import { cx } from '@emotion/css';
import { Book1, TaskSquare } from 'iconsax-react';

export enum PreviewMode {
  Action = 'action',
  Read = 'read',
}

interface PreviewModePickerProps {
  previewMode: PreviewMode;
  className?: string;
  onClick(mode: PreviewMode): void;
}

const PreviewModePicker = ({
  previewMode,
  className,
  onClick,
}: PreviewModePickerProps) => {
  const actions = [
    {
      Icon: Book1,
      mode: PreviewMode.Read,
    },
    {
      Icon: TaskSquare,
      mode: PreviewMode.Action,
    },
  ];

  return (
    <div
      className={cx(
        'flex items-center p-1 gap-2 rounded-[48px] bg-white text-ceil shadow-atobi',
        className
      )}
    >
      {actions.map(({ Icon, mode }) => (
        <button
          key={mode}
          className={cx(
            'flex items-center justify-center h-10 w-12 rounded-[39px] transition-all',
            {
              'text-focus bg-focus-background': previewMode === mode,
            }
          )}
          onClick={() => onClick(mode)}
        >
          <Icon size={24} />
        </button>
      ))}
    </div>
  );
};

export default PreviewModePicker;
