import { useDispatch, useSelector } from 'app/hooks';
import { selectors as requestSelectors } from 'app/store/request';
import { actions, selectors } from 'app/store/sisense';
import dayjs from 'dayjs';
import { useCallback, useEffect } from 'react';
import { useQuery } from 'react-query';

import queryKeys from '../queryKeys';
import getWebAccessToken from '../resources/getWebAccessToken';

const getExpiresInTime = (expiresOn: number) =>
  dayjs.unix(expiresOn).diff(dayjs());

const useGetWebAccessToken = (enabled = true) => {
  const dispatch = useDispatch();
  const cachedSisenseToken = useSelector(selectors.getSisenseToken);
  const cachedSisenseTokenExpiration = useSelector(
    selectors.getSisenseTokenExpiresOn
  );

  const requestInstanceReady = useSelector(
    requestSelectors.getRequestInstanceReady
  );
  const { data, isLoading, isError } = useQuery(
    queryKeys.getWebAccessToken(),
    getWebAccessToken,
    {
      enabled:
        requestInstanceReady &&
        !cachedSisenseToken &&
        cachedSisenseTokenExpiration === undefined &&
        enabled,
    }
  );

  const expireToken = useCallback(() => {
    dispatch(actions.sisenseTokenExpired());
  }, [dispatch]);

  useEffect(() => {
    if (
      !data ||
      !data.data ||
      (cachedSisenseToken && cachedSisenseTokenExpiration)
    )
      return;

    dispatch(actions.sisenseTokenRetrieved(data.data.token));
    dispatch(actions.sisenseTokenExpiresOnRetrieved(data.data.expiresOn));
  }, [cachedSisenseToken, cachedSisenseTokenExpiration, data, dispatch]);

  useEffect(() => {
    if (!cachedSisenseToken || !cachedSisenseTokenExpiration) return;

    if (getExpiresInTime(cachedSisenseTokenExpiration) <= 0) {
      expireToken();
      return;
    }

    const timeout = setTimeout(() => {
      expireToken();
    }, getExpiresInTime(cachedSisenseTokenExpiration));

    return () => {
      clearTimeout(timeout);
    };
  }, [cachedSisenseToken, cachedSisenseTokenExpiration, expireToken]);

  return { token: cachedSisenseToken, isLoading, isError };
};

export default useGetWebAccessToken;
