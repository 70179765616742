import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BasicChannelInfo,
  BasicRelevantChannelInfo,
} from 'submodules/common-ui/generated/api/gcs';

import { NAME } from './constants';
import { State } from './types';

const initialState: State = {
  channels: [],
  relevantChannels: [],
};

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setRelevantChannels: (
      state,
      { payload }: PayloadAction<BasicRelevantChannelInfo[]>
    ) => {
      state.relevantChannels = payload;
    },
    deleteChannel: (state, { payload }: PayloadAction<BasicChannelInfo>) => {
      state.channels = state.channels.filter(
        (channel) => channel.id !== payload.id
      );
    },
  },
});
export default slice;
