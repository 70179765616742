import narrowUnknown from 'app/utils/narrowUnknown';
import { useQuery } from 'react-query';
import { ScormRegistrationRequest } from 'submodules/common-ui/generated/api/gcs';

import { queryKeys, resources } from '../index';

const useScormAction = ({
  blockId,
  languageCode,
  actionInstanceId,
  timezone,
  enabled,
}: ScormRegistrationRequest & { enabled: boolean }) => {
  const {
    data,
    isLoading,
    isFetching,
    error: apiError,
    refetch,
  } = useQuery(
    queryKeys.scormAction(blockId, actionInstanceId, timezone),
    () =>
      resources.addScormRegistration({
        blockId,
        languageCode,
        actionInstanceId,
        timezone,
      }),
    {
      enabled,
      refetchOnWindowFocus: false,
    }
  );

  return {
    data,
    isLoading,
    isFetching,
    error: narrowUnknown(apiError),
    apiError,
    refetch,
  };
};

export { useScormAction };
export default useScormAction;
