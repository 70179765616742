import { Modal } from 'app/components';
import { actions } from 'app/store/modal';
import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import ActiveLanguages from './components/ActiveLanguages';
import LanguagesDropdown from './components/LanguagesDropdown';
import ModalFooter from './components/ModalFooter';
import ModalHeader from './components/ModalHeader';
import { LanguageSettingsProvider } from './context';

export interface LanguagesModalProps {
  onSaveClick?: VoidFunction;
}

const LanguagesModal = ({ onSaveClick }: LanguagesModalProps) => {
  const dispatch = useDispatch();
  const onClose = () => dispatch(actions.hideModal());

  const onSave = () => {
    if (onSaveClick) onSaveClick();
  };

  return (
    <LanguageSettingsProvider>
      <Modal
        onClose={onClose}
        className="w-[434px] overflow-visible overflow-y-visible"
        containerPaddingClass="p-0"
      >
        <div className="pt-6 px-6">
          <ModalHeader />
          <LanguagesDropdown onSaveClick={onSave} />
          <ActiveLanguages onSave={onSave} />
        </div>
        <ModalFooter />
        <ReactTooltip place="top" effect="solid" class="react-tooltip" />
      </Modal>
    </LanguageSettingsProvider>
  );
};

export default LanguagesModal;
