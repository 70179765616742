import { Element, useEditor, useNode } from '@craftjs/core';
import { cx } from '@emotion/css';
import useIsEditorEnabled from 'app/hooks/useIsEditorEnabled';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { ActionTypeEnum } from 'submodules/common-ui/generated/api/gcs';

import CategoryDropdown from '../components/CategoryDropdown';
import Completion from '../components/Completion';
import Deadline from '../components/Deadline';
import Mandatory from '../components/Mandatory';
import Privacy from '../components/Privacy';
import Recurring from '../components/Recurring';
import TaskType from '../components/TaskType';
import Title from '../components/Title';

import YesNoNaOption from './Option/YesNoNaOption';
import YesNoNaOptionsContainer from './Option/YesNoNaOptionsContainer';

import { YesNoNaBlockProps } from '.';

interface EditorUserModeProps {
  setHasFocus: Dispatch<SetStateAction<boolean>>;
  setProp: (cb: any, throttleRate?: number | undefined) => void;
  setBackspace: Dispatch<SetStateAction<boolean>>;
}

const EditorUserMode = ({
  setHasFocus,
  setProp,
  setBackspace,
}: EditorUserModeProps) => {
  const { t } = useArticlesTranslation();
  const {
    query: { node: getNode },
    nodes,
  } = useEditor((state) => ({ nodes: state.nodes }));

  const { selected, yesNoNaProps, nodeId } = useNode((node) => ({
    selected: node.events.selected,
    nodeId: node.id,
    yesNoNaProps: node.data.props as YesNoNaBlockProps,
  }));

  const { enabled } = useIsEditorEnabled();

  const getYesNoNaOptionNodes = useCallback(() => {
    const { descendants } = getNode(nodeId);
    const descendantsNodes = descendants(true);

    return descendantsNodes.filter(
      (n) => nodes[n]?.data.name === 'YesNoNaOption'
    );
  }, [getNode, nodeId, nodes]);

  const choiceConflictError = useCallback(() => {
    const yesNoNaOptionNodes = getYesNoNaOptionNodes();

    if (
      yesNoNaOptionNodes.filter((n) => nodes[n].data.props.correct === true)
        .length > 1
    ) {
      return 'Correct';
    }

    if (
      yesNoNaOptionNodes.filter((n) => nodes[n].data.props.correct === false)
        .length > 1
    ) {
      return 'Incorrect';
    }

    if (
      yesNoNaOptionNodes.filter((n) => nodes[n].data.props.correct === null)
        .length > 1
    ) {
      return 'Neutral';
    }
  }, [getYesNoNaOptionNodes, nodes]);

  useEffect(() => {
    if (yesNoNaProps.mandatory === undefined)
      setProp((props: YesNoNaBlockProps) => {
        props.mandatory = true;
      });
  }, [yesNoNaProps.mandatory, setProp]);

  return (
    <div className="flex justify-between m-2 p-3 rounded border border-gray-light shadow-action">
      <div className="flex flex-col w-full">
        <div className="flex items-center">
          <TaskType type={ActionTypeEnum.YesNoTask} disabled={!enabled} />
          <Title
            onFocusChange={(focus) => setHasFocus(focus)}
            placeholder={`${t('Title (optional)')}`}
            className="font-bold placeholder:font-normal"
            description={yesNoNaProps.title}
            enabled={enabled}
            onChange={(value) =>
              setProp((props: YesNoNaBlockProps) => {
                props.title = value;
              })
            }
          />
        </div>
        <div className="flex mt-2 ml-[32px]">
          <Title
            onFocusChange={(focus) => setHasFocus(focus)}
            placeholder={`${t('Question')}*`}
            enabled={enabled}
            error={!yesNoNaProps.description && !selected}
            description={yesNoNaProps.description}
            onChange={(value) =>
              setProp((props: YesNoNaBlockProps) => {
                props.description = value;
              })
            }
          />
        </div>

        <Element id="optionsContainer" is={YesNoNaOptionsContainer} canvas>
          <YesNoNaOption
            text={{
              type: 'paragraph',
              children: [
                {
                  type: 'text',
                  value: 'Yes',
                  format: {},
                },
              ],
            }}
            correct={true}
          />
          <YesNoNaOption
            text={{
              type: 'paragraph',
              children: [
                {
                  type: 'text',
                  value: 'No',
                  format: {},
                },
              ],
            }}
            correct={false}
          />
          <YesNoNaOption
            text={{
              type: 'paragraph',
              children: [
                {
                  type: 'text',
                  value: 'Not applicable',
                  format: {},
                },
              ],
            }}
          />
        </Element>

        {choiceConflictError() && (
          <div className="text-error text-xs pt-2">
            {t('Only one choice can be marked as {{status}}', {
              status: choiceConflictError(),
            })}
          </div>
        )}

        <div className="flex text-gray-dark mt-2.5 items-center">
          {(yesNoNaProps.schedule?.frequency === 'once' ||
            !yesNoNaProps.schedule) && (
            <Deadline
              disabled={!enabled}
              selected={yesNoNaProps.deadline}
              onChange={(value) =>
                setProp((props: YesNoNaBlockProps) => {
                  props.deadline = value ?? undefined;
                  props.schedule = undefined;
                })
              }
            />
          )}
          <Recurring
            disabled={!enabled}
            scheduleDate={yesNoNaProps.schedule}
            onChange={(date) =>
              setProp((props: YesNoNaBlockProps) => {
                props.schedule = date;
                props.deadline = undefined;
              })
            }
          />
          <CategoryDropdown
            disabled={!enabled}
            selectedCategories={yesNoNaProps.categories}
            onChange={(category) =>
              setProp((props: YesNoNaBlockProps) => {
                props.categories = category ? [category] : [];
              })
            }
            onOpenCallback={setBackspace}
          />
          <div
            className={cx('flex items-center ml-auto text-gray-dark gap-1.5', {
              hidden: !selected && !yesNoNaProps.deadline,
            })}
          >
            <Privacy
              disabled={!enabled}
              isPublic={yesNoNaProps.public}
              onClick={(value) =>
                setProp((props: YesNoNaBlockProps) => {
                  props.public = value;
                })
              }
            />
            <div className="bg-gray-light h-4 w-[1px]" />
            <Completion
              disabled={!enabled}
              required={yesNoNaProps.required}
              onClick={(value) =>
                setProp((props: YesNoNaBlockProps) => {
                  props.required = value;
                })
              }
            />
            <div className="bg-gray-light h-4 w-[1px]" />
            <Mandatory
              checked={!!yesNoNaProps.mandatory}
              onChange={() =>
                setProp((props: YesNoNaBlockProps) => {
                  props.mandatory = !yesNoNaProps.mandatory;
                })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditorUserMode;
