import { request } from 'app/api/gcsRequest';
import config from 'app/config';
import { ChannelApi } from 'submodules/common-ui/generated/api/gcs';

const getChannel = (channelId: number, live?: '1') =>
  new ChannelApi(undefined, config.env.gcsApiUrl, request()).getChannel(
    channelId,
    live
  );

export { getChannel };
export default getChannel;
