import useGetSettings from 'app/api/pwaSettings/hooks/useGetSettings';
import env from 'app/config/env';
import useManifestUrl from 'app/hooks/useManifestUrl';
import { resolveSubdomain } from 'app/utils/subdomain';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Head = () => {
  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  const subdomain = resolveSubdomain();
  const baseUrl = `${env.subdomainApiUrl}/${subdomain}`;

  const { settings, isLoading } = useGetSettings();

  const { manifestUrl } = useManifestUrl();

  if (isLoading) return <></>;

  const renderMicrosoftClarityScript = () => (
    <script type="text/javascript">
      {`
      (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "${env.microsoftClarityProjectId}");
    `}
    </script>
  );

  return (
    <HelmetProvider>
      {settings ? (
        <Helmet>
          <link
            href={`${baseUrl}/fonts`}
            rel="stylesheet"
            crossOrigin="anonymous"
          />
          {env.microsoftClarityProjectId && renderMicrosoftClarityScript()}
          {manifestUrl && <link rel="manifest" href={manifestUrl} />}
          {settings.favicons.map((favicon) => (
            <link
              key={favicon.url}
              rel={favicon.rel}
              type={favicon.type}
              sizes={favicon.size}
              href={favicon.url}
            />
          ))}
          <meta name="theme-color" content={settings.themeColor} />
          <meta name="description" content={settings.description} />
          {iOS && (
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover"
            />
          )}
          <title>{settings.title}</title>
        </Helmet>
      ) : (
        <Helmet>
          <link
            href={`${baseUrl}/fonts`}
            rel="stylesheet"
            crossOrigin="anonymous"
          />

          {env.microsoftClarityProjectId && renderMicrosoftClarityScript()}
        </Helmet>
      )}
    </HelmetProvider>
  );
};

export default Head;
