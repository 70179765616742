import ActionToast from 'app/components/Toast/ActionToast';
import SimpleToast from 'app/components/Toast/SimpleToast';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { toast } from 'react-toastify';

import { useArticlesContext } from '../context';

import useDeleteArticleMutation from './useDeleteArticleMutation';
import useDuplicateArticleMutation from './useDuplicateArticleMutation';

interface CopyProps {
  articleId: number;
  newTitle?: string;
  deadline?: string;
  onSuccess?: (articleIds: number[]) => void;
}

const useCopyArticle = () => {
  const { t } = useArticlesTranslation();
  const { refetch: refetchArticlesList } = useArticlesContext();
  const { mutate: duplicate, isLoading } = useDuplicateArticleMutation();
  const { mutate: deleteArticle } = useDeleteArticleMutation();

  const showToast = (content: JSX.Element) => {
    toast.dismiss();
    toast.clearWaitingQueue();

    toast(content, {
      position: 'bottom-center',
      autoClose: 8000,
      closeButton: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      containerId: 'articleActions',
    });
  };

  const copy = ({ articleId, deadline, newTitle, onSuccess }: CopyProps) =>
    duplicate(
      {
        articleId,
        newTitle: newTitle || null,
        newDeadline: deadline || null,
        concatTitle: null,
        objectIds: [],
        objectType: null,
      },
      {
        onSuccess: ({ articleIds }) => {
          onSuccess?.(articleIds);
          if (!onSuccess)
            showToast(
              <ActionToast
                text={t('Article duplicated')}
                action={`${t('Undo')}`}
                onClick={() => {
                  deleteArticle(
                    { id: articleIds[0] },
                    { onSuccess: () => refetchArticlesList?.() }
                  );
                  toast.dismiss();
                }}
              />
            );
          refetchArticlesList?.();
        },
        onError: () =>
          showToast(<SimpleToast text={t('Copy article failed')} />),
      }
    );

  return { copy, isLoading };
};

export default useCopyArticle;
