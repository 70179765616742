import { cx } from '@emotion/css';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import YesNoIcon from 'assets/icons/yes-no.svg?react';
import {
  Chart2,
  Eye,
  Gallery,
  MessageQuestion,
  MessageText1,
  Teacher,
  TickSquare,
} from 'iconsax-react';
import { ReactNode } from 'react';
import {
  ActionTypeEnum,
  MultiChoiceBlockRenderTypeEnum,
} from 'submodules/common-ui/generated/api/gcs';

interface ActionContainerProps {
  canAnswer: boolean;
  isComplete?: boolean | null;
  type: ActionTypeEnum;
  renderType?: MultiChoiceBlockRenderTypeEnum;
  children?: ReactNode;
  isMandatory?: boolean;
}

const ActionContainer = ({
  type,
  children,
  canAnswer,
  renderType,
  isMandatory = true,
  isComplete = false,
}: ActionContainerProps) => {
  const { t } = useArticlesTranslation();

  const renderIcon = () => {
    const size = 24;
    const className = canAnswer ? 'text-ceil' : 'text-ceil text-opacity-50';
    switch (type) {
      case ActionTypeEnum.SimpleTask:
        return <TickSquare size={size} className={className} />;
      case ActionTypeEnum.MediaTask:
        return <Gallery size={size} className={className} />;
      case ActionTypeEnum.OpenQuestionTask:
        return <MessageText1 size={size} className={className} />;
      case ActionTypeEnum.YesNoTask:
        return <YesNoIcon height={size} width={24} className={className} />;
      case ActionTypeEnum.ScormTask:
        return <Teacher height={size} width={24} className={className} />;
      case ActionTypeEnum.MultiChoice: {
        if (renderType === MultiChoiceBlockRenderTypeEnum.Poll) {
          return <Chart2 size={size} className={className} />;
        }

        if (renderType === MultiChoiceBlockRenderTypeEnum.YesNoNa) {
          return <YesNoIcon height={size} width={24} className={className} />;
        }

        return <MessageQuestion size={size} className={className} />;
      }
      default:
        break;
    }
  };

  return (
    <div
      className={cx(
        'flex flex-col mt-7 rounded-xl relative border border-focus-background',
        {
          'bg-fade-blue': isComplete || !canAnswer,
          'bg-white': !isComplete && canAnswer,
        }
      )}
    >
      <div className="p-6">
        <div className="flex items-center justify-center w-[48px] h-[48px] rounded-full bg-hover-blue absolute left-0 right-0 bottom-0 -top-6 my-0 mx-auto">
          <div className="flex items-center justify-center w-[42px] h-[42px] rounded-full bg-fade-blue relative">
            {renderIcon()}
            {!canAnswer && (
              <div
                className={cx('absolute bottom-1.5 right-1.5', {
                  'bg-fade-blue': isComplete || !canAnswer,
                  'bg-white': !isComplete && canAnswer,
                })}
              >
                <Eye size={14} className="text-focus" />
              </div>
            )}
          </div>
        </div>
        {children}
      </div>
      {!isMandatory && (
        <div className="flex justify-center bg-gray-white-shadow py-2 rounded-b-xl text-xs text-gray-light-blue">
          {t('Optional')}
        </div>
      )}
    </div>
  );
};

export default ActionContainer;
