import { useArticlesTranslation } from 'app/internationalization/hooks';
import Spinner from 'assets/icons/spinner.svg?react';
import { TickCircle } from 'iconsax-react';
import { ActionTypeEnum } from 'submodules/common-ui/generated/api/gcs';

const useButtonLabel = (
  isLoading: boolean,
  isSuccess: boolean,
  isTooEarly: boolean,
  type?: ActionTypeEnum
) => {
  const { t } = useArticlesTranslation();

  if (isLoading) {
    return <Spinner className="w-6 h-6 animate-spin text-white" />;
  }
  if (isSuccess) {
    return <TickCircle className="w-6 h-6 text-white" />;
  }
  if (isTooEarly) {
    return t('Too early');
  }
  switch (type) {
    case ActionTypeEnum.MediaTask:
    case ActionTypeEnum.YesNoTask:
    case ActionTypeEnum.MultiChoice:
    case ActionTypeEnum.OpenQuestionTask:
      return t('Confirm');
    case ActionTypeEnum.SimpleTask:
      return t('Mark as complete');
    default:
      return t('Confirm');
  }
};

export default useButtonLabel;
