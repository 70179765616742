import { cx } from '@emotion/css';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import useGetCategoryName from 'app/pages/Editor/hooks/useGetCategoryName';
import { Hashtag } from 'iconsax-react';
import { useEffect, useRef, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { GetCategory } from 'submodules/common-ui/generated/api/gcs';

import CategoryPopup from './CategoryPopup';

interface Props {
  disabled: boolean;
  selectedCategories?: GetCategory[];
  onChange: (category?: GetCategory) => void;
  onOpenCallback: (value: boolean) => void;
}

const CategoryDropdown = ({
  onChange,
  selectedCategories,
  disabled,
  onOpenCallback,
}: Props) => {
  const popupRef = useRef<HTMLDivElement>(null);

  const { t } = useArticlesTranslation();
  const { getCategoryName } = useGetCategoryName();

  const [open, setOpen] = useState(false);
  const [bottomPosition, setBottomPosition] = useState<number | null>(null);

  useEffect(() => {
    onOpenCallback(open);

    const popupElement = popupRef.current;
    if (!popupElement) return setBottomPosition(null);

    const popUpRect = popupElement.getBoundingClientRect();
    const isOutsideViewport = popUpRect.bottom > window.innerHeight;

    const popoverBottomHeight = 45;

    setBottomPosition(isOutsideViewport ? popoverBottomHeight : null);
  }, [open, onOpenCallback]);

  const selectedCategory = selectedCategories?.[0];

  const togglePopup = () => setOpen((prevOpen) => !prevOpen);

  return (
    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
      <button
        disabled={disabled}
        onClick={togglePopup}
        className={cx('text-xs flex', {
          'text-grayscale-secondary': !!selectedCategory,
          'hover:text-grayscale-secondary': !selectedCategory,
        })}
      >
        <Hashtag className="mr-1" size={16} />
        {selectedCategory
          ? getCategoryName(selectedCategory)
          : t('No category')}
      </button>
      {open && (
        <CategoryPopup
          bottomPosition={bottomPosition}
          popupRef={popupRef}
          onChange={onChange}
          selectedCategory={selectedCategory}
          onOpen={setOpen}
          getCategoryName={getCategoryName}
        />
      )}
    </OutsideClickHandler>
  );
};

export default CategoryDropdown;
