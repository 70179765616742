import { useEditor, useNode } from '@craftjs/core';
import { cx } from '@emotion/css';
import { Dot, Spinner } from 'app/components';
import useFileUpload from 'app/hooks/useFileUpload';
import useIsEditorEnabled from 'app/hooks/useIsEditorEnabled';
import {
  useArticlesTranslation,
  useCommonTranslation,
} from 'app/internationalization/hooks';
import BlockTypes from 'app/pages/Editor/helpers/constants';
import { selectors as authSelectors } from 'app/store/auth';
import { selectors as editorSelectors } from 'app/store/editor';
import { getSizeStringFromUrl, logger } from 'app/utils';
import { ExportSquare, Folder, InfoCircle, Refresh } from 'iconsax-react';
import prettyBytes from 'pretty-bytes';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ExternalFile,
  InternalFileGet,
} from 'submodules/common-ui/generated/api/gcs/api';

import MAX_FILE_SIZE from '../../constants';
import useDownloadFile from '../../hooks/useDownloadFile';
import BaseBlockContainer from '../BaseBlockContainer';
import MediaBlockOptionSettings, {
  AddedFile,
  UploadedFile,
} from '../MediaBlockSettings/MediaBlockOptionSettings';
import { SettingsState } from '../MediaBlockSettings/types';

interface FileBlockProps {
  error?: string;
  file?: File;
  fileSchema?: InternalFileGet | ExternalFile;
  translationStatus: string;
  isUploading?: boolean;
}

const FileBlock = ({ error, file, fileSchema }: FileBlockProps) => {
  const { t: at } = useArticlesTranslation();
  const { t: ct } = useCommonTranslation();
  const { enabled } = useIsEditorEnabled();
  const { upload, isLoading } = useFileUpload();
  const [clicked, setClicked] = useState<boolean>();
  const [fileSize, setFileSize] = useState<string | undefined>('');
  const fileInputRef = useRef<HTMLInputElement>(null);

  const canEdit = useSelector(editorSelectors.getCanEdit);
  const tenant = useSelector(authSelectors.getSelectedTenant);

  const { openFileInBrowser } = useDownloadFile({
    fileSchema,
    tenantAlias: tenant?.alias,
    fileId: fileSchema?.type === 'internal' ? fileSchema.id : '',
    fileName: fileSchema?.name ?? '',
  });

  const {
    actions: {
      history: { ignore },
    },
  } = useEditor();

  const {
    actions: { setProp },
    connectors: { connect },
    id,
    selected,
    uploading,
  } = useNode((node) => ({
    selected: node.events.selected,
    uploading: Boolean(node.data?.custom?.uploading),
  }));

  const fileSrc = file ? URL.createObjectURL(file) : fileSchema?.url;

  useEffect(() => {
    ignore().setCustom(id, (props: FileBlockProps) => {
      props.isUploading = isLoading;
    });
  }, [isLoading, ignore, id]);

  useEffect(() => {
    if (!enabled) return;

    if (!fileSrc && clicked === undefined) {
      setClicked(true);
    } else if (!selected) {
      setClicked(false);
    }
  }, [clicked, fileSrc, enabled, selected]);

  useEffect(() => {
    if (!file && !fileSchema?.url) return;

    if (file) {
      setFileSize(prettyBytes(file.size, { maximumFractionDigits: 0 }));
      return;
    }

    if (fileSchema?.type === 'external') {
      getSizeStringFromUrl(fileSchema.url).then(setFileSize);
      return;
    }

    if (fileSchema?.directUrls) {
      getSizeStringFromUrl(
        fileSchema.directUrls.small?.url ?? fileSchema.directUrls.original.url
      ).then(setFileSize);

      return;
    }

    if (fileSchema?.url) {
      getSizeStringFromUrl(fileSchema.url).then(setFileSize);
    }
  }, [file, fileSchema]);

  const triggerInputClick = () => {
    if (!fileInputRef.current || !enabled) return;

    fileInputRef.current.click();
  };

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const fileToUpload = e?.target.files?.[0];
    if (!fileToUpload)
      return logger.debug('MediaPicker onFileChange file is null');

    if (fileToUpload.size > MAX_FILE_SIZE) {
      setProp((props: FileBlockProps) => {
        props.file = undefined;
        props.fileSchema = undefined;
        props.error = `${at('Your file is too big. The limit is {{size}} MB', {
          size: 500,
        })}`;
      });
      return;
    }

    uploadFile(fileToUpload);
  };

  const uploadFile = (fileToUpload: File) => {
    upload(fileToUpload, {
      onSuccess: (response) => {
        setProp((props: FileBlockProps) => {
          props.error = '';
          props.file = fileToUpload;
          props.fileSchema = {
            type: 'internal',
            id: response.id,
            url: '',
            name: fileToUpload.name,
            translationStatus: 'draft',
          } as InternalFileGet;
        });
      },
      onError: logger.error,
    });
  };

  return (
    <div
      ref={(ref) => ref && connect(ref)}
      role="presentation"
      onClick={() => {
        if (enabled && !clicked) {
          setClicked(true);
          return;
        }

        if (!enabled) openFileInBrowser();
      }}
    >
      <BaseBlockContainer
        deleteOnBackspace={selected}
        nodeId={id}
        options={[
          {
            disabled: !canEdit,
            Icon: Refresh,
            text: at('Replace'),
            onClick: triggerInputClick,
          },
        ]}
        selected={selected}
        showTranslationStatus={false}
        type="File"
      >
        <div
          className={cx(
            'h-[68px] flex items-center gap-6 m-2 py-3 px-6 text-focus bg-focus-background rounded cursor-pointer',
            {
              'border border-error': Boolean(error),
            }
          )}
          role="presentation"
          onClick={() => enabled && clicked && triggerInputClick()}
        >
          {!uploading && !fileSchema && (
            <>
              <Folder size={24} />
              <div className="text-grayscale-secondary font-bold">
                {at('Upload file')}
                {error && (
                  <p className="flex items-center gap-1 text-sm text-error">
                    <InfoCircle size={18} />
                    {error}
                  </p>
                )}
              </div>
            </>
          )}
          {uploading && (
            <>
              <Spinner className="!m-0" />
              <div className="font-bold">{ct('Uploading...')}</div>
            </>
          )}
          {!uploading && fileSchema && (
            <>
              <Folder size={24} />
              <div>
                <p className="font-bold line-clamp-1">
                  {fileSchema.name.split('.').shift()}
                </p>
                <p className="flex items-center gap-2 text-sm">
                  {fileSchema.name.split('.').pop()}
                  {fileSize && (
                    <>
                      <Dot className="text-focus" />
                      {fileSize}
                    </>
                  )}
                </p>
              </div>
            </>
          )}
          {!enabled && <ExportSquare className="ml-auto" />}
        </div>

        <input
          type="file"
          ref={fileInputRef}
          className="hidden"
          onChange={onFileChange}
          disabled={!enabled}
        />
      </BaseBlockContainer>
    </div>
  );
};

const FileSettings = () => {
  const { t } = useArticlesTranslation();

  const {
    actions: {
      history: { ignore },
    },
  } = useEditor();

  const {
    actions: { setProp },
    selectedProps,
    nodeId,
  } = useNode((node) => ({
    selectedProps: node.data.props,
    nodeId: node.id,
  }));

  const state: SettingsState = {
    id: selectedProps?.fileSchema?.id,
    url: selectedProps?.fileSchema?.url,
    name: selectedProps?.fileSchema?.name,
  };

  const getPropPayload = (
    data: UploadedFile | AddedFile
  ): {
    file: File | undefined;
    fileSchema: InternalFileGet;
  } => ({
    file: data.data,
    fileSchema: {
      type: 'internal',
      id: data.type === 'uploaded' ? data.id : '',
      url: '',
      name: data.data.name,
      translationStatus: 'draft',
    } as InternalFileGet,
  });

  return (
    <MediaBlockOptionSettings
      hasEnterUrlOption={false}
      inputLabel={t('File')}
      state={state}
      onChange={(data) => {
        ignore().setCustom(nodeId, (props: FileBlockProps) => {
          if (typeof data !== 'string' && data.type === 'error') {
            props.isUploading = false;
            return;
          }

          props.isUploading = typeof data !== 'string' && data.type === 'added';
        });
        setProp((props: FileBlockProps) => {
          if (typeof data !== 'string' && data.type === 'error') {
            props.file = undefined;
            props.fileSchema = undefined;
            props.error = data.error;
            return;
          }

          const { file, fileSchema } = getPropPayload(
            data as UploadedFile | AddedFile
          );
          props.file = file;
          props.fileSchema = fileSchema;
          props.error = '';
        });
      }}
    />
  );
};

FileBlock.craft = {
  displayName: BlockTypes.FileBlock,
  related: {
    settings: FileSettings,
  },
};

export { FileBlock };
export default FileBlock;
