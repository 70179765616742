import { SerializedLexicalText } from 'app/components/LexicalEditor/types';
import { SerializedLexicalNode } from 'lexical';
import {
  ListItem,
  TextVariantItem,
  TextOrLink,
  TextBlockVariant,
  ListListTypeEnum,
} from 'submodules/common-ui/generated/api/gcs';

import { getFormat } from './lexicalToApi';

const getTextType = (type: string): TextVariantItem['type'] => {
  if (type === 'heading') return 'heading';
  else if (type === 'list') return 'list';
  else return 'paragraph';
};

const editorToApi = (editor: SerializedLexicalNode[]): TextBlockVariant => {
  const items = editor.map((item): TextVariantItem => {
    const { children, listType } = item as SerializedLexicalText;

    const itemChildren = children ?? [];
    const textType = getTextType(item.type);

    if (textType === 'list') {
      return getList(itemChildren, listType);
    }

    if (textType === 'heading') {
      return getHeading(itemChildren, item);
    }

    return {
      children: itemChildren.map((each) => {
        return {
          type: 'text',
          format: getFormat(each.format) ?? {},
          value: each.text ?? '',
        };
      }),

      type: 'paragraph',
    };
  });

  return {
    translationStatus: 'draft',
    items,
  };
};

const getHeading = (
  items: SerializedLexicalText[],
  item: SerializedLexicalNode
): TextVariantItem => {
  const { tag, type } = item as SerializedLexicalText;
  const mappedChildren: TextOrLink[] = items.map((child) => {
    if (type === 'link') {
      return {
        type: 'link',
        children: (child.children ?? []).map((eachPart) => {
          return {
            format: getFormat(eachPart.format) ?? {},
            type: 'text',
            value: eachPart?.text ?? '',
          };
        }),
        url: child.url ?? '',
      };
    }

    return {
      type: 'text',
      format: getFormat(child.format) ?? {},
      value: child.text ?? '',
    };
  });

  return {
    children: mappedChildren,
    level: Number(tag?.substring(1, 2)),
    type: 'heading',
  };
};

const getList = (
  items: SerializedLexicalText[],
  listType?: string
): TextVariantItem => {
  const treatedChildren: ListItem[] = items.map((child) => {
    return {
      children: (child.children ?? []).map((each) => {
        return {
          type: 'text',
          format: getFormat(each.format) ?? {},
          value: each.text ?? '',
        };
      }),
      type: 'list-item',
    };
  });

  return {
    children: treatedChildren,
    type: 'list',
    listType: listType as ListListTypeEnum,
  };
};

export default editorToApi;
