import { selectors } from 'app/store/editor';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useCanChangeArticleStructure = () => {
  const canEdit = useSelector(selectors.getCanEdit);
  const selectedLanguages = useSelector(selectors.getSelectedLanguages);
  const defaultIsActive =
    selectedLanguages.length === 1 ||
    selectedLanguages.some((l) => l.isDefault && l.active);

  const [canChangeArticleStructure, setCanChangeArticleStructure] =
    useState(true);

  useEffect(
    () => setCanChangeArticleStructure(defaultIsActive && canEdit),
    [canEdit, defaultIsActive]
  );

  return {
    canChangeArticleStructure,
  };
};

export default useCanChangeArticleStructure;
