import { MANAGER_ROLES } from 'app/api/auth/constants';
import App from 'app/App';
import ChannelBreadcrumb from 'app/components/Breadcrumbs/ChannelBreadcrumb';
import DefaultBreadcrumb from 'app/components/Breadcrumbs/DefaultBreadcrumb';
import KPIBreadcrumb from 'app/components/Breadcrumbs/KPIBreadcrumb';
import MissionActivityBreadcrumb from 'app/components/Breadcrumbs/MissionActivityBreadcrumb';
import MissionBreadcrumb from 'app/components/Breadcrumbs/MissionBreadcrumb';
import MissionReportBreadcrumb from 'app/components/Breadcrumbs/MissionReportBreadcrumb';
import MissionsListBreadcrumb from 'app/components/Breadcrumbs/MissionsListBreadcrumb';
import NewsBreadcrumb from 'app/components/Breadcrumbs/NewsBreadcrumb';
import VisualGuideBreadcrumb from 'app/components/Breadcrumbs/VisualGuideBreadcrumb';
import ChannelsIcon from 'app/components/Navigation/components/ChannelIcon';
import { AuthenticatedPageLayout } from 'app/layouts';
import {
  Book,
  Book1,
  CardEdit,
  DocumentText1,
  Graph,
  Hashtag,
  LampCharge,
  Messages2,
  People,
  Setting3,
  Shop,
  TaskSquare,
  UserSquare,
} from 'iconsax-react';
import { lazy } from 'react';
import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import LikeIcon from 'remixicon-react/Heart3LineIcon';

import PrivateRoute from './PrivateRoute';
import routes from './routes';

const NikeSKURedirect = lazy(() => import('../pages/NikeSKURedirect'));
const PageNotFound = lazy(() => import('../pages/PageNotFound'));
const NewsFeed = lazy(() => import('../pages/NewsFeed'));
const WikiLanding = lazy(() => import('../pages/wiki/WikiLanding'));
const NewsArticle = lazy(() => import('../pages/NewsArticle'));
const WikiArticle = lazy(() => import('../pages/wiki/WikiArticle'));
const Missions = lazy(() => import('../pages/missions/Missions'));
const MissionsList = lazy(() => import('../pages/missions/MissionsList'));
const Mission = lazy(() => import('../pages/missions/Mission'));
const MissionReport = lazy(() => import('../pages/missions/MissionReport'));
const MissionActivity = lazy(() => import('../pages/missions/MissionActivity'));
const SocialFeed = lazy(() => import('../pages/SocialFeed'));
const VisualGuides = lazy(() => import('../pages/VisualGuides'));
const VisualGuide = lazy(() => import('../pages/VisualGuide'));
const Dashboard = lazy(() => import('../pages/kpis/Dashboard'));
const Kpi = lazy(() => import('../pages/kpis/Kpi'));
const KpiRankings = lazy(() => import('../pages/kpis/Rankings'));
const Notifications = lazy(() => import('../pages/Notifications'));
const AtticusRedirect = lazy(() => import('../pages/AtticusRedirect'));
const Home = lazy(() => import('../pages/Home'));
const Articles = lazy(() => import('../pages/Articles'));
const Editor = lazy(() => import('../pages/Editor'));
const Categories = lazy(() => import('../pages/Categories'));
const Audiences = lazy(() => import('../pages/Audiences/Audiences'));
const Audience = lazy(() => import('../pages/Audience/Audience'));
const Channels = lazy(() => import('../pages/Channels'));
const Dashboards = lazy(() => import('../pages/Dashboards'));
const SettingsList = lazy(
  () => import('../pages/PlatformSettings/SettingsList')
);
const LinksList = lazy(() => import('../pages/PlatformSettings/LinksList'));
const Glossary = lazy(() => import('../pages/Glossary/GlossaryPage'));
const PublicProfile = lazy(() => import('../pages/profiles/PublicProfile'));
const PrivateProfile = lazy(() => import('../pages/profiles/PrivateProfile'));
const Members = lazy(() => import('../pages/Members'));
const Chat = lazy(() => import('../pages/Chat'));
const UpcomingActions = lazy(() => import('../pages/UpcomingActions'));
const RelevantChannel = lazy(() => import('../pages/RelevantChannel'));
const RelevantChannels = lazy(() => import('../pages/RelevantChannels'));
const ArticlePostPage = lazy(
  () => import('../pages/ChannelFeed/ArticlePostPage')
);

const articleStudio = 'Article Studio';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<App />}>
      <Route
        path={routes.nikeSkuRedirect.value}
        element={<NikeSKURedirect />}
      />
      <Route path="/404" element={<PageNotFound />} />
      <Route element={<AuthenticatedPageLayout />}>
        {/* LEGACY START */}
        <Route
          handle={{
            crumb: (to: string) => (
              <DefaultBreadcrumb
                to={to}
                title={{ translationKey: 'News' }}
                Icon={DocumentText1}
              />
            ),
          }}
        >
          <Route
            path={routes.newsFeed.value}
            element={
              <PrivateRoute>
                <NewsFeed />
              </PrivateRoute>
            }
          />
          <Route
            path={routes.newsArticle.value}
            element={
              <PrivateRoute>
                <NewsArticle />
              </PrivateRoute>
            }
            handle={{
              crumb: () => <NewsBreadcrumb />,
            }}
          />
        </Route>

        <Route
          path={routes.wiki.value}
          element={
            <PrivateRoute>
              <WikiLanding />
            </PrivateRoute>
          }
          handle={{
            crumb: () => (
              <DefaultBreadcrumb
                to={routes.wiki.value}
                title={{ translationKey: 'Wiki' }}
                Icon={Book1}
              />
            ),
          }}
        />
        <Route
          path={routes.wikiArticle.value}
          element={
            <PrivateRoute>
              <WikiArticle />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.missions.value}
          element={
            <PrivateRoute>
              <Missions />
            </PrivateRoute>
          }
          handle={{
            crumb: () => (
              <DefaultBreadcrumb
                to={routes.missions.value}
                title={{ translationKey: 'Missions' }}
                Icon={LampCharge}
              />
            ),
          }}
        />
        <Route
          path={routes.missionsList.value}
          element={
            <PrivateRoute>
              <MissionsList />
            </PrivateRoute>
          }
          handle={{
            crumb: () => <MissionsListBreadcrumb />,
          }}
        />
        <Route
          path={routes.mission.value}
          element={
            <PrivateRoute>
              <Mission />
            </PrivateRoute>
          }
          handle={{
            crumb: () => <MissionBreadcrumb />,
          }}
        />
        <Route
          path={routes.missionReport.value}
          element={
            <PrivateRoute>
              <MissionReport />
            </PrivateRoute>
          }
          handle={{
            crumb: () => <MissionReportBreadcrumb />,
          }}
        />
        <Route
          path={routes.missionActivity.value}
          element={
            <PrivateRoute>
              <MissionActivity />
            </PrivateRoute>
          }
          handle={{
            crumb: () => <MissionActivityBreadcrumb />,
          }}
        />
        <Route
          path={routes.socialFeed.value}
          element={
            <PrivateRoute>
              <SocialFeed />
            </PrivateRoute>
          }
          handle={{
            crumb: () => (
              <DefaultBreadcrumb
                to={routes.socialFeed.value}
                title={{ translationKey: 'Social Feed' }}
                Icon={LikeIcon}
              />
            ),
          }}
        />
        <Route
          path={routes.login.value}
          element={<Navigate replace to={routes.home.value} />}
        />
        <Route
          path={routes.visualGuides.value}
          element={
            <PrivateRoute>
              <VisualGuides />
            </PrivateRoute>
          }
          handle={{
            crumb: () => (
              <DefaultBreadcrumb
                to={routes.visualGuides.value}
                title={{ translationKey: 'Visual Guides' }}
              />
            ),
          }}
        />
        <Route
          path={routes.visualGuide.value}
          element={
            <PrivateRoute>
              <VisualGuide />
            </PrivateRoute>
          }
          handle={{
            crumb: () => VisualGuideBreadcrumb,
          }}
        />
        <Route
          path={routes.kpis.value}
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
          handle={{
            crumb: () => (
              <DefaultBreadcrumb
                to={routes.kpis.value}
                title={{ translationKey: 'KPIs' }}
              />
            ),
          }}
        />

        <Route
          path={routes.kpi.value}
          element={
            <PrivateRoute>
              <Kpi />
            </PrivateRoute>
          }
          handle={{
            crumb: () => KPIBreadcrumb,
          }}
        />
        <Route
          path={routes.kpiRankings.value}
          element={
            <PrivateRoute accessibleByRoles={MANAGER_ROLES}>
              <KpiRankings />
            </PrivateRoute>
          }
          handle={{
            crumb: () => (
              <DefaultBreadcrumb
                to={routes.kpiRankings.value}
                title={{ translationKey: 'KPI rankings' }}
              />
            ),
          }}
        />
        <Route
          path={routes.notifications.value}
          element={
            <PrivateRoute>
              <Notifications />
            </PrivateRoute>
          }
          handle={{
            crumb: () => (
              <DefaultBreadcrumb
                to={routes.notifications.value}
                title={{ translationKey: 'Notifications' }}
              />
            ),
          }}
        />
        <Route
          path={routes.atticusRedirect.value}
          element={
            <PrivateRoute>
              <AtticusRedirect />
            </PrivateRoute>
          }
        />
        {/* LEGACY END */}
        <Route
          path={routes.home.value}
          element={
            <PrivateRoute accessibleByEasyAccess>
              <Home />
            </PrivateRoute>
          }
          handle={{
            crumb: () => (
              <DefaultBreadcrumb
                to={routes.home.value}
                title={{ translationKey: 'Home' }}
                Icon={Shop}
              />
            ),
          }}
        />
        <Route
          path={routes.articleStudio.value}
          element={
            <PrivateRoute>
              <Articles />
            </PrivateRoute>
          }
          handle={{
            crumb: () => (
              <DefaultBreadcrumb
                to={routes.articleStudio.value}
                title={{ translationKey: articleStudio }}
                Icon={CardEdit}
              />
            ),
          }}
        />
        <Route
          path={routes.editorArticle.value}
          element={
            <PrivateRoute accessibleByEasyAccess>
              <Editor />
            </PrivateRoute>
          }
          handle={{
            crumb: () => (
              <DefaultBreadcrumb
                to={routes.editorArticle.value}
                title={{ translationKey: articleStudio }}
              />
            ),
          }}
        />
        <Route
          path={routes.editor.value}
          element={
            <PrivateRoute accessibleByEasyAccess>
              <Editor />
            </PrivateRoute>
          }
          handle={{
            crumb: () => (
              <DefaultBreadcrumb
                to={routes.editor.value}
                title={{ translationKey: articleStudio }}
              />
            ),
          }}
        />
        <Route
          path={routes.categoriesStudio.value}
          element={
            <PrivateRoute>
              <Categories />
            </PrivateRoute>
          }
          handle={{
            crumb: () => (
              <DefaultBreadcrumb
                to={routes.categoriesStudio.value}
                title={{ translationKey: 'Categories Studio' }}
                Icon={Hashtag}
              />
            ),
          }}
        />
        <Route
          handle={{
            crumb: () => (
              <DefaultBreadcrumb
                to={routes.audiences.value}
                title={{ translationKey: 'Audiences' }}
                Icon={People}
              />
            ),
          }}
        >
          <Route
            path={routes.audiences.value}
            element={
              <PrivateRoute>
                <Audiences />
              </PrivateRoute>
            }
          />
          <Route
            path={routes.audience.value}
            element={
              <PrivateRoute>
                <Audience />
              </PrivateRoute>
            }
            handle={{
              crumb: (to: string) => {
                return (
                  <DefaultBreadcrumb
                    to={to}
                    title={{ translationKey: 'Audiences' }}
                  />
                );
              },
            }}
          />
        </Route>

        <Route
          path={routes.channels.value}
          element={
            <PrivateRoute accessibleByEasyAccess>
              <Channels />
            </PrivateRoute>
          }
          handle={{
            crumb: () => (
              <DefaultBreadcrumb
                to={routes.channels.value}
                title={{ translationKey: 'Channels Studio' }}
                Icon={ChannelsIcon}
              />
            ),
          }}
        />
        <Route
          path={routes.dashboards.value}
          element={
            <PrivateRoute>
              <Dashboards />
            </PrivateRoute>
          }
          handle={{
            crumb: () => (
              <DefaultBreadcrumb
                to={routes.dashboards.value}
                title={{ translationKey: 'Dashboards' }}
                Icon={Graph}
              />
            ),
          }}
        />
        <Route
          path={routes.settingsList.value}
          element={
            <PrivateRoute onlyOwners>
              <SettingsList />
            </PrivateRoute>
          }
          handle={{
            crumb: () => (
              <DefaultBreadcrumb
                to={routes.settingsList.value}
                title={{ translationKey: 'Platform Settings' }}
                Icon={Setting3}
              />
            ),
          }}
        />
        <Route
          path={routes.glossary.value}
          element={
            <PrivateRoute onlyOwners>
              <Glossary />
            </PrivateRoute>
          }
          handle={{
            crumb: () => (
              <DefaultBreadcrumb
                to={routes.glossary.value}
                title={{ translationKey: 'Glossary' }}
                Icon={Book}
              />
            ),
          }}
        />
        <Route
          path={routes.linksList.value}
          element={
            <PrivateRoute onlyOwners>
              <LinksList />
            </PrivateRoute>
          }
          handle={{
            crumb: () => (
              <DefaultBreadcrumb
                to={routes.linksList.value}
                title={{ translationKey: 'Links' }}
              />
            ),
          }}
        />
        <Route
          path={routes.user.value}
          element={
            <PrivateRoute>
              <PublicProfile />
            </PrivateRoute>
          }
          handle={{
            crumb: () => (
              <DefaultBreadcrumb
                to={routes.user.value}
                title={{ translationKey: 'Profile' }}
              />
            ),
          }}
        />
        <Route
          path={routes.profile.value}
          element={
            <PrivateRoute>
              <PrivateProfile />
            </PrivateRoute>
          }
          handle={{
            crumb: () => (
              <DefaultBreadcrumb
                to={routes.profile.value}
                title={{ translationKey: 'Profile settings' }}
              />
            ),
          }}
        />
        <Route
          path={routes.members.value}
          element={
            <PrivateRoute>
              <Members />
            </PrivateRoute>
          }
          handle={{
            crumb: () => (
              <DefaultBreadcrumb
                to={routes.members.value}
                title={{ translationKey: 'Members' }}
                Icon={UserSquare}
              />
            ),
          }}
        />
        <Route
          path={routes.chat.value}
          element={
            <PrivateRoute>
              <Chat />
            </PrivateRoute>
          }
          handle={{
            crumb: () => (
              <DefaultBreadcrumb
                to={routes.chat.value}
                title={{ translationKey: 'Chat' }}
                Icon={Messages2}
              />
            ),
          }}
        />
        <Route
          path={routes.upcomingActions.value}
          element={
            <PrivateRoute accessibleByEasyAccess>
              <UpcomingActions />
            </PrivateRoute>
          }
          handle={{
            crumb: () => (
              <DefaultBreadcrumb
                to={routes.upcomingActions.value}
                title={{ translationKey: 'Upcoming Actions' }}
                Icon={TaskSquare}
              />
            ),
          }}
        />
        <Route
          path={routes.relevantChannelPost.value}
          element={
            <PrivateRoute accessibleByEasyAccess>
              <ArticlePostPage />
            </PrivateRoute>
          }
          handle={{
            crumb: () => <ChannelBreadcrumb />,
          }}
        />
        <Route
          path={routes.relevantChannel.value}
          element={
            <PrivateRoute accessibleByEasyAccess>
              <RelevantChannel />
            </PrivateRoute>
          }
          handle={{
            crumb: () => <ChannelBreadcrumb />,
          }}
        />
        <Route
          path={routes.relevantChannels.value}
          element={
            <PrivateRoute accessibleByEasyAccess>
              <RelevantChannels />
            </PrivateRoute>
          }
          handle={{
            crumb: () => (
              <DefaultBreadcrumb
                to={routes.relevantChannels.value}
                title={{ translationKey: 'Channels' }}
              />
            ),
          }}
        />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Route>
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Route>
  )
);
export default router;
