import {
  GetCategory,
  MediaTaskBlock,
  MediaTaskBlockVariants,
  OpenQuestionTaskBlock,
  OpenQuestionTaskBlockVariants,
  SimpleTaskBlock,
  SimpleTaskBlockVariants,
} from 'submodules/common-ui/generated/api/gcs/api';

import { Props } from './types';

const mapTaskblock = ({
  article,
  block,
  index,
  selectedLanguage,
  translationStatuses,
  schedule,
  blockId,
}: Props): SimpleTaskBlock | MediaTaskBlock | OpenQuestionTaskBlock | null => {
  if (!block.data.props.description) return null;

  let variants:
    | SimpleTaskBlockVariants
    | MediaTaskBlockVariants
    | OpenQuestionTaskBlockVariants = {};
  if (blockId) {
    article.blocks.forEach((b) => {
      if (
        b.id !== blockId ||
        (b.type !== 'media_task' &&
          b.type !== 'open_question_task' &&
          b.type !== 'simple_task')
      )
        return;

      variants = { ...b.variants };
    });
  }

  Object.keys(variants).forEach((language) => {
    if (blockId)
      variants[language] = {
        ...variants[language],
        ...{
          translationStatus:
            translationStatuses[blockId][language] ??
            variants[language].translationStatus,
        },
      };
  });

  return {
    id: blockId,
    version: 1,
    parentId: null,
    position: index,
    type: block.data.props.type,
    categories:
      block.data.props.categories?.map(
        (category: GetCategory) => category.id
      ) ?? [],
    deadline: block.data.props.deadline,
    required: block.data.props.required,
    public: block.data.props.public,
    audiences: block.data.props.audiences,
    mandatory: block.data.props.mandatory,
    schedule,
    variants: {
      ...variants,
      [selectedLanguage]: {
        title: block.data.props.title ?? '',
        description: block.data.props.description,
        translationStatus: blockId
          ? translationStatuses[blockId][selectedLanguage] ?? 'draft'
          : 'draft',
      },
    },
  };
};

export default mapTaskblock;
