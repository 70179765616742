import { cx } from '@emotion/css';

import { useAnswersContext } from '../../context';

const LoadingSkeleton = ({
  contentHeightList = '300px',
  contentHeightGrid = '185px',
  skeletonContentCount = 1,
}) => {
  const { layout } = useAnswersContext();
  const contentHeight =
    layout === 'grid' ? contentHeightGrid : contentHeightList;

  return (
    <div className="rounded-xl flex flex-col bg-hover-blue">
      <div className="p-4 pb-0 flex items-center gap-3 text-grayscale-secondary">
        {layout === 'grid' ? (
          <div className="rounded-[10px] h-[54px] w-[54px] bg-focus-background" />
        ) : (
          <div className="rounded-[10px] h-[38px] w-[38px] bg-focus-background" />
        )}

        {layout === 'grid' ? (
          <div className="flex flex-col gap-1">
            <div className="h-4 w-[74px] bg-focus-background rounded-xl" />
            <div className="h-3.5 w-[103px] bg-focus-background rounded-xl" />
            <div className="h-3.5 w-[125px] bg-focus-background rounded-xl" />
          </div>
        ) : (
          <>
            <div className="flex flex-col gap-1">
              <div className="h-4 w-[74px] bg-focus-background rounded-xl" />
              <div className="flex items-center gap-2">
                <div className="h-3.5 w-[102px] bg-focus-background rounded-xl" />
                <div className="h-1 w-1 bg-focus-background rounded-full" />
                <div className="h-3.5 w-[102px] bg-focus-background rounded-xl" />
              </div>
            </div>
          </>
        )}
      </div>
      <div
        className={cx({
          'p-2': skeletonContentCount <= 0,
          'p-4': skeletonContentCount > 0,
        })}
      >
        {[...Array(skeletonContentCount)].map((_, i) => (
          <div
            key={i}
            className="w-full rounded-lg bg-focus-background mb-2.5"
            style={{ height: contentHeight }}
          />
        ))}
      </div>
    </div>
  );
};

export default LoadingSkeleton;
