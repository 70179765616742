export default (hostname: string) => `<!DOCTYPE html>
<html lang="en" style="height:100%">
<head>
    <meta charset="UTF-8">
    <title>SCORM Article</title>
    <script src="scorm_api.js"></script> <!-- SCORM API wrapper script -->
</head>
<body style="height:100%" onbeforeunload="doUnload(false);" onunload="doUnload();">
    <iframe id="atobi-iframe" src="${hostname}/home" width="100%" height="100%" frameborder="0"></iframe>

    <div id="navDiv" style="position: absolute;right: 20px;top: 20px; display: none;">
        <input type="button" value="Exit" id="butExit" onclick="doExit();"/>
    </div>
</body>
</html>`;
