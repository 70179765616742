import { useEditor } from '@craftjs/core';
import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import config from 'app/config';
import { useScreenBreakpoint } from 'app/hooks';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { isMobile } from 'app/utils';
import ChaptersIcon from 'assets/icons/chapters.svg?react';
import { useCallback } from 'react';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import {
  Heading,
  Text,
  TextBlock,
} from 'submodules/common-ui/generated/api/gcs';

const Contents = styled.div`
  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 9px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${config.colors['gray-light']};
    border-radius: 9px;
  }
`;

interface TableContentsProps {
  blocks: TextBlock[];
  language: string;
  title?: string;
  onClose(): void;
}

const TableContents = ({
  blocks,
  language,
  title,
  onClose,
}: TableContentsProps) => {
  const { t } = useArticlesTranslation();

  const breakpoint = useScreenBreakpoint();
  const mobile = isMobile(breakpoint);

  const {
    selectedNodeId,
    nodes,
    actions: { selectNode },
  } = useEditor((state) => ({
    nodes: state.nodes,
    selectedNodeId: [...state.events.selected][0],
  }));

  const getParentNode = useCallback(
    (id: string) => nodes[id]?.data?.parent,
    [nodes]
  );

  const getBlockedNode = useCallback(
    (id: string) => {
      const parent = getParentNode(id);
      const sectionId = parent ? getParentNode(parent) : undefined;
      return sectionId ? nodes[sectionId] : undefined;
    },
    [getParentNode, nodes]
  );

  const handleNodeClick = useCallback(
    (nodeId: string) => {
      const element = document.getElementById(nodeId);
      if (!element) return;
      selectNode(nodeId);
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    },
    [selectNode]
  );

  return (
    <div
      className={cx(
        'flex flex-col w-full md:w-[314px] h-[420px] md:h-auto md:min-h-[400px] max-h-[calc(100%-148px)] px-4 py-5 md:px-[22px]',
        'text-grayscale-primary bg-white border md:border border-gray-light rounded-t-xl md:rounded-xl',
        'overflow-hidden',
        {
          'fixed top-[96px] right-8 z-50': !mobile,
        }
      )}
    >
      <div className="flex justify-between w-full mb-3">
        <div className="flex items-center gap-2 text-focus [&_svg]:h-5 [&_svg]:w-5 truncate">
          <ChaptersIcon className="min-w-5 [&_path]:fill-current" />
          <span className="font-bold text-black truncate">
            {title ?? t('Table of Contents')}
          </span>
        </div>
        <CloseLineIcon
          className="min-w-[23px] cursor-pointer"
          size={23}
          onClick={onClose}
        />
      </div>
      <Contents className="flex-auto -mr-4 pr-4 leading-none overflow-y-auto">
        {blocks.map((block, index) => {
          const item = block.variants[language]?.items[0];
          const blockId = String(block.id);

          const section = getBlockedNode(blockId);

          const nodeId =
            section && section?.data?.props?.isSectionBlocked
              ? section.id
              : blockId;

          return (
            <div
              role="presentation"
              onClick={() => handleNodeClick(nodeId)}
              key={nodeId}
              className={cx(
                'relative block py-3.5 cursor-pointer truncate',
                (item as Heading)?.level === 1
                  ? cx('font-bold', { 'mt-1.5': index > 0 })
                  : 'px-3'
              )}
            >
              <span
                className={cx(
                  'px-4.5 hover:text-focus transition-all duration-300',
                  'after:absolute after:left-0 after:top-1/2 after:h-4 after:w-0 after:-translate-y-1/2 after:transition-all after:duration-300',
                  {
                    'hover:pl-6': selectedNodeId !== nodeId,
                    'pl-6 after:w-0.5 after:bg-focus after:rounded-xs text-focus':
                      selectedNodeId === nodeId,
                    'text-grayscale-secondary':
                      section?.data?.props?.isSectionBlocked &&
                      selectedNodeId !== nodeId,
                  }
                )}
              >
                {(item?.children[0] as Text)?.value}
              </span>
            </div>
          );
        })}
      </Contents>
    </div>
  );
};

export default TableContents;
