import { MembersKey } from '../types';

import useBaseTranslation from './useBaseTranslation';

const useMembersTranslation = () =>
  useBaseTranslation<MembersKey>(undefined, {
    keyPrefix: 'members',
  });

export default useMembersTranslation;
