import { Editor as CraftEditor } from '@craftjs/core';
import useGetArticleCollaboratorsQuery from 'app/api/articleCollaborators/hooks/useGetArticleCollaboratorsQuery';
import useGetAudiencesQuery from 'app/api/audiences/hooks/useAudiencesQuery';
import { useAuthenticatedUser } from 'app/api/auth/hooks';
import { PageLoader } from 'app/components';
import config from 'app/config';
import { useDispatch, useISOLanguages, useSelector } from 'app/hooks';
import { routes } from 'app/router';
import { editorTypes } from 'app/router/constants';
import { actions as editorActions, selectors } from 'app/store/editor';
import { Audience, Collaborator, Language } from 'app/store/editor/types';
import { actions as navigationActions } from 'app/store/navigation';
import difference from 'lodash/difference';
import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArticleShownAs } from 'submodules/common-ui/generated/api/gcs';

import ArticleLinkBlock from '../ArticleEditor/components/blocks/ArticleLinkBlock';
import CategoriesBlock from '../ArticleEditor/components/blocks/CategoriesBlock';
import CoverImageBlock from '../ArticleEditor/components/blocks/CoverImageBlock';
import FileBlock from '../ArticleEditor/components/blocks/FileBlock';
import GiphyBlock from '../ArticleEditor/components/blocks/GiphyBlock';
import HeadingSectionBlock from '../ArticleEditor/components/blocks/HeadingSection';
import HeadingSectionContainer from '../ArticleEditor/components/blocks/HeadingSection/HeadingSectionsContainer';
import ImageBlock from '../ArticleEditor/components/blocks/ImageBlock';
import PDFBlock from '../ArticleEditor/components/blocks/PDFBlock';
import PollBlock from '../ArticleEditor/components/blocks/tasks/Poll';
import PollOption from '../ArticleEditor/components/blocks/tasks/Poll/Option/PollOption';
import PollOptionsContainer from '../ArticleEditor/components/blocks/tasks/Poll/Option/PollOptionsContainer';
import QuizBlock from '../ArticleEditor/components/blocks/tasks/Quiz';
import QuizOption from '../ArticleEditor/components/blocks/tasks/Quiz/Option/QuizOption';
import QuizOptionsContainer from '../ArticleEditor/components/blocks/tasks/Quiz/Option/QuizOptionsContainer';
import ScormBlock from '../ArticleEditor/components/blocks/tasks/ScormBlock';
import SimpleTask from '../ArticleEditor/components/blocks/tasks/SimpleTask';
import YesNoNaBlock from '../ArticleEditor/components/blocks/tasks/YesNoNa';
import YesNoNaOption from '../ArticleEditor/components/blocks/tasks/YesNoNa/Option/YesNoNaOption';
import YesNoNaOptionsContainer from '../ArticleEditor/components/blocks/tasks/YesNoNa/Option/YesNoNaOptionsContainer';
import TextBlock from '../ArticleEditor/components/blocks/TextBlock';
import TitleBlock from '../ArticleEditor/components/blocks/TitleBlock';
import UrlBlock from '../ArticleEditor/components/blocks/UrlBlock';
import VideoBlock from '../ArticleEditor/components/blocks/VideoBlock';
import DraggableContainer from '../ArticleEditor/components/DraggableContainer';

import ActionsMode from './ActionsMode';
import ErrorContainer from './components/ErrorContainer/ErrorContainer';
import EditMode from './EditMode';
import useArticle from './hooks/useArticle';
import ReviewMode from './ReviewMode';
import ViewMode from './ViewMode';

const EditorContextWrapper = () => {
  const navigate = useNavigate();
  const { mode } = useParams<{
    id?: string;
    mode: keyof typeof editorTypes;
  }>();

  useEffect(() => {
    if (!mode) return;

    const includes = Object.values(editorTypes).includes(mode);

    if (!includes) {
      navigate('/404', { replace: true });
    }
  }, [mode, navigate]);

  const { data: user } = useAuthenticatedUser();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(navigationActions.setFullPage(true));

    return () => {
      dispatch(navigationActions.setFullPage(false));
      dispatch(editorActions.stateReset());
    };
  }, [dispatch]);

  const {
    article,
    isLoading: isLoadingArticle,
    isFetching: isFetchingArticle,
    apiError,
    refetch,
  } = useArticle();

  const useTemplateView = useSelector(selectors.getUseTemplateView);

  const { data: collaborators, isLoading: isLoadingCollaborators } =
    useGetArticleCollaboratorsQuery({
      role: 'collaborators',
      permissions: 'update',
      refetchOnWindowFocus: false,
    });

  const {
    data: audiences,
    isFetchingNextPage,
    fetchNextPage,
    meta,
  } = useGetAudiencesQuery({
    page: 1,
    query: '',
    refetchOnWindowFocus: false,
  });

  const languages = useISOLanguages({ search: '' });

  const setArticleLanguages = () => {
    if (!article) return;

    const stateLanguages: Language[] = [];
    languages.forEach((l) => {
      article.languages.forEach((l2) => {
        if (l.code === l2.language) {
          stateLanguages.push({
            name: l.name,
            code: l.code,
            isDefault: l2.isDefault,
            active: user?.contentLanguage.uiLanguage === l.code,
          });
        }
      });
    });

    const anyActive = stateLanguages.find((l) => l.active);

    if (!anyActive) {
      stateLanguages.forEach((l) => {
        if (l.isDefault) l.active = true;
      });
    }

    dispatch(editorActions.updateLanguages(stateLanguages));
  };

  const setArticleCollaborators = () => {
    if (!collaborators || !article) return;

    const stateCollaborators: Collaborator[] = [];
    collaborators.forEach((c) => {
      article.users.forEach((u) => {
        if (c.id === u.id) {
          stateCollaborators.push({
            id: c.id,
            avatar: c.avatars.small,
            fullName: c.fullName,
            languages: u.languages,
            email: c.email,
          });
        }
      });
    });

    dispatch(editorActions.updateCollaborators(stateCollaborators));
  };

  const updateArticleAudiences = () => {
    if (!article || !audiences) return;

    if (isLoadingArticle || isFetchingArticle) return;

    const currentPage = meta?.currentPage || 0;
    const lastPage = meta?.lastPage || 0;

    const stateAudiences: Audience[] = audiences
      .filter(({ id }) => article.audiences?.includes(id))
      .map(({ id, name, usersCount }) => ({
        id,
        name,
        members: usersCount ?? 0,
        isVisible: true,
      }));

    const hiddenAudiences = difference(
      article?.audiences,
      audiences.map((a) => a.id)
    );

    hiddenAudiences.forEach((id) => {
      stateAudiences.push({ id, name: '', members: 0, isVisible: false });
    });

    dispatch(editorActions.updateAudiences(stateAudiences));

    if (currentPage < lastPage && !isFetchingNextPage) fetchNextPage();
  };

  useEffect(() => {
    updateArticleAudiences();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audiences]);

  const setDefaultUserAndLanguage = () => {
    if (article || !user) return;

    dispatch(
      editorActions.addLanguage({
        name: user.contentLanguage.uiLanguage,
        code: user.contentLanguage.uiLanguage,
        isDefault: true,
        active: true,
      })
    );
    dispatch(
      editorActions.addCollaborator({
        id: user.id,
        fullName: `${user.name} ${user.lastName}`,
        avatar: user.avatars.small,
        languages: [user.contentLanguage.uiLanguage],
        email: user.email,
      })
    );
  };
  const isLoadingContent =
    mode === 'actions' ? isFetchingArticle : isLoadingArticle;

  const isLoading = isLoadingContent || isLoadingCollaborators;

  useEffect(() => {
    if (!isLoading && !article && user) return setDefaultUserAndLanguage();
    if (collaborators) setArticleCollaborators();
    if (useTemplateView) dispatch(editorActions.setTemplateView(true));

    setArticleLanguages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [article, user, collaborators, isLoading]);

  useEffect(() => {
    dispatch(
      editorActions.setShownAs({
        value: article?.shownAs ?? ArticleShownAs.Article,
      })
    );

    if (
      article &&
      article.status === 'inbound' &&
      mode !== editorTypes.review
    ) {
      navigate(
        routes.editorArticle.create(article.id.toString(), editorTypes.review),
        { replace: true }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [article]);

  useEffect(() => {
    if (mode === 'actions') refetch();
  }, [mode, refetch]);

  const renderEditor = (): JSX.Element => {
    switch (mode) {
      case editorTypes.actions:
        return <ActionsMode />;
      case editorTypes.view:
        return <ViewMode />;
      case editorTypes.review:
        return <ReviewMode />;
      default:
        return <EditMode />;
    }
  };

  if (isLoading) return <PageLoader />;

  if (apiError) return <ErrorContainer error={apiError} />;

  return (
    <div className="w-full flex flex-col items-center bg-gradient-article2 h-full overflow-auto">
      <CraftEditor
        resolver={{
          ImageBlock,
          VideoBlock,
          GiphyBlock,
          TextBlock,
          HeadingSectionBlock,
          HeadingSectionContainer,
          CoverImageBlock,
          CategoriesBlock,
          TitleBlock,
          UrlBlock,
          PDFBlock,
          FileBlock,
          ArticleLinkBlock,
          SimpleTask,
          QuizBlock,
          QuizOption,
          QuizOptionsContainer,
          YesNoNaBlock,
          YesNoNaOptionsContainer,
          YesNoNaOption,
          PollBlock,
          PollOption,
          PollOptionsContainer,
          ScormBlock,
          DraggableContainer,
        }}
        indicator={{ success: config.colors.focus, error: config.colors.error }}
      >
        {renderEditor()}
      </CraftEditor>
    </div>
  );
};

export default EditorContextWrapper;
