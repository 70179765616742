import { Location } from 'app/api/auth/types';
import { FullLocationTree } from 'app/api/locations/types';
import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';

import getSelectedIds from './getSelectedIds';
import reducer, {
  Action,
  getStateLocation,
  Location as ReducerLocation,
} from './locationsReducer';
import useLocations from './useLocations';

const uninitialisedFunction = () => new Error('Uninitialsed Function');

export const LocationTreeContext = createContext<LocationTreeContextValues>({
  locationsTree: undefined,
  isLoading: false,
  visibleLocations: [],
  selectedLocations: [],
  onSearch: uninitialisedFunction,
  toggleSelection: uninitialisedFunction,
  searchLocations: uninitialisedFunction,
  dispatch: uninitialisedFunction,
});

interface LocationTreeContextValues {
  locationsTree?: FullLocationTree[];
  visibleLocations: ReducerLocation[];
  selectedLocations: number[];
  isLoading: boolean;
  onSearch: (text: string) => void;
  toggleSelection: (selected: boolean) => void;
  searchLocations: () => void;
  dispatch: Dispatch<Action>;
}

type ContextProps = {
  children: ReactNode;
  locations?: Location[];
  preSelectedLocations?: Location[];
};

const LocationTreeContextProvider = ({
  children,
  locations,
  preSelectedLocations,
}: ContextProps) => {
  const { locationsTree, isLoading } = useLocations({ locations });
  const [state, dispatch] = useReducer(reducer, {
    locations: [],
    searchText: '',
  });

  useEffect(() => {
    dispatch({
      type: 'locations-loaded',
      data: locationsTree?.map(getStateLocation) || [],
    });

    if (preSelectedLocations && preSelectedLocations.length > 0) {
      dispatch({
        type: 'pre-select',
        data: preSelectedLocations,
      });
    }
  }, [locationsTree, preSelectedLocations]);

  const onSearch = (text: string) => {
    dispatch({
      type: 'search-input',
      data: { text },
    });
  };

  const toggleSelection = (selected: boolean) => {
    dispatch({
      type: 'toggle-all-selected',
      data: { selected },
    });
  };

  const searchLocations = () =>
    state.locations.filter(function r(location): boolean {
      if (
        location.name
          .toLocaleLowerCase()
          .includes(state.searchText.toLocaleLowerCase())
      ) {
        return true;
      }

      if (location.children && location.children.length > 0) {
        return Boolean(location.children.filter(r).length);
      } else {
        return false;
      }
    });

  const visibleLocations = searchLocations();
  const selectedLocations = useMemo(
    () => getSelectedIds(state.locations),
    [state.locations]
  );

  const contextValue: LocationTreeContextValues = {
    locationsTree,
    visibleLocations,
    selectedLocations,
    isLoading,
    onSearch,
    toggleSelection,
    searchLocations,
    dispatch,
  };

  return (
    <LocationTreeContext.Provider value={contextValue}>
      {children}
    </LocationTreeContext.Provider>
  );
};

const useLocationTreeContext = () => {
  const context = useContext(LocationTreeContext);

  if (context === undefined) {
    throw new Error(
      'useLocationsTree must be used within a LocationTreeContextProvider'
    );
  }

  return context;
};

export { LocationTreeContextProvider, useLocationTreeContext };
export type { LocationTreeContextValues };
