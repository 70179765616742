import { useArticlesTranslation } from 'app/internationalization/hooks';
import { FC } from 'react';
import { ActionAnswerResponse } from 'submodules/common-ui/generated/api/gcs';
interface Props {
  actionAnswers?: ActionAnswerResponse[];
  quizAvgScore?: number | null;
}

const QuizScore: FC<Props> = ({ quizAvgScore }) => {
  const { t } = useArticlesTranslation();

  return (
    <div className="flex flex-col">
      <div className="flex justify-between">
        <div className="h-[104px] w-full bg-light flex flex-col justify-center gap-2 rounded p-4">
          <span className="text-hover-primary text-3xl font-bold">
            {quizAvgScore}%
          </span>
          <span className="text-xs text-ceil font-normal">
            {t('average score')}
          </span>
        </div>
      </div>
    </div>
  );
};

export default QuizScore;
