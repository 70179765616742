import { request } from 'app/api/gcsRequest';
import config from 'app/config';
import { ArchiveArticleApi } from 'common-ui/generated/api/gcs';

const archiveArticle = ({ articleIds }: { articleIds: number[] }) =>
  new ArchiveArticleApi(undefined, config.env.gcsApiUrl, request())
    .archiveArticle({ articleIds })
    .then((response) => response.data);

export { archiveArticle };
export default archiveArticle;
