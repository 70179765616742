import { request } from 'app/api/gcsRequest';
import config from 'app/config';
import { ChannelApi, NewChannel } from 'submodules/common-ui/generated/api/gcs';

const createChannel = (newChannel: NewChannel) =>
  new ChannelApi(undefined, config.env.gcsApiUrl, request()).createNewChannel(
    newChannel
  );

export default createChannel;
