import { cx } from '@emotion/css';

interface Props {
  items: { label: string; value: string }[];
  onClick: (item: string) => void;
  selectedItem: string;
  itemClassName?: string;
  className?: string;
}

const FilterButtons = ({
  items,
  onClick,
  selectedItem,
  itemClassName,
  className,
}: Props) => (
  <div
    className={cx(
      'border rounded-[10px] border-gray-white overflow-clip flex',
      className
    )}
  >
    {items.map((item) => (
      <button
        key={item.value}
        className={cx(
          'border-gray-white border py-2.5 px-4',
          {
            'bg-gray-white-shadow': selectedItem === item.value,
          },
          itemClassName
        )}
        onClick={() =>
          selectedItem === item.value ? undefined : onClick(item.value)
        }
      >
        <div className="flex items-center text-gray-extra-dark-shadow text-sm">
          <span
            className={cx('font-bold', {
              'text-gray-extra-dark': selectedItem === item.value,
            })}
          >
            {item.label}
          </span>
        </div>
      </button>
    ))}
  </div>
);

export default FilterButtons;
