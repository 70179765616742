import { useMemo } from 'react';
import { ActionAnswerResponse } from 'submodules/common-ui/generated/api/gcs';

interface UseDisplayAnswerProps {
  isAddingOwnAnswer: boolean;
  isActionCompleted: boolean;
  answeredByColleague: boolean;
  currentInstanceAnswer?: ActionAnswerResponse;
  colleagueAnswer?: ActionAnswerResponse;
}

const useDisplayAnswer = ({
  isAddingOwnAnswer,
  isActionCompleted,
  answeredByColleague,
  currentInstanceAnswer,
  colleagueAnswer,
}: UseDisplayAnswerProps): ActionAnswerResponse | undefined => {
  return useMemo(() => {
    if (isAddingOwnAnswer) return undefined;
    if (isActionCompleted) return currentInstanceAnswer;
    if (answeredByColleague) return colleagueAnswer;
    return undefined;
  }, [
    isAddingOwnAnswer,
    isActionCompleted,
    answeredByColleague,
    currentInstanceAnswer,
    colleagueAnswer,
  ]);
};

export default useDisplayAnswer;
