import { Modal } from 'app/components';
import { useScreenBreakpoint } from 'app/hooks';
import { useComponentsTranslation } from 'app/internationalization/hooks';
import { isMobile } from 'app/utils';
import CrossIcon from 'assets/icons/cross.svg?react';

import SubmitAndCancelButton from '../SubmitAndCancelButton';

interface HideModalProps {
  title: string;
  description: string;
  onClose: () => void;
  onHide: () => void;
}

const HideModal = ({ onClose, onHide, title, description }: HideModalProps) => {
  const { t } = useComponentsTranslation();
  const breakpoint = useScreenBreakpoint();
  const _isMobile = isMobile(breakpoint);

  return (
    <Modal onClose={onClose} className="p-6" width={_isMobile ? 'sm' : 'md'}>
      <div className="flex items-center mb-4 justify-between">
        <p className="text-grayscale-primary text-lg font-bold">{title}</p>
        <button
          className="bg-light text-grayscale-secondary shadow-atobi flex items-center justify-center w-10 h-10 rounded-lg"
          onClick={onClose}
        >
          <CrossIcon />
        </button>
      </div>
      <p className="text-grayscale-primary mt-2 mb-7 max-w-[328px]">
        {description}
      </p>
      <SubmitAndCancelButton
        cancelLabel={t('Cancel')}
        submitLabel={t('Hide')}
        onCancel={onClose}
        onSubmit={onHide}
      />
    </Modal>
  );
};

export default HideModal;
