/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PermissionsConfiguration } from '../../api/auth/types';

import { NAME } from './constants';
import { State, Tenant } from './types';

const initialState: State = {
  token: null,
  idToken: null,
  refreshToken: null,
  selectedTenant: null,
  tenants: null,
  translationLanguage: null,
  moduleAccessPermissions: null,
  expiresAt: null,
  isTokenRefreshing: null,
};

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    tokenRetrieved: (state, { payload }: PayloadAction<string>) => {
      state.token = payload;
    },
    idTokenRetrieved: (state, { payload }: PayloadAction<string>) => {
      state.idToken = payload;
    },
    refreshTokenRetrieved: (state, { payload }: PayloadAction<string>) => {
      state.refreshToken = payload;
    },
    isTokenRefreshingRetrieved: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isTokenRefreshing = payload;
    },
    tokenExpiresAt: (state, { payload }: PayloadAction<number>) => {
      state.expiresAt = payload;
    },
    tenantSelected: (state, { payload }: PayloadAction<Tenant>) => {
      state.selectedTenant = payload;
    },
    translationLanguageChanged: (state, { payload }: PayloadAction<string>) => {
      state.translationLanguage = payload;
    },
    tenantsRetrieved: (state, { payload }: PayloadAction<Array<Tenant>>) => {
      state.tenants = payload;
    },
    moduleAccessPermissionsRetrieved: (
      state,
      { payload }: PayloadAction<PermissionsConfiguration>
    ) => {
      state.moduleAccessPermissions = payload;
    },
    stateReset: () => initialState,
  },
});

export default slice;
