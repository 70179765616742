import { selectors } from 'app/store/editor';
import { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import {
  MediaTaskBlockV1,
  MultiChoiceBlockV1,
  OpenQuestionTaskBlockV1,
  SimpleTaskBlockV1,
} from 'submodules/common-ui/generated/api/gcs/api';

import AnswerHeader from './AnswerHeader';
import AnswerTitle from './AnswerTitle';

interface AnswerContainerProps {
  block:
    | SimpleTaskBlockV1
    | MediaTaskBlockV1
    | OpenQuestionTaskBlockV1
    | MultiChoiceBlockV1;
  onClick?: VoidFunction;
}
const AnswerContainer = ({
  children,
  block,
  onClick,
}: PropsWithChildren<AnswerContainerProps>) => {
  const languange = useSelector(selectors.getActiveLanguage);
  return (
    <div
      className="flex flex-col gap-3 w-[390px] rounded-[18px] border border-focus-background bg-grayscale-bg-dark p-6"
      role="presentation"
      onClick={() => onClick?.()}
    >
      <AnswerHeader
        deadline={block.deadline}
        blockType={block.type}
        isPublic={block.public}
        mandatory={block.mandatory ?? false}
        required={block.required}
        renderType={'renderType' in block ? block.renderType : undefined}
      />

      <AnswerTitle
        description={block.variants[languange].description}
        title={block.variants[languange].title ?? ''}
      />
      {children}
    </div>
  );
};

export default AnswerContainer;
