import { request } from 'app/api/gcsRequest';
import config from 'app/config';
import { BacklinksApi } from 'submodules/common-ui/generated/api/gcs';

import { BacklinkFilter } from '../types';

const getBacklinks = (articleId: number, filter?: BacklinkFilter) =>
  new BacklinksApi(undefined, config.env.gcsApiUrl, request())
    .getBacklinks(
      articleId,
      undefined,
      undefined,
      filter?.timezone,
      filter?.live
    )
    .then((response) => response.data);

export { getBacklinks };
export default getBacklinks;
