import useDateTimeFormat from 'app/hooks/useDateTimeFormat';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import CategoryButton from 'app/pages/ChannelView/components/CategoryButton';
import BlockTypes from 'app/pages/Editor/helpers/constants';
import useGetCategoryName from 'app/pages/Editor/hooks/useGetCategoryName';
import useUserQuery from 'app/pages/profiles/PublicProfile/useUserQuery';
import { editorTypes } from 'app/router/constants';
import { useParams } from 'react-router-dom';
import { Article } from 'submodules/common-ui/generated/api/gcs';

interface Props {
  article?: Article;
}

const CategoriesBlock = ({ article }: Props) => {
  const { t } = useArticlesTranslation();

  const { mode } = useParams<{ mode: string }>();
  const { getCategoryName } = useGetCategoryName();

  const { data: user, isLoading } = useUserQuery({
    id: Number(article?.createdBy),
    enabled: article?.createdBy !== undefined,
  });

  const formattedUpdatedAt = useDateTimeFormat({
    timestamp: article?.publishAt || '',
    type: 'formattedDate',
  });

  const isEmpty = article?.categories.length === 0;
  const fullName = `${user?.firstName} ${user?.lastName}`;

  if (mode !== editorTypes.actions) return null;

  return (
    <div className="px-4 flex flex-col">
      {!isLoading && (
        <div className="flex items-center">
          {user ? (
            <>
              <img
                className="h-4 w-4 rounded-sm border border-white-shadow mr-1.5"
                src={user.avatars?.small}
                alt={fullName}
              />
              <span className="font-bold text-xs">{fullName}</span>
            </>
          ) : (
            <span className="font-bold text-xs">
              {t('By a former employee')}
            </span>
          )}
          <div className="rounded bg-grayscale-secondary w-[3px] h-[3px] mx-2" />
          <span className="text-grayscale-secondary text-xs">
            {formattedUpdatedAt}
          </span>
        </div>
      )}
      {!isEmpty && (
        <div className="flex gap-2 flex-wrap mt-2">
          {article?.categories.map((category) => (
            <CategoryButton
              key={category.id}
              title={getCategoryName(category)}
              isStatic
            />
          ))}
        </div>
      )}
    </div>
  );
};

CategoriesBlock.craft = {
  displayName: BlockTypes.CategoriesBlock,
};

export default CategoriesBlock;
