import {
  ImageBlock,
  ImageBlockVariants,
} from 'submodules/common-ui/generated/api/gcs/api';

import { Props } from './types';

const mapImageBlock = ({
  article,
  block,
  index,
  selectedLanguage,
  translationStatuses,
  blockId,
}: Props): ImageBlock | null => {
  if (!block.data.props.imageSchema) return null;

  let variants: ImageBlockVariants = {};

  if (blockId) {
    article.blocks.forEach((b) => {
      if (b.id === blockId) {
        variants = { ...b.variants } as ImageBlockVariants;
      }
    });
  }

  Object.keys(variants).forEach((language) => {
    if (blockId)
      variants[language] = {
        ...variants[language],
        ...{
          translationStatus:
            translationStatuses[blockId][language] ??
            variants[language].translationStatus,
        },
      };
  });

  return {
    id: blockId,
    version: 1,
    parentId: null,
    position: index,
    type: 'image',
    variants: {
      ...variants,
      [selectedLanguage]: {
        name: block.data.props.imageSchema.name,
        type: block.data.props.imageSchema.type,
        url: block.data.props.imageSchema.url,
        id: block.data.props.imageSchema.id,
        tenantId: block.data.props.imageSchema.tenantId ?? null,
        translationStatus: blockId
          ? translationStatuses[blockId][selectedLanguage] ?? 'draft'
          : 'draft',
      },
    },
  };
};

export default mapImageBlock;
