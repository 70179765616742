import { css, cx } from '@emotion/css';
import useGetCategoryName from 'app/pages/Editor/hooks/useGetCategoryName';
import routes from 'app/router/routes';
import formatDate from 'app/utils/formatDate';
import { Calendar, DocumentText } from 'iconsax-react';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { ListArticle } from 'submodules/common-ui/generated/api/gcs';

interface ArticleCardProps
  extends Pick<React.HTMLAttributes<HTMLDivElement>, 'className'> {
  article: ListArticle;
  userLanguage: string;
  CoverImage: ReactNode;
  recent?: boolean;
  isColumn?: boolean;
  contentStart?: boolean;
  showCatetgories?: boolean;
}

const ArticleCard = ({
  article,
  userLanguage,
  className,
  CoverImage,
  recent = false,
  isColumn = false,
  showCatetgories = false,
  contentStart = false,
}: ArticleCardProps) => {
  const navigate = useNavigate();

  const { getCategoryName } = useGetCategoryName();

  const getChannelDefaultLanguage = () => {
    return (
      Object.keys(article.channel?.title ?? {}).find(
        (key) => article.channel?.title?.[key].isDefault
      ) ?? ''
    );
  };

  const articleDefaultLanguage = article.languages.filter((l) => l.isDefault)[0]
    .language;

  const articleTitle =
    article.variants?.[userLanguage]?.title ??
    article.variants?.[articleDefaultLanguage]?.title;

  const channelTitle =
    article.channel?.title?.[userLanguage]?.title ??
    article.channel?.title?.[articleDefaultLanguage]?.title ??
    article.channel?.title?.[getChannelDefaultLanguage()]?.title ??
    '-';

  const renderCategories = () => {
    const category = article.categories[0];
    return (
      <div
        className={cx(
          'flex flex-col md:flex-row w-full md:items-center md:justify-between text-gray-light-blue mt-2',
          { 'md:flex-row-reverse': !category }
        )}
      >
        <div
          className={cx('flex justify-start gap-1 mb-2 md:mb-0', {
            'hidden md:flex md:invisible': !category,
          })}
        >
          <div
            role="presentation"
            className="border-2 text-10 font-semibold bg-hover-blue text-ceil border-focus-background rounded-full w-fit px-2 py-1.5 flex gap-2 group items-center whitespace-nowrap"
          >
            {category ? getCategoryName(category) : ''}
          </div>

          <div
            role="presentation"
            className={cx(
              'border-2 text-10 font-semibold bg-hover-blue text-ceil border-focus-background rounded-full w-fit px-2 py-1.5 flex gap-2 group items-center whitespace-nowrap',
              { 'hidden md:flex md:invisible': article.categories.length <= 1 }
            )}
          >
            {`+${article.categories.length - 1}`}
          </div>
        </div>
        <div className="flex">
          <Calendar size={16} />
          <span className="ml-1 text-xs">{formatDate(article?.publishAt)}</span>
        </div>
      </div>
    );
  };

  return (
    <button
      className={cx('self-start', className)}
      key={article.id}
      onClick={() =>
        navigate(routes.editorArticle.create(article.id.toString(), 'actions'))
      }
    >
      <div
        className={cx('flex', {
          'flex-col': isColumn,
          'items-center': !isColumn && !contentStart,
        })}
      >
        {CoverImage}
        <div className={cx('flex flex-col', { 'ml-2': !isColumn })}>
          <div className="relative flex items-center">
            {recent && (
              <div className="absolute top-0 w-1.5 h-1.5 mt-2 bg-focus rounded-full" />
            )}
            <span
              className={cx(
                'font-bold text-start line-clamp-2',
                { 'ml-2.5': recent },
                css('word-break:break-word')
              )}
            >
              {articleTitle}
            </span>
          </div>
          {showCatetgories ? (
            renderCategories()
          ) : (
            <div className="flex items-start mt-2 text-focus">
              <DocumentText size={14} className="mt-0.5" />
              <span className="text-start text-xs font-semibold ml-1 line-clamp-1">
                {channelTitle}
              </span>
            </div>
          )}
        </div>
      </div>
    </button>
  );
};

export default ArticleCard;
