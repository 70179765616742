import { useScreenBreakpoint } from 'app/hooks';
import { actions } from 'app/store/navigation';
import { dayjs, isMobile as resolveIsMobile } from 'app/utils';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
  prompt(): Promise<void>;
}

declare global {
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent;
  }
}

const storageKey = 'lastInstallPopUpShown';
const sevenDaysInMilis = 604800;

const isInstalled = window.matchMedia('(display-mode: standalone)').matches;
const isSafari = 'safari' in window;

const useInstallChromePwa = () => {
  const dispatch = useDispatch();
  const [hidden, setHidden] = useState(true);
  const [installEvent, setInstallEvent] =
    useState<BeforeInstallPromptEvent | null>(null);

  const breakpoint = useScreenBreakpoint();
  const isMobile = resolveIsMobile(breakpoint);

  const beforeAppInstallpromptHandler = (e: BeforeInstallPromptEvent) => {
    e.preventDefault();

    if (!isInstalled && !isSafari) setInstallEvent(e);
  };

  const appInstalledHandler = (e: Event) => {
    window.removeEventListener(
      'beforeinstallprompt',
      beforeAppInstallpromptHandler
    );
    e.preventDefault();
  };

  useEffect(() => {
    if (!isReminderSevenDaysOld()) return;

    window.addEventListener(
      'beforeinstallprompt',
      beforeAppInstallpromptHandler
    );

    window.addEventListener('appinstalled', appInstalledHandler);

    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        beforeAppInstallpromptHandler
      );
      window.removeEventListener('appinstalled', appInstalledHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnInstall = () => {
    installEvent?.prompt();
  };

  const isReminderSevenDaysOld = () => {
    const lastInstallPoUpShown = localStorage.getItem(storageKey);

    if (!lastInstallPoUpShown) return true;

    const lastInstallPopUpShownMilis = parseInt(lastInstallPoUpShown);
    const now = dayjs().millisecond();

    return (now - lastInstallPopUpShownMilis) / 1000 >= sevenDaysInMilis;
  };

  useEffect(() => {
    if (isReminderSevenDaysOld()) {
      setHidden(false);
    } else {
      setHidden(true);
    }
  }, []);

  const onCancel = () => {
    localStorage.setItem(storageKey, dayjs().millisecond().toString());

    setHidden(true);

    if (isMobile) {
      dispatch(actions.setMobilePwaPopupHidden(true));
    }
  };

  return {
    isInstalled,
    isMobile,
    isSafari,
    hidden,
    installEvent,
    handleOnInstall,
    onCancel,
  };
};

export default useInstallChromePwa;
