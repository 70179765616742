import narrowUnknown from 'app/utils/narrowUnknown';
import { useQuery } from 'react-query';

import { queryKeys, resources } from '../index';

const useGetArticleBlock = ({
  articleId,
  blockId,
  enabled,
  timezone,
  live,
}: {
  articleId: number;
  blockId: number;
  enabled: boolean;
  timezone?: string;
  live?: '1';
}) => {
  const {
    data,
    isLoading,
    isFetching,
    error: apiError,
    refetch,
  } = useQuery(
    queryKeys.articleBlock(articleId, blockId),
    () => resources.getArticleBlock(articleId, blockId, timezone, live),
    {
      enabled,
    }
  );

  return {
    block: data,
    isLoading,
    isFetching,
    error: narrowUnknown(apiError),
    apiError,
    refetch,
  };
};

export { useGetArticleBlock };
export default useGetArticleBlock;
