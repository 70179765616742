import { useFeatures } from '@paralleldrive/react-feature-toggles';
import { useAuthenticatedUser } from 'app/api/auth/hooks';
import { isActiveFeatureName } from 'app/utils';

import { Link } from '../types';

const useModuleFeatureCheck = () => {
  const featureList = useFeatures();
  const { data: user } = useAuthenticatedUser();

  const checkActiveList = (link: Link) => {
    if (!user) return false;

    if (link.feature && !isActiveFeatureName(link.feature, featureList))
      return false;

    if (!link.moduleKey) return true;

    return (
      !user.discontinuedModules?.[link.moduleKey] &&
      !user.hiddenModules?.[link.moduleKey]
    );
  };

  const checkDiscontinuedList = (link: Link) => {
    if (!user) return false;

    if (link.feature && !isActiveFeatureName(link.feature, featureList))
      return false;

    if (!link.moduleKey) return false;

    return (
      user.discontinuedModules?.[link.moduleKey] &&
      !user.hiddenModules?.[link.moduleKey]
    );
  };

  return { checkActiveList, checkDiscontinuedList };
};

export default useModuleFeatureCheck;
