import { useEditor, useNode } from '@craftjs/core';
import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import useArticles from 'app/api/articles/hooks/useArticles';
import { InfiniteScroll, Input2, Spinner } from 'app/components';
import config from 'app/config';
import useIsEditorEnabled from 'app/hooks/useIsEditorEnabled';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import BlockTypes from 'app/pages/Editor/helpers/constants';
import routes from 'app/router/routes';
import { selectors } from 'app/store/editor';
import debounce from 'lodash/debounce';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Popover, PopoverPosition as Position } from 'react-tiny-popover';
import { BasicArticleInfo } from 'submodules/common-ui/generated/api/gcs';

import BaseBlockContainer from '../../BaseBlockContainer';

import LinkedArticle from './LinkedArticle';

const Articles = styled.div`
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${config.colors['grayscale-bg-dark']};
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${config.colors['gray-light']};
    border-radius: 8px;
  }
`;

interface ArticleLinkBlockProps {
  articleLink?: BasicArticleInfo;
  unavailable?: boolean;
}

const ArticleLinkBlock = ({
  articleLink,
  unavailable,
}: ArticleLinkBlockProps) => {
  const { t } = useArticlesTranslation();
  const navigate = useNavigate();
  const { enabled, mode } = useIsEditorEnabled();
  const [active, setActive] = useState<boolean>(false);
  const [clicked, setClicked] = useState<boolean>();
  const [focused, setFocused] = useState<boolean>(false);
  const [popoverPosition, setPopoverPosition] = useState<Position | undefined>(
    'bottom'
  );
  const [searchValue, setSearchValue] = useState<string>();

  const {
    actions: { selectNode },
  } = useEditor();

  const {
    actions: { setProp },
    connectors: { connect },
    id,
    selected,
  } = useNode((node) => ({
    selected: node.events.selected,
  }));

  const { data, hasNextPage, isFetching, meta, fetchNextPage } = useArticles({
    enabled: enabled && active,
    filters: {
      title: searchValue,
    },
    refetchOnWindowFocus: false,
    nodeId: id,
  });
  const selectedArticle = useSelector(selectors.getArticle);
  const selectedArticleId = selectedArticle?.id;

  const articles = useMemo(
    () => data?.filter((article) => article.id !== selectedArticleId) ?? [],
    [data, selectedArticleId]
  );

  const isSameArticleIncluded = data?.some(
    (article) => article.id === selectedArticleId
  );

  const totalCount = meta?.total || 0;
  const resultsCount = isSameArticleIncluded ? totalCount - 1 : totalCount;

  const debounceOnSearch = debounce(
    (value) => setSearchValue((prev) => (value === prev ? prev : value)),
    500
  );

  useEffect(() => {
    if (!enabled) return;

    if (!articleLink && clicked === undefined) {
      setActive(true);
      setClicked(false);
      setFocused(true);
    } else if (!selected) {
      setClicked(false);
      setFocused(false);
    }
  }, [articleLink, clicked, enabled, selected]);

  if (!enabled && unavailable) return null;

  return (
    <div
      ref={(ref) => ref && connect(ref)}
      role="presentation"
      onClick={() => enabled && !clicked && setClicked(true)}
    >
      <BaseBlockContainer
        deleteOnBackspace={enabled && !focused}
        nodeId={id}
        selected={selected}
        showTranslationStatus={false}
        type="Article Linking"
      >
        <div
          className={cx('m-2', {
            'min-h-[80px]': enabled,
            'pt-7 transition-all': focused && popoverPosition === 'top',
          })}
        >
          <Popover
            containerClassName="z-10"
            content={({ childRect, position }) => (
              <>
                <PopoverPosition
                  position={position}
                  setPosition={setPopoverPosition}
                />
                <div
                  className={cx(
                    'py-4 border border-gray-dark shadow-atobi bg-white transition-all',
                    {
                      '-mt-[1px] border-t-0 rounded-bl-[12px] rounded-br-[12px]':
                        position === 'bottom',
                      '-mb-[1px] border-b-0 rounded-tl-[12px] rounded-tr-[12px]':
                        position === 'top',
                    }
                  )}
                  style={{ width: childRect.width - 2 }}
                >
                  {articles.length < 1 && isFetching ? (
                    <Spinner className="!my-5" />
                  ) : (
                    <>
                      <Articles
                        id="parent"
                        className={cx('max-h-[208px] overflow-y-auto', {
                          'pr-2': articles.length > 4,
                        })}
                      >
                        <InfiniteScroll
                          scrollableTarget="parent"
                          dataLength={articles.length}
                          hasMore={!!hasNextPage}
                          next={fetchNextPage}
                          loader={<></>}
                        >
                          {articles.map((article, index) => (
                            <LinkedArticle
                              key={index}
                              article={article}
                              className={cx('mb-1 px-5 py-1', {
                                'bg-focus-background':
                                  article.id === articleLink?.id,
                                'hover:bg-hover-blue':
                                  article.id !== articleLink?.id,
                              })}
                              onClick={() => {
                                setProp((props: ArticleLinkBlockProps) => {
                                  props.articleLink = article;
                                  props.unavailable = false;
                                });
                                selectNode(id);
                                setClicked(true);
                                setFocused(false);
                              }}
                            />
                          ))}
                        </InfiniteScroll>
                      </Articles>

                      <div
                        className={cx('px-5 text-xs text-grayscale-secondary', {
                          'mt-3': articles.length > 0,
                        })}
                      >
                        {t('Results found', {
                          count: resultsCount,
                        })}
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
            isOpen={enabled && focused}
            positions={['bottom', 'top']}
          >
            <div className="grow">
              {enabled && focused && (
                <Input2
                  autoFocus={focused}
                  className={cx('outline-[1px] transition-all', {
                    'rounded-lg': !selected,
                    'rounded-bl-[0] rounded-br-[0]':
                      selected && popoverPosition === 'bottom',
                    'rounded-tl-[0] rounded-tr-[0]':
                      selected && popoverPosition === 'top',
                  })}
                  defaultValue={searchValue}
                  height={30}
                  isSearch
                  name="search"
                  placeholder={t('Search for Article')}
                  onChange={(e) =>
                    debounceOnSearch(e.target.value.toLowerCase())
                  }
                  onFocus={() => {
                    setActive(true);
                    setFocused(true);
                  }}
                />
              )}
              {!focused && (
                <LinkedArticle
                  article={articleLink}
                  className={cx('rounded-lg shadow-atobi', {
                    'h-[80px] p-5 border border-gray-dark': enabled,
                    '!cursor-default !pr-4': !enabled && unavailable,
                  })}
                  endUser={!enabled}
                  unavailable={unavailable}
                  onClick={() => {
                    if (enabled) {
                      clicked && setFocused(true);
                    } else if (!unavailable && articleLink)
                      navigate(
                        routes.editorArticle.create(
                          articleLink.id.toString(),
                          mode === 'view' ? 'view' : 'actions'
                        )
                      );
                  }}
                />
              )}
            </div>
          </Popover>
        </div>
      </BaseBlockContainer>
    </div>
  );
};

ArticleLinkBlock.craft = {
  displayName: BlockTypes.ArticleLinkBlock,
};

const PopoverPosition = ({
  position,
  setPosition,
}: {
  position?: Position;
  setPosition(position?: Position): void;
}) => {
  useEffect(() => {
    setPosition(position);
  }, [position, setPosition]);

  return <></>;
};

export { ArticleLinkBlock };
export default ArticleLinkBlock;
