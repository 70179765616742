import { useNode } from '@craftjs/core';
import { css, cx } from '@emotion/css';
import config from 'app/config';
import { selectors } from 'app/store/editor';
import { CloseCircle, MinusCirlce, TickCircle } from 'iconsax-react';
import { ComponentProps } from 'react';
import { useSelector } from 'react-redux';
import { Text } from 'submodules/common-ui/generated/api/gcs';

import YesNoNaBlock from '../../../../YesNoNa';
import ActionContainer from '../ActionContainer';
import EditorNavigation from '../EditorNavigation';

const YesNoNaQuestionViewerAction = () => {
  const { selectedProps } = useNode((node) => ({
    selectedProps: node.data.props as ComponentProps<typeof YesNoNaBlock>,
  }));

  const { title, description, choices } = selectedProps;
  const currentLanguage = useSelector(selectors.getActiveLanguage);
  const renderIcon = (index: number) => {
    switch (index) {
      case 0:
        return <TickCircle className="mr-1" color={config.colors.success} />;
      case 1:
        return <CloseCircle className="mr-1" color={config.colors.error} />;
      case 2:
        return <MinusCirlce className="mr-1" color={config.colors.warning} />;
    }
  };

  return (
    <ActionContainer
      isMandatory={selectedProps.mandatory}
      canAnswer={false}
      type={'yes_no_task'}
    >
      <div className="flex flex-col">
        <span
          className={cx('text-lg font-bold', css('word-break: break-word;'))}
        >
          {title}
        </span>
        <span className={cx('text-black', css('word-break: break-word;'))}>
          {description}
        </span>
      </div>
      <div className="flex flex-col gap-3 mt-4">
        {choices &&
          choices.map((choice, index) => {
            return (
              <div
                key={index}
                className="rounded-lg border-gray-dark border opacity-50"
              >
                <div className="flex p-4">
                  {renderIcon(index)}
                  <span className="font-bold">
                    {
                      (
                        choice.variants[currentLanguage]?.answer
                          ?.children[0] as Text
                      )?.value
                    }
                  </span>
                </div>
              </div>
            );
          })}
      </div>
      <div className="flex justify-between items-center w-full mt-6">
        <EditorNavigation />
      </div>
    </ActionContainer>
  );
};

export default YesNoNaQuestionViewerAction;
