import { useEditor } from '@craftjs/core';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import BlockTypes from 'app/pages/Editor/helpers/constants';
import { Gallery, VideoVertical, Unlimited } from 'iconsax-react';
import React from 'react';

import SideMenuActionContainer from '../../../SideMenuActionContainer';

import { MediaBlockOption, MediaBlockType } from './MediaBlockOption';

interface Props {
  onClose: () => void;
}

const MediaBlockDropdown = ({ onClose }: Props) => {
  const { selected: selectedProps } = useEditor((state) => {
    const [currentNodeId] = state.events.selected;
    let selected;

    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        name: state.nodes[currentNodeId].data.name,
        settings:
          state.nodes[currentNodeId].related &&
          state.nodes[currentNodeId].related.settings,
      };
    }

    return {
      selected,
    };
  });

  const { t } = useArticlesTranslation();

  const getBlockTitle = () => {
    switch (selectedProps?.name) {
      case BlockTypes.ImageBlock:
        return t('Image');
      case BlockTypes.VideoBlock:
        return t('Video');
      default:
        undefined;
    }
  };

  const isSettingsOpened = selectedProps && selectedProps.settings;
  const title = isSettingsOpened
    ? t('{{block}} Settings', {
        block: getBlockTitle(),
      })
    : t('Media');

  return (
    <>
      {selectedProps?.name === BlockTypes.GiphyBlock ? (
        <div
          className="dropdown bg-white absolute rounded-xl shadow-atobi"
          role="presentation"
          onClick={(e) => e.stopPropagation()}
        >
          {isSettingsOpened && React.createElement(selectedProps.settings)}
        </div>
      ) : (
        <SideMenuActionContainer title={title} onClose={onClose}>
          {isSettingsOpened ? (
            React.createElement(selectedProps.settings)
          ) : (
            <>
              <MediaBlockOption
                text={t('Image')}
                Icon={Gallery}
                type={MediaBlockType.Image}
              />
              <MediaBlockOption
                text={t('Video')}
                Icon={VideoVertical}
                type={MediaBlockType.Video}
              />
              <MediaBlockOption
                text={t('Gif').toUpperCase()}
                Icon={Unlimited}
                type={MediaBlockType.Gif}
              />
            </>
          )}
        </SideMenuActionContainer>
      )}
    </>
  );
};

export default MediaBlockDropdown;
