import { cx } from '@emotion/css';
import { PublicUserProfile } from 'app/api/user/types';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { LocationTick, CalendarCircle } from 'iconsax-react';

import { useAnswersContext } from '../context';

interface UserDetailsProps {
  isLoadingUser: boolean;
  user: PublicUserProfile | undefined;
  createdAtTime: string | undefined;
  layout: 'list' | 'grid';
  score?: number | null;
}

const UserDetails = ({
  createdAtTime,
  isLoadingUser,
  layout,
  user,
  score,
}: UserDetailsProps) => {
  const { t } = useArticlesTranslation();
  const { selectedTenant } = useAnswersContext();

  return (
    <div className="flex gap-3">
      {!selectedTenant &&
        (isLoadingUser ? (
          <div className="h-[38px] w-[38px] bg-focus-background rounded-[10px]" />
        ) : (
          <img
            className={cx('rounded-[10px] object-cover', {
              'h-[38px] w-[38px]': layout === 'list',
              'h-[54px] w-[54px]': layout === 'grid',
            })}
            src={user?.avatars?.small}
            alt="User's avatar"
          />
        ))}

      <div className="flex flex-col gap-1 pt-0.5">
        {!selectedTenant &&
          (isLoadingUser ? (
            <div className="h-4 w-[74px] bg-focus-background rounded-xl" />
          ) : (
            <span className="font-black text-[14px] leading-4">
              {user?.firstName} {user?.lastName}
            </span>
          ))}

        {selectedTenant && (
          <span className="font-black text-[14px] leading-4">
            {selectedTenant?.tenantName} {t('member')}
          </span>
        )}

        <div
          className={
            layout === 'list' ? 'flex flex-row gap-3' : 'flex flex-col gap-1'
          }
        >
          {!selectedTenant && (
            <div
              className={cx('flex gap-0.5 items-center text-gray-light-blue')}
            >
              {isLoadingUser ? <></> : <LocationTick className="h-3.5 w-3.5" />}
              {isLoadingUser ? (
                <div className="h-3.5 w-[102px] bg-focus-background rounded-xl" />
              ) : (
                <span className="text-xs leading-[14px]">
                  {user?.location?.name}
                </span>
              )}
            </div>
          )}
          <div className={cx('flex gap-0.5 items-center text-gray-light-blue')}>
            <CalendarCircle className="h-3.5 w-3.5" />
            <span className="text-xs leading-[14px]">{createdAtTime}</span>
          </div>
        </div>
      </div>

      {!isLoadingUser && score !== null && score !== undefined && (
        <div className="ml-auto flex gap-1.5">
          <span className="text-[14px] leading-4 text-grayscale-primary">
            Score:
          </span>
          <span
            className={cx('text-[14px] leading-4 font-black', {
              'text-success': score === 100,
              'text-grayscale-primary': score !== 100,
            })}
          >
            {score}%
          </span>
        </div>
      )}
    </div>
  );
};

export default UserDetails;
