import { useArticlesTranslation } from 'app/internationalization/hooks';
import {
  RecurringArticleAction,
  RecurringArticleDate,
  RecurringArticleValue,
  days,
  months,
  weeks,
} from 'app/pages/Articles/Components/RecuringArticleModal/types';
import first from 'lodash/first';
import last from 'lodash/last';
import { useCallback } from 'react';
import {
  ActionSchedule,
  DailySchedule,
  DailyScheduleFrequencyEnum,
  MonthlySchedule,
  MonthlyScheduleFrequencyEnum,
  ScheduleMonthDay,
  WeeklySchedule,
  WeeklyScheduleFrequencyEnum,
} from 'submodules/common-ui/generated/api/gcs';

const useRecurringDateAsSchedule = () => {
  const { t } = useArticlesTranslation();

  const getFormattedDate = useCallback(
    (date: RecurringArticleAction) =>
      `${date.week?.label || ''} ${date.day?.label || ''} ${
        date.time || ''
      }`.trim(),
    []
  );

  const getFormattedOptions = useCallback(
    (repeat: RecurringArticleValue[], options: RecurringArticleValue[]) => {
      if (repeat.length === 1) return first(repeat)?.label;

      const selectedOptions = repeat.map((day) => options.indexOf(day));

      const isRightOrder = selectedOptions.every(
        (value, index, array) => index === 0 || value - array[index - 1] === 1
      );

      return isRightOrder
        ? `${first(repeat)?.label}-${last(repeat)?.label}`
        : repeat.map(({ label }) => label).join(', ');
    },
    []
  );

  const getScheduleDate = useCallback(
    (date?: RecurringArticleDate): ActionSchedule | undefined => {
      if (!date) return undefined;

      const { end, recurring, start } = date;

      const getExceptions = (scope: RecurringArticleValue[]) =>
        scope
          .filter(
            (day) => !date?.repeat.some(({ value }) => day.value === value)
          )
          .map(({ value }) => value);

      switch (recurring.value) {
        case DailyScheduleFrequencyEnum.Daily:
          return {
            frequency: 'daily',
            exceptions: getExceptions(days),
            end: { time: end.time },
            start: { time: start.time },
          } as DailySchedule;
        case WeeklyScheduleFrequencyEnum.Weekly:
          return {
            frequency: 'weekly',
            exceptions: getExceptions(weeks),
            end: {
              time: end.time,
              day: end.day?.value,
            },
            start: {
              time: start.time,
              day: start.day?.value,
            },
          } as WeeklySchedule;
        case MonthlyScheduleFrequencyEnum.Monthly:
          return {
            frequency: 'monthly',
            exceptions: getExceptions(months),
            end: {
              time: end.time,
              day: `${end.week?.value} ${end.day?.value}`,
            },
            start: {
              time: start.time,
              day: `${start.week?.value} ${start.day?.value}`,
            },
          } as MonthlySchedule;
        default:
          return undefined;
      }
    },
    []
  );

  const getRecurringDate = useCallback(
    (date: ActionSchedule): RecurringArticleDate | undefined => {
      const extractParts = (input: string) => ({
        firstPart: input.split(' ')[0],
        secondPart: input.split(' ')[1],
      });

      const extractDayAndWeek = (dayMonth: ScheduleMonthDay) => {
        const parts = extractParts(dayMonth.toString());
        const day = days.find(({ value }) => value === parts.secondPart);
        const week = weeks.find(({ value }) => value === parts.firstPart);
        return { day, week };
      };

      switch (date.frequency) {
        case DailyScheduleFrequencyEnum.Daily: {
          const selectedDays = days.filter(
            (day) => !date?.exceptions.some((value) => day.value === value)
          );

          return {
            repeat: selectedDays,
            recurring: {
              value: DailyScheduleFrequencyEnum.Daily,
              label: t('Daily ({{date}})', {
                date: getFormattedOptions(selectedDays, days),
              }),
            },
            start: {
              time: date.start.time,
            },
            end: {
              time: date.end.time,
            },
          };
        }
        case WeeklyScheduleFrequencyEnum.Weekly: {
          const selectedWeeks = weeks.filter(
            (day) => !date?.exceptions.some((value) => day.value === value)
          );

          const sDay = days.find(({ value }) => value === date.start.day);
          const eDay = days.find(({ value }) => value === date.end.day);

          return {
            repeat: selectedWeeks,
            recurring: {
              value: WeeklyScheduleFrequencyEnum.Weekly,
              label: t('Weekly ({{week}}; {{day}})', {
                week:
                  selectedWeeks.length === weeks.length
                    ? t('each week')
                    : getFormattedOptions(selectedWeeks, weeks),
                day:
                  eDay !== sDay ? `${sDay?.label}-${eDay?.label}` : sDay?.label,
              }),
            },
            start: {
              time: date.start.time,
              day: sDay,
            },
            end: {
              time: date.end.time,
              day: eDay,
            },
          };
        }
        case MonthlyScheduleFrequencyEnum.Monthly: {
          const selectedMonths = months.filter(
            (day) => !date?.exceptions.some((value) => day.value === value)
          );
          const { day: sDay, week: sWeek } = extractDayAndWeek(date.start.day);
          const { day: eDay, week: eWeek } = extractDayAndWeek(date.end.day);

          return {
            repeat: selectedMonths,
            recurring: {
              value: MonthlyScheduleFrequencyEnum.Monthly,
              label: t('Monthly ({{month}}; {{week}})', {
                month: getFormattedOptions(selectedMonths, months),
                week:
                  sDay !== eDay || sWeek !== eWeek
                    ? `${sWeek?.label} ${sDay?.label}-${eWeek?.label} ${eDay?.label}`
                    : `${sWeek?.label} ${eDay?.label}`,
              }),
            },
            start: {
              time: date.start.time,
              day: sDay,
              week: sWeek,
            },
            end: {
              time: date.end.time,
              day: eDay,
              week: eWeek,
            },
          };
        }
        default:
          return undefined;
      }
    },
    [getFormattedOptions, t]
  );

  return {
    getFormattedDate,
    getScheduleDate,
    getRecurringDate,
    getFormattedOptions,
  };
};

export default useRecurringDateAsSchedule;
