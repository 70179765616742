import { useEditor } from '@craftjs/core';
import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import config from 'app/config';
import GiphyBlock from 'app/pages/ArticleEditor/components/blocks/GiphyBlock';
import ImageBlock from 'app/pages/ArticleEditor/components/blocks/ImageBlock';
import VideoBlock from 'app/pages/ArticleEditor/components/blocks/VideoBlock';
import a11yKeydown from 'app/utils/a11y/keydown';
import { Icon as IconType } from 'iconsax-react';
import { useState } from 'react';

interface MediaBlockOptionProps {
  Icon: IconType;
  text: string;
  type: MediaBlockType;
}

enum MediaBlockType {
  Image = 'Image',
  Video = 'Video',
  Gif = 'Gif',
}

const MediaBlockOption = ({ Icon, text, type }: MediaBlockOptionProps) => {
  const [pressing, setPressing] = useState(false);

  const {
    connectors: { create },
    actions: { addNodeTree },
    query: { parseReactElement },
  } = useEditor();

  const addBlock = () => {
    const block = parseReactElement(getBlockToRender()).toNodeTree();

    addNodeTree(block, 'dropableRegion');
  };

  const getBlockToRender = (): JSX.Element => {
    switch (type) {
      case MediaBlockType.Image:
        return <ImageBlock translationStatus="draft" />;
      case MediaBlockType.Video:
        return <VideoBlock translationStatus="draft" />;
      case MediaBlockType.Gif:
        return <GiphyBlock translationStatus="draft" />;
      default:
        return <ImageBlock translationStatus="draft" />;
    }
  };

  return (
    <StyledContainer
      ref={(ref) => ref && create(ref, getBlockToRender)}
      className={cx(
        `w-full flex items-center rounded cursor-grab min-w-[148px] mt-2 py-2 px-3`,
        {
          'cursor-grabbing transform translate-0 bg-focus-background border border-focus':
            pressing,
          'bg-gray-white-shadow hover:bg-hover-blue border border-transparent':
            !pressing,
        }
      )}
      onClick={addBlock}
      onKeyDown={(e) => a11yKeydown(e, addBlock)}
      onMouseDown={() => setPressing(true)}
      onMouseUp={() => setPressing(false)}
      onDragStart={() => setPressing(true)}
      onDragEnd={() => setPressing(false)}
    >
      <Icon
        className="mr-2"
        size={16}
        color={pressing ? config.colors.focus : config.colors.black}
      />
      <span
        className={cx('text-xs', {
          'text-focus': pressing,
          'text-black': !pressing,
        })}
      >
        {text}
      </span>
      <div
        className={cx(
          'h-4.5 w-4.5 flex justify-center items-center absolute -top-1.5 -left-1.5 bg-focus rounded-lg text-white text-xs',
          { hidden: !pressing }
        )}
      >
        +
      </div>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  &:hover {
    svg {
      color: ${config.colors['grayscale-primary']};
    }
  }
`;

export { MediaBlockOption, MediaBlockType };

export default MediaBlockOption;
