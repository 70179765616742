import { KpisKey } from '../types';

import useBaseTranslation from './useBaseTranslation';

const useKpisTranslation = () =>
  useBaseTranslation<KpisKey>(undefined, {
    keyPrefix: 'kpis',
  });

export default useKpisTranslation;
