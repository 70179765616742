import { request } from 'app/api/gcsRequest';
import config from 'app/config';
import {
  ChannelApi,
  GetChannelPageShownAsEnum,
  SortOrderEnum,
} from 'submodules/common-ui/generated/api/gcs';

const getChannels = (
  page: number,
  ranking: SortOrderEnum,
  shownAs?: GetChannelPageShownAsEnum
) =>
  new ChannelApi(undefined, config.env.gcsApiUrl, request())
    .getChannelPage(page, 20, ranking, shownAs)
    .then((response) => response.data);

export { getChannels };
export default getChannels;
