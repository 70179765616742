import { queryKeys, resources } from 'app/api/articles';
import { useQuery } from 'react-query';

const useGetFile = (
  tenant: string,
  fileId: string,
  fileName: string,
  enabled = false
) => {
  const { data, isLoading, error, refetch } = useQuery(
    queryKeys.file(fileId, fileName),
    () => resources.getFile({ tenant, fileId, fileName }),
    { enabled }
  );

  return {
    data,
    refetch,
    isLoading,
    error,
  };
};

export default useGetFile;
