import { cx } from '@emotion/css';
import CheckBox from 'app/components/Checkbox';
import Input from 'app/components/Input';
import ScrollbarContainer from 'app/components/ScrollbarContainer';
import VerticalChevron from 'app/components/VerticalChevron';
import useChannelsTranslation from 'app/internationalization/hooks/useChannelsTranslation';
import useFormLanguages from 'app/pages/Channels/hooks/useFormLanguages';
import { ISOLanguage } from 'app/pages/Channels/types';
import { useState } from 'react';
import { Popover } from 'react-tiny-popover';

interface Values {
  isMain: boolean;
  checked: boolean;
  code: string;
}

interface Props {
  values?: Values[];
  selectedLanguages: ISOLanguage[];
  parent?: HTMLElement;
  handleCheckLanguage: (lang: ISOLanguage) => void;
}

const LanguageList = ({
  values,
  selectedLanguages,
  parent,
  handleCheckLanguage,
}: Props) => {
  const { languages, setSearch } = useFormLanguages();
  const { t } = useChannelsTranslation();
  const [languagesOpen, setLanguagesOpen] = useState(false);

  const toggleLanguages = () => setLanguagesOpen((prev) => !prev);

  const renderTitleTranslationsList = () => (
    <div className="max-w-[332px] bg-light border border-gray-light rounded shadow-atobi">
      <div className="flex flex-col p-4 pb-3 gap-2">
        <Input
          className="border border-gray-dark rounded"
          containerClassName="focus-within:!border-focus !rounded !px-3"
          inputClassName="py-[13px] focus"
          name="search-language"
          placeholder="Search for language"
          onChange={(e) => setSearch(e.target.value)}
        />
        <ScrollbarContainer className="h-[176px] overflow-auto">
          {languages.map((lang) => {
            const title = values?.find((e) => e.code === lang.code);
            return (
              <div
                key={lang.code}
                role="presentation"
                className={cx(
                  'flex items-center gap-2 p-2 rounded-sm cursor-pointer',
                  {
                    'bg-grayscale-bg-dark grayscale': title?.isMain,
                    'bg-focus-background': title?.checked,
                  }
                )}
                onClick={() => {
                  !title?.isMain && handleCheckLanguage(lang);
                }}
              >
                <CheckBox
                  id={lang.code}
                  checked={!!(title?.checked ?? title?.isMain)}
                  className={cx({
                    'opacity-70': title?.isMain,
                  })}
                  onClick={(e) => e.stopPropagation()}
                  onChange={() => {
                    !title?.isMain && handleCheckLanguage(lang);
                  }}
                />
                <span
                  className={cx({
                    'text-grayscale-secondary': title?.isMain,
                  })}
                >
                  {lang.name}
                </span>
              </div>
            );
          })}
        </ScrollbarContainer>
      </div>
    </div>
  );

  const renderTitleTranslations = () => (
    <button
      type="button"
      className="flex justify-between items-center pt-4 border-t-[1px] border-gray-light w-full z-999"
      onClick={toggleLanguages}
    >
      <span className="flex gap-2">
        {t('Add Translation')}
        <span className="h-[22px] w-[22px] bg-focus rounded-[6px] text-white flex items-center justify-center text-sm">
          {selectedLanguages.length}
        </span>
      </span>
      <VerticalChevron
        size={16}
        open={languagesOpen}
        onClick={(e) => {
          e.stopPropagation();
          toggleLanguages();
        }}
      />
    </button>
  );

  return (
    <Popover
      isOpen={languagesOpen}
      parentElement={parent}
      positions={['bottom', 'top']}
      align="start"
      onClickOutside={() => setLanguagesOpen(false)}
      containerClassName="-mt-4 z-[50] shadow-atobi rounded-lg bg-white min-w-[220px]"
      content={renderTitleTranslationsList()}
    >
      {renderTitleTranslations()}
    </Popover>
  );
};

export default LanguageList;
