import { request } from 'app/api/gcsRequest';
import config from 'app/config';
import {
  CategoryApi,
  NewCategory,
} from 'submodules/common-ui/generated/api/gcs';

const createCategory = (newCategory: NewCategory) =>
  new CategoryApi(undefined, config.env.gcsApiUrl, request()).createNewCategory(
    newCategory
  );

export default createCategory;
