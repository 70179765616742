interface Props {
  disabled: boolean;
  text?: string;
}
const Skeleton = ({ disabled, text }: Props) => {
  if (disabled) {
    return (
      <div className="relative w-full">
        <div className="w-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-md py-1 px-2 z-[1] shadow-atobi text-center">
          <span className="text-sm text-grayscale-secondary">{text}</span>
        </div>
        <div className="opacity-50">
          <div className="w-[66px] h-5 mt-6 bg-hover-blue rounded-lg" />
          <div className="w-full h-12 mt-3 bg-hover-blue rounded-lg" />

          {[...Array(4)].map((_, i) => (
            <div className="flex mt-3" key={i}>
              <div className="w-6 h-6 rounded-full bg-hover-blue" />
              <div className="flex-1 h-6 ml-[10px] bg-hover-blue rounded-lg" />
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col py-6 pr-6 rounded-r-[36px] bg-white">
      <div className="flex">
        <div className="w-6 h-6 bg-hover-blue rounded-lg rounded-r-[0]" />
        <div className="w-[60px] h-6 bg-hover-blue rounded-lg rounded-l-[0]" />
      </div>

      <div className="w-[66px] h-5 mt-6 bg-hover-blue rounded-lg" />
      <div className="w-full h-12 mt-3 bg-hover-blue rounded-lg" />
      <div className="w-[66px] h-5 mt-6 bg-hover-blue rounded-lg" />
      <div className="w-full h-12 mt-3 bg-hover-blue rounded-lg" />

      {[...Array(4)].map((_, i) => (
        <div className="flex mt-3" key={i}>
          <div className="w-6 h-6 rounded-full bg-hover-blue" />
          <div className="flex-1 h-6 ml-[10px] bg-hover-blue rounded-lg" />
        </div>
      ))}
    </div>
  );
};

export default Skeleton;
