const LoadingHeaderAndTitle = () => {
  return (
    <>
      <div className="rounded-xl p-2 gap-2 w-full flex flex-col bg-hover-blue">
        <div className="h-11 w-11 rounded bg-focus-background" />
        <div className="flex gap-2">
          <div className="w-7 h-7 rounded bg-focus-background" />
          <div className="w-7 h-7 rounded bg-focus-background" />
          <div className="w-34 h-7 p-1 rounded bg-focus-background" />
        </div>
      </div>

      <div className="rounded-xl p-2 gap-2 w-full flex flex-col bg-hover-blue">
        <div className="w-full h-5 p-1 rounded bg-focus-background" />
        <div className="w-full h-5 rounded bg-focus-background" />
      </div>
    </>
  );
};

export default LoadingHeaderAndTitle;
