import { createSlice } from '@reduxjs/toolkit';

import { NAME } from './constants';
import { CommentsModalState } from './types';

const initialState: CommentsModalState = {
  isVisible: false,
};

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    showCommentModal: (state) => {
      state.isVisible = true;
    },
    hideCommentModal: (state) => {
      state.isVisible = false;
    },
  },
});

export default slice;
