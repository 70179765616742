import { queryKeys, resources } from 'app/api/articles';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import queryClient from 'app/query';
import { isAxiosError } from 'axios';
import { useMutation } from 'react-query';

const useUpdateArticleMutation = () => {
  const { t } = useArticlesTranslation();

  const serverErrorMessage = (error: unknown): string | undefined => {
    if (!isAxiosError(error)) return undefined;

    const { response } = error;
    const message = Array.isArray(response?.data)
      ? response?.data?.[0]?.message
      : response?.data;

    const status = response?.status;

    return status === 404 && message === 'Category not found'
      ? t(
          'The selected categories are no longer available. Remove them to continue.'
        )
      : message;
  };

  const { mutate, isLoading, isError, data } = useMutation(
    resources.updateArticle,
    {
      onSettled: (res, _) => {
        if (!res) return;
        queryClient.invalidateQueries(queryKeys.article(res.id));
        queryClient.invalidateQueries(['articles']);
      },
    }
  );

  return { mutate, isLoading, isError, data, serverErrorMessage };
};

export default useUpdateArticleMutation;
