import { Node } from '@craftjs/core/lib/interfaces/nodes';
import { PollBlockProps } from 'app/pages/ArticleEditor/components/blocks/tasks/Poll';
import { ScormProps } from 'app/pages/ArticleEditor/components/blocks/tasks/ScormBlock';
import { SimpleTaskProps } from 'app/pages/ArticleEditor/components/blocks/tasks/SimpleTask';
import { UrlBlockProps } from 'app/pages/ArticleEditor/components/blocks/UrlBlock';

import BlockTypes from './constants';

const mandatoryFieldsMissing = (nodes: Record<string, Node>): boolean => {
  return Object.keys(nodes).some((key) => {
    switch (nodes[key].data.displayName) {
      case BlockTypes.CoverImageBlock:
        return !nodes[key].data.props?.imageSchema;
      case BlockTypes.TitleBlock:
        return !nodes[key].data.props?.text;
      case BlockTypes.SimpleTaskBlock:
        return !(nodes[key].data.props as SimpleTaskProps)?.description;
      case BlockTypes.QuizBlock: {
        if (!(nodes[key].data.props as SimpleTaskProps)?.description)
          return true;

        const optionsContainer =
          nodes[key].data.linkedNodes['optionsContainer'];

        const choices = nodes[optionsContainer]?.data?.nodes;

        const noneCorrect = choices.every(
          (choice) => !nodes[choice].data.props.checked
        );

        const bothFieldsEmpty = choices.some(
          (choice) =>
            !nodes[choice].data.props.text &&
            !nodes[choice].data.props.imageSchema
        );

        return noneCorrect || bothFieldsEmpty;
      }
      case BlockTypes.UrlBlock:
        return (
          !(nodes[key].data.props as UrlBlockProps).name ||
          !(nodes[key].data.props as UrlBlockProps).url ||
          !(nodes[key].data.props as UrlBlockProps).isValidUrl
        );
      case BlockTypes.PollBlock: {
        if (!(nodes[key].data.props as PollBlockProps)?.description)
          return true;

        const optionsContainer =
          nodes[key].data.linkedNodes['pollOptionsContainer'];

        const choices = nodes[optionsContainer]?.data?.nodes;

        return choices.some((choice) => {
          return (
            !nodes[choice].data.props.text?.children[0]?.value &&
            !nodes[choice].data.props.imageSchema
          );
        });
      }
      case BlockTypes.ScormBlock:
        return (
          !(nodes[key].data.props as ScormProps).description ||
          !(nodes[key].data.props as ScormProps).package ||
          !(nodes[key].data.props as ScormProps).image
        );
      default:
        break;
    }
  });
};

export default mandatoryFieldsMissing;
