import { Node } from '@craftjs/core/lib/interfaces/nodes';
import useArchiveArticleMutation from 'app/pages/Articles/hooks/useArchiveArticleMutation';
import useUnarchiveArticleMutation from 'app/pages/Articles/hooks/useUnarchiveArticleMutation';
import { actions, selectors } from 'app/store/editor';
import { isAxiosError } from 'axios';
import dayjs from 'dayjs';
import noop from 'lodash/noop';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Article,
  ArticleStatusEnum,
  NewArticleStatus,
  UpdatedArticle,
} from 'submodules/common-ui/generated/api/gcs';

import useCreateArticleMutation from './mutations/useCreateArticleMutation';
import useUpdateArticleMutation from './mutations/useUpdateArticleMutation';
import useMapNewArticle from './useMapNewArticle';
import useMapUpdateArticle from './useMapUpdateArticle';

interface Props {
  article?: Article;
  nodes: Record<string, Node>;
}

export type Overwrite = Record<'overwrite', boolean>;
export interface ArticleState {
  state?:
    | 'created'
    | 'updated'
    | 'published'
    | 'template'
    | 'createdPublished'
    | 'error'
    | 'scheduled'
    | 'createdScheduled'
    | 'scheduleCanceled'
    | 'archived'
    | 'unarchived'
    | 'conflict';
  result?: Article;
  errorMessage?: string;
  retryFn?: (props?: Overwrite) => void;
}

const useMutateArticle = ({ article, nodes }: Props) => {
  const { id: articleId } = useParams<{ id?: string }>();

  const publishDate = useSelector(selectors.getPublishDate);
  const archiveDate = useSelector(selectors.getArchiveDate);

  const dispatch = useDispatch();

  const { mutate: createArticle, isLoading: isCreatingArticle } =
    useCreateArticleMutation();
  const {
    mutate: updateArticle,
    isLoading: isUpdatingArticle,
    serverErrorMessage,
  } = useUpdateArticleMutation();
  const { mutate: unarchiveArticleMutation, isLoading: isUnarchivingArticle } =
    useUnarchiveArticleMutation();
  const { mutate: archiveArticleMutation, isLoading: isArchivingArticle } =
    useArchiveArticleMutation();

  const { nodesToNewArticle } = useMapNewArticle(nodes);
  const { nodesToUpdateArticle } = useMapUpdateArticle(nodes);

  const [articleState, setArticleState] = useState<ArticleState>({
    state: undefined,
  });

  const publishNowDate = `${dayjs().format().substring(0, 19)}Z`;

  const articleConflictError = (error: unknown) => {
    if (!isAxiosError(error)) return false;

    return error.response?.status === 409;
  };

  const getArticlePublishParams = <T>(
    hasSchedule?: boolean
  ): {
    status: T;
    publishAt: string | null;
    archiveAt: string | null;
  } => {
    if (!hasSchedule) {
      return {
        status: 'published' as T,
        publishAt: publishNowDate,
        archiveAt: null,
      };
    }

    return {
      status: 'published' as T,
      publishAt: publishDate ? publishDate : null,
      archiveAt: archiveDate ? archiveDate : null,
    };
  };

  const saveNewArticle = ({
    isPublish = false,
    isSchedule = false,
  }: {
    isPublish?: boolean;
    isSchedule?: boolean;
  }) => {
    const newArticle = nodesToNewArticle();

    if (!newArticle) return;

    if (isPublish || isSchedule) {
      const { archiveAt, publishAt, status } =
        getArticlePublishParams<NewArticleStatus>(isSchedule);

      newArticle.archiveAt = archiveAt;
      newArticle.publishAt = publishAt;
      newArticle.status = status;
      newArticle.publishedInstantly = status === 'published' && !isSchedule;
    }

    createArticle(
      { article: newArticle },
      {
        onSuccess: (res) =>
          setArticleState({
            state: isPublish
              ? 'createdPublished'
              : isSchedule
                ? 'createdScheduled'
                : 'created',
            result: res,
          }),
        onError: () =>
          setArticleState({
            state: 'error',
          }),
      }
    );
  };

  const updateHandler = (
    articleToUpdate: UpdatedArticle,
    onSuccess: (updated: Article) => void,
    onError: (error: unknown) => void,
    retryFn: (props?: Overwrite) => void
  ) => {
    updateArticle(
      {
        article: articleToUpdate,
      },
      {
        onSuccess,
        onError: (error) => {
          if (!articleConflictError(error)) {
            onError(error);
            return;
          }

          return setArticleState({
            state: 'conflict',
            retryFn,
          });
        },
      }
    );
  };

  const saveArticle = (props?: Overwrite): Promise<void> | undefined => {
    if (!article) return;

    return new Promise<void>((resolve, reject) => {
      const updatedArticle = nodesToUpdateArticle(article);

      updatedArticle.archiveAt = archiveDate ? archiveDate : null;
      updatedArticle.publishAt = publishDate
        ? publishDate
        : updatedArticle.publishAt;

      if (props?.overwrite) updatedArticle.updatedAt = undefined;

      const onSuccess = (updated: Article) => {
        dispatch(actions.setArticle(updated));
        setArticleState({ state: 'updated' });
        resolve();
      };

      const onError = (error: unknown) => {
        setArticleState({
          state: 'error',
          errorMessage: serverErrorMessage(error),
        });

        reject();
      };

      updateHandler(updatedArticle, onSuccess, onError, saveArticle);
    });
  };

  const publishArticle = (props?: Overwrite) => {
    if (!article || !articleId) {
      return saveNewArticle({ isPublish: true });
    }

    const updatedArticle = nodesToUpdateArticle(article);

    updatedArticle.archiveAt = null;
    updatedArticle.publishAt = publishNowDate;
    updatedArticle.status = 'published';
    updatedArticle.publishedInstantly = true;

    if (props?.overwrite) updatedArticle.updatedAt = undefined;

    const onSuccess = (updated: Article) => {
      setArticleState({ state: 'published' });
      dispatch(actions.setArticle(updated));
    };

    const onError = (error: unknown) => {
      setArticleState({
        state: 'error',
        errorMessage: serverErrorMessage(error),
      });
    };

    updateHandler(updatedArticle, onSuccess, onError, publishArticle);
  };

  const templateArticle = (props?: Overwrite) => {
    if (!article) return;

    const updatedArticle = nodesToUpdateArticle(article);
    if (props?.overwrite) updatedArticle.updatedAt = undefined;

    const onSuccess = (updated: Article) => {
      setArticleState({ state: 'template' });
      dispatch(actions.setArticle(updated));
    };

    const onError = (error: unknown) => {
      setArticleState({
        state: 'error',
        errorMessage: isAxiosError(error)
          ? (error.response?.data[0]?.message as string)
          : undefined,
      });
    };

    updateHandler(
      { ...updatedArticle, isTemplate: true },
      onSuccess,
      onError,
      templateArticle
    );
  };

  const unarchiveArticle = () => {
    if (!article) return;

    if (article.status !== 'archived') return;

    unarchiveArticleMutation(
      {
        articleIds: [article.id],
      },
      {
        onSuccess: (res) => {
          setArticleState({ state: 'unarchived' });

          if (res?.[0]) {
            dispatch(actions.setArticle(res[0]));
          }
        },
      }
    );
  };

  const archiveArticle = () => {
    if (!article) return;

    if (article.status !== 'published') return;

    archiveArticleMutation(
      {
        articleIds: [article.id],
      },
      {
        onSuccess: (res) => {
          setArticleState({ state: 'archived' });

          if (res?.[0]) {
            dispatch(actions.setArticle(res[0]));
          }
        },
      }
    );
  };

  const scheduleArticle = (props?: Overwrite) => {
    if (!article || !articleId) {
      saveNewArticle({ isSchedule: true });
      return;
    }

    const updatedArticle = nodesToUpdateArticle(article);
    const { archiveAt, publishAt, status } =
      getArticlePublishParams<ArticleStatusEnum>(true);

    updatedArticle.archiveAt = archiveAt;
    updatedArticle.publishAt = publishAt;
    updatedArticle.status = status;
    updatedArticle.publishedInstantly = false;

    if (props?.overwrite) updatedArticle.updatedAt = undefined;

    const onSuccess = (updated: Article) => {
      setArticleState({ state: 'scheduled' });
      dispatch(actions.setArticle(updated));
    };

    // eslint-disable-next-line sonarjs/no-identical-functions
    const onError = (error: unknown) => {
      setArticleState({
        state: 'error',
        errorMessage: serverErrorMessage(error),
      });
    };

    updateHandler(updatedArticle, onSuccess, onError, scheduleArticle);
  };

  const cancelArticleSchedule = (props?: Overwrite) => {
    dispatch(actions.setArchiveDate(undefined));
    dispatch(actions.setPublishDate(undefined));

    if (!article) {
      setArticleState({ state: 'scheduleCanceled' });
      return;
    }

    const updatedArticle = nodesToUpdateArticle(article);

    updatedArticle.archiveAt = null;
    updatedArticle.publishAt = null;
    updatedArticle.status = 'draft';

    if (props?.overwrite) updatedArticle.updatedAt = undefined;

    const onSuccess = (updated: Article) => {
      setArticleState({ state: 'scheduleCanceled' });
      dispatch(actions.setArticle(updated));
    };

    updateHandler(updatedArticle, onSuccess, noop, cancelArticleSchedule);
  };

  return {
    saveArticle,
    saveNewArticle,
    publishArticle,
    scheduleArticle,
    unarchiveArticle,
    cancelArticleSchedule,
    archiveArticle,
    templateArticle,
    articleState,
    isCreatingArticle,
    isUpdatingArticle,
    isUnarchivingArticle,
    isArchivingArticle,
  };
};

export default useMutateArticle;
