import { setUser } from '@sentry/react';
import createOpenIdManager from 'app/api/createOpenIdManager';
import config from 'app/config';
import { useSelector, useDispatch } from 'app/hooks';
import { useBaseTranslation } from 'app/internationalization/hooks';
import { resetState as resetQueryState } from 'app/query/utils';
import { persistor, store } from 'app/store';
import { actions } from 'app/store/auth';
import { actions as configActions } from 'app/store/config';
import { selectors } from 'app/store/request';
import { resetState as resetReduxState } from 'app/store/utils';
import Message, { MessageKeys } from 'app/swTypes';
import { selectData, dayjs } from 'app/utils';
import { remapApiLanguageCodeToGcsLanguage } from 'app/utils/remapApiLanguagesToGcs';
import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { reset as resetChatRequest } from '../../chat/request';
import { setContentLanguage, reset } from '../../request';
import { COLLABORATOR_PERMISSIONS, MANAGER_ROLES, Role } from '../constants';
import queryKeys from '../queryKeys';
import getAuthenticatedUser from '../resources/getAuthenticatedUser';

type HasRoleArg = Role | string;

type HasRole = (roles: HasRoleArg | Array<HasRoleArg>) => boolean;

const useAuthenticatedUser = () => {
  const navigate = useNavigate();
  const requestInstanceReady = useSelector(selectors.getRequestInstanceReady);
  const { i18n } = useBaseTranslation();
  const dispatch = useDispatch();

  const { data, refetch, isLoading, isError } = useQuery(
    queryKeys.authenticatedUser(),
    getAuthenticatedUser,
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,

      onSuccess: (response) => {
        const responseData = selectData(response);
        if (!responseData) return undefined;

        responseData.contentLanguage.uiLanguage =
          remapApiLanguageCodeToGcsLanguage(
            responseData.contentLanguage.uiLanguage
          );

        // Configure request headers
        setContentLanguage(responseData.contentLanguage.current.code);

        // Set Translation Language
        dispatch(
          actions.translationLanguageChanged(
            responseData.translationLanguage ?? 'en'
          )
        );

        dispatch(
          actions.moduleAccessPermissionsRetrieved(
            responseData.permissions ?? null
          )
        );

        // Set user context for sentry
        setUser({
          id: String(responseData?.id),
          email: responseData?.email,
          username: responseData?.name,
        });

        return undefined;
      },
      enabled: requestInstanceReady,
    }
  );

  const user = useMemo(() => selectData(data), [data]);

  useEffect(() => {
    if (!user) return;
    const { uiLanguage } = user.contentLanguage;

    i18n.changeLanguage(uiLanguage);
    dayjs.locale(uiLanguage);
  }, [i18n, user]);

  const hasRole: HasRole = (roles) => {
    if (!user) return false;
    const userRole = user.profession.slug;
    if (Array.isArray(roles)) {
      return roles.includes(userRole);
    }

    return roles === userRole;
  };

  const isCollaborator = () =>
    user?.permissions?.modules?.articles?.some((permission) =>
      COLLABORATOR_PERMISSIONS.includes(permission)
    ) ?? false;

  const showAdvancedDashboard =
    user?.permissions?.modules?.advancedDashboard?.includes('read') ?? false;

  const isChannelsManager = () => user?.permissions?.modules?.channels ?? false;

  const hasArticleDeletePermission =
    user?.permissions?.modules?.articles?.includes('delete') ?? false;

  const hasCreateArticlePermission =
    user?.permissions.modules.articles.includes('create') ?? false;

  const hasUpdateArticlePermission =
    user?.permissions.modules.articles.includes('update') ?? false;

  const hasConnectAccessPermission = !!user?.permissions.modules.connectAccess;

  const getPermissions = () => {
    if (user?.roles && user?.roles.length > 0) {
      return user.roles.reduce((permissions: Array<string>, role) => {
        role.permissions.forEach((permission) => {
          // Check if the permission name is already in the array
          if (!permissions.includes(permission.name)) {
            permissions.push(permission.name);
          }
        });
        return permissions;
      }, []);
    }
    return [];
  };

  return {
    refetch,
    data: user,
    isLoading,
    hasRole,
    isManager: () => hasRole(MANAGER_ROLES),
    isCollaborator,
    showAdvancedDashboard,
    isChannelsManager,
    getPermissions,
    hasCreateArticlePermission,
    hasArticleDeletePermission,
    hasConnectAccessPermission,
    hasUpdateArticlePermission,
    isError,
    logout: async (logoutFromIdentityServer = true) => {
      navigator.serviceWorker.controller?.postMessage({
        key: MessageKeys.unsubscribe,
        value: {
          url: config.env.pushNotificationsApiUrl,
          token: '',
          vapidKey: '',
        },
      } satisfies Message);

      const idToken = store.getState().auth.idToken;
      await persistor.purge();
      await resetQueryState();
      resetReduxState();
      // Adding logout param, for not showing login form while we still need redirect to Identity server
      if (logoutFromIdentityServer) {
        dispatch(configActions.isLoggingOutRetrieved(true));
        navigate({ pathname: '/', search: '?logout=1' });
      }
      // Reset request instance -> remove token, base URL from request cache
      reset();
      resetChatRequest();

      if (logoutFromIdentityServer) {
        await createOpenIdManager().signoutRedirect({
          id_token_hint: idToken,
        });
      }
    },
  };
};

export default useAuthenticatedUser;
