import { cx } from '@emotion/css';
import useGetCategoriesQuery from 'app/api/categories/hooks/useCategories';
import { InfiniteScroll, PageLoader, VerticalChevron } from 'app/components';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import useGetCategoryName from 'app/pages/Editor/hooks/useGetCategoryName';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import CheckLineIcon from 'remixicon-react/CheckLineIcon';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import {
  GetCategory,
  CategoryStatusEnum,
} from 'submodules/common-ui/generated/api/gcs';

interface Props {
  selectedCategories?: GetCategory[];
  onApplyCategories: (categories: GetCategory[]) => void;
  onRemoveCategory: (categoryId: number) => void;
}

const CategoriesDropdown: FC<Props> = ({
  selectedCategories,
  onApplyCategories,
  onRemoveCategory,
}) => {
  const [open, setOpen] = useState(false);
  const [dropdownSelectedCategories, setDropdownSelectedCategories] = useState<
    GetCategory[]
  >(selectedCategories ?? []);
  const { t } = useArticlesTranslation();
  const { getCategoryName } = useGetCategoryName();
  const [search, setSearch] = useState('');

  const {
    data,
    isLoading: isLoadingCategories,
    hasNextPage,
    fetchNextPage,
  } = useGetCategoriesQuery({
    page: 0,
    per_page: 10,
    name: search,
    status: CategoryStatusEnum.Active,
  });

  useEffect(() => {
    setDropdownSelectedCategories(selectedCategories ?? []);
  }, [selectedCategories]);

  const categories = useMemo(() => data ?? [], [data]);

  const suggestions = useMemo(() => {
    if (search === '') return categories;

    return categories?.filter((category) =>
      Object.keys(category.variants).find(
        (key) =>
          category.variants[key].isDefault &&
          category.variants[key].name
            .toLowerCase()
            .includes(search.trim().toLowerCase())
      )
    );
  }, [search, categories]);

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value),
    []
  );

  const handleCategoryClick = useCallback(
    (category: GetCategory) => {
      const isSelected = dropdownSelectedCategories.some(
        (prev) => prev.id === category.id
      );

      const newSelectedCategories = isSelected
        ? dropdownSelectedCategories.filter((prev) => prev.id !== category.id)
        : [...dropdownSelectedCategories, category];

      setDropdownSelectedCategories(newSelectedCategories);
      onApplyCategories(newSelectedCategories);
      setOpen(false);
    },
    [dropdownSelectedCategories, onApplyCategories]
  );

  const handleOutsideClick = useCallback(() => {
    setOpen(false);
    setDropdownSelectedCategories([]);
  }, [setOpen, setDropdownSelectedCategories]);

  return (
    <div className="relative w-full">
      <label className="font-bold text-sm">{t('Categories')}</label>
      <OutsideClickHandler onOutsideClick={handleOutsideClick}>
        <div
          role="presentation"
          onClick={() => setOpen(!open)}
          className={cx(
            'relative min-h-[46px] max-h-[140px] border gap-2 overflow-hidden rounded p-2 flex',
            selectedCategories && selectedCategories.length > 0
              ? 'border-focus'
              : 'focus-within:border-gray-dark border-gray-dark'
          )}
        >
          <div className={cx('w-full gap-2 flex flex-wrap mr-7')}>
            {selectedCategories &&
              selectedCategories.slice(0, 6).map((category) => (
                <div
                  key={category.id}
                  className="bg-hover-blue border-2 border-focus-background hover:bg-focus-background rounded-full h-[36px] w-fit px-2 flex gap-2 group items-center"
                >
                  <span className="text-sm text-ceil">
                    {getCategoryName(category)}
                  </span>
                  <button
                    type="button"
                    className="group-hover:visible invisible"
                    onClick={() => onRemoveCategory(category.id)}
                  >
                    <CloseLineIcon className="text-grayscale-secondary h-4 w-4" />
                  </button>
                </div>
              ))}
            {selectedCategories && selectedCategories.length > 6 && (
              <div className="bg-hover-blue border-2 border-focus-background hover:bg-focus-background rounded-full h-[36px] w-fit px-2 flex gap-2 group items-center">
                <span className="text-sm text-ceil">
                  +{selectedCategories.length - 6}
                </span>
              </div>
            )}
            <input
              placeholder={
                selectedCategories?.length === 0
                  ? t('Add one or several categories') ??
                    'Add one or several categories'
                  : undefined
              }
              value={search}
              onChange={handleSearchChange}
              autoComplete="off"
              className="border-none outline-none flex-grow min-w-0"
            />
          </div>
          <div className="absolute right-2 top-4">
            <VerticalChevron open={open} className="w-4 h-4 mr-3" />
          </div>
        </div>

        {open && (
          <div className="absolute top-13 left-0 w-full bg-white border border-gray-light rounded shadow-md z-50">
            {isLoadingCategories && (
              <div className="h-full">
                <PageLoader />
              </div>
            )}
            <div
              id="parent"
              className={cx(
                'h-full overflow-auto max-h-[256px]',
                !isEqual(
                  sortBy(selectedCategories, 'id'),
                  sortBy(dropdownSelectedCategories, 'id')
                ) && 'max-h-[192px]'
              )}
            >
              <InfiniteScroll
                dataLength={suggestions.length}
                hasMore={!!hasNextPage}
                hasChildren={suggestions.length > 0}
                next={fetchNextPage}
                scrollableTarget="parent"
                loader={<></>}
              >
                {suggestions.map((category) => (
                  <button
                    type="button"
                    key={category.id}
                    className={cx(
                      'w-full text-center flex p-2 items-center gap-2 cursor-pointer hover:bg-grayscale-secondary hover:bg-opacity-10 h-16',
                      dropdownSelectedCategories.some(
                        (prev) => prev.id === category.id
                      ) && 'bg-grayscale-secondary bg-opacity-10'
                    )}
                    onClick={() => handleCategoryClick(category)}
                  >
                    <span className="text-sm text-grayscale-primary">
                      {getCategoryName(category)}
                    </span>
                    {dropdownSelectedCategories.some(
                      (prev) => prev.id === category.id
                    ) && (
                      <div className="ml-auto mr-3">
                        <CheckLineIcon className="text-focus" />
                      </div>
                    )}
                  </button>
                ))}
              </InfiniteScroll>
              {(!categories || suggestions.length === 0) &&
                !isLoadingCategories && (
                  <div className="flex p-2 items-center gap-2 cursor-pointer hover:bg-grayscale-secondary hover:bg-opacity-10 h-16">
                    <span className="text-sm text-grayscale-primary">
                      {t('No categories found')}
                    </span>
                  </div>
                )}
            </div>
          </div>
        )}
      </OutsideClickHandler>
      <span className="text-xs text-grayscale-secondary">
        {t('Categories will help audience members find the right info')}
      </span>
    </div>
  );
};

export default CategoriesDropdown;
