import useScormAction from 'app/api/articles/hooks/useScormContent';
import { useAuthenticatedUser } from 'app/api/auth/hooks';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import Spinner from 'assets/icons/spinner.svg?react';
import { AxiosError } from 'axios';
import { Danger } from 'iconsax-react';
import { useCallback, useState } from 'react';

export type ScormAnswerModalProps = {
  actionId: string;
  languageCode: string;
};

const ScormIframe = ({ actionId, languageCode }: ScormAnswerModalProps) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  const { data: user } = useAuthenticatedUser();

  const { data, error, apiError } = useScormAction({
    blockId: Number(actionId),
    languageCode,
    actionInstanceId: undefined,
    timezone: user?.timezone,
    enabled: !!user,
  });

  const { t } = useArticlesTranslation();

  const renderApiError = useCallback(() => {
    const status = (apiError as AxiosError)?.response?.status;
    let message = '';

    if (status === 404 || status === 402) {
      message = t('Sorry, the requested language was not found');
    }

    if (status === 403) {
      message = t('You don’t have access to this course');
    }

    return (
      <div className="h-full w-full z-10 absolute top-0 right-0 flex justify-center items-center">
        <div className="flex items-center flex-col gap-2">
          <span>
            <Danger className="w-5 h-5 text-grayscale-primary" />
          </span>
          {message && (
            <span className="text-grayscale-primary font-bold">{message}</span>
          )}
          {status && (
            <span className="text-grayscale-primary text-sm">
              {t('Error {{status}}', { status })}
            </span>
          )}
        </div>
      </div>
    );
  }, [apiError, t]);

  return (
    <div className="h-full w-full relative">
      {data && (
        <iframe
          onLoad={() => {
            setTimeout(() => {
              setLoaded(true);
            }, 1000);
          }}
          className="w-full h-full"
          src={data.url}
          title={data.url}
        />
      )}
      {!loaded && !error && (
        <div className="h-full w-full z-10 absolute top-0 right-0 flex justify-center items-center">
          <div className="flex items-center flex-col gap-2">
            <span>
              <Spinner className="w-5 h-5 animate-spin text-grayscale-primary" />
            </span>
            <span className="text-grayscale-primary font-bold">
              {t('Preparing course')}...
            </span>
          </div>
        </div>
      )}
      {error && renderApiError()}
    </div>
  );
};

export default ScormIframe;
