import { IconButton, Modal } from 'app/components';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import CloseFillIcon from 'remixicon-react/CloseFillIcon';

import RecurringArticleView from './components/RecurringArticleView';
import { RecurringArticleContextProvider } from './context';
import { RecurringArticleDate } from './types';

interface Props {
  existingRecurringDate?: RecurringArticleDate | null;
  onClose: () => void;
  onSubmit: (date: RecurringArticleDate) => void;
  onCancel: () => void;
}

const RecurringArticleModal = ({
  onClose,
  onCancel,
  onSubmit,
  existingRecurringDate,
}: Props) => {
  const { t } = useArticlesTranslation();

  return (
    <Modal className="w-[384px]" onClose={onClose}>
      <div className="flex justify-between items-center mb-4">
        <span className="text-lg text-grayscale-primary font-bold">
          {t('Recurring')}
        </span>
        <IconButton
          Icon={CloseFillIcon}
          onClick={onClose}
          className="shadow-none"
        />
      </div>
      <RecurringArticleContextProvider
        existingRecurringDate={existingRecurringDate}
      >
        <RecurringArticleView onSubmit={onSubmit} onCancel={onCancel} />
      </RecurringArticleContextProvider>
    </Modal>
  );
};

export default RecurringArticleModal;
