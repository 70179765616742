import { cx } from '@emotion/css';
import config from 'app/config';
import React from 'react';
import CheckLineIcon from 'remixicon-react/CheckLineIcon';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';

interface ToggleButtonProps {
  checked: boolean;
  label?: string;
  onClick(): void;
}

const ToggleButton: React.FC<ToggleButtonProps> = ({
  checked,
  label,
  onClick,
}) => (
  <div className="flex items-center">
    <button type="button" onClick={onClick}>
      <div
        className={cx(
          'relative w-12 h-7 rounded-full transition-all duration-500 ease-in-out w-100',
          {
            'bg-success hover:bg-success-hover': checked,
            'bg-gray-light hover:bg-gray-dark': !checked,
          }
        )}
      >
        <div
          className={cx(
            `absolute top-0.5 left-0.5 bg-white w-6 h-6 rounded-full border-0 shadow-alert flex justify-center items-center transition-all duration-300 ease-in-out`,
            { 'transform translate-x-5': checked }
          )}
        >
          {checked ? (
            <CheckLineIcon color={config.colors.success} size={16} />
          ) : (
            <CloseLineIcon
              color={config.colors['grayscale-secondary']}
              size={16}
            />
          )}
        </div>
      </div>
    </button>
    {label && <div className="ml-2 text-sm">{label}</div>}
  </div>
);

export default ToggleButton;
