import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import config from 'app/config';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { Magicpen, Speedometer } from 'iconsax-react';

import useResolveSentiment from './useResolveSentiment';

interface MainSummaryProps {
  summary: string;
  score: number;
}

const MainSummary = ({ summary, score }: MainSummaryProps) => {
  const { t } = useArticlesTranslation();
  const resolveSentiment = useResolveSentiment();

  return (
    <div className="bg-white rounded-lg px-3 py-2.5 border border-focus-background mx-4 my-3">
      <h1 className="text-sm flex items-center">
        <Magicpen size={16} className="mr-1" /> <span>{t('Summary')}</span>
      </h1>
      <div className="text-xs py-2 flex items-center">
        <Speedometer size={16} className="mr-1" />
        <span className="pr-1">{t('Overal sentiment')}:</span>
        <span className={cx('group relative', resolveSentiment(score).color)}>
          {resolveSentiment(score).sentiment}
          <Tooltip className="!rounded !opacity-100 top-6 left-6 bg-gray-extra-dark absolute z-999 hidden group-hover:inline-block text-white px-4 py-2 w-[283px] whitespace-normal text-center text-sm -translate-x-1/2">
            {resolveSentiment(score).tooltip}
          </Tooltip>
        </span>
      </div>
      <div className="text-sm">{summary}</div>
    </div>
  );
};

const Tooltip = styled.div`
  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 50%;
    border-width: 5px;
    border-style: solid;
    transform: translateX(-50%);
    border-color: transparent transparent ${config.colors['gray-extra-dark']}
      transparent;
  }
`;

export default MainSummary;
