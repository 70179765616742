import {
  DailyScheduleFrequencyEnum,
  MonthlyScheduleFrequencyEnum,
  ScheduleMonth,
  ScheduleWeekDay,
  WeeklyScheduleExceptionsEnum,
  WeeklyScheduleFrequencyEnum,
} from 'submodules/common-ui/generated/api/gcs';

export type RecurringArticleDropdownValue =
  | WeeklyScheduleFrequencyEnum
  | DailyScheduleFrequencyEnum
  | MonthlyScheduleFrequencyEnum;

export interface RecurringArticleDate {
  start: RecurringArticleAction;
  end: RecurringArticleAction;
  repeat: RecurringArticleValue[];
  recurring: RecurringArticleOption<RecurringArticleDropdownValue>;
}

export interface RecurringArticleAction {
  day?: RecurringArticleOption<ScheduleWeekDay>;
  week?: RecurringArticleOption<WeeklyScheduleExceptionsEnum>;
  time: string;
}

export type RecurringArticleValue =
  | RecurringArticleOption<ScheduleWeekDay>
  | RecurringArticleOption<WeeklyScheduleExceptionsEnum>
  | RecurringArticleOption<ScheduleMonth>;

export interface RecurringArticleOption<T> {
  label: string;
  value: T;
}

export const days: RecurringArticleOption<ScheduleWeekDay>[] = [
  { label: 'Mon', value: ScheduleWeekDay.Monday },
  { label: 'Tue', value: ScheduleWeekDay.Tuesday },
  { label: 'Wed', value: ScheduleWeekDay.Wednesday },
  { label: 'Thu', value: ScheduleWeekDay.Thursday },
  { label: 'Fri', value: ScheduleWeekDay.Friday },
  { label: 'Sat', value: ScheduleWeekDay.Saturday },
  { label: 'Sun', value: ScheduleWeekDay.Sunday },
];

export const months: RecurringArticleOption<ScheduleMonth>[] = [
  { label: 'Jan', value: ScheduleMonth.January },
  { label: 'Feb', value: ScheduleMonth.February },
  { label: 'Mar', value: ScheduleMonth.March },
  { label: 'Apr', value: ScheduleMonth.April },
  { label: 'May', value: ScheduleMonth.May },
  { label: 'Jun', value: ScheduleMonth.June },
  { label: 'Jul', value: ScheduleMonth.July },
  { label: 'Aug', value: ScheduleMonth.August },
  { label: 'Sep', value: ScheduleMonth.September },
  { label: 'Oct', value: ScheduleMonth.October },
  { label: 'Nov', value: ScheduleMonth.November },
  { label: 'Dec', value: ScheduleMonth.December },
];

export const weeks: RecurringArticleOption<WeeklyScheduleExceptionsEnum>[] = [
  { label: '1st', value: WeeklyScheduleExceptionsEnum.First },
  { label: '2nd', value: WeeklyScheduleExceptionsEnum.Second },
  { label: '3rd', value: WeeklyScheduleExceptionsEnum.Third },
  { label: '4th', value: WeeklyScheduleExceptionsEnum.Fourth },
  { label: 'last', value: WeeklyScheduleExceptionsEnum.Last },
];
