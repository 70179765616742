import { cx, css } from '@emotion/css';
import { PageLoader } from 'app/components';
import useImageSource from 'app/hooks/useImageSource';
import CoverImage from 'app/pages/Home/components/CoverImage';
import { Gallery } from 'iconsax-react';
import { ArticleVariantGet } from 'submodules/common-ui/generated/api/gcs';

interface BacklinkProps {
  backlink: ArticleVariantGet;
  onClick(): void;
}

const Backlink = ({ backlink, onClick }: BacklinkProps) => {
  const { hasError, hasLoaded, imageSource, setHasError, setHasLoaded } =
    useImageSource(backlink?.coverImage);

  return (
    <button className="w-[168px]" onClick={onClick}>
      <div className="flex flex-col w-full h-full">
        {!hasLoaded && !hasError && (
          <div className="h-full w-full flex justify-center items-center">
            <PageLoader />
          </div>
        )}
        <div
          className={cx('w-full h-full', {
            hidden: (!hasLoaded && !hasError) || hasError,
          })}
        >
          <CoverImage
            coverImage={backlink.coverImage}
            className="w-[168px] h-[100px]"
            onLoad={() => {
              setHasLoaded(true);
              setHasError(false);
            }}
            onError={() => {
              setHasLoaded(true);
              setHasError(true);
            }}
          />
        </div>
        {(hasError || (hasLoaded && !imageSource)) && (
          <div className="w-full flex items-center justify-center bg-hover-blue text-focus">
            <Gallery size={36} />
          </div>
        )}
        <div className="relative flex items-center mt-2">
          <span
            className={cx(
              'font-bold text-start line-clamp-2',
              css('word-break:break-word')
            )}
          >
            {backlink.title}
          </span>
        </div>
      </div>
    </button>
  );
};

export default Backlink;
