import { useQuery } from 'react-query';

import { resources, queryKeys } from '../index';

const useGetCommentAndParentsQuery = ({
  commentId,
  enabled,
}: {
  commentId: number;
  enabled: boolean;
}) => {
  const { data, error, isLoading, refetch } = useQuery(
    queryKeys.commentAndParents(commentId),
    () => resources.getCommentAndParents(commentId),
    { enabled }
  );

  return {
    data: data?.data?.data,
    error,
    isLoading,
    refetch,
  };
};

export default useGetCommentAndParentsQuery;
