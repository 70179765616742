import { useNode } from '@craftjs/core';
import { css, cx } from '@emotion/css';
import useGetActionAnswerSummary from 'app/api/articles/hooks/useGetActionAnswerSummary';
import useGetArticleBlock from 'app/api/articles/hooks/useGetArticleBlock';
import { useAuthenticatedUser } from 'app/api/auth/hooks';
import Spinner from 'app/components/Spinner';
import { useDispatch, useSelector } from 'app/hooks';
import useUnblockNextSection from 'app/hooks/useUnblockNextSection';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import useAnswerActionMutation from 'app/pages/ArticleEditor/hooks/useAnswerActionMutation';
import { actions, selectors } from 'app/store/editor';
import { logger } from 'app/utils';
import {
  ComponentProps,
  FC,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { Trans } from 'react-i18next';
import {
  DbMultiChoiceAnswer,
  PostActionAnswer,
  UserAnswerResponse,
  GetChoiceSchema,
  ActionAnswerResponse,
  ActionTypeEnum,
  MultiChoiceBlockRenderTypeEnum,
} from 'submodules/common-ui/generated/api/gcs';

import PollBlock from '../../../../Poll';
import { useCurrentSelectedInstance } from '../../context';
import ActionContainer from '../ActionContainer';
import EditorNavigation from '../EditorNavigation';

import ActionButton from './ActionButton';
import AddOwnAnswerButton from './AddOwnAnswerButton';
import AnswerActionScore, {
  EditorAnswerActionScore,
} from './AnswerActionScore';
import PollAnswerOption from './PollAnswerOption';
import useButtonLabel from './useButtonLabel';
import useDisplayAnswer from './useDisplayAnswer';

interface PollAnswerActionProps {
  actionId: string;
  selectedProps: ComponentProps<typeof PollBlock>;
  onAnswer: (data: UserAnswerResponse, actionId: string) => void;
  isEditor?: boolean;
  NavigationContainer: ({
    children,
  }: {
    children: React.ReactNode;
  }) => JSX.Element;
}

const PollAnswerAction: FC<PollAnswerActionProps> = ({
  selectedProps,
  isEditor,
  actionId,
  onAnswer,
  NavigationContainer,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const dispatch = useDispatch();

  const {
    block: contextBlock,
    currentSelectedInstanceId,
    refetch: contextRefetch,
    answeredByColleague,
    colleagueAnswer,
  } = useCurrentSelectedInstance();

  const { data: user } = useAuthenticatedUser();

  const { block, refetch } = useGetArticleBlock({
    articleId: selectedProps.articleId!,
    blockId: parseInt(actionId),
    enabled: !!selectedProps.articleId && !contextBlock,
    timezone: user?.timezone,
    live: '1',
  });

  const activeBlock = contextBlock || block;

  const activeRefetch = useMemo(
    () => (contextBlock ? contextRefetch : refetch),
    [contextBlock, refetch, contextRefetch]
  );

  const currentInstanceId =
    currentSelectedInstanceId ?? activeBlock?.currentInstanceId;

  const currentUserAnswersList = activeBlock?.currentUserAnswers || [];
  const currentInstanceAnswer = currentUserAnswersList.find(
    (currentUserAnswer: ActionAnswerResponse) =>
      currentUserAnswer.actionInstanceId === currentInstanceId
  );

  const isActionCompleted =
    !!currentInstanceAnswer && currentInstanceAnswer.revertedAt === null;

  const [selectedOptionsIds, setSelectedOptionsIds] = useState<number[]>([]);
  const [isAddingOwnAnswer, setIsAddingOwnAnswer] = useState(false);

  const displayAnswer = useDisplayAnswer({
    isAddingOwnAnswer,
    isActionCompleted,
    answeredByColleague,
    currentInstanceAnswer,
    colleagueAnswer,
  });

  const {
    mutate: answerAction,
    isLoading,
    isSuccess,
    reset,
  } = useAnswerActionMutation();

  const {
    summary,
    refetch: summaryRefetch,
    isLoading: isSummaryLoading,
  } = useGetActionAnswerSummary({
    actionId: parseInt(actionId),
    actionInstanceId: currentInstanceId,
  });

  const { t } = useArticlesTranslation();

  const currentLanguage = useSelector(selectors.getActiveLanguage);

  const title = activeBlock?.variants?.[currentLanguage]?.title;
  const description = activeBlock?.variants?.[currentLanguage]?.description;

  const choices = activeBlock?.choices ?? [];
  const maxSelectedChoices = activeBlock?.maxSelectedChoices ?? 1;

  const maxChoices =
    maxSelectedChoices > 0 ? maxSelectedChoices : choices.length;

  const isSubmitButtonDisabled = isLoading || selectedOptionsIds.length === 0;
  const isTooEarly = currentInstanceId === null;

  const handleOnOptionPress = (id: number) => {
    if (isActionCompleted || (answeredByColleague && !isAddingOwnAnswer)) {
      return;
    }

    if (selectedOptionsIds.includes(id)) {
      const updatedSelection = selectedOptionsIds.filter(
        (optionId) => optionId !== id
      );
      return setSelectedOptionsIds(updatedSelection);
    }

    if (selectedOptionsIds.length < maxChoices) {
      const updatedSelection = [...selectedOptionsIds, id];
      return setSelectedOptionsIds(updatedSelection);
    }

    const updatedSelection = [...selectedOptionsIds.slice(1), id];
    return setSelectedOptionsIds(updatedSelection);
  };

  const completeAction = useCallback(() => {
    const payload: PostActionAnswer = {
      actionId: parseInt(actionId),
      language: currentLanguage,
      answer: selectedOptionsIds,
      timezone: user?.timezone,
      actionInstanceId: currentInstanceId,
    };
    answerAction(payload, {
      onError: (e) => logger.error(e),
      onSuccess: (data) => {
        onAnswer(data, actionId);
        dispatch(
          actions.setStats({
            mandatoryProgress: data.mandatoryProgress,
            mandatoryScore: data.totalMandatoryScore,
            progress: data.progress,
            score: data.score,
          })
        );

        reset();
        activeRefetch();
        if (activeBlock?.public) {
          summaryRefetch();
        }
        setIsAddingOwnAnswer(false);
      },
    });
  }, [
    actionId,
    currentLanguage,
    selectedOptionsIds,
    user?.timezone,
    currentInstanceId,
    answerAction,
    onAnswer,
    dispatch,
    reset,
    activeRefetch,
    summaryRefetch,
    activeBlock?.public,
  ]);

  const renderAnswerActionScore = () => {
    if (isEditor) return <EditorAnswerActionScore totalScore={0} />;
    return (
      <AnswerActionScore
        selectedProps={selectedProps}
        showQuizScore={false}
        showProgressScore={false}
        totalScore={0}
        blockId={parseInt(actionId)}
      />
    );
  };

  const getChoicePercentage = (choiceId: number) => {
    if (!summary || !summary.choices) return 0;
    const choice = summary.choices.find((c) => c.choiceId === choiceId);

    return choice ? choice.choicePercentage : 0;
  };

  useEffect(() => {
    if (displayAnswer) {
      setSelectedOptionsIds(
        (displayAnswer.answer as DbMultiChoiceAnswer).map((a) => a.id)
      );
      return;
    }

    setSelectedOptionsIds([]);
  }, [displayAnswer, actionId]);

  const buttonLabel = useButtonLabel(
    isLoading,
    isSuccess,
    isTooEarly,
    activeBlock?.type
  );

  const renderFooterContent = () => {
    if (isAddingOwnAnswer) {
      return (
        <ActionButton
          onClick={completeAction}
          disabled={isSubmitButtonDisabled || isTooEarly}
          isTooEarly={isTooEarly}
          answer={
            selectedOptionsIds.length > 0 ? selectedOptionsIds.join(', ') : ''
          }
          buttonLabel={buttonLabel}
        />
      );
    }

    if (answeredByColleague && !isActionCompleted) {
      return (
        <AddOwnAnswerButton
          answeredByColleague={answeredByColleague}
          isActionCompleted={isActionCompleted}
          onAddAnswer={() => setIsAddingOwnAnswer(true)}
        />
      );
    }

    return (
      !isActionCompleted && (
        <ActionButton
          onClick={completeAction}
          disabled={isSubmitButtonDisabled || isTooEarly}
          isTooEarly={isTooEarly}
          answer={
            selectedOptionsIds.length > 0 ? selectedOptionsIds.join(', ') : ''
          }
          buttonLabel={buttonLabel}
        />
      )
    );
  };

  return (
    <>
      {renderAnswerActionScore()}
      <ActionContainer
        isMandatory={selectedProps.mandatory}
        canAnswer={true}
        type={ActionTypeEnum.MultiChoice}
        renderType={MultiChoiceBlockRenderTypeEnum.Poll}
      >
        <div className="flex flex-col gap-2">
          <span
            className={cx('text-lg font-bold', css('word-break: break-word;'))}
          >
            {title}
          </span>
          <span className={cx('text-black', css('word-break: break-word;'))}>
            {description}
          </span>

          <div className="text-ceil text-sm">
            <Trans
              components={{ bold: <span className="font-bold" /> }}
              i18nKey="articles.Pick up to {{count}} answers"
              values={{
                count: maxChoices,
              }}
            />
          </div>
        </div>

        {isActionCompleted || answeredByColleague ? (
          activeBlock?.public ? (
            isSummaryLoading ? (
              <div className="flex justify-center items-center mt-4">
                <Spinner className="w-6 h-6 animate-spin text-focus" />
              </div>
            ) : (
              <div className="flex flex-col gap-2 mt-4">
                {choices.map((choice: GetChoiceSchema) => {
                  const choiceVariants = choice.variants;
                  const variant = choiceVariants?.[currentLanguage]
                    ? choiceVariants[currentLanguage]
                    : choiceVariants[Object.keys(choiceVariants)[0]];

                  return (
                    <PollAnswerOption
                      imageUri={variant?.image}
                      canAnswer={!isActionCompleted}
                      isSelected={selectedOptionsIds.includes(choice.id)}
                      onClick={() => handleOnOptionPress(choice.id)}
                      text={variant?.answer}
                      key={choice.id}
                      choicePercentage={getChoicePercentage(choice.id)}
                      completedByColleague={answeredByColleague}
                      isAddingOwnAnswer={isAddingOwnAnswer}
                    />
                  );
                })}
                <div className="flex flex-col p-3 justify-center items-start gap-1 self-stretch rounded-xl bg-hover-blue mt-5">
                  <span className="flex items-center gap-1">
                    <span>🚀</span>
                    <span className="text-sm text-grayscale-primary font-bold">
                      {t('Thanks for answering!')}
                    </span>
                  </span>
                  <span className="text-xs text-grayscale-primary">
                    {t('Keep up the good work!')}
                  </span>
                </div>
              </div>
            )
          ) : (
            <div className="flex flex-col gap-2 mt-4">
              {choices.map((choice: GetChoiceSchema) => {
                const choiceVariants = choice.variants;
                const variant = choiceVariants?.[currentLanguage]
                  ? choiceVariants[currentLanguage]
                  : choiceVariants[Object.keys(choiceVariants)[0]];

                return (
                  <PollAnswerOption
                    imageUri={variant?.image}
                    canAnswer={false}
                    isSelected={selectedOptionsIds.includes(choice.id)}
                    onClick={() => handleOnOptionPress(choice.id)}
                    text={variant?.answer}
                    key={choice.id}
                    choicePercentage={undefined}
                  />
                );
              })}
              <div className="flex flex-col p-3 justify-center items-start gap-1 self-stretch rounded-xl bg-hover-blue mt-5">
                <span className="flex items-center gap-1">
                  <span className="">🚀</span>
                  <span className="text-sm text-grayscale-primary font-bold">
                    {t('Thanks for answering!')}
                  </span>
                </span>
                <span className="text-xs text-grayscale-primary">
                  {t('Keep up the good work!')}
                </span>
              </div>
            </div>
          )
        ) : (
          <div className="flex flex-col gap-2 mt-4">
            {choices.map((choice: GetChoiceSchema) => {
              const choiceVariants = choice.variants;
              const variant = choiceVariants?.[currentLanguage]
                ? choiceVariants[currentLanguage]
                : choiceVariants[Object.keys(choiceVariants)[0]];

              return (
                <PollAnswerOption
                  imageUri={variant?.image}
                  canAnswer={!isActionCompleted}
                  isSelected={selectedOptionsIds.includes(choice.id)}
                  onClick={() => handleOnOptionPress(choice.id)}
                  text={variant?.answer}
                  key={choice.id}
                  choicePercentage={0}
                />
              );
            })}
          </div>
        )}
        <div className="flex justify-between items-center w-full mt-6">
          <NavigationContainer>{renderFooterContent()}</NavigationContainer>
        </div>
      </ActionContainer>
    </>
  );
};

export const EditorPollAnswerAction = () => {
  const {
    selectedProps,
    nodeId,
    actions: { setProp },
  } = useNode((node) => ({
    selectedProps: node.data.props as ComponentProps<typeof PollBlock>,
    nodeId: node.id,
  }));
  const { setIsUnblocked } = useUnblockNextSection(nodeId);

  const { data: user } = useAuthenticatedUser();

  if (!user) return null;

  return (
    <PollAnswerAction
      selectedProps={selectedProps}
      actionId={nodeId}
      onAnswer={(data) => {
        setProp((props: ComponentProps<typeof PollBlock>) => {
          props.currentUserAnswers = [{ ...data, completedBy: user.id }];
          props.completed = true;
        });

        setIsUnblocked(true);
      }}
      isEditor={true}
      NavigationContainer={EditorNavigation}
    />
  );
};

export default PollAnswerAction;
