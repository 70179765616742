import { request } from 'app/api/gcsRequest';
import config from 'app/config';
import { CategoryApi } from 'submodules/common-ui/generated/api/gcs';

import { GetCategoriesRequest } from '../types';

const getCategories = ({
  page,
  per_page,
  name,
  status,
  createdAtSort,
}: GetCategoriesRequest) =>
  new CategoryApi(undefined, config.env.gcsApiUrl, request()).getCategoryPage(
    page,
    per_page,
    name,
    status,
    undefined,
    createdAtSort
  );

export default getCategories;
