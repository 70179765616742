import { ProfileKeys } from '../types';

import useBaseTranslation from './useBaseTranslation';

const useProfileTranslation = () =>
  useBaseTranslation<ProfileKeys>(undefined, {
    keyPrefix: 'profile',
  });

export default useProfileTranslation;
