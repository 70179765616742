import { useModulesTranslation } from 'app/internationalization/hooks';
import { BasicRelevantChannelInfo } from 'submodules/common-ui/generated/api/gcs';

import ChannelRow from '../ChannelRow';

const ChannelResults = ({
  channels,
}: {
  channels: BasicRelevantChannelInfo[];
}) => {
  const { t } = useModulesTranslation();
  return (
    <>
      <span className="text-xs text-gray-silver font-bold mt-3">
        {t('Channels').toUpperCase()}
      </span>
      {channels.map((channel) => {
        return <ChannelRow channel={channel} key={channel.id} />;
      })}
    </>
  );
};

export default ChannelResults;
