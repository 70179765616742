import { Modal } from 'app/components';
import { useScreenBreakpoint } from 'app/hooks';
import { useComponentsTranslation } from 'app/internationalization/hooks';
import { isMobile } from 'app/utils';
import CrossIcon from 'assets/icons/cross.svg?react';

import SubmitAndCancelButton from '../SubmitAndCancelButton';

interface BlockModalProps {
  onClose: () => void;
  onBlock: () => void;
  creatorName: string;
}

const BlockModal = ({ onClose, onBlock, creatorName }: BlockModalProps) => {
  const { t } = useComponentsTranslation();
  const breakpoint = useScreenBreakpoint();
  const _isMobile = isMobile(breakpoint);

  const onSubmit = () => {
    onBlock();
    onClose();
  };

  return (
    <Modal onClose={onClose} className="p-6" width={_isMobile ? 'sm' : 'md'}>
      <div className="flex items-center mb-4 justify-between">
        <p className="text-grayscale-primary text-lg font-bold">
          {t('Block {{creatorName}}?', {
            user: creatorName,
          })}
        </p>
        <button
          className="bg-light text-grayscale-secondary shadow-atobi flex items-center justify-center w-10 h-10 rounded-lg"
          onClick={onClose}
        >
          <CrossIcon />
        </button>
      </div>
      <p className="text-grayscale-primary mt-2 mb-7 max-w-[328px]">
        {t('You won’t see posts or comments from {{creatorName}}', {
          creatorName,
        })}
      </p>
      <SubmitAndCancelButton
        cancelLabel={t('Cancel')}
        submitLabel={t('Block')}
        onCancel={onClose}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

export default BlockModal;
