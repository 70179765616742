import CollaborativeIcon from 'assets/icons/reaction-collaborative.svg?react';
import CreativeIcon from 'assets/icons/reaction-creative.svg?react';
import DedicatedIcon from 'assets/icons/reaction-dedicated.svg?react';
import EffectiveIcon from 'assets/icons/reaction-effective.svg?react';
import FunnyIcon from 'assets/icons/reaction-funny.svg?react';
import HelpfulIcon from 'assets/icons/reaction-helpful.svg?react';
import InformativeIcon from 'assets/icons/reaction-informative.svg?react';
import InsightfulIcon from 'assets/icons/reaction-insightful.svg?react';
import InspiringIcon from 'assets/icons/reaction-inspiring.svg?react';
import MotivatingIcon from 'assets/icons/reaction-motivating.svg?react';
import OrganizedIcon from 'assets/icons/reaction-organized.svg?react';
import ProactiveIcon from 'assets/icons/reaction-proactive.svg?react';
import ProductiveIcon from 'assets/icons/reaction-productive.svg?react';
import ReliableIcon from 'assets/icons/reaction-reliable.svg?react';
import { EmojiEnum } from 'submodules/common-ui/generated/api/gcs/api';

interface EmojiProps {
  emoji: string;
}

const ReactionEmoji = ({ emoji }: EmojiProps) => {
  switch (emoji) {
    case EmojiEnum.Collaborative:
      return <CollaborativeIcon />;
    case EmojiEnum.Creative:
      return <CreativeIcon />;
    case EmojiEnum.Dedicated:
      return <DedicatedIcon />;
    case EmojiEnum.Effective:
      return <EffectiveIcon />;
    case EmojiEnum.Funny:
      return <FunnyIcon />;
    case EmojiEnum.Helpful:
      return <HelpfulIcon />;
    case EmojiEnum.Informative:
      return <InformativeIcon />;
    case EmojiEnum.Insightful:
      return <InsightfulIcon />;
    case EmojiEnum.Inspiring:
      return <InspiringIcon />;
    case EmojiEnum.Motivating:
      return <MotivatingIcon />;
    case EmojiEnum.Organized:
      return <OrganizedIcon />;
    case EmojiEnum.Proactive:
      return <ProactiveIcon />;
    case EmojiEnum.Productive:
      return <ProductiveIcon />;
    case EmojiEnum.Reliable:
      return <ReliableIcon />;
    default:
      return <></>;
  }
};

export default ReactionEmoji;
