import { useAuthenticatedUser } from 'app/api/auth/hooks';
import { Button, Modal } from 'app/components';
import AtobiSupport from 'app/components/AtobiSupport';
import Badge, { BadgeColor } from 'app/components/Badge';
import config from 'app/config';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { selectors } from 'app/store/editor';
import { actions } from 'app/store/modal';
import { ModalTypes } from 'app/store/modal/types';
import CrossIcon from 'assets/icons/cross.svg?react';
import Note from 'assets/icons/note.svg?react';
import { Calendar } from 'iconsax-react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

interface SuccessModalProps {
  onClose?: VoidFunction;
}

const SuccessModal = ({ onClose }: SuccessModalProps) => {
  const dispatch = useDispatch();

  const { t } = useArticlesTranslation();
  const { data: user } = useAuthenticatedUser();

  const article = useSelector(selectors.getArticle);

  const articleOwner = article?.createdBy ?? user?.id;

  const close = () => {
    if (!onClose) return dispatch(actions.hideModal());
    onClose();
  };

  const onTrack = () => {
    if (!article || !articleOwner) return;
    dispatch(
      actions.showModal({
        modalType: ModalTypes.FORWARD_MODAL,
        modalProps: { articleOwner, articleId: article.id },
      })
    );
  };

  return (
    <Modal onClose={close} className="w-[380px]" containerPaddingClass="p-0">
      <div className="flex relative items-center justify-center flex-col px-6 pt-6">
        <div className="flex flex-col items-center">
          <span className="text-[40px]">&#10024;</span>
          <span className="font-bold text-lg mt-2">
            {t('The article is forwarded!')}
          </span>
          <span className="mt-2 font-bold">{t('Now the external team')}</span>

          <div className="flex mt-2">
            <Note />
            <span className="text-sm text-black ml-2">
              {t('Receives a copy of the article')}
            </span>
          </div>
          <div className="flex mt-2">
            <Calendar color={config.colors['grayscale-secondary']} size={20} />
            <span className="text-sm text-black ml-2">
              {t('Can schedule and publish the article')}
            </span>
          </div>
        </div>
        <div className="mt-5 w-[176px] h-12">
          <Button onClick={onTrack} variant="primary">
            {t('Track article status')}
          </Button>
        </div>
        <button
          className="h-10 w-10 text-grayscale-secondary absolute right-0 top-2"
          onClick={close}
        >
          <CrossIcon />
        </button>
      </div>
      <div className="text-xs px-5 py-3 flex w-full justify-between items-center mt-4 bg-grayscale-bg-dark text-grayscale-secondary rounded-b-lg">
        <Badge title={t('NEW')} type={BadgeColor.Black} />
        <span className="ml-2">
          <Trans
            components={{ bold: <span className="font-bold" /> }}
            i18nKey="articles.Do you want to save more time by connecting to even more external teams?"
          />{' '}
          <AtobiSupport />
        </span>
      </div>
    </Modal>
  );
};

export { SuccessModal };
export default SuccessModal;
