import { cx } from '@emotion/css';
import { Location } from 'app/api/auth/types';
import { ScrollbarContainer } from 'app/components';
import Input2 from 'app/components/Input2';
import {
  LocationTreeContextProvider,
  useLocationTreeContext,
} from 'app/components/LocationTree/context';
import LocationTree from 'app/components/LocationTree/LocationTree';
import {
  useComponentsTranslation,
  useMembersTranslation,
} from 'app/internationalization/hooks';
import { ArrowRotateRight } from 'iconsax-react';

import { useAnswersContext } from '../context';

const LocationsList = () => {
  const { t } = useComponentsTranslation();
  const { t: mt } = useMembersTranslation();

  const { onSearch, toggleSelection, selectedLocations } =
    useLocationTreeContext();

  const { setSelectedLocations } = useAnswersContext();

  const totalSelected = selectedLocations.length;

  return (
    <>
      <div className="flex mt-4 mb-3 items-center text-sm">
        <span className="font-bold">{mt('Locations')}</span>

        <div
          className={cx(
            'ml-2 px-1 py-0.5 rounded-full text-focus bg-hover-blue',
            { invisible: totalSelected === 0 }
          )}
        >
          {totalSelected}
        </div>
        {totalSelected > 0 && (
          <button
            onClick={() => toggleSelection(false)}
            className="flex ml-auto text-grayscale-secondary items-center"
          >
            <ArrowRotateRight size={14} />
            <span className="text-sm ml-0.5">{t('Clear')}</span>
          </button>
        )}
      </div>

      <Input2
        onChange={(e) => onSearch(e.target.value)}
        onClear={() => onSearch('')}
        placeholder={t('Search...')}
        isSearch={true}
        className="h-10 rounded-xl"
        searchIconSize={16}
      />
      <ScrollbarContainer className="max-h-[168px] mt-3">
        <LocationTree onLocationsChanged={setSelectedLocations} />
      </ScrollbarContainer>
    </>
  );
};

const Locations = ({ locations }: { locations?: Location[] }) => {
  return (
    <LocationTreeContextProvider locations={locations}>
      <LocationsList />
    </LocationTreeContextProvider>
  );
};

export default Locations;
