import {
  EnumTextFormat,
  SerializedLexicalState,
  SerializedLexicalText,
} from 'app/components/LexicalEditor/types';
import {
  TextFormat,
  Paragraph,
  Text,
} from 'submodules/common-ui/generated/api/gcs';

const getFormat = (format: TextFormat) => {
  if (!format) return '';

  let formatNumber = 0;
  if (format?.bold) formatNumber += EnumTextFormat['bold'];
  if (format?.italic) formatNumber += EnumTextFormat['italic'];
  if (format?.underline) formatNumber += EnumTextFormat['underline'];

  return formatNumber;
};

const getChildren = (children: Text[]) => {
  const blocks: SerializedLexicalText[] = children.map((block) => {
    const formatChildren = {} as Partial<SerializedLexicalText>;

    formatChildren.text = String(block.value);
    formatChildren.format = getFormat(block.format);

    const lexicalNode: SerializedLexicalText = {
      ...formatChildren,
      detail: 0,
      format: formatChildren.format ?? EnumTextFormat.normal,
      mode: 'normal',
      type: 'text',
      version: 1,
      direction: 'ltr',
      indent: 0,
    };
    return lexicalNode;
  });

  return blocks;
};

const apiToLexical = (node?: Paragraph): SerializedLexicalState => {
  const lexicalBlock = {} as Partial<SerializedLexicalText>;

  const paragraph = {
    children: getChildren(node?.children as Text[]) ?? [],
    direction: 'ltr',
    format: '',
    indent: 0,
    type: 'paragraph',
    version: 1,
    start: 1,
    ...lexicalBlock,
  };

  return {
    root: {
      children: [paragraph],
      direction: 'ltr',
      format: '',
      indent: 0,
      type: 'root',
      version: 1,
    },
  };
};

export default apiToLexical;
