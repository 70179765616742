import { request } from 'app/api/gcsRequest';
import config from 'app/config';
import {
  CommentApi,
  ReactionObjectTypeEnum,
  SortOrderEnum,
} from 'submodules/common-ui/generated/api/gcs';

const perPage = 10;

const getComments = ({
  objectType,
  objectId,
  page,
  createdAt = 'desc',
}: {
  objectType: ReactionObjectTypeEnum;
  objectId: number;
  page: number;
  createdAt?: SortOrderEnum;
}) =>
  new CommentApi(undefined, config.env.gcsApiUrl, request())
    .getComments(objectType, objectId, page, perPage, createdAt)
    .then((response) => response.data);

export default getComments;
