import { useSelector } from 'app/hooks';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { selectors } from 'app/store/editor';
import { FlashCircle, TickCircle } from 'iconsax-react';
import React from 'react';
import ReactTooltip from 'react-tooltip';

import { useLanguageSettingsContext } from '../context';

type TranslationStatusProp = {
  languageCode: string;
  onSelect: (status: 'approved' | 'draft', languageCode: string) => void;
};

const TranslationStatus: React.FC<TranslationStatusProp> = ({
  languageCode,
  onSelect,
}) => {
  const translationStatuses = useSelector(selectors.getTranslationStatus);
  const { t } = useArticlesTranslation();
  const { isTranslatingAddedLanguage, translatingLanguageCode, isTranslating } =
    useLanguageSettingsContext();

  const statuses = Object.values(translationStatuses)
    .filter((status) => status[languageCode])
    .map((status) => status[languageCode]);

  const totalCount = statuses.length;
  const approvedCount = statuses.filter(
    (status) => status === 'approved'
  ).length;

  const isReadyForPublish = approvedCount === totalCount;
  const isTranslatingSelectedLanguage =
    translatingLanguageCode === languageCode && isTranslating;

  const onPublish = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    onSelect('approved', languageCode);
  };

  if (isTranslatingAddedLanguage(languageCode) || isTranslatingSelectedLanguage)
    return (
      <div className="flex items-center">
        <FlashCircle size={12} variant="Bold" className="text-focus mr-0.5" />
        <span className="text-grayscale-secondary text-xs">Translating...</span>
      </div>
    );

  return (
    <div className="flex flex-row items-center">
      {statuses.includes('auto') && (
        <div className="flex items-center mr-1">
          <FlashCircle size={12} variant="Bold" className="text-ceil mr-0.5" />
          <span className="text-grayscale-secondary text-xs">
            {t('Auto-translated')}
          </span>
        </div>
      )}
      {!isReadyForPublish && (
        <span className="text-orange text-xs">
          {approvedCount}/{totalCount} {t('blocks proofread')}
        </span>
      )}
      {isReadyForPublish ? (
        <span className="text-success text-xs">
          {t('Ready for publishing')}
        </span>
      ) : (
        <>
          <button
            data-for="publish"
            data-tip={t('Mark as ready for publishing')}
            onClick={onPublish}
            className="text-grayscale-secondary hover:text-success p-1"
          >
            <TickCircle size={16} />
          </button>
          <ReactTooltip
            place="top"
            effect="solid"
            class="react-tooltip"
            id="publish"
          />
        </>
      )}
    </div>
  );
};

export default TranslationStatus;
