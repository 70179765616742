import { request } from 'app/api/gcsRequest';
import config from 'app/config';
import {
  ReactionApi,
  NewReaction,
} from 'submodules/common-ui/generated/api/gcs';

const createComment = (newReaction: NewReaction) =>
  new ReactionApi(undefined, config.env.gcsApiUrl, request()).createReaction(
    newReaction
  );

export default createComment;
