const lexicalEditorTheme = {
  paragraph: 'text-sm',
  text: {
    bold: 'font-bold',
    italic: 'italic',
    underline: 'underline',
  },
};

export default lexicalEditorTheme;
