import { cx } from '@emotion/css';
import { FC } from 'react';
import {
  DbChoiceAnswer,
  MultiChoiceBlockV1,
  Text,
} from 'submodules/common-ui/generated/api/gcs';

interface Props {
  userChoice: DbChoiceAnswer;
  selectedTaskBlock: MultiChoiceBlockV1;
  language: string;
}

const ChoiceItem: FC<Props> = ({ userChoice, selectedTaskBlock, language }) => {
  const { id: choiceId, correct } = userChoice;

  const foundChoice = selectedTaskBlock?.choices.find(
    (choice) => choice.id === choiceId
  );

  const answerText = (
    foundChoice?.variants[language]?.answer?.children?.[0] as Text
  )?.value;

  const imageUrl =
    foundChoice?.variants[language]?.image?.directUrls?.small?.url;

  return (
    <div
      className={cx(
        'flex rounded-lg border-[1px] items-center',
        {
          'bg-grayscale-bg-dark border-focus-background':
            selectedTaskBlock?.renderType === 'poll',
          'bg-success-background border-success': correct,
          'bg-error-light border-error':
            !correct && selectedTaskBlock?.renderType !== 'poll',
          'flex-row gap-3': !!imageUrl,
          'px-3 py-2.5': !imageUrl,
        },
        'w-full text-sm'
      )}
    >
      {imageUrl && (
        <img
          src={imageUrl}
          alt="choice"
          className="w-[66px] h-[40px] object-cover rounded-s-lg"
        />
      )}
      {answerText && <span>{answerText}</span>}
    </div>
  );
};

export default ChoiceItem;
