import { request } from 'app/api/gcsRequest';
import config from 'app/config';
import {
  CategoryApi,
  UpdatedCategory,
} from 'submodules/common-ui/generated/api/gcs';

const updateCategory = (updatedCategory: UpdatedCategory) =>
  new CategoryApi(undefined, config.env.gcsApiUrl, request()).updateCategory(
    updatedCategory
  );

export default updateCategory;
