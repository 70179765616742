import { cx } from '@emotion/css';
import useGetAudiencesListQuery from 'app/api/audiences/hooks/useGetAudiencesListQuery';
import { Language, Location } from 'app/api/auth/types';
import useGetConfigurationsQuery from 'app/api/pwaSettings/hooks/useGetConfigurationsQuery';
import useCreationInfoQuery from 'app/api/user/hooks/useCreationInfoQuery';
import { Button, Modal, PageLoader } from 'app/components';
import config from 'app/config';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { outsideRoutes, routes } from 'app/router';
import { selectors } from 'app/store/editor';
import { COUNTRY_TO_LANGUAGE_MAP } from 'app/utils/remapApiLanguagesToGcs';
import { resolveSubdomain } from 'app/utils/subdomain';
import CrossIcon from 'assets/icons/cross.svg?react';
import { saveAs } from 'file-saver';
import { Danger, Teacher } from 'iconsax-react';
import JSZip from 'jszip';
import compact from 'lodash/compact';
import flatten from 'lodash/flatten';
import snakeCase from 'lodash/snakeCase';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import { useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import htmlIndex from './htmlIndex';
import imsmanifest from './imsmanifest';
import LanguageDropdown from './LanguageDropdown';
import LocationDropdown from './LocationDropdown';
import scormApi from './scormApi';

interface Props {
  onClose: () => void;
  onFinish: () => void;
}

const GenerateScormPackageModal = ({ onClose, onFinish }: Props) => {
  const { t } = useArticlesTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState<
    Language | undefined
  >(undefined);
  const [selectedLocation, setSelectedLocation] = useState<
    Location | undefined
  >();

  const article = useSelector(selectors.getArticle);
  const languages = useSelector(selectors.getSelectedLanguages);

  const hasRecurringTask = article?.blocks.some(
    (block) => 'schedule' in block && block.schedule.frequency !== 'once'
  );

  const { data, isLoading: isLanguagesLoading } = useCreationInfoQuery();

  const { data: configurations } = useGetConfigurationsQuery({
    enabled: true,
  });

  const subdomain = resolveSubdomain();
  const configuration = configurations?.find((c) => (c.subdomain = subdomain));

  const tenantLanguages = data?.languages;

  // Filter out languages that are not available for the tenant
  const availableLanguages = tenantLanguages?.filter(({ code }) => {
    const languageCode = COUNTRY_TO_LANGUAGE_MAP.get(code) ?? code;
    return languages?.some((l) => l.code === languageCode);
  });

  const { data: audiences, isLoading: isAudiencesLoading } =
    useGetAudiencesListQuery({
      ids: article?.audiences ?? [],
      enabled: article && article?.audiences.length > 0,
    });

  const locations = useMemo(() => {
    if (audiences.length > 0 && !isAudiencesLoading) {
      const flattenedLocations = flatten(
        compact(audiences?.map((audience) => audience?.locations))
      ).filter((location) => location.type === 's');
      return sortBy(uniqBy(flattenedLocations, 'id'), 'name');
    }
  }, [audiences, isAudiencesLoading]);

  const articleMainLanguage =
    article?.languages.filter((l) => l.isDefault)[0].language || 'en';

  let fileName = snakeCase(
    article?.variants[articleMainLanguage].title.substring(0, 20)
  );

  if (selectedLanguage) {
    fileName += `_${selectedLanguage.code}`;
  }

  if (selectedLocation) {
    fileName += `_${snakeCase(selectedLocation.name)}`;
  }

  const generateScormPackage = () => {
    const zip = new JSZip();

    const hostname = `${window.location.protocol}//${window.location.hostname}`;

    // Add the XML content to the zip file
    zip.file('imsmanifest.xml', imsmanifest);
    zip.file('index.html', htmlIndex(hostname));
    zip.file(
      'scorm_api.js',
      scormApi(
        hostname,
        article?.id ?? 0,
        selectedLanguage?.id,
        selectedLocation?.id
      )
    );

    // Generate the zip file and trigger the download
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, `${fileName}.zip`);
      onFinish();
    });
  };

  const isGenerateScormPackageDisabled = !selectedLocation || hasRecurringTask;

  return (
    <Modal className="w-[585px]" onClose={onClose} containerPaddingClass="">
      <div className="flex flex-col relative ">
        <button
          className="text-grayscale-secondary absolute right-4 top-4"
          onClick={onClose}
        >
          <CrossIcon />
        </button>
        <span className="flex font-bold text-sm mb-0.5 p-4">
          <Teacher size={16} className="mr-2" />
          <span>{t('Generate SCORM package')}</span>
        </span>
        {locations?.length === 0 && (
          <div className="px-4 mb-4">
            <div className="bg-error-light border border-error rounded p-3 flex text-error text-10 ">
              <Danger size={12} color={config.colors.error} className="mr-1" />
              {t(
                'The audiences chosen for this articles, do not include any locations of type "store"'
              )}
            </div>
          </div>
        )}
        {hasRecurringTask && (
          <div className="px-4 mb-4">
            <div className="bg-error-light border border-error rounded p-3 flex text-error text-10 ">
              <Danger size={12} color={config.colors.error} className="mr-1" />
              {t(
                'SCORM does not support recurring actions. Please remove the recurring schedules from the actions.'
              )}
            </div>
          </div>
        )}
        <div className="px-4">
          <div className="mb-5 text-xs text-grayscale-primary">
            {t(
              'Generate a SCORM package based on your article and play it on other LMS platforms. You will still receive action answers and can keep track of reach and more.'
            )}
          </div>
          {configuration && (
            <div className="mb-5 text-xs text-grayscale-secondary">
              {configuration.allowedIframeUrls &&
              configuration.allowedIframeUrls.length > 0
                ? t(
                    'You will be able to use the SCORM package under the following domains'
                  )
                : t(
                    'You currently do not have any allowed domains to use the SCORM package.'
                  )}
              : <br />
              <span className="font-bold">
                {configuration.allowedIframeUrls?.join(', ')}
              </span>
              <br />
              <Trans
                components={{
                  a: (
                    <a
                      target="_blank"
                      className="underline"
                      href={routes.settingsList.create()}
                      rel="noreferrer"
                    >
                      {t('Platform Settings')}
                    </a>
                  ),
                }}
                i18nKey="articles.To update the allowed domains list, visit Platform Settings"
              />
            </div>
          )}

          {isAudiencesLoading || isLanguagesLoading ? (
            <PageLoader />
          ) : (
            <>
              {availableLanguages && (
                <div className="mb-5">
                  <div className="text-sm font-bold mb-2">{t('Language')}</div>
                  <LanguageDropdown
                    languages={availableLanguages}
                    onSelectedLanguage={(language) =>
                      setSelectedLanguage(language)
                    }
                  />
                  <span className="text-grayscale-secondary text-sm mt-2 inline-block">
                    {t(
                      'Pick the language in which the SCORM package should be played.'
                    )}
                  </span>
                </div>
              )}
              {locations && (
                <div className="mb-5">
                  <div className="text-sm font-bold mb-2">
                    {t('Location')}
                    <span className="text-error">*</span>
                  </div>
                  <LocationDropdown
                    locations={locations}
                    onSelectedLocation={setSelectedLocation}
                  />
                  <span className="text-grayscale-secondary text-sm mt-2 inline-block">
                    {t(
                      'External users, data, and action answers will be attached to this location.'
                    )}
                  </span>
                </div>
              )}
              <div className="ml-auto w-[176px] my-4">
                <Button
                  variant="primary"
                  onClick={generateScormPackage}
                  className={cx('h-[48px]', {
                    'bg-gray-light !text-grayscale-secondary':
                      isGenerateScormPackageDisabled,
                  })}
                  disabled={isGenerateScormPackageDisabled}
                >
                  {t('Generate')}
                </Button>
              </div>
            </>
          )}
        </div>
        <div className="text-grayscale-secondary text-xs bg-gray-white-shadow px-4 py-2">
          <Trans
            components={{
              a: (
                <a
                  target="_blank"
                  className="text-focus underline"
                  href={outsideRoutes.atobiHelpMail.create()}
                  rel="noreferrer"
                >
                  {t('Atobi Support')}
                </a>
              ),
            }}
            i18nKey="articles.Having issues generating the package? Contact Atobi Support"
          />
        </div>
      </div>
    </Modal>
  );
};

export default GenerateScormPackageModal;
