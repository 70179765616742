import { Node } from '@craftjs/core/lib/interfaces/nodes';
import { PollOptionProps } from 'app/pages/ArticleEditor/components/blocks/tasks/Poll/Option/PollOption';
import { QuizOptionProps } from 'app/pages/ArticleEditor/components/blocks/tasks/Quiz/Option/QuizOption';
import { YesNoNaOptionProps } from 'app/pages/ArticleEditor/components/blocks/tasks/YesNoNa/Option/YesNoNaOption';
import { selectors } from 'app/store/editor';
import {
  ArticleVariant,
  CoverImageFile,
  GetCategory,
  MultiChoiceBlockRenderTypeEnum,
  NewArticle,
  NewArticleLinkBlock,
  NewBlock,
  NewBlockList,
  NewChoiceSchema,
  NewFileBlock,
  NewImageBlock,
  NewMediaTaskBlock,
  NewMultiChoiceBlock,
  NewPdfBlock,
  NewSimpleTaskBlock,
  NewTextBlock,
  NewUrlBlock,
  NewVideoBlock,
  ScormTaskBlock,
  ScormTaskBlockBVariants,
} from 'common-ui/generated/api/gcs';
import { useSelector } from 'react-redux';

import BlockTypes from '../helpers/constants';

import useRecurringDateAsSchedule from './useRecurringDateAsSchedule';

const useMapNewArticle = (nodes: Record<string, Node>) => {
  const selectedLanguages = useSelector(selectors.getSelectedLanguages);
  const selectedAudiences = useSelector(selectors.getSelectedAudiences);
  const selectedChannel = useSelector(selectors.getSelectedChannel);
  const selectedCategories = useSelector(selectors.getSelectedCategories);
  const selectedCollaborators = useSelector(selectors.getSelectedCollaborators);
  const isReactingAllowed = useSelector(selectors.getAllowComments);
  const shownAs = useSelector(selectors.getShownAs);
  const recurringDate = useSelector(selectors.getRecurringDate);
  const { getScheduleDate } = useRecurringDateAsSchedule();
  const answerSharing = useSelector(selectors.getAnswerSharing);
  const mainLanguage =
    selectedLanguages.find((sl) => sl.isDefault)?.code || 'en';

  const scheduleDate = getScheduleDate(recurringDate);

  const getScheduleValue = (block: Node) =>
    scheduleDate ? scheduleDate : block.data.props.schedule ?? null;

  const getDeadlineValue = (block: Node) =>
    scheduleDate
      ? null
      : block.data.props.schedule
        ? null
        : block.data.props.deadline ?? null;

  const getBlocks = (): NewBlockList => {
    const blockIds = nodes['dropableRegion'].data.nodes;
    const blocks: Array<NewBlock | null> = [];

    blockIds.forEach((blockId, index) => {
      const block = nodes[blockId];
      switch (block.data.displayName) {
        case BlockTypes.ArticleLinkBlock:
          blocks.push(buildArticleLinkBlockObject(block, index));
          break;
        case BlockTypes.TextBlock: {
          blocks.push(buildTextBlockObject(block, index));
          const dragId = block.data.linkedNodes['draggableContainer'];
          if (dragId) blocks.push(buildDraggableObject(nodes[dragId], index));
          break;
        }
        case BlockTypes.UrlBlock:
          blocks.push(buildUrlBlockObject(block, index));
          break;
        case BlockTypes.ImageBlock:
          blocks.push(buildImageBlockObject(block, index));
          break;
        case BlockTypes.VideoBlock:
          blocks.push(buildVideoBlockObject(block, index));
          break;
        case BlockTypes.GiphyBlock:
          blocks.push(buildGiphyBlockObject(block, index));
          break;
        case BlockTypes.PDFBlock:
          blocks.push(buildPdfBlockObject(block, index));
          break;
        case BlockTypes.FileBlock:
          blocks.push(buildFileBlockObject(block, index));
          break;
        case BlockTypes.SimpleTaskBlock:
          blocks.push(buildSimpleTaskBlockObject(block, index));
          break;
        case BlockTypes.QuizBlock:
          blocks.push(buildQuizBlockObject(block, index));
          break;
        case BlockTypes.YesNoNaBlock:
          blocks.push(buildYesNoNaBlockObject(block, index));
          break;
        case BlockTypes.PollBlock:
          blocks.push(buildPollBlockObject(block, index));
          break;
        case BlockTypes.ScormBlock:
          blocks.push(buildScormBlockObject(block, index));
          break;
        default:
          break;
      }
    });

    return blocks.flatMap((block) => (block ? [block] : []));
  };

  const nodesToNewArticle = (): NewArticle | null => {
    if (!nodes['dropableRegion']) return null;
    const blocks = getBlocks();

    const coverImage = nodes['coverImage'].data.props.imageSchema;
    const title = nodes['title'].data.props.text || 'Untitled';
    const variants = buildOuterVariantObject(title, coverImage);

    const article: NewArticle = {
      variants,
      blocks,
      status: 'draft',
      channelId: selectedChannel?.id ?? null,
      categories:
        selectedCategories?.map((sc) => {
          return sc.id;
        }) ?? [],
      publishAt: null,
      archiveAt: null,
      audiences: selectedAudiences.map((sa): number => {
        return sa.id;
      }),
      languages: selectedLanguages.map((sl) => {
        return { language: sl.code, isDefault: sl.isDefault };
      }),
      users: selectedCollaborators.map((sc) => {
        return {
          id: sc.id,
          languages: sc.languages,
        };
      }),
      isReactingAllowed,
      publishedInstantly: false,
      isTemplate: false,
      objectId: null,
      objectType: null,
      shownAs: shownAs.value,
      answerSharing: answerSharing ?? true,
    };

    return syncBlocks(article);
  };

  const buildOuterVariantObject = (
    title: string,
    coverImage: CoverImageFile
  ): { [key: string]: ArticleVariant } => {
    return {
      [mainLanguage]: {
        title,
        coverImage,
        translationStatus: 'draft',
      },
    };
  };

  const buildArticleLinkBlockObject = (
    block: Node,
    index: number
  ): NewArticleLinkBlock | null => {
    if (!block.data.props.articleLink?.id || block.data.props.unavailable) {
      return null;
    }

    return {
      version: 1,
      parentId: null,
      position: index,
      type: 'article_link',
      variants: {
        [mainLanguage]: {
          id: block.data.props.articleLink.id,
        },
      },
    };
  };

  const buildTextBlockObject = (
    block: Node,
    index: number
  ): NewTextBlock | null => {
    if (!block.data.props.nodes) return null;

    return {
      version: 1,
      parentId: null,
      type: 'text',
      position: index,
      variants: {
        [mainLanguage]: block.data.props.nodes,
      },
    };
  };

  const buildUrlBlockObject = (
    block: Node,
    index: number
  ): NewUrlBlock | null => {
    if (!block.data.props.url) return null;

    return {
      version: 1,
      parentId: null,
      position: index,
      type: 'url',
      variants: {
        [mainLanguage]: {
          name: block.data.props.name ?? '',
          url: block.data.props.url,
          openIn: block.data.props.openIn ?? 'new-window',
          translationStatus: 'draft',
        },
      },
    };
  };

  const buildImageBlockObject = (
    block: Node,
    index: number
  ): NewImageBlock | null => {
    if (!block.data.props.imageSchema) return null;

    return {
      version: 1,
      parentId: null,
      position: index,
      type: 'image',
      variants: {
        [mainLanguage]: {
          name: block.data.props.imageSchema.name,
          type: block.data.props.imageSchema.type,
          url: block.data.props.imageSchema.url,
          id: block.data.props.imageSchema.id,
          translationStatus: 'draft',
        },
      },
    };
  };

  const buildGiphyBlockObject = (
    block: Node,
    index: number
  ): NewImageBlock | null => {
    if (!block.data.props.imageSchema) return null;

    return {
      version: 1,
      parentId: null,
      position: index,
      type: 'image',
      variants: {
        [mainLanguage]: {
          name: block.data.props.imageSchema.name,
          type: 'external',
          url: block.data.props.imageSchema.url,
          translationStatus: 'draft',
        },
      },
    };
  };

  const buildVideoBlockObject = (
    block: Node,
    index: number
  ): NewVideoBlock | null => {
    if (!block.data.props.videoSchema) return null;

    return {
      version: 1,
      parentId: null,
      position: index,
      type: 'video',
      variants: {
        [mainLanguage]: {
          type: block.data.props.videoSchema.type,
          name: block.data.props.videoSchema.name,
          url: block.data.props.videoSchema.url,
          id: block.data.props.videoSchema.id,
          translationStatus: 'draft',
        },
      },
    };
  };

  const buildPdfBlockObject = (
    block: Node,
    index: number
  ): NewPdfBlock | null => {
    if (!block.data.props.pdfSchema) return null;

    return {
      version: 1,
      parentId: null,
      position: index,
      type: 'pdf',
      variants: {
        [mainLanguage]: {
          type: block.data.props.pdfSchema.type,
          name: block.data.props.pdfSchema.name,
          url: block.data.props.pdfSchema.url,
          id: block.data.props.pdfSchema.id,
          translationStatus: 'draft',
        },
      },
    };
  };

  const buildFileBlockObject = (
    block: Node,
    index: number
  ): NewFileBlock | null => {
    if (!block.data.props.fileSchema) return null;

    return {
      version: 1,
      parentId: null,
      position: index,
      type: 'file',
      variants: {
        [mainLanguage]: {
          type: block.data.props.fileSchema.type,
          name: block.data.props.fileSchema.name,
          url: block.data.props.fileSchema.url,
          id: block.data.props.fileSchema.id,
          translationStatus: 'draft',
        },
      },
    };
  };

  const buildSimpleTaskBlockObject = (
    block: Node,
    index: number
  ): NewSimpleTaskBlock | NewMediaTaskBlock | null => {
    if (!block.data.props.description) return null;

    return {
      version: 1,
      parentId: null,
      position: index,
      type: block.data.props.type,
      schedule: getScheduleValue(block),
      categories:
        block.data.props.categories?.map(
          (category: GetCategory) => category.id
        ) ?? [],
      deadline: getDeadlineValue(block),
      required: block.data.props.required,
      public: block.data.props.public,
      audiences: block.data.props.audiences,
      mandatory: block.data.props.mandatory,
      variants: {
        [mainLanguage]: {
          title: block.data.props?.title ?? '',
          description: block.data.props?.description,
          translationStatus: 'draft',
        },
      },
    };
  };

  const buildQuizBlockObject = (
    block: Node,
    index: number
  ): NewMultiChoiceBlock => {
    const optionsContainer = block.data.linkedNodes['optionsContainer'];
    const choices: NewChoiceSchema[] = nodes[optionsContainer].data.nodes.map(
      (n, i) => {
        const optionProps = nodes[n].data.props as QuizOptionProps;

        return {
          correct: optionProps.checked ?? false,
          position: i,
          answerType: 'string',
          variants: {
            [mainLanguage]: {
              ...(optionProps?.text && {
                answer: optionProps.text,
              }),
              ...(optionProps.imageSchema?.id && {
                image: {
                  id: optionProps.imageSchema.id,
                  translationStatus: 'draft',
                  type: 'internal',
                },
              }),
            },
          },
        };
      }
    );

    return {
      version: 1,
      parentId: null,
      position: index,
      type: block.data.props.type,
      categories:
        block.data.props.categories?.map(
          (category: GetCategory) => category.id
        ) ?? [],
      deadline: getDeadlineValue(block),
      schedule: getScheduleValue(block),
      required: block.data.props.required,
      public: block.data.props.public,
      audiences: block.data.props.audiences,
      minCorrectAnswers: block.data.props.minCorrectAnswers ?? 0,
      mandatory: block.data.props.mandatory,
      choices,
      variants: {
        [mainLanguage]: {
          title: block.data.props.title ?? '',
          description: block.data.props.description ?? '',
          explanation: block.data.props.explanation ?? '',
          translationStatus: 'draft',
        },
      },
    };
  };
  const buildYesNoNaBlockObject = (
    block: Node,
    index: number
  ): NewMultiChoiceBlock => {
    const optionsContainer = block.data.linkedNodes['optionsContainer'];
    const choices: NewChoiceSchema[] = nodes[optionsContainer].data.nodes.map(
      (n, i) => {
        const optionProps = nodes[n].data.props as YesNoNaOptionProps;

        return {
          correct: optionProps.correct ?? null,
          position: i,
          answerType: 'string',
          variants: {
            [mainLanguage]: {
              ...(optionProps?.text && {
                answer: optionProps.text,
              }),
            },
          },
        };
      }
    );

    return {
      version: 1,
      parentId: null,
      position: index,
      type: block.data.props.type,
      categories:
        block.data.props.categories?.map(
          (category: GetCategory) => category.id
        ) ?? [],
      deadline: getDeadlineValue(block),
      required: block.data.props.required,
      schedule: getScheduleValue(block),
      public: block.data.props.public,
      audiences: block.data.props.audiences,
      minCorrectAnswers: block.data.props.minCorrectAnswers ?? 0,
      renderType: MultiChoiceBlockRenderTypeEnum.YesNoNa,
      mandatory: block.data.props.mandatory,
      choices,
      variants: {
        [mainLanguage]: {
          title: block.data.props.title ?? '',
          description: block.data.props.description ?? '',
          explanation: block.data.props.explanation ?? '',
          translationStatus: 'draft',
        },
      },
    };
  };

  const buildDraggableObject = (block: Node, index: number) => {
    if (block.data.nodes.length <= 0) return null;

    const actionBlock = nodes[block.data.nodes[0]];

    switch (actionBlock.data.name) {
      case BlockTypes.SimpleTaskBlock:
        return buildSimpleTaskBlockObject(actionBlock, index);
      case BlockTypes.PollBlock:
        return buildPollBlockObject(actionBlock, index);
      case BlockTypes.QuizBlock:
        return buildQuizBlockObject(actionBlock, index);
      case BlockTypes.YesNoNaBlock:
        return buildYesNoNaBlockObject(actionBlock, index);
      case BlockTypes.ScormBlock:
        return buildScormBlockObject(actionBlock, index);
      default:
        return null;
    }
  };

  const buildPollBlockObject = (
    block: Node,
    index: number
  ): NewMultiChoiceBlock => {
    const pollOptionsContainer = block.data.linkedNodes['pollOptionsContainer'];
    const choices: NewChoiceSchema[] = nodes[
      pollOptionsContainer
    ].data.nodes.map((n, i) => {
      const optionProps = nodes[n].data.props as PollOptionProps;

      return {
        correct: null,
        position: i,
        answerType: 'string',
        variants: {
          [mainLanguage]: {
            ...(optionProps?.text && {
              answer: optionProps.text,
            }),
            ...(optionProps.imageSchema?.id && {
              image: {
                id: optionProps.imageSchema.id,
                translationStatus: 'draft',
                type: 'internal',
              },
            }),
          },
        },
      };
    });

    return {
      version: 1,
      parentId: null,
      position: index,
      type: block.data.props.type,
      renderType: MultiChoiceBlockRenderTypeEnum.Poll,
      categories:
        block.data.props.categories?.map(
          (category: GetCategory) => category.id
        ) ?? [],
      schedule: getScheduleValue(block),
      deadline: getDeadlineValue(block),
      required: block.data.props.required,
      public: block.data.props.public,
      audiences: block.data.props.audiences,
      maxSelectedChoices: block.data.props.maxSelectedChoices ?? 1,
      mandatory: block.data.props.mandatory,
      choices,
      variants: {
        [mainLanguage]: {
          title: block.data.props.title ?? '',
          description: block.data.props.description ?? '',
          translationStatus: 'draft',
        },
      },
    };
  };

  const buildScormBlockObject = (
    block: Node,
    index: number
  ): ScormTaskBlock | null => {
    if (!block.data.props) return null;

    return {
      version: 1,
      parentId: null,
      position: index,
      type: block.data.props.type,
      categories:
        block.data.props.categories?.map(
          (category: GetCategory) => category.id
        ) ?? [],
      deadline: getDeadlineValue(block),
      required: block.data.props.required,
      public: block.data.props.public,
      schedule: getScheduleValue(block),
      audiences: block.data.props.audiences,
      mandatory: block.data.props.mandatory,
      variants: {
        [mainLanguage]: {
          description: block.data.props.description,
          translationStatus: 'draft',
          package: block.data.props.package,
          image: block.data.props.image,
        },
      } as ScormTaskBlockBVariants,
    } as ScormTaskBlock;
  };

  const syncBlocks = (article: NewArticle): NewArticle => {
    if (!mainLanguage) return article;

    const selectedLanguageCodes = selectedLanguages
      .map((sl) => sl.code)
      .filter((code) => code !== mainLanguage);

    const withSyncedBlocks = article;

    selectedLanguageCodes.forEach((code) => {
      if (!article.variants[code]) {
        withSyncedBlocks.variants[code] = {
          ...article.variants[mainLanguage],
        };
      }

      article.blocks.forEach((block, i) => {
        if (block.variants[code]) return;

        withSyncedBlocks.blocks[i].variants[code] =
          block.variants[mainLanguage];

        if (block.type !== 'multi_choice') return;

        block.choices.forEach((ch, idx) => {
          if (ch.variants[code]) return;

          (withSyncedBlocks.blocks[i] as NewMultiChoiceBlock).choices[
            idx
          ].variants[code] = block.choices[idx].variants[mainLanguage];
        });
      });
    });

    return withSyncedBlocks;
  };

  return {
    nodesToNewArticle,
  };
};

export default useMapNewArticle;
