import { useEditor } from '@craftjs/core';
import { cx } from '@emotion/css';
import { useAuthenticatedUser } from 'app/api/auth/hooks';
import Badge, { BadgeColor } from 'app/components/Badge';
import Button from 'app/components/Button';
import { useDispatch } from 'app/hooks';
import useDateTimeFormat from 'app/hooks/useDateTimeFormat';
import {
  useArticlesTranslation,
  useCommonTranslation,
} from 'app/internationalization/hooks';
import useCopyArticle from 'app/pages/Articles/hooks/useCopyArticle';
import { routes } from 'app/router';
import { editorTypes } from 'app/router/constants';
import { selectors, actions as editorActions } from 'app/store/editor';
import CloudSave from 'assets/icons/cloud-save.svg?react';
import dayjs from 'dayjs';
import { ArrowForward, ArrowLeft2, Back, Send } from 'iconsax-react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import TimeLineIcon from 'remixicon-react/TimeLineIcon';
import { ArticleStatusEnum } from 'submodules/common-ui/generated/api/gcs';

import BlockTypes from '../../helpers/constants';
import useArticleCanBePublished from '../../hooks/useArticleCanBePublished';
import useArticleStatus from '../../hooks/useArticleStatus';
import useCurrentArticleLanguage from '../../hooks/useCurrentArticleLanguage';
import InfoPopUp from '../InfoPopUp';

import DropdownButton from './DropdownButton';
import LanguageDropdown from './LanguageDropdown';
import PublishDropdown from './PublishDropdown';
import TableOfContentButton from './TableOfContentsButton';
import TopBarTabsIndicatorContainer from './TopBarTabsIndicatorContainer';

interface TopBarProps {
  isLoading?: boolean;
  onSaveClick?: VoidFunction;
  onPublishClick?: VoidFunction;
  onTemplateClick?: VoidFunction;
  onLanguageSettingsClick?: () => void;
  beforeLanguageChange?: (code: string) => void;
  onShareClick?: () => void;
  showPublishSuccessModal?: () => void;
  onGenerateScormPackageClick?: () => void;
}

const TopBar = ({
  isLoading,
  onSaveClick,
  onPublishClick,
  onTemplateClick,
  beforeLanguageChange,
  onLanguageSettingsClick,
  onShareClick,
  showPublishSuccessModal,
  onGenerateScormPackageClick,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
TopBarProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id: articleId, mode } = useParams<{ id?: string; mode: string }>();
  const { t } = useArticlesTranslation();
  const { t: ct } = useCommonTranslation();
  const { data: user } = useAuthenticatedUser();
  const currentLanguageCode = useCurrentArticleLanguage();

  const canEdit = useSelector(selectors.getCanEdit);
  const article = useSelector(selectors.getArticle);
  const useTemplateView = useSelector(selectors.getUseTemplateView);

  const status = useArticleStatus(article);
  const { copy, isLoading: isCopyLoading } = useCopyArticle();

  const formattedUpdatedAt = useDateTimeFormat({
    timestamp: article?.updatedAt || '',
    type: 'fullDate',
  });

  const {
    title: articleTitle,
    actions,
    canRedo,
    canUndo,
    nodes,
  } = useEditor((state, query) => ({
    title: state?.nodes?.title?.data?.props?.text,
    canUndo: query.history.canUndo(),
    canRedo: query.history.canRedo(),
    nodes: state.nodes,
  }));

  const { canPublish } = useArticleCanBePublished(article);

  const { undo, redo } = actions.history;

  if (location?.state?.published) {
    showPublishSuccessModal?.();
  }

  const onTemplateUse = () => {
    if (!article) return;
    copy({
      articleId: article.id,
      onSuccess: (ids) => {
        navigate(routes.editorArticle.create(ids[0].toString(), 'edit'), {
          replace: true,
        });
        dispatch(editorActions.setTemplateView(false));
      },
    });
  };

  const articleOwner = article?.createdBy ?? user?.id;

  const disabled = useMemo(() => {
    const uploadTypes = [
      BlockTypes.ImageBlock,
      BlockTypes.VideoBlock,
      BlockTypes.PDFBlock,
      BlockTypes.FileBlock,
    ];

    const isUploading = Object.keys(nodes).some((key) => {
      if (uploadTypes.includes(nodes[key].data.displayName)) {
        return nodes[key].data.custom.isUploading;
      }

      return false;
    });

    return mode === editorTypes.review || isLoading || isUploading;
  }, [mode, isLoading, nodes]);

  const hasPermission = user?.permissions.modules.connectAccess
    ? true
    : articleOwner === user?.id;

  const updatedAtDate =
    useTemplateView || (!articleId && article)
      ? formattedUpdatedAt
      : dayjs(article?.updatedAt).format('DD MMM YYYY');

  const getBadgeColor = () => {
    switch (article?.status.toLowerCase()) {
      case ArticleStatusEnum.Published:
        return BadgeColor.Success;
      case 'scheduled':
        return BadgeColor.Focus;
      case ArticleStatusEnum.Archived:
        return BadgeColor.Error;
      default:
        return BadgeColor.Warning;
    }
  };

  return (
    <div className="flex w-full px-6 py-3 h-18 bg-white sticky top-0 z-10">
      <div className="flex flex-1 justify-start">
        <div className="flex">
          <button
            onClick={() =>
              navigate(routes.articleStudio.create(), { replace: true })
            }
          >
            <ArrowLeft2 />
          </button>
          <div className="flex flex-col items-start justify-between ml-6 mr-4">
            <span className="block text-sm max-w-34 overflow-hidden text-ellipsis whitespace-nowrap mb-1">
              {articleTitle?.length ? articleTitle : t('Untitled')}
            </span>

            <div className="flex items-center">
              {!article?.isTemplate && (
                <Badge title={status} type={getBadgeColor()} titleSize="10" />
              )}
              {updatedAtDate && (
                <>
                  {!article?.isTemplate && (
                    <div className="rounded bg-gray-light w-[3px] h-[3px] mx-2" />
                  )}
                  <div className="flex items-center justify-center text-gray-silver">
                    <TimeLineIcon size={12} />
                    <span className="block ml-1 text-xs max-w-34 overflow-hidden text-ellipsis whitespace-nowrap">
                      {ct('Updated')} {updatedAtDate}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="flex border-gray-light">
          <DropdownButton
            tooltipText={t('Switch and manage languages')}
            text={currentLanguageCode}
            disabled={disabled}
          >
            <LanguageDropdown
              disabled={!hasPermission}
              selectedLanguageCode={currentLanguageCode}
              beforeLanguageChange={beforeLanguageChange}
              onLanguageSettingsClick={() => onLanguageSettingsClick?.()}
            />
          </DropdownButton>
        </div>

        {!article?.isTemplate && (
          <div className="flex items-center text-gray-dark">
            <button onClick={undo} disabled={!canUndo || disabled}>
              <Back
                className={cx('ml-3', {
                  'text-grayscale-secondary': canUndo && !disabled,
                })}
              />
            </button>
            <button onClick={redo} disabled={!canRedo || disabled}>
              <ArrowForward
                className={cx('ml-2', {
                  'text-grayscale-secondary': canRedo && !disabled,
                })}
              />
            </button>
          </div>
        )}
      </div>

      {!useTemplateView &&
        (article?.isTemplate ? (
          <div className="py-2.5 px-5 bg-orange-light rounded">
            <span className="text-orange text-base">
              {t('You are editing a template.')}
            </span>
          </div>
        ) : (
          mode !== undefined &&
          !disabled &&
          !article?.isTemplate && (
            <TopBarTabsIndicatorContainer article={article} />
          )
        ))}

      <div className="flex flex-1 justify-end items-center">
        <TableOfContentButton />
        <InfoPopUp className="mr-8" />
        {useTemplateView ? (
          <div className="w-fit">
            <Button
              disabled={isCopyLoading}
              onClick={onTemplateUse}
              variant="primary"
              className="disabled:bg-gray-light px-3 flex items-center"
            >
              <Send size={16} className="mr-2" />
              {t('Use this template')}
            </Button>
          </div>
        ) : (
          <>
            <button
              className={cx(
                'flex justify-center items-center h-[36px] rounded-xl w-auto text-sm border-transparent py-2 px-3',
                {
                  'bg-hover-blue hover:bg-focus-background text-focus':
                    !disabled && canEdit,
                  'bg-grayscale-bg-dark text-grayscale-secondary':
                    disabled || !canEdit,
                }
              )}
              disabled={disabled || !canEdit}
              onClick={onSaveClick}
            >
              <div className="mr-1.5">
                <CloudSave />
              </div>
              <span className="text-sm">
                {(article && article.status === 'published') ||
                article?.isTemplate
                  ? t('Update')
                  : t('Save')}
              </span>
            </button>
            {onPublishClick && onTemplateClick && (
              <PublishDropdown
                hasPermission={hasPermission}
                disabled={disabled || !!article?.isTemplate || !canPublish}
                onPublishClick={onPublishClick}
                onTemplateClick={onTemplateClick}
                onShareClick={onShareClick}
                onGenerateScormPackageClick={onGenerateScormPackageClick}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default TopBar;
