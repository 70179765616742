import { useState, useLayoutEffect } from 'react';

const SCREEN_SIZES = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
};

export type ScreenBreakpoint = keyof typeof SCREEN_SIZES;

const resolveBreakpoint = (size: number): ScreenBreakpoint => {
  const breakpointKeys = Object.keys(SCREEN_SIZES).reverse();
  const breakpoint = breakpointKeys.find(
    (key: string) => size >= SCREEN_SIZES[key as ScreenBreakpoint]
  );

  if (!breakpoint) {
    return size > SCREEN_SIZES['2xl'] ? '2xl' : 'sm';
  }

  return breakpoint as ScreenBreakpoint;
};

const useScreenBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState<ScreenBreakpoint>(
    resolveBreakpoint(window.innerWidth)
  );

  useLayoutEffect(() => {
    const iOS = 'standalone' in navigator && window.visualViewport;

    const handleResize = () => {
      setBreakpoint((prev) => {
        const bp = resolveBreakpoint(window.innerWidth);
        return bp === prev ? prev : bp;
      });
    };

    if (iOS) {
      window?.visualViewport?.addEventListener('resize', handleResize);
    } else {
      window.addEventListener('resize', handleResize);
    }

    return () => {
      if (iOS) {
        window?.visualViewport?.removeEventListener('resize', handleResize);
      } else {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  return breakpoint;
};

export default useScreenBreakpoint;
