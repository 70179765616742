import { Modal } from 'app/components';
import { actions as modalActions } from 'app/store/modal';
import { useDispatch } from 'react-redux';

import ForwardArticleView from './ForwardArticleView';

export interface ForwardModalProps {
  articleOwner: number;
  articleId: number;
}

const ForwardModal = ({ articleOwner, articleId }: ForwardModalProps) => {
  const dispatch = useDispatch();

  const onClose = () => dispatch(modalActions.hideModal());

  return (
    <Modal
      onClose={onClose}
      className="w-[536px] overflow-visible overflow-y-visible"
      overflow
      containerPaddingClass="py-0 px-0"
    >
      <ForwardArticleView
        articleId={articleId}
        articleOwner={articleOwner}
        onClose={onClose}
      />
    </Modal>
  );
};

export default ForwardModal;
