import { cx } from '@emotion/css';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { editorTypes } from 'app/router/constants';
import ChaptersIcon from 'assets/icons/chapters.svg?react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import TableOfContentsEditor from '../TableOfContentsEditor';

const TableOfContentButton = () => {
  const { t } = useArticlesTranslation();
  const { mode, id } = useParams<{ mode: string; id?: string }>();
  const [isOpen, setIsOpen] = useState(false);

  const isToCVisible = (mode === editorTypes.edit || !id) && isOpen;

  return (
    <>
      <button
        data-for="tableOfContents"
        data-tip={t('Show Table of Contents')}
        className={cx(
          'text-grayscale-primary hover:text-focus p-2 rounded-lg hover:bg-hover-blue mr-1',
          {
            'text-focus bg-hover-blue [&_path]:text-focus': isOpen,
          }
        )}
        onClick={() => setIsOpen(!isOpen)}
      >
        <ChaptersIcon className="min-w-5 [&_path]:fill-current" />
      </button>
      <ReactTooltip
        place="bottom"
        effect="solid"
        class="react-tooltip"
        id="tableOfContents"
      />
      {isToCVisible && (
        <TableOfContentsEditor onHide={() => setIsOpen(false)} />
      )}
    </>
  );
};

export default TableOfContentButton;
