import styled from '@emotion/styled';
import Masonry from 'react-responsive-masonry';
import { ActionTypeEnum, Block } from 'submodules/common-ui/generated/api/gcs';

import { useAnswersContext } from './context';
import DeepDive from './DeepDive';
import ActionsSekeleton from './EmptyActions';
import Filter from './Filter/Filter';
import MediaTaskAnswers from './MediaTaskAnswers';
import OpenQuestionTaskAnswers from './OpenQuestionTaskAnswers';
import PollTaskAnswers from './PollTaskAnswers';
import QuizTaskAnswers from './QuizTaskAnswers';
import SimpleTaskAnswers from './SimpleTaskAnswers';
import YesNoTaskAnswers from './YesNoTaskAnswers';

const Answers = () => {
  const { selectedTaskId, article, isLoadingSharedArticle } =
    useAnswersContext();

  const taskBlocks = article?.blocks.filter(
    (block) =>
      block.type !== 'scorm_task' &&
      (Object.values(ActionTypeEnum) as string[]).includes(block.type)
  );

  const renderAnswers = (taskBlock: Block) => {
    switch (taskBlock.type) {
      case ActionTypeEnum.MediaTask:
        return <MediaTaskAnswers block={taskBlock} key={taskBlock.id} />;

      case ActionTypeEnum.SimpleTask:
        return <SimpleTaskAnswers block={taskBlock} key={taskBlock.id} />;

      case ActionTypeEnum.MultiChoice: {
        if (taskBlock.renderType === 'poll') {
          return <PollTaskAnswers block={taskBlock} key={taskBlock.id} />;
        }
        return <QuizTaskAnswers block={taskBlock} key={taskBlock.id} />;
      }

      case ActionTypeEnum.OpenQuestionTask:
        return <OpenQuestionTaskAnswers block={taskBlock} key={taskBlock.id} />;
      case ActionTypeEnum.YesNoTask:
        return <YesNoTaskAnswers block={taskBlock} key={taskBlock.id} />;
    }
  };

  if (taskBlocks?.length === 0) return <ActionsSekeleton />;

  if (isLoadingSharedArticle) {
    return (
      <div className="flex gap-6">
        <ActionsSekeleton empty={false} />
        <div className="w-[1px] h-[inherit] bg-gray-white-shadow" />
        <div className="flex md:w-[330px] h-12 items-center rounded-lg text-sm text-focus p-3 bg-focus-background opacity-50" />
      </div>
    );
  }

  if (selectedTaskId) {
    return (
      <div className="flex gap-6 h-full">
        <DeepDive />
        <div className="w-[1px] bg-gray-white-shadow h-[inherit]" />
        <Filter />
      </div>
    );
  }

  return (
    <div className="flex gap-6">
      <StyledMansory columnsCount={2} gutter="24px">
        {taskBlocks?.map(renderAnswers) ?? <></>}
      </StyledMansory>
      <div className="w-[1px] h-[inherit] bg-gray-white-shadow" />
      <Filter />
    </div>
  );
};

const StyledMansory = styled(Masonry)`
  width: 804px !important;
  place-content: stretch start !important;
  & > div {
    width: initial !important;
    flex: initial !important;
  }
`;

export default Answers;
