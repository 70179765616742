import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import config from 'app/config';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import removeTimezone from 'app/utils/removeTimezone';
import Chart2 from 'assets/icons/chart-2.svg?react';
import Gallery from 'assets/icons/gallery.svg?react';
import MessageText from 'assets/icons/message-gradient.svg?react';
import MessageQuestion from 'assets/icons/message-question.svg?react';
import TickSquare from 'assets/icons/tick-square.svg?react';
import YesNoIcon from 'assets/icons/yes-no-gradient.svg?react';
import dayjs from 'dayjs';
import {
  Eye,
  EyeSlash,
  Profile2User,
  Shop,
  Star1,
  Timer1,
} from 'iconsax-react';
import { FC, useCallback, useMemo } from 'react';
import {
  ActionTypeEnum,
  MultiChoiceBlockV1RenderTypeEnum,
} from 'submodules/common-ui/generated/api/gcs';
interface Props {
  blockType: string;
  deadline: string | null;
  isPublic: boolean;
  mandatory: boolean;
  required: number;
  renderType?: MultiChoiceBlockV1RenderTypeEnum;
}

const AnswerHeader: FC<Props> = ({
  deadline,
  blockType,
  isPublic,
  mandatory,
  required,
  renderType,
}) => {
  const { t } = useArticlesTranslation();

  const Icon = () => {
    const className = 'h-6 w-6';
    switch (blockType) {
      case ActionTypeEnum.MediaTask:
        return <Gallery className={className} />;
      case ActionTypeEnum.SimpleTask:
        return <TickSquare className={className} />;
      case ActionTypeEnum.MultiChoice:
        if (renderType === 'quiz') {
          return <MessageQuestion className={className} />;
        }
        if (renderType === 'poll') {
          return <Chart2 className={className} />;
        }
        return <YesNoIcon className={className} />;
      case ActionTypeEnum.OpenQuestionTask:
        return <MessageText className={className} />;
      case ActionTypeEnum.YesNoTask:
        return <YesNoIcon className={className} />;
      default:
        return null;
    }
  };

  const formattedDeadline = useMemo(() => {
    if (
      dayjs(deadline).isAfter(dayjs().add(2, 'day')) ||
      dayjs(deadline).isBefore(dayjs())
    ) {
      return dayjs(removeTimezone(deadline)).format('DD/MM/YYYY [at] HH:00');
    }

    return (
      dayjs(removeTimezone(deadline)).fromNow(true).replace('in ', '') + ' left'
    );
  }, [deadline]);

  const isDeadlineOver = useMemo(
    () => dayjs(deadline).isBefore(dayjs()),
    [deadline]
  );

  const isDeadlineBeforeDate = useCallback(
    (days: number) => dayjs(deadline).isBefore(dayjs().add(days, 'day')),
    [deadline]
  );

  return (
    <div className="rounded-xl p-2 gap-2 w-full shadow-atobi flex bg-grayscale-bg-dark">
      <div className="flex items-center gap-2">
        <div className="h-7 w-7 flex justify-center items-center rounded bg-white">
          <Icon />
        </div>
        <div className="w-7 h-7 flex justify-center items-center rounded bg-hover-blue text-ceil relative group">
          {required === -1 ? (
            <Profile2User className="h-4 w-4" />
          ) : (
            <Shop className="h-4 w-4" />
          )}
          {mandatory ? (
            <Star1
              className="absolute -bottom-0.5 -right-0.5"
              color={config.colors.white}
              size={12}
              style={{ fill: config.colors.success }}
            />
          ) : (
            <span className="absolute -bottom-[1px] -right-[1px] h-2.5 w-2.5 border border-white bg-warning rounded-full"></span>
          )}
          <Tooltip className="!rounded !opacity-100 bg-gray-extra-dark top-10 absolute z-999 hidden group-hover:inline-block text-white px-4 py-2 w-fit text-sm whitespace-nowrap">
            {`${mandatory ? t('Mandatory') : t('Optional')} ${
              required === -1
                ? t('for all members in the audience')
                : t('for one member per location')
            }`}
          </Tooltip>
        </div>

        <div className="w-7 h-7 flex justify-center items-center rounded bg-hover-blue text-ceil group relative">
          {isPublic ? (
            <Eye className="h-4 w-4" />
          ) : (
            <EyeSlash className="h-4 w-4" />
          )}
          <Tooltip className="!rounded !opacity-100 bg-gray-extra-dark top-10 absolute z-999 hidden group-hover:inline-block text-white px-4 py-2 w-fit text-sm whitespace-nowrap">
            {isPublic ? t('Answers are public') : t('Answers are private')}
          </Tooltip>
        </div>

        {deadline && (
          <div
            className={cx('h-7 flex items-center p-1 rounded gap-1', {
              'bg-orange-light text-orange':
                isDeadlineBeforeDate(2) && !isDeadlineBeforeDate(2),
              'bg-error-light text-error':
                isDeadlineBeforeDate(1) || isDeadlineOver,
              'bg-hover-blue text-ceil':
                !isDeadlineBeforeDate(1) &&
                !isDeadlineOver &&
                !isDeadlineBeforeDate(2),
            })}
          >
            <Timer1 className="h-4 w-4" />
            <span className="text-sm">{formattedDeadline}</span>
          </div>
        )}
      </div>
    </div>
  );
};

const Tooltip = styled.div`
  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 50%;
    border-width: 5px;
    border-style: solid;
    transform: translateX(-50%);
    border-color: transparent transparent ${config.colors['gray-extra-dark']}
      transparent;
  }
`;

export default AnswerHeader;
