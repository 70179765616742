import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import config from 'app/config';
import { Icon as IconType } from 'iconsax-react';

import Badge, { BadgeColor } from '../Badge';

interface TabIndicatorProps {
  className?: string;
  label: string;
  selected: boolean;
  Icon?: IconType | React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  badgeTitle?: string;
  onClick: () => void;
  isBeta?: boolean;
}

const TabIndicator = ({
  className,
  label,
  selected,
  Icon,
  badgeTitle,
  onClick,
  isBeta,
}: TabIndicatorProps) => {
  const selectedColor = selected
    ? config.colors['grayscale-primary']
    : config.colors['grayscale-secondary'];

  return (
    <button
      className={cx('mr-8 flex flex-col relative', className)}
      onClick={selected ? undefined : onClick}
    >
      <div className="flex items-center">
        {Icon && <Icon fill={selectedColor} color={selectedColor} size={20} />}
        <span
          className={cx('flex p-2 gap-1 items-center', {
            'text-grayscale-primary': selected,
            'text-grayscale-secondary': !selected,
          })}
        >
          {label}
          {isBeta && (
            <BetaStyle className="px-1 py-0.5 text-xs relative">Beta</BetaStyle>
          )}
        </span>
        {badgeTitle && (
          <div className="flex">
            <Badge title={badgeTitle} type={BadgeColor.Primary} />
          </div>
        )}
      </div>
      <div
        className={cx(
          'w-full h-[3px] bg-focus rounded absolute bottom-[-2px]',
          {
            invisible: !selected,
          }
        )}
      ></div>
    </button>
  );
};

const BetaStyle = styled.span`
  background: linear-gradient(133.41deg, #323493 -0.08%, #df9e9c 100%);
  background-clip: text;
  text-fill-color: transparent;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 8px;
    border: 1px solid transparent;
    background: linear-gradient(to right, #323493, #df9e9c) border-box;
    -webkit-mask:
      linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`;

export default TabIndicator;
