import { useAuthenticatedUser } from 'app/api/auth/hooks';
import { editorTypes } from 'app/router/constants';
import { selectors } from 'app/store/editor';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const disabledModes = [
  editorTypes.view.toString(),
  editorTypes.review.toString(),
  editorTypes.actions.toString(),
];

const useIsEditorEnabled = () => {
  const { mode } = useParams<{ mode: string }>();
  const { data: user } = useAuthenticatedUser();
  const canEdit = useSelector(selectors.getCanEdit);

  const enabled = useMemo(
    () =>
      (user?.permissions.modules.connectAccess || canEdit) &&
      !disabledModes.includes(mode ?? ''),
    [canEdit, mode, user?.permissions.modules.connectAccess]
  );

  return {
    enabled,
    mode,
  };
};

export default useIsEditorEnabled;
