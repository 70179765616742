import { cx } from '@emotion/css';
import { Notification } from 'iconsax-react';
import { FC } from 'react';

interface NotificationsIconProps {
  hasUnread: boolean;
  isActive: boolean;
  iconClassName?: string;
  unreadPosition?: 'right' | 'left';
}

const NotificationsIcon: FC<NotificationsIconProps> = ({
  hasUnread,
  isActive,
  unreadPosition = 'right',
  iconClassName,
}) => (
  <div className="relative">
    {hasUnread && (
      <div
        className={cx(
          'absolute right-0  mr-1 rounded-full w-2.5 h-2.5 border border-white bg-focus',
          {
            '-mt-0.5': unreadPosition === 'right',
            '-mt-0.5 mr-3': unreadPosition === 'left',
          }
        )}
      />
    )}
    <Notification
      className={cx('group-hover:text-focus w-6 h-6', iconClassName, {
        'text-focus ': isActive,
      })}
    />
  </div>
);

export default NotificationsIcon;
