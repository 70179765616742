import { PaginatedResponse } from 'app/api/types';

import { request } from '../../request';
import { ProfessionLocationUser } from '../types';

const getUsersFromLocationProfession = (
  locations: number[],
  professions: number[],
  includeParentsLocations: boolean
) =>
  request().get<PaginatedResponse<ProfessionLocationUser[]>>(`/api/v1/users?`, {
    params: {
      locations: locations.toString(),
      professions: professions.toString(),
      include_parents_locations: includeParentsLocations,
    },
  });

export { getUsersFromLocationProfession };
export default getUsersFromLocationProfession;
