import { useArticlesTranslation } from 'app/internationalization/hooks';

interface Props {
  onChange: (value: string) => void;
}

const ForwardArticleComment = ({ onChange }: Props) => {
  const { t } = useArticlesTranslation();

  return (
    <textarea
      rows={3}
      onChange={(event) => onChange(event.target.value)}
      className="bg-grayscale-bg-dark p-4 w-full text-sm rounded-lg resize-none border mb-2 focus:outline border-gray-dark focus-visible:outline-focus"
      placeholder={t(
        'Add an optional short message to the team(s). E.g. what’s your ideal audience and publish date?'
      )}
    />
  );
};

export default ForwardArticleComment;
