import { useFeatures } from '@paralleldrive/react-feature-toggles';
import { useAuthenticatedUser } from 'app/api/auth/hooks';
import { useDispatch, useSelector } from 'app/hooks';
import { actions, selectors } from 'app/store/channels';
import { useEffect } from 'react';

import useGetRelevantChannelsQuery from './useRelevantChannelsQuery';

const useChannels = () => {
  const features = useFeatures();
  const dispatch = useDispatch();

  const { data: user } = useAuthenticatedUser();
  const channels = useSelector(selectors.getRelevantChannels);

  const { data, isLoading, meta, hasNextPage, fetchNextPage, isFetching } =
    useGetRelevantChannelsQuery({
      ranking: 'desc',
      timeZone: user?.timezone ?? '',
      enabled: features.includes('channelsView') && user !== undefined,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    });

  useEffect(() => {
    const channelsData = data?.pages?.flatMap((page) => page.data.data) ?? [];
    dispatch(actions.setRelevantChannels(channelsData));
  }, [data?.pages, dispatch]);

  return {
    channels,
    isLoading,
    hasNextPage,
    isFetching,
    fetchNextPage,
    meta,
  };
};

export default useChannels;
