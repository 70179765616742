import { Button, Modal } from 'app/components';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { Link } from 'iconsax-react';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';

const ConfirmDeleteBlockModal = ({
  onCancelClick,
  onConfirmClick,
}: {
  onConfirmClick: VoidFunction;
  onCancelClick: VoidFunction;
}) => {
  const { t } = useArticlesTranslation();
  return (
    <Modal onClose={onCancelClick}>
      <div className="max-w-sm">
        <div className="flex items-center relative w-full justify-between">
          <label className="block font-bold text-lg" htmlFor="leave">
            {t('Are you sure?')}
          </label>
          <button
            className="h-6 w-6 flex justify-center items-center rounded bg-white text-grayscale-secondary"
            onClick={onCancelClick}
          >
            <CloseLineIcon />
          </button>
        </div>
        <div className="font-bold mt-4 mb-6 text-lg">
          {t(
            'You are about to delete a block from an article that is being shared.'
          )}
        </div>
        <div className="flex">
          <div className="pr-4">
            <Link size={24} />
          </div>
          <div>
            {t(
              'Deleting this block will unlink it from the shared article, meaning any actions or answers associated with this block will no longer be shared with others.'
            )}
          </div>
        </div>
        <div className="flex w-full items-center justify-center mt-8">
          <Button
            className="w-[170px] h-12 ml-3.5 rounded-xl text-sm border-transparent bg-hover-blue text-focus"
            onClick={onCancelClick}
          >
            {t('Cancel')}
          </Button>

          <Button
            className="w-[170px] h-12 ml-3.5 rounded-xl text-sm border-transparent bg-focus text-white"
            onClick={onConfirmClick}
          >
            {t('Yes, delete')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteBlockModal;
