import { cx } from '@emotion/css';
import { ScrollbarContainer } from 'app/components';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { selectors } from 'app/store/editor';
import sortBy from 'lodash/sortBy';
import { useSelector } from 'react-redux';

import LanguageItem from './LanguageItem';

interface Props {
  onSave: () => void;
}

const ActiveLanguages = ({ onSave }: Props) => {
  const { t } = useArticlesTranslation();
  const languages = useSelector(selectors.getSelectedLanguages);

  const mainLanguage = languages.find(({ isDefault }) => isDefault);
  const secondaryLanguages = languages.filter(({ isDefault }) => !isDefault);
  const isSecondaryLanguagesNotEmpty = secondaryLanguages.length > 0;

  const sortedSecondaryLanguages = sortBy(secondaryLanguages, ['name']);

  return (
    <div className="mt-6 relative">
      <div className="flex flex-col pb-3">
        <span className="font-bold text-sm mb-1">{t('Active Languages')}</span>
        <span className="text-xs text-grayscale-secondary">
          {t('Members will see the language based on their profile settings.')}
        </span>
      </div>
      <div
        className={cx({
          'border-b pb-3 mb-3 border-b-hover-blue':
            isSecondaryLanguagesNotEmpty,
        })}
      >
        {mainLanguage && (
          <LanguageItem language={mainLanguage} onSave={onSave} />
        )}
      </div>
      {isSecondaryLanguagesNotEmpty && (
        <ScrollbarContainer className="max-h-[275px]">
          {sortedSecondaryLanguages.map((language) => (
            <LanguageItem
              key={`${language.name}-${language.code}`}
              language={language}
              className="mb-2"
              onSave={onSave}
            />
          ))}
        </ScrollbarContainer>
      )}
    </div>
  );
};

export default ActiveLanguages;
