import {
  BasicChannelInfo,
  BasicRelevantChannelInfo,
} from 'submodules/common-ui/generated/api/gcs';

const getChannelTitle = (
  channel: BasicChannelInfo | BasicRelevantChannelInfo | null,
  interfaceLanguage?: string
) => {
  if (!channel?.title) return '-';

  const mainLanguage =
    Object.keys(channel.title).find((e) => channel.title[e].isDefault) ?? 'en';

  let title;
  if (interfaceLanguage) title = channel.title[interfaceLanguage]?.title;
  if (!title) title = channel.title[mainLanguage]?.title;
  if (!title) title = channel.title[Object.keys(channel.title)[0]].title;

  return title;
};

export default getChannelTitle;
