import { cx } from '@emotion/css';
import { ArticleFilter } from 'app/store/common/types';
import ThreeDots from 'assets/icons/three_dots_vertical.svg?react';
import UncheckedIcon from 'assets/icons/unchecked.svg?react';
import { Gallery } from 'iconsax-react';
import { PropsWithChildren } from 'react';

interface ArticleTableSkeletonProps {
  rowsCount?: number;
  status?: ArticleFilter;
  inbound?: boolean;
}

const ArticleTableSkeleton = ({
  rowsCount = 20,
  status,
  inbound = false,
}: ArticleTableSkeletonProps) => {
  const skeleton = [];

  for (let i = 0; i < rowsCount; i++) {
    skeleton.push(
      <tr key={i}>
        <td className="!w-full py-4 px-6">
          <div className="flex justify-start items-center gap-3 min-h-[55px]">
            {!inbound && <UncheckedIcon />}
            <Placeholder className="!h-[35px] flex items-center justify-center">
              <Gallery className="text-focus opacity-40" size={17} />
            </Placeholder>
            <Placeholder className="w-[110px]" />
          </div>
        </td>
        <td>
          <Placeholder />
        </td>
        <td>
          <Placeholder className="w-[88px]" />
        </td>
        <td>
          <Placeholder className="w-[98px]" />
        </td>
        <td>
          <Placeholder className="w-[88px]" />
        </td>
        {(status === 'live' || status === 'draft') && (
          <td>
            <Placeholder className="w-[30px]" />
          </td>
        )}
        {(status === 'live' || status === 'scheduled') && (
          <td>
            <Placeholder className="w-[88px]" />
          </td>
        )}
        {!inbound && (
          <td className="!w-[88px]">
            <ThreeDots />
          </td>
        )}
      </tr>
    );
  }

  return <>{skeleton}</>;
};

export const Placeholder = ({
  className,
  children,
}: { className?: string } & PropsWithChildren) => (
  <div className={cx('h-[14px] w-16 bg-hover-blue rounded-sm', className)}>
    {children}
  </div>
);

export default ArticleTableSkeleton;
