import { cx } from '@emotion/css';
import { Switch } from 'app/components';
import { t } from 'i18next';
import { ArrowRight2 } from 'iconsax-react';
import { useState } from 'react';

interface AdvancesSettingsProps {
  sharingEnabled: boolean;
  allowResharing: boolean;
  setSharingEnabled: (value: boolean) => void;
  setAllowResharing: (value: boolean) => void;
}

const AdvancedSettings = ({
  allowResharing,
  sharingEnabled,
  setAllowResharing,
  setSharingEnabled,
}: AdvancesSettingsProps) => {
  const [settingsExpanded, setSettingsExpanded] = useState(false);

  return (
    <div className="flex flex-col  mt-3 bg-white border border-focus-background rounded-xl">
      <div className="flex gap-2 items-center justify-start px-2 py-3">
        <button onClick={() => setSettingsExpanded((prev) => !prev)}>
          <ArrowRight2
            className={cx({
              'transform rotate-90': settingsExpanded,
            })}
            size={16}
          />
        </button>
        <span className="text-xs font-bold">{t('Advanced settings')}</span>
      </div>

      <div
        className={cx(
          'max-h-0 transition-all duration-300 ease-in-out overflow-hidden',
          {
            'max-h-[300px]': settingsExpanded,
          }
        )}
      >
        <div
          className={cx(
            'flex items-center px-2 pb-3 border-t border-t-focus-background'
          )}
        >
          <div className="flex flex-col mr-3">
            <span className="text-xs font-bold mt-2">
              {t('Request insights')}
            </span>
            <span className="text-xs text-grayscale-secondary">
              {t(
                'Keep this enabled to get access to insights, results, and answers from partners. Note: The partners can chose to opt out.'
              )}
            </span>
          </div>
          <Switch
            className="ml-auto"
            width={39}
            height={24}
            checked={sharingEnabled}
            onChange={(checked) => setSharingEnabled(checked)}
          />
        </div>

        <div
          className={cx(
            'flex items-center px-2 pb-3 border-t border-t-focus-background'
          )}
        >
          <div className="flex flex-col mr-3">
            <span className="text-xs font-bold mt-2">
              {t('Allow resharing')}
            </span>
            <span className="text-xs text-grayscale-secondary">
              {t(
                'Keep this enabled to allow partners to reshare this article with other partners.'
              )}
            </span>
          </div>
          <Switch
            className="ml-auto"
            width={39}
            height={24}
            checked={allowResharing}
            onChange={(checked) => setAllowResharing(checked)}
          />
        </div>
      </div>
    </div>
  );
};

export default AdvancedSettings;
