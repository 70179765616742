import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import cs from '../../translations/cs.json';
import da from '../../translations/da.json';
import de from '../../translations/de.json';
import el from '../../translations/el.json';
import en from '../../translations/en.json';
import es from '../../translations/es.json';
import fi from '../../translations/fi.json';
import fr from '../../translations/fr.json';
import he from '../../translations/he.json';
import it from '../../translations/it.json';
import kk from '../../translations/kk.json';
import nb from '../../translations/nb.json';
import pt from '../../translations/pt.json';
import ru from '../../translations/ru.json';
import sl from '../../translations/sl.json';
import sv from '../../translations/sv.json';
import tr from '../../translations/tr.json';
import uk from '../../translations/uk.json';

const resources = {
  en: {
    translation: en,
  },
  de: {
    translation: de,
  },
  fi: {
    translation: fi,
  },
  cs: {
    translation: cs,
  },
  es: {
    translation: es,
  },
  fr: {
    translation: fr,
  },
  it: {
    translation: it,
  },
  da: {
    translation: da,
  },
  sv: {
    translation: sv,
  },
  el: {
    translation: el,
  },
  he: {
    translation: he,
  },
  sl: {
    translation: sl,
  },
  pt: {
    translation: pt,
  },
  nb: {
    translation: nb,
  },
  uk: {
    translation: uk,
  },
  kk: {
    translation: kk,
  },
  tr: {
    translation: tr,
  },
  ru: {
    translation: ru,
  },
};

// eslint-disable-next-line import/no-named-as-default-member
i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  returnNull: false,
});

export default i18n;
