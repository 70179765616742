import { cx } from '@emotion/css';
import { useSelector } from 'app/hooks';
import { editorTypes } from 'app/router/constants';
import { selectors } from 'app/store/editor';
import { useState } from 'react';

import Backlinks from '../Backlinks';
import EditorCanvas from '../components/EditorCanvas';
import PreviewNavigation from '../components/PreviewNavigation';
import ReviewPopUp from '../components/ReviewPopUp';

const ReviewMode = () => {
  const [showTableContents, setShowTableContents] = useState(false);

  const article = useSelector(selectors.getArticle);

  const animate = () => showTableContents;

  return (
    <>
      <PreviewNavigation onShowTableContents={setShowTableContents} />
      <EditorCanvas
        animate={false}
        className="mt-[54px] lg:mt-[96px]"
        mode={editorTypes.review}
      >
        <>
          <Backlinks
            articleId={article?.id}
            className={cx(
              'flex-auto flex flex-col justify-end mt-5 lg:mt-0 lg:mb-5 transition duration-300 ease-in-out transform',
              {
                'lg:-translate-x-[50px]': animate(),
              }
            )}
          />
        </>
      </EditorCanvas>
      <ReviewPopUp />
    </>
  );
};

export default ReviewMode;
