import useLoginMethod from 'app/hooks/useLoginMethod';
import { useModulesTranslation } from 'app/internationalization/hooks';

import NavigationLink from '../NavigationLink';
import { Link, SidebarSection } from '../types';

import ModuleExpansion from './ModuleExpansion';

interface Props {
  header?: string;
  items: Link[];
  isClipped: boolean;
  badgeData: {
    [x: string]: number | undefined;
  };
  onClick?: () => void;
  section: SidebarSection;
}

const NavigationModule = ({
  badgeData,
  header,
  isClipped,
  items,
  onClick,
  section,
}: Props) => {
  const { t } = useModulesTranslation();
  const { isEasyAccessLogin } = useLoginMethod();

  if (
    items.length <= 0 ||
    (isEasyAccessLogin && !items.some((i) => i.isEasyAccessLoginAccessible))
  )
    return null;

  return (
    <ModuleExpansion section={section} title={header} isClipped={isClipped}>
      {items.map(
        ({ title, Icon, to, badgeName, isEasyAccessLoginAccessible }) =>
          (!isEasyAccessLogin || isEasyAccessLoginAccessible) && (
            <NavigationLink
              key={to}
              badge={badgeName ? badgeData[badgeName] : undefined}
              Icon={Icon}
              isClipped={isClipped}
              to={to}
              onClick={onClick}
              tooltipText={title}
            >
              <div className="whitespace-nowrap ml-1">{t(title)}</div>
            </NavigationLink>
          )
      )}
    </ModuleExpansion>
  );
};

export default NavigationModule;
