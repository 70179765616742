import { useFeatures } from '@paralleldrive/react-feature-toggles';
import { isActiveFeatureName } from 'app/utils';
import { useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';

import NavigationLink from '../NavigationLink';
import { Link } from '../types';

interface Props extends Link {
  isClipped: boolean;
}

const ChannelLink = ({
  Icon,
  title,
  to,
  badgeName,
  feature,
  isClipped,
}: Props) => {
  const features = useFeatures();

  const ref = useRef<HTMLDivElement | null>(null);
  const [isTitleTruncated, setIsTitleTruncated] = useState(false);

  useEffect(() => {
    if (!ref.current) return;
    setIsTitleTruncated(ref.current.scrollHeight > ref.current.clientHeight);
  }, [title]);

  if (feature && !isActiveFeatureName(feature, features)) return null;

  return (
    <NavigationLink
      data-tip={isTitleTruncated ? title : undefined}
      data-for={isTitleTruncated ? 'title' : undefined}
      key={to}
      badge={badgeName}
      Icon={Icon}
      isClipped={isClipped}
      to={to}
      tooltipText={title}
    >
      <div ref={ref} className="ml-1 line-clamp-1">
        {title}
      </div>
      {isTitleTruncated && (
        <ReactTooltip
          place="top"
          delayShow={500}
          effect="solid"
          class="react-tooltip"
          id="title"
        />
      )}
    </NavigationLink>
  );
};

export default ChannelLink;
