const CommentSkeleton = () => {
  return (
    <div className="w-full px-6 flex pb-5">
      <div className="h-[32px] w-[32px] bg-hover-blue rounded mr-2" />
      <div className="min-h-[86px] w-full bg-hover-blue rounded-tl-sm rounded-xl flex flex-col px-3 pt-3">
        <div className="flex justify-between w-full pb-2">
          <div className="pt-1 pb-3 px-2 bg-gradient-to-l bg-focus-background rounded-xl w-1/3" />
          <div className="pt-1 pb-3 px-2 bg-gradient-to-l bg-focus-background rounded-xl w-1/6" />
        </div>
        <div className="pb-2">
          <div className="pt-1 pb-3 px-2 bg-gradient-to-l bg-focus-background rounded-xl w-1/2" />
        </div>
        <div className="pt-1 pb-3 px-2 bg-gradient-to-l bg-focus-background rounded-xl" />
      </div>
    </div>
  );
};

export default CommentSkeleton;
