import { Role, SystemModules } from 'app/api/auth/constants';
import { useAuthenticatedUser } from 'app/api/auth/hooks';
import { PageLoader } from 'app/components';
import useLoginMethod from 'app/hooks/useLoginMethod';
import log from 'app/utils/logger';
import localforage from 'localforage';
import React, { useEffect } from 'react';
import { useLocation, Navigate } from 'react-router-dom';

import routes from './routes';

interface PrivateRouteProps {
  accessibleByRoles?: Array<Role>;
  accessibleByEasyAccess?: boolean;
  onlyOwners?: boolean;
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  accessibleByRoles,
  accessibleByEasyAccess,
  onlyOwners,
  children,
}) => {
  const {
    data: user,
    hasRole,
    isChannelsManager,
    hasCreateArticlePermission,
    hasUpdateArticlePermission,
  } = useAuthenticatedUser();

  const { isEasyAccessLogin } = useLoginMethod();

  const location = useLocation();

  useEffect(() => {
    const handlePendingNotification = async () => {
      try {
        const url = await localforage.getItem<string>('redirectUrl');

        if (!url) return;

        await localforage.removeItem('redirectUrl');

        window.location.href = url;
      } catch (error) {
        log.error('Error handling pending notification', error);
      }
    };
    handlePendingNotification();
  }, []);

  const getDefaultRoute = () => {
    if (!user) return routes.login.create();

    const initialPages = [
      { module: SystemModules.News, route: routes.newsFeed.create() },
      { module: SystemModules.Social, route: routes.socialFeed.create() },
    ];

    const initialPage = initialPages.find(
      (p) => !user.hiddenModules?.[p.module]
    );

    if (!initialPage) return routes.home.create();

    return initialPage.route;
  };

  const isUserNotManager = () => {
    const paths =
      location.pathname.includes(routes.categoriesStudio.create()) ||
      location.pathname.includes(routes.channels.create());

    return user && paths && !isChannelsManager();
  };

  const canAccessGlossary = () => {
    const glossary = location.pathname === routes.glossary.create();

    if (!glossary || !user) return false;

    return (
      user.isOwner || hasCreateArticlePermission || hasUpdateArticlePermission
    );
  };

  const userUnableToCreateArticle = () => {
    const createPath = location.pathname === routes.editor.create();

    return user && createPath && !hasCreateArticlePermission;
  };

  const isGoingToHiddenRoute = (): boolean => {
    return (
      ((user?.hiddenModules?.[SystemModules.Social] ||
        user?.discontinuedModules?.[SystemModules.Social]) &&
        location.pathname.includes('feed')) ||
      ((user?.hiddenModules?.[SystemModules.News] ||
        user?.discontinuedModules?.[SystemModules.News]) &&
        location.pathname.includes('news')) ||
      (!user?.hiddenModules?.[SystemModules.News] &&
        !user?.discontinuedModules?.[SystemModules.News] &&
        location.pathname.includes('home'))
    );
  };

  // Wait until we have user data to configure request instance
  if (!user) {
    return <PageLoader />;
  }

  if (accessibleByRoles && !user) return null;

  if (!accessibleByEasyAccess && isEasyAccessLogin) return null;

  if (canAccessGlossary()) return <>{children}</>;

  if (onlyOwners && !user?.isOwner) {
    if (!user) return null;

    return <Navigate replace to={getDefaultRoute()} />;
  }

  if (isGoingToHiddenRoute()) {
    return <Navigate replace to={getDefaultRoute()} />;
  }

  if (accessibleByRoles && user && !hasRole(accessibleByRoles)) {
    return <Navigate replace to={getDefaultRoute()} />;
  }

  if (isUserNotManager() || userUnableToCreateArticle()) {
    return <Navigate replace to={getDefaultRoute()} />;
  }

  return <>{children}</>;
};

export default PrivateRoute;
