import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import { Alert, FilePicker } from 'app/components';
import { types } from 'app/utils';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import CameraIcon from 'remixicon-react/CameraLineIcon';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';

interface ProfileLayoutProps {
  imageSource: types.SelectedImageSource;
  className?: string;
  onProfileImageChange?: (image: File) => void;
  imageError?: string;
  children: ReactNode;
}

const ProfileImage = styled.img`
  width: 147px;
  height: 147px;
`;

const ProfileLayout = ({
  imageSource,
  children,
  className,
  onProfileImageChange,
  imageError,
}: ProfileLayoutProps) => {
  const navigate = useNavigate();

  return (
    <div className="w-full flex justify-center pt-12 relative">
      <button
        className="lg:hidden flex justify-center items-center h-8 w-8 ml-4 mt-1 rounded bg-white shadow-atobi text-grayscale-secondary absolute left-0 top-0"
        onClick={() => navigate(-1)}
      >
        <CloseLineIcon />
      </button>
      <div className="h-fit w-full lg:w-[55%]">
        <div
          className={cx(
            ' shadow-atobi rounded-lg p-7.5 lg:p-21 relative mt-21 mb-12',
            className
          )}
        >
          <div className="absolute top-0 transform -translate-y-[85%] translate-x-1/2 lg:translate-x-0 lg:-translate-y-2/3">
            <div className="p-1 rounded-xl relative shadow-atobi border-white">
              {onProfileImageChange && (
                <FilePicker
                  accept="image"
                  onChange={onProfileImageChange}
                  className="absolute bg-grayscale-secondary opacity-80 hover:opacity-90 text-white inset-1/2 h-12 w-12 flex items-center justify-center transform -translate-x-1/2 -translate-y-1/2  p-2 rounded-full"
                >
                  <span>
                    <CameraIcon />
                  </span>
                </FilePicker>
              )}

              {imageSource && (
                <ProfileImage
                  {...imageSource}
                  alt="Profile"
                  className="rounded-xl"
                />
              )}
            </div>
            {imageError && <Alert variant="error">{imageError}</Alert>}
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};
export default ProfileLayout;
