import useChannels from 'app/api/channels/hooks/useChannels';

import ChannelLinks from '../components/ChannelLinks';
import { SidebarSections } from '../types';

import ModuleExpansion from './ModuleExpansion';

interface Props {
  isClipped: boolean;
  badgeData: {
    [x: string]: number | undefined;
  };
}

const ChannelsModule = ({ isClipped, badgeData }: Props) => {
  const { channels, hasNextPage, fetchNextPage } = useChannels();

  if (channels.length <= 0) return null;

  return (
    <ModuleExpansion
      section={SidebarSections.Channels}
      title="Channels"
      isClipped={isClipped}
    >
      <ChannelLinks
        channels={channels}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        badgeData={badgeData}
        isClipped={isClipped}
      />
    </ModuleExpansion>
  );
};

export default ChannelsModule;
