import config from 'app/config';
import { selectors } from 'app/store/auth';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  CoverImageArticleFileGet,
  DirectUrls,
  InternalFileGet,
} from 'submodules/common-ui/generated/api/gcs';

const useImageSource = (image?: CoverImageArticleFileGet | InternalFileGet) => {
  const [directUrls, setDirectUrls] = useState<DirectUrls>();
  const [external, setExternal] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [imageSource, setImageSource] = useState('');

  const tenant = useSelector(selectors.getSelectedTenant);

  useEffect(() => {
    const internal = image?.type === 'internal';
    const imgSource = image?.url
      ? internal
        ? `${config.env.gcsApiUrl}/${image.url}`
        : image.url
      : internal
      ? `${config.env.gcsApiUrl}/file/${tenant?.alias}/${image.id}/${image?.name}`
      : '';
    if (image && 'directUrls' in image) setDirectUrls(image.directUrls);

    setImageSource(imgSource);
    setExternal(!internal);
  }, [image, tenant]);

  return {
    directUrls,
    external,
    hasError,
    hasLoaded,
    imageSource,
    setHasLoaded,
    setHasError,
  };
};

export default useImageSource;
