import { useCommonTranslation } from 'app/internationalization/hooks';

import useInstallChromePwa from './useInstallChromePwa';

const ChromePopup = () => {
  const { t } = useCommonTranslation();

  const {
    installEvent,
    isInstalled,
    isSafari,
    hidden,
    isMobile,
    handleOnInstall,
    onCancel,
  } = useInstallChromePwa();

  if (isInstalled || isMobile || isSafari || hidden || !installEvent) {
    return <></>;
  }

  return (
    <div className="flex flex-col w-[300px] p-4 bg-white fixed top-16 right-8 z-999 rounded-lg shadow-atobi">
      <span className="text-focus">
        {t('Stay in the loop')}, {t('Install the App')}
      </span>
      <div className="flex w-full mt-5 text-sm">
        <button
          className="p-3 py-2 bg-focus rounded text-white"
          onClick={handleOnInstall}
        >
          {t('Install')}
        </button>
        <button
          className="p-3 py-2 bg-gray rounded ml-5 text-focus"
          onClick={onCancel}
        >
          {t('Remind me later')}
        </button>
      </div>
    </div>
  );
};

export default ChromePopup;
