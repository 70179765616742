import { useNode } from '@craftjs/core';
import { cx } from '@emotion/css';
import useIsEditorEnabled from 'app/hooks/useIsEditorEnabled';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { Dispatch, SetStateAction, useEffect } from 'react';

import CategoryDropdown from '../components/CategoryDropdown';
import Completion from '../components/Completion';
import Deadline from '../components/Deadline';
import Mandatory from '../components/Mandatory';
import Privacy from '../components/Privacy';
import Recurring from '../components/Recurring';
import TaskType from '../components/TaskType';
import Title from '../components/Title';

import { SimpleTaskProps } from '.';

interface EditorUserModeProps {
  setBackspace: Dispatch<SetStateAction<boolean>>;
  setHasFocus: Dispatch<SetStateAction<boolean>>;
  setProp: (cb: any, throttleRate?: number | undefined) => void;
}

const EditorUserMode = ({
  setHasFocus,
  setProp,
  setBackspace,
}: EditorUserModeProps) => {
  const { t } = useArticlesTranslation();
  const { enabled } = useIsEditorEnabled();

  const { selected, simpleTaskProps } = useNode((node) => ({
    selected: node.events.selected,
    simpleTaskProps: node.data.props as SimpleTaskProps,
  }));

  const getDescriptionPlaceholder = () => {
    switch (simpleTaskProps.type) {
      case 'simple_task':
        return `${t('Type what this task is about here')}*`;
      case 'yes_no_task':
        return `${t('Type your yes/no question here')}*`;
      case 'media_task':
        return `${t('Type what you need pictures of here')}*`;
      case 'open_question_task':
        return `${t('Type your open question here')}*`;
      default:
        return `${t('Description')}* `;
    }
  };

  useEffect(() => {
    if (simpleTaskProps.mandatory === undefined)
      setProp((props: SimpleTaskProps) => {
        props.mandatory = true;
      });
  }, [simpleTaskProps.mandatory, setProp]);

  return (
    <div className="flex justify-between m-2 p-3 rounded border border-gray-light shadow-action">
      <div className="flex flex-col w-full leading-none">
        <div className="flex">
          <TaskType
            type={simpleTaskProps.type}
            disabled={!enabled}
            onClick={(value) =>
              setProp((props: SimpleTaskProps) => {
                props.type = value;
              })
            }
          />
          <div className="flex flex-col flex-auto mr-4">
            <Title
              onFocusChange={(focus) => setHasFocus(focus)}
              className={cx(
                '[&:not(:empty)]:mb-2 font-bold placeholder:font-normal',
                {
                  hidden: !selected && !simpleTaskProps.title,
                }
              )}
              description={simpleTaskProps.title}
              enabled={enabled}
              placeholder={`${t('Title (optional)')} `}
              onChange={(value) =>
                setProp((props: SimpleTaskProps) => {
                  props.title = value;
                })
              }
            />
            <Title
              onFocusChange={(focus) => setHasFocus(focus)}
              placeholder={getDescriptionPlaceholder()}
              description={simpleTaskProps.description}
              error={!selected && !simpleTaskProps.description}
              enabled={enabled}
              onChange={(value) =>
                setProp((props: SimpleTaskProps) => {
                  props.description = value;
                })
              }
            />
          </div>
        </div>
        <div className="flex items-center text-gray-dark mt-2.5">
          {(simpleTaskProps.schedule?.frequency === 'once' ||
            !simpleTaskProps.schedule) && (
            <Deadline
              disabled={!enabled}
              selected={simpleTaskProps.deadline}
              onChange={(value) =>
                setProp((props: SimpleTaskProps) => {
                  props.deadline = value ?? undefined;
                  props.schedule = undefined;
                })
              }
            />
          )}

          <Recurring
            disabled={!enabled}
            scheduleDate={simpleTaskProps.schedule}
            onChange={(date) =>
              setProp((props: SimpleTaskProps) => {
                props.schedule = date;
                props.deadline = undefined;
              })
            }
          />

          <CategoryDropdown
            disabled={!enabled}
            onOpenCallback={setBackspace}
            selectedCategories={simpleTaskProps.categories}
            onChange={(category) =>
              setProp((props: SimpleTaskProps) => {
                props.categories = category ? [category] : [];
              })
            }
          />
        </div>
      </div>
      <div className="flex flex-col justify-end">
        <div className="flex items-center ml-auto text-gray-dark gap-1.5">
          <Privacy
            disabled={!enabled}
            isPublic={simpleTaskProps.public}
            onClick={(value) =>
              setProp((props: SimpleTaskProps) => {
                props.public = value;
              })
            }
          />
          <div className="bg-gray-light h-4 w-[1px]" />
          <Completion
            disabled={!enabled}
            required={simpleTaskProps.required}
            onClick={(value) =>
              setProp((props: SimpleTaskProps) => {
                props.required = value;
              })
            }
          />
          <div className="bg-gray-light h-4 w-[1px]" />
          <Mandatory
            disabled={!enabled}
            checked={!!simpleTaskProps.mandatory}
            onChange={() =>
              setProp((props: SimpleTaskProps) => {
                props.mandatory = !simpleTaskProps.mandatory;
              })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default EditorUserMode;
