import { cx } from '@emotion/css';
import { useSelector } from 'app/hooks';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import RecurringArticleModal from 'app/pages/Articles/Components/RecuringArticleModal';
import useRecurringDateAsSchedule from 'app/pages/Editor/hooks/useRecurringDateAsSchedule';
import { selectors } from 'app/store/editor';
import { Repeat } from 'iconsax-react';
import { useState } from 'react';
import {
  ActionSchedule,
  ArticleShownAs,
} from 'submodules/common-ui/generated/api/gcs';

interface Props {
  disabled?: boolean;
  scheduleDate?: ActionSchedule | null;
  onChange: (date?: ActionSchedule) => void;
}

const Recurring = ({ scheduleDate, onChange, disabled }: Props) => {
  const { t } = useArticlesTranslation();
  const { getRecurringDate, getScheduleDate, getFormattedDate } =
    useRecurringDateAsSchedule();

  const shownAs = useSelector(selectors.getShownAs);

  const [isOpen, setIsOpen] = useState(false);

  if (shownAs.value === ArticleShownAs.Training) return null;

  const existingRecurringDate = scheduleDate
    ? getRecurringDate(scheduleDate)
    : undefined;

  return (
    <>
      <button
        disabled={disabled}
        onClick={() => setIsOpen(!isOpen)}
        className={cx('flex flex-row items-center text-xs mr-2 ', {
          'text-grayscale-secondary': !!existingRecurringDate,
          'hover:text-grayscale-secondary': !existingRecurringDate && !disabled,
        })}
      >
        <Repeat className="mr-1" size={16} />
        {existingRecurringDate ? (
          <>{`${existingRecurringDate.recurring.label}; ${t(
            'Actions available {{startDate}}-{{endDate}}',
            {
              startDate: getFormattedDate(existingRecurringDate.start),
              endDate: getFormattedDate(existingRecurringDate.end),
            }
          )}`}</>
        ) : (
          t('Recurring')
        )}
      </button>
      {isOpen && (
        <RecurringArticleModal
          existingRecurringDate={existingRecurringDate}
          onClose={() => setIsOpen(false)}
          onCancel={() => {
            onChange(undefined);
            setIsOpen(false);
          }}
          onSubmit={(date) => {
            onChange(getScheduleDate(date));
            setIsOpen(false);
          }}
        />
      )}
    </>
  );
};

export default Recurring;
