import { cx } from '@emotion/css';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import useUserQuery from 'app/pages/profiles/PublicProfile/useUserQuery';
import dayjs from 'app/utils/dayjs';
import removeTimezone from 'app/utils/removeTimezone';
import { ArrowLeft, ArrowRight, Trash } from 'iconsax-react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { useCurrentSelectedInstance } from '../ActionSettings/context';

import DatePickerInput from './DatePickerInput';
import DatePickerTimeInput from './DatePickerTimeInput';

interface DeadlineProps {
  selected?: string;
  disabled?: boolean;
  onChange: (date: string | null) => void;
  isRecurring?: boolean | null;
  recurringText?: string;
}

const Deadline = ({
  selected,
  disabled,
  onChange,
  isRecurring,
  recurringText,
}: DeadlineProps) => {
  const { t } = useArticlesTranslation();
  const { colleagueId, answeredByColleague } = useCurrentSelectedInstance();

  const { data: colleagueProfile } = useUserQuery({
    id: colleagueId || 0,
    enabled: colleagueId !== undefined,
  });

  return (
    <ReactDatePicker
      disabled={disabled}
      selected={
        selected
          ? dayjs(removeTimezone(selected)).toDate()
          : dayjs().hour(18).toDate()
      }
      minDate={dayjs().toDate()}
      showPopperArrow={false}
      shouldCloseOnSelect={false}
      onChange={(date) => {
        const apiDate = removeTimezone(date);
        onChange(apiDate ?? null);
      }}
      showTimeInput
      timeInputLabel=""
      customTimeInput={<DatePickerTimeInput date={selected} />}
      customInput={
        <DatePickerInput
          className={cx({
            'hover:text-grayscale-secondary': !disabled,
          })}
          disabled={disabled}
          date={selected}
          isRecurring={isRecurring}
          text={isRecurring ? recurringText : `${t('No deadline')}`}
          answeredByColleague={answeredByColleague}
          colleagueProfile={colleagueProfile}
        />
      }
      renderCustomHeader={({
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div className="flex justify-between bg-white px-5">
          <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            <ArrowLeft size={14} />
          </button>
          <div className="flex">
            <span>{dayjs(date).format('MMMM')}</span>
            &nbsp;
            <span>{dayjs(date).format('YYYY')}</span>
          </div>

          <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            <ArrowRight size={14} />
          </button>
        </div>
      )}
    >
      {selected && (
        <button
          className="flex items-center h-10 w-full hover:bg-hover-blue text-error text-sm font-custom rounded-sm"
          onClick={() => onChange(null)}
        >
          <Trash size={20} className="mx-2" />
          <span>{t('Remove deadline')}</span>
        </button>
      )}
    </ReactDatePicker>
  );
};

export default Deadline;
