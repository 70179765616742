import { setRequestInstance, setRequestToken } from 'app/api';
import { setRequestHeaders as setAiServiceRequestHeaders } from 'app/api/aiServiceRequest';
import { setRequestHeaders as setGCSRequestHeaders } from 'app/api/gcsRequest';
import { setRequestHeaders as setIdentityRequestHeaders } from 'app/api/identityRequest';
import { setRequestHeaders as setSubdomainRequestHeaders } from 'app/api/subdomainRequest';
import { useSelector, useDispatch } from 'app/hooks';
import { selectors } from 'app/store/auth';
import { actions } from 'app/store/request';
import dayjs from 'app/utils/dayjs';
import { ReactNode, useEffect } from 'react';

interface Props {
  children: ReactNode;
}

const isExpired = (expiresAt: number) => dayjs().isAfter(dayjs(expiresAt));

const RequestInstanceHandler = ({ children }: Props) => {
  const tenant = useSelector(selectors.getSelectedTenant);
  const token = useSelector(selectors.getToken);
  const authTokenExpiresAt = useSelector(selectors.getExpiry);
  const dispatch = useDispatch();

  useEffect(() => {
    if (tenant?.url) {
      setRequestInstance(tenant.url);
    }
  }, [tenant]);

  useEffect(() => {
    if (token && authTokenExpiresAt && !isExpired(authTokenExpiresAt)) {
      setRequestToken(token);
      setGCSRequestHeaders({ token });
      setSubdomainRequestHeaders({ token });
      setAiServiceRequestHeaders({ token });
      setIdentityRequestHeaders({ token });
    }
  }, [authTokenExpiresAt, token]);

  useEffect(() => {
    if (
      token &&
      tenant &&
      authTokenExpiresAt &&
      !isExpired(authTokenExpiresAt)
    ) {
      dispatch(actions.requestInstanceUpdated(true));
    }
  }, [authTokenExpiresAt, dispatch, tenant, token]);

  return <>{children}</>;
};

export default RequestInstanceHandler;
