import { useEditor } from '@craftjs/core';
import styled from '@emotion/styled';
import useGenerateBlockMutation from 'app/api/aiService/hooks/useGenerateBlockMutation';
import ActionToast from 'app/components/Toast/ActionToast';
import {
  useArticlesTranslation,
  useCommonTranslation,
} from 'app/internationalization/hooks';
import QuizBlock from 'app/pages/ArticleEditor/components/blocks/tasks/Quiz';
import { selectors } from 'app/store/editor';
import Spinner from 'assets/icons/spinner.svg?react';
import { ArrowLeft2, InfoCircle } from 'iconsax-react';
import capitalize from 'lodash/capitalize';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  ActionTypeEnum,
  MultiChoiceBlockRenderTypeEnum,
  UncompletedActionChoice,
} from 'submodules/common-ui/generated/api/gcs';

import BlockTypes from '../../helpers/constants';
import useMapNewArticle from '../../hooks/useMapNewArticle';

let skeletonId: null | string = null;

const Prompt = ({
  selectedBlock,
  goBack,
  onClose,
}: {
  selectedBlock: string;
  goBack: () => void;
  onClose: () => void;
}) => {
  const { t } = useArticlesTranslation();
  const { t: ct } = useCommonTranslation();
  const [customPrompt, setCustomPrompt] = useState('');

  const currentLanguage = useSelector(selectors.getActiveLanguage);

  const {
    actions: { addNodeTree, delete: deleteNode },
    query: { parseReactElement },
    nodes,
  } = useEditor((state) => ({ nodes: state.nodes }));

  const { nodesToNewArticle } = useMapNewArticle(nodes);

  const addQuizSkeleton = () => {
    const block = parseReactElement(
      <QuizBlock
        type={ActionTypeEnum.MultiChoice}
        required={-1}
        public={true}
        completed={false}
        audiences={[]}
        translationStatus="draft"
        renderType={MultiChoiceBlockRenderTypeEnum.Quiz}
        isSkeleton={true}
      />
    ).toNodeTree();

    skeletonId = block.rootNodeId;

    addNodeTree(block, 'dropableRegion');
  };

  const addQuiz = (response: any) => {
    const quiz = response.data.blocks[0];

    const taskWithAudiences = Object.keys(nodes).find(
      (key) =>
        nodes[key].data.displayName === BlockTypes.QuizBlock ||
        nodes[key].data.displayName === BlockTypes.SimpleTaskBlock ||
        nodes[key].data.displayName === BlockTypes.YesNoNaBlock
    );
    const assigneeAudiences = taskWithAudiences
      ? nodes[taskWithAudiences].data.props.audiences
      : [];

    const block = parseReactElement(
      <QuizBlock
        type={ActionTypeEnum.MultiChoice}
        required={-1}
        public={true}
        completed={false}
        audiences={assigneeAudiences}
        translationStatus="draft"
        renderType={MultiChoiceBlockRenderTypeEnum.Quiz}
        title={quiz.variants[currentLanguage]?.title}
        description={quiz.variants[currentLanguage]?.description}
        explanation={quiz.variants[currentLanguage]?.explanation}
        choices={quiz.choices as UncompletedActionChoice[]}
      />
    ).toNodeTree();

    addNodeTree(block, 'dropableRegion');
  };

  const showToast = (title: string, onClick: () => void) =>
    toast(<ActionToast text={title} onClick={onClick} />, {
      position: 'bottom-right',
      autoClose: 8000,
      closeButton: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      containerId: 'GenerateToastId',
    });

  const { mutate: generate, isLoading: isGenerating } =
    useGenerateBlockMutation(addQuiz, () =>
      showToast(
        t('{{block}} block generation failed.', {
          block: capitalize(selectedBlock),
        }),
        toast.dismiss
      )
    );

  const selectedBlockTitle = () => {
    if (selectedBlock === 'quiz') {
      return t('Generate Quiz');
    }
  };

  const onPromptChange = (value: string) => {
    setCustomPrompt(value);
  };

  const onSubmitPrompt = () => {
    addQuizSkeleton();
    const article = nodesToNewArticle();

    generate(
      {
        data: {
          language: currentLanguage,
          article: article,
          prompt: customPrompt,
          blockType: 'quiz',
        },
      },
      {
        onSuccess: () => {
          if (skeletonId) deleteNode(skeletonId);
          skeletonId = null;
          onClose();
          showToast(t('Quiz block successfully generated'), toast.dismiss);
        },
      }
    );
  };

  if (isGenerating) {
    return (
      <StyledButton className="flex translate-x-[-36px] bottom-3">
        <span>{t('Generating...')} 🪄</span>
        <Spinner className="w-4.5 h-4.5 animate-spin" />
      </StyledButton>
    );
  }

  return (
    <div className="translate-x-[-36px] absolute bottom-3 w-xl max-w-screen text-grayscale-primary bg-white border border-focus-background rounded-xl shadow-block">
      <div className="px-4 py-2.5 flex items-center justify-between border-b border-gray-light">
        <div className="flex items-center gap-1 text-xs">
          <button type="button" onClick={goBack}>
            <ArrowLeft2 size={13} />
          </button>
          {selectedBlockTitle()}{' '}
          <span className="text-focus bg-hover-blue border border-focus-background rounded-xl px-2 py-0.5">
            {ct('Beta')}
          </span>
        </div>
        <div className="flex items-center text-gray-light-blue text-10 gap-0.5">
          <InfoCircle size={10} />
          {t('Ai outputs can be misleading or wrong')}
        </div>
      </div>
      <div>
        <textarea
          placeholder={`🪄 ${t(
            'Would you like to add some context? (Optional)'
          )}`}
          onChange={(event) => onPromptChange(event.target.value)}
          className="text-sm text-black placeholder-grayscale-secondary w-full p-4 resize-none overflow-hidden outline-none"
        ></textarea>
        <button
          type="button"
          onClick={onSubmitPrompt}
          className="text-sm bg-light absolute right-4 bottom-4 rounded border border-focus-background py-1 px-2 font-normal text-focus"
        >
          {t('Generate')}
        </button>
      </div>
    </div>
  );
};

const StyledButton = styled.div`
  line-height: 1;
  padding: 16px;
  border-radius: 26px;
  color: #b549d0;
  border: 1px rgba(181, 73, 208, 0.15) solid;
  box-shadow: 0px 2px 6px -2px rgba(16, 24, 40, 0.03);
  background: linear-gradient(270deg, #e8d8ec, #eaeaea, #c48bdf);
  background-size: 600% 600%;

  animation: GradientLoading 5s ease infinite;

  @keyframes GradientLoading {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

export default Prompt;
