import { cx } from '@emotion/css';
import useUserQuery from 'app/pages/profiles/PublicProfile/useUserQuery';
import { Shop } from 'iconsax-react';
import capitalize from 'lodash/capitalize';
import { ActionAnswerResponse } from 'submodules/common-ui/generated/api/gcs';

interface Props {
  answer: ActionAnswerResponse;
}
const YesNoAnswer = ({ answer }: Props) => {
  const { data: user } = useUserQuery({ id: answer.userId });

  return (
    <div className="flex flex-col p-2 border border-grayscale-bg-dark rounded-lg gap-1 bg-white">
      <div className="flex gap-2">
        <div className="w-7 h-7 rounded bg-hover-blue flex items-center justify-center">
          {user?.avatars?.small ? (
            <img className="rounded-[10px]" src={user.avatars.small} alt="" />
          ) : (
            <Shop className="w-4 h-4 text-ceil" />
          )}
        </div>

        <div className="w-full flex flex-col gap-1">
          <span className="text-grayscale-primary text-sm">
            {user?.firstName} {user?.lastName}
          </span>

          <div className="flex gap-1 items-center text-grayscale-secondary text-sm">
            <span>{user?.profession.name}</span>
            <span className="w-0.5 h-0.5 bg-gray-dark rounded-full" />
            <span>{user?.location?.name}</span>
          </div>
        </div>
      </div>

      <div
        className={cx(
          'flex items-center h-10 w-full border rounded bg-grayscale-bg-dark relative border-gray-light'
        )}
      >
        <span className="text-sm text-hover-primary ml-2">
          {capitalize(answer.answer as string)}
        </span>
      </div>
    </div>
  );
};

export default YesNoAnswer;
