import { useMissionQuery } from 'app/api/missions/hooks';
import { useParams } from 'react-router-dom';

import DefaultBreadcrumb from './DefaultBreadcrumb';

const MissionBreadcrumb = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = useMissionQuery({
    id: Number(id),
    enabled: !Number.isNaN(Number(id)),
  });

  return <DefaultBreadcrumb to={window.location.href} title={data?.name} />;
};

export default MissionBreadcrumb;
