import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import { hooks } from 'app/api/auth';
import { SystemModules } from 'app/api/auth/constants';
import { NotificationFilter } from 'app/api/notifications/constants';
import { useNotificationsQuery } from 'app/api/notifications/hooks';
import { useSelector } from 'app/hooks';
import useLoginMethod from 'app/hooks/useLoginMethod';
import { useComponentsTranslation } from 'app/internationalization/hooks';
import Search from 'app/pages/Home/components/Search';
import { outsideRoutes } from 'app/router';
import { selectors } from 'app/store/auth';
import { createDeprecatedAtobiURL } from 'app/utils';
import { UserOctagon } from 'iconsax-react';
import React, { useState, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useLocation } from 'react-router-dom';
import ArrowDownIcon from 'remixicon-react/ArrowDownSLineIcon';

import Breadcrumbs from '../Breadcrumbs';

import NotificationsIcon from './NotificationsIcon';
import NotificationsPopup from './NotificationsPopup';
import UserMenu from './UserMenu';

const { useAuthenticatedUser } = hooks;

export const MENU_HEIGHT = '56px';

const MenuContainer = styled.div`
  height: ${MENU_HEIGHT};
`;

interface TopMenuProps {
  toggleSidebar: () => void;
  isMobile: boolean;
}

const TopMenu: React.FC<TopMenuProps> = () => {
  const { data, logout } = useAuthenticatedUser();
  const [displayNotifications, setDisplayNotifications] = useState(false);
  const [isUserMenuVisible, setIsUserMenuVisible] = useState(false);
  const tenant = useSelector(selectors.getSelectedTenant);
  const [notificationFilter, setNotificationFilter] = useState(
    NotificationFilter.unread
  );
  const { t } = useComponentsTranslation();
  const { data: notifications, isLoading: isNotificationsLoading } =
    useNotificationsQuery(notificationFilter, Boolean(data));

  const location = useLocation();
  useEffect(() => {
    setIsUserMenuVisible(false);
  }, [location]);
  const { isEasyAccessLogin } = useLoginMethod();

  // prevent flickering
  if (!data) return <MenuContainer className="w-full bg-white" />;

  return (
    <div className="rounded-t-[14px] text-black px-3 relative z-40 bg-white h-[52px] flex items-center transition-all duration-300 w-full border-b-[0.5px] border-gray-silver-blue">
      <div className="font-bold text-lg flex items-center">
        <Breadcrumbs />
      </div>
      <div className="relative items-center ml-auto flex">
        {isEasyAccessLogin && (
          <div className="flex text-sm items-center text-grayscale-secondary mr-4">
            {t('Limited access')}
          </div>
        )}
        {data.isAdmin && (
          <a
            className="flex text-sm items-center text-grayscale-primary py-1.5 px-3 hover:text-focus rounded-lg hover:bg-hover-blue"
            target="_blank"
            href={outsideRoutes.adminDashboard.create(
              createDeprecatedAtobiURL(String(tenant?.url))
            )}
            rel="noreferrer"
          >
            <UserOctagon size={24} className="mr-1" />
            {t('Switch to Admin')}
          </a>
        )}
        <Search iconClassName="self-center hover:text-focus hover:bg-hover-blue rounded-lg p-2" />
        {!data.hiddenModules?.[SystemModules.Notifications] &&
          !isEasyAccessLogin && (
            <div className="flex items-center">
              <OutsideClickHandler
                onOutsideClick={() => setDisplayNotifications(false)}
              >
                <button
                  type="button"
                  onClick={() => {
                    setDisplayNotifications((prev) => !prev);
                  }}
                  className={cx(
                    'group hover:bg-focus-background w-10 h-10 flex items-center justify-center rounded-lg',
                    {
                      'bg-focus-background': displayNotifications,
                    }
                  )}
                >
                  <NotificationsIcon
                    isActive={displayNotifications}
                    hasUnread={Boolean(
                      notifications?.find(({ read }) => !read)
                    )}
                  />
                </button>
                {displayNotifications && (
                  <NotificationsPopup
                    notifications={notifications}
                    isLoading={isNotificationsLoading}
                    filter={notificationFilter}
                    onFilterChange={() =>
                      setNotificationFilter((prev) => {
                        if (prev === NotificationFilter.all)
                          return NotificationFilter.unread;

                        return NotificationFilter.all;
                      })
                    }
                    onClose={() => setDisplayNotifications(false)}
                  />
                )}
              </OutsideClickHandler>
            </div>
          )}
        <div className="relative py-1 px-1.5 hover:bg-hover-blue rounded-lg ml-1">
          <OutsideClickHandler
            onOutsideClick={() => setIsUserMenuVisible(false)}
          >
            <button
              onClick={() => setIsUserMenuVisible((prevState) => !prevState)}
              type="button"
              className="flex items-center hover:text-focus"
            >
              <img
                src={data.avatars.small}
                alt="User avatar"
                className="w-8 h-8 rounded-lg bg-gray-dark mr-1"
              />
              <ArrowDownIcon className="w-4" />
            </button>
            {isUserMenuVisible && <UserMenu logout={logout} name={data.name} />}
          </OutsideClickHandler>
        </div>
      </div>
    </div>
  );
};

export default TopMenu;
