import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import config from 'app/config';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { Profile2User, Speedometer } from 'iconsax-react';

import { Category } from './types';
import useResolveSentiment from './useResolveSentiment';

interface SummaryCategoryProps {
  className?: string;
  containerClassName?: string;
  category: Category;
  onCategorySelected?: (category: Category) => void;
}

const SummaryCategory = ({
  className,
  containerClassName,
  category,
  onCategorySelected,
}: SummaryCategoryProps) => {
  const resolveSentiment = useResolveSentiment();
  const { t } = useArticlesTranslation();

  return (
    <div key={category.name} className={containerClassName}>
      <div
        className={cx(
          'rounded-lg px-3 py-2.5 gap-y-1.5 flex flex-col',
          className
        )}
      >
        <div className="flex items-center justify-between">
          <h2 className="font-bold text-sm leading-5">{category.name}</h2>
          {onCategorySelected && (
            <span className="flex items-center rounded text-gray-silver bg-gray-white-shadow py-1.5 pl-2.5 pr-2">
              <Profile2User size={12} className="mr-1.5" />
              <button
                type="button"
                className="text-10"
                onClick={() => onCategorySelected?.(category)}
              >
                {t('{{totalAnswers}} answers', {
                  totalAnswers: category.answers.length,
                })}
              </button>
            </span>
          )}
        </div>
        <div className="text-xs flex items-center">
          <Speedometer size={16} className="mr-1" />
          <span className="mr-1">{t('Sentiment')}: </span>
          <span
            className={cx(
              'group relative',
              resolveSentiment(category.sentimentScore).color
            )}
          >
            {resolveSentiment(category.sentimentScore).sentiment}
            <Tooltip className="!rounded !opacity-100 top-6 left-6 bg-gray-extra-dark absolute z-999 hidden group-hover:inline-block text-white text-sm px-4 py-2 w-[283px] whitespace-normal text-center -translate-x-1/2">
              {resolveSentiment(category.sentimentScore).tooltip}
            </Tooltip>
          </span>
        </div>
        <div className="text-sm">
          <div>{t('Takeaways')}:</div>
          <ul className="list-disc list-inside pl-2">
            {category.takeaways.map((takeaway) => (
              <li key={takeaway}>{takeaway}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const Tooltip = styled.div`
  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 50%;
    border-width: 5px;
    border-style: solid;
    transform: translateX(-50%);
    border-color: transparent transparent ${config.colors['gray-extra-dark']}
      transparent;
  }
`;
export default SummaryCategory;
