const isValidURL = (urlTest: string) => {
  const protocol = 'https://';
  const domain = '[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.';
  const topLevelDomain = '[a-zA-Z0-9()]{1,6}';
  const path = '([-a-zA-Z0-9()@:%_+.~#?&//=]*)?';
  const expressionUrl = `^${protocol}${domain}${topLevelDomain}\\b${path}$`;
  const expression = new RegExp(expressionUrl, 'i');
  return expression.test(urlTest);
};
export default isValidURL;
