import { useArticlesTranslation } from 'app/internationalization/hooks';
import { actions } from 'app/store/modal';
import { useDispatch } from 'react-redux';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';

const ModalHeader = () => {
  const dispatch = useDispatch();
  const { t } = useArticlesTranslation();

  const onClose = () => dispatch(actions.hideModal());

  return (
    <div className="relative flex flex-col h-full">
      <label
        className="block font-bold text-grayscale-primary text-base mb-1"
        htmlFor="collaborator"
      >
        {t('Language Settings')}
      </label>
      <span className="text-sm text-grayscale-secondary">
        {t('Choose the languages you want the article to be translated to.')}
      </span>
      <CloseLineIcon
        className="text-grayscale-secondary absolute right-0 top-0 cursor-pointer"
        size={24}
        onClick={onClose}
      />
    </div>
  );
};

export default ModalHeader;
