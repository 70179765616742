import { useAuthenticatedUser } from 'app/api/auth/hooks';
import { ComplaintTypes } from 'app/api/complaints/constants';
import { ControlsPopup } from 'app/components';
import useCommentTranslation from 'app/internationalization/hooks/useCommentTranslation';
import { EmojiEnum } from 'submodules/common-ui/generated/api/gcs';

interface Props {
  commentId: number;
  createdBy: number;
  fullName: string;
  isParentComment: boolean;
  onReact(emoji: EmojiEnum): void;
  onReply(): void;
  onDeleteComment(number: number): void;
  onEditComment(number: number): void;
}

const CommentPopup = ({
  commentId,
  createdBy,
  fullName,
  isParentComment,
  onReact,
  onReply,
  onDeleteComment,
  onEditComment,
}: Props) => {
  const { t } = useCommentTranslation();

  const { data: currentUser } = useAuthenticatedUser();

  const currentUserComment = currentUser?.id === createdBy;
  const complainType = isParentComment
    ? ComplaintTypes.articleComment
    : ComplaintTypes.articleCommentReply;

  const onDelete = () => onDeleteComment(commentId);
  const onEdit = () => onEditComment(commentId);

  return (
    <ControlsPopup
      commentId={commentId}
      complaintType={complainType}
      createdByMe={currentUserComment}
      deleteLabel={t('Delete')}
      deleteModalDescription={
        isParentComment
          ? t('Are you sure? Your comment and replies to it will be deleted.')
          : undefined
      }
      deleteModalTitle={
        isParentComment ? t('Delete Comment?') : t('Delete Reply?')
      }
      editLabel={t('Edit')}
      hideLabel={t('Hide Comment')}
      hideDescription={
        isParentComment
          ? t('You won’t see the comment anymore.')
          : t('You won’t see the reply anymore.')
      }
      reactLabel={t('Add reaction')}
      replyLabel={t('Reply')}
      showContextMenu={false}
      userCommentId={createdBy}
      userName={fullName}
      onDelete={onDelete}
      onDisplayEditor={onEdit}
      onReact={onReact}
      onReply={isParentComment ? onReply : undefined}
    />
  );
};

export default CommentPopup;
