import { cx } from '@emotion/css';
import { HTMLAttributes } from 'react';

const TablePageContainer = ({
  children,
  className,
}: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cx(
      'flex flex-col flex-1 md:py-4 px-0 md:px-6 h-full max-w-full md:duration-300 overflow-scroll',
      className
    )}
  >
    {children}
  </div>
);

export default TablePageContainer;
