import { useEditor } from '@craftjs/core';
import { cx } from '@emotion/css';
import { useSelector } from 'app/hooks';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import ArticleEngagement from 'app/pages/Articles/Components/ArticleEngagement';
import useUIArticleLanguage from 'app/pages/Articles/hooks/useUIArticleLanguage';
import routes from 'app/router/routes';
import { actions as commentActions } from 'app/store/comments';
import { selectors } from 'app/store/editor';
import { actions as modalActions } from 'app/store/modal';
import isIframed from 'app/utils/isIframed';
import ChaptersIcon from 'assets/icons/chapters.svg?react';
import MessageIcon from 'assets/icons/message.svg?react';
import { ArrowLeft2, ChartSquare } from 'iconsax-react';
import throttle from 'lodash/throttle';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { TextBlock } from 'submodules/common-ui/generated/api/gcs';
import { Drawer } from 'vaul';

import useCurrentArticleLanguage from '../hooks/useCurrentArticleLanguage';

import TableContents from './TableContents';
import DropdownButton from './TopBar/DropdownButton';
import LanguageDropdown from './TopBar/LanguageDropdown';
import TranslateButton from './TranslateButton';

interface PreviewNavigationStatus {
  offset: number;
  visible: boolean;
}

interface MenuAction {
  active: boolean;
  icon: JSX.Element;
  tip: string;
  onClick: () => void;
}

interface PreviewNavigationProps {
  onShowTableContents?(value: boolean): void;
}

const PreviewNavigation = ({ onShowTableContents }: PreviewNavigationProps) => {
  const isMobile = window.matchMedia('(max-width: 768px)').matches;
  const { mode } = useParams<{ mode: string }>();
  const { t } = useArticlesTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [showTableContents, setShowTableContents] = useState(false);
  const [showEngagement, setShowEngagement] = useState<boolean | null>(null);
  const [tableContentsBlocks, setTableContentsBlocks] = useState<TextBlock[]>(
    []
  );
  const [previewNavigationStatus, setPreviewNavigationStatus] =
    useState<PreviewNavigationStatus>({
      offset: 0,
      visible: true,
    });

  const article = useSelector(selectors.getArticle);
  const showComments = useSelector(({ comments }) => comments.isVisible);

  const isReview = mode === 'review';
  const currentLanguageCode = useCurrentArticleLanguage() ?? 'en';

  const { getMainLanguage } = useUIArticleLanguage();
  const mainLanguage = getMainLanguage(article?.languages);

  const language = isReview ? currentLanguageCode : mainLanguage;

  const {
    actions: { selectNode },
  } = useEditor();

  const onScroll = useMemo(
    () =>
      throttle(() => {
        const offset = window.scrollY;

        setPreviewNavigationStatus((prevHeaderStatus) => ({
          offset,
          visible: prevHeaderStatus.offset >= offset || offset <= 0,
        }));
      }, 200),
    []
  );

  const renderAction = (
    { active, icon, onClick, tip }: MenuAction,
    index: number
  ) => {
    return (
      <button
        key={index}
        className={cx(
          '[&_svg]:h-5 [&_svg]:w-5 transition-all',
          buttonClassName,
          {
            'bg-focus-background text-focus': active,
            'text-grayscale-primary hover:bg-hover-blue hover:text-focus':
              !active,
          }
        )}
        data-tip={tip}
        data-for="navigation-actions"
        onClick={onClick}
      >
        {icon}
      </button>
    );
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);

  useEffect(() => {
    if (!article) return;

    setTableContentsBlocks(
      (article?.blocks.filter(
        (block) =>
          block.type === 'text' &&
          block.variants?.[language]?.items.findIndex(
            (item) => item.type === 'heading'
          ) !== -1
      ) as TextBlock[]) || []
    );
  }, [article, language]);

  useEffect(() => {
    if (isReview) return;

    if (isMobile || tableContentsBlocks.length < 1)
      return setShowTableContents(false);

    setShowTableContents(true);
  }, [isMobile, tableContentsBlocks, isReview]);

  useEffect(() => {
    onShowTableContents?.(showTableContents);

    if (showTableContents) dispatch(commentActions.hideCommentModal());
  }, [dispatch, showTableContents, onShowTableContents]);

  useEffect(() => {
    if (!showComments) return;

    setShowTableContents(false);
  }, [showComments]);

  useEffect(() => {
    ReactTooltip.hide();
    ReactTooltip.rebuild();
  }, [showComments, showTableContents]);

  useEffect(() => {
    if (showEngagement === null) return;

    if (showEngagement) {
      dispatch(modalActions.modalOpened());
      return;
    }

    dispatch(modalActions.modalClosed());
  }, [showEngagement, dispatch]);

  const otherActions = [
    {
      active: !!showEngagement,
      icon: <ChartSquare className="[&_path]:stroke-current" />,
      tip: t('View engagement'),
      onClick: () => setShowEngagement((state) => !state),
    },
  ];

  const actions = [
    ...(tableContentsBlocks.length > 0
      ? [
          {
            active: showTableContents,
            icon: <ChaptersIcon className="[&_path]:fill-current" />,
            tip: showTableContents
              ? t('Hide Table of Contents')
              : t('Show Table of Contents'),
            onClick: () => {
              setShowTableContents((prev) => !prev);
              selectNode(undefined);
            },
          },
        ]
      : []),
    ...(article?.isReactingAllowed && !isReview
      ? [
          {
            active: showComments,
            icon: <MessageIcon className="[&_path]:stroke-current" />,
            tip: showComments ? t('Hide comments') : t('Show comments'),
            onClick: () => {
              !showComments
                ? dispatch(commentActions.showCommentModal())
                : dispatch(commentActions.hideCommentModal());

              selectNode(undefined);
            },
          },
        ]
      : []),
  ];
  const buttonClassName =
    'flex justify-center items-center w-[34px] h-[34px] rounded-[10px]';

  const renderTableContents = () => (
    <TableContents
      blocks={tableContentsBlocks}
      language={language}
      title={article?.variants[language]?.title}
      onClose={() => setShowTableContents(false)}
    />
  );

  return (
    <>
      <header
        className={cx(
          'fixed top-0 left-0 w-full py-2.5 px-4 lg:px-8 text-grayscale-primary bg-white border-b border-b-gray-light z-10 transition-all duration-500',
          {
            '-translate-y-full opacity-0': !previewNavigationStatus.visible,
          }
        )}
      >
        <nav className="flex justify-between items-center">
          {!isIframed && (
            <button
              className={cx(
                'border border-gray-light shadow-[0_1px_2px_0px_rgba(16,24,40,0.05)]',
                buttonClassName
              )}
              onClick={() => {
                if (article?.channel?.id && location.key === 'default') {
                  return navigate(
                    routes.relevantChannel.create(article?.channel?.id),
                    { replace: true }
                  );
                }
                navigate(-1);
              }}
            >
              <ArrowLeft2 size={20} />
            </button>
          )}
          {isReview && (
            <span className="ml-3 mr-auto text-sm font-bold text-grayscale-primary">
              {article?.variants?.[currentLanguageCode]?.title}
            </span>
          )}
          <div className="flex gap-0.5">
            <div className="flex items-center gap-0.5">
              {!isReview &&
                otherActions.map((action, index) =>
                  renderAction(action, index)
                )}
            </div>
            <div className="flex items-center gap-0.5">
              {isMobile && tableContentsBlocks.length > 0 && (
                <Drawer.Root
                  modal={false}
                  open={showTableContents}
                  onOpenChange={setShowTableContents}
                >
                  <Drawer.Portal>
                    <Drawer.Overlay
                      className="fixed inset-0 bg-grayscale-primary/50 z-50"
                      onClick={() => setShowTableContents(false)}
                    />
                    <Drawer.Content className="fixed bottom-0 left-0 right-0 flex flex-col outline-none z-50">
                      {renderTableContents()}
                    </Drawer.Content>
                  </Drawer.Portal>
                </Drawer.Root>
              )}
              {actions.map(renderAction)}
              {isReview && (
                <div
                  className="flex border-gray-light h-[34px]"
                  role="presentation"
                  onClick={() => setShowTableContents(false)}
                >
                  <DropdownButton
                    tooltipText={t('Switch and manage languages')}
                    text={currentLanguageCode}
                    disabled={false}
                  >
                    <LanguageDropdown
                      disabled={false}
                      selectedLanguageCode={currentLanguageCode}
                    />
                  </DropdownButton>
                </div>
              )}
              {!isReview && <TranslateButton size={20} />}
            </div>
          </div>
          {actions.length > 0 && (
            <ReactTooltip
              class="react-tooltip text-center whitespace-nowrap"
              effect="solid"
              id="navigation-actions"
              place="bottom"
            />
          )}
        </nav>
      </header>
      {!isMobile && showTableContents && renderTableContents()}
      {article?.id && (
        <ArticleEngagement
          postId={article.id}
          isOpen={!!showEngagement}
          onOpenChange={setShowEngagement}
        />
      )}
      {isMobile && (
        <div
          className={cx(
            'fixed inset-0 bg-grayscale-primary/50 transition-all z-50',
            {
              'opacity-100': showTableContents,
              'opacity-0 invisible': !showTableContents,
            }
          )}
          role="presentation"
          onClick={() => setShowTableContents(false)}
        ></div>
      )}
    </>
  );
};

export default PreviewNavigation;
