import { resolveSubdomain } from 'app/utils/subdomain';
import { useQuery } from 'react-query';

import { resources, queryKeys } from '../index';

import { CACHE_TIME } from './constants';

interface Props {
  enabled?: boolean;
}

const useGetSettingsQuery = ({ enabled = true }: Props) => {
  const subdomain = resolveSubdomain();

  const { data, isLoading, error, isFetching } = useQuery(
    queryKeys.settings(),
    () => resources.getSettings(subdomain),
    {
      refetchOnWindowFocus: false,
      enabled,
      staleTime: CACHE_TIME,
    }
  );

  return {
    data: data?.data,
    isFetching,
    isLoading,
    error,
  };
};

export default useGetSettingsQuery;
