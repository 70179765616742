import useGetArticleSharingStatusOverview from 'app/api/articleSharing/hooks/useGetArticleSharingStatusOverview';
import useGetSharingConnectionQuery from 'app/api/articleSharing/hooks/useGetSharingConnectionQuery';
import { Dispatch, SetStateAction } from 'react';

import MultiSelect, { SelectOption } from './MultiSelect';
import SharingStatusOverview from './SharingStatusOverview';

interface Props {
  articleOwner?: number;
  articleId: number;
  selectedTennants: SelectOption[];
  setSelectedTennants: Dispatch<SetStateAction<SelectOption[]>>;
}

const ForwardArticleTenant = ({
  articleOwner,
  articleId,
  selectedTennants,
  setSelectedTennants,
}: Props) => {
  const { data: sharingConnections, isLoading: isLoadingConnections } =
    useGetSharingConnectionQuery({});

  const { data: statusOverview, isLoading: isLoadingStatusOverview } =
    useGetArticleSharingStatusOverview({
      articleId,
    });

  const hasSharedTenants = statusOverview && statusOverview.length > 0;

  return (
    <>
      <MultiSelect
        isLoading={isLoadingConnections || isLoadingStatusOverview}
        sharingTenants={sharingConnections}
        statusOverview={statusOverview}
        selectedTennants={selectedTennants}
        setSelectedTennants={setSelectedTennants}
      />
      {articleOwner && (
        <>
          {hasSharedTenants && (
            <SharingStatusOverview
              statusOverview={statusOverview}
              sender={sharingConnections?.sourceName}
              articleId={articleId}
              articleOwner={articleOwner}
            />
          )}
        </>
      )}
    </>
  );
};

export default ForwardArticleTenant;
