export enum Role {
  RegionManager = 'rm',
  StoreManager = 'sm',
  CountryManager = 'cm',
  AreaManager = 'am',
  FullTimeEmployee = 'ft',
  PartTimeEmployee = 'pt',
}

export const MANAGER_ROLES = [
  Role.RegionManager,
  Role.StoreManager,
  Role.AreaManager,
  Role.CountryManager,
];

export const COLLABORATOR_PERMISSIONS = ['create', 'update', 'delete'];

export enum LoginMethods  {
  Password = 'password',
  EasyAccess = 'easy-access'
}

export enum SystemModules {
  Badges = 'badges',
  Dashboard = 'dashboard',
  Information = 'information',
  KpiDashboard = 'kpiDashboard',
  Kpis = 'kpis',
  Leaderboard = 'leaderboard',
  Messenger = 'messenger',
  Missions = 'missions',
  MyProfile = 'myProfile',
  News = 'news',
  Notifications = 'notifications',
  PublicProfile = 'publicProfile',
  Social = 'social',
  VmGuides = 'vmGuides',
  Whatsnext = 'whatsnext',
  Wiki = 'wiki',
  MemberManagement = 'memberManagement',
}

export enum UserActionPermission {
  CREATE_USER = 'create user',
  VIEW_USER = 'view user',
  UPDATE_USER = 'update user',
  DELETE_USER = 'delete user',
  ARCHIVE_USER = 'archive user',
  ANONYMIZE_USER = 'anonymize user',
  RESTORE_USER = 'restore user',
  RESET_USER_PASSWORD = 'reset user password',
  REST_USER_AVATAR = 'reset user avatar',
}
