import { request } from 'app/api/gcsRequest';
import config from 'app/config';
import { ArticleBlockApi } from 'common-ui/generated/api/gcs';

const getArticleBlock = (
  articleId: number,
  blockId: number,
  timezone?: string,
  live?: '1'
) => {
  return new ArticleBlockApi(undefined, config.env.gcsApiUrl, request())
    .getArticleBlock(articleId, blockId, timezone, live)
    .then((response) => response.data);
};

export { getArticleBlock };
export default getArticleBlock;
