import { useNode } from '@craftjs/core';
import { cx } from '@emotion/css';
import useGetArticleBlock from 'app/api/articles/hooks/useGetArticleBlock';
import { useSelector } from 'app/hooks';
import useFileUpload from 'app/hooks/useFileUpload';
import useIsEditorEnabled from 'app/hooks/useIsEditorEnabled';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { selectors } from 'app/store/editor';
import { logger } from 'app/utils';
import CloudUpload from 'assets/icons/cloud-save.svg?react';
import Spinner from 'assets/icons/spinner.svg?react';
import { Danger, ExportSquare, InfoCircle } from 'iconsax-react';
import {
  Dispatch,
  SetStateAction,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  CreatedFileSlot,
  InternalFileGet,
  ScormTaskBlockBVariantsValueCourseStatusEnum,
} from 'submodules/common-ui/generated/api/gcs';

import CategoryDropdown from '../components/CategoryDropdown';
import Completion from '../components/Completion';
import Deadline from '../components/Deadline';
import Mandatory from '../components/Mandatory';
import Privacy from '../components/Privacy';
import TaskType from '../components/TaskType';
import Title from '../components/Title';
import ScormCoverImage from '../ScormBlock/components/ScormCoverImage';

import { ScormProps } from '.';

export interface EditorUserModeRef {
  replaceUploadFile: () => void;
}

interface EditorUserModeProps {
  setBackspace: Dispatch<SetStateAction<boolean>>;
  setHasFocus: Dispatch<SetStateAction<boolean>>;
  setProp: (cb: any, throttleRate?: number | undefined) => void;
}

const EditorUserMode = forwardRef<EditorUserModeRef, EditorUserModeProps>(
  ({ setHasFocus, setProp, setBackspace }, ref) => {
    const { selected, scormProps, nodeId } = useNode((node) => ({
      selected: node.events.selected,
      scormProps: node.data.props as ScormProps,
      nodeId: node.id,
    }));

    const [imageFileSource, setImageFileSource] = useState<string | null>(null);
    const [isUploaded, setIsUploaded] = useState(false);

    const { t } = useArticlesTranslation();
    const { enabled } = useIsEditorEnabled();

    const { upload, isLoading: isUploading, isError } = useFileUpload();

    const currentLanguage = useSelector(selectors.getActiveLanguage);

    const inputRef = useRef<HTMLInputElement>(null);

    const { block, refetch } = useGetArticleBlock({
      articleId: scormProps?.articleId ?? 1,
      blockId: Number(nodeId),
      enabled:
        scormProps?.courseStatus ===
        ScormTaskBlockBVariantsValueCourseStatusEnum.Running,
    });

    const replaceUploadFile = () => {
      inputRef.current?.click();
    };

    useImperativeHandle(ref, () => ({
      replaceUploadFile,
    }));

    useEffect(() => {
      if (!scormProps.image?.directUrls) return;
      setImageFileSource(
        scormProps.image.directUrls?.small?.url ??
          scormProps.image.directUrls.original.url
      );
    }, [scormProps.image?.directUrls, scormProps.image?.url]);

    useEffect(() => {
      if (
        scormProps?.courseStatus !==
        ScormTaskBlockBVariantsValueCourseStatusEnum.Running
      )
        return;

      const interval = setInterval(() => {
        if (
          block &&
          block.variants[currentLanguage].courseStatus !==
            ScormTaskBlockBVariantsValueCourseStatusEnum.Running
        ) {
          clearInterval(interval);
          return setProp((props: ScormProps) => {
            props.courseStatus = block.variants[currentLanguage].courseStatus;
          });
        }
        refetch();
      }, 5000);

      return () => {
        if (interval) clearInterval(interval);
      };
    }, [block, currentLanguage, refetch, scormProps?.courseStatus, setProp]);

    const onFileUpload = (
      file: File,
      response: CreatedFileSlot,
      type: 'image' | 'scorm'
    ): void => {
      const uploadedFile: InternalFileGet = {
        id: response.id,
        translationStatus: 'draft',
        type: 'internal',
        name: file.name,
        url: response.url,
      };

      if (type === 'scorm') {
        setProp((props: ScormProps) => {
          props.package = uploadedFile;
        });
      }
      if (type === 'image') {
        const src = URL.createObjectURL(file);
        setImageFileSource(src);
        setProp((props: ScormProps) => {
          props.image = uploadedFile;
        });
      }
    };

    const onFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
      const file = e.target.files?.[0];
      e.target.value = '';

      if (!file) return;

      setIsUploaded(true);

      setProp((props: ScormProps) => {
        props.package = undefined;
      });

      upload(file, {
        onSuccess: (response) => onFileUpload(file, response, 'scorm'),
        onError: () => {
          logger.error();
          setIsUploaded(false);
        },
      });
    };

    const renderUploadContent = useCallback(() => {
      if (isUploading) {
        return (
          <>
            <Spinner className="w-5 h-5 animate-spin text-focus" />
            <span className="text-sm">{t('Uploading')}...</span>
          </>
        );
      }

      if (isError) {
        return (
          <>
            <Danger className="w-5 h-5 text-error" />
            <span className="text-sm text-error">
              {t('Error uploading file. Try again')}...
            </span>
          </>
        );
      }

      if (isUploaded) {
        return (
          <>
            <InfoCircle className="w-5 h-5 text-focus" />
            <span className="text-sm">
              {t('Please save the article to perform SCORM course validation')}
            </span>
          </>
        );
      }

      if (
        scormProps?.courseStatus ===
        ScormTaskBlockBVariantsValueCourseStatusEnum.Error
      ) {
        return (
          <>
            <CloudUpload className="w-5 h-5 text-error" />
            <span className="text-sm text-error">
              {t('Error validating file. Try with another file')}...
            </span>
          </>
        );
      }

      if (
        scormProps?.courseStatus ===
        ScormTaskBlockBVariantsValueCourseStatusEnum.Running
      ) {
        return (
          <>
            <CloudUpload />
            <span className="text-sm">
              {t(
                'Scorm package validation in progress. You can save and close the article...'
              )}
            </span>
          </>
        );
      }

      if (scormProps?.package) {
        return (
          <>
            <ExportSquare size={24} />
            <span className="text-sm">{scormProps.package.name}</span>
          </>
        );
      }

      return (
        <>
          <CloudUpload
            className={cx({
              'text-error': !selected && !scormProps.package,
            })}
          />
          <span
            className={cx('text-sm', {
              'text-error': !selected && !scormProps.package,
            })}
          >
            {t('Upload Scorm Package')}
          </span>
        </>
      );
    }, [
      isError,
      isUploading,
      isUploaded,
      scormProps?.courseStatus,
      scormProps.package,
      t,
      selected,
    ]);

    useEffect(() => {
      if (scormProps.mandatory === undefined)
        setProp((props: ScormProps) => {
          props.mandatory = true;
        });
    }, [scormProps.mandatory, setProp]);

    return (
      <div className="flex justify-between m-2 p-3 rounded border border-gray-light shadow-action">
        <div className="flex flex-col w-full leading-none gap-2">
          <div className="flex">
            <TaskType
              type={scormProps.type}
              disabled={!enabled}
              onClick={(value) =>
                setProp((props: ScormProps) => {
                  props.type = value;
                })
              }
            />
            <div className="flex flex-col flex-auto gap-2">
              <Title
                onFocusChange={(focus) => setHasFocus(focus)}
                className={cx(
                  '[&:not(:empty)]:mb-2 font-bold placeholder:font-normal',
                  {
                    hidden: !selected && !scormProps.title,
                  }
                )}
                description={scormProps.title}
                enabled={enabled}
                placeholder={`${t('Title (optional)')} `}
                onChange={(value) =>
                  setProp((props: ScormProps) => {
                    props.title = value;
                  })
                }
              />
              <Title
                onFocusChange={(focus) => setHasFocus(focus)}
                placeholder={t('Description')}
                description={scormProps.description}
                error={!selected && !scormProps.description}
                enabled={enabled}
                onChange={(value) =>
                  setProp((props: ScormProps) => {
                    props.description = value;
                  })
                }
              />
            </div>
          </div>

          <input
            onChange={(e) => onFileChange(e)}
            type="file"
            id={`scormFileInput-${nodeId}`}
            className="hidden"
            accept=".zip"
            ref={inputRef}
            disabled={isUploading}
          />

          <div className="ml-8 h-12 p-1 rounded-lg bg-hover-blue flex items-center">
            <ScormCoverImage
              nodeId={`scormImageInput-${nodeId}`}
              source={imageFileSource}
              error={!selected && !scormProps.image}
              onUploadError={() => logger.error()}
              onUploadSuccess={(file, res) => onFileUpload(file, res, 'image')}
              onSelectImage={() =>
                setProp((props: ScormProps) => {
                  props.image = undefined;
                })
              }
            />
            <label
              htmlFor={`scormFileInput-${nodeId}`}
              className="grow flex justify-center items-center text-focus gap-2"
            >
              {renderUploadContent()}
            </label>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center text-gray-dark">
              {(!scormProps.schedule ||
                scormProps.schedule.frequency === 'once') && (
                <Deadline
                  disabled={!enabled}
                  selected={scormProps.deadline}
                  onChange={(value) =>
                    setProp((props: ScormProps) => {
                      props.deadline = value ?? undefined;
                      props.schedule = undefined;
                    })
                  }
                />
              )}
              <CategoryDropdown
                disabled={!enabled}
                onOpenCallback={setBackspace}
                selectedCategories={scormProps.categories}
                onChange={(category) =>
                  setProp((props: ScormProps) => {
                    props.categories = category ? [category] : [];
                  })
                }
              />
            </div>

            <div className="flex items-center text-gray-dark gap-1.5">
              <Privacy
                disabled={!enabled}
                isPublic={scormProps.public}
                onClick={(value) =>
                  setProp((props: ScormProps) => {
                    props.public = value;
                  })
                }
              />
              <div className="bg-gray-light h-4 w-[1px]" />
              <Completion
                disabled={!enabled}
                required={scormProps.required}
                onClick={(value) =>
                  setProp((props: ScormProps) => {
                    props.required = value;
                  })
                }
              />
              <div className="bg-gray-light h-4 w-[1px]" />
              <Mandatory
                disabled={!enabled}
                checked={!!scormProps.mandatory}
                onChange={() =>
                  setProp((props: ScormProps) => {
                    props.mandatory = !scormProps.mandatory;
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default EditorUserMode;
