import { cx } from '@emotion/css';
import { actions, selectors } from 'app/store/navigation';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon';

import { SidebarSection } from '../types';

interface Props {
  section: SidebarSection;
  title?: string;
  isClipped: boolean;
  children: React.ReactNode;
}

const ModuleExpansion = ({ children, title, isClipped, section }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (ref.current) setHeight(ref.current.scrollHeight);
  }, [children]);

  const { expanded: _expanded } = useSelector(
    selectors.selectSideMenuModulesState
  )[section];

  if (!title) return <>{children}</>;

  return (
    <div className="flex flex-col ">
      <button
        data-for="module"
        data-tip={title}
        onClick={() =>
          dispatch(
            actions.setSidebarState({
              section,
              expanded: !_expanded,
            })
          )
        }
        className="w-full flex items-center text-gray-silver hover:bg-gray-silver-blue py-3 px-4 hover:rounded-[10px]"
      >
        <div className={cx({ '-rotate-90': !_expanded, 'mr-2': !isClipped })}>
          <ArrowDownSLineIcon size={18} />
        </div>
        {!isClipped && <span className="text-xs">{title}</span>}
      </button>
      <div
        ref={ref}
        style={{
          maxHeight: _expanded ? `${height}px` : '0',
          opacity: _expanded ? '1' : '0',
          transition: 'max-height 0.5s, opacity 0.5s',
          overflow: 'hidden',
        }}
      >
        {children}
      </div>
      {isClipped && (
        <ReactTooltip
          place="right"
          effect="solid"
          class="react-tooltip"
          id="module"
        />
      )}
    </div>
  );
};

export default ModuleExpansion;
