import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import useGetSettings from 'app/api/pwaSettings/hooks/useGetSettings';
import { useCommonTranslation } from 'app/internationalization/hooks';
import HomeScreenIcon from 'assets/icons/home-screen.svg?react';
import ShareIcon from 'assets/icons/share.svg?react';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

const IosPopup = () => {
  const storageKey = 'lastInstallPopUpShown';
  const sevenDaysInMilis = 604800;

  const iOS = 'standalone' in window.navigator;
  const iOSChromeUserAgent = 'CriOS';
  const chrome = Boolean(
    [iOSChromeUserAgent].find((regex) => navigator.userAgent.match(regex))
  );

  const isInstalled =
    'standalone' in window.navigator ? window.navigator.standalone : false;

  const { t } = useCommonTranslation();

  const [hidden, setHidden] = useState(true);

  const { settings } = useGetSettings();

  const checkVisibility = () => {
    const lastInstallPoUpShown = localStorage.getItem(storageKey);

    if (!lastInstallPoUpShown) return setHidden(false);

    const lastInstallPopUpShownMilis = parseInt(lastInstallPoUpShown);
    const now = dayjs().millisecond();

    if ((now - lastInstallPopUpShownMilis) / 1000 >= sevenDaysInMilis) {
      return setHidden(false);
    }

    return setHidden(true);
  };

  useEffect(() => checkVisibility(), []);

  const onCancel = () => {
    localStorage.setItem(storageKey, dayjs().millisecond().toString());

    setHidden(true);
  };

  const logo = settings?.favicons.find((f) => {
    return f.rel === 'apple-touch-icon';
  });

  if (!iOS || isInstalled || hidden) return <></>;

  return (
    <InstallationPopUp
      isChrome={chrome}
      className={cx(
        'flex flex-col min-w-[360px] bg-black rounded-lg fixed z-999 text-white',
        { 'top-3': chrome, 'bottom-3': !chrome }
      )}
    >
      <div className="flex items-center justify-between border-b border-b-grayscale-secondary p-3 pr-5">
        <span className="font-medium">{t('Add to Home Screen')}</span>
        <button className="font-medium text-[#0078DC]" onClick={onCancel}>
          {t('Cancel')}
        </button>
      </div>
      <div className="flex p-3 pr-5 items-center">
        {logo?.url ? (
          <img
            src={logo?.url}
            alt="App logo"
            className="w-14 h-14 rounded-lg"
          />
        ) : (
          <div className="w-14 h-14 bg-grayscale-secondary rounded-lg"></div>
        )}
        <div className="flex flex-col flex-grow text-sm ml-4">
          <div className="flex items-center justify-between">
            <span>{t('Tap the Share button')}</span>
            <ShareIcon className="text-[#0078DC] w-7 h-7" />
          </div>
          <div className="flex items-center justify-between mt-3">
            <span>{t('Tap Add to Home Screen')}</span>
            <HomeScreenIcon className="w-7 h-7" />
          </div>
        </div>
      </div>
    </InstallationPopUp>
  );
};

const InstallationPopUp = styled.div`
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    margin-left: -10px;

    ${({ isChrome }: { isChrome: boolean }) => {
      if (isChrome) {
        return `
        margin-top: -10px;
        border-bottom-color: #222222;
        border-top:0;
        top:0;
        right: 10%;
      `;
      }
      return `
        border-top-color: #222222;
        border-bottom: 0;
        bottom: 0;
        margin-bottom: -10px;
        left: 50%;
      `;
    }}
  
`;

export default IosPopup;
