import { cx } from '@emotion/css';
import { ScrollbarContainer, Switch } from 'app/components';
import config from 'app/config';
import { useISOLanguages, useSelector } from 'app/hooks';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { actions, selectors } from 'app/store/editor';
import { Language } from 'app/store/editor/types';
import { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDispatch } from 'react-redux';
import CheckLineIcon from 'remixicon-react/CheckLineIcon';

import { useLanguageSettingsContext } from '../context';

interface Props {
  onSaveClick: () => void;
}

const LanguagesDropdown = ({ onSaveClick }: Props) => {
  const { t } = useArticlesTranslation();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [autoTranslate, setAutoTranslate] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [dropdownSelectedLanguages, setDropdownSelectedLanguages] = useState<
    Language[]
  >([]);

  const { translate, setAddedDropdownLanguages, setTranslationStatus } =
    useLanguageSettingsContext();
  const languages = useISOLanguages({ search });

  const selectedLanguages = useSelector(selectors.getSelectedLanguages);
  const article = useSelector(selectors.getArticle);

  const mainLanguage = selectedLanguages.find(({ isDefault }) => isDefault);
  const activeLanguage = selectedLanguages.find(({ active }) => active);

  const isMenuVisible = open && languages.length > 0;

  const isLanguageSelected = (code: string) =>
    dropdownSelectedLanguages.some((prev) => prev.code === code);

  const isDisabled = (languageCode: string) =>
    languageCode === activeLanguage?.code ||
    languageCode === mainLanguage?.code;

  useEffect(() => {
    setDropdownSelectedLanguages(selectedLanguages);
  }, [selectedLanguages, open]);

  const handleSelectCategory = (category: Language) =>
    setDropdownSelectedLanguages((prev) => [...prev, category]);

  const handleUnselectCategory = (category: Language) =>
    setDropdownSelectedLanguages((prev) =>
      prev.filter((prevCategory) => prevCategory.code !== category.code)
    );

  const translateLanguage = async (language: Language) =>
    await translate(
      {
        articleId: Number(article?.id),
        toLanguage: language.code,
      },
      {
        onSuccess(data) {
          handleAddLanguage(language);
          dispatch(actions.setArticle(data));
          setAddedDropdownLanguages([language]);
        },
      }
    );

  const handleLanguageClick = async (language: Language) => {
    if (dropdownSelectedLanguages.some((prev) => prev.code === language.code)) {
      handleUnselectCategory(language);
      handleRemoveLanguage(language);
    } else {
      handleSelectCategory(language);
      handleAddLanguage(language);
      handleOutsideClick();
      if (autoTranslate) await translateLanguage(language);
    }

    setIsLoaded(true);
    handleOutsideClick();
  };

  const handleOutsideClick = () => {
    setOpen(false);
    setDropdownSelectedLanguages([]);
  };

  useEffect(() => {
    if (!isLoaded) return;
    if (!autoTranslate) onSaveClick();
    setIsLoaded(false);
    setAddedDropdownLanguages([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearch(event.target.value);

  const handleAddLanguage = (language: Language) => {
    dispatch(actions.addLanguage(language));
    updateTranslationStatus(autoTranslate ? 'auto' : 'draft', language.code);
  };

  const handleRemoveLanguage = (language: Language) =>
    dispatch(actions.removeLanguage(language.code));

  const updateTranslationStatus = (
    status: 'draft' | 'auto',
    languageCode: string
  ) => {
    const translationStatus = setTranslationStatus(languageCode, status);
    if (!translationStatus) return;
    dispatch(actions.updateTranslationStatus(translationStatus));
  };

  return (
    <div className="mt-4 relative">
      <OutsideClickHandler onOutsideClick={handleOutsideClick}>
        <div
          role="presentation"
          onClick={() => setOpen(!open)}
          className="relative min-h-[46px] max-h-[92px] border gap-2 rounded p-2 flex border-gray-dark focus-within:border-focus"
        >
          <div className="w-full flex items-center">
            <input
              placeholder={`${t('Add language')}`}
              value={search}
              onChange={handleSearchChange}
              autoComplete="off"
              className="border-none outline-none flex-grow min-w-0 placeholder:text-sm"
            />
          </div>
          <div
            role="presentation"
            className="right-2 top-4 flex items-center"
            onClick={(event) => event.stopPropagation()}
          >
            <Switch
              className="mr-2"
              onColor={config.colors.success}
              offColor={config.colors['gray-dark']}
              handleDiameter={12}
              height={15}
              width={24}
              checked={autoTranslate}
              onChange={setAutoTranslate}
            />
            <span className="text-grayscale-secondary text-xs w-max">
              {t('Auto-translate')}
            </span>
          </div>
        </div>
        {isMenuVisible && (
          <div className="absolute top-13 left-0 w-full bg-white border border-gray-light rounded shadow-md z-50">
            <ScrollbarContainer className="h-full overflow-auto max-h-[150px]">
              {languages.map((language) => (
                <button
                  type="button"
                  key={language.name}
                  disabled={isDisabled(language.code)}
                  className={cx(
                    'w-full flex py-2 px-3 items-center gap-2 cursor-pointer hover:bg-grayscale-secondary hover:bg-opacity-10',
                    {
                      'bg-grayscale-secondary bg-opacity-10': isDisabled(
                        language.code
                      ),
                      'bg-focus-background': isLanguageSelected(language.code),
                    }
                  )}
                  onClick={() =>
                    handleLanguageClick({
                      name: language.name,
                      code: language.code,
                      isDefault: false,
                      active: false,
                    })
                  }
                >
                  <span
                    className={cx(
                      'text-sm text-grayscale-primary overflow-hidden text-ellipsis whitespace-nowrap',
                      {
                        'text-grayscale-secondary': isDisabled(language.code),
                      }
                    )}
                  >
                    {language.name}
                  </span>
                  {isLanguageSelected(language.code) &&
                    !isDisabled(language.code) && (
                      <CheckLineIcon size={20} className="text-focus ml-auto" />
                    )}
                </button>
              ))}
            </ScrollbarContainer>
          </div>
        )}
      </OutsideClickHandler>
    </div>
  );
};

export default LanguagesDropdown;
