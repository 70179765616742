import { MissionsKey } from '../types';

import useBaseTranslation from './useBaseTranslation';

const useMissionsTranslation = () =>
  useBaseTranslation<MissionsKey>(undefined, {
    keyPrefix: 'missions',
  });

export default useMissionsTranslation;
