import { request } from 'app/api/aiServiceRequest';
import { useMutation } from 'react-query';

const useGenerateBlockMutation = (
  onSuccess?: (response: any) => void,
  onError?: () => void
) => {
  const { mutate, isLoading } = useMutation(
    ({ data }: { data: any }) => {
      return request().post('generate-block', data);
    },
    {
      onSuccess,
      onError,
    }
  );
  return { mutate, isLoading };
};

export default useGenerateBlockMutation;
