import { cx } from '@emotion/css';
import React from 'react';

interface OptionProps {
  label: string;
  onSelect: () => void;
  isSelected: boolean;
  isDisabled?: boolean;
}

const Option: React.FC<OptionProps> = ({
  label,
  onSelect,
  isSelected,
  isDisabled,
}) => (
  <button
    type="button"
    className={cx('pl-4 text-left flex items-center hover:bg-gray-light', {
      'bg-gray-light': isDisabled,
    })}
    onClick={onSelect}
    disabled={isDisabled}
  >
    <div className="w-6 h-6 p-0.5 my-2 mr-2 rounded-sm border border-grayscale-primary">
      {isSelected && <div className="rounded-sm bg-focus w-full h-full" />}
    </div>
    {label}
  </button>
);

export default Option;
