import { Node, useEditor } from '@craftjs/core';
import uniqueId from 'lodash/uniqueId';
import { useCallback } from 'react';

const useCloneTree = () => {
  const { query } = useEditor();

  const cloneTree = useCallback(
    (id: string) => {
      const tree = query.node(id).toNodeTree();
      const treeNodes: any = {};

      const updateNodeId = (node: Node, parentId?: string) => {
        const updatedNodeId = uniqueId();
        const childNodes = node.data.nodes.map((childNodeId) =>
          updateNodeId(tree.nodes[childNodeId], updatedNodeId)
        );
        const linkedNodes = Object.keys(node.data.linkedNodes).reduce(
          (acc, linkedNodeId) => ({
            ...acc,
            [linkedNodeId]: updateNodeId(
              tree.nodes[node.data.linkedNodes[linkedNodeId]],
              updatedNodeId
            ),
          }),
          {}
        );
        const updatedNode = {
          ...node,
          id: updatedNodeId,
          data: {
            ...node.data,
            parent: parentId || node.data.parent,
            nodes: childNodes,
            linkedNodes,
          },
        };

        treeNodes[updatedNodeId] = query.parseFreshNode(updatedNode).toNode();

        return updatedNodeId;
      };

      const rootNodeId = updateNodeId(tree.nodes[tree.rootNodeId]);
      return {
        rootNodeId,
        nodes: treeNodes,
      };
    },
    [query]
  );

  return {
    cloneTree,
  };
};
export default useCloneTree;
