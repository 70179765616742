import { resources } from 'app/api/comments';
import { useMutation } from 'react-query';

const useDeleteCommentMutation = () => {
  const mutation = useMutation(resources.deleteComment);

  return { ...mutation };
};

export default useDeleteCommentMutation;
