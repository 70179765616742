import { cx } from '@emotion/css';
import { Button, Modal, SuccessModal } from 'app/components';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { routes } from 'app/router';
import { actions } from 'app/store/common';
import CrossIcon from 'assets/icons/cross.svg?react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

interface Props {
  disabled: boolean;
  isSubmitSuccess: boolean;
  onSubmit: VoidFunction;
  onClose: VoidFunction;
}

const ConvertToTemplateModal = ({
  onSubmit,
  onClose,
  isSubmitSuccess,
  disabled,
}: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useArticlesTranslation();

  const [isSuccess, setIsSuccess] = useState(false);

  const onSuccessClose = () => {
    setIsSuccess(false);
    onClose();
  };

  useEffect(() => {
    if (isSubmitSuccess) setIsSuccess(true);
  }, [isSubmitSuccess]);

  if (isSuccess)
    return (
      <SuccessModal
        title={t('Congratulations!')}
        description={t('Your article is converted to a template.')}
        cancelLabel={t('Go to Template Gallery')}
        submitLabel={t('Edit Template')}
        onSubmit={onClose}
        onCancel={() => {
          navigate(routes.articleStudio.create());
          dispatch(actions.setArticleStudioTab('template_gallery'));
        }}
        onClose={onSuccessClose}
      />
    );

  return (
    <Modal onClose={onClose} className="max-w-[377px]">
      <div className="flex items-center mb-1 justify-between">
        <span className="text-grayscale-primary font-bold text-base">
          {t('Convert to Template')}
        </span>
        <button className="text-grayscale-secondary w-6 h-6" onClick={onClose}>
          <CrossIcon />
        </button>
      </div>
      <p className="text-xs text-grayscale-secondary">
        {t(
          "Transform your go-to articles into templates! They're a handy time-saver for your team, ensuring consistent work every time."
        )}
      </p>
      <div className="w-max mx-auto mt-6">
        <Button
          variant="primary"
          onClick={onSubmit}
          disabled={disabled}
          className={cx('p-6', {
            'bg-gray-light': disabled,
          })}
        >
          <span
            className={cx(disabled ? 'text-grayscale-secondary' : 'text-white')}
          >
            {t('Convert to Template')}
          </span>
        </Button>
      </div>
    </Modal>
  );
};

export default ConvertToTemplateModal;
