import { useQuery } from 'react-query';

import { resources, queryKeys } from '../index';

const useCreationInfoQuery = () => {
  const { data, isLoading, error, isFetching, isPreviousData } = useQuery(
    queryKeys.memberCreate(),
    () => resources.getMemberCreate()
  );

  return {
    data: data?.data,
    isFetching,
    isLoading,
    isPreviousData,
    error,
  };
};

export default useCreationInfoQuery;
