import { cx } from '@emotion/css';
import { Input2, ScrollbarContainer, VerticalChevron } from 'app/components';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { selectors } from 'app/store/auth';
import { Building, Slash } from 'iconsax-react';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Popover } from 'react-tiny-popover';
import CheckLineIcon from 'remixicon-react/CheckLineIcon';

import { useAnswersContext } from '../context';

const TenantsDropdown = () => {
  const { t } = useArticlesTranslation();

  const sessionTenant = useSelector(selectors.getSelectedTenant);

  const {
    tenants,
    selectedTenant,
    selectedTaskId,
    article,
    onTenantChange,
    setSearch,
  } = useAnswersContext();

  const [tenantsOpen, setTenantsOpen] = useState(false);

  const parentRef = useRef(null);

  const selectedTenantName =
    tenants?.filter(
      (tenant) => tenant.articleId === selectedTenant?.articleId
    )[0]?.tenantName ?? t('Partners');

  const disabled =
    !!selectedTaskId ||
    (!article?.isShared &&
      sessionTenant?.alias !== article?.originalTenant?.name);

  return (
    <div ref={parentRef}>
      <Popover
        isOpen={tenantsOpen}
        onClickOutside={disabled ? undefined : () => setTenantsOpen(false)}
        positions={['bottom']}
        containerClassName="z-[9999]"
        parentElement={parentRef.current ?? undefined}
        content={({ childRect }) => (
          <div
            className="mb-4 bg-light border border-gray-light rounded shadow-atobi"
            style={{ width: childRect.width + 32 }}
          >
            <div className="flex flex-col gap-2 pt-4">
              <div className="px-4">
                <Input2
                  searchIconSize={16}
                  className=" py-3 pr-3 rounded-lg outline-[1px] outline-gray-white"
                  placeholder="Partner Name"
                  onChange={(e) => setSearch(e.target.value)}
                  onClear={() => setSearch('')}
                  isSearch={true}
                />
              </div>

              <ScrollbarContainer className="max-h-[225px] overflow-auto">
                {tenants?.map((tenant) => (
                  <button
                    key={tenant.tenantId}
                    className={cx('flex items-center w-full p-2 rounded-sm', {
                      'hover:bg-focus-background': tenant.answerSharing,
                    })}
                    disabled={!tenant.answerSharing}
                    onClick={() => {
                      if (!tenant.answerSharing) return;

                      onTenantChange(tenant);
                      setTenantsOpen(false);
                    }}
                  >
                    <div className="flex flex-col justify-center min-w-0 ml-3">
                      <div className="flex mb-0.5">
                        <span className="text-sm text-ellipsis whitespace-nowrap overflow-hidden ">
                          {tenant.tenantName}
                        </span>

                        {!tenant.answerSharing && (
                          <div className="flex gap-1 text-error ml-4">
                            <Slash size={16} />

                            <span className="text-xs font-bold">
                              {t('Insights sharing off')}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    {selectedTenant?.articleId === tenant.articleId && (
                      <CheckLineIcon
                        size={20}
                        className="ml-auto mr-1 text-focus"
                      />
                    )}
                  </button>
                ))}
              </ScrollbarContainer>
            </div>
            <button
              className="flex items-center justify-center w-full text-grayscale-secondary font-bold py-3 bg-grayscale-bg-dark rounded-br rounded-bl"
              onClick={() => onTenantChange()}
            >
              {t('Clear selection')}
            </button>
          </div>
        )}
      >
        <button
          disabled={disabled}
          className={cx(
            'flex w-full items-center rounded-lg text-sm text-focus p-3 bg-focus-background',
            { 'opacity-50': disabled }
          )}
          onClick={() => setTenantsOpen((prev) => !prev)}
        >
          <Building size={16} />
          <span className="ml-[6px]">{selectedTenantName}</span>
          <div className="ml-auto">
            <VerticalChevron open={tenantsOpen} size={16} />
          </div>
        </button>
      </Popover>
    </div>
  );
};

export default TenantsDropdown;
