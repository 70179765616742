import { useTranslation } from 'react-i18next';
import {
  GetCategory,
  RelevantCategory,
} from 'submodules/common-ui/generated/api/gcs/api';

const useGetCategoryName = () => {
  const {
    i18n: { language: userLanguage },
  } = useTranslation();

  const getCategoryName = (category: GetCategory | RelevantCategory) => {
    const defaultCategoryNameKey = Object.keys(category.variants).find(
      (key) => category.variants[key].isDefault
    );

    return (
      category?.variants?.[userLanguage]?.name ??
      (defaultCategoryNameKey
        ? category?.variants?.[defaultCategoryNameKey]?.name
        : '')
    );
  };

  return { getCategoryName };
};

export default useGetCategoryName;
