import { useEditor, useNode } from '@craftjs/core';
import { cx } from '@emotion/css';
import { useSelector } from 'app/hooks';
import { ACTION_BLOCKS } from 'app/pages/Editor/helpers/blocks';
import useArticleBlocks from 'app/pages/Editor/hooks/useArticleBlocks';
import { selectors } from 'app/store/editor';
import { ArrowSquareLeft, ArrowSquareRight } from 'iconsax-react';
import sortBy from 'lodash/sortBy';

import { ArticleActionNavigation } from '../types';

const EditorNavigation = ({
  children,
  isSubmitting,
}: ArticleActionNavigation) => {
  const {
    nodes,
    actions: { selectNode },
  } = useEditor((state, _) => ({
    nodes: state.nodes,
  }));

  const selectedLanguages = useSelector(selectors.getSelectedLanguages);
  const mainLanguage = selectedLanguages.find((l) => l.isDefault)?.code ?? 'en';
  const { blocks } = useArticleBlocks(mainLanguage);

  const { nodeId } = useNode((node) => ({
    nodeId: node.id,
  }));

  const tasksToCycle = sortBy(
    Object.values(blocks).filter(({ displayName }) =>
      ACTION_BLOCKS.includes(displayName)
    ),
    'position'
  ).map(({ id }) => id);

  const currentNodeIndex = tasksToCycle.indexOf(nodeId);
  const nextTask = tasksToCycle[currentNodeIndex + 1];
  const previousTask = tasksToCycle[currentNodeIndex - 1];

  const next = () => {
    if (currentNodeIndex + 1 > tasksToCycle.length - 1) return;
    selectNode(nextTask);
  };

  const previous = () => {
    if (currentNodeIndex - 1 < 0) return;
    selectNode(previousTask);
  };

  const isNextTaskLocked = nodes[nextTask]?.data?.props?.isLocked ?? false;

  const previousDisabled = currentNodeIndex === 0 || isSubmitting;
  const nextDisabled =
    currentNodeIndex === tasksToCycle.length - 1 ||
    isSubmitting ||
    isNextTaskLocked;

  return (
    <>
      <button
        className={cx({
          'text-gray-dark': previousDisabled,
          'text-black': currentNodeIndex > 0,
        })}
        onClick={previous}
        disabled={previousDisabled}
      >
        <ArrowSquareLeft size={24} />
      </button>
      {children}
      <button
        className={cx({
          'text-gray-dark': nextDisabled,
          'text-black': currentNodeIndex < tasksToCycle.length,
        })}
        onClick={next}
        disabled={nextDisabled}
      >
        <ArrowSquareRight size={24} />
      </button>
    </>
  );
};

export default EditorNavigation;
