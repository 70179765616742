import { ClassNames } from '@emotion/react';
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND,
} from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isHeadingNode } from '@lexical/rich-text';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import { ArrowDown2 } from 'iconsax-react';
import {
  $getSelection,
  $isRangeSelection,
  $isTextNode,
  COMMAND_PRIORITY_LOW,
  FORMAT_TEXT_COMMAND,
  LexicalEditor,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import BoldIcon from 'remixicon-react/BoldIcon';
import ItalicIcon from 'remixicon-react/ItalicIcon';
import ListOrderedIcon from 'remixicon-react/ListOrderedIcon';
import ListUnorderedIcon from 'remixicon-react/ListUnorderedIcon';
import UnderlineIcon from 'remixicon-react/UnderlineIcon';

import {
  EditorBlockName,
  ListFormat,
  TextFormat,
  blockTypeToBlockName,
} from '../types';
import getDOMRangeRect from '../utils/getDOMRangeRect';
import getSelectedNode from '../utils/getSelectedNode';
import setFloatingElemPosition from '../utils/setFloatingElemPosition';

import DropdownOptions from './DropdownOptions';
import ToolbarIcon from './ToolbarIcon';

interface TextFormatFloatingToolbarProps {
  editor: LexicalEditor;
  anchorElem: HTMLElement;
  isBold: boolean;
  isItalic: boolean;
  isUnderline: boolean;
  blockType: EditorBlockName;
  paragraphOnly: boolean;
}

function TextFormatFloatingToolbar({
  editor,
  anchorElem,
  isBold,
  isItalic,
  isUnderline,
  blockType,
  paragraphOnly,
}: TextFormatFloatingToolbarProps): JSX.Element {
  const popupCharStylesEditorRef = useRef<HTMLDivElement | null>(null);

  const mouseMoveListener = (e: MouseEvent) => {
    if (!popupCharStylesEditorRef?.current) return;
    if (e.buttons !== 1 && e.buttons !== 3) return;
    if (popupCharStylesEditorRef.current.style.pointerEvents === 'none') return;

    const x = e.clientX;
    const y = e.clientY;
    const elementUnderMouse = document.elementFromPoint(x, y);

    if (!popupCharStylesEditorRef.current.contains(elementUnderMouse)) {
      // Mouse is not over the target element => not a normal click, but probably a drag
      popupCharStylesEditorRef.current.style.pointerEvents = 'none';
    }
  };

  const mouseUpListener = (_: MouseEvent) => {
    if (!popupCharStylesEditorRef?.current) return;

    if (popupCharStylesEditorRef.current.style.pointerEvents !== 'auto') {
      popupCharStylesEditorRef.current.style.pointerEvents = 'auto';
    }
  };

  useEffect(() => {
    if (popupCharStylesEditorRef?.current) {
      document.addEventListener('mousemove', mouseMoveListener);
      document.addEventListener('mouseup', mouseUpListener);

      return () => {
        document.removeEventListener('mousemove', mouseMoveListener);
        document.removeEventListener('mouseup', mouseUpListener);
      };
    }
  }, [popupCharStylesEditorRef]);

  const updateTextFormatFloatingToolbar = useCallback(() => {
    const selection = $getSelection();

    const popupCharStylesEditorElem = popupCharStylesEditorRef.current;
    const nativeSelection = window.getSelection();

    if (popupCharStylesEditorElem === null) {
      return;
    }

    const rootElement = editor.getRootElement();
    if (
      selection !== null &&
      nativeSelection !== null &&
      !nativeSelection.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const rangeRect = getDOMRangeRect(nativeSelection, rootElement);

      setFloatingElemPosition(rangeRect, popupCharStylesEditorElem, anchorElem);
    }
  }, [editor, anchorElem]);

  useEffect(() => {
    const scrollerElem = anchorElem.parentElement;

    const update = () => {
      editor.getEditorState().read(() => {
        updateTextFormatFloatingToolbar();
      });
    };

    window.addEventListener('resize', update);
    if (scrollerElem) {
      scrollerElem.addEventListener('scroll', update);
    }

    return () => {
      window.removeEventListener('resize', update);
      if (scrollerElem) {
        scrollerElem.removeEventListener('scroll', update);
      }
    };
  }, [editor, updateTextFormatFloatingToolbar, anchorElem]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateTextFormatFloatingToolbar();
    });
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateTextFormatFloatingToolbar();
        });
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateTextFormatFloatingToolbar();
          return false;
        },
        COMMAND_PRIORITY_LOW
      )
    );
  }, [editor, updateTextFormatFloatingToolbar]);

  const formatBulletList = () => {
    if (blockType !== 'ul') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatOrderedList = () => {
    if (blockType !== 'ol') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const LIST_FORMATS: ListFormat[] = [
    {
      id: 1,
      icon: <ListUnorderedIcon size={20} />,
      onClick: formatBulletList,
      active: blockType === 'ul',
    },
    {
      id: 2,
      icon: <ListOrderedIcon size={20} />,
      onClick: formatOrderedList,
      active: blockType === 'ol',
    },
  ];

  const TEXT_FORMATS: TextFormat[] = [
    {
      id: 1,
      type: 'bold',
      icon: <BoldIcon size={20} />,
      active: isBold,
    },
    {
      id: 2,
      type: 'italic',
      icon: <ItalicIcon size={20} />,
      active: isItalic,
    },
    {
      id: 3,
      type: 'underline',
      icon: <UnderlineIcon size={20} />,
      active: isUnderline,
    },
  ];

  const [showBlockOptionsDropDown, setShowBlockOptionsDropDown] =
    useState(false);
  const toolbarRef = useRef<HTMLDivElement>(null);

  return (
    <div
      id="floatingMenu"
      ref={popupCharStylesEditorRef}
      className="flex absolute top-0 left-0 z-10 opacity-0 transition-opacity bg-white"
    >
      {editor.isEditable() && (
        <ClassNames>
          {({ cx }) => (
            <div
              ref={toolbarRef}
              className={cx(
                'inline-flex gap-2 rounded-lg shadow-atobi py-1 px-4 items-center'
              )}
            >
              {!paragraphOnly && (
                <button
                  className="flex items-center hover:bg-hover-blue py-1 px-1.5 rounded-sm min-w-30 justify-between"
                  onClick={() =>
                    setShowBlockOptionsDropDown(!showBlockOptionsDropDown)
                  }
                >
                  <span className="text mr-1.5">
                    {blockTypeToBlockName[blockType]}
                  </span>

                  <ArrowDown2
                    size={16}
                    className={cx({ 'rotate-180': showBlockOptionsDropDown })}
                  />
                </button>
              )}
              {!paragraphOnly && <span className="h-6 w-[1px] bg-gray-light" />}
              {!paragraphOnly &&
                LIST_FORMATS.map((format) => (
                  <ToolbarIcon
                    key={format.id}
                    onClick={format.onClick}
                    active={format.active}
                  >
                    {format.icon}
                  </ToolbarIcon>
                ))}
              {!paragraphOnly && <span className="h-6 w-[1px] bg-gray-light" />}
              {TEXT_FORMATS.map((format) => (
                <ToolbarIcon
                  key={format.id}
                  onClick={() =>
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, format.type)
                  }
                  active={format.active}
                >
                  {format.icon}
                </ToolbarIcon>
              ))}

              {!paragraphOnly &&
                showBlockOptionsDropDown &&
                toolbarRef.current &&
                createPortal(
                  <DropdownOptions
                    editor={editor}
                    blockType={blockType}
                    toolbarRef={toolbarRef}
                    setShowBlockOptionsDropDown={setShowBlockOptionsDropDown}
                  />,
                  toolbarRef.current
                )}
            </div>
          )}
        </ClassNames>
      )}
    </div>
  );
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function useFloatingTextFormatToolbar(
  editor: LexicalEditor,
  anchorElem: HTMLElement,
  paragraphOnly: boolean
): JSX.Element | null {
  const [isText, setIsText] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [blockType, setBlockType] = useState<EditorBlockName>('paragraph');

  const updatePopup = useCallback(() => {
    // eslint-disable-next-line sonarjs/cognitive-complexity
    editor.getEditorState().read(() => {
      const selection = $getSelection();
      const nativeSelection = window.getSelection();
      const rootElement = editor.getRootElement();

      if (
        nativeSelection !== null &&
        (!$isRangeSelection(selection) ||
          rootElement === null ||
          !rootElement.contains(nativeSelection.anchorNode))
      ) {
        setIsText(false);
        return;
      }

      if (!$isRangeSelection(selection)) {
        return;
      }

      const node = getSelectedNode(selection);

      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          setBlockType(() => {
            return (
              $isHeadingNode(element) ? element.getTag() : element.getType()
            ) as EditorBlockName;
          });
        }
      }

      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));

      if (selection.getTextContent() !== '') {
        setIsText($isTextNode(node));
      } else {
        setIsText(false);
      }

      const rawTextContent = selection.getTextContent().replace(/\n/g, '');
      if (!selection.isCollapsed() && rawTextContent === '') {
        setIsText(false);
      }
    });
  }, [editor]);

  useEffect(() => {
    document.addEventListener('selectionchange', updatePopup);
    return () => {
      document.removeEventListener('selectionchange', updatePopup);
    };
  }, [updatePopup]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(() => {
        updatePopup();
      }),
      editor.registerRootListener(() => {
        if (editor.getRootElement() === null) {
          setIsText(false);
        }
      })
    );
  }, [editor, updatePopup]);

  if (!isText) {
    return null;
  }

  return (
    <TextFormatFloatingToolbar
      editor={editor}
      anchorElem={anchorElem}
      isBold={isBold}
      isItalic={isItalic}
      isUnderline={isUnderline}
      blockType={blockType}
      paragraphOnly={paragraphOnly}
    />
  );
}

export default function FloatingTextFormatToolbarPlugin({
  anchorElem = document.body,
  paragraphOnly = false,
}: {
  anchorElem?: HTMLElement;
  paragraphOnly?: boolean;
}): JSX.Element | null {
  const [editor] = useLexicalComposerContext();
  return useFloatingTextFormatToolbar(editor, anchorElem, paragraphOnly);
}
