import { cx } from '@emotion/css';
import { Paragraph } from 'submodules/common-ui/generated/api/gcs';

const editorTextToHtml = (texts?: Paragraph) => {
  if (!texts) return <></>;
  return texts.children.map((each, i) => {
    if (each.type === 'text') {
      const formatStyles = [];
      if (each.format?.bold) formatStyles.push('font-bold');
      if (each.format?.italic) formatStyles.push('italic');
      if (each.format?.underline) formatStyles.push('underline');

      const className = formatStyles.join(' ');
      return (
        <span className={cx(className)} key={`${each.value}-${i}`}>
          {each.value}
        </span>
      );
    }
    if (each.type === 'link') {
      const linkBlocks = each.children.map((link, index) => (
        <span key={`${link.value}-${index}`}>{link.value}</span>
      ));
      return (
        <a href={each.url} className="text-focus underline">
          {linkBlocks}
        </a>
      );
    }
  });
};

export default editorTextToHtml;
