import { cx } from '@emotion/css';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { ArticlesKey } from 'app/internationalization/types';
import { Calendar, Eye, Import, Send, Slash, TickCircle } from 'iconsax-react';
import {
  ArticleSharingStatus,
  ArticleStatusEnum,
} from 'submodules/common-ui/generated/api/gcs';

interface StatusTagProps {
  sharingStatus: ArticleSharingStatus;
  className?: string;
}

const StatusTag = ({ sharingStatus, className }: StatusTagProps) => {
  const { t } = useArticlesTranslation();
  const iconSize = 16;

  const draftOrScheduledLabel = () => {
    if (sharingStatus.publishAt === null) return 'Approved';

    return 'Scheduled';
  };

  const draftOrScheduledIcon = () => {
    if (sharingStatus.publishAt === null) return <TickCircle size={iconSize} />;

    return <Calendar size={iconSize} />;
  };

  const statusProps = {
    inbound: {
      text: 'text-grayscale-secondary',
      icon: <Import size={iconSize} />,
    },
    draft: {
      text: sharingStatus.publishAt ? 'text-success' : 'text-focus',
      icon: draftOrScheduledIcon(),
    },
    archived: {
      text: 'text-warning',
      icon: <Eye size={iconSize} />,
    },
    published: {
      text: 'text-success',
      icon: <Send size={iconSize} />,
    },
    rejected: {
      text: 'text-error',
      icon: <Slash size={iconSize} />,
    },
  };

  const getTranslationKey = (key: ArticleStatusEnum): ArticlesKey => {
    switch (key) {
      case 'archived':
        return 'Archived';
      case 'inbound':
        return 'Received';
      case 'published':
        return 'Published';
      case 'rejected':
        return 'Rejected';
      default:
        return draftOrScheduledLabel();
    }
  };

  return (
    <div
      className={cx(
        className,
        `flex items-center gap-1 px-1 py-0.5 text-xs font-bold capitalize ${
          statusProps[sharingStatus.status].text
        }`
      )}
    >
      {statusProps[sharingStatus.status].icon}
      <span>{t(getTranslationKey(sharingStatus.status))}</span>
    </div>
  );
};

export default StatusTag;
