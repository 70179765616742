import { cx } from '@emotion/css';
import { HTMLAttributes } from 'react';

import DropdownItem, { DropdownItemProps } from './DropdownItem';

interface ActionsDropdownProps extends HTMLAttributes<HTMLDivElement> {
  items?: Array<DropdownItemProps>;
}

const ActionsDropdown = ({ items, className }: ActionsDropdownProps) => {
  return (
    <div
      className={cx(
        className,
        'min-w-max absolute right-0 z-100 py-2 bg-white rounded-lg z-50 transform -translate-y-2 shadow-atobi'
      )}
    >
      {items &&
        items.map(
          (
            {
              text,
              Icon,
              disabled,
              tag,
              onClick,
              description,
              showTooltip,
              tooltipText,
            },
            index
          ) => (
            <DropdownItem
              text={text}
              Icon={Icon}
              onClick={onClick}
              key={index}
              disabled={disabled}
              tag={tag}
              description={description}
              showTooltip={showTooltip}
              tooltipText={tooltipText}
            />
          )
        )}
    </div>
  );
};

export default ActionsDropdown;
