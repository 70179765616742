import { SystemModules } from 'app/api/auth/constants';
import { FeatureFlagKey } from 'app/config/types';
import { routes } from 'app/router';
import {
  Book1,
  CardEdit,
  ChartSquare,
  Clipboard,
  DocumentText1,
  ExportSquare,
  LampCharge,
  Messages2,
  UserSquare,
  People,
  Shop,
  Hashtag,
  Book,
  Setting3,
  Graph,
} from 'iconsax-react';
import LikeIcon from 'remixicon-react/Heart3LineIcon';

import ChannelsIcon from '../components/ChannelIcon';
import { BADGE_KEYS, Link } from '../types';

const contentManagementLinks: Link[] = [
  {
    title: 'Article Studio',
    Icon: CardEdit,
    to: routes.articleStudio.create(),
  },
  {
    title: 'Audiences',
    Icon: People,
    to: routes.audiences.create(),
  },
];

const getDashboardLinks = ({
  hasDashboard,
  newsHidden,
}: {
  hasDashboard: boolean;
  newsHidden: boolean;
}): Link[] => [
  ...(newsHidden
    ? [
        {
          title: 'Home',
          Icon: Shop,
          to: routes.home.create(),
          isEasyAccessLoginAccessible: true,
        },
      ]
    : []),
  {
    title: 'Chat',
    Icon: Messages2,
    to: routes.chat.create(),
    badgeName: BADGE_KEYS.unreadThreadsAmount,
    moduleKey: SystemModules.Messenger,
  },
  {
    title: 'Members',
    Icon: UserSquare,
    to: routes.members.create(),
    moduleKey: SystemModules.MemberManagement,
  },
  ...(hasDashboard
    ? [
        {
          title: 'Dashboards',
          Icon: Graph,
          to: routes.dashboards.create(),
          moduleKey: SystemModules.Dashboard,
        },
      ]
    : []),
];

const getLegacyLinks = ({
  newsHidden,
  socialHidden,
}: {
  newsHidden: boolean;
  socialHidden: boolean;
}): Link[] => [
  ...(!newsHidden
    ? [
        {
          title: 'News',
          Icon: DocumentText1,
          to: routes.newsFeed.create(),
          badgeName: BADGE_KEYS.unseenNewsAmount,
          feature: 'news' as FeatureFlagKey,
          moduleKey: SystemModules.News,
        },
      ]
    : []),
  ...(!socialHidden
    ? [
        {
          title: 'Social Feed',
          Icon: LikeIcon,
          to: routes.socialFeed.create(),
          feature: 'social' as FeatureFlagKey,
          moduleKey: SystemModules.Social,
        },
      ]
    : []),
  {
    title: 'Missions',
    Icon: LampCharge,
    to: routes.missions.create(),
    feature: 'missions',
    moduleKey: SystemModules.Missions,
  },
  {
    title: 'Wiki',
    Icon: Book1,
    to: routes.wiki.create(),
    moduleKey: SystemModules.Wiki,
  },
  {
    title: 'KPIs',
    Icon: ChartSquare,
    to: routes.kpis.create(),
    feature: 'kpiDashboard',
    moduleKey: SystemModules.KpiDashboard,
  },
  {
    title: 'Visual Guides',
    Icon: Clipboard,
    to: routes.visualGuides.value,
    feature: 'vmGuides',
    moduleKey: SystemModules.VmGuides,
  },
  {
    title: 'Nike Product Hub',
    Icon: ExportSquare,
    to: routes.nikeSkuRedirect.create(),
    feature: 'nikeSKU',
  },
  {
    title: 'adidas Product Learning',
    Icon: ExportSquare,
    to: routes.atticusRedirect.create(),
    feature: 'atticus',
  },
];

const getOrganizationLinks = ({
  isGlossary,
  isManager,
  isSettings,
}: {
  isManager: boolean;
  isGlossary: boolean;
  isSettings: boolean;
}): Link[] => [
  ...(isManager
    ? [
        {
          title: 'Channels Studio',
          Icon: ChannelsIcon,
          to: routes.channels.create(),
        },
        {
          title: 'Categories Studio',
          Icon: Hashtag,
          to: routes.categoriesStudio.create(),
        },
      ]
    : []),
  ...(isGlossary
    ? [
        {
          title: 'Glossary',
          Icon: Book,
          to: routes.glossary.create(),
        },
      ]
    : []),
  ...(isSettings
    ? [
        {
          title: 'Platform Settings',
          Icon: Setting3,
          to: routes.settingsList.create(),
        },
      ]
    : []),
];

export {
  contentManagementLinks,
  getDashboardLinks,
  getOrganizationLinks,
  getLegacyLinks,
};
