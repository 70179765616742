import { BlockingProvider } from 'app/blockings';

import Comments from './components/Comments';
import { CommentsContextProvider } from './context';

const CommentsView = () => {
  return (
    <BlockingProvider>
      <CommentsContextProvider>
        <Comments />
      </CommentsContextProvider>
    </BlockingProvider>
  );
};

export default CommentsView;
