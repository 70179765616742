import { request } from 'app/api/aiServiceRequest';
import { useMutation } from 'react-query';
import {
  SummaryApi,
  GenerateSummaryResponseSchema,
} from 'submodules/common-ui/generated/api/ai-service';

const useSummarizeMutation = (
  onSuccess?: (response: any) => void,
  onError?: () => void
) => {
  const { mutate, isLoading, isError, data } = useMutation(
    (requestData: GenerateSummaryResponseSchema) => {
      return new SummaryApi(undefined, undefined, request()).generateSummary(
        requestData
      );
    },
    {
      onSuccess,
      onError,
    }
  );
  return { mutate, isLoading, isError, data };
};

export default useSummarizeMutation;
