import { cx } from '@emotion/css';
import { ButtonHTMLAttributes, forwardRef } from 'react';

const ControlButton = forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement>
>(({ children, className, ...props }, ref) => (
  <button
    {...props}
    ref={ref}
    className={cx(
      'flex hover:bg-hover-blue w-full active:bg-focus-background items-center text-grayscale-primary py-1 px-1.5',
      className
    )}
    type="button"
  >
    {children}
  </button>
));

export default ControlButton;
