import { CommonKey } from '../types';

import useBaseTranslation from './useBaseTranslation';

const useCommonTranslation = () =>
  useBaseTranslation<CommonKey>(undefined, {
    keyPrefix: 'common',
  });

export default useCommonTranslation;
