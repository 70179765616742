import { useVisualGuideQuery } from 'app/api/visualGuides/hooks';
import { useParams } from 'react-router-dom';

import DefaultBreadcrumb from './DefaultBreadcrumb';

const VisualGuideBreadcrumb = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = useVisualGuideQuery(Number(id), { enabled: false });

  return <DefaultBreadcrumb to={window.location.href} title={data?.title} />;
};

export default VisualGuideBreadcrumb;
