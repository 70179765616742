import { ReactNode } from 'react';

interface DropdownContainerProps {
  children: ReactNode;
}

const DropdownContainer = ({ children }: DropdownContainerProps) => (
  <div
    className="dropdown flex-col bg-white rounded-[18px] border border-focus-background p-3 hidden absolute whitespace-nowrap cursor-default shadow-atobi"
    role="presentation"
    onClick={(e) => e.stopPropagation()}
  >
    {children}
  </div>
);

export default DropdownContainer;
