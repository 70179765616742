const LoadingPollOption = () => {
  return (
    <div
      className={
        'h-10 border rounded bg-focus-background border-gray-light mb-3'
      }
    />
  );
};

export default LoadingPollOption;
