import { Button } from 'app/components';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import Masonry from 'react-responsive-masonry';

import LoadingHeaderAndTitle from './LoadingHeaderAndTitle';
import LoadingMediaTasks from './MediaTask/LoadingMediaTasks';
import LoadingOpenQuestionTasks from './OpenQuestionTask/LoadingOpenQuestionTasks';
import LoadingPollOption from './PollTask/LoadingPollOption';
import LoadingQuizOption from './QuizTask/LoadingQuizOption';

const ActionsSekeleton = ({ empty = true }: { empty?: boolean }) => {
  const { t } = useArticlesTranslation();

  return (
    <div className="md:w-[780px]">
      <Masonry columnsCount={2} gutter="10px" className="relative">
        <div className="rounded-[40px] bg-grayscale-bg-dark p-6 flex flex-col gap-3">
          <LoadingHeaderAndTitle />
          <LoadingOpenQuestionTasks />
          <LoadingOpenQuestionTasks />
        </div>
        <div className="rounded-[40px] bg-grayscale-bg-dark p-6 flex flex-col gap-3">
          <LoadingHeaderAndTitle />
          <div className="grid grid-cols-2 gap-2 relative">
            <LoadingMediaTasks />
            <LoadingMediaTasks />
            <LoadingMediaTasks />
            <LoadingMediaTasks />
          </div>
          <div className=" w-[342px] h-[48px] rounded-xl bg-hover-blue"></div>
        </div>
        <div className="rounded-[40px] bg-grayscale-bg-dark p-6 flex flex-col gap-3">
          <LoadingHeaderAndTitle />
          <LoadingPollOption />
          <LoadingPollOption />
        </div>
        <div className="rounded-[40px] bg-grayscale-bg-dark p-6 flex flex-col gap-3">
          <LoadingHeaderAndTitle />
          <LoadingQuizOption />
          <LoadingQuizOption />
        </div>
        {empty && (
          <div className="absolute w-[390px] h-[188px] bg-light shadow-atobi rounded p-6 flex flex-col items-center text-center gap-2.5 top-0 bottom-0 right-0 left-0 m-auto z-999">
            <div className="flex flex-col gap-2">
              <span className="text-lg font-bold text-grayscale-primary">
                {t('This dashboard is empty.')}
              </span>
              <span className="text-sm text-grayscale-secondary">
                {t(
                  'To discover the potential of our action blocks and learn how to use them, visit the page below.'
                )}
              </span>
            </div>
            <a
              href="https://help.atobi.io/article/137-how-to-create-an-article"
              target="_blank"
              className="w-full"
              rel="noreferrer"
            >
              <Button variant="secondary">{t('What are actions')}</Button>
            </a>
          </div>
        )}
      </Masonry>
    </div>
  );
};

export default ActionsSekeleton;
