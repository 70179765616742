import useGetFile from 'app/hooks/useGetFile';
import { selectors } from 'app/store/auth';
import { useState } from 'react';
import { useSelector } from 'react-redux';

const useMediaViewerActions = ({
  imageIds,
  imageRef,
}: {
  imageIds: string[];
  imageRef: HTMLImageElement | null;
}) => {
  const tenant = useSelector(selectors.getSelectedTenant);
  const [selectedImage, setSelectedImage] = useState(0);

  const { refetch: getFileUrl } = useGetFile(
    tenant?.alias ?? '',
    imageIds[selectedImage],
    'fileName'
  );

  const onNextClick = () => {
    if (imageIds.length === 1) return;

    if (selectedImage + 1 > imageIds.length - 1) {
      return setSelectedImage(0);
    }

    setSelectedImage((prev) => prev + 1);
  };

  const onPreviousClick = () => {
    if (imageIds.length === 1) return;

    if (selectedImage - 1 < 0) {
      return setSelectedImage(imageIds.length - 1);
    }

    setSelectedImage((prev) => prev - 1);
  };

  const zoomImage = () => {
    if (!imageRef) return;

    if (imageRef.style.transform === 'scale(2) translate(-25%, -25%)') {
      imageRef.style.transform = 'scale(1) translate(-50%, -50%)';
      return;
    }
    imageRef.style.transform = 'scale(2) translate(-25%, -25%)';
  };

  const downloadFile = async () => {
    const directUrls = await getFileUrl().catch();
    const directUrl = directUrls.data?.originalUrl ?? directUrls.data?.url;

    if (!directUrl) return;

    const res = await fetch(directUrl);

    const blob = await res.blob();
    const fileData = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = fileData;
    a.download = `action_answer_${imageIds[selectedImage]}`;

    document.body.appendChild(a);

    a.click();

    document.body.removeChild(a);

    URL.revokeObjectURL(fileData);
  };

  const downloadExternalFile = async (externalSrc: string) => {
    const res = await fetch(externalSrc);

    const blob = await res.blob();
    const fileData = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = fileData;
    a.download = externalSrc;

    document.body.appendChild(a);

    a.click();

    document.body.removeChild(a);

    URL.revokeObjectURL(fileData);
  };

  return {
    selectedImage,
    onNextClick,
    onPreviousClick,
    zoomImage,
    downloadFile,
    downloadExternalFile,
    imageRef,
  };
};

export default useMediaViewerActions;
