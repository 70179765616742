import { useEffect, useState } from 'react';

import { NestedComment } from '../context';

const useCommenAndParentsTree = (commentAndParents: NestedComment[]) => {
  const [commentTree, setCommentTree] = useState<Array<NestedComment>>([]);

  useEffect(() => {
    if (!commentAndParents || commentAndParents.length === 0) return;

    const idToCommentMap: Record<number, NestedComment> = {};

    commentAndParents.forEach(
      (cap) => (idToCommentMap[cap.id] = { ...cap, children: [] })
    );

    const dataTree: Array<NestedComment> = [];
    commentAndParents.forEach((cap) => {
      if (cap.parentCommentId && idToCommentMap[cap.parentCommentId]) {
        idToCommentMap[cap.parentCommentId].children?.push(
          idToCommentMap[cap.id]
        );
        return;
      }

      dataTree.push(idToCommentMap[cap.id]);
    });
    setCommentTree(dataTree);
  }, [commentAndParents]);

  return commentTree;
};

export default useCommenAndParentsTree;
