import { request } from '../../request';
import { ArticleCollaboratorsResponse } from '../types';

const getArticleCollaborators = (
  role: string,
  permissions: string,
  search: string | undefined,
  perPage?: number,
  page?: number
) =>
  request().get<ArticleCollaboratorsResponse>(`/api/v1/users`, {
    params: {
      role,
      permissions,
      name: search,
      page,
      per_page: perPage,
    },
  });

export { getArticleCollaborators };
export default getArticleCollaborators;
