import Tooltip from 'app/components/Tooltip';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { selectors } from 'app/store/auth';
import { Setting3 } from 'iconsax-react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useAnswersContext } from '../context';

import AudiencesDropdown from './AudiencesDropdown';
import Locations from './Locations';
import Professions from './Professions';
import Skeleton from './Skeleton';
import TenantsDropdown from './TenantsDropdown';

const Filter = () => {
  const { t } = useArticlesTranslation();
  const sessionTenant = useSelector(selectors.getSelectedTenant);
  const {
    selectedAudiences,
    locations,
    isLoadingAudience,
    selectedTenant,
    selectedTaskId,
    isLoadingTenants,
    article,
  } = useAnswersContext();

  const tooltipContent =
    article?.isShared && !article.originalTenant ? (
      t(
        'Filtering by external partner is currently not available at the action level. Please return to the answers overview and adjust the filter there.'
      )
    ) : (
      <Trans
        components={{
          a: (
            <a
              target="_blank"
              className="underline"
              href="https://help.atobi.io"
              rel="noreferrer"
            >
              Learn more
            </a>
          ),
        }}
        i18nKey="articles.This article has not been shared with any external partners. Learn more about Atobi Connect."
      />
    );

  return (
    <div className="p-3 w-[330px]">
      {!isLoadingTenants && (
        <div className="flex">
          <Setting3 size={24} />
          <span className="font-bold ml-1">{t('Filter by')}</span>
        </div>
      )}
      <div className="flex flex-col py-4">
        {!isLoadingTenants ? (
          <>
            <span className="font-bold text-sm mb-3">
              {t('External partner')}
            </span>

            <Tooltip
              fixedWidth={true}
              centerText={true}
              content={tooltipContent}
              show={
                !!selectedTaskId ||
                (!article?.isShared &&
                  sessionTenant?.alias !== article?.originalTenant?.name)
              }
              delay={100}
            >
              <div>
                <TenantsDropdown />
              </div>
            </Tooltip>
          </>
        ) : (
          <div className="flex w-full h-12 items-center rounded-lg text-sm text-focus p-3 bg-focus-background opacity-50" />
        )}

        {selectedTaskId && !selectedTenant && (
          <>
            <span className="font-bold text-sm mb-3 mt-4">{t('Audience')}</span>
            <AudiencesDropdown />

            {selectedAudiences.length > 0 && !isLoadingAudience && (
              <>
                <div className="flex mt-6">
                  <Setting3 size={24} />
                  <span className="font-bold ml-1">{t('Filter by')}</span>
                </div>
                <Locations locations={locations} />
                <Professions />
              </>
            )}
            {isLoadingAudience && <Skeleton disabled={false} />}
            {selectedAudiences.length === 0 && (
              <Skeleton
                disabled={true}
                text={t('Pick an Audience to enable filtering')}
              />
            )}
          </>
        )}
        {!!selectedTaskId && selectedTenant && (
          <Skeleton
            disabled={true}
            text={t(
              'Filters not available when previewing shared data due to privacy policies.'
            )}
          />
        )}
      </div>
    </div>
  );
};

export default Filter;
