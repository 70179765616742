import { useScreenBreakpoint } from 'app/hooks';
import { useModulesTranslation } from 'app/internationalization/hooks';
import { ModulesKeys } from 'app/internationalization/types';
import { isMobile } from 'app/utils';
import { Icon as IconType } from 'iconsax-react';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { RemixiconReactIconComponentType } from 'remixicon-react';

import BreadcrumbTruncate from './BreadcrumbTruncate';

interface TitleWithTranslationKey {
  translationKey: ModulesKeys;
}

type Title = TitleWithTranslationKey | string;

interface DefaultBreadcrumbProps {
  title?: Title;
  to: string;
  Icon?: IconType | RemixiconReactIconComponentType;
}

const DefaultBreadcrumb: FC<DefaultBreadcrumbProps> = ({ to, title, Icon }) => {
  const { t } = useModulesTranslation();
  const breakpoint = useScreenBreakpoint();
  const _isMobile = isMobile(breakpoint);

  const getTitle = () => {
    if (!title) return '...';

    if (typeof title === 'string') {
      return title;
    }

    return t(title.translationKey);
  };

  return (
    <>
      <Link to={to}>
        {Icon && !_isMobile ? (
          <div className="flex items-center text-grayscale-primary">
            {Icon && (
              <div className="mr-1">
                <Icon size={18} />
              </div>
            )}
            <BreadcrumbTruncate title={getTitle()} />
          </div>
        ) : (
          <BreadcrumbTruncate title={getTitle()} />
        )}
      </Link>
    </>
  );
};

export default DefaultBreadcrumb;
