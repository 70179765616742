import { VMGuidesKey } from '../types';

import useBaseTranslation from './useBaseTranslation';

const useVMGuidesTranslation = () =>
  useBaseTranslation<VMGuidesKey>(undefined, {
    keyPrefix: 'vmguides',
  });

export default useVMGuidesTranslation;
