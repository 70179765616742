import { Frame, useEditor } from '@craftjs/core';
import { cx } from '@emotion/css';
import { logEventArticle } from 'app/api/articles/resources';
import { useSelector } from 'app/hooks';
import { editorTypes } from 'app/router/constants';
import { selectors } from 'app/store/editor';
import { PropsWithChildren, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import useArticleBlocks from '../hooks/useArticleBlocks';

import Dashboard from './Dashboard';
import EditorBottomActions from './EditorBottomActions';

interface EditorCanvasProps {
  animate?: boolean;
  className?: string;
  hasClickOutside?: boolean;
  mode: keyof typeof editorTypes;
}

const EditorCanvas = ({
  animate,
  className,
  children,
  hasClickOutside,
  mode,
}: EditorCanvasProps & PropsWithChildren) => {
  const {
    store: {
      actions: { deserialize, selectNode },
    },
  } = useEditor((state, query) => ({
    title: state?.nodes?.title?.data?.props?.text,
    nodes: query.getNodes(),
  }));

  const article = useSelector(selectors.getArticle);
  const editorTab = useSelector(selectors.getEditorTab);
  const selectedLanguages = useSelector(selectors.getSelectedLanguages);
  const mainLanguage = selectedLanguages.find((l) => l.isDefault)?.code ?? 'en';
  const shownAs = useSelector(selectors.getShownAs);

  const { blocks } = useArticleBlocks(mainLanguage);

  const isActionsVisible =
    article?.isReactingAllowed && mode === editorTypes.actions;

  useEffect(() => {
    if (!blocks || shownAs.toggled) return;

    deserialize(blocks);
  }, [blocks, deserialize, shownAs.toggled]);

  useEffect(() => {
    if (article?.id) logEventArticle(article?.id);
  }, [article?.id]);

  const isDashboard = editorTab === 'results' || editorTab === 'shared';

  const renderContent = () => {
    const content = (
      <div className="flex flex-col max-w-[100vw]">
        <div
          className={cx(
            'lg:w-[792px] lg:min-h-[550px] lg:mb-[120px] bg-white lg:rounded-lg shadow-atobi transition duration-300',
            { 'lg:-translate-x-[50px]': animate }
          )}
        >
          <Frame />
          {isActionsVisible && <EditorBottomActions />}
        </div>
        {children}
      </div>
    );

    return (
      <div>
        {hasClickOutside ? (
          <OutsideClickHandler onOutsideClick={() => selectNode(undefined)}>
            {content}
          </OutsideClickHandler>
        ) : (
          content
        )}
      </div>
    );
  };

  return (
    <div
      id="editorCanvas"
      className={cx(
        'relative flex h-full w-full mt-[54px] lg:mt-[96px]',
        {
          'lg:!mt-[12px] !w-[90%]': isDashboard,
          'justify-center': editorTab !== 'answers',
        },
        className
      )}
    >
      {editorTab === 'create' && renderContent()}
      {isDashboard && <Dashboard article={article} />}
    </div>
  );
};

export default EditorCanvas;
