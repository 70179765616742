import { Node } from '@craftjs/core/lib/interfaces/nodes';
import { YesNoNaOptionProps } from 'app/pages/ArticleEditor/components/blocks/tasks/YesNoNa/Option/YesNoNaOption';
import {
  Article,
  ChoiceSchema,
  ChoiceVariants,
  GetCategory,
  MultiChoiceBlock,
  MultiChoiceBlockRenderTypeEnum,
  MultiChoiceBlockV1,
  MultiChoiceVariants,
  TranslationStatusEnum,
} from 'submodules/common-ui/generated/api/gcs/api';

import { SEPARATOR } from '../apiToCraft';

import { Props } from './types';

const mapYesNoNaBlock = ({
  article,
  block,
  index,
  nodes,
  selectedLanguage,
  translationStatuses,
  schedule,
  blockId,
}: Props): MultiChoiceBlock => {
  let variants: MultiChoiceVariants = {};
  let yesNoNaBlockIndex = 0;

  if (blockId) {
    article.blocks.forEach((b, i) => {
      if (b.id === blockId) {
        variants = { ...b.variants } as MultiChoiceVariants;
        yesNoNaBlockIndex = i;
      }
    });
  }

  const optionsContainer = block.data.linkedNodes['optionsContainer'];
  const { choices, variants: updatedVariants } = getYesNoNaChoices(
    optionsContainer,
    article,
    yesNoNaBlockIndex,
    variants,
    selectedLanguage,
    translationStatuses,
    nodes,
    blockId
  );

  return {
    id: blockId,
    version: 1,
    parentId: null,
    position: index,
    type: block.data.props.type,
    categories:
      block.data.props.categories?.map(
        (category: GetCategory) => category.id
      ) ?? [],
    deadline: block.data.props.deadline,
    required: block.data.props.required,
    public: block.data.props.public,
    audiences: block.data.props.audiences,
    minCorrectAnswers: undefined,
    renderType: MultiChoiceBlockRenderTypeEnum.YesNoNa,
    mandatory: block.data.props.mandatory,
    schedule,
    choices,
    variants: {
      ...updatedVariants,
      [selectedLanguage]: {
        title: block.data.props.title ?? '',
        description: block.data.props.description ?? '',
        explanation: block.data.props.explanation ?? '',
        translationStatus: blockId
          ? translationStatuses[blockId][selectedLanguage] ?? 'draft'
          : 'draft',
      },
    },
  };
};

const getYesNoNaChoices = (
  key: string,
  article: Article,
  parentIndex: number,
  variants: MultiChoiceVariants,
  selectedLanguage: string,
  translationStatuses: Record<string, Record<string, TranslationStatusEnum>>,
  nodes: Record<string, Node>,
  blockId?: number | undefined
) => {
  let choiceVariants: ChoiceVariants = {};
  const choices: ChoiceSchema[] = nodes[key].data.nodes.map((n, i) => {
    const choiceId = n.split(SEPARATOR)[1];

    (article.blocks[parentIndex] as MultiChoiceBlockV1)?.choices?.forEach(
      (c) => {
        if (choiceId === c.id.toString()) {
          choiceVariants = c.variants;
        }
      }
    );

    const optionProps = nodes[n].data.props as YesNoNaOptionProps;

    Object.keys(variants).forEach((language) => {
      if (blockId)
        variants[language] = {
          ...variants[language],
          ...{
            translationStatus:
              translationStatuses[blockId][language] ??
              variants[language].translationStatus,
          },
        };
    });

    return {
      ...(!isNaN(Number(choiceId)) && { id: Number(choiceId) }),
      correct: optionProps.correct ?? null,
      position: i,
      answerType: 'string',
      variants: {
        ...choiceVariants,
        [selectedLanguage]: {
          ...(optionProps?.text && {
            answer: optionProps.text,
          }),
        },
      },
    };
  });

  return { choices, variants };
};

export default mapYesNoNaBlock;
