const LoadingQuizScore = () => {
  return (
    <div className="flex flex-col gap-1 mb-3">
      <div className="flex justify-between">
        <div className="h-[108px] w-full bg-focus-background rounded" />
      </div>
    </div>
  );
};

export default LoadingQuizScore;
