import { request } from 'app/api/gcsRequest';
import config from 'app/config';
import { CommentAndParentsApi } from 'submodules/common-ui/generated/api/gcs';

const getCommentAndParents = (commentId: number) =>
  new CommentAndParentsApi(
    undefined,
    config.env.gcsApiUrl,
    request()
  ).getCommentAndParentsById(commentId);

export { getCommentAndParents };
export default getCommentAndParents;
