import { Node } from '@craftjs/core/lib/interfaces/nodes';
import { cx } from '@emotion/css';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { RecurringArticleDate } from 'app/pages/Articles/Components/RecuringArticleModal/types';
import useRecurringDateAsSchedule from 'app/pages/Editor/hooks/useRecurringDateAsSchedule';
import { Edit2 as Edit, InfoCircle } from 'iconsax-react';
import ReactTooltip from 'react-tooltip';
import { ActionTypeEnum } from 'submodules/common-ui/generated/api/gcs';

interface Props {
  checked: boolean;
  onSwitch: (check: boolean) => void;
  onEdit: () => void;
  recurringBlocks: number;
  totalBlocks: number;
  consistentSchedule?: RecurringArticleDate | null;
  actionBlocks: Node[];
}

const RecurringAction = ({
  checked,
  onSwitch,
  onEdit,
  recurringBlocks,
  totalBlocks,
  consistentSchedule,
  actionBlocks,
}: Props) => {
  const { t } = useArticlesTranslation();
  const { getFormattedDate } = useRecurringDateAsSchedule();

  const getButtonText = () => {
    if (!consistentSchedule && recurringBlocks > 0) {
      return t('Update');
    }
    return t('Edit');
  };

  const hasScormTask = actionBlocks.some(
    (block) => block.data.props.type === ActionTypeEnum.ScormTask
  );

  return (
    <div className="w-full rounded-lg border border-gray-light py-4 px-3 flex flex-col gap-2">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-1 flex-1">
          <div className="flex flex-col justify-center gap-0.5">
            <div className="flex gap-0.5">
              <span className="font-bold text-grayscale-primary text-xs">
                {t('Recurring actions')}
              </span>

              <InfoCircle
                data-for="react"
                data-tip={t(
                  'Article starts recurring on publish date and stops when it’s archived'
                )}
                className="text-grayscale-secondary"
                size={12}
              />
              <ReactTooltip
                place="bottom"
                effect="solid"
                className="react-tooltip"
                id="react"
              />
            </div>
            {hasScormTask ? (
              <span className="text-grayscale-primary text-xs">
                {t(
                  'Recurring disabled. Scorm block does not support recurring schedules'
                )}
              </span>
            ) : (
              recurringBlocks > 0 && (
                <span className="text-grayscale-primary text-xs">
                  {t(
                    '{{recurringBlocks}} of {{totalBlocks}} action(s) will recur',
                    {
                      recurringBlocks: recurringBlocks,
                      totalBlocks: totalBlocks,
                    }
                  )}
                </span>
              )
            )}
          </div>
        </div>
        {!consistentSchedule && (
          <button
            onClick={() => onSwitch(!checked)}
            disabled={hasScormTask}
            className={cx('text-xs text-focus flex pt-2 pb-3 p-3 rounded-md', {
              'bg-hover-blue': !hasScormTask,
              'bg-gray-light text-gray': hasScormTask,
            })}
          >
            {getButtonText()}
          </button>
        )}
      </div>
      {consistentSchedule && (
        <>
          <button
            type="button"
            className="flex px-2 justify-between border border-gray-light rounded h-10 items-center w-full bg-grayscale-bg-dark"
            onClick={onEdit}
          >
            <span className="text-sm text-left text-grayscale-primary">
              {consistentSchedule.recurring.label}
            </span>
            <Edit className="text-grayscale-secondary" size={16} />
          </button>
          <span className="text-grayscale-secondary text-xs">
            {t('Actions available {{startDate}}-{{endDate}}', {
              startDate: getFormattedDate(consistentSchedule.start),
              endDate: getFormattedDate(consistentSchedule.end),
            })}
          </span>
        </>
      )}
    </div>
  );
};

export default RecurringAction;
