import styled from '@emotion/styled';
import config from 'app/config';
import useCommentTranslation from 'app/internationalization/hooks/useCommentTranslation';
import CloseIcon from 'assets/icons/cross.svg?react';
import UndoIcon from 'assets/icons/undo.svg?react';
import { Edit2 as EditIcon } from 'iconsax-react';

import { useCommentsContext } from '../context';

const CommentActionMenu = () => {
  const { t } = useCommentTranslation();
  const { commentAction, setCommentAction, setText } = useCommentsContext();

  if (!commentAction) return null;

  const title =
    commentAction.type === 'edit'
      ? t('Editing comment')
      : t('Replying to comment');

  const icon =
    commentAction.type === 'edit' ? (
      <EditIcon className="w-5 h-5" />
    ) : (
      <UndoIcon className="w-5 h-5" />
    );

  const onClose = () => {
    setCommentAction(undefined);
    setText('');
  };

  return (
    <div className="h-10 w-full bg-hover-blue shadow-atobi flex justify-between py-2 px-4 items-center">
      <IconContainer className="flex text-ceil items-center">
        {icon}
        <span className="ml-1 text-sm">{title}</span>
      </IconContainer>
      <CloseIcon
        className="cursor-pointer text-grayscale-secondary w-5 h-5"
        onClick={onClose}
      />
    </div>
  );
};

const IconContainer = styled.div`
  g {
    stroke: ${config.colors.ceil};
  }
`;

export default CommentActionMenu;
