import { cx } from '@emotion/css';
import styled from '@emotion/styled';

const SekeletonRow = ({
  index,
  isColumn,
}: {
  index?: number;
  isColumn?: boolean;
}) => {
  return (
    <Wrapper className="flex flex-col">
      {index === 0 && <div className="w-[68px] h-4 bg-hover-blue rounded" />}
      <div className={cx('flex mt-3', { 'flex-col': isColumn })}>
        <div
          className={cx('bg-hover-blue rounded', {
            'w-[86px] h-[58px]': !isColumn,
            'w-[195px] h-[122px] mt-2.5': isColumn,
          })}
        />
        <div className={cx({ 'ml-2': !isColumn, 'mt-2': isColumn })}>
          <div className="w-[190px] h-5 bg-hover-blue rounded" />
          <div className="w-[134px] h-4.5 mt-2 bg-hover-blue rounded" />
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  animation: pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;

  @keyframes pulse {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.7;
    }
  }
`;

export default SekeletonRow;
