import { Modal } from 'app/components';
import { useDispatch, useSelector } from 'app/hooks';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import {
  selectors as modalSelectors,
  actions as modalActions,
} from 'app/store/modal';
import { ModalTypes } from 'app/store/modal/types';
import noop from 'lodash/noop';
import { useEffect } from 'react';

export type YesNoConfirmActionType = 'confirm' | 'cancel' | undefined;

export interface YesNoConfirmModalProps {
  actionType: YesNoConfirmActionType;
}

const YesNoConfirmModal = () => {
  const { t } = useArticlesTranslation();
  const { modalType, modalProps } = useSelector(modalSelectors.getOpenedModals);
  const dispatch = useDispatch();

  const handleActionType = (actionType: YesNoConfirmActionType) => {
    dispatch(
      modalActions.showModal({
        modalType: ModalTypes.YES_NO_NOT_POSSIBLE_MODAL,
        modalProps: {
          actionType,
        },
      })
    );
  };

  useEffect(() => {
    const { actionType } = modalProps as YesNoConfirmModalProps;
    if (actionType === 'cancel') {
      dispatch(modalActions.hideModal());
    }
  }, [dispatch, modalProps]);

  const option = `"${t('Not possible to answer')}"`;

  return (
    <Modal
      className="max-w-[450px]"
      onClose={
        modalType === ModalTypes.YES_NO_NOT_POSSIBLE_MODAL
          ? () => handleActionType(undefined)
          : noop
      }
    >
      <div className="flex flex-col gap-6">
        <span className="font-bold text-lg">
          {t('Mark Question as {{option}}?', {
            option,
          })}
        </span>
        <span className="font-normal text-base">
          {t(
            "You've selected {{option}} for this question. Are you sure you want to proceed?",
            {
              option,
            }
          )}
        </span>
        <div className="flex gap-2">
          <button
            type="button"
            className="flex items-center justify-center w-full h-12 rounded-xl bg-hover-blue text-focus"
            onClick={() => handleActionType('cancel')}
          >
            {t('Cancel')}
          </button>
          <button
            type="button"
            className="flex items-center justify-center w-full h-12 rounded-xl bg-focus text-white"
            onClick={() => handleActionType('confirm')}
          >
            {t('Confirm')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default YesNoConfirmModal;
