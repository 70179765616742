import { ClassNames } from '@emotion/react';
import React, { FC } from 'react';

interface IToolbarIcon {
  children: React.ReactNode;
  active: boolean;
  onClick: () => void;
}

const ToolbarIcon: FC<IToolbarIcon> = ({ active, children, onClick }) => {
  return (
    <ClassNames>
      {({ cx }) => (
        <button
          className={cx(
            'w-8 h-8 flex justify-center items-center rounded-sm group',
            {
              'bg-focus-background text-grayscale-primary': active,
              'transparent text-grayscale-secondary hover:text-grayscale-primary hover:bg-hover-blue':
                !active,
            }
          )}
          onClick={onClick}
        >
          {children}
        </button>
      )}
    </ClassNames>
  );
};

export default ToolbarIcon;
