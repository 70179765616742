import { resources } from 'app/api/articles';
import { useMutation } from 'react-query';

const useRevertAnswerMutation = () => {
  const { mutate, isLoading, isError, data, isSuccess } = useMutation(
    resources.revertAnswer
  );

  return { mutate, isLoading, isError, isSuccess, data };
};

export default useRevertAnswerMutation;
