import config from 'app/config';
import { SettingsApi } from 'submodules/common-ui/generated/api/sub-domain-config';

import { createCustomInstance } from '../../request';

const getSettings = (subdomain: string) => {
  const instance = createCustomInstance({
    disableCamelCaseConversion: false,
    url: config.env.subdomainApiUrl,
  });

  return new SettingsApi(
    undefined,
    config.env.subdomainApiUrl,
    instance
  ).getSettings(subdomain);
};

export { getSettings };
export default getSettings;
