import { createCustomInstance } from 'app/api/request';
import config from 'app/config';
import { FileSlot, FileSlotApi } from 'submodules/common-ui/generated/api/gcs';

const createFileSlot = ({ mimeType, name }: FileSlot) => {
  const instance = createCustomInstance({
    disableCamelCaseConversion: true,
    url: config.env.gcsApiUrl,
  });
  return new FileSlotApi(undefined, config.env.gcsApiUrl, instance)
    .createFileSlot({ mimeType, name })
    .then((response) => response.data);
};

export default createFileSlot;
