import {
  Profession,
  Reward,
  Location,
  Language,
  MemberRole,
} from '../auth/types';
import {
  ResponsiveMediaSizes,
  Response,
  OrderType,
  PaginatedResponseMeta,
} from '../types';

export interface PublicUserProfile {
  id: number;
  allLocations: Array<Location>;
  allProfessions: Array<Profession>;
  avatarsMobile: ResponsiveMediaSizes;
  avatars: ResponsiveMediaSizes;
  brands: Array<string>;
  client: string;
  email: string;
  firstName: string;
  lastName: string;
  lastSeen: string;
  location: Location;
  memberSince: string;
  messengerUsername: string;
  phone: string;
  profession: Profession;
  rewards: Array<Reward>;
  color: string;
  websiteId: number;
}

export type PublicUserProfileResponse = Response<PublicUserProfile>;

export interface PublicUserListProfile {
  id: number;
  fullName: string;
  location: Location;
  profession: Profession;
}

export type PublicUserListProfileResponse = Response<
  Array<PublicUserListProfile>
>;

export interface PublicUserChatDetails extends PublicUserListProfile {
  fullName: string;
  acsUserId: string | null;
  expoPushNotificationToken: string;
}
export type PublicUserChatDetailsListResponse = Response<
  Array<PublicUserChatDetails>
>;

export enum UserStatus {
  active = 'active',
  archived = 'archived',
  anonymized = 'anonymized',
}

export enum SortByColumn {
  name = 'first_name',
  date = 'last_login_at',
}
export interface GetMemberListRequest {
  status: UserStatus;
  sortedByColumn: SortByColumn;
  order: OrderType;
  page: number;
  query?: string;
  per_page?: number;
}

export interface UserMember {
  id: number;
  locations: Array<Location>;
  professions: Array<Profession>;
  language: Language;
  languages: Array<Language>;
  uiLanguage: number;
  translationLanguage: string;
  roles: Array<MemberRole>;
  avatar: string;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  username: string;
  status: number;
  createdAt: number;
  lastLoginAt: number;
  timezone: string;
  isAdmin: boolean;
  isOwner: boolean;
  isPushNotificationDisabled: boolean;
  hasLoggedIn: boolean;
}

export interface UserMemberQueried {
  id: number;
  locations: Array<Location>;
  professions: Array<Profession>;
  language: Language;
  languages: Array<{ code: number; lngname: string }>;
  // roles: Array<MemberRole>;
  rolekey: string;
  avatars: ResponsiveMediaSizes;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  username: string;
  status: number;
  memberSince: string;
  updated: string;
  timezone: string;
  isActive: boolean;
  isAdmin: boolean;
  isOwner: boolean;
  isPushNotificationDisabled: boolean;
  interfaceLanguage: Language;
  translationLanguage: TranslationLanguage;
}

export type MembersResponse = {
  data: Array<UserMember>;
  meta: PaginatedResponseMeta;
};

export interface EmailTemplate {
  email_template_type_id: number;
  id: number;
  name: string;
  slug: string;
  subject: string;
  created_at: Date;
  updated_at: Date;
}

export interface TranslationLanguage {
  name: string;
  code: string;
}

export type MemberCreationInfoResponse = {
  locations: Array<Location>;
  professions: Array<Profession>;
  roles: Array<MemberRole>;
  languages: Array<Language>;
  emailTemplates: Array<EmailTemplate>;
  translationLanguages: Array<TranslationLanguage>;
  useUsername: boolean;
};

export type MemberDetailRepsonse = Response<UserMemberQueried>;
