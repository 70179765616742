import useArticles from 'app/api/articles/hooks/useArticles';
import { useAuthenticatedUser } from 'app/api/auth/hooks';
import { UserProfile } from 'app/api/auth/types';
import useGetChannelsQuery from 'app/api/channels/hooks/useChannelsQuery';
import { selectors } from 'app/store/common';
import React, { createContext, ReactNode, useContext, useState } from 'react';
import {
  InfiniteData,
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from 'react-query/types/core/types';
import { useSelector } from 'react-redux';
import {
  ArticlePage,
  ActionAnswerPageMeta,
  ArticleStatusEnum,
  BasicChannelInfo,
  ListArticle,
  GetArticlePageRealStatusEnum,
} from 'submodules/common-ui/generated/api/gcs';

export interface ArticleFilters extends Omit<ArticleFilter, 'status'> {
  status?: ArticleStatusEnum;
}

type RefetchArticleList =
  | undefined
  | (<TPageData>(
      options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<InfiniteData<ArticlePage>, unknown>>);

type ArchiveModalState = { open: boolean; ids: Array<number> };
type DeleteModalState = { open: boolean; ids: Array<number> };

const uninitialisedFunction = () => new Error('Uninitialsed Function');

export const ArticlesContext = createContext<ArticlesContextValues>({
  archiveModalState: { open: false, ids: [] },
  articleFilters: {
    status: 'draft',
  },
  articles: undefined,
  inboundArticles: undefined,
  inboundMeta: undefined,
  articlesMeta: undefined,
  channels: [],
  deleteModalState: { open: false, ids: [] },
  isLoading: false,
  isLoadingInbound: false,
  page: 0,
  inboundPage: 0,
  refetch: undefined,
  selectedArticle: undefined,
  selectedArticles: [],
  selectedChannels: [],
  user: undefined,
  hasCreateArticlePermission: true,
  setInboudPage: uninitialisedFunction,
  changePage: uninitialisedFunction,
  setArchiveModalState: uninitialisedFunction,
  setArticleFilters: uninitialisedFunction,
  setDeleteModalState: uninitialisedFunction,
  setSelectedArticle: uninitialisedFunction,
  setSelectedArticles: uninitialisedFunction,
  setSelectedChannels: uninitialisedFunction,
  setInboundFilter: uninitialisedFunction,
});

type SortValues = 'asc' | 'desc';
interface ArticleFilter {
  title?: string;
  status?: ArticleStatusEnum | 'scheduled';
  realStatus?: GetArticlePageRealStatusEnum;
  createdBy?: number;
  archivedAt?: SortValues;
  createdAt?: SortValues;
  updatedAt?: SortValues;
  publishedAt?: SortValues;
  deadline?: SortValues;
  channelId?: string;
  live?: '1';
  unseen?: '1';
  inprogress?: '1';
  progress?: SortValues;
  timezone?: string;
  include?: 'audiences';
  categoryIds?: string[];
  excludeFeedPosts?: '1';
}

interface ArticlesContextValues {
  archiveModalState: ArchiveModalState;
  articleFilters: ArticleFilters;
  articles: ListArticle[] | undefined;
  inboundArticles: ListArticle[] | undefined;
  articlesMeta: ActionAnswerPageMeta | undefined;
  inboundMeta: ActionAnswerPageMeta | undefined;
  isLoadingInbound: boolean;
  channels: BasicChannelInfo[];
  deleteModalState: DeleteModalState;
  isLoading: boolean;
  page: number;
  inboundPage: number;
  selectedArticle: ListArticle | undefined;
  selectedArticles: Array<number>;
  selectedChannels: BasicChannelInfo[];
  user: UserProfile | undefined;
  hasCreateArticlePermission: boolean;
  setInboudPage: React.Dispatch<React.SetStateAction<number>>;
  changePage: React.Dispatch<React.SetStateAction<number>>;
  refetch: RefetchArticleList;
  setArchiveModalState: React.Dispatch<React.SetStateAction<ArchiveModalState>>;
  setArticleFilters: React.Dispatch<React.SetStateAction<ArticleFilters>>;
  setDeleteModalState: React.Dispatch<React.SetStateAction<DeleteModalState>>;
  setSelectedArticle: React.Dispatch<
    React.SetStateAction<ListArticle | undefined>
  >;
  setSelectedArticles: React.Dispatch<React.SetStateAction<Array<number>>>;
  setSelectedChannels: React.Dispatch<React.SetStateAction<BasicChannelInfo[]>>;
  setInboundFilter: React.Dispatch<React.SetStateAction<SortValues>>;
}

type ContextProps = {
  children: ReactNode;
};

const ArticlesContextProvider = ({ children }: ContextProps) => {
  const articleStudioTab = useSelector(selectors.getArticleStudioTab);
  const { data: user, hasCreateArticlePermission } = useAuthenticatedUser();

  const initialFilterState: ArticleFilters = {
    realStatus: 'draft',
    include: 'audiences',
  };

  const [articleFilters, setArticleFilters] =
    useState<ArticleFilters>(initialFilterState);

  const [page, setPage] = useState(0);
  const [inboundPage, setInboudPage] = useState(0);
  const [inboundFilter, setInboundFilter] = useState<SortValues>('desc');
  const [selectedArticle, setSelectedArticle] = useState<ListArticle>();
  const [selectedArticles, setSelectedArticles] = useState<Array<number>>([]);
  const [archiveModalState, setArchiveModalState] = useState<ArchiveModalState>(
    {
      open: false,
      ids: [],
    }
  );
  const [deleteModalState, setDeleteModalState] = useState<DeleteModalState>({
    open: false,
    ids: [],
  });

  const { data: channels } = useGetChannelsQuery({
    ranking: 'desc',
    refetchOnMount: true,
  });

  const [selectedChannels, setSelectedChannels] = useState<BasicChannelInfo[]>(
    []
  );

  const {
    data: articles,
    meta: articlesMeta,
    isLoading,
    refetch,
  } = useArticles({
    page,
    filters: articleFilters,
    refetchOnWindowFocus: true,
    enabled: articleStudioTab == 'articles',
  });

  const {
    data: inboundArticles,
    meta: inboundMeta,
    isLoading: isLoadingInbound,
  } = useArticles({
    page: inboundPage,
    filters: {
      createdAt: inboundFilter,
      status: 'inbound',
    },
    enabled: articleStudioTab === 'atobi_connect',
  });

  const contextValue: ArticlesContextValues = {
    archiveModalState,
    articleFilters,
    articles,
    inboundArticles,
    articlesMeta,
    inboundMeta,
    isLoadingInbound,
    channels,
    deleteModalState,
    isLoading,
    page,
    inboundPage,
    selectedChannels,
    selectedArticle,
    selectedArticles,
    user,
    hasCreateArticlePermission,
    setInboudPage,
    changePage: setPage,
    refetch,
    setArchiveModalState,
    setArticleFilters,
    setDeleteModalState,
    setSelectedArticle,
    setSelectedArticles,
    setSelectedChannels,
    setInboundFilter,
  };

  return (
    <ArticlesContext.Provider value={contextValue}>
      {children}
    </ArticlesContext.Provider>
  );
};

const useArticlesContext = () => {
  const context = useContext(ArticlesContext);

  if (context === undefined) {
    throw new Error(
      'useArticlesContext must be used within a ArticlesContextProvider'
    );
  }

  return context;
};

export { ArticlesContextProvider, useArticlesContext };
export type { ArticlesContextValues, ArticleFilter, SortValues };
