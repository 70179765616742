import { useComponentsTranslation } from 'app/internationalization/hooks';
import { missionListTypes } from 'app/router/constants';
import { useParams } from 'react-router-dom';

import DefaultBreadcrumb from './DefaultBreadcrumb';

const MissionsListBreadcrumb = () => {
  const { category } = useParams<{ category: string }>();
  const { t } = useComponentsTranslation();

  const getTitle = () => {
    switch (category) {
      case missionListTypes.active:
        return t('In Progress');
      case missionListTypes.completed:
        return t('Completed');
      case missionListTypes.new:
        return t('Not Started');
      default:
        return t('Mission list');
    }
  };

  return <DefaultBreadcrumb title={getTitle()} to={window.location.href} />;
};

export default MissionsListBreadcrumb;
