import { useDispatch } from 'app/hooks';
import useCommentTranslation from 'app/internationalization/hooks/useCommentTranslation';
import { useCommentsContext } from 'app/pages/Comments/context';
import { actions } from 'app/store/comments';
import { Send as SendMessageIcon } from 'iconsax-react';

const CommentBottomAction = () => {
  const { t } = useCommentTranslation();
  const dispatch = useDispatch();

  const { comments, currentUser } = useCommentsContext();

  return (
    <button
      className="w-full"
      onClick={() => dispatch(actions.showCommentModal())}
    >
      <div className="flex items-center text-sm mb-2.5">
        <span className="font-bold mr-1">{t('Comments')}</span>
        {comments && (
          <span className="text-grayscale-secondary">{comments.length}</span>
        )}
      </div>
      <div className="flex items-center w-full">
        <img
          className="w-10 h-10 rounded mr-3"
          src={currentUser?.avatars?.small}
          alt="avatar"
        />
        <div className="p-3 relative w-full flex items-center justify-between border-[0.5px] bg-grayscale-bg-dark border-gray-dark rounded">
          <span className="text-grayscale-secondary text-sm">
            {t('Add a comment')}
          </span>
          <SendMessageIcon size={16} className="text-focus" />
        </div>
      </div>
    </button>
  );
};

export default CommentBottomAction;
