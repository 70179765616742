import config from 'app/config';
import axios from 'axios';

import { RefreshTokenReponse } from '../types';

const refreshToken = (token: string) => () => {
  return axios.post<RefreshTokenReponse>(
    `/connect/token`,
    new URLSearchParams({
      refresh_token: token,
      grant_type: 'refresh_token',
      client_id: config.env.identityServerClientId,
    }),
    {
      baseURL: config.env.identityServerUri,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: '*/*',
      },
    }
  );
};

export default refreshToken;
