import { useArticlesTranslation } from 'app/internationalization/hooks';
const useResolveSentiment = () => {
  const { t } = useArticlesTranslation();

  return (score: number) => {
    switch (true) {
      case score < 4:
        return {
          sentiment: t('Negative'),
          color: 'text-error',
          tooltip: t(
            'Responses express dissatisfaction, frustration, or negative feelings. They highlight significant issues or concerns.'
          ),
        };
      case score < 8:
        return {
          sentiment: t('Neutral'),
          color: 'text-warning',
          tooltip: t(
            'Responses are balanced, expressing neither strong satisfaction nor significant dissatisfaction. They provide a mix of positive and negative feedback or are indifferent.'
          ),
        };
      default:
        return {
          sentiment: t('Positive'),
          color: 'text-success',
          tooltip: t(
            'Responses convey satisfaction, happiness, or positive feelings. They highlight favorable experiences or strong approval.'
          ),
        };
    }
  };
};

export default useResolveSentiment;
