import { useEditor } from '@craftjs/core';
import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import { Modal } from 'app/components';
import config from 'app/config';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import useGlossaryTranslation from 'app/internationalization/hooks/useGlossaryTranslation';
import PollBlock from 'app/pages/ArticleEditor/components/blocks/tasks/Poll';
import QuizBlock from 'app/pages/ArticleEditor/components/blocks/tasks/Quiz';
import Scorm from 'app/pages/ArticleEditor/components/blocks/tasks/ScormBlock';
import SimpleTask from 'app/pages/ArticleEditor/components/blocks/tasks/SimpleTask';
import YesNoNaBlock from 'app/pages/ArticleEditor/components/blocks/tasks/YesNoNa';
import { ACTION_BLOCKS } from 'app/pages/Editor/helpers/blocks';
import { outsideRoutes } from 'app/router';
import a11yKeydown from 'app/utils/a11y/keydown';
import MediaTaskPreview from 'assets/images/media-task.png';
import OpenQuestionPreview from 'assets/images/open-question.png';
import PollTaskPreview from 'assets/images/poll-task.png';
import QuizTaskPreview from 'assets/images/quiz-task.png';
import ScormTaskPreview from 'assets/images/scorm-task.png';
import SimpleTaskPreview from 'assets/images/simple-task.png';
import YesNoTaskPreview from 'assets/images/yes-no-task.png';
import { Icon as IconType, Medal, Add } from 'iconsax-react';
import { useEffect, useState } from 'react';
import {
  ActionTypeEnum,
  MultiChoiceBlockRenderTypeEnum,
} from 'submodules/common-ui/generated/api/gcs';

interface ActionBlockProps {
  text: string;
  Icon: IconType | React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  type: ActionTypeEnum;
  renderType?: MultiChoiceBlockRenderTypeEnum;
  enabled?: boolean;
}

const ActionBlockOption = ({
  text,
  Icon,
  type,
  renderType,
  enabled = true,
}: ActionBlockProps) => {
  const { t } = useArticlesTranslation();
  const { t: gt } = useGlossaryTranslation();

  const [pressing, setPressing] = useState(false);
  const [hoveredOption, setHoveredOption] = useState<ActionTypeEnum>();
  const [showDisabledModal, setShowDisabledModal] = useState(false);

  const {
    connectors: { create },
    actions: { addNodeTree },
    query: { parseReactElement, getNodes },
  } = useEditor();

  const getBlockToRender = (): JSX.Element => {
    const nodes = getNodes();

    const taskWithAudiences = Object.keys(nodes).find((key) =>
      ACTION_BLOCKS.includes(nodes[key].data.displayName)
    );

    const assigneeAudiences = taskWithAudiences
      ? nodes[taskWithAudiences].data.props.audiences
      : [];

    switch (type) {
      case ActionTypeEnum.MediaTask:
      case ActionTypeEnum.SimpleTask:
      case ActionTypeEnum.OpenQuestionTask:
      case ActionTypeEnum.YesNoTask:
        return (
          <SimpleTask
            type={type}
            required={-1}
            public={true}
            completed={false}
            audiences={assigneeAudiences}
            translationStatus="draft"
          />
        );
      case ActionTypeEnum.MultiChoice:
        return getMultiChoiceBlock(assigneeAudiences);
      case ActionTypeEnum.ScormTask:
        return (
          <Scorm
            audiences={assigneeAudiences}
            completed={false}
            public={true}
            required={-1}
            translationStatus="draft"
            type={type}
          />
        );
      default:
        return (
          <QuizBlock
            type={ActionTypeEnum.MultiChoice}
            required={-1}
            public={true}
            completed={false}
            audiences={assigneeAudiences}
            translationStatus="draft"
            renderType={MultiChoiceBlockRenderTypeEnum.Quiz}
          />
        );
    }
  };

  const getMultiChoiceBlock = (audiences: number[]) => {
    switch (renderType) {
      case MultiChoiceBlockRenderTypeEnum.Poll:
        return (
          <PollBlock
            type={ActionTypeEnum.MultiChoice}
            required={-1}
            public={true}
            completed={false}
            audiences={audiences}
            translationStatus="draft"
            renderType={MultiChoiceBlockRenderTypeEnum.Poll}
          />
        );
      case MultiChoiceBlockRenderTypeEnum.YesNoNa:
        return (
          <YesNoNaBlock
            type={ActionTypeEnum.MultiChoice}
            required={-1}
            public={true}
            completed={false}
            audiences={audiences}
            translationStatus="draft"
            renderType={MultiChoiceBlockRenderTypeEnum.YesNoNa}
          />
        );
      default:
        return (
          <QuizBlock
            type={ActionTypeEnum.MultiChoice}
            required={-1}
            public={true}
            completed={false}
            audiences={audiences}
            translationStatus="draft"
            renderType={MultiChoiceBlockRenderTypeEnum.Quiz}
          />
        );
    }
  };

  const addBlock = () => {
    if (!enabled) return setShowDisabledModal(true);
    const block = parseReactElement(getBlockToRender()).toNodeTree();
    addNodeTree(block, 'dropableRegion');
  };

  const renderDisabledOptionContent = () => {
    let contentTitle;
    let contentDescription;

    if (type === 'scorm_task') {
      contentTitle = t('Leverage your existing library');
      contentDescription = t(
        'Do you already have a library of content created with SCORM? Then, save time by embedding it directly into Atobi and keep measuring the results.'
      );
    }

    return (
      <>
        {contentTitle && (
          <span className="text-lg font-bold">{contentTitle}</span>
        )}
        {contentDescription && (
          <span className="text-base text-primary text-center max-w-[328px]">
            {contentDescription}
          </span>
        )}
      </>
    );
  };

  const renderPreview = () => {
    let previewImage;
    let previewText;
    switch (type) {
      case ActionTypeEnum.MediaTask:
        previewImage = MediaTaskPreview;
        previewText = t('Get visual confirmation of how it looks');
        break;
      case ActionTypeEnum.SimpleTask:
        previewImage = SimpleTaskPreview;
        previewText = t('Confirm and track if something is done');
        break;
      case ActionTypeEnum.OpenQuestionTask:
        previewImage = OpenQuestionPreview;
        previewText = t('Get open answer for the question');
        break;
      case ActionTypeEnum.YesNoTask:
        previewImage = YesNoTaskPreview;
        previewText = t('Ask question with a Yes / No answer');
        break;
      case ActionTypeEnum.MultiChoice: {
        const { image, text: txt } = getMultiChoicePreview();
        previewImage = image;
        previewText = txt;
        break;
      }
      case ActionTypeEnum.ScormTask:
        previewImage = ScormTaskPreview;
        previewText = t('Add a Scorm Package and track member’s completion');
        break;
      default:
        previewImage = QuizTaskPreview;
        previewText = t(
          'Test people’s knowledge with single or multiple-choice quizzes.'
        );
        break;
    }

    return (
      <div className="flex flex-col w-[148px] p-2">
        <img src={previewImage} alt="Help" className="h-auto" />
        <span className="text-white text-[10px] mt-2 text-start">
          {previewText}
        </span>
      </div>
    );
  };

  const getMultiChoicePreview = () => {
    switch (renderType) {
      case MultiChoiceBlockRenderTypeEnum.YesNoNa:
        return {
          image: YesNoTaskPreview,
          text: t('Ask question with a Yes / No answer'),
        };
      case MultiChoiceBlockRenderTypeEnum.Poll:
        return {
          image: PollTaskPreview,
          text: t('Gather opinions and insights by creating a survey.'),
        };
      default:
        return {
          image: QuizTaskPreview,
          text: t(
            'Test people’s knowledge with single or multiple-choice quizzes.'
          ),
        };
    }
  };

  const renderOptionIcon = () => {
    if ('propTypes' in Icon && Icon.propTypes?.color) {
      return <Icon className="ml-3 mr-2" size={16} />;
    }
    return <Icon className="ml-3 mr-2" size={16} />;
  };

  useEffect(() => {
    if (pressing) setHoveredOption(undefined);
  }, [pressing]);

  return (
    <>
      <div
        data-tip
        data-for={type}
        role="button"
        ref={(ref) => ref && create(ref, getBlockToRender())}
        tabIndex={0}
        onClick={addBlock}
        onKeyDown={(e) => a11yKeydown(e, addBlock)}
        onMouseDown={() => setPressing(true)}
        onMouseUp={() => setPressing(false)}
        onDragStart={() => setPressing(true)}
        onDragEnd={() => setPressing(false)}
        onMouseEnter={() => {
          setHoveredOption(type);
        }}
        onMouseLeave={() => {
          setHoveredOption(undefined);
        }}
        className={cx(
          `flex items-center rounded mt-3 h-8.5 min-w-[148px] cursor-grab relative`,
          {
            'cursor-grabbing transform translate-0 bg-focus-background border border-focus text-focus':
              pressing,
            'bg-gray-white-shadow hover:bg-hover-blue border border-transparent text-grayscale-primary':
              !pressing,
          }
        )}
      >
        <div>{renderOptionIcon()}</div>
        <span className="text-xs text-black inline-flex gap-1 items-center">
          {text}
          {!enabled && (
            <span className="text-warning pr-2">
              <Medal size={16} />
            </span>
          )}
        </span>
        <div
          className={cx(
            'h-4.5 w-4.5 flex justify-center items-center absolute -top-1.5 -left-1.5 bg-focus rounded-lg text-white text-xs',
            { hidden: !pressing }
          )}
        >
          +
        </div>
        <Tooltip
          className={cx(
            'whitespace-pre-wrap !rounded !opacity-100 bg-gray-extra-dark top-12 absolute left-0 right-0 z-999',
            {
              'inline-block': hoveredOption === type,
              hidden: hoveredOption !== type,
            }
          )}
        >
          {renderPreview()}
        </Tooltip>
      </div>
      {showDisabledModal && (
        <Modal onClose={() => setShowDisabledModal(false)}>
          <div className="flex flex-col gap-2 items-center relative">
            <button
              onClick={() => setShowDisabledModal(false)}
              className="absolute top-0 right-0 text-grayscale-secondary"
            >
              <Add size={24} className="rotate-45 transform" />
            </button>
            <span className="text-warning mb-1">
              <Medal size={40} />
            </span>
            {renderDisabledOptionContent()}
            <button className="bg-focus rounded-lg py-3 px-5 text-white mt-4">
              <a href={outsideRoutes.atobiHelpMail.create()}>
                {gt('Contact Atobi Support')}
              </a>
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

const Tooltip = styled.div`
  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent ${config.colors['gray-extra-dark']}
      transparent;
  }
`;

export default ActionBlockOption;
