import { request } from 'app/api/gcsRequest';
import config from 'app/config';
import {
  ShareArticleRequest,
  SharedContentApi,
} from 'common-ui/generated/api/gcs';

const shareArticle = (params: ShareArticleRequest) =>
  new SharedContentApi(undefined, config.env.gcsApiUrl, request())
    .shareArticle(params)
    .then((response) => response.data);

export { shareArticle };
export default shareArticle;
