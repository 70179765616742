import { resources, queryKeys } from 'app/api/auth';
import { selectors } from 'app/store/request';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

const useGetEnabledModules = () => {
  const requestInstanceReady = useSelector(selectors.getRequestInstanceReady);
  const { data, isLoading } = useQuery(
    queryKeys.getEnabledModules(),
    resources.getEnabledModules,
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled: requestInstanceReady,
    }
  );

  return {
    isLoading,
    modules: data?.data?.data,
  };
};

export default useGetEnabledModules;
