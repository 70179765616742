import { cx } from '@emotion/css';
import { useISOLanguages } from 'app/hooks';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { LocationTick, TickCircle } from 'iconsax-react';
import React from 'react';
import ReactTooltip from 'react-tooltip';

import { LanguageApprovalStatus, languageStatuses } from '../types';

interface Props {
  status: LanguageApprovalStatus;
  languages: string[];
  mainLanguage: string;
  onUpdateStatus: () => void;
}

const LanguageStatus = ({
  languages,
  status,
  mainLanguage,
  onUpdateStatus,
}: Props) => {
  const { t } = useArticlesTranslation();
  const isoLanguages = useISOLanguages({ search: '' });

  const languageStatus = languageStatuses.find(({ type }) => type === status);

  if (languages.length <= 0 || !languageStatus) return null;

  const articleLanguages = isoLanguages.filter((isoLanguage) =>
    languages.includes(isoLanguage.code)
  );

  const mainLanguageIndex = articleLanguages.findIndex(
    (language) => language.code === mainLanguage
  );

  const mainLanguageObject = articleLanguages.splice(mainLanguageIndex, 1);

  const orderedLanguages = [...mainLanguageObject, ...articleLanguages];

  return (
    <div className="w-full pt-1 pb-1.5 font-bold text-grayscale-secondary flex">
      {languageStatus.type === 'draft' && (
        <button
          data-for="publish"
          data-tip={t('Mark as ready for publishing')}
          className="text-grayscale-secondary hover:text-success mr-1"
          onClick={onUpdateStatus}
        >
          <TickCircle size={16} />
          <ReactTooltip
            place="top"
            effect="solid"
            class="react-tooltip"
            id="publish"
          />
        </button>
      )}
      <span
        className={cx('font-normal mr-1 flex flex-none', languageStatus.color)}
      >
        {t(languageStatus.text)}:
      </span>
      <div className="flex flex-auto">
        {orderedLanguages.map((language, index) => (
          <React.Fragment key={language.code}>
            {language.code === mainLanguage && (
              <LocationTick
                size={12}
                className="text-grayscale-secondary mr-0.5 mt-0.5 flex flex-none"
              />
            )}
            {language.name}
            {index < orderedLanguages.length - 1 && ', '}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default LanguageStatus;
