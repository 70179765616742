import useHomeTranslation from 'app/internationalization/hooks/useHomeTranslation';

const Empty = () => {
  const { t } = useHomeTranslation();
  return (
    <div className="flex items-center justify-center text-center w-full h-[300px]">
      <div className="flex flex-col items-center justify-center">
        <span className="text-2xl">🔍</span>
        <span className="font-bold">
          {t("Hmm, it looks like we don't have any matches.")}
        </span>
        <span className="text-sm text-gray-silver">
          {t('Try searching with other keywords.')}
        </span>
      </div>
    </div>
  );
};

export default Empty;
