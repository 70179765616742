import { request } from 'app/api/gcsRequest';
import config from 'app/config';
import { FileApi } from 'common-ui/generated/api/gcs';

const getFile = ({
  tenant,
  fileId,
  fileName,
}: {
  tenant: string;
  fileId: string;
  fileName: string;
}) =>
  new FileApi(undefined, config.env.gcsApiUrl, request())
    .getFile(tenant, fileId, fileName, 'url')
    .then((response) => response.data);

type AttachedFile = Awaited<ReturnType<typeof getFile>>;

export type { AttachedFile };
export { getFile };
export default getFile;
