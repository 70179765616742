import { cx, css } from '@emotion/css';
import styled from '@emotion/styled';
import { useDispatch } from 'app/hooks';
import { actions } from 'app/store/modal';
import { ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';
import OutsideClickHandler from 'react-outside-click-handler';

import ScrollbarContainer from '../ScrollbarContainer';

const ModalContainer = styled.div`
  background-color: rgba(34, 34, 34, 0.3);
`;

const modalWidth = {
  sm: '340px',
  md: '450px',
  lg: '530px',
  xl: '820px',
};

interface ModalProps {
  children: ReactNode;
  className?: string;
  containerPaddingClass?: string;
  heading?: JSX.Element;
  headingClassName?: string;
  overflow?: boolean;
  width?: keyof typeof modalWidth;
  onClose: () => void;
  containerClassName?: HTMLDivElement['className'];
  anchor?: HTMLElement | Element;
}

const Modal = ({
  children,
  className,
  containerPaddingClass = 'p-6',
  heading,
  headingClassName,
  overflow,
  width,
  containerClassName,
  anchor,
  onClose,
}: ModalProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.modalOpened());

    return () => {
      dispatch(actions.modalClosed());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const modalContentClassName = cx(
    'm-4 md:m-0 rounded-lg modal_content_container bg-light shadow-atobi',
    containerClassName,
    width &&
      css`
        width: ${modalWidth[width]};
      `,
    { 'overflow-clip': !overflow }
  );

  return createPortal(
    <ModalContainer className="fixed z-50 top-0 left-0 w-screen h-screen">
      <div className="flex items-center w-full h-full justify-center">
        <OutsideClickHandler onOutsideClick={onClose}>
          <div className={modalContentClassName}>
            <ScrollbarContainer overflow={overflow} className="max-h-[90vh]">
              {heading && (
                <div
                  className={cx(
                    'border-b border-gray-light pt-4 pb-2 px-4 sm:pt-6 sm:pb-4 sm:px-6',
                    headingClassName
                  )}
                >
                  {heading}
                </div>
              )}
              <div
                className={cx(
                  'modal_content',
                  className,
                  containerPaddingClass
                )}
              >
                {children}
              </div>
            </ScrollbarContainer>
          </div>
        </OutsideClickHandler>
      </div>
    </ModalContainer>,
    anchor ?? document.body
  );
};

export default Modal;
