import { cx } from '@emotion/css';
import CheckLineIcon from 'remixicon-react/CheckLineIcon';

interface ButtonProps {
  index: number;
  maxCorrectAnswers: number;
  optionsNumber: number;
  correctAnswersNumber: number;
  disabled: boolean;
  onClick: VoidFunction;
}

const Button = ({
  index,
  maxCorrectAnswers,
  optionsNumber,
  correctAnswersNumber,
  disabled,
  onClick,
}: ButtonProps) => {
  return (
    <button
      disabled={disabled}
      key={index}
      onClick={onClick}
      className={cx(
        'text-sm w-full h-[36px] px-3 font-normal flex items-center justify-between whitespace-nowrap bg-gray-light text-grayscale-secondary',
        {
          'hover:bg-hover-blue bg-white text-black': index <= maxCorrectAnswers,
        },
        { 'rounded-t': index === 0 },
        {
          'rounded-b': index === optionsNumber,
        }
      )}
    >
      <span>{index}</span>
      <CheckLineIcon
        size={20}
        className={cx('text-focus ml-4', {
          invisible: index !== correctAnswersNumber,
        })}
      />
    </button>
  );
};
export default Button;
