import { cx, keyframes } from '@emotion/css';
import styled from '@emotion/styled';
import React, { useState, useEffect, useMemo } from 'react';

interface RollingCounterProps {
  value: number;
}

const rollingSpeedInSeconds = 0.1;

const RollingCounter: React.FC<RollingCounterProps> = ({ value }) => {
  const { rollingDigit, isRolling } = useRollingAnimation(value);

  const scoreString = value.toString();
  const lastIndex = scoreString.length - 1;
  const staticPart = scoreString.slice(0, lastIndex);

  const isFullScore = value === 100;

  return (
    <RollingCounterStyle className="overflow-hidden">
      <>
        <span
          className={cx('text-lg  font-bold', {
            'text-success': isFullScore,
            'text-focus': !isFullScore,
          })}
        >
          {staticPart}
          <span
            className={cx(`inline-block`, {
              rolling: isRolling,
            })}
          >
            {rollingDigit}
          </span>

          <span className="text-base font-bold">%</span>
        </span>
      </>
    </RollingCounterStyle>
  );
};

const useRollingAnimation = (value: number) => {
  const [rollingDigit, setRollingDigit] = useState<number>(0);
  const [isRolling, setIsRolling] = useState<boolean>(false);

  const animationDuration = 1000;
  const framesPerSecond = 30;
  const totalFrames = useMemo(
    () => (animationDuration / 1000) * framesPerSecond,
    []
  );

  useEffect(() => {
    const lastDigit = value % 10;
    const increment = lastDigit === 0 ? 1 : Math.ceil(lastDigit / totalFrames);
    let currentFrame = 0;

    setIsRolling(true);

    const onAnimationEnd = () => {
      clearInterval(interval);
      setRollingDigit(Math.round(lastDigit));
      setIsRolling(false);
    };

    const onAnimationNewFrame = () => {
      setRollingDigit((prevDigit) => (prevDigit + increment) % 10);
      currentFrame++;
    };

    const interval = setInterval(() => {
      if (currentFrame >= totalFrames) onAnimationEnd();
      else onAnimationNewFrame();
    }, 1000 / framesPerSecond);

    return () => clearInterval(interval);
  }, [value, totalFrames]);

  return { rollingDigit, isRolling };
};

const rollingAnimation = keyframes`
  0%,
  100% {
    transform: translateY(60%);
  }
  50% {
    transform: translateY(-60%);
  }
`;

const RollingCounterStyle = styled.div`
  .rolling {
    animation: ${rollingAnimation} ${rollingSpeedInSeconds}s infinite;
  }
`;

export default RollingCounter;
