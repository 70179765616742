import styled from '@emotion/styled';
import { ActionsDropdown } from 'app/components';
import ActionToast from 'app/components/Toast/ActionToast';
import config from 'app/config';
import { useArticlesTranslation } from 'app/internationalization/hooks';
import { actions, selectors } from 'app/store/editor';
import { Language } from 'app/store/editor/types';
import ThreeDots from 'assets/icons/three_dots_vertical.svg?react';
import { LocationTick, Translate, Trash } from 'iconsax-react';
import { useDispatch, useSelector } from 'react-redux';
import { Popover } from 'react-tiny-popover';
import { toast } from 'react-toastify';

import { useLanguageSettingsContext } from '../context';

interface LanguageSettingsProps {
  language: Language;
  parentRef: HTMLDivElement;
  isAllBlocksApproved: boolean;
}

const LanguageSettings = ({
  language,
  parentRef,
  isAllBlocksApproved,
}: LanguageSettingsProps) => {
  const { name, code, isDefault, active } = language;
  const {
    popovers,
    setPopovers,
    isTranslating,
    translate,
    setTranslatingLanguageCode,
  } = useLanguageSettingsContext();

  const { t } = useArticlesTranslation();
  const dispatch = useDispatch();
  const article = useSelector(selectors.getArticle);
  const languages = useSelector(selectors.getSelectedLanguages);
  const mainLanguage = languages.find((l) => l.isDefault) || {
    name,
    code,
    isDefault,
    active,
  };

  const clearToasts = () => {
    toast.dismiss();
    toast.clearWaitingQueue();
  };

  const setMainLanguage = () => {
    dispatch(actions.setMainLanguage(code));
    setPopovers(undefined);
    clearToasts();
    toast(
      <ActionToast
        action={`${t('Undo')}`}
        text={`${t('The Main Language as been changed to')} ${name}`}
        onClick={() => dispatch(actions.setMainLanguage(mainLanguage.code))}
      />,
      {
        position: 'bottom-center',
        autoClose: 8000,
        closeButton: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        containerId: 'Languages',
      }
    );
  };

  const translateArticle = () => {
    if (!article) return;
    setPopovers(undefined);
    setTranslatingLanguageCode(code);
    translate(
      { articleId: article.id, toLanguage: code },
      {
        onSuccess: (updatedArticle) => {
          dispatch(actions.setArticle(updatedArticle));
        },
        onSettled() {
          setTranslatingLanguageCode(undefined);
        },
      }
    );
  };

  const removeLanguage = () => {
    setPopovers(undefined);
    dispatch(actions.removeLanguage(code));
    clearToasts();
    toast(
      <ActionToast
        action={`${t('Undo')}`}
        text={`${name} ${t('was removed')}`}
        onClick={() => {
          dispatch(actions.addLanguage({ name, code, isDefault, active }));
          toast.dismiss();
        }}
      />,
      {
        position: 'bottom-center',
        autoClose: 8000,
        closeButton: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        containerId: 'Languages',
      }
    );
  };

  const items = [
    {
      text: `${t('Set as Main Language')}`,
      description: t('Source for auto-translation'),
      Icon: LocationTick,
      disabled: isDefault || (article && !article.variants[language.code]),
      onClick: setMainLanguage,
    },
    {
      text: `${t('Auto-Translate')}`,
      description: `${t('Replaces non-proofread blocks')}`,
      Icon: Translate,
      disabled: isDefault || isTranslating || isAllBlocksApproved,
      onClick: translateArticle,
    },
    {
      text: `${t('Delete')}`,
      Icon: Trash,
      disabled: isDefault || active,
      onClick: removeLanguage,
    },
  ];

  const onOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setPopovers((prev) => ({
      openSettingsPopover:
        prev?.openSettingsPopover === language.code ? undefined : language.code,
    }));
  };

  return (
    <Popover
      containerClassName="z-50"
      isOpen={popovers?.openSettingsPopover === language.code}
      positions={['bottom']}
      onClickOutside={() => setPopovers(undefined)}
      content={<ActionsDropdown items={items} className="md:right-auto" />}
      parentElement={parentRef}
      padding={5}
    >
      <div className="ml-auto">
        <div className="flex justify-center">
          <button
            onClick={onOpen}
            className="w-6 h-6 flex items-center justify-center whitespace-nowrap hover:bg-focus-background"
            type="button"
            data-bs-toggle="dropdown"
          >
            <StyledThreeDots />
          </button>
        </div>
      </div>
    </Popover>
  );
};

const StyledThreeDots = styled(ThreeDots)`
  path {
    fill: ${config.colors['gray-dark']};
  }
  &:hover {
    path {
      fill: ${config.colors.focus};
    }
  }
`;

export default LanguageSettings;
