import { useFeatures } from '@paralleldrive/react-feature-toggles';
import { SystemModules } from 'app/api/auth/constants';
import { useAuthenticatedUser } from 'app/api/auth/hooks';
import useGetEnabledModules from 'app/api/auth/hooks/useGetEnabledModules';
import { isActiveFeatureName } from 'app/utils';

import ScrollbarContainer from '../ScrollbarContainer';

import useModuleFeatureCheck from './hooks/useModuleFeatureCheck';
import ChannelsModule from './modules/ChannelsModule';
import CustomModule from './modules/CustomModule';
import {
  contentManagementLinks,
  getDashboardLinks,
  getLegacyLinks,
  getOrganizationLinks,
} from './modules/links';
import NavigationModule from './modules/NavigationModule';
import { SidebarSections } from './types';

interface Props {
  isClipped: boolean;
  badgeData: {
    [x: string]: number | undefined;
  };
}

const SidebarNavigation = ({ badgeData, isClipped }: Props) => {
  const features = useFeatures();
  const { checkActiveList, checkDiscontinuedList } = useModuleFeatureCheck();

  const {
    data: user,
    isChannelsManager,
    isCollaborator,
    hasCreateArticlePermission,
    hasUpdateArticlePermission,
    showAdvancedDashboard,
  } = useAuthenticatedUser();
  const { modules } = useGetEnabledModules();

  const newsHidden =
    (user?.hiddenModules?.[SystemModules.News] ||
      user?.discontinuedModules?.[SystemModules.News]) ??
    false;
  const socialHidden =
    (user?.hiddenModules?.[SystemModules.Social] ||
      user?.discontinuedModules?.[SystemModules.Social]) ??
    false;

  const legacyLinks = getLegacyLinks({ newsHidden, socialHidden });

  const discontinuedLinks = legacyLinks.filter(checkDiscontinuedList);

  const activeLinks = legacyLinks.filter(checkActiveList);

  const organizationLinks = getOrganizationLinks({
    isManager: !!isChannelsManager(),
    isGlossary:
      user?.isOwner || hasCreateArticlePermission || hasUpdateArticlePermission,
    isSettings: !!user?.isOwner,
  });

  const contentManagement = isCollaborator()
    ? contentManagementLinks.filter(
        ({ feature }) => !feature || isActiveFeatureName(feature, features)
      )
    : [];

  const dashboardLinks = getDashboardLinks({
    newsHidden,
    hasDashboard:
      features.includes('sisense') &&
      (modules?.kpiDashboard?.enabled || showAdvancedDashboard),
  });

  return (
    <ScrollbarContainer className="h-screen px-2">
      <nav className="flex-col flex text-grayscale-primary">
        <NavigationModule
          isClipped={isClipped}
          badgeData={badgeData}
          items={dashboardLinks}
          section={SidebarSections.General}
        />
        <NavigationModule
          header="Content management"
          isClipped={isClipped}
          badgeData={badgeData}
          items={contentManagement}
          section={SidebarSections.ContentManagement}
        />
        <NavigationModule
          header="Organisation"
          isClipped={isClipped}
          badgeData={badgeData}
          items={organizationLinks}
          section={SidebarSections.Organization}
        />
        <NavigationModule
          header="Modules"
          isClipped={isClipped}
          badgeData={badgeData}
          items={activeLinks}
          section={SidebarSections.Modules}
        />
        <NavigationModule
          header="Discontinued"
          isClipped={isClipped}
          badgeData={badgeData}
          items={discontinuedLinks}
          section={SidebarSections.Discontinued}
        />
        <CustomModule isClipped={isClipped} />
        <ChannelsModule isClipped={isClipped} badgeData={badgeData} />
      </nav>
    </ScrollbarContainer>
  );
};

export default SidebarNavigation;
