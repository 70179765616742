import config from 'app/config';
import { actions } from 'app/store/modal';
import { ArrowLeft2, ArrowRight2 } from 'iconsax-react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  DbActionAnswerValue,
  DbMediaTaskAnswer,
  MediaTaskBlockTypeEnum,
  SimpleTaskBlockTypeEnum,
} from 'submodules/common-ui/generated/api/gcs';

import ImageModal from './ImageModal';

interface MediaViewerProps {
  type: MediaTaskBlockTypeEnum | SimpleTaskBlockTypeEnum;
  answer?: DbActionAnswerValue;
  imgsToUpload: Array<string>;
  isLoading?: boolean;
  tenantName: string;
}

const MediaViewer = ({
  type,
  answer,
  isLoading = false,
  tenantName,
  imgsToUpload,
}: MediaViewerProps) => {
  const [selectedImage, setSelectedImage] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const onEscapeClick = (ev: KeyboardEvent) => {
      if (ev.key === 'Escape') {
        setModalOpen(false);
        dispatch(actions.modalClosed());
      }
    };

    document.addEventListener('keydown', onEscapeClick);

    return () => document.removeEventListener('keydown', onEscapeClick);
  }, [dispatch]);

  if (type === 'simple_task') return <></>;

  const srcs: Array<string> = [];

  if (isLoading) {
    srcs.push(...imgsToUpload);
  } else if (answer) {
    (answer as DbMediaTaskAnswer)?.forEach((a) =>
      srcs.push(`${config.env.gcsApiUrl}/file/${tenantName}/${a}`)
    );
  }

  const closeModal = () => {
    setModalOpen(false);
    dispatch(actions.modalClosed());
  };

  const onNextClick = () => {
    if (selectedImage + 1 > srcs.length - 1) {
      setSelectedImage(0);
      return;
    }

    setSelectedImage((prev) => prev + 1);
  };

  const onPreviousClick = () => {
    if (selectedImage - 1 < 0) {
      setSelectedImage(srcs.length - 1);
      return;
    }

    setSelectedImage((prev) => prev - 1);
  };

  return (
    <>
      <div className="flex items-center justify-center mt-4 rounded relative">
        <button
          onClick={onPreviousClick}
          className="flex items-center justify-center w-5 h-5 absolute left-[8px] rounded-full text-white bg-grayscale-secondary opacity-80"
        >
          <ArrowLeft2 size={12} />
        </button>
        <button
          onClick={() => {
            setModalOpen(true);
            dispatch(actions.modalOpened());
          }}
        >
          <img
            className="rounded-xl w-[354px] max-h-[188px] object-cover"
            src={srcs[selectedImage]}
            alt="Answer"
          />
        </button>

        <button
          onClick={onNextClick}
          className="flex items-center justify-center w-5 h-5 absolute right-[8px] rounded-full text-white bg-grayscale-secondary opacity-80"
        >
          <ArrowRight2 size={12} />
        </button>
      </div>
      {modalOpen && (
        <ImageModal
          closeModal={closeModal}
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
          selectedImage={selectedImage}
          srcs={srcs}
        />
      )}
    </>
  );
};

export default MediaViewer;
